import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { skip } from "rxjs";
import { Button, NotificationsContext } from "../..";
import { NotificationCategory } from "../../../utils/api/misc";
import useMarkAllNotificationsRead from "../../../utils/api/misc/useMarkAllNotificationsRead";
import { clearCache } from "../../../utils/cache";
import NotificationTab from "../NotificationTab";
import styles from "./NotificationDropdown.module.scss";

const NotificationDropdown = ({
    notificationCategory,
    hide,
    updateNotificationCount,
}: INotificationDropdownProps) => {
    const { t } = useTranslation();
    const [readStatus, setReadStatus] = useState<ReadStatus>("all");

    const { markRead } = useMarkAllNotificationsRead(notificationCategory);

    const markAllAsRead = useCallback(() => {
        markRead().subscribe(() => updateNotificationCount(true));
    }, [markRead, updateNotificationCount]);

    const { categories } = useContext(NotificationsContext);
    useEffect(() => {
        categories[
            notificationCategory === NotificationCategory.Issue
                ? "issue"
                : "standard"
        ]
            .pipe(skip(1))
            .subscribe(() => {
                clearCache();
                hide();
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className={styles.notificationFilter}>
                {t("Show")}
                <Button
                    onClick={setReadStatus}
                    clickParams={["all"]}
                    disabled={readStatus === "all"}
                >
                    {t("All")}
                </Button>
                <Button
                    onClick={setReadStatus}
                    clickParams={["unread"]}
                    disabled={readStatus === "unread"}
                >
                    {t("Unread")}
                </Button>
                <Button
                    onClick={setReadStatus}
                    clickParams={["read"]}
                    disabled={readStatus === "read"}
                >
                    {t("Read")}
                </Button>
                <Button onClick={markAllAsRead}>{t("Mark All as Read")}</Button>
            </div>

            {readStatus === "unread" && (
                <NotificationTab
                    notificationCategory={notificationCategory}
                    updateNotificationCount={updateNotificationCount}
                    read={false}
                />
            )}
            {readStatus === "read" && (
                <NotificationTab
                    notificationCategory={notificationCategory}
                    updateNotificationCount={updateNotificationCount}
                    read={true}
                />
            )}
            {readStatus === "all" && (
                <NotificationTab
                    notificationCategory={notificationCategory}
                    updateNotificationCount={updateNotificationCount}
                />
            )}
        </>
    );
};

interface INotificationDropdownProps {
    notificationCategory: NotificationCategory;
    updateNotificationCount: (all: boolean) => void;
    hide: () => void;
}

type ReadStatus = "read" | "unread" | "all";

export default NotificationDropdown;
