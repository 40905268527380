import { useCallback, useContext } from "react";
import { PropertyCategoriesContext, UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const useHoldAudit = (id: number) => {
    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);
    const { send, error } = useApiRequest({
        errorMessage:
            "You can't view/edit this audit. The audit is on hold by someone else.",
    });
    const sendRequest = useXTagApiRequest();

    const holdAudit = useCallback(
        () =>
            send(
                sendRequest({
                    url: `${config.auditsApiUrl}/${id}/hold`,
                    method: "POST",
                    urlParams: getGlobalUrlParameters(
                        selectedCategories.map((c) => c.id),
                        activeUserParentsIds,
                    ),
                }),
            ),
        [send, sendRequest, id, selectedCategories, activeUserParentsIds],
    );

    return { holdAudit, error };
};

export default useHoldAudit;
