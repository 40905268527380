import { CSSProperties, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Icon, Link } from "../../../components";
import AuthorizedImage from "../../../components/AuthorizedImage";
import { ISimpleProperty } from "../../../utils/api/properties";
import styles from "./PropertyGridItem.module.scss";

const PropertyGridItem = ({ property, tagCreated }: IPropertyGridItemProps) => {
    const { t } = useTranslation();

    const cssRules = useMemo(() => {
        const css: CSSProperties = {
            borderRadius: "10px",
            marginBottom: "20px",
            boxShadow: "0 3px 7px 0 rgba(0, 0, 0, 0.4)",
        };

        if (!tagCreated && !property.tag) {
            css.filter = "grayscale(100%)";
        }

        return css;
    }, [property.tag, tagCreated]);

    return (
        <div className={styles.container}>
            <AuthorizedImage
                image={property.imageUrl}
                height={150}
                cssRules={cssRules}
            >
                {tagCreated ? (
                    <div className={styles.animated}>
                        <Icon icon="check" ariaLabel={t("Tag created")} />
                    </div>
                ) : (
                    <></>
                )}
            </AuthorizedImage>
            <Link url={`/management/properties/${property.id}`}>
                {property.addressString}
            </Link>
        </div>
    );
};

interface IPropertyGridItemProps {
    property: ISimpleProperty;
    tagCreated: boolean;
}

export default PropertyGridItem;
