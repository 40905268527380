import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button, Popover } from "../../../../components";
import AuthorizedImage from "../../../../components/AuthorizedImage";
import PopoverListItem from "../../../../components/Popover/PopoverListItem";
import { useToggle } from "../../../../hooks";
import { INotificationType } from "../../../../utils/api/users";
import styles from "./IssuePreferenceItem.module.scss";

const IssuePreferenceItem = ({
    issue,
    selected,
    onChange,
}: IIssuePreferenceItemProps) => {
    const { t } = useTranslation();
    const { hide, toggle, visible } = useToggle();
    const popoverRef = useRef<HTMLButtonElement>(null);

    const enableClicked = () => {
        onChange(true, issue);
        hide();
    };
    const disableClicked = () => {
        onChange(false, issue);
        hide();
    };

    return (
        <div className={styles.container}>
            <div className={styles.containerLeft}>
                <AuthorizedImage
                    image={`PortalIcons/NotificationImages/${issue.icon}`}
                    size={50}
                    cssRules={{ backgroundColor: "transparent" }}
                />
                <div className={styles.issueBody}>
                    <div className={styles.issueTitle}>{t(issue.title)}</div>
                    <div className={styles.issueDescription}>
                        {t(issue.displayName)}
                    </div>
                </div>
            </div>

            <div className={styles.containerRight}>
                <Button setRef={popoverRef} onClick={toggle}>
                    {selected ? t("On") : t("Off")}
                </Button>

                {visible && (
                    <Popover anchorElement={popoverRef} hide={hide}>
                        <PopoverListItem onClick={enableClicked}>
                            {selected ? <b>{t("On")}</b> : t("On")}
                        </PopoverListItem>
                        <PopoverListItem onClick={disableClicked}>
                            {!selected ? <b>{t("Off")}</b> : t("Off")}
                        </PopoverListItem>
                    </Popover>
                )}
            </div>
        </div>
    );
};

interface IIssuePreferenceItemProps {
    issue: INotificationType;
    selected: boolean;
    onChange: (issueActive: boolean, issueType: INotificationType) => void;
}

export default IssuePreferenceItem;
