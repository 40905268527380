import { useTranslation } from "react-i18next";
import { IImagePopupProps } from ".";
import { Backdrop, Button, Icon } from "..";
import { useAuthorizedImage } from "../../hooks/useAuthorizedImage";
import styles from "./ImagePopup.module.scss";

const ImagePopup = ({ imageUrl, caption, hide }: IImagePopupProps) => {
    const { t } = useTranslation();
    const authorizedImageUrl = useAuthorizedImage(imageUrl);

    return (
        <Backdrop>
            <div className={styles.container}>
                <div className={styles.imagePosition}>
                    <div className={styles.imageContainer}>
                        <img
                            src={authorizedImageUrl}
                            className={styles.image}
                            alt={t("image")}
                        />
                    </div>
                </div>

                {caption && (
                    <div className={styles.captionContainer}>
                        <div className={styles.caption}>{caption}</div>
                    </div>
                )}

                <div className={styles.closeContainer}>
                    <Button onClick={hide} displayAsLink={true} color="white">
                        <Icon icon="close" ariaLabel={t("Close")} />
                    </Button>
                </div>
            </div>
        </Backdrop>
    );
};

export default ImagePopup;
