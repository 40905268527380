import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IIssueDetailsProps } from ".";
import {
    Button,
    Card,
    Gallery,
    Link,
    SimpleTable,
    useGallery,
} from "../../../components";
import { IColumn } from "../../../components/SimpleTable";
import useDownloadPaperwork from "../../../hooks/useDownloadPaperwork";
import { IIssue, IIssueUser } from "../../../utils/api/issues";
import {
    IAssetJob,
    IFile,
    IFuelType,
    IIssueBase,
    IObservation,
} from "../../../utils/api/observations";

const IssueDetails = ({ issue, type }: IIssueDetailsProps) => {
    const { t } = useTranslation();
    const { currentImageIndex, setCurrentImageIndex, show, hide, visible } =
        useGallery();
    const { download } = useDownloadPaperwork();

    const renderJob = useCallback(
        (value: IAssetJob | null) =>
            value && (
                <Link url={`/jobs/jobs/${value.id}`}>{`#${value.id}`}</Link>
            ),
        [],
    );

    const renderDownloadButton = useCallback(
        (value: IAssetJob | null, row: IIssueBase) => {
            const downloadDocument = () => {
                // TODO: add the functionaity for standard issue
                const eicr = (issue as IObservation).eicr;
                if (eicr?.fileLink) {
                    download(eicr.id, eicr.fileLink).subscribe();
                }
            };

            const hasDocument =
                type === "observation"
                    ? (row as IObservation).eicr?.fileLink
                    : true;

            return value && hasDocument ? (
                <Button onClick={downloadDocument}>
                    {row.fuelType.displayName !== "Electric"
                        ? t("Download LGSR")
                        : t("Download EICR")}
                </Button>
            ) : (
                "---"
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [issue, t, type],
    );

    const renderProperty = useCallback(
        (value: string, row: IIssueBase) => (
            <Link url={`/management/properties/${row.property.id}`}>
                {value}
            </Link>
        ),
        [],
    );

    // start standard issue renders
    const renderAppliance = useCallback(
        (value: string, row: IIssue) =>
            row.appliance && (
                <Link url={`/management/appliances/${row.appliance.id}`}>
                    {value}
                </Link>
            ),
        [],
    );

    const renderAssignedTo = useCallback(
        (value: IIssueUser | null) => (
            <>
                {value ? value.name : t("Not assigned")}
                <Button cssRules={{ marginLeft: "10px" }}>{t("Change")}</Button>
            </>
        ),
        [t],
    );
    // end standard issue columns

    // start observations
    const renderImageLink = useCallback(
        (value: IFile) => {
            const openGallery = (image: string) => {
                setCurrentImageIndex(0);
                show();
            };

            return (
                value && (
                    <Button
                        cssRules={{ margin: "0 10px 10px 0" }}
                        onClick={openGallery}
                    >
                        {t("Image")}
                    </Button>
                )
            );
        },
        [setCurrentImageIndex, show, t],
    );

    const pictures = useMemo(() => {
        if (type === "observation") {
            const observation = issue as IObservation;

            if (observation.image) {
                return [observation.image.fileLink];
            }
        }

        return undefined;
    }, [issue, type]);

    const columns = useMemo<IColumn[]>(
        () => [
            {
                title: t("Jobs"),
                path: "job",
                type: "component",
                render: renderJob,
            },
            {
                title: t("Job Type"),
                path: "job",
                type: "component",
                render: (value: IAssetJob | null) =>
                    value ? t(value.jobType) : "---",
            },
            {
                title: t("Fuel Type"),
                path: "fuelType",
                type: "component",
                render: (value: IFuelType | null) =>
                    value ? value.displayName : "---",
            },
            {
                title:
                    issue.fuelType.displayName !== "Electric"
                        ? t("LGSR")
                        : t("EICR"),
                type: "component",
                path: "job",
                render: renderDownloadButton,
            },
            {
                title: t("Property"),
                type: "component",
                path: "property.address",
                render: renderProperty,
            },
            {
                title: t("Appliance"),
                type: "component",
                path: "appliance.displayName",
                hidden: type !== "issue",
                render: renderAppliance,
            },
            {
                title: t("Assigned To"),
                type: "component",
                path: "assignedUser",
                hidden: type !== "issue",
                render: renderAssignedTo,
            },
            {
                title: t("Section"),
                type: "component",
                path: "task.name",
                hidden: type !== "observation",
                render: (value: string) => (value ? value : "---"),
            },
            {
                title: t("Code"),
                type: "string",
                path: "type.displayName",
                hidden: type !== "observation",
            },
            {
                title: t("Description"),
                type: "string",
                path: "description",
                hidden: type !== "observation",
            },
            {
                title: t("Circuit"),
                type: "string",
                path: "circuit.displayName",
                hidden: type !== "observation",
            },
            {
                title: t("Notes"),
                type: "string",
                path: "notes",
                hidden: type !== "observation",
            },
            {
                title: t("Images"),
                type: "component",
                path: "image",
                hidden: type !== "observation",
                render: renderImageLink,
            },
            {
                title: t("Reason for not resolving"),
                type: "string",
                path: "unresolvedReason",
                hidden: type !== "observation",
            },
        ],
        [
            issue.fuelType.displayName,
            renderAppliance,
            renderAssignedTo,
            renderDownloadButton,
            renderImageLink,
            renderJob,
            renderProperty,
            t,
            type,
        ],
    );

    return (
        <Card title={t("Details")}>
            <SimpleTable single={true} columns={columns} data={issue} />

            {visible && pictures && (
                <Gallery
                    images={pictures}
                    currentImageIndex={currentImageIndex}
                    setCurrentImageIndex={setCurrentImageIndex}
                    hide={hide}
                />
            )}
        </Card>
    );
};

export default IssueDetails;
