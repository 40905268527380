import { ReactNode, useMemo } from "react";
import { tap } from "rxjs";
import { IUserPreferencesContext, UserPreferencesContext } from ".";
import { IUserPreferences, useUpdatePreferences } from "../../utils/api/users";

const UserPreferencesProvider = ({
    preferences,
    preferencesId,
    savePreferences,
    children,
}: IUserPreferencesProviderProps) => {
    const { update, loading } = useUpdatePreferences();

    const context = useMemo<IUserPreferencesContext>(
        () => ({
            preferences,
            updatePreferences: (newPreferences: IUserPreferences) =>
                update(preferencesId, newPreferences).pipe(
                    tap(() => {
                        savePreferences({
                            id: preferencesId,
                            preferences: newPreferences,
                        });
                    }),
                ),
            loading: loading,
        }),
        [loading, preferences, preferencesId, savePreferences, update],
    );

    return (
        <UserPreferencesContext.Provider value={context}>
            {children}
        </UserPreferencesContext.Provider>
    );
};

interface IUserPreferencesProviderProps {
    preferencesId: number;
    preferences: IUserPreferences;
    savePreferences: (preferences: {
        id: number;
        preferences: IUserPreferences;
    }) => void;
    children: ReactNode;
}

export default UserPreferencesProvider;
