import { useCallback, useContext } from "react";
import { IJobAudit } from ".";
import { PropertyCategoriesContext, UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiValue from "../useApiValue";

const useJobAudit = () => {
    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);
    const { value, loaded, reset, send } = useApiValue<IJobAudit>({});
    const sendRequest = useXTagApiRequest();

    const getJobAudit = useCallback(
        (jobId: number) =>
            send(
                sendRequest({
                    url: `${config.lgsrsApiUrl}/audit/${jobId}`,
                    method: "GET",
                    urlParams: getGlobalUrlParameters(
                        selectedCategories.map((c) => c.id),
                        activeUserParentsIds,
                    ),
                }),
            ),
        [activeUserParentsIds, selectedCategories, send, sendRequest],
    );

    return { value, loaded, reset, getJobAudit };
};

export default useJobAudit;
