import { useTranslation } from "react-i18next";
import { IAssetsAtPropertyProps } from ".";
import { Card, Loading } from "..";
import { useCircuitsAtProperty } from "../../utils/api/assets";
import AssetListItem from "../AssetListItem";

const AssetsAtProperty = ({ assetId, propertyId }: IAssetsAtPropertyProps) => {
    const { t } = useTranslation();
    const { records, loaded } = useCircuitsAtProperty(propertyId);

    return (
        <Card title={t(`Other circuits at property`)}>
            {loaded ? (
                records.map(
                    (asset) =>
                        assetId !== asset.id && (
                            <AssetListItem key={asset.id} asset={asset} />
                        ),
                )
            ) : (
                <Loading small={true} centerContent={true} />
            )}
        </Card>
    );
};

export default AssetsAtProperty;
