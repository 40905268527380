import { useCallback, useContext } from "react";
import { ICreateDocument } from ".";
import { PropertyCategoriesContext, UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const useCreateDocument = () => {
    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);
    const { send, loading, error } = useApiRequest({});
    const sendRequest = useXTagApiRequest();

    const createDocument = useCallback(
        (document: ICreateDocument) =>
            send(
                sendRequest({
                    url: `${config.lgsrsApiUrl}/documents/v2`,
                    method: "POST",
                    urlParams: getGlobalUrlParameters(
                        selectedCategories.map((c) => c.id),
                        activeUserParentsIds,
                    ),
                    body: document,
                }),
            ),
        [activeUserParentsIds, selectedCategories, send, sendRequest],
    );

    return { createDocument, loading, error };
};

export default useCreateDocument;
