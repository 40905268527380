import { useCallback, useContext } from "react";
import { ICreateUser } from ".";
import { UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const useAdminCreateUser = () => {
    const { send, error, loading } = useApiRequest({});
    const { user: currentUser } = useContext(UserContext);

    const sendRequest = useXTagApiRequest();

    const createUser = useCallback(
        (user: ICreateUser) =>
            send(
                sendRequest({
                    url: `${config.usersApiUrl}/v2`,
                    method: "POST",
                    urlParams: getGlobalUrlParameters(
                        [],
                        currentUser.userParents.map((up) => up.userParentId),
                    ),
                    body: user,
                }),
            ),
        [currentUser, send, sendRequest],
    );

    return { createUser, error, loading };
};

export default useAdminCreateUser;
