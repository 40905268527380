import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IContractorSelectProps } from ".";
import { useAllContractors } from "../../../utils/api/landlords";
import Select, { ISelectOption } from "../../Select";

const ContractorSelect = ({
    contractorId,
    setContractorId,
    validator,
}: IContractorSelectProps) => {
    const { t } = useTranslation();

    const { records } = useAllContractors();

    const options = useMemo<ISelectOption[]>(
        () =>
            records.map((contractor) => ({
                label: contractor.name,
                value: contractor.id.toString(),
            })),
        [records],
    );

    return (
        <Select
            label={t("Contractor")}
            options={options}
            value={contractorId}
            allowEmpty={true}
            onChange={setContractorId}
            {...validator}
        />
    );
};

export default ContractorSelect;
