import { ReactNode } from "react";

const Tab = ({ children }: ITabProps) => {
    return <>{children}</>;
};

interface ITabProps {
    header: ReactNode;
    children: ReactNode;
}

export default Tab;
