import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AssignUser, Button, Form } from "../../../components";
import { IUser } from "../../../utils/api/users";
import {
    isRequired,
    useValidateField,
    validateForm,
} from "../../../utils/validation";

const AssignIssue = ({ users, onSave, loading }: IAssignIssueProps) => {
    const { t } = useTranslation();

    const [selectedUserId, setSelectedUserId] = useState("");

    const save = () => {
        onSave(selectedUserId);
    };

    const selectedUserIdValidator = useValidateField(
        selectedUserId,
        isRequired(),
    );

    const formValidators = validateForm(() => [selectedUserIdValidator]);

    return (
        <>
            <Form onSubmit={save} {...formValidators}>
                <AssignUser
                    users={users}
                    value={selectedUserId}
                    onChange={setSelectedUserId}
                    {...selectedUserIdValidator}
                />
                <Button variant="primary" type="submit" disabled={loading}>
                    {t("Save")}
                </Button>
            </Form>
        </>
    );
};

interface IAssignIssueProps {
    loading: boolean;
    users: IUser[];
    onSave: (id: string) => void;
}

export default AssignIssue;
