import GroupedBarChart from "./GroupedBarChart";

export interface IGroupedData {
    title: string;
    color: string;
    data: {
        label: string; // Date
        value: IDataValue[]; // Properties
        onClick?: () => void;
    }[];
    onClick?: () => void;
}

export interface IDataValue {
    label: string;
    value: number;
    colour: string;
}

export default GroupedBarChart;
