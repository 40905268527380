import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Loading, Select } from "../../../components";
import { ISelectOption } from "../../../components/Select";
import {
    useLandlordPreferences,
    useUpdateLandlordIssuePreferences,
} from "../../../utils/api/landlords";
import { INotificationType } from "../../../utils/api/users";
import useNotificationTypes from "../../../utils/api/users/useNotificationTypes";
import IssuePreferenceItem from "./IssuePreferenceItem";
import styles from "./IssuesPreferences.module.scss";

const IssuesPreferences = ({ landlordOptions }: IIssuesPreferencesProps) => {
    const landlordPreferences = useLandlordPreferences();
    const updateLandlordIssuePreferences = useUpdateLandlordIssuePreferences();
    const issueTypes = useNotificationTypes(2);

    const [activeIssueIds, setActiveIssueIds] = useState<number[]>([]);
    const [selectedLandlordId, setSelectedLandlordId] = useState("");

    const { t } = useTranslation();

    useEffect(() => {
        if (selectedLandlordId && landlordPreferences.value) {
            const selectedLandlordPrefs = landlordPreferences.value?.find(
                (preferences) => preferences.id === Number(selectedLandlordId),
            );

            if (selectedLandlordPrefs) {
                setActiveIssueIds(
                    selectedLandlordPrefs.preferences.issueSettings,
                );
            }
        }
    }, [landlordPreferences.value, selectedLandlordId]);

    const updateActiveIssueTypes = () => {
        updateLandlordIssuePreferences
            .updateLandlordIssuePreferences({
                landlordId: Number(selectedLandlordId),
                issueIds: activeIssueIds,
            })
            .subscribe();
    };

    const updateIssueIds = (
        isActive: boolean,
        issueType: INotificationType,
    ) => {
        if (isActive) {
            const updatedIssueIds = activeIssueIds.filter(
                (issueId) => issueId !== issueType.id,
            );

            setActiveIssueIds(updatedIssueIds);
        } else {
            const exists = activeIssueIds.some(
                (issueId) => issueId === issueType.id,
            );

            if (!exists) {
                setActiveIssueIds([...activeIssueIds, issueType.id]);
            }
        }
    };

    return landlordPreferences.value && issueTypes.value ? (
        <>
            <h2 className={styles.title}>{t("Issue Preferences")}</h2>
            <Select
                label={t(
                    "Toggle which issues you'd like to be generated within your organisation.",
                )}
                options={landlordOptions}
                onChange={setSelectedLandlordId}
                value={selectedLandlordId}
                allowEmpty={true}
            />
            {selectedLandlordId && (
                <>
                    <div className={styles.section}>
                        {issueTypes.value.map((issueType) => {
                            return (
                                <IssuePreferenceItem
                                    key={issueType.id}
                                    issue={issueType}
                                    onChange={updateIssueIds}
                                    selected={
                                        !activeIssueIds.some(
                                            (issueId) =>
                                                issueId === issueType.id,
                                        )
                                    }
                                />
                            );
                        })}
                    </div>

                    {!updateLandlordIssuePreferences.loading ? (
                        <Button
                            variant="primary"
                            onClick={updateActiveIssueTypes}
                        >
                            {t("Save")}
                        </Button>
                    ) : (
                        <Loading small={true} />
                    )}
                </>
            )}
        </>
    ) : (
        <Loading visible={true} />
    );
};

interface IIssuesPreferencesProps {
    landlordOptions: ISelectOption[];
}

export default IssuesPreferences;
