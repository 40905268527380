import { useCallback, useContext } from "react";
import { PropertyCategoriesContext, UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const useMarkAsSentByPost = () => {
    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);
    const { send } = useApiRequest({});
    const sendRequest = useXTagApiRequest();

    const markAsSentByPost = useCallback(
        (ids: number[]) =>
            send(
                sendRequest({
                    url: `${config.lgsrsApiUrl}/documents/v2/markassentbypost`,
                    method: "POST",
                    urlParams: getGlobalUrlParameters(
                        selectedCategories.map((c) => c.id),
                        activeUserParentsIds,
                    ),
                    body: { ids },
                }),
            ),
        [activeUserParentsIds, selectedCategories, send, sendRequest],
    );

    return { markAsSentByPost };
};

export default useMarkAsSentByPost;
