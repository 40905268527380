const encodeUrlParameters = (urlParams: IUrlParameters = {}) => {
    const params = [];
    for (const key of Object.keys(urlParams)) {
        const encodedKey = encodeURIComponent(key);

        for (const value of urlParams[key]) {
            const encodedValue = encodeURIComponent(value);

            if (encodedValue) {
                params.push(`${encodedKey}=${encodedValue}`);
            }
        }
    }

    return params.join("&");
};

const encodeUrl = (url: string, urlParams: IUrlParameters = {}) => {
    const params = encodeUrlParameters(urlParams);
    const urlWithParams = params ? `${url}?${params}` : url;

    return urlWithParams;
};

const getUrlParameters = () => {
    // eslint-disable-next-line no-restricted-globals
    const list = location.search.slice(1).split("&");

    const parameters: IUrlParameters = {};
    for (const item of list) {
        const parts = item.split("=");
        const key = parts[0];
        const value = decodeURIComponent(parts[1]);

        if (key && value) {
            if (parameters[key]) {
                parameters[key].push(value);
            } else {
                parameters[key] = [value];
            }
        }
    }

    return parameters;
};

export interface IUrlParameters {
    [key: string]: string[];
}

export { encodeUrl, encodeUrlParameters, getUrlParameters };
