import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { LandlordsApplianceOption } from ".";
import { Link } from "../../../../components";
import PieChart, {
    IPieChartData,
} from "../../../../components/Charts/PieChart";
import { useCssClasses } from "../../../../hooks";
import { colours } from "../../../../styles/colours";
import { IApplianceCondition } from "../../../../utils/api/misc";
import { encodeUrl } from "../../../../utils/url";
import styles from "./ApplianceCondition.module.scss";

const ApplianceCondition = ({
    conditions,
    collapsed,
    landlordsAppliance,
}: IApplianceConditionProps) => {
    const { t } = useTranslation();
    const [hoverIndex, setHoverIndex] = useState(-1);

    const pieChartData = useMemo<IPieChartData[]>(() => {
        const dictionary: { [key: string]: number } = {};

        for (const landlordConditions of conditions) {
            const filteredConditions = landlordConditions.data.filter(
                (f) =>
                    f.landlordAppliance === undefined ||
                    landlordsAppliance === "" ||
                    f.landlordAppliance.toString() === landlordsAppliance,
            );

            for (const condition of filteredConditions) {
                const label = condition.label || "Unknown";
                const value = condition.value;

                if (!dictionary[label]) {
                    dictionary[label] = value;
                } else {
                    dictionary[label] += value;
                }
            }
        }

        return Object.entries(dictionary).map((data, index) => ({
            label: data[0],
            value: data[1],
            color:
                hoverIndex === -1 || hoverIndex === index
                    ? data[0] === "Unknown"
                        ? colours.chart.grey
                        : colours.chart.colours[hoverIndex]
                    : colours.chart.lightGrey,
        }));
    }, [conditions, hoverIndex, landlordsAppliance]);

    const total = useMemo(() => {
        let count = 0;

        for (const data of pieChartData) {
            count += data.value;
        }

        return count;
    }, [pieChartData]);

    const conditionCssClasses = useCssClasses(
        styles.condition,
        collapsed ? styles.collapsed : "",
    );

    const chartContainerCssClasses = useCssClasses(
        styles.chartContainer,
        collapsed ? styles.collapsed : "",
    );

    const valueCssClasses = useCssClasses(
        styles.value,
        collapsed ? styles.collapsed : "",
    );

    return (
        <div className={styles.centerContainer}>
            <div className={styles.container}>
                <div>
                    <div className={chartContainerCssClasses}>
                        <PieChart
                            data={pieChartData}
                            showLabels={false}
                            thickness={collapsed ? 10 : 30}
                            aspectRatio={1}
                        />

                        <div className={styles.total}>
                            <div
                                className={styles.count}
                                data-test-id="Appliances_Total_Div"
                            >
                                {total}
                            </div>
                            <div className={styles.label}>{t("Total")}</div>
                        </div>
                    </div>
                </div>
                <div>
                    {pieChartData.map((data, index) => {
                        const onFocus = () => setHoverIndex(index);
                        const onBlur = () => setHoverIndex(-1);

                        return (
                            <Link
                                key={index}
                                url={encodeUrl("/management/appliances", {
                                    conditionOfAppliance: [`=${data.label}`],
                                    ...(landlordsAppliance !== "" && {
                                        landlordsApplianceV2: [
                                            `=${landlordsAppliance}`,
                                        ],
                                    }),
                                })}
                                display="block"
                                onFocus={onFocus}
                                onBlur={onBlur}
                            >
                                <div className={conditionCssClasses}>
                                    <div
                                        className={styles.leftBar}
                                        style={{
                                            backgroundColor:
                                                data.label === "Unknown"
                                                    ? colours.chart.grey
                                                    : colours.chart.colours[
                                                          index
                                                      ],
                                        }}
                                    />
                                    <div
                                        className={valueCssClasses}
                                        style={{
                                            color:
                                                data.label === "Unknown"
                                                    ? colours.chart.grey
                                                    : colours.chart.colours[
                                                          index
                                                      ],
                                        }}
                                        data-test-id={`Appliances_${data.label.replace(
                                            " ",
                                            "_",
                                        )}_Total_Div`}
                                    >
                                        {data.value}
                                    </div>
                                    <div className={styles.label}>
                                        {t(data.label)}
                                    </div>
                                </div>
                            </Link>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

interface IApplianceConditionProps {
    conditions: IApplianceCondition[];
    collapsed: boolean;
    landlordsAppliance: LandlordsApplianceOption;
}

export default ApplianceCondition;
