import { Fragment, useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IDocumentAuditProps } from ".";
import { Button, Icon, InputField, UserContext } from "../../../components";
import ToggleButton from "../../../components/ToggleButton";
import {
    DocumentAuditStatus,
    IBasicUser,
    IDocumentComment,
    useLgsrsFailReasons,
} from "../../../utils/api/lgsrs";
import { toDateTimeString } from "../../../utils/dates";
import styles from "./DocumentAudit.module.scss";

const DocumentAudit = ({
    document,
    readonly = false,
    updateHandler,
}: IDocumentAuditProps) => {
    const [documentStatus, setDocumentStatus] = useState<
        "pass" | "fail" | "awaiting" | undefined
    >(document.auditResult?.status);
    const { t } = useTranslation();

    const commentReadOnly = false;

    const [comments, setComments] = useState<IDocumentComment[]>(
        document.auditResult?.comments ?? [],
    );
    const [actions, setActions] = useState<IDocumentComment[]>(
        document.auditResult?.remedialActions ?? [],
    );
    const [currentComment, setCurrentComment] = useState<string>("");
    const [currentAction, setCurrentAction] = useState<string>("");

    const lgsrsFailReasons = useLgsrsFailReasons(document.documentTypeId);
    const userContext = useContext(UserContext);

    const user = useMemo<IBasicUser>(() => {
        return { id: userContext.user.id, name: userContext.user.name };
    }, [userContext.user]);

    const getOrCreateResult = useCallback(() => {
        const now = new Date(Date.now());
        return (
            document.auditResult ??
            (document.auditResult = {
                status: "awaiting",
                auditedBy: user,
                auditedDate: now.toISOString(),
                comments: [],
                remedialActions: [],
            })
        );
    }, [document, user]);

    const resetComment = useCallback(() => {
        setCurrentComment("");
        setAddingComment(false);
    }, []);

    const addCommentHandler = useCallback(() => {
        const docResult = getOrCreateResult();
        const now = new Date(Date.now()).toISOString();

        if (currentComment && document.auditResult) {
            docResult.comments = document.auditResult.comments ?? [];

            docResult.comments.push({
                comment: currentComment,
                date: now,
                createdBy: user,
            });

            setComments([...docResult.comments]);
            resetComment();
            updateHandler(document.id, getOrCreateResult());
        }
    }, [
        currentComment,
        document.auditResult,
        document.id,
        getOrCreateResult,
        resetComment,
        updateHandler,
        user,
    ]);

    const cancelAddCommentHandler = useCallback(() => {
        resetComment();
    }, [resetComment]);

    const resetAction = useCallback(() => {
        setCurrentAction("");
        setAddingAction(false);
    }, []);

    const addActionHandler = useCallback(() => {
        const docResult = getOrCreateResult();
        const now = new Date(Date.now()).toISOString();

        if (currentAction && document.auditResult) {
            docResult.remedialActions = docResult.remedialActions ?? [];
            docResult.remedialActions.push({
                comment: currentAction,
                date: now,
                createdBy: user,
            });

            setActions([...docResult.remedialActions]);
            resetAction();
            updateHandler(document.id, getOrCreateResult());
        }
    }, [
        currentAction,
        document.auditResult,
        document.id,
        getOrCreateResult,
        resetAction,
        updateHandler,
        user,
    ]);

    const cancelAddActionHandler = useCallback(() => {
        resetAction();
    }, [resetAction]);

    const [addingComment, setAddingComment] = useState(false);
    const [addingAction, setAddingAction] = useState(false);

    const showAddComment = useCallback(() => {
        setAddingComment(true);
    }, []);

    const showAddAction = useCallback(() => {
        setAddingAction(true);
    }, []);

    const updateAuditStatus = useCallback(
        (documentAuditStatus: DocumentAuditStatus) => {
            if (!readonly) {
                const docStatus = getOrCreateResult();

                if (docStatus.status !== documentAuditStatus) {
                    docStatus.status = documentAuditStatus;

                    setFailReasons([]);
                    docStatus.failReasons = [];
                }

                updateHandler(document.id, docStatus);
                setDocumentStatus(documentAuditStatus);
            }
        },
        [document.id, getOrCreateResult, readonly, updateHandler],
    );

    const toggleButtonHandler = useCallback(
        (checked: boolean, value: number) => {
            if (document.auditResult) {
                const failReasons = (document.auditResult.failReasons =
                    document.auditResult.failReasons ?? []);

                const record = failReasons.find(
                    (r) => r.failReasonId === value,
                );

                const now = new Date(Date.now()).toISOString();

                if (checked && !record) {
                    failReasons.push({
                        failReasonId: value,
                        createdAt: now,
                    });
                } else if (!checked && record) {
                    failReasons.splice(failReasons.indexOf(record), 1);
                }
                setFailReasons([...failReasons]);

                updateHandler(document.id, document.auditResult);
            }
        },
        [document.auditResult, document.id, updateHandler],
    );

    const [failReasons, setFailReasons] = useState(
        document.auditResult?.failReasons,
    );

    const selectedFailureReason = useMemo(() => {
        const reasons: { [key: string]: boolean } = {};

        lgsrsFailReasons.loaded &&
            lgsrsFailReasons.value.forEach((reason) => {
                reasons["r_" + reason.id.toString()] =
                    failReasons?.some((fr) => fr.failReasonId === reason.id) ??
                    false;
            });
        return reasons;
    }, [lgsrsFailReasons.loaded, lgsrsFailReasons.value, failReasons]);

    return (
        <>
            {!readonly && (
                <div className={styles.passFail}>
                    <Button
                        onClick={updateAuditStatus}
                        clickParams={["pass"]}
                        variant="primary"
                    >
                        {t("Pass")}
                    </Button>

                    <Button
                        onClick={updateAuditStatus}
                        clickParams={["fail"]}
                        variant="error"
                    >
                        {t("Fail")}
                    </Button>
                </div>
            )}
            {documentStatus === "fail" && (
                <div className={styles.divider}>
                    <div className={styles.failureTitle}>
                        {t("Failure Reasons")}
                    </div>
                    <div className="reasons">
                        {lgsrsFailReasons.loaded &&
                            lgsrsFailReasons.value.map((reason) => {
                                return (
                                    <ToggleButton
                                        value={reason.id}
                                        onChange={toggleButtonHandler}
                                        checked={
                                            selectedFailureReason[
                                                "r_" + reason.id
                                            ]
                                        }
                                        key={reason.id}
                                        readonly={readonly}
                                    >
                                        {reason.displayName}
                                    </ToggleButton>
                                );
                            })}
                    </div>
                </div>
            )}
            <div className={styles.divider}>
                <div className={styles.title}>
                    {t("Comments")} ({comments.length})
                </div>
                <div>
                    {comments.map((comment, index) => (
                        <Fragment key={index}>
                            <div className={styles.comment}>
                                {comment.comment}
                            </div>
                            <div className={styles.commentMetaData}>
                                <span>{comment.createdBy.name}</span>
                                <span>
                                    {toDateTimeString(new Date(comment.date))}
                                </span>
                            </div>
                        </Fragment>
                    ))}
                    <div className={styles.add}>
                        {!addingComment && !commentReadOnly && (
                            <Button onClick={showAddComment}>{t("Add")}</Button>
                        )}
                    </div>
                    {addingComment && (
                        <div>
                            <InputField
                                cssRules={{ flex: 1 }}
                                value={currentComment}
                                onChange={setCurrentComment}
                                placeholder={t("Comment")}
                                append={
                                    <>
                                        <Button
                                            onClick={addCommentHandler}
                                            cssRules={{ flex: 0 }}
                                        >
                                            <Icon
                                                icon="check"
                                                color="green"
                                                ariaLabel={t("Send")}
                                            />
                                        </Button>
                                        <Button
                                            cssRules={{
                                                marginLeft: "10px",
                                            }}
                                            onClick={cancelAddCommentHandler}
                                        >
                                            <Icon
                                                icon="close"
                                                ariaLabel={t("Cancel")}
                                            />
                                        </Button>
                                    </>
                                }
                            />
                        </div>
                    )}
                </div>
            </div>
            <div className={styles.divider}>
                <div className={styles.title}>
                    {t("Remedial actions")} ({actions.length})
                </div>
                <div>
                    {actions.map((comment, index) => (
                        <Fragment key={index}>
                            <div className={styles.comment}>
                                {comment.comment}
                            </div>
                            <div className={styles.commentMetaData}>
                                <span>{comment.createdBy.name}</span>
                                <span>
                                    {toDateTimeString(new Date(comment.date))}
                                </span>
                            </div>
                        </Fragment>
                    ))}
                    <div className={styles.add}>
                        {!addingAction && !commentReadOnly && (
                            <Button onClick={showAddAction}>{t("Add")}</Button>
                        )}
                    </div>
                    {addingAction && (
                        <div>
                            <InputField
                                cssRules={{ flex: 1 }}
                                value={currentAction}
                                onChange={setCurrentAction}
                                placeholder={t("Remedial action")}
                                append={
                                    <>
                                        <Button
                                            onClick={addActionHandler}
                                            cssRules={{ flex: 0 }}
                                        >
                                            <Icon
                                                icon="check"
                                                color="green"
                                                ariaLabel={t("Send")}
                                            />
                                        </Button>
                                        <Button
                                            cssRules={{
                                                marginLeft: "10px",
                                            }}
                                            onClick={cancelAddActionHandler}
                                        >
                                            <Icon
                                                icon="close"
                                                ariaLabel={t("Cancel")}
                                            />
                                        </Button>
                                    </>
                                }
                            />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default DocumentAudit;
