import { ReactNode } from "react";
import { useCssClasses } from "../../../hooks";
import { DataType, formatValue } from "../../../utils/values";
import styles from "./TableCell.module.scss";

const TableCell = ({
    currentValue,
    currentRow,
    type = "string",
    render = formatValue(type),
    separator = false,
    set = () => null,
}: ITableCellProps) => {
    const cssClasses = useCssClasses(
        styles.container,
        separator ? styles.separator : "",
    );

    return (
        <td className={cssClasses}>{render(currentValue, currentRow, set)}</td>
    );
};

interface ITableCellProps {
    currentValue: any;
    currentRow: any;
    type?: DataType;
    render?: (value: any, row: any, set?: (r: any) => void) => ReactNode;
    separator?: boolean;
    set?: (row: any) => void;
}

export default TableCell;
