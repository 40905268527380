import { map } from "lodash";
import { useTranslation } from "react-i18next";
import { ITableFiltersProps } from ".";
import { ITableColumn } from "..";
import { toDateString, toTimeSpan, toTimeString } from "../../../utils/dates";
import TableFilter from "../TableFilter";

const TableFilters = ({
    filters,
    columns,
    clearFilters,
}: ITableFiltersProps) => {
    const { t } = useTranslation();

    const format = (value: string, column: ITableColumn<unknown>) => {
        switch (column.type) {
            case "date":
                return value && toDateString(new Date(value));
            case "time":
                return value && toTimeString(new Date(value));
            case "datetime":
                return (
                    value &&
                    `${toDateString(new Date(value))} ${toTimeString(
                        new Date(value),
                    )}`
                );
            case "timespan":
                return value && toTimeSpan(value);
            case "boolean": {
                if (column.getBooleanFilters) {
                    const list = column.getBooleanFilters();

                    const filter = list.filter((f) => f.value === value).pop();
                    if (filter) {
                        return filter.displayText;
                    }
                }

                return value === "true" ? t("Yes") : t("No");
            }
            default: {
                if (column.getColumnFilters) {
                    const list = column.getColumnFilters();

                    const filter = list.filter((f) => f === value).pop();

                    if (filter) {
                        return (column.filterFormatter || defaultFormatter)(
                            filter,
                        );
                    }
                }

                return (column.filterFormatter || defaultFormatter)(value);
            }
        }
    };

    const defaultFormatter = (value: string) =>
        value === "" ? "''" : value === null ? "(Blanks)" : value;

    return (
        <div>
            {map(filters, (filterGroup, property) => (
                <TableFilter
                    key={property}
                    property={property}
                    column={columns[property]}
                    filterGroup={filterGroup}
                    clearFilters={clearFilters}
                    format={format}
                />
            ))}
        </div>
    );
};

export default TableFilters;
