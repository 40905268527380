import { ISimpleProperty } from ".";
import config from "../../../config";
import useFilterable, { IFilterableParams } from "../useFilterable";

const useProperties = (
    params?: IFilterableParams,
    onRecordsLoaded?: (
        values: ISimpleProperty[],
        currentPage: number,
        totalPages: number,
    ) => void,
) => {
    const filterable = useFilterable<ISimpleProperty>(
        config.propertiesApiUrl,
        {
            params,
        },
        onRecordsLoaded,
    );

    return filterable;
};

export default useProperties;
