import { useCallback, useContext, useState } from "react";
import { noop, switchMap } from "rxjs";
import { UserContext } from "../components";
import { useUploadFile } from "../utils/api/misc";
import { useUpdateUser } from "../utils/api/users";
import useSignaturePad from "./useSignaturePad";

const useUploadUserSignature = (callback: () => void = noop) => {
    const { updateUser, loading: updateUserLoading } = useUpdateUser();
    const { uploadFile, loading: uploadFileLoading } = useUploadFile();
    const { user } = useContext(UserContext);

    const {
        canvasRef,
        clear: clearCanvas,
        isEmpty,
        getImage,
    } = useSignaturePad();
    const [isSignatureNotEmpty, setIsSignatureNotEmpty] = useState(true);
    const [hasUploadError, setHasUploadError] = useState(false);

    const uploadSignature = useCallback(() => {
        setHasUploadError(false);
        const empty = isEmpty();

        if (!empty) {
            setIsSignatureNotEmpty(true);

            getImage("signature.jpg", "image/jpeg")
                .pipe(
                    switchMap((image) =>
                        uploadFile(`user/${user.id}/${image.name}`, image),
                    ),
                    switchMap((signatureUrl) =>
                        updateUser({
                            signatureUrl: signatureUrl.filename,
                        }),
                    ),
                )
                .subscribe(
                    () => {
                        callback();
                    },
                    () => setHasUploadError(true),
                );
        } else {
            setIsSignatureNotEmpty(false);
        }
    }, [callback, getImage, isEmpty, updateUser, uploadFile, user.id]);

    return {
        uploadSignature,
        canvasRef,
        clearCanvas,
        isSignatureNotEmpty,
        hasUploadError,
        loading: updateUserLoading || uploadFileLoading,
    };
};

export default useUploadUserSignature;
