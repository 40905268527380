import { IStringAnswer } from "../../../utils/api/answers";
import OverrideDateAnswer from "./OverrideDateAnswer";

export interface IOverrideDateAnswerProps {
    answer: IStringAnswer;
    value?: string;
    overrideAnswer: (answerId: number, answer?: string) => void;
}

export default OverrideDateAnswer;
