import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { IAddComplianceRowProps } from ".";
import {
    Button,
    Form,
    Grid,
    GridColumn,
    Icon,
    Select,
} from "../../../../components";
import {
    isRequired,
    useValidateField,
    validateForm,
} from "../../../../utils/validation";
import styles from "./AddComplianceRow.module.scss";

const AddComplianceRow = ({
    complianceTypes,
    contractors,
    addRow,
}: IAddComplianceRowProps) => {
    const { t } = useTranslation();

    const [contractorId, setContractorId] = useState("");
    const [complianceId, setComplianceId] = useState("");

    const contractorIdValidator = useValidateField(contractorId, isRequired());
    const categoryIdValidator = useValidateField(complianceId, isRequired());
    const formValidator = validateForm(() => [
        contractorIdValidator,
        categoryIdValidator,
    ]);

    const handleSubmit = useCallback(() => {
        setContractorId("");
        setComplianceId("");
        contractorIdValidator.reset();
        categoryIdValidator.reset();
        addRow(complianceId, contractorId);
    }, [
        addRow,
        categoryIdValidator,
        complianceId,
        contractorId,
        contractorIdValidator,
    ]);

    return (
        <div className={styles.row}>
            <Form onSubmit={handleSubmit} {...formValidator}>
                <Grid>
                    <GridColumn size="oneQuarter">
                        <Select
                            value={complianceId}
                            onChange={setComplianceId}
                            options={complianceTypes}
                            allowEmpty={true}
                            useMargin={false}
                            size="small"
                            placeholder={t("Select compliance type")}
                            {...categoryIdValidator}
                        />
                    </GridColumn>
                    <GridColumn size="half">
                        <Select
                            value={contractorId}
                            onChange={setContractorId}
                            options={contractors}
                            allowEmpty={true}
                            useMargin={false}
                            size="small"
                            placeholder={t("Select contractor")}
                            {...contractorIdValidator}
                        />
                    </GridColumn>
                    <GridColumn size="oneQuarter">
                        <div className={styles.actions}>
                            <Button type="submit">
                                <Icon icon="plus" ariaHidden={true} />
                                &nbsp;{t("Add row")}
                            </Button>
                        </div>
                    </GridColumn>
                </Grid>
            </Form>
        </div>
    );
};

export default AddComplianceRow;
