import { useState } from "react";
import { useTranslation } from "react-i18next";
import { forkJoin } from "rxjs";
import { Button, Form, InputField, Modal, Portal } from "../../components";
import { useSendDocumentsToEmail } from "../../utils/api/lgsrs";
import {
    isEmail,
    isRequired,
    useValidateField,
    validateForm,
} from "../../utils/validation";
import ModalBody from "../Modal/ModalBody";
import ModalFooter from "../Modal/ModalFooter";

const SendLgsrsModal = ({
    paperworkIds,
    tenantEmail = "",
    hide,
}: ISendLgsrsModalProps) => {
    const { t } = useTranslation();
    const { sendEmail, loading } = useSendDocumentsToEmail();

    const [email, setEmail] = useState(tenantEmail);
    const emailValidator = useValidateField(email, isRequired(), isEmail());
    const formValidator = validateForm(() => [emailValidator]);

    const handleFormSubmit = () => {
        if (paperworkIds.length > 0) {
            forkJoin(
                paperworkIds.map((id) => sendEmail([id], email)),
            ).subscribe(() => {
                hide();
            });
        }
    };

    return (
        <Portal>
            <Form onSubmit={handleFormSubmit} {...formValidator}>
                <Modal title={t("Document")} hide={hide}>
                    <ModalBody>
                        <InputField
                            label={t(
                                "Who would you like to send the document to?",
                            )}
                            placeholder={t("Email address")}
                            value={email}
                            onChange={setEmail}
                            {...emailValidator}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button type="submit" disabled={loading}>
                            {t("Send Document")}
                        </Button>
                    </ModalFooter>
                </Modal>
            </Form>
        </Portal>
    );
};

interface ISendLgsrsModalProps {
    paperworkIds: number[];
    tenantEmail?: string;
    hide: () => void;
}

export default SendLgsrsModal;
