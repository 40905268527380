import { useCallback, useContext } from "react";
import { IReconciliationUpdateModel } from ".";
import { PropertyCategoriesContext, UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const useUpdateReconciliation = (entity: string) => {
    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);
    const { send } = useApiRequest({});
    const sendRequest = useXTagApiRequest();

    const updateReconciliation = useCallback(
        (reconciliation: IReconciliationUpdateModel) =>
            send(
                sendRequest({
                    url: `${config.reconciliationApiUrl}/${entity}`,
                    method: "PUT",
                    urlParams: getGlobalUrlParameters(
                        selectedCategories.map((c) => c.id),
                        activeUserParentsIds,
                    ),
                    body: reconciliation,
                }),
            ),
        [activeUserParentsIds, entity, selectedCategories, send, sendRequest],
    );

    return { updateReconciliation };
};

export default useUpdateReconciliation;
