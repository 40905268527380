import { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import SuperCluster from "supercluster";
import {
    ClusterMarker,
    IMapCoordinates,
    IMapMarkerOptions,
    IMapProps,
} from "..";
import clusterImage from "../../../images/map/cluster.png";
import {
    GoogleMap,
    IGoogleMapEventListener,
    IGoogleMapIcon,
    IGoogleMapOptions,
    IGoogleMapsApi,
} from "../GoogleMaps";
import MapMarker from "../MapMarker";
import styles from "./MapElement.module.scss";

const MapElement = ({
    googleMapsApi,
    lat = 53.4,
    lng = -2.97,
    minHeight,
    height = "300px",
    markers = [],
    heatmap = [],
    clusterMarkers = false,
    onMarkerDragEnd,
    children,
    zoomPosition = 9,
}: IMapElementProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const [mapOptions] = useState<IGoogleMapOptions>({
        center: { lat, lng },
        gestureHandling: "cooperative",
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        maxZoom: 17,
        zoomControlOptions: {
            position: zoomPosition,
        },
        styles: [
            {
                featureType: "poi",
                elementType: "labels",
                stylers: [{ visibility: "off" }],
            },
            {
                featureType: "transit",
                elementType: "labels",
                stylers: [{ visibility: "off" }],
            },
            {
                featureType: "road",
                elementType: "labels",
                stylers: [{ visibility: "off" }],
            },
        ],
    });

    const [map, setMap] = useState<GoogleMap | null>(null);
    useEffect(() => {
        if (ref.current) {
            setMap(new googleMapsApi.Map(ref.current, mapOptions));
        }
    }, [googleMapsApi, mapOptions]);

    const fitMapToBounds = useCallback(
        (points: IMapCoordinates[]) => {
            if (map) {
                let minLat = 90;
                let maxLat = -90;
                let minLng = 180;
                let maxLng = -180;

                for (const point of points) {
                    if (point[0] < minLng) {
                        minLng = point[0];
                    }
                    if (point[0] > maxLng) {
                        maxLng = point[0];
                    }

                    if (point[1] < minLat) {
                        minLat = point[1];
                    }
                    if (point[1] > maxLat) {
                        maxLat = point[1];
                    }
                }

                map.fitBounds({
                    north: minLat,
                    south: maxLat,
                    west: minLng,
                    east: maxLng,
                });
            }
        },
        [map],
    );

    const [superCluster] = useState(
        new SuperCluster<IMapMarkerOptions>({
            radius: 120,
        }),
    );

    const [clusters, setClusters] = useState<ClusterMarker[]>([]);
    const updateClusters = useCallback(() => {
        if (map) {
            const mapBounds = map.getBounds().toJSON();
            const bounds: GeoJSON.BBox = [
                mapBounds.west,
                mapBounds.south,
                mapBounds.east,
                mapBounds.north,
            ];

            setClusters(superCluster.getClusters(bounds, map.getZoom()));
        }
    }, [superCluster, map]);

    useEffect(() => {
        if (map) {
            let listener: IGoogleMapEventListener;

            if (clusterMarkers) {
                superCluster.load(markers);
                listener = googleMapsApi.event.addListener(
                    map,
                    "bounds_changed",
                    updateClusters,
                );
            } else {
                setClusters(markers);
            }

            fitMapToBounds(
                markers.map((marker) => marker.geometry.coordinates),
            );

            return () => {
                if (listener) {
                    listener.remove();
                }
            };
        }
    }, [
        googleMapsApi,
        map,
        superCluster,
        clusterMarkers,
        markers,
        updateClusters,
        fitMapToBounds,
    ]);

    const [markerIcon] = useState<IGoogleMapIcon>({
        size: {
            width: 30,
            height: 30,
        },
        anchor: {
            x: 15,
            y: 15,
        },
    });
    const [clusterIcon] = useState<IGoogleMapIcon>({
        url: clusterImage,
        size: {
            width: 60,
            height: 60,
        },
        anchor: {
            x: 30,
            y: 30,
        },
    });

    useEffect(() => {
        if (map && heatmap.length > 0) {
            const heatmapLayer = new googleMapsApi.visualization.HeatmapLayer({
                data: heatmap.map(
                    (point) => new googleMapsApi.LatLng(point[1], point[0]),
                ),
                map,
            });

            fitMapToBounds(heatmap);

            return () => {
                heatmapLayer.setMap(null);
            };
        }
    }, [map, googleMapsApi, heatmap, fitMapToBounds]);

    return (
        <div
            className={styles.container}
            style={{ height: height, minHeight: minHeight }}
        >
            <div
                className={styles.mapContainer}
                ref={ref}
                data-test-id="GoogleMapsWidget_Div"
            />
            {map &&
                clusters.map((cluster) => (
                    <MapMarker
                        key={cluster.id}
                        map={map}
                        superCluster={superCluster}
                        googleMapsApi={googleMapsApi}
                        clusterId={cluster.properties.cluster_id}
                        lng={cluster.geometry.coordinates[0]}
                        lat={cluster.geometry.coordinates[1]}
                        cluster={cluster.properties.cluster}
                        icon={cluster.properties.icon}
                        pointsInCluster={cluster.properties.point_count}
                        draggable={cluster.properties.draggable}
                        infoWindow={cluster.properties.infoWindow}
                        onMarkerDragEnd={onMarkerDragEnd}
                        markerIcon={markerIcon}
                        clusterIcon={clusterIcon}
                    />
                ))}

            {children}
        </div>
    );
};

interface IMapElementProps extends IMapProps {
    children?: ReactNode;
    googleMapsApi: IGoogleMapsApi;
}

export default MapElement;
