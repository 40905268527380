import { ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button, Gallery, useGallery } from "../../";
import { IAnswer } from "../../../utils/api/answers";
import { toDateString } from "../../../utils/dates";
import { Grid, GridColumn } from "../../Grid";
import styles from "./JobTimelineAnswers.module.scss";

const JobTimelineAnswers = ({ answers }: IJobTimelineAnswersProps) => {
    const parsedAnswers = useMemo(() => {
        const answerManipulate = (answer: IAnswer, collection: IAnswer[]) => {
            const payload = answer.override?.payload ?? answer.payload;

            switch (answer.type) {
                case "TextField":
                case "TextArea":
                case "AutoComplete":
                    return payload;
                case "Boolean": {
                    const textAnswer = collection
                        .filter(
                            (c) =>
                                c.questionName ===
                                `${answer.questionName}Result`,
                        )
                        .pop();

                    return (
                        textAnswer?.override?.payload ??
                        textAnswer?.payload ??
                        ""
                    );
                }
                case "MultiSelect":
                case "PartsSelect":
                    return answer.questionName.endsWith("Result")
                        ? ""
                        : payload.toString();
                case "PhotoButton":
                case "SignatureButton":
                    return payload ?? "";
                case "Reading": {
                    const item = collection
                        .filter(
                            (c) =>
                                c.questionName === `${answer.questionName}Item`,
                        )
                        .pop();

                    return item && payload !== null && payload !== 0
                        ? payload +
                              " " +
                              (item.override?.payload ?? item.payload ?? "")
                        : "";
                }
                case "DatePicker": {
                    if (!answer.questionName.endsWith("Result") && payload) {
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                        const date = new Date(payload);
                        return date.toString() !== "Invalid Date"
                            ? toDateString(date)
                            : "";
                    }

                    return "";
                }
                case "InspectionSchedule":
                case "ToggleButtonGroup": {
                    if (payload.Limitation) {
                        return `LIM - ${payload.Limitation.Description}`;
                    } else if (payload.Observation) {
                        return `OBS - ${payload.Observation.Code}`;
                    } else {
                        return payload.Text;
                    }
                }
                case "ButtonAddRepeater": {
                    return <div className={styles.multiLine}>{payload}</div>;
                }
                case "WorkHistory":
                case "ApplianceDetails":
                    return "";
                default:
                    return payload;
            }
        };

        return answers.map((answer) => ({
            ...answer,
            payload: answerManipulate(answer, answers),
        }));
    }, [answers]);

    const { currentImageIndex, setCurrentImageIndex, show, hide, visible } =
        useGallery();
    const { t } = useTranslation();

    const images = useMemo(
        () =>
            parsedAnswers
                .filter(
                    (answer) =>
                        (answer.type === "PhotoButton" ||
                            answer.type === "SignatureButton") &&
                        answer.payload !== "" &&
                        !answer.questionName.endsWith("Notes"),
                )
                .map((image) => image.payload),
        [parsedAnswers],
    );

    // TODO: Refactor this.
    const data: IAnswerData[] = useMemo(() => {
        const result: IAnswerData[] = [];

        if (parsedAnswers) {
            let imageIndex = 0;

            const openGallery = (image: number) => {
                setCurrentImageIndex(image);
                show();
            };

            parsedAnswers.forEach((answer) => {
                const index = imageIndex;
                if (answer.payload !== "") {
                    const item: IAnswerData = {
                        id: answer.id,
                        label: answer.questionText,
                        value: answer.payload,
                        render: (value) => value,
                    };

                    if (
                        (answer.type === "PhotoButton" ||
                            answer.type === "SignatureButton") &&
                        answer.payload !== "" &&
                        !answer.questionName.endsWith("Notes")
                    ) {
                        const renderButton = () => (
                            <Button onClick={openGallery} clickParams={[index]}>
                                {t("View Image")}
                            </Button>
                        );

                        item.render = renderButton;
                        imageIndex++;
                    }

                    result.push(item);
                }
            });
        }

        return result;
    }, [parsedAnswers, setCurrentImageIndex, show, t]);
    return (
        <>
            {visible && (
                <Gallery
                    images={images}
                    currentImageIndex={currentImageIndex}
                    setCurrentImageIndex={setCurrentImageIndex}
                    hide={hide}
                />
            )}

            {data.map((answer) => (
                <div className={styles.container} key={answer.id}>
                    <Grid>
                        <GridColumn size="half">
                            <div className={styles.field}>{answer.label}</div>
                        </GridColumn>
                        <GridColumn size="half">
                            <div className={styles.field}>
                                {answer.render(answer.value)}
                            </div>
                        </GridColumn>
                    </Grid>
                </div>
            ))}
        </>
    );
};

interface IAnswerData {
    id: number;
    label: string;
    value: string;
    render: (value: string) => ReactNode;
}

interface IJobTimelineAnswersProps {
    answers: IAnswer[];
}

export default JobTimelineAnswers;
