import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Loading, SelectFilter } from "../../../../components";
import { ISelectOption } from "../../../../components/Select";
import { useContractors } from "../../../../utils/api/landlords";
import { IFilterableParams } from "../../../../utils/api/useFilterable";
import { isRequired, useValidateField } from "../../../../utils/validation";

const ContractorSelect = ({
    landlordId,
    contractorId,
    setContractorId,
}: IContractorSelectProps) => {
    const { t } = useTranslation();
    const landlordFilter = useMemo<IFilterableParams>(
        () => ({
            filters: {
                ...(landlordId && {
                    landlordId: {
                        operator: "{AND}",
                        filters: [{ function: "=", value: landlordId }],
                    },
                }),
            },
        }),
        [landlordId],
    );

    const contractors = useContractors(landlordFilter);

    const contractorOptions = useMemo<ISelectOption[]>(() => {
        if (contractors.records) {
            return contractors.records.map((c) => ({
                label: c.name,
                value: c.id.toString(),
            }));
        }
        return [];
    }, [contractors.records]);

    const contractorIdValidator = useValidateField(contractorId, isRequired());

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => contractors.refresh(landlordFilter.filters), [landlordId]);

    return contractors.records ? (
        <>
            <SelectFilter
                label={t("Select a Contractor")}
                value={contractorId}
                onChange={setContractorId}
                {...contractors}
                options={contractorOptions}
                {...contractorIdValidator}
            />
            {!contractorId &&
                t(
                    "You must select both a landlord and contractor before editing the limitations.",
                )}
        </>
    ) : (
        <Loading />
    );
};

interface IContractorSelectProps {
    landlordId: string;
    contractorId: string;
    setContractorId: (value: string) => void;
}

export default ContractorSelect;
