import { IComplianceGasDashboardProps } from ".";
import { Grid, GridColumn } from "../../../components";
import ApplianceConditionCard from "../ApplianceConditionCard";
import CappedMaintenance from "../CappedMaintenance";
import CompliancePropertyMap from "../CompliancePropertyMap";
import JobsIssuesUTAsWidget from "../JobsIssuesUTAsWidget";
import NetPromoterScoreCard from "../NetPromoterScoreCard";
import NoAccessCountCard from "../NoAccessCountCard";
import PropertyStatus from "../PropertyStatus";
import RecentWorksCard from "../RecentWorksCard";
import SafetyRisks from "../SafetyRisks";
import TotalJobsAverageTimeCard from "../TotalJobsAverageTimeCard";

const ComplianceGasDashboard = ({
    rowHeight,
    dashboard,
    loaded,
    mapFeature,
}: IComplianceGasDashboardProps) => {
    return (
        <>
            <Grid>
                <GridColumn size="oneQuarter">
                    <NoAccessCountCard />
                </GridColumn>

                <GridColumn size="oneQuarter">
                    <TotalJobsAverageTimeCard
                        loaded={loaded}
                        data={dashboard}
                    />
                </GridColumn>

                <GridColumn size="oneQuarter">
                    <SafetyRisks />
                </GridColumn>

                <GridColumn size="oneQuarter">
                    <ApplianceConditionCard
                        loaded={loaded}
                        data={dashboard}
                        collapsed={true}
                    />
                </GridColumn>
            </Grid>

            <Grid>
                <GridColumn size="half">
                    <PropertyStatus />
                </GridColumn>

                <GridColumn size="half">
                    <CappedMaintenance />
                </GridColumn>
            </Grid>

            <Grid>
                <GridColumn size="oneThird">
                    <RecentWorksCard
                        loaded={loaded}
                        data={dashboard}
                        maxHeight={rowHeight}
                    />
                </GridColumn>

                <GridColumn size="oneThird">
                    <JobsIssuesUTAsWidget rowScrollHeight={rowHeight} />
                </GridColumn>

                {mapFeature && (
                    <GridColumn size="oneThird">
                        <CompliancePropertyMap />
                    </GridColumn>
                )}
            </Grid>

            <Grid>
                <GridColumn size="oneThird">
                    <NetPromoterScoreCard loaded={loaded} data={dashboard} />
                </GridColumn>
            </Grid>
        </>
    );
};

export default ComplianceGasDashboard;
