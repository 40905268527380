import { useCallback, useContext } from "react";
import { IAuditObservation } from ".";
import { PropertyCategoriesContext, UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

export const useDeleteObservation = (auditId: number) => {
    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);
    const { send, loading } = useApiRequest<IAuditObservation>({});
    const sendRequest = useXTagApiRequest();

    const deleteObservation = useCallback(
        (observationId: string) =>
            send(
                sendRequest({
                    url: `${config.auditsApiUrl}/${auditId}/observation/${observationId}`,
                    method: "DELETE",
                    urlParams: getGlobalUrlParameters(
                        selectedCategories.map((c) => c.id),
                        activeUserParentsIds,
                    ),
                }),
            ),
        [send, sendRequest, auditId, selectedCategories, activeUserParentsIds],
    );

    return { deleteObservation, loading };
};
