import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, GridColumn, Icon, InputField } from "../../../../components";
import { isRequired, useValidateField } from "../../../../utils/validation";

const LimitationRow = ({
    id,
    isFormSubmitted,
    isEditing,
    value,
    order,
    onChange,
    onRemove,
}: ILimitationRowProps) => {
    const { t } = useTranslation();

    const { error, isValid, validate } = useValidateField(value, isRequired());

    const handleChange = useCallback(
        (newValue: string) => {
            onChange(id, newValue);
            validate();
        },
        [id, onChange, validate],
    );

    const handleRemoveClick = useCallback(() => {
        onRemove(id);
    }, [id, onRemove]);

    useEffect(() => {
        if (isFormSubmitted) {
            validate();
        }
    }, [isFormSubmitted, validate]);

    return (
        <>
            <GridColumn size="tenPercent" cssRules={{ marginTop: "10px" }}>
                {order}
            </GridColumn>
            <GridColumn size="ninetyPercent" cssRules={{ marginTop: "10px" }}>
                {isEditing ? (
                    <>
                        <InputField
                            value={value}
                            onChange={handleChange}
                            placeholder={t("Limitation")}
                            isValid={isValid}
                            error={error}
                            append={
                                <Button
                                    displayAsLink={true}
                                    onClick={handleRemoveClick}
                                >
                                    <Icon
                                        icon="remove"
                                        ariaLabel={t("Remove limitation")}
                                    />
                                </Button>
                            }
                        />
                    </>
                ) : (
                    value
                )}
            </GridColumn>
        </>
    );
};

interface ILimitationRowProps {
    id: string;
    isEditing: boolean;
    order: number;
    value: string;
    isFormSubmitted: boolean;
    onChange: (id: string, value: string) => void;
    onRemove: (id: string) => void;
}

export default LimitationRow;
