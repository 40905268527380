import { useCallback, useContext } from "react";
import { PropertyCategoriesContext, UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const useGenerateAmendedLgsr = () => {
    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);
    const { send } = useApiRequest({});
    const sendRequest = useXTagApiRequest();

    const regenerateLgsr = useCallback(
        (jobId: number) =>
            send(
                sendRequest({
                    url: `${config.lgsrsApiUrl}/amendedlgsrs/${jobId}/regenerate`,
                    method: "POST",
                    urlParams: getGlobalUrlParameters(
                        selectedCategories.map((c) => c.id),
                        activeUserParentsIds,
                    ),
                }),
            ),
        [activeUserParentsIds, selectedCategories, send, sendRequest],
    );

    return { regenerateLgsr };
};

export default useGenerateAmendedLgsr;
