import { ReactNode } from "react";
import Map from "./Map";

export type IMapCoordinates = number[];

export interface IMapMarkerOptions {
    icon?: string;
    infoWindow?: string;
    draggable?: boolean;
}

export type IMapMarker = GeoJSON.Feature<GeoJSON.Point, IMapMarkerOptions>;

export interface IMapProps {
    lat?: number;
    lng?: number;
    minHeight?: number;
    height?: string;
    markers?: IMapMarker[];
    heatmap?: IMapCoordinates[];
    clusterMarkers?: boolean;
    children?: ReactNode;
    onMarkerDragEnd?: (lat: number, lng: number) => void;
    zoomPosition?: number;
}

interface IClusterOptions {
    cluster?: boolean;
    cluster_id?: number;
    point_count?: number;
    point_count_abbreviated?: string | number;
}

export type ClusterMarker = GeoJSON.Feature<
    GeoJSON.Point,
    IClusterOptions & IMapMarkerOptions
>;

export default Map;
