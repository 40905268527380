import Accordion from "./Accordion";

export interface IAccordionProps {
    content: { [key: string]: IAccordionItem };
}

export interface IAccordionItem {
    header: JSX.Element;
    content?: JSX.Element;
    expanded: boolean;
    exclusiveExpandedKey?: string;
    activePointerHandler?: (id: string) => boolean;
    expandedHandler?: (id: string, expanded: boolean) => void;
    headerRefresh?: () => void;
    // TODO: make inteface generic and pass type here
    data?: any;
    index?: number;
}

export interface IAccordionItemInternal extends IAccordionItem {
    id: string;
    toggle: (id: string, expanded: boolean) => void;
    alterState?: (state: boolean) => void;
}

export default Accordion;
