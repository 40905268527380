import { IEditableListItemFormProps } from "../../../components/EditableList/EditableListItem";
import { ISelectOption } from "../../../components/Select";
import { IPropertyCategory } from "../../../utils/api/properties";
import ComplianceTypeForm from "./ComplianceTypeForm";

export interface IComplianceTypeFormProps
    extends IEditableListItemFormProps<IPropertyCategory> {
    propertyId: number;
    propertyCategories: ISelectOption[];
}

export default ComplianceTypeForm;
