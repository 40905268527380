import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { IAddNote, IPortalPropertyNote } from ".";
import { UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const useAddNote = () => {
    const { t } = useTranslation();
    const { activeUserParentsIds } = useContext(UserContext);
    const { send } = useApiRequest<IPortalPropertyNote>({
        errorMessage: t("Failed to create note."),
    });

    const sendRequest = useXTagApiRequest();

    const addNote = useCallback(
        (data: IAddNote) =>
            send(
                sendRequest({
                    url: `${config.propertiesApiUrl}/${data.propertyId}/portalnotes`,
                    method: "POST",
                    urlParams: getGlobalUrlParameters([], activeUserParentsIds),
                    body: data,
                }),
            ),
        [activeUserParentsIds, send, sendRequest],
    );

    return { addNote };
};

export default useAddNote;
