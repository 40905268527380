import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { noop } from "rxjs";
import { Button, Icon } from "..";
import { useCssClasses } from "../../hooks";
import { addMonths } from "../../utils/dates";
import DateCell, { DateCellStyle } from "./DateCell";
import styles from "./DatePicker.module.scss";

const DatePicker = ({
    openAtDate = new Date(),
    selectedFromDate = null,
    selectedToDate = null,
    onDateSelected = noop,
    margin,
}: IDatePickerProps) => {
    const { t } = useTranslation();
    const [currentDate, setCurrentDate] = useState(openAtDate);
    useEffect(() => {
        setCurrentDate(openAtDate);
    }, [openAtDate]);

    const days = useMemo(() => {
        const month = currentDate.getMonth();
        const year = currentDate.getFullYear();

        const monthDays: IDate[][] = [[]];
        const firstDayOfMonth = new Date(year, month).getDay();
        const daysInMonth = 32 - new Date(year, month, 32).getDate();

        let weeks = 0;
        let day = 1;
        for (; day <= daysInMonth; day++) {
            if ((day + firstDayOfMonth - 1) % 7 === 0) {
                weeks++;
                monthDays.push([]);
            }

            monthDays[weeks].push({
                date: new Date(year, month, day),
                currentMonth: true,
            });
        }

        while (weeks < 6) {
            while (monthDays[weeks].length < 7) {
                monthDays[weeks].push({
                    date: new Date(year, month, day++),
                    currentMonth: false,
                });
            }

            weeks++;
            monthDays.push([]);
        }

        day = 0;
        while (monthDays[0].length < 7) {
            monthDays[0].unshift({
                date: new Date(year, month, day--),
                currentMonth: false,
            });
        }

        return monthDays;
    }, [currentDate]);

    const handlePreviousMonthClick = () =>
        setCurrentDate(addMonths(currentDate, -1));
    const handleNextMonthClick = () =>
        setCurrentDate(addMonths(currentDate, 1));

    const areDatesEqual = (date: Date, otherDate: Date | null) =>
        otherDate && date.getTime() === otherDate.getTime();

    const getCellStyle = (date: Date): DateCellStyle => {
        if (
            areDatesEqual(date, selectedFromDate) ||
            areDatesEqual(date, selectedToDate)
        ) {
            return "blue";
        }

        return selectedFromDate &&
            selectedToDate &&
            date > selectedFromDate &&
            date < selectedToDate
            ? "lightBlue"
            : "";
    };

    const cssClasses = useCssClasses(
        styles.container,
        margin ? styles.margin : "",
    );

    return (
        <div className={cssClasses}>
            <div className={styles.header}>
                <Button size="small" onClick={handlePreviousMonthClick}>
                    <Icon icon="caret-left" ariaLabel={t("Previous month")} />
                </Button>
                {t(`months.${currentDate.getMonth()}`)}&nbsp;
                {currentDate.getFullYear()}
                <Button size="small" onClick={handleNextMonthClick}>
                    <Icon icon="caret-right" ariaLabel={t("Next month")} />
                </Button>
            </div>

            <table className={styles.table}>
                <thead>
                    <tr>
                        {[...Array(7)].map((_, index) => (
                            <th key={index} className={styles.headerCell}>
                                {t(`daysShort.${index}`)}
                            </th>
                        ))}
                    </tr>
                </thead>

                <tbody>
                    {days.map((week, index) => (
                        <tr key={index}>
                            {week.map((day) => (
                                <DateCell
                                    key={day.date.getTime()}
                                    date={day.date}
                                    currentMonth={day.currentMonth}
                                    onClick={onDateSelected}
                                    style={getCellStyle(day.date)}
                                />
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

interface IDate {
    date: Date;
    currentMonth: boolean;
}

interface IDatePickerProps {
    openAtDate?: Date;
    selectedFromDate?: Date | null;
    selectedToDate?: Date | null;
    onDateSelected?: (date: Date) => void;
    margin?: boolean;
}

export default DatePicker;
