import { useCallback, useContext } from "react";
import { IAnswerOverride } from ".";
import { PropertyCategoriesContext, UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const useOverrideAnswers = (auditId: number) => {
    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);
    const { send, loading } = useApiRequest({});
    const sendRequest = useXTagApiRequest();

    const overrideAnswers = useCallback(
        (answers: IAnswerOverride[]) =>
            send(
                sendRequest({
                    url: `${config.auditsApiUrl}/${auditId}/answers`,
                    method: "POST",
                    urlParams: getGlobalUrlParameters(
                        selectedCategories.map((c) => c.id),
                        activeUserParentsIds,
                    ),
                    body: answers,
                }),
            ),
        [activeUserParentsIds, auditId, selectedCategories, send, sendRequest],
    );

    return { overrideAnswers, loading };
};

export default useOverrideAnswers;
