import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Popover, UserContext } from "../..";
import { Img } from "../../../components";
import PopoverItem from "../../../components/Popover/PopoverItem";
import { useToggle } from "../../../hooks";
import { useAuthorizedImage } from "../../../hooks/useAuthorizedImage";
import useClickableNonInteractiveElement from "../../../hooks/useClickableNonInteractiveElement";
import defaultImage from "../../../images/default-profile.svg";
import styles from "./UserProfile.module.scss";

const UserProfile = () => {
    const { t } = useTranslation();
    const { user } = useContext(UserContext);
    const imageUrl = useAuthorizedImage(user.profileImageUrl);

    const userProfileButton = useRef<HTMLDivElement>(null);
    const { toggle, hide, visible } = useToggle();
    const { onKeyDown } = useClickableNonInteractiveElement(toggle);

    const { logout } = useAuth0();
    const handleLogoutClick = useCallback(() => {
        logout({ returnTo: window.location.origin });
    }, [logout]);

    return (
        <div
            className={styles.container}
            ref={userProfileButton}
            onClick={toggle}
            onKeyDown={onKeyDown}
            role="button"
            tabIndex={0}
            data-test-id="UserProfile_Dropdown"
        >
            <Img size={37} circle={true} url={imageUrl || defaultImage} />

            {visible && (
                <Popover
                    applyPadding={false}
                    anchorElement={userProfileButton}
                    hide={hide}
                >
                    <PopoverItem>
                        <div className={styles.name}>{user.name}</div>
                        <div className={styles.email}>{user.email}</div>
                    </PopoverItem>

                    <PopoverItem url="/user">
                        {t("Account & settings")}
                    </PopoverItem>

                    <PopoverItem onClick={handleLogoutClick}>
                        {t("Sign out")}
                    </PopoverItem>
                </Popover>
            )}
        </div>
    );
};

export default UserProfile;
