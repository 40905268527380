import { useTranslation } from "react-i18next";
import { Backdrop, Button, Icon } from "..";
import { useAuthorizedImage } from "../../hooks/useAuthorizedImage";
import styles from "./Gallery.module.scss";
import Thumbnail from "./Thumbnail";

const Gallery = ({
    images,
    hide,
    currentImageIndex,
    setCurrentImageIndex,
}: IGalleryProps) => {
    const { t } = useTranslation();
    const currentImage = useAuthorizedImage(images[currentImageIndex]);

    return (
        <Backdrop>
            <div className={styles.container}>
                <img
                    src={currentImage}
                    alt={t("gallery")}
                    className={styles.image}
                />
            </div>

            <div className={styles.thumbnails}>
                {images.map((image, index) => (
                    <Thumbnail
                        key={image}
                        imageUrl={image}
                        onClick={setCurrentImageIndex}
                        clickParams={[index]}
                        isCurrent={index === currentImageIndex}
                    />
                ))}
            </div>

            <div className={styles.caption}>
                {currentImageIndex + 1} {t("of")} {images.length}
            </div>

            {currentImageIndex > 0 && (
                <div className={`${styles.arrowContainer} ${styles.left}`}>
                    <Button
                        onClick={setCurrentImageIndex}
                        clickParams={[currentImageIndex - 1]}
                        displayAsLink={true}
                        color="white"
                    >
                        <Icon
                            icon="chevron-left"
                            size={50}
                            ariaLabel={t("Previous image")}
                        />
                    </Button>
                </div>
            )}

            {currentImageIndex + 1 < images.length && (
                <div className={`${styles.arrowContainer} ${styles.right}`}>
                    <Button
                        onClick={setCurrentImageIndex}
                        clickParams={[currentImageIndex + 1]}
                        displayAsLink={true}
                        color="white"
                    >
                        <Icon
                            icon="chevron-right"
                            size={50}
                            ariaLabel={t("Next image")}
                        />
                    </Button>
                </div>
            )}

            <div className={styles.closeContainer}>
                <Button onClick={hide} displayAsLink={true} color="white">
                    <Icon icon="close" ariaLabel={t("Close")} size={50} />
                </Button>
            </div>
        </Backdrop>
    );
};

export interface IGalleryProps {
    images: string[];
    currentImageIndex: number;
    setCurrentImageIndex: (index: number) => void;
    hide: () => void;
}

export default Gallery;
