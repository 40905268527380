import { useContext, useEffect } from "react";
import { filter, map } from "rxjs";
import { RealtimeContext } from "../../components";
import { clearCache } from "../../utils/cache";

const usePropertiesTagRealtime = (
    propertyIds: number[],
    tagCreated: (propertyId: number) => void,
) => {
    const { realtimeObservable } = useContext(RealtimeContext);

    // Realtime updates
    useEffect(() => {
        const subscription = realtimeObservable
            .pipe(
                filter((e) => e.event === "created" && e.entity === "tag"),
                map((e) => e.payload as ITag),
                filter((e) => propertyIds.some((p) => p === e.propertyId)),
            )
            .subscribe((value) => {
                clearCache();
                tagCreated(value.propertyId);
            });

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [propertyIds, realtimeObservable, tagCreated]);
};

interface ITag {
    id: number;
    tagString: string;
    propertyId: number;
}

export default usePropertiesTagRealtime;
