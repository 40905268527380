import { useCallback, useContext } from "react";
import { IJobTask } from ".";
import { PropertyCategoriesContext, UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiValue from "../useApiValue";

const useJobTasks = () => {
    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);
    const { value, loaded, loading, send } = useApiValue<IJobTask[]>({});
    const sendRequest = useXTagApiRequest();

    const getTasks = useCallback(
        (id: number) =>
            send(
                sendRequest({
                    url: `${config.jobsApiUrl}/${id}/tasks`,
                    method: "GET",
                    urlParams: getGlobalUrlParameters(
                        selectedCategories.map((c) => c.id),
                        activeUserParentsIds,
                    ),
                }),
            ),
        [activeUserParentsIds, selectedCategories, send, sendRequest],
    );

    return { value, loaded, loading, getTasks };
};

export default useJobTasks;
