import { useMemo } from "react";
import { IReconciliationLookup } from ".";
import useAllFilterable from "../useAllFilterable";
import config from "../../../config";

const useReconciliationLookups = (entity: string) => {
    const sortProperties = useMemo(() => ["name"], []);

    const filterable = useAllFilterable<IReconciliationLookup>({
        url: `${config.reconciliationApiUrl}/${entity}/lookups`,
        sortProperties,
    });

    return filterable;
};

export default useReconciliationLookups;
