import { debounce } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Button,
    Card,
    Grid,
    GridColumn,
    Icon,
    InfiniteScroll,
    InputField,
    Loading,
    PropertyMap,
    Select,
} from "../../components";
import { ISelectOption } from "../../components/Select";
import { useToggle } from "../../hooks";
import {
    ISimpleProperty,
    useMapProperties,
    useProperties,
} from "../../utils/api/properties";
import { IFilterToggle, SortDirection } from "../../utils/api/useFilterable";
import styles from "./PropertiesMap.module.scss";
import PropertyGridItem from "./PropertyGridItem";
import PropertyListItem from "./PropertyListItem";
import usePropertiesTagRealtime from "./usePropertiesTagRealtime";
import { usePropertyMapFeature } from "./usePropertiesmapFeature";

const PropertiesMap = () => {
    const { t } = useTranslation();

    const {
        show: gridShow,
        hide: gridHide,
        visible: gridVisible,
    } = useToggle(true);
    const { toggle: moreFiltersToggle, visible: moreFiltersVisible } =
        useToggle();

    const [sortDirection, setSortDirection] = useState<SortDirection>("asc");

    const [sortProperty, setSortProperty] = useState("id");
    const orderByOptions = useMemo<ISelectOption[]>(
        () => [
            { label: t("Id"), value: "id" },
            { label: t("Address"), value: "addressString" },
        ],
        [t],
    );

    const [activeStatus, setActiveStatus] = useState("");
    const activeOptions = useMemo<ISelectOption[]>(
        () => [
            { label: t("All"), value: "" },
            { label: t("Active"), value: "true" },
            { label: t("Inactive"), value: "false" },
        ],
        [t],
    );

    const [propertiesList, setPropertiesList] = useState<ISimpleProperty[]>([]);
    const onRecordsLoaded = useCallback(
        (values: ISimpleProperty[], currentPage: number) => {
            const newRecords = currentPage === 1 ? [] : [...propertiesList];

            newRecords.push(...values);

            setPropertiesList(newRecords);
        },
        [propertiesList],
    );
    const {
        applySearch,
        records,
        sort,
        totalPages,
        currentPage,
        goToNextPage,
        toggleFilter,
        loading,
    } = useProperties(
        {
            sortProperty,
            sortDirection,
        },
        onRecordsLoaded,
    );
    const {
        applySearch: mapPropertiesApplySearch,
        loaded: mapPropertiesLoaded,
        toggleFilter: mapPropertiesToggleFilter,
        records: mapPropertiesRecords,
    } = useMapProperties();

    const propertyIds = useMemo(() => records.map((m) => m.id), [records]);

    const [propertyTags, setPropertyTags] = useState<{
        [key: number]: boolean;
    }>({});

    const propertyTagRegistered = useCallback((propertyId: number) => {
        setPropertyTags((p) => ({ ...p, [propertyId]: true }));
    }, []);

    usePropertiesTagRealtime(propertyIds, propertyTagRegistered);

    const handleSearchChange = useMemo(
        () =>
            debounce((query: string) => {
                applySearch(query);
                mapPropertiesApplySearch(query);
            }, 300),
        [applySearch, mapPropertiesApplySearch],
    );

    const handleOrderByChange = (orderBy: string) => {
        setSortProperty(orderBy);
        sort(orderBy, sortDirection);
    };

    const handleSortDirectionClick = () => {
        const direction = sortDirection === "asc" ? "desc" : "asc";
        setSortDirection(direction);
        sort(sortProperty, direction);
    };

    const handleActiveChange = (active: string) => {
        setActiveStatus(active);

        const filter: IFilterToggle = {
            property: "active",
            filterGroup: {
                operator: "{AND}",
                filters: [],
            },
            appendFilters: false,
        };
        if (active) {
            filter.filterGroup.filters.push({ function: "=", value: active });
        }

        if (active) {
            toggleFilter([filter]);
            mapPropertiesToggleFilter([filter]);
        }
    };

    // TODO: Create an env variable and get this feature flag from there XT-1142.
    const mapFeature = true;
    const { map } = usePropertyMapFeature(mapFeature, mapPropertiesRecords);

    const loadNextPage = () => {
        if (currentPage < totalPages) {
            goToNextPage();
        }
    };

    const propertiesGrid = useMemo<ISimpleProperty[][]>(() => {
        const list: ISimpleProperty[][] = [];

        for (let i = 0; i < propertiesList.length; i++) {
            const index1 = Math.floor(i / 2);
            const index2 = i % 2;

            if (!list[index1]) {
                list[index1] = [];
            }

            list[index1][index2] = propertiesList[i];
        }

        return list;
    }, [propertiesList]);

    return mapPropertiesLoaded ? (
        <Grid fullHeight={true}>
            <GridColumn size="fiveOverTwelve" fullHeight={true}>
                <div className={styles.filtersContainer}>
                    <div className={styles.flexNoShrinkContainer}>
                        <InputField
                            onChange={handleSearchChange}
                            placeholder={t("Filter")}
                        />

                        <div className={styles.filtersHeaderContainer}>
                            <div className={styles.flexGrowContainer}>
                                <Button onClick={moreFiltersToggle}>
                                    {moreFiltersVisible
                                        ? t("Hide filters")
                                        : t("Show more filters")}
                                </Button>
                            </div>

                            <div className={styles.flexNoShrinkContainer}>
                                <Button
                                    onClick={gridShow}
                                    disabled={gridVisible}
                                    cssRules={{ marginRight: "10px" }}
                                >
                                    <Icon
                                        icon="four-squares"
                                        size={16}
                                        ariaLabel={t("Grid view")}
                                    />
                                </Button>
                                <Button
                                    onClick={gridHide}
                                    disabled={!gridVisible}
                                >
                                    <Icon
                                        icon="list"
                                        size={16}
                                        ariaLabel={t("List view")}
                                    />
                                </Button>
                            </div>
                        </div>

                        {moreFiltersVisible && (
                            <>
                                <div className={styles.moreFiltersContainer}>
                                    <div className={styles.flexGrowContainer}>
                                        <Select
                                            label={t("Order by")}
                                            value={sortProperty}
                                            onChange={handleOrderByChange}
                                            options={orderByOptions}
                                        />
                                    </div>

                                    <div
                                        className={styles.flexNoShrinkContainer}
                                    >
                                        <Button
                                            onClick={handleSortDirectionClick}
                                            cssRules={{ marginLeft: "10px" }}
                                        >
                                            {sortDirection === "asc" ? (
                                                <Icon
                                                    icon="chevron-down"
                                                    ariaLabel={t(
                                                        "Sort descending",
                                                    )}
                                                    size={16}
                                                />
                                            ) : (
                                                <Icon
                                                    icon="chevron-up"
                                                    ariaLabel={t(
                                                        "Sort ascending",
                                                    )}
                                                    size={16}
                                                />
                                            )}
                                        </Button>
                                    </div>
                                </div>

                                <Select
                                    label={t("Active status")}
                                    value={activeStatus}
                                    onChange={handleActiveChange}
                                    options={activeOptions}
                                />
                            </>
                        )}
                    </div>

                    <div className={styles.propertiesContainer}>
                        <InfiniteScroll
                            loading={loading}
                            onScrollToBottom={loadNextPage}
                        >
                            {gridVisible
                                ? propertiesGrid.map((row, index) => (
                                      <div className={styles.row} key={index}>
                                          {row.map((property) => (
                                              <div
                                                  className={styles.column}
                                                  key={property.id}
                                              >
                                                  <PropertyGridItem
                                                      property={property}
                                                      tagCreated={
                                                          propertyTags[
                                                              property.id
                                                          ]
                                                      }
                                                  />
                                              </div>
                                          ))}
                                      </div>
                                  ))
                                : propertiesList.map((property) => (
                                      <PropertyListItem
                                          key={property.id}
                                          property={property}
                                      />
                                  ))}
                        </InfiniteScroll>
                    </div>
                </div>
            </GridColumn>
            {mapFeature && (
                <GridColumn size="sevenOverTwelve" fullHeight={true}>
                    <Card
                        padding={false}
                        withMargin={false}
                        fullHeight={true}
                        flexBasis={true}
                    >
                        <PropertyMap properties={map} />
                    </Card>
                </GridColumn>
            )}
        </Grid>
    ) : (
        <Loading />
    );
};

export default PropertiesMap;
