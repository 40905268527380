import { IIntegrationFailures } from ".";
import config from "../../../config";
import useFilterable from "../useFilterable";

const useIntegrationFailures = (requeued: boolean) => {
    const filterable = useFilterable<IIntegrationFailures>(
        `${config.miscApiUrl}/integration/failures`,
        {
            params: {
                filters: {
                    requeued: {
                        operator: "{AND}",
                        readOnly: true,
                        filters: [
                            {
                                function: "=",
                                value: requeued ? "true" : "false",
                            },
                        ],
                    },
                },
            },
        },
    );

    return filterable;
};

export default useIntegrationFailures;
