import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { IEditableListProps } from ".";
import { Button } from "..";
import { useToggle } from "../../hooks";
import styles from "./EditableList.module.scss";
import EditableListItem from "./EditableListItem";

function EditableList<T>({
    deleteModalTitle,
    deleteModalMessage,
    entities,
    getEntityId,
    onCreate,
    onUpdate,
    onDelete,
    deleteRequest,
    renderForm,
    renderView,
    emptyMessage,
    canEdit = true,
}: IEditableListProps<T>) {
    const { t } = useTranslation();
    const { visible, show, hide } = useToggle();

    const [editingEntities, setEditingEntities] = useState<{
        [key: string]: boolean;
    }>({});
    const handleEditClick = useCallback((id: string) => {
        setEditingEntities((value) => ({
            ...value,
            [id]: true,
        }));
    }, []);
    const handleCancelClick = useCallback(
        (id: string) => {
            if (id) {
                setEditingEntities((value) => ({
                    ...value,
                    [id]: false,
                }));
            } else {
                hide();
            }
        },
        [hide],
    );

    const handleCreate = useCallback(
        (entity: T) => {
            onCreate(entity);
            hide();
        },
        [onCreate, hide],
    );

    const handleUpdate = useCallback(
        (entity: T) => {
            onUpdate(entity);
            setEditingEntities(() => ({}));
        },
        [onUpdate],
    );

    return (
        <>
            {canEdit && !visible && (
                <div className={styles.actions}>
                    <Button onClick={show}>{t("Add")}</Button>
                </div>
            )}

            {visible ? (
                <EditableListItem
                    onSave={handleCreate}
                    onCancel={handleCancelClick}
                    isEditing={canEdit}
                    getEntityId={getEntityId}
                    deleteRequest={deleteRequest}
                    renderForm={renderForm}
                    renderView={renderView}
                    deleteModalTitle={deleteModalTitle}
                    deleteModalMessage={deleteModalMessage}
                    canEdit={canEdit}
                />
            ) : (
                entities.length === 0 && emptyMessage
            )}

            {entities.map((entity) => (
                <EditableListItem
                    key={getEntityId(entity)}
                    entity={entity}
                    isEditing={editingEntities[getEntityId(entity)]}
                    onSave={handleUpdate}
                    onEdit={handleEditClick}
                    onCancel={handleCancelClick}
                    onDelete={onDelete}
                    deleteRequest={deleteRequest}
                    renderForm={renderForm}
                    renderView={renderView}
                    getEntityId={getEntityId}
                    deleteModalTitle={deleteModalTitle}
                    deleteModalMessage={deleteModalMessage}
                    canEdit={canEdit}
                />
            ))}
        </>
    );
}

export default EditableList;
