import { useContext, useEffect } from "react";
import { filter, map } from "rxjs";
import { ISimpleAppliance } from ".";
import {
    PropertyCategoriesContext,
    RealtimeContext,
    UserContext,
} from "../../../components";
import config from "../../../config";
import { clearCache } from "../../cache";
import { realtimeCreateList, realtimeUpdateList } from "../../realtime";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import { IProperty } from "../properties";
import useApiValue from "../useApiValue";
import { IFilterable } from "../useFilterable";

const useAppliancesAtProperty = (propertyId: number) => {
    const { realtimeObservable } = useContext(RealtimeContext);

    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);
    const { value, loaded, send, updateValue } = useApiValue<
        IFilterable<ISimpleAppliance>
    >({
        initialValue: {
            totalResults: 0,
            totalPages: 0,
            results: [],
        },
    });
    const sendRequest = useXTagApiRequest();

    useEffect(() => {
        const subscription = send(
            sendRequest({
                url: config.appliancesApiUrl,
                method: "GET",
                urlParams: {
                    propertyId: [propertyId.toString()],
                    active: ["true"],
                    pageSize: ["1000"],
                    ...getGlobalUrlParameters(
                        selectedCategories.map((c) => c.id),
                        activeUserParentsIds,
                    ),
                },
            }),
        ).subscribe();

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [
        propertyId,
        selectedCategories,
        activeUserParentsIds,
        send,
        sendRequest,
    ]);

    useEffect(() => {
        if (value.results.length > 0) {
            const subscription = realtimeObservable
                .pipe(
                    filter(
                        (e) => e.entity === "property" && e.event === "updated",
                    ),
                    map((e) => e.payload as IProperty),
                )
                .subscribe((newValue) => {
                    const appliances = [...value.results];

                    for (const appliance of appliances) {
                        if (appliance.property.id === newValue.id) {
                            appliance.property = {
                                id: newValue.id,
                                addressString: newValue.addressString,
                                uprn: newValue.uprn,
                                landlord: {
                                    id: newValue.landlord.id,
                                    userId: newValue.landlord.userId,
                                },
                            };
                        }
                    }

                    updateValue({
                        ...value,
                        results: appliances,
                    });
                    clearCache();
                });

            return () => {
                if (subscription) {
                    subscription.unsubscribe();
                }
            };
        }
    }, [realtimeObservable, updateValue, value]);

    useEffect(() => {
        if (value) {
            const subscription = realtimeObservable
                .pipe(
                    filter(
                        (e) =>
                            e.entity === "appliance" && e.event === "created",
                    ),
                    map((e) => e.payload as ISimpleAppliance),
                    filter(
                        (e) =>
                            e.property !== null && e.property.id === propertyId,
                    ),
                )
                .subscribe((newValue) => {
                    const updatedList = realtimeCreateList(
                        newValue,
                        value.results,
                    );

                    if (updatedList) {
                        updateValue({
                            ...value,
                            results: updatedList,
                        });
                    }

                    clearCache();
                });

            return () => {
                if (subscription) {
                    subscription.unsubscribe();
                }
            };
        }
    }, [value, propertyId, realtimeObservable, updateValue]);

    useEffect(() => {
        if (value) {
            const subscription = realtimeObservable
                .pipe(
                    filter(
                        (e) =>
                            e.entity === "appliance" && e.event === "updated",
                    ),
                    map((e) => e.payload as ISimpleAppliance),
                    filter(
                        (e) =>
                            e.property !== null && e.property.id === propertyId,
                    ),
                )
                .subscribe((newValue) => {
                    const updatedList = realtimeUpdateList(
                        newValue,
                        value.results,
                    );

                    if (updatedList) {
                        updateValue({
                            ...value,
                            results: updatedList,
                        });
                    }

                    clearCache();
                });

            return () => {
                if (subscription) {
                    subscription.unsubscribe();
                }
            };
        }
    }, [value, propertyId, realtimeObservable, updateValue]);

    return { value, loaded };
};

export default useAppliancesAtProperty;
