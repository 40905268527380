import { useCallback, useEffect, useMemo } from "react";
import { IDocument } from ".";
import config from "../../../config";
import useFilterable, { IFilters, SortDirection } from "../useFilterable";

const useDocuments = ({
    propertyId,
    jobPropertyId,
    jobId,
    sortProperty = "createdAt",
    sortDirection = "desc",
    disableFuelTypeFiltering = false,
}: IUseDocuments) => {
    const filter = useMemo<IFilters>(() => {
        return {
            ...(propertyId && {
                propertyId: {
                    operator: "{AND}",
                    readOnly: true,
                    filters: [{ function: "=", value: propertyId.toString() }],
                },
            }),
            ...(jobPropertyId && {
                "job.propertyId": {
                    operator: "{AND}",
                    readOnly: true,
                    filters: [
                        { function: "=", value: jobPropertyId.toString() },
                    ],
                },
            }),
            ...(jobId && {
                jobId: {
                    operator: "{AND}",
                    readOnly: true,
                    filters: [{ function: "=", value: jobId.toString() }],
                },
            }),
        };
    }, [jobId, jobPropertyId, propertyId]);

    const {
        records,
        loaded,
        refresh: filterableRefresh,
    } = useFilterable<IDocument>(`${config.lgsrsApiUrl}/documents/v2`, {
        params: {
            sortProperty,
            sortDirection,
            itemsPerPage: 100,
            filters: filter,
        },
        disableFuelTypeFiltering,
    });

    const refresh = useCallback(() => {
        filterableRefresh(filter);
    }, [filter, filterableRefresh]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    return { records, loaded, refresh };
};

interface IUseDocuments {
    propertyId?: string;
    jobPropertyId?: string;
    jobId?: string;
    sortProperty?: string;
    sortDirection?: SortDirection;
    disableFuelTypeFiltering?: boolean;
}

export default useDocuments;
