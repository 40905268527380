import { MouseEvent, ReactNode, useRef } from "react";
import { noop } from "rxjs";
import { useCssClasses } from "../../hooks";
import styles from "./Backdrop.module.scss";

const Backdrop = ({
    children,
    alignCenter = false,
    hide = noop,
    hideOnClick = false,
}: IBackdropProps) => {
    const cssClasses = useCssClasses(
        styles.backdrop,
        alignCenter ? styles.alignCenter : "",
    );

    const backdropElement = useRef<HTMLDivElement>(null);
    const handleBackdropClick = (event: MouseEvent<HTMLDivElement>) => {
        if (hideOnClick && backdropElement.current === event.target) {
            hide();
        }
    };

    return (
        <div
            className={cssClasses}
            onClick={handleBackdropClick}
            ref={backdropElement}
            aria-hidden="true"
        >
            {children}
        </div>
    );
};

interface IBackdropProps {
    children: ReactNode;
    alignCenter?: boolean;
    hide?: () => void;
    hideOnClick?: boolean;
}

export default Backdrop;
