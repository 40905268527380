import { Fragment } from "react";
import { INote } from ".";
import { Link } from "..";
import { toDateTimeString } from "../../utils/dates";
import styles from "./Notes.module.scss";

const Notes = ({ notes }: INotesProps) => {
    return (
        <>
            {notes.map((note, index) => (
                <Fragment key={index}>
                    <div className={styles.note}>{note.text}</div>

                    <div className={styles.noteDetails}>
                        {note.url ? (
                            <Link url={note.url}>{note.subtitle}</Link>
                        ) : (
                            <span>{note.subtitle}</span>
                        )}

                        <span className={styles.noteDate}>
                            {note.date && toDateTimeString(new Date(note.date))}
                        </span>
                    </div>
                </Fragment>
            ))}
        </>
    );
};

interface INotesProps {
    notes: INote[];
}

export default Notes;
