import { IAnswerOverride, IAudit } from "../../../utils/api/audits";
import { IAnswerSection } from "../QualifiedSupervisorAudit";
import QualifiedSupervisorAuditsSaveModal from "./QualifiedSupervisorAuditsSaveModal";

export interface IQuestion {
    id: string;
    section: string;
    question: string;
    originalAnswer: string;
    overriddenAnswer: string;
}

export interface IQualifiedSupervisorAuditsSaveModalProps {
    audit: IAudit;
    overriddenAnswers: IAnswerOverride[];
    answersSections: IAnswerSection[];
    onSaveAudit: () => void;
    hide: () => void;
    canCompleteAudit?: boolean;
}

export default QualifiedSupervisorAuditsSaveModal;
