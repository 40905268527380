import { useEffect } from "react";
import { combineLatest, map } from "rxjs";
import { IQuestion } from ".";
import config from "../../../config";
import { useXTagApiRequest } from "../../request";
import useApiValue from "../useApiValue";

export const useQuestions = (questionSetIds: string[]) => {
    const { send, value, loaded } = useApiValue<IQuestion[]>({
        initialValue: [],
    });
    const sendRequest = useXTagApiRequest();

    useEffect(() => {
        if (!loaded) {
            const subscription = send(
                combineLatest(
                    questionSetIds.map((questionSetId) =>
                        sendRequest<IQuestion[], unknown>({
                            url: `${config.answersApiUrl}/questions/${questionSetId}`,
                            method: "GET",
                        }),
                    ),
                ).pipe(map((result) => result.flatMap((list) => list))),
            ).subscribe();

            return () => {
                if (subscription) {
                    subscription.unsubscribe();
                }
            };
        }
    }, [loaded, questionSetIds, send, sendRequest]);

    return { value, loaded };
};
