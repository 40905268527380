import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { UserPreferencesContext } from "../components";
import { IFilterGroup } from "../components/DashboardWidgetFilter";
import { IComplianceDashboard } from "../utils/api/misc";
import { encodeUrl } from "../utils/url";

const useJobLevelWidgetFilter = (
    preferenceKey:
        | "total_jobs_average_time_job_level"
        | "average_job_time_job_level"
        | "total_jobs_job_level",
    data?: IComplianceDashboard,
) => {
    const { t } = useTranslation();

    const { preferences, updatePreferences } = useContext(
        UserPreferencesContext,
    );

    const jobLevel = useMemo(() => preferences[preferenceKey] || "jobs", [
        preferenceKey,
        preferences,
    ]);

    const filters = useMemo(() => {
        const changeJobLevel = (value: string) => {
            updatePreferences({
                ...preferences,
                [preferenceKey]: value,
            }).subscribe();
        };

        const list: IFilterGroup[] = [
            {
                id: "job_level",
                title: t("Job level"),
                value: jobLevel,
                changeValue: changeJobLevel,
                allowEmptyValue: false,
                options: [
                    { label: t("Jobs"), value: "jobs" },
                    { label: t("Sub jobs"), value: "sub-jobs" },
                ],
            },
        ];

        return list;
    }, [jobLevel, preferenceKey, preferences, t, updatePreferences]);

    const url = useMemo(
        () =>
            encodeUrl("/jobs/jobs", {
                activeTab: [jobLevel],
            }),
        [jobLevel],
    );

    const [totalJobs, jobTimes] = useMemo(() => {
        if (jobLevel === "sub-jobs") {
            return [data?.totalSubJobs ?? [], data?.averageSubJobTimes ?? []];
        }

        return [data?.totalJobs ?? [], data?.averageJobTimes ?? []];
    }, [jobLevel, data]);

    return { filters, url, totalJobs, jobTimes };
};

export default useJobLevelWidgetFilter;
