import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { BaseAppliance } from ".";
import { IEditableDetailsData } from "../../components/EditableDetailsTable";
import { IAlarmAppliance, IAppliance } from "../../utils/api/appliances";
import { toDateString } from "../../utils/dates";

const AlarmAppliance = ({ appliance }: IAlarmApplianceProps) => {
    const { t } = useTranslation();

    const getColumns = useCallback(() => {
        const detailTableColumns: IEditableDetailsData[] = [
            {
                title: t("Make"),
                type: "string",
                path: "make",
            },
            {
                title: t("Model"),
                type: "string",
                path: "model",
            },
            {
                title: t("Expiry Date"),
                type: "date",
                path: "expiryDate",
                render: (value: string) => {
                    return value ? toDateString(new Date(value)) : "";
                },
            },
        ];

        return detailTableColumns;
    }, [t]);

    const patchUpdateModel = useCallback((model: IAppliance) => {
        const alarmAppliance = model as IAlarmAppliance;

        return alarmAppliance;
    }, []);

    return (
        <BaseAppliance
            appliance={appliance}
            getColumns={getColumns}
            patchUpdateModel={patchUpdateModel}
        />
    );
};

interface IAlarmApplianceProps {
    appliance: IAlarmAppliance;
}

export default AlarmAppliance;
