import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IServiceDateResetApprovalTableProps } from ".";
import {
    ColourPill,
    Icon,
    JobPaperworkDropdown,
    JobType,
    Link,
    Loading,
    Portal,
} from "../../../components";
import Dropdown, { IDropdownItem } from "../../../components/Dropdown";
import Table, { IBulkAction, ITableColumn } from "../../../components/Table";
import Toast from "../../../components/Toast";
import { useToast, useToggle } from "../../../hooks";
import { IEngineer } from "../../../utils/api/engineers";
import {
    IComplianceType,
    IManualUploadResetApproval,
    ISimplePaperwork,
    useManualUploadResetApprovals,
} from "../../../utils/api/jobs";
import { ISimpleProperty } from "../../../utils/api/properties";
import ServiceDateApprovalModal from "../ServiceDateApprovalModal";
import useQueryString from "../../../hooks/useQueryString";
import useDownloadCsv from "../../../hooks/useDownloadCsv";
import config from "../../../config";
import DownloadCsvModal from "../../../components/DownloadCsvModal";

const ServiceDateResetApprovalTable = ({
    type,
}: IServiceDateResetApprovalTableProps) => {
    const { t } = useTranslation();
    const toast = useToast();

    const resetApprovals = useManualUploadResetApprovals({ type });

    const { search, filters } = resetApprovals;

    const { show, hide, visible } = useToggle();

    const [ids, setIds] = useState<number[]>([]);

    const [updateType, setUpdateType] = useState<
        "approved" | "decline" | undefined
    >();

    const approvalModalHandler = useCallback(
        (selectedIds: number[], selectedType: "approved" | "decline") => {
            setUpdateType(selectedType);
            setIds(selectedIds);
            show();
        },
        [show],
    );

    const updatedHandler = useCallback(() => {
        toast.show();
        resetApprovals.refresh();
    }, [resetApprovals, toast]);

    const bulkActions = useMemo<IBulkAction[] | undefined>(() => {
        const actions: IBulkAction[] = [];

        if (["declined", "approved"].includes(type)) {
            return;
        }

        if (type === "waiting") {
            actions.push({
                value: "approve",
                label: t("Approve selected"),
                onSubmit: (selectedIds: number[]) =>
                    approvalModalHandler(selectedIds, "approved"),
            });
        }

        actions.push({
            value: "decline",
            label: t("Decline selected"),
            onSubmit: (selectedIds: number[]) =>
                approvalModalHandler(selectedIds, "decline"),
        });

        return actions;
    }, [type, t, approvalModalHandler]);

    const rowActions = useCallback(
        (row: IManualUploadResetApproval): IDropdownItem[] => {
            const actions: IDropdownItem[] = [];

            if (["declined", "approved"].includes(type)) {
                return actions;
            }

            if (type === "waiting") {
                actions.push({
                    value: "approve",
                    label: t("Approve"),
                    onClick: () =>
                        approvalModalHandler([row.jobId], "approved"),
                });
            }

            actions.push({
                value: "decline",
                label: t("Decline"),
                onClick: () => approvalModalHandler([row.jobId], "decline"),
            });

            return actions;
        },
        [type, t, approvalModalHandler],
    );

    const columns = useMemo(() => {
        let cols: {
            [key: string]: ITableColumn<IManualUploadResetApproval>;
        } = {
            id: {
                title: t("Id"),
                hidden: true,
                canBeToggledByUser: false,
            },
            jobId: {
                title: t("Job Id"),
                filterable: false,
                canBeToggledByUser: false,
                field: "jobId",
                render(value: number) {
                    return <Link url={`/jobs/jobs/${value}`}>#{value}</Link>;
                },
            },
            createdAt: {
                filterable: true,
                type: "date",
                title: t("Uploaded"),
            },
            jobType: {
                title: t("Job Type"),
                render(value) {
                    return <JobType jobType={value} />;
                },
            },
            engineer: {
                title: t("Engineer"),
                render(value: IEngineer) {
                    return (
                        <Link url={`/management/engineers/${value.id}`}>
                            {value.name}
                        </Link>
                    );
                },
            },
            property: {
                title: t("Address"),
                render(value: ISimpleProperty) {
                    return (
                        <Link url={`/management/properties/${value.id}`}>
                            {value.addressString}
                        </Link>
                    );
                },
            },
            paperwork: {
                title: t("Paperwork"),
                sortable: false,
                filterable: false,
                field: "paperwork",
                render(value: ISimplePaperwork[]) {
                    return (
                        value &&
                        value.length > 0 && (
                            <JobPaperworkDropdown paperwork={value} />
                        )
                    );
                },
            },
            complianceType: {
                title: t("Compliance type"),
                render(value: IComplianceType | null) {
                    return (
                        value && (
                            <ColourPill
                                customColour={value.colour}
                                value={t(value.displayName)}
                            />
                        )
                    );
                },
            },
            nextServiceDateAtTimeOfCreation: {
                title: t("Next service date at upload"),
                type: "date",
            },
            currentServiceDate: {
                title: t("Current next service date"),
                type: "date",
                sortable: false,
            },
            suggestedNextServiceDate: {
                title: t("Proposed service date"),
                type: "date",
            },
            uploadedBy: {
                title: t("Uploaded by"),
                field: "uploadedBy",
            },
            reviewedBy: {
                title: t("Reviewed by"),
                field: "reviewedBy",
            },
            approved: {
                title: t("Status"),
                filterable: false,
                sortable: false,
                render: (value, row) => {
                    if (value === null) {
                        return row.currentServiceDate ===
                            row.nextServiceDateAtTimeOfCreation
                            ? t("Approval Required")
                            : t("Lapsed");
                    }

                    return value === false ? t("Declined") : t("Approved");
                },
            },
        };

        if (["waiting", "lapsed"].includes(type)) {
            cols = {
                ...cols,
                actions: {
                    title: t("Actions"),
                    filterable: false,
                    render(_, row) {
                        return (
                            <Dropdown
                                size="small"
                                label={
                                    <Icon
                                        icon="more"
                                        ariaLabel={t("Actions")}
                                    />
                                }
                                items={rowActions(row)}
                            />
                        );
                    },
                },
            };
        }

        return cols;
    }, [t, rowActions, type]);

    const { getQueryString } = useQueryString(search);

    const {
        downloadUrl,
        visible: visibleCsv,
        handleDownloadClick,
        downloading,
        hide: hideCsv,
    } = useDownloadCsv({
        exportDataUrl: `${config.jobsApiUrl}/manualuploadresetapproval/export`,
        filters: filters,
        filterColumns: (vc) => vc.key !== "actions",
        search: getQueryString(),
    });

    return resetApprovals && resetApprovals.loaded ? (
        <>
            <Table
                preferences="service-date-reset-approval-table"
                columns={columns}
                bulkActions={bulkActions}
                {...resetApprovals}
                alternateCsvFunction={handleDownloadClick}
            />

            {visible && (
                <ServiceDateApprovalModal
                    jobIds={ids}
                    updateType={updateType}
                    updatedAction={updatedHandler}
                    hide={hide}
                />
            )}

            {toast.visible && (
                <Portal>
                    <Toast>{t("The service date has been updated.")}</Toast>
                </Portal>
            )}

            {visibleCsv && (
                <DownloadCsvModal
                    downloadUrl={downloadUrl}
                    downloading={downloading}
                    hide={hideCsv}
                />
            )}
        </>
    ) : (
        <Loading />
    );
};

export default ServiceDateResetApprovalTable;
