import { useTranslation } from "react-i18next";
import { ProgressCircular } from "..";
import { useCssClasses } from "../../hooks";
import styles from "./Loading.module.scss";

const Loading = ({
    visible = true,
    text = "",
    small = false,
    smallWidth = 30,
    centerContent = false,
    inline = false,
}: ILoadingProps) => {
    const { t } = useTranslation();

    const cssClasses = useCssClasses(
        styles.container,
        inline ? styles.inline : "",
        centerContent ? styles.centerContent : "",
        !small ? styles.padding : "",
    );

    if (visible) {
        return (
            <div
                className={cssClasses}
                style={small ? { width: `${smallWidth}px` } : undefined}
                data-test-id="LoadingSpinner_Div"
            >
                <ProgressCircular
                    value={30}
                    max={100}
                    spin={true}
                    displayValue={false}
                    thickness={small ? 4 : 10}
                />
                {!small && (
                    <div className={styles.title}>
                        {text || t("Loading...")}
                    </div>
                )}
            </div>
        );
    }

    return null;
};

interface ILoadingProps {
    visible?: boolean;
    text?: string;
    small?: boolean;
    smallWidth?: number;
    centerContent?: boolean;
    inline?: boolean;
}

export default Loading;
