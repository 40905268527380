import { useTranslation } from "react-i18next";
import { Card, Icon, Link, Loading } from "../../../components";
import { IComplianceDashboard } from "../../../utils/api/misc";
import ManualUpload from "./ManualUpload";

const ManualUploadCard = ({ data, maxHeight, loaded }: IManualUploadProps) => {
    const { t } = useTranslation();

    return (
        <Card
            title={t("Manual uploads")}
            testId="ManualUpload_Widget"
            bodyScroll={false}
            actions={
                <Link
                    url="/jobs/manual-uploads"
                    ariaLabel={t("View all manual uploads")}
                >
                    <Icon
                        icon="eye"
                        size={12}
                        display="inline-block"
                        cssRules={{ marginRight: "5px" }}
                        ariaHidden={true}
                    />
                    {t("View all")}
                </Link>
            }
        >
            {loaded ? (
                <ManualUpload
                    manualUploads={data.manualUploads}
                    maxHeight={maxHeight}
                />
            ) : (
                <Loading />
            )}
        </Card>
    );
};

interface IManualUploadProps {
    data: IComplianceDashboard;
    loaded: boolean;
    maxHeight: number;
}

export default ManualUploadCard;
