import { ISelectOption } from "../../../../components/Select";
import AddComplianceRow from "./AddComplianceRow";

export interface IAddComplianceRowProps {
    complianceTypes: ISelectOption[];
    contractors: ISelectOption[];
    addRow: (complianceId: string, contractorId: string) => void;
}

export default AddComplianceRow;
