import { useCallback, useContext } from "react";
import { UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const useUpdateIssueStatus = () => {
    const { activeUserParentsIds } = useContext(UserContext);
    const { send, loading } = useApiRequest({});
    const sendRequest = useXTagApiRequest();

    const updateIssueStatus = useCallback(
        (id: string, status: string, comment: string) =>
            send(
                sendRequest({
                    url: `${config.issuesApiUrl}/${id}/status`,
                    method: "PUT",
                    urlParams: getGlobalUrlParameters([], activeUserParentsIds),
                    body: {
                        status,
                        comment,
                    },
                }),
            ),
        [activeUserParentsIds, send, sendRequest],
    );

    return { updateIssueStatus, loading };
};

export default useUpdateIssueStatus;
