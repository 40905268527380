import { useTranslation } from "react-i18next";
import { IPropertyNotesProps } from ".";
import { Button, EngineerNotes, Modal, Portal } from "../..";
import { useToggle } from "../../../hooks";
import { usePropertyNotes } from "../../../utils/api/properties";
import ModalBody from "../../Modal/ModalBody";

const PropertyNotes = ({ propertyId }: IPropertyNotesProps) => {
    const { t } = useTranslation();

    const { value, loaded } = usePropertyNotes(propertyId);

    const { show, hide, visible } = useToggle();

    return (
        <>
            {loaded && value.length > 0 && (
                <>
                    <Button onClick={show} cssRules={{ marginBottom: "20px" }}>
                        {t("View notes")}
                    </Button>

                    {visible && (
                        <Portal>
                            <Modal title={t("Engineer notes")} hide={hide}>
                                <ModalBody>
                                    <EngineerNotes notes={value} />
                                </ModalBody>
                            </Modal>
                        </Portal>
                    )}
                </>
            )}
        </>
    );
};

export default PropertyNotes;
