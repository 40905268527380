import { useCallback, useMemo } from "react";
import { IQualifiedSupervisorAuditQuestionsGridSectionProps } from ".";
import { useToggleColumns } from "../../../hooks";
import { IAnswer } from "../../../utils/api/answers";
import QualifiedSupervisorAuditGridRow from "../QualifiedSupervisorAuditGridRow";
import { IQualifiedSupervisorAuditAnswer } from "../QualifiedSupervisorAuditsGrouping";
import Table from "../Table";
import ToggleColumns from "../ToggleColumns";
import styles from "./QualifiedSupervisorAuditQuestionsGridSection.module.scss";

const QualifiedSupervisorAuditQuestionsGridSection = ({
    id,
    auditId,
    questionGroups,
    isReadOnly,
    documentType,
    overriddenAnswers,
    hiddenAnswer,
    updateOverriddenAnswers,
    validate,
    addAuditJobComment,
    addAuditObservation,
    removeAuditJobComment,
    removeAuditObservation,
    addAdditionalAnswers,
}: IQualifiedSupervisorAuditQuestionsGridSectionProps) => {
    const questions = useMemo(() => {
        const dictionary: Record<string, string | undefined> = {};

        const allQuestions = questionGroups.flatMap((g) =>
            g.children.map((c) => ({
                id: c.id,
                title: c.title,
            })),
        );

        for (const question of allQuestions) {
            if (dictionary[question.id] === undefined) {
                dictionary[question.id] = question.title;
            }
        }

        return Object.keys(dictionary).map((k) => ({
            id: k,
            title: dictionary[k] ?? "",
        }));
    }, [questionGroups]);

    const answers = useMemo(() => {
        const dictionary: {
            [key: string]: {
                [key: string]: IQualifiedSupervisorAuditAnswer[] | undefined;
            };
        } = {};

        for (const group of questionGroups) {
            dictionary[group.id] = {};

            for (const question of group.children) {
                dictionary[group.id][question.id] = question.children;
            }
        }

        return dictionary;
    }, [questionGroups]);

    const allColumns = useMemo(
        () =>
            questions.map((q) => ({
                id: q.id,
                title: q.title,
            })),
        [questions],
    );

    const {
        columnVisiblity,
        visibleColumns,
        toggleColumnVisibility,
        showColumns,
    } = useToggleColumns("qs-modal-section-columns", allColumns, [
        "Bsen",
        "ProtectiveDeviceType",
        "Rating",
        "BreakingCapacity",
        "RcdOperatingCurrent",
        "MaximumPermitted",
        "NumOfPointsServed",
        "ReferenceMethod",
        "Live",
        "cpc",
        "line",
        "neutral",
        "r1r2",
        "r2",
        "InsulationTestVoltage",
        "LiveToLive",
        "LiveToEarth",
        "PolarityConfirmed",
        "MaxMeasuredZ",
        "DisconnectionTime",
        "TestButtonOperation",
        "TestButtonOperationV2",
        "ManualTest",
        "Remarks",
    ]);

    const handleAddAdditionalAnswers = useCallback(
        (additionalAnswers: IAnswer[]) => {
            addAdditionalAnswers(additionalAnswers);
            showColumns(additionalAnswers.map((a) => a.questionKey));
        },
        [addAdditionalAnswers, showColumns],
    );

    return (
        <div className={styles.container}>
            <Table withMargin={false}>
                <thead>
                    <tr>
                        <th className={styles.headerColumn}>
                            <ToggleColumns
                                allColumns={allColumns}
                                columnvisiblity={columnVisiblity}
                                toggleColumnvisibility={toggleColumnVisibility}
                            />
                        </th>

                        {!isReadOnly && <th className={styles.editColumn} />}

                        {visibleColumns.map((q) => (
                            <th
                                key={q.id}
                                className={
                                    [
                                        "PolarityConfirmed",
                                        "MaxMeasuredZ",
                                        "DisconnectionTime",
                                        "TestButtonOperation",
                                        "TestButtonOperationV2",
                                        "RcdOperatingCurrent",
                                        "MaximumPermitted",
                                        "ReferenceMethod",
                                        "BreakingCapacity",
                                        "InsulationTestVoltage",
                                        "ConfigureDisconnectionTimeV2",
                                    ].includes(q.id)
                                        ? styles.rotatedColumn
                                        : styles.column
                                }
                            >
                                {q.title}
                            </th>
                        ))}
                    </tr>
                </thead>

                <tbody>
                    {questionGroups.map((g) => (
                        <QualifiedSupervisorAuditGridRow
                            key={g.id}
                            id={`${id}-${g.id}`}
                            auditId={auditId}
                            rowTitle={g.title}
                            columns={visibleColumns}
                            documentType={documentType}
                            overriddenAnswers={overriddenAnswers}
                            questions={g.children}
                            answers={answers[g.id]}
                            isReadOnly={isReadOnly}
                            hiddenAnswer={hiddenAnswer}
                            updateOverriddenAnswers={updateOverriddenAnswers}
                            validate={validate}
                            addAuditJobComment={addAuditJobComment}
                            addAuditObservation={addAuditObservation}
                            removeAuditJobComment={removeAuditJobComment}
                            removeAuditObservation={removeAuditObservation}
                            addAdditionalAnswers={handleAddAdditionalAnswers}
                        />
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

export default QualifiedSupervisorAuditQuestionsGridSection;
