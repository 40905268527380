import { useCallback, useContext } from "react";
import { IUpdateUser } from ".";
import { UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const useAdminUpdateUser = () => {
    const { send, error, loading } = useApiRequest({});
    const { user: currentUser } = useContext(UserContext);

    const sendRequest = useXTagApiRequest();

    const updateUser = useCallback(
        (userId: number, user: IUpdateUser) =>
            send(
                sendRequest({
                    url: `${config.usersApiUrl}/v2/${userId}`,
                    method: "PUT",
                    urlParams: getGlobalUrlParameters(
                        [],
                        currentUser.userParents.map((up) => up.userParentId),
                    ),
                    body: user,
                }),
            ),
        [currentUser, send, sendRequest],
    );

    return { updateUser, error, loading };
};

export default useAdminUpdateUser;
