import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { UserPreferencesContext } from "../components";

export const useToggleColumns = (
    key: string,
    allColumns: { id: string; title: string }[],
    defaultVisibleColumns: string[],
) => {
    const { preferences, updatePreferences } = useContext(
        UserPreferencesContext,
    );

    const [defaultValue] = useState(() =>
        defaultVisibleColumns.reduce<Record<string, boolean | undefined>>(
            (dictionary, column) => ({ ...dictionary, [column]: true }),
            {},
        ),
    );
    const [columnVisiblity, setColumnVisiblity] =
        useState<Record<string, boolean | undefined>>(defaultValue);

    useEffect(() => {
        if (preferences[key] !== undefined) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            setColumnVisiblity(preferences[key]);
        } else {
            setColumnVisiblity(defaultValue);
        }
    }, [key, preferences, defaultValue]);

    const toggleColumnVisibility = useCallback(
        (columnId: string) => {
            const temp = {
                ...columnVisiblity,
                [columnId]: !columnVisiblity[columnId],
            };

            setColumnVisiblity(temp);
            updatePreferences({ ...preferences, [key]: temp }).subscribe();
        },
        [key, columnVisiblity, preferences, updatePreferences],
    );

    const showColumns = useCallback(
        (columnIds: string[]) => {
            const temp: Record<string, boolean | undefined> = {
                ...columnVisiblity,
            };

            for (const columnId of columnIds) {
                temp[columnId] = true;
            }

            setColumnVisiblity(temp);
            updatePreferences({ ...preferences, [key]: temp }).subscribe();
        },
        [columnVisiblity, key, preferences, updatePreferences],
    );

    const visibleColumns = useMemo(() => {
        return allColumns.filter((c) => columnVisiblity[c.id]);
    }, [allColumns, columnVisiblity]);

    return {
        columnVisiblity,
        visibleColumns,
        toggleColumnVisibility,
        showColumns,
    };
};
