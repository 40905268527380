import environmentVariables from "./environmentVariables";

const appliances = environmentVariables.REACT_APP_AWS
    ? environmentVariables.REACT_APP_API_DOMAIN
    : environmentVariables.REACT_APP_APPLIANCES_DOMAIN ||
      environmentVariables.REACT_APP_API_DOMAIN;
const assets = environmentVariables.REACT_APP_AWS
    ? environmentVariables.REACT_APP_API_DOMAIN
    : environmentVariables.REACT_APP_ASSETS_DOMAIN ||
      environmentVariables.REACT_APP_API_DOMAIN;
const properties = environmentVariables.REACT_APP_AWS
    ? environmentVariables.REACT_APP_API_DOMAIN
    : environmentVariables.REACT_APP_PROPERTIES_DOMAIN ||
      environmentVariables.REACT_APP_API_DOMAIN;
const jobs = environmentVariables.REACT_APP_AWS
    ? environmentVariables.REACT_APP_API_DOMAIN
    : environmentVariables.REACT_APP_JOBS_DOMAIN ||
      environmentVariables.REACT_APP_API_DOMAIN;
const engineers = environmentVariables.REACT_APP_AWS
    ? environmentVariables.REACT_APP_API_DOMAIN
    : environmentVariables.REACT_APP_ENGINEERS_DOMAIN ||
      environmentVariables.REACT_APP_API_DOMAIN;
const landlords = environmentVariables.REACT_APP_AWS
    ? environmentVariables.REACT_APP_API_DOMAIN
    : environmentVariables.REACT_APP_LANDLORDS_DOMAIN ||
      environmentVariables.REACT_APP_API_DOMAIN;
const users = environmentVariables.REACT_APP_AWS
    ? environmentVariables.REACT_APP_API_DOMAIN
    : environmentVariables.REACT_APP_USERS_DOMAIN ||
      environmentVariables.REACT_APP_API_DOMAIN;
const lgsrs = environmentVariables.REACT_APP_AWS
    ? environmentVariables.REACT_APP_API_DOMAIN
    : environmentVariables.REACT_APP_LGSRS_DOMAIN ||
      environmentVariables.REACT_APP_API_DOMAIN;
const misc = environmentVariables.REACT_APP_AWS
    ? environmentVariables.REACT_APP_API_DOMAIN
    : environmentVariables.REACT_APP_MISC_DOMAIN ||
      environmentVariables.REACT_APP_API_DOMAIN;
const allocatedJobs = environmentVariables.REACT_APP_AWS
    ? environmentVariables.REACT_APP_API_DOMAIN
    : environmentVariables.REACT_APP_ALLOCATED_JOBS_DOMAIN ||
      environmentVariables.REACT_APP_API_DOMAIN;
const issues = environmentVariables.REACT_APP_AWS
    ? environmentVariables.REACT_APP_API_DOMAIN
    : environmentVariables.REACT_APP_ISSUES_DOMAIN ||
      environmentVariables.REACT_APP_API_DOMAIN;
const answers = environmentVariables.REACT_APP_AWS
    ? environmentVariables.REACT_APP_API_DOMAIN
    : environmentVariables.REACT_APP_ANSWERS_DOMAIN ||
      environmentVariables.REACT_APP_API_DOMAIN;
const reconciliations = environmentVariables.REACT_APP_AWS
    ? environmentVariables.REACT_APP_API_DOMAIN
    : environmentVariables.REACT_APP_RECONCILIATION_DOMAIN ||
      environmentVariables.REACT_APP_API_DOMAIN;
const audits = environmentVariables.REACT_APP_AWS
    ? environmentVariables.REACT_APP_API_DOMAIN
    : environmentVariables.REACT_APP_AUDITS_DOMAIN ||
      environmentVariables.REACT_APP_API_DOMAIN;

const config = {
    phpApiUrl: `https://${environmentVariables.REACT_APP_PHP_API_DOMAIN}/api`,
    websocketUrl: `wss://${environmentVariables.REACT_APP_WEBSOCKET_DOMAIN}`,
    appliancesApiUrl: `https://${appliances}/api/appliance`,
    assetsApiUrl: `https://${assets}/api/assets`,
    propertiesApiUrl: `https://${properties}/api/properties`,
    jobsApiUrl: `https://${jobs}/api/jobs`,
    engineersApiUrl: `https://${engineers}/api/engineers`,
    landlordsApiUrl: `https://${landlords}/api/landlords`,
    usersApiUrl: `https://${users}/api/users`,
    lgsrsApiUrl: `https://${lgsrs}/api/lgsrs`,
    miscApiUrl: `https://${misc}/api/misc`,
    allocatedJobsApiUrl: `https://${allocatedJobs}/api/allocatedjobs`,
    issuesApiUrl: `https://${issues}/api/issues`,
    answersApiUrl: `https://${answers}/api/answers`,
    reconciliationApiUrl: `https://${reconciliations}/api/reconciliation`,
    auditsApiUrl: `https://${audits}/api/audits`,
    googleMapsApiKey: environmentVariables.REACT_APP_GOOGLE_MAPS_API_KEY,
    isPageTrackingEnabled: environmentVariables.REACT_APP_ENABLE_PAGE_TRACKING,
    segmentApiKey: environmentVariables.REACT_APP_SEGMENT_API_KEY ?? "",
    auth0: {
        audience: environmentVariables.REACT_APP_API_DOMAIN ?? "",
        domain: environmentVariables.REACT_APP_AUTH0_DOMAIN ?? "",
        clientId: environmentVariables.REACT_APP_AUTH0_CLIENT_ID ?? "",
    },
    fullStoryOrganizationId:
        environmentVariables.REACT_APP_FULLSTORY_ORGANIZATION_ID ?? "",
    isAmendLgsrEnabled: false, // Temporary feature flag XT-787
};

export default config;
