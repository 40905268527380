import { useCallback } from "react";
import { IUserPreferences } from ".";
import config from "../../../config";
import { useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const useUpdatePreferences = () => {
    const { send, loading } = useApiRequest({});
    const sendRequest = useXTagApiRequest();

    const update = useCallback(
        (id: number, preferences: IUserPreferences) =>
            send(
                sendRequest({
                    url: `${config.usersApiUrl}/preferences`,
                    method: "PUT",
                    body: {
                        id,
                        preferences: JSON.stringify(preferences),
                    },
                }),
            ),
        [send, sendRequest],
    );

    return { loading, update };
};

export default useUpdatePreferences;
