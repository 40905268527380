import { trim } from "lodash";
import { useCallback, useState } from "react";
import { catchError, Observable, tap } from "rxjs";

function useApiRequest<T>({ errorMessage }: IApiRequestParams) {
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    const send = useCallback(
        (observable: Observable<T>) => {
            setError("");
            setLoading(true);

            return observable
                .pipe(
                    catchError((reason) => {
                        const message =
                            errorMessage ||
                            (reason.response && reason.response.message) ||
                            (reason.response && reason.response.Message) ||
                            (typeof reason.response === "string" &&
                                reason.response) ||
                            reason.message ||
                            reason.toString();

                        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                        throw trim(message, '" ');
                    }),
                )
                .pipe(
                    tap(
                        () => {
                            setLoading(false);
                        },
                        (reason) => {
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                            setError(reason);
                            setLoading(false);
                        },
                    ),
                );
        },
        [errorMessage],
    );

    return { send, setError, error, loading };
}

export interface IApiRequestParams {
    errorMessage?: string;
}

export default useApiRequest;
