import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IApplianceListItemProps } from ".";
import { ImageList, Link } from "..";
import { toDateString, toTimeString } from "../../utils/dates";
import styles from "./ApplianceListitem.module.scss";

const ApplianceListItem = ({ appliance }: IApplianceListItemProps) => {
    const { t } = useTranslation();

    const nextServiceDate = useMemo(
        () => toDateString(new Date(appliance.nextServiceDate)),
        [appliance.nextServiceDate],
    );

    const expiryDate = useMemo(
        () =>
            appliance.expiryDate &&
            toDateString(new Date(appliance.expiryDate)),
        [appliance.expiryDate],
    );

    const unsafeDate = useMemo(
        () =>
            appliance.unsafeDate &&
            toTimeString(new Date(appliance.unsafeDate)) +
                " on " +
                toDateString(new Date(appliance.unsafeDate)),
        [appliance.unsafeDate],
    );

    const cappedDate = useMemo(
        () =>
            appliance.cappedDate &&
            toDateString(new Date(appliance.cappedDate)),
        [appliance.cappedDate],
    );

    return (
        <ImageList image={appliance.imageUrl}>
            <Link url={`/management/appliances/${appliance.id}`}>
                {`${appliance.type.displayName}: ${appliance.make} - ${appliance.model}`}
                <br />
                {t("Next service date")}: {nextServiceDate}
                {expiryDate && (
                    <>
                        <br />
                        {t("Expiry date")}: {expiryDate}
                    </>
                )}
                {unsafeDate && (
                    <>
                        <br />
                        <span className={styles.unsafeLabel}>
                            {t("Unsafe")}:
                        </span>
                        &nbsp;
                        <span className={styles.unsafeDate}>{unsafeDate}</span>
                    </>
                )}
                {cappedDate && (
                    <>
                        <br />
                        {t("Capped date")}: {cappedDate}
                    </>
                )}
            </Link>
        </ImageList>
    );
};

export default ApplianceListItem;
