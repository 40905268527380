import { IAuditComment, IIssue } from "../../../utils/api/audits";
import HeadersAccordion from "./HeadersAccordion";

export interface IHeadersAccordionProps {
    auditId: number;
    isReadOnly: boolean;
    comments: IAuditComment[];
    remedialActions: IAuditComment[];
    issues: IIssue[];
    createComment: (comment: IAuditComment) => void;
}

export default HeadersAccordion;
