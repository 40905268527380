import { useMemo } from "react";

const useCssClasses = (...className: string[]) => {
    const cssClass = useMemo(
        () => className.filter((name) => !!name).join(" ") || undefined,
        [className],
    );

    return cssClass;
};

export default useCssClasses;
