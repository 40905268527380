import { ITaskType } from "../../../utils/api/appliances";
import JobTimelineTask from "./JobTimelineTask";

export interface IAppliance {
    id: string;
    type: IApplianceType;
    make: string | null;
    model: string | null;
    displayName: string | null;
    pageType: string | null;
}

interface IApplianceType {
    id: number;
    displayName: string;
}

export interface ITask {
    id: number;
    appliance?: IAppliance;
    startDate: string;
    endDate: string;
    type: ITaskType;
}

export default JobTimelineTask;
