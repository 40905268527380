import { IComplianceDashboard } from "../../../utils/api/misc";
import ComplianceFireDoorDashboard from "./ComplianceFireDoorDashboard";

export interface IComplianceFireDoorDashboardProps {
    rowHeight: number;
    dashboard: IComplianceDashboard;
    loaded: boolean;
    mapFeature: boolean;
}

export default ComplianceFireDoorDashboard;
