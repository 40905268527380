import { useContext, useEffect } from "react";
import { IContractor } from ".";
import { UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiValue from "../useApiValue";

const useCurrentContractor = () => {
    const { value, send, loading, updateValue } = useApiValue<IContractor>({});
    const { activeUserParentsIds } = useContext(UserContext);
    const sendRequest = useXTagApiRequest();

    useEffect(() => {
        const subscription = send(
            sendRequest({
                url: `${config.landlordsApiUrl}/contractors/me`,
                method: "GET",
                urlParams: getGlobalUrlParameters([], activeUserParentsIds),
            }),
        ).subscribe();

        return () => {
            subscription.unsubscribe();
        };
    }, [activeUserParentsIds, send, sendRequest]);

    return { value, loading, updateValue };
};

export default useCurrentContractor;
