import { useCallback, useMemo, useState } from "react";
import {
    IGroup,
    IQualifiedSupervisorAuditAnswer,
    IQualifiedSupervisorAuditsGroupingProps,
    IRootGroup,
} from ".";
import { Alert, Loading, Portal } from "../../../components";
import ModalBody from "../../../components/Modal/ModalBody";
import { useToggle } from "../../../hooks";
import {
    IAnswer,
    IInspectionScheduleAnswer,
    useAnswersForJob,
} from "../../../utils/api/answers";
import { useAuditOverriddenAnswers } from "../../../utils/api/audits";
import QualifiedSupervisorAudit, {
    IAnswerSection,
} from "../QualifiedSupervisorAudit";
import QualifiedSupervisorAuditsSaveModal from "../QualifiedSupervisorAuditsSaveModal";

const QualifiedSupervisorAuditsGrouping = ({
    audit,
    isReadOnly,
    createComment,
    canCompleteAudit,
    isSaveDisabled,
    onHoldAudit,
    onSaveAudit,
    canOverrideAnswers,
    addAuditJobComment,
    addAuditObservation,
    removeAuditJobComment,
    removeAuditObservation,
    sidebarVisible,
}: IQualifiedSupervisorAuditsGroupingProps) => {
    const {
        value: answers,
        loading: answersLoading,
        error: answersError,
    } = useAnswersForJob(audit.job.id.toString(), {
        activeAssets: true,
        includePreviousConfigAnswers: true,
        includeDeletedAnswers: true,
    });

    const {
        value: overriddenAnswers,
        loading: overriddenAnswersLoading,
        error: overriddenAnswersError,
        updateOverriddenAnswers,
    } = useAuditOverriddenAnswers(audit.id);

    const [additionalAnswers, setAdditionalAnswers] = useState<IAnswer[]>([]);
    const addAdditionalAnswers = useCallback((newAnswers: IAnswer[]) => {
        setAdditionalAnswers((list) => [...list, ...newAnswers]);
    }, []);

    const answersSections = useMemo(() => {
        const groups: IRootGroup<
            IGroup<IGroup<IQualifiedSupervisorAuditAnswer[]>>
        > = {};
        const inspectionSchedules: Record<
            string,
            Record<
                string,
                {
                    raisingAnswer: IInspectionScheduleAnswer;
                    relatedAnswerIds: number[];
                }
            >
        > = {};

        const processAnswer = (answer: IAnswer) => {
            let groupId = answer.taskType.id.toString();
            let groupTitle = answer.taskType.displayName;

            if (
                [
                    "electric_add_observation",
                    "electric_resolve_observation",
                    "add_comment",
                    "electric_add_limitation",
                ].includes(answer.taskType.taskType)
            ) {
                groupId = answer.taskId.toString();
            } else if (answer.asset) {
                if (answer.asset.boardId) {
                    groupId = answer.asset.boardId;
                } else {
                    groupId = answer.asset.assetType;
                }
            }

            if (answer.asset) {
                if (answer.asset.boardName) {
                    groupTitle = answer.asset.boardName;
                } else {
                    groupTitle = `${answer.asset.assetType}s`;
                }
            }

            const subGroupId = answer.asset
                ? answer.asset.id
                : answer.questionSet.questionsetId;

            const subGroupTitle = answer.asset
                ? answer.asset.displayName
                : answer.questionSet.questionsetName;

            const question = {
                order: answer.order,
                title: answer.questionText,
                value: [
                    {
                        answer,
                        relatedAnswerIds: [],
                    },
                ],
            };

            const taskDateOrder = new Date(answer.taskEndDate).getTime();

            const subGroup = {
                order: answer.asset?.wayIndex ?? answer.questionSetOrder,
                title: subGroupTitle,
                value: {
                    [answer.questionKey ?? ""]: question,
                },
            };
            const group = groups[groupId];

            if (!group) {
                groups[groupId] = {
                    order: taskDateOrder,
                    title: groupTitle,
                    displayChildrenOnMenu:
                        answer.taskType.taskType ===
                        "electric_inspection_schedule_item",
                    displayAsGrid: answer.asset?.assetType === "Circuit",
                    value: {
                        [subGroupId]: subGroup,
                    },
                };
            } else {
                if (taskDateOrder > group.order) {
                    group.order = taskDateOrder;
                }

                const existingSubGroup = group.value[subGroupId];

                if (!existingSubGroup) {
                    group.value[subGroupId] = subGroup;
                } else {
                    const existingQuestion =
                        existingSubGroup.value[answer.questionKey ?? ""];

                    if (!existingQuestion) {
                        existingSubGroup.value[answer.questionKey ?? ""] =
                            question;
                    } else {
                        existingQuestion.value.push({
                            answer,
                            relatedAnswerIds: [],
                        });
                    }
                }
            }

            if (
                answer.type === "InspectionSchedule" &&
                (answer.payload.Observation || answer.payload.Comment)
            ) {
                const section =
                    inspectionSchedules[answer.questionSet.questionsetName];

                if (!section) {
                    inspectionSchedules[answer.questionSet.questionsetName] =
                        {};
                }

                inspectionSchedules[answer.questionSet.questionsetName][
                    answer.questionText
                ] = {
                    raisingAnswer: answer,
                    relatedAnswerIds: [],
                };
            }

            switch (answer.questionKey) {
                case "InspectionScheduleSection": {
                    groups[groupId].inspectionScheduleSection = answer.payload;
                    break;
                }
                case "InspectionScheduleItem": {
                    groups[groupId].inspectionScheduleItem = answer.payload;
                    break;
                }
            }
        };

        const limitations: Record<
            string,
            { isAgreed: boolean; answers: Record<string, IAnswer> }
        > = {};

        const allAnswers = [...answers, ...additionalAnswers];
        for (const answer of allAnswers) {
            let isAllowedAnswer = false;

            if (answer.taskType.taskType === "electric_add_limitation") {
                const existingLimitationAnswer =
                    limitations[answer.taskId.toString()];

                if (existingLimitationAnswer) {
                    if (answer.questionKey === "Type") {
                        existingLimitationAnswer.isAgreed =
                            answer.payload === "Agreed";
                    }

                    existingLimitationAnswer.answers[answer.questionKey] =
                        answer;
                } else {
                    limitations[answer.taskId.toString()] = {
                        isAgreed:
                            answer.questionKey === "Type" &&
                            answer.payload === "Agreed",
                        answers: { [answer.questionKey]: answer },
                    };
                }
            } else if (
                answer.questionName !== "E_NGLPGResult" &&
                answer.taskType.taskType !== "register_board" &&
                (answer.questionName === "E_OperationalLimitationDesc" ||
                    answer.taskType.taskType !== "electric_apply_limitation")
            ) {
                switch (answer.type) {
                    case "TextField":
                    case "TextArea":
                    case "AutoComplete":
                    case "SignatureButton":
                    case "Reading":
                    case "InspectionSchedule":
                    case "RadioButtonGroup":
                    case "ToggleButtonGroup":
                    case "ButtonAddRepeater": {
                        isAllowedAnswer = true;
                        break;
                    }
                    case "Boolean": {
                        isAllowedAnswer =
                            answer.questionName.endsWith("Result");
                        break;
                    }
                    case "PhotoButton": {
                        isAllowedAnswer =
                            answer.questionName.endsWith("Result") ||
                            answer.questionName.endsWith("Notes");
                        break;
                    }
                    case "Dropdown":
                    case "FilteredDropdown":
                    case "DatePicker": {
                        isAllowedAnswer =
                            !answer.questionName.endsWith("Result");
                        break;
                    }
                    case "MultiSelect": {
                        isAllowedAnswer =
                            answer.questionName.endsWith("Result");
                        break;
                    }
                    default: {
                        break;
                    }
                }
            }

            if (isAllowedAnswer) {
                processAnswer(answer);
            }
        }

        for (const limitation of Object.values(limitations)) {
            const description = limitation.answers["Description"];

            if (description) {
                if (limitation.isAgreed) {
                    processAnswer({
                        ...description,
                        type: "Dropdown",
                        payload: description.payload,
                    });
                } else {
                    const otherDescription =
                        limitation.answers["OtherDescription"];

                    processAnswer({
                        ...description,
                        type: "TextField",
                        payload:
                            otherDescription?.payload ?? description.payload,
                    });
                }
            }
        }

        for (const groupId of Object.keys(groups)) {
            const group = groups[groupId];
            const groupAnswers = Object.values(group.value).flatMap((v) =>
                Object.values(v.value).flatMap((q) => q.value),
            );

            const relatedAnswerIds = groupAnswers
                .filter((a) =>
                    [
                        "electric_add_limitation",
                        "electric_add_observation",
                    ].includes(a.answer.taskType.taskType),
                )
                .map((a) => a.answer.id);

            if (
                group.inspectionScheduleSection &&
                group.inspectionScheduleItem &&
                relatedAnswerIds.length > 0
            ) {
                inspectionSchedules[group.inspectionScheduleSection][
                    group.inspectionScheduleItem
                ].relatedAnswerIds = relatedAnswerIds;
            }
        }

        const list: IAnswerSection[] = Object.keys(groups)
            .map((groupId) => {
                const group = groups[groupId];

                let hiddenAnswer: IInspectionScheduleAnswer | undefined;

                if (
                    group.inspectionScheduleSection &&
                    group.inspectionScheduleItem
                ) {
                    const section =
                        inspectionSchedules[group.inspectionScheduleSection];
                    if (section !== undefined) {
                        hiddenAnswer =
                            section[group.inspectionScheduleItem].raisingAnswer;
                    }
                }

                return {
                    id: groupId,
                    order: group.order,
                    title: group.title,
                    hiddenAnswer: hiddenAnswer,
                    displayChildrenOnMenu: group.displayChildrenOnMenu,
                    displayAsGrid: group.displayAsGrid,
                    children: Object.keys(group.value)
                        .map((childId) => {
                            const child = group.value[childId];

                            return {
                                id: childId,
                                order: child.order,
                                title: child.title,
                                children: Object.keys(child.value)
                                    .map((questionKey) => {
                                        const question =
                                            child.value[questionKey];

                                        return {
                                            id: questionKey,
                                            order: question.order,
                                            title: question.title,
                                            children: question.value
                                                .map((a) => {
                                                    let relatedAnswerIds: number[] =
                                                        [];

                                                    const section =
                                                        inspectionSchedules[
                                                            a.answer.questionSet
                                                                .questionsetName
                                                        ];
                                                    if (section) {
                                                        const inspectionSchedule =
                                                            section[
                                                                a.answer
                                                                    .questionText
                                                            ];

                                                        if (
                                                            inspectionSchedule
                                                        ) {
                                                            relatedAnswerIds =
                                                                inspectionSchedule.relatedAnswerIds;
                                                        }
                                                    }

                                                    return {
                                                        answer: a.answer,
                                                        relatedAnswerIds,
                                                    };
                                                })
                                                .sort((a, b) =>
                                                    a.answer.questionName >
                                                    b.answer.questionName
                                                        ? 1
                                                        : -1,
                                                ),
                                        };
                                    })
                                    .sort((a, b) =>
                                        a.order > b.order ? 1 : -1,
                                    ),
                            };
                        })
                        .sort((a, b) => (a.order > b.order ? 1 : -1)),
                };
            })
            .sort((a, b) => (a.order > b.order ? 1 : -1));

        return list;
    }, [additionalAnswers, answers]);

    const {
        visible: confirmSaveModalVisible,
        hide: cancelSave,
        show: showConfirmSaveModal,
    } = useToggle();

    const loading = useMemo(
        () => answersLoading || overriddenAnswersLoading,
        [answersLoading, overriddenAnswersLoading],
    );
    const error = useMemo(
        () => answersError || overriddenAnswersError,
        [answersError, overriddenAnswersError],
    );

    return (
        <>
            {error ? (
                <ModalBody>
                    <Alert type="error">{error}</Alert>
                </ModalBody>
            ) : !loading ? (
                <QualifiedSupervisorAudit
                    audit={audit}
                    isReadOnly={isReadOnly}
                    answersSections={answersSections}
                    updateOverriddenAnswers={updateOverriddenAnswers}
                    onSaveAudit={showConfirmSaveModal}
                    onHoldAudit={onHoldAudit}
                    createComment={createComment}
                    isSaveDisabled={isSaveDisabled}
                    canOverrideAnswers={canOverrideAnswers}
                    overriddenAnswers={overriddenAnswers}
                    addAuditJobComment={addAuditJobComment}
                    addAuditObservation={addAuditObservation}
                    removeAuditJobComment={removeAuditJobComment}
                    removeAuditObservation={removeAuditObservation}
                    addAdditionalAnswers={addAdditionalAnswers}
                    sidebarVisible={sidebarVisible}
                />
            ) : (
                <ModalBody>
                    <Loading />
                </ModalBody>
            )}

            {confirmSaveModalVisible && (
                <Portal>
                    <QualifiedSupervisorAuditsSaveModal
                        audit={audit}
                        overriddenAnswers={overriddenAnswers}
                        answersSections={answersSections}
                        onSaveAudit={onSaveAudit}
                        hide={cancelSave}
                        canCompleteAudit={canCompleteAudit}
                    />
                </Portal>
            )}
        </>
    );
};

export default QualifiedSupervisorAuditsGrouping;
