import { IPropertyFlag } from "../../../utils/api/properties";
import TableFlag from "../TableFlag/TableFlag";
import styles from "./TableFlagGroup.module.scss";

const TableFlagGroup = ({ flags }: ITableFlagGroupProps) => {
    return (
        <div className={styles.container}>
            {flags.map((flag, index) => (
                <TableFlag
                    key={index}
                    name={flag.name}
                    description={flag.description}
                    colour={flag.colour}
                />
            ))}
        </div>
    );
};

interface ITableFlagGroupProps {
    flags: IPropertyFlag[];
}

export default TableFlagGroup;
