import { IPropertyFlag } from "../../../utils/api/properties";
import PropertyFlags from "./PropertyFlags";

export interface IPropertyFlagsProps {
    propertyId: number;
    selectedFlags: { [id: string]: boolean };
    onSelectedFlagsChange: (flags: IPropertyFlag[]) => void;
}

export default PropertyFlags;
