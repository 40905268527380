const formatAddress = (address: IAddress) => {
    if (address) {
        const addresses = [address.addressOne];

        if (address.addressTwo) {
            addresses.push(address.addressTwo);
        }
        if (address.city) {
            addresses.push(address.city);
        }
        if (address.county) {
            addresses.push(address.county);
        }

        addresses.push(address.postcode);

        return addresses.join(", ");
    }

    return "";
};

const degressToRadians = (degrees: number) => degrees * (Math.PI / 180);

const getDistance = (point1: ICoordinates, point2: ICoordinates) => {
    const earthRadius = 6371;
    const dLat = degressToRadians(point2.lat - point1.lat);
    const dLng = degressToRadians(point2.lng - point1.lng);

    // Haversine formula.
    const a =
        Math.pow(Math.sin(dLat / 2), 2) +
        Math.cos(degressToRadians(point1.lat)) *
            Math.cos(degressToRadians(point2.lat)) *
            Math.pow(Math.sin(dLng / 2), 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = earthRadius * c;

    // Convert from km to miles.
    return (distance * 0.621371).toFixed(2);
};

interface ICoordinates {
    lat: number;
    lng: number;
}

interface IAddress {
    addressOne: string;
    addressTwo: string;
    city: string;
    county: string;
    postcode: string;
}

export { getDistance, formatAddress };
