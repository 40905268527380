import { useTranslation } from "react-i18next";
import { Card, Icon, Link, Loading, Modal, Portal } from "..";
import { useToggle } from "../../hooks";
import { colours } from "../../styles/colours";
import Button from "../Button";
import InfoTooltip from "../InfoToolTip";
import ModalBody from "../Modal/ModalBody";
import SimpleTable, { IColumn } from "../SimpleTable";
import styles from "./KeyValueWidget.module.scss";

function KeyValueWidget<T>({
    title,
    summary,
    data,
    url,
    columns,
    tooltipText,
    loaded,
}: IKeyValueWidgetProps<T>) {
    const { t } = useTranslation();
    const { hide, toggle, visible } = useToggle();

    return (
        <Card
            title={title}
            fullHeight={true}
            actions={
                <>
                    {data && data.length > 0 ? (
                        <>
                            <Button onClick={toggle}>{t("View more")}</Button>
                            <InfoTooltip tooltipText={tooltipText} />
                        </>
                    ) : (
                        <>
                            {url && (
                                <Link url={url}>
                                    <Icon
                                        icon="eye"
                                        display="inline-block"
                                        size={12}
                                        cssRules={{ marginRight: "5px" }}
                                        ariaHidden={true}
                                    />
                                    {t("View more")}
                                </Link>
                            )}
                            <InfoTooltip tooltipText={tooltipText} />
                        </>
                    )}
                </>
            }
        >
            {loaded ? (
                <div className={styles.container}>
                    {summary.map((record, index) => (
                        <div
                            className={styles.darkGrey}
                            style={{ color: colours.chart.colours[index] }}
                            key={index}
                        >
                            <div className={styles.key}>{record.key}</div>
                            <div className={styles.value}>{record.value}</div>
                        </div>
                    ))}

                    {summary.length === 0 &&
                        t("No data available for this date range")}

                    {visible && columns && (
                        <Portal>
                            <Modal fullWidth={true} hide={hide}>
                                <ModalBody>
                                    <SimpleTable
                                        columns={columns}
                                        data={data}
                                        withHeaders={true}
                                    />
                                </ModalBody>
                            </Modal>
                        </Portal>
                    )}
                </div>
            ) : (
                <Loading small={true} smallWidth={100} centerContent={true} />
            )}
        </Card>
    );
}

interface IKeyvalue {
    key: string;
    value: string | number;
}

interface IKeyValueWidgetProps<T> {
    title: string;
    summary: IKeyvalue[];
    url?: string;
    columns?: IColumn[];
    data?: T[];
    tooltipText: string;
    loaded: boolean;
}

export default KeyValueWidget;
