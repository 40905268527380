import { useTranslation } from "react-i18next";
import { IUserSignatureModalProps } from ".";
import {
    Alert,
    Button,
    Form,
    Modal,
    Portal,
    ValidationError,
} from "../../../components";
import ModalBody from "../../../components/Modal/ModalBody";
import ModalFooter from "../../../components/Modal/ModalFooter";
import useUploadUserSignature from "../../../hooks/useUploadUserSignature";
import styles from "./UserSignatureModal.module.scss";

const UserSignatureModal = ({ hide }: IUserSignatureModalProps) => {
    const { t } = useTranslation();

    const {
        uploadSignature,
        clearCanvas,
        canvasRef,
        hasUploadError,
        isSignatureNotEmpty,
        loading,
    } = useUploadUserSignature();

    return (
        <Portal>
            <Form onSubmit={uploadSignature}>
                <Modal title={t("My signature")} hide={hide}>
                    <ModalBody>
                        <div className={styles.container}>
                            <canvas className={styles.canvas} ref={canvasRef} />

                            <ValidationError
                                isValid={isSignatureNotEmpty}
                                error={t("Please draw your signature.")}
                            />
                        </div>

                        {hasUploadError && (
                            <Alert type="error">
                                {t("An error occurred. Please try again.")}
                            </Alert>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            type="submit"
                            variant="primary"
                            disabled={loading}
                        >
                            {t("Save")}
                        </Button>
                        &nbsp;
                        <Button onClick={clearCanvas}>{t("Clear")}</Button>
                    </ModalFooter>
                </Modal>
            </Form>
        </Portal>
    );
};

export default UserSignatureModal;
