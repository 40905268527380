import { useTranslation } from "react-i18next";
import { IDownloadCsvModalProps } from ".";
import { Link, Loading, Modal, Portal } from "..";
import ModalBody from "../Modal/ModalBody";

const DownloadCsvModal = ({
    hide,
    downloadUrl,
    downloading,
}: IDownloadCsvModalProps) => {
    const { t } = useTranslation();

    return (
        <Portal>
            <Modal
                title={t(downloading ? "Generating CSV" : "Download CSV")}
                hide={hide}
            >
                <ModalBody>
                    {downloading ? (
                        <Loading />
                    ) : (
                        <Link url={downloadUrl}>
                            {t("Click to download CSV")}
                        </Link>
                    )}
                </ModalBody>
            </Modal>
        </Portal>
    );
};

export default DownloadCsvModal;
