import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { KeyValueWidget } from "../../../components";
import { useAwaitingAllocationJobCount } from "../../../utils/api/allocatedJobs";
import { getNow } from "../../../utils/dates";
import { encodeUrl } from "../../../utils/url";

const MissedSlaIncomplete = ({ priorities }: IMissedSlaIncompleteProps) => {
    const { t } = useTranslation();

    const now = useMemo(() => getNow(), []);

    const { value, loaded } = useAwaitingAllocationJobCount({
        jobPriorities: priorities,
        jobStatus: [
            "Awaiting Allocation",
            "Allocated",
            "Awaiting Re-allocation",
        ],
        slaMet: false,
        endDate: now,
    });

    const summary = useMemo(
        () => [
            {
                key: t("Total"),
                value: value?.count,
            },
        ],
        [t, value],
    );

    const url = useMemo(
        () =>
            encodeUrl("/jobs/awaiting-allocation", {
                slaMet: ["=false"],
                slaDate: [`<${now.toISOString()}`],
                ...(!priorities.includes("All") && {
                    jobPriority: [priorities.map((j) => `=${j}`).join("{OR}")],
                }),
            }),
        [now, priorities],
    );

    return (
        <KeyValueWidget
            title={t("SLA missed and incomplete")}
            summary={summary}
            url={url}
            tooltipText={t(
                "Shows all awaiting allocation jobs that have passed their SLA date but have been completed within the date filters selected.",
            )}
            loaded={loaded}
        />
    );
};

interface IMissedSlaIncompleteProps {
    priorities: string[];
}

export default MissedSlaIncomplete;
