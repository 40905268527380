import { useMemo } from "react";
import { IPaginationProps } from ".";
import { Button } from "../..";
import styles from "./Pagination.module.scss";

const Pagination = ({
    currentPage,
    totalPages,
    goToPage,
    goToNextPage,
    goToPreviousPage,
}: IPaginationProps) => {
    const paginationButtons = useMemo<(number | string)[]>(() => {
        const buttons: (number | string)[] = [1];

        if (totalPages > 1) {
            buttons.push(2);
        }

        if (totalPages > 2) {
            const centerButtons = [
                currentPage - 2,
                currentPage - 1,
                currentPage,
                currentPage + 1,
                currentPage + 2,
            ];
            const endButtons = [totalPages - 1, totalPages];

            while (endButtons[0] <= buttons[1]) {
                endButtons.shift();
            }

            while (centerButtons[0] <= buttons[1]) {
                centerButtons.shift();
            }

            if (buttons[buttons.length - 1] < centerButtons[0] - 1) {
                buttons.push("...");
            }

            while (centerButtons[centerButtons.length - 1] >= endButtons[0]) {
                centerButtons.pop();
            }

            buttons.push(...centerButtons);

            if (centerButtons[centerButtons.length - 1] + 1 < endButtons[0]) {
                buttons.push("...");
            }

            buttons.push(...endButtons);
        }

        return buttons;
    }, [currentPage, totalPages]);

    return (
        <div>
            <Button disabled={currentPage <= 1} onClick={goToPreviousPage}>
                &lt;
            </Button>
            {paginationButtons.map((page, index) =>
                typeof page === "string" ? (
                    <span key={page + index} className={styles.separator}>
                        {page}
                    </span>
                ) : (
                    <Button
                        key={page}
                        variant={currentPage === page ? "primary" : ""}
                        clickParams={[page]}
                        onClick={goToPage}
                    >
                        {page}
                    </Button>
                ),
            )}
            <Button disabled={currentPage >= totalPages} onClick={goToNextPage}>
                &gt;
            </Button>
        </div>
    );
};

export default Pagination;
