import { Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IJobCommentsTimelineProps } from ".";
import { Card } from "..";
import { IJobComment } from "../../utils/api/jobs";
import { toDateString } from "../../utils/dates";
import AuthorizedImage from "../AuthorizedImage";
import styles from "./JobCommentsTimeline.module.scss";

const JobCommentsTimeline = ({ comments }: IJobCommentsTimelineProps) => {
    const { t } = useTranslation();

    const groupedComments = useMemo(() => {
        const group: { [key: string]: IJobComment[] } = {};

        const sortedComments = comments.sort((a, b) =>
            a.createdAt > b.createdAt ? -1 : 1,
        );

        for (const comment of sortedComments) {
            const key = toDateString(new Date(comment.createdAt));

            if (!group[key]) {
                group[key] = [];
            }

            group[key].push(comment);
        }

        return group;
    }, [comments]);

    const dateGroups = useMemo(() => {
        const dates = Object.keys(groupedComments);

        return dates.sort((a, b) => (a > b ? -1 : 1));
    }, [groupedComments]);

    return (
        <Card padding={comments.length === 0} title={t("Comments")}>
            {comments.length > 0 ? (
                <>
                    {dateGroups.map((date) => (
                        <Fragment key={date}>
                            <div className={styles.groupHeader}>{date}</div>

                            {groupedComments[date].map((comment) => (
                                <div
                                    key={comment.id}
                                    className={styles.container}
                                >
                                    <div className={styles.image}>
                                        <AuthorizedImage
                                            image={comment.imageUrl}
                                            size={33}
                                            circle={true}
                                            display="inline-block"
                                        />
                                    </div>

                                    <div className={styles.comment}>
                                        {comment.questionAnswer && (
                                            <div className={styles.section}>
                                                <div className={styles.header}>
                                                    {t(
                                                        "Inspection schedule section",
                                                    )}
                                                    :
                                                </div>
                                                {
                                                    comment.questionAnswer
                                                        .question?.questionset
                                                        .questionsetName
                                                }
                                            </div>
                                        )}
                                        {comment.questionAnswer && (
                                            <div className={styles.section}>
                                                <div className={styles.header}>
                                                    {t(
                                                        "Inspection schedule item",
                                                    )}
                                                    :
                                                </div>
                                                {
                                                    comment.questionAnswer
                                                        .question?.questionText
                                                }
                                            </div>
                                        )}
                                        {comment.asset && (
                                            <div className={styles.section}>
                                                <div className={styles.header}>
                                                    {t("Circuit")}:
                                                </div>
                                                {comment.asset.displayName}
                                            </div>
                                        )}
                                        <div className={styles.section}>
                                            <div className={styles.header}>
                                                {t("Notes")}:
                                            </div>
                                            {comment.notes}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Fragment>
                    ))}
                </>
            ) : (
                t("There are no comments")
            )}
        </Card>
    );
};

export default JobCommentsTimeline;
