import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IQualifiedSupervisorParentAuditProps } from ".";
import { Alert, Loading } from "../../../components";
import ModalBody from "../../../components/Modal/ModalBody";
import Stepper, { IStep } from "../../../components/Stepper";
import { useAudit } from "../../../utils/api/audits";
import QualifiedSupervisorHoldAudit from "../QualifiedSupervisorHoldAudit";
import styles from "./QualifiedSupervisorParentAudit.module.scss";

const QualifiedSupervisorParentAudit = ({
    audit,
    isReadOnly,
    createComment,
    onHoldAudit,
    onSaveAudit,
    addAuditJobComment,
    addAuditObservation,
    removeAuditJobComment,
    removeAuditObservation,
    removeAuditOverrides,
    sidebarVisible,
}: IQualifiedSupervisorParentAuditProps) => {
    const { t } = useTranslation();

    const [hasHoldOnParent, setHasHoldOnParent] = useState(false);
    const handleHoldAcquiredOnParent = useCallback(() => {
        if (!hasHoldOnParent) {
            setHasHoldOnParent(true);
            if (audit.childAudits.length > 0) {
                setAuditId(audit.childAudits[0].id);
            }
        }
    }, [audit, hasHoldOnParent]);

    const [completedAudits, setCompletedAudits] = useState<{
        [key: string]: boolean | undefined;
    }>({});
    const [auditId, setAuditId] = useState<number>();

    const {
        value: childAudit,
        addComment: childCreateComment,
        error: childError,
        loaded: childLoaded,
        addAuditJobComment: addChildAuditJobComment,
        addAuditObservation: addChildAuditObservation,
        removeAuditJobComment: removeChildAuditJobComment,
        removeAuditObservation: removeChildAuditObservation,
        removeAuditOverrides: removeChildAuditOverrides,
    } = useAudit(auditId);

    const childAuditNext = useCallback(() => {
        if (auditId !== undefined) {
            const ids = audit.childAudits.map((c) => c.id);

            const index = ids.indexOf(auditId);

            if (index > -1) {
                const nextIndex = index + 1;

                const id = ids.slice(nextIndex, nextIndex + 1).pop();
                setAuditId(id);
            }

            setCompletedAudits((a) => ({ ...a, [auditId]: true }));
        }
    }, [audit, auditId]);

    const steps = useMemo(() => {
        const list: IStep[] = audit.childAudits
            .sort((a, b) => (a.jobId > b.jobId ? 1 : -1))
            .map((child) => ({
                id: child.id.toString(),
                title: t("{{documentType}} {{jobId}}", {
                    documentType: child.documentType,
                    jobId: child.jobId,
                }),
                onClick: () => setAuditId(child.id),
                isCompleted: completedAudits[child.id] ?? false,
            }));

        list.push({
            id: audit.id.toString(),
            title: t("{{documentType}} {{jobId}}", {
                documentType: audit.documentType,
                jobId: audit.jobId,
            }),
            onClick: () => setAuditId(undefined),
            isCompleted: completedAudits[audit.id] ?? false,
        });

        return list;
    }, [audit, completedAudits, t]);

    const isSaveDisabled = useMemo(() => {
        for (const child of audit.childAudits) {
            if (completedAudits[child.id] !== true) {
                return true;
            }
        }

        return false;
    }, [audit, completedAudits]);

    return (
        <>
            <div className={styles.header}>
                <Stepper
                    steps={steps}
                    activeStepId={(auditId ?? audit.id).toString()}
                />
            </div>

            {auditId && !childLoaded ? (
                <ModalBody>
                    <Loading />
                </ModalBody>
            ) : childError ? (
                <ModalBody>
                    <Alert type="error">{t(childError)}</Alert>
                </ModalBody>
            ) : childAudit ? (
                <QualifiedSupervisorHoldAudit
                    audit={childAudit}
                    isReadOnly={isReadOnly}
                    createComment={childCreateComment}
                    onHoldAudit={onHoldAudit}
                    onSaveAudit={childAuditNext}
                    canCompleteAudit={false}
                    addAuditJobComment={addChildAuditJobComment}
                    addAuditObservation={addChildAuditObservation}
                    removeAuditJobComment={removeChildAuditJobComment}
                    removeAuditObservation={removeChildAuditObservation}
                    removeAuditOverrides={removeChildAuditOverrides}
                    sidebarVisible={sidebarVisible}
                />
            ) : (
                <QualifiedSupervisorHoldAudit
                    audit={audit}
                    isReadOnly={isReadOnly}
                    createComment={createComment}
                    onHoldAudit={onHoldAudit}
                    onSaveAudit={onSaveAudit}
                    hasHold={hasHoldOnParent}
                    onHoldAcquired={handleHoldAcquiredOnParent}
                    isSaveDisabled={isSaveDisabled}
                    canOverrideAnswers={false}
                    addAuditJobComment={addAuditJobComment}
                    addAuditObservation={addAuditObservation}
                    removeAuditJobComment={removeAuditJobComment}
                    removeAuditObservation={removeAuditObservation}
                    removeAuditOverrides={removeAuditOverrides}
                    sidebarVisible={sidebarVisible}
                />
            )}
        </>
    );
};

export default QualifiedSupervisorParentAudit;
