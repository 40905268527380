import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { IPropertyFlagsProps } from ".";
import { Checkbox } from "../..";
import { usePropertyFlags } from "../../../utils/api/properties";
import styles from "./PropertyFlags.module.scss";

const PropertyFlags = ({
    propertyId,
    selectedFlags,
    onSelectedFlagsChange,
}: IPropertyFlagsProps) => {
    const { t } = useTranslation();

    const { value, loaded } = usePropertyFlags(propertyId);

    const handleChange = useCallback(
        (checked: boolean, id: string) => {
            const newFlags = { ...selectedFlags };
            newFlags[id] = checked;

            const list = value.filter((flag) => newFlags[flag.id.toString()]);
            onSelectedFlagsChange(list);
        },
        [onSelectedFlagsChange, selectedFlags, value],
    );

    return (
        <>
            {loaded && value.length > 0 && (
                <>
                    <div className={styles.propertyFlags}>
                        {t("Send flags to engineer")}
                    </div>

                    {value.map((flag) => (
                        <div className={styles.propertyCheckbox} key={flag.id}>
                            <Checkbox
                                value={flag.id.toString()}
                                useMargin={false}
                                checked={selectedFlags[flag.id.toString()]}
                                onChange={handleChange}
                            >
                                {flag.name}
                            </Checkbox>
                            <div className={styles.flagDescription}>
                                {flag.description}
                            </div>
                        </div>
                    ))}
                </>
            )}
        </>
    );
};

export default PropertyFlags;
