import { useCallback, useMemo } from "react";
import { IOverrideDateAnswerProps } from ".";
import { InputDatePicker } from "../../../components";

const OverrideDateAnswer = ({
    answer,
    value,
    overrideAnswer,
}: IOverrideDateAnswerProps) => {
    const date = useMemo(() => {
        const newDate = value
            ? new Date(value.replace(/^"(.+)"$/, "$1"))
            : undefined;

        if (newDate?.toString() !== "Invalid Date") {
            return newDate;
        }
    }, [value]);

    const handleDateSelected = useCallback(
        (newValue?: Date) =>
            overrideAnswer(
                answer.id,
                newValue ? `"${newValue.toISOString()}"` : undefined,
            ),
        [answer, overrideAnswer],
    );

    return (
        <InputDatePicker
            label=""
            date={date}
            onDateSelected={handleDateSelected}
            onClearDate={handleDateSelected}
            useMargin={false}
            canClearDate={true}
        />
    );
};

export default OverrideDateAnswer;
