import { ReactChild, ReactNode } from "react";
import { Observable } from "rxjs";
import {
    IFilter,
    IFilterToggle,
    LogicalOperator,
} from "../../utils/api/useFilterable";
import { DataType } from "../../utils/values";
import { ISelectOption } from "../Select";
import { ISortingProps } from "./Sorting";
import Table from "./Table";
import { ITableFiltersProps } from "./TableFilters";
import { ITableFooterProps } from "./TableFooter";
import { ITableHeaders } from "./TableHeader";

interface IFilters {
    toggleFilter: (toggle: IFilterToggle[]) => void;
}

export interface IColumnFilters extends IFilters {
    property: string;
    activeFilters: IFilter[];
    loading: boolean;
    getBooleanFilters?: () => IBooleanFilter[];
    getColumnFilters?: () => string[];
    filterAvailableFilters?: (filters: string[]) => string[];
}

export interface IFilterProps extends IColumnFilters {
    hover: boolean;
    title?: string;
}

export interface IBooleanFilter {
    value: "true" | "false";
    displayText: string;
}

export interface ITableFilters extends IFilters {
    getFilters: (
        property: string,
        userParentIds: number[],
    ) => Observable<string[]>;
}

export interface ITableColumn<T> {
    title: string;
    field?: string;
    sortable?: boolean;
    filterable?: boolean;
    hidden?: boolean;
    canBeToggledByUser?: boolean;
    type?: DataType;
    filterOperator?: LogicalOperator;
    render?: (value: any, row: T, set?: (r: T) => void) => ReactNode;
    filterFormatter?: (value: any) => string;
    getBooleanFilters?: () => IBooleanFilter[];
    getColumnFilters?: () => string[];
    filterAvailableFilters?: (filters: string[]) => string[];
    separator?: boolean;
}

export interface IBulkAction extends ISelectOption {
    onSubmit: (ids: number[]) => void;
}

export interface ITableProps
    extends ITableFooterProps,
        ISortingProps,
        ITableHeaders,
        ITableFilters,
        ITableFiltersProps {
    columns: { [key: string]: ITableColumn<any> };
    records: any[];
    bulkActions?: IBulkAction[];
    hideFilters?: boolean;
    preferences: string;
    loading: boolean;
    actionButton?: ReactChild;
}

export default Table;
