import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IListFilterProps } from ".";
import { Button, Checkbox, Icon, Loading, Popover, UserContext } from "../..";
import { useToggle } from "../../../hooks";
import useApiValue from "../../../utils/api/useApiValue";
import InputField from "../../InputField";

const ListFilter = ({
    property,
    toggleFilter,
    hover,
    operator = "{OR}",
    getFilters,
    getColumnFilters,
    filterAvailableFilters,
    activeFilters,
    title,
    filterFormatter = (value: string) =>
        value === "" ? "''" : value === null ? "(Blanks)" : value,
}: IListFilterProps) => {
    const { t } = useTranslation();
    const { value, loaded, loading, send } = useApiValue<string[]>({});

    const filters = useMemo(() => {
        let list = (getColumnFilters ? getColumnFilters() : value) ?? [];

        if (filterAvailableFilters) {
            list = filterAvailableFilters(list);
        }

        return list;
    }, [value, getColumnFilters, filterAvailableFilters]);

    const { visible, hide, toggle } = useToggle();
    const popoverButton = useRef<HTMLButtonElement>(null);

    // TODO: Remove this code and use useFilter hook instead.
    const [search, setSearch] = useState("");
    const filteredFilters = useMemo(() => {
        if (search) {
            return filters.filter(
                (f) =>
                    f !== null &&
                    f.toLowerCase().includes(search.toLowerCase()),
            );
        }

        return filters;
    }, [filters, search]);

    const userContext = useContext(UserContext);

    useEffect(() => {
        const subscription = userContext.onActiveUserParentsChanged.subscribe(
            (userParentIds) => {
                if (!getColumnFilters && loaded) {
                    send(getFilters(property, userParentIds)).subscribe();
                }
            },
        );

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [property, loaded]);

    const handleClick = () => {
        if (!getColumnFilters && !visible) {
            send(
                getFilters(property, userContext.activeUserParentsIds),
            ).subscribe();
        }

        toggle();
    };

    const selectedFilters = useMemo(() => {
        const dictionary: { [key: string]: boolean | undefined } = {};

        for (const currentFilter of activeFilters) {
            dictionary[currentFilter.value] = true;
        }

        return dictionary;
    }, [activeFilters]);

    const handleFilter = (checked: boolean, filterValue: string) =>
        toggleFilter([
            {
                property,
                filterGroup: {
                    operator,
                    filters: [{ function: "=", value: filterValue }],
                },
                appendFilters: true,
            },
        ]);

    return (
        <>
            <Button
                onClick={handleClick}
                setRef={popoverButton}
                size="small"
                cssRules={
                    hover
                        ? { marginLeft: "5px" }
                        : { marginLeft: "5px", opacity: 0 }
                }
            >
                <Icon
                    icon="list"
                    size={16}
                    ariaLabel={t(`Filter ${title ?? property}`)}
                />
            </Button>

            {visible && (
                <Popover
                    anchorElement={popoverButton}
                    maxWidth={400}
                    hide={hide}
                >
                    {!loading ? (
                        <>
                            <InputField
                                placeholder={t("Filter")}
                                value={search}
                                onChange={setSearch}
                            />
                            {filteredFilters.map((l) => (
                                <Checkbox
                                    key={l}
                                    value={l}
                                    checked={selectedFilters[l] || false}
                                    onChange={handleFilter}
                                >
                                    {filterFormatter(l)}
                                </Checkbox>
                            ))}
                        </>
                    ) : (
                        <Loading />
                    )}
                </Popover>
            )}
        </>
    );
};

export default ListFilter;
