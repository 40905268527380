import { IAudit, IAuditComment } from "../../../utils/api/audits";
import { IAnswerSection } from "../QualifiedSupervisorAudit";
import QualifiedSupervisorAuditSidebar from "./QualifiedSupervisorAuditSidebar";

export interface IQualifiedSupervisorAuditSidebarProps {
    audit: IAudit;
    reviewedSections: { [key: string]: boolean };
    answersSections: IAnswerSection[];
    isReadOnly: boolean;
    isJobSummaryReviewed: boolean;
    isAuditReasonReviewed: boolean;
    isObservationsReviewed: boolean;
    isLimitationsReviewed: boolean;
    createComment: (comment: IAuditComment) => void;
}

export default QualifiedSupervisorAuditSidebar;
