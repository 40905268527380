import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IEicrStatusProps } from ".";
import { Link, PieChart } from "../../../components";
import { IPieChartData } from "../../../components/Charts/PieChart";
import { colours } from "../../../styles/colours";
import { encodeUrl } from "../../../utils/url";
import styles from "./EicrStatus.module.scss";

const EicrStatus = ({ eicrStatus }: IEicrStatusProps) => {
    const { t } = useTranslation();
    const [hoverIndex, setHoverIndex] = useState(-1);

    const pieChartData = useMemo<IPieChartData[]>(() => {
        const dictionary: { [key: string]: number } = {};

        for (const score of eicrStatus) {
            if (score.data !== null) {
                for (const data of score.data) {
                    const label = data.label || "Unknown";
                    const value = data.value;

                    if (!dictionary[label]) {
                        dictionary[label] = value;
                    } else {
                        dictionary[label] += value;
                    }
                }
            }
        }

        return Object.entries(dictionary).map((data, index) => ({
            label: data[0],
            value: data[1],
            color:
                hoverIndex === -1 || hoverIndex === index
                    ? data[0] === "Unknown"
                        ? colours.chart.grey
                        : colours.chart.colours[hoverIndex]
                    : colours.chart.lightGrey,
        }));
    }, [eicrStatus, hoverIndex]);

    const totalCount = useMemo(
        () =>
            pieChartData
                .map((e) => e.value)
                .reduce((current, value) => value + current, 0),
        [pieChartData],
    );

    return (
        <div className={styles.centerContainer}>
            <div className={styles.container}>
                <div>
                    <div className={styles.chartContainer}>
                        <PieChart
                            data={pieChartData}
                            aspectRatio={1}
                            thickness={30}
                            showLabels={false}
                        />
                        <div className={styles.total}>
                            <div className={styles.count}>{totalCount}</div>
                            <div className={styles.label}>
                                {t("Total reports")}
                            </div>
                        </div>
                        <div className={styles.subText}>
                            {t("EICR reports produced this year")}
                        </div>
                    </div>
                </div>

                <div>
                    {pieChartData.map((data, index) => {
                        const onFocus = () => setHoverIndex(index);
                        const onBlur = () => setHoverIndex(-1);

                        return (
                            <Link
                                key={index}
                                url={encodeUrl("/audits/qs", {
                                    activeTab: [data.label.toLowerCase()],
                                    documentType: ["=EICR"],
                                })}
                                display="block"
                                onFocus={onFocus}
                                onBlur={onBlur}
                            >
                                <div className={styles.status}>
                                    <div
                                        className={styles.statusColour}
                                        style={{
                                            backgroundColor:
                                                data.label === "Unknown"
                                                    ? colours.chart.grey
                                                    : colours.chart.colours[
                                                          index
                                                      ],
                                        }}
                                    />
                                    <div
                                        className={styles.value}
                                        style={{
                                            color:
                                                data.label === "Unknown"
                                                    ? colours.chart.grey
                                                    : colours.chart.colours[
                                                          index
                                                      ],
                                        }}
                                    >
                                        {data.value}
                                    </div>
                                    <div className={styles.label}>
                                        {t(`eicrStatus.${data.label}`)}
                                    </div>
                                </div>
                            </Link>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default EicrStatus;
