import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { INotification } from "../..";
import { NotificationsContext } from "../../..";
import useClickableNonInteractiveElement from "../../../../hooks/useClickableNonInteractiveElement";
import AuthorizedImage from "../../../AuthorizedImage";
import styles from "./NotificationPopup.module.scss";

const NotificationPopup = ({ notification }: INotificationPopupProps) => {
    const [visible, setVisible] = useState(true);
    const { t } = useTranslation();
    const { updateNotificationsCount } = useContext(NotificationsContext);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setVisible(false);
        }, 10000);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [notification]);

    const navigate = useNavigate();

    const handleClick = useCallback(() => {
        updateNotificationsCount(notification.category, -1);
        navigate(notification.url);
        setVisible(false);
    }, [navigate, notification, updateNotificationsCount]);

    const { onKeyDown } = useClickableNonInteractiveElement(handleClick);

    // TODO: Take a look at why transparent is needed and placeholder isn't removed when image has loaded
    return (
        <>
            {visible && (
                <div
                    className={styles.popup}
                    onClick={handleClick}
                    onKeyDown={onKeyDown}
                    role="button"
                    tabIndex={0}
                >
                    <div className={styles.icon}>
                        <AuthorizedImage
                            size={50}
                            image={`PortalIcons/NotificationImages/${notification.icon}`}
                            cssRules={{ backgroundColor: "transparent" }}
                        />
                    </div>
                    <div className={styles.details}>
                        <div className={styles.title}>
                            {t(notification.title)}
                        </div>
                        <div className={styles.description}>
                            {t(notification.description)}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

interface INotificationPopupProps {
    notification: INotification;
}

export default NotificationPopup;
