import { groupBy, map, orderBy } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IAssetJobTimelineProps, ITaskMapped } from ".";
import { Card, Loading } from "..";
import { useTasksForAsset } from "../../utils/api/answers";
import { toDateString } from "../../utils/dates";
import Alert from "../Alert";
import JobTimelineJob from "../JobTimeline/JobTimelineJob";
import styles from "./AssetJobTimeline.module.scss";

const AssetJobTimeline = ({ assetId, displayName }: IAssetJobTimelineProps) => {
    const { t } = useTranslation();

    const { records, loaded, error } = useTasksForAsset({ assetId });

    const groupByDate = useMemo(() => {
        if (records.length > 0) {
            const groupedjob = groupBy(
                records.map((task): ITaskMapped => {
                    return {
                        jobId: task.jobId,
                        task: {
                            ...task,
                            type: {
                                id: 0,
                                taskType: task.answers[0].taskType.taskType,
                                displayName:
                                    task.answers[0].taskType.displayName,
                            },
                            appliance: {
                                id: task.asset.id,
                                type: {
                                    id: 0,
                                    displayName: task.asset.assetType,
                                },
                                make: task.asset.displayName,
                                model: "",
                                displayName: task.asset.displayName,
                                pageType: task.asset.assetType,
                            },
                        },
                        engineer: task.engineer,
                        answers: task.answers,
                        date: task.startDate.split("T")[0],
                        time: task.startDate.split("T")[1],
                    };
                }),
                "jobId",
            );

            return groupBy(
                orderBy(
                    groupedjob,
                    [(task) => task[0].date, (task) => task[0].time],
                    ["desc", "asc"],
                ),
                (task) => toDateString(new Date(task[0].date || 0)),
            );
        }
    }, [records]);

    return (
        <Card title={t("Work history")}>
            {loaded && records.length > 0 ? (
                map(groupByDate, (group, date) => (
                    <div key={date}>
                        <div className={styles.group}>{date}</div>
                        {group.map((taskgroup) => {
                            const firstTask = taskgroup[0];
                            return (
                                <JobTimelineJob
                                    key={firstTask.jobId}
                                    job={{
                                        id: firstTask.jobId,
                                        engineer: {
                                            id: firstTask.engineer.id,
                                            name: firstTask.engineer.name,
                                        },
                                        tasks: taskgroup.map((taskmap) => {
                                            return {
                                                ...taskmap.task,
                                                ...taskmap,
                                            };
                                        }),
                                    }}
                                    showTaskIcons={false}
                                    showAddress={false}
                                    showJobType={false}
                                    isChecked={false}
                                    answers={taskgroup.flatMap(
                                        (task) => task.answers,
                                    )}
                                />
                            );
                        })}
                    </div>
                ))
            ) : loaded && records.length === 0 ? (
                t(
                    `There are no records for this ${displayName.toLocaleLowerCase()}`,
                )
            ) : error ? (
                <Alert type="error">
                    {t("Work history data failed to load")}
                </Alert>
            ) : (
                <Loading small={true} centerContent={true} />
            )}
        </Card>
    );
};

export default AssetJobTimeline;
