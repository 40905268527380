import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IPieChartData } from ".";
import { IDrawableObject } from "..";
import { colours } from "../../../styles/colours";
import { animate, noopAnimate } from "../animation";
import { bounce } from "../animations";
import { createChart } from "../chart";
import { roundedRectangle } from "../draw";
import styles from "./PieChart.module.scss";

const PieChart = ({
    data,
    thickness = 30,
    aspectRatio,
    showLabels = true,
    maxHeight,
}: IPieChartProps) => {
    const { t } = useTranslation();
    const [chart, setChart] = useState<IChart | null>(null);

    const chartData = useMemo(() => {
        const limit = colours.chart.colours.length;

        const records = data.slice(0, limit);
        const other = data.slice(limit);

        if (other.length > 0) {
            records[limit - 1] = {
                label: t("Other"),
                value:
                    records[limit - 1].value +
                    other.reduce((sum, current) => sum + current.value, 0),
            };
        }

        return records;
    }, [data, t]);

    const getLabels = useCallback(
        (animateObject = true): IDrawableObject => {
            const animation = animateObject
                ? animate(1500, bounce)
                : noopAnimate();

            let startPosition: number;
            let xPosition: number;
            let offsetY: number;

            const onResize = (width: number, height: number) => {
                startPosition = height / 2;
                xPosition = width / 2;
                offsetY = Math.min(height / chartData.length, 20);
            };

            const draw = (context: CanvasRenderingContext2D, time: number) => {
                const isAnimating = animation.updateFrame(time);
                const x = animation.getValue(xPosition, startPosition);
                const rectSize = animation.getValue(12);

                context.save();

                chartData.forEach((group, index) => {
                    const y = animation.getValue(
                        offsetY * index,
                        startPosition,
                    );

                    roundedRectangle(context, x, y, rectSize, rectSize, 2);
                    context.fillStyle =
                        group.color || colours.chart.colours[index];
                    context.fill();

                    context.fillStyle = colours.chart.grey;
                    context.fillText(
                        group.label + ": " + group.value,
                        x + 20,
                        y + 10,
                    );
                });
                context.restore();

                return isAnimating;
            };

            return { draw, onResize };
        },
        [chartData],
    );

    const total = useMemo(() => {
        return chartData.reduce((sum, current) => sum + current.value, 0);
    }, [chartData]);

    const getAngle = useCallback(
        (value: number) => (value / total) * 2 * Math.PI,
        [total],
    );

    const getPie = useCallback(
        (animateObject = true): IDrawableObject => {
            const animation = animateObject
                ? animate(1500, bounce)
                : noopAnimate();

            let outerDiameter: number;
            let radius: number;
            let center: number;

            const onResize = (width: number, height: number) => {
                outerDiameter = height / 2;
                radius = Math.max((height - thickness) / 2, 0);
                center = height / 2;
            };

            const draw = (context: CanvasRenderingContext2D, time: number) => {
                const isAnimating = animation.updateFrame(time);
                const lineWidth = animation.getValue(thickness);

                let offsetValue = 0;
                context.save();
                context.lineWidth = lineWidth;
                chartData.forEach((group, index) => {
                    const startAngle = animation.getValue(
                        getAngle(offsetValue),
                    );
                    const endAngle = animation.getValue(
                        getAngle(offsetValue + group.value),
                        startAngle,
                    );

                    offsetValue += group.value;

                    if (endAngle > startAngle) {
                        context.beginPath();
                        context.arc(
                            center,
                            center,
                            radius,
                            startAngle,
                            endAngle,
                        );
                        context.strokeStyle =
                            group.color || colours.chart.colours[index];
                        context.stroke();
                    }
                });
                context.restore();

                offsetValue = 0;
                context.save();
                context.strokeStyle = colours.chart.white;
                context.lineWidth = 2;
                chartData.forEach((group) => {
                    const angle = animation.getValue(getAngle(offsetValue));

                    offsetValue += group.value;

                    context.beginPath();
                    context.moveTo(center, center);
                    context.lineTo(
                        center + Math.cos(angle) * outerDiameter,
                        center + Math.sin(angle) * outerDiameter,
                    );
                    context.stroke();
                });
                context.restore();

                return isAnimating;
            };

            return { draw, onResize };
        },
        [chartData, getAngle, thickness],
    );

    const canvasRef = useCallback(
        (node: HTMLCanvasElement | null) => {
            if (node) {
                const newChart = createChart({
                    aspectRatio,
                    canvas: node,
                    objects: showLabels ? [getPie(), getLabels()] : [getPie()],
                    fontSize: 13,
                    maxHeight,
                });

                setChart(newChart);
            }
        },
        [aspectRatio, getLabels, getPie, maxHeight, showLabels],
    );

    useEffect(() => {
        if (chart) {
            chart.updateObjects(
                showLabels
                    ? [getPie(false), getLabels(false)]
                    : [getPie(false)],
            );
        }
    }, [chart, chartData, getLabels, getPie, showLabels, total]);

    useEffect(() => {
        return () => {
            if (chart) {
                chart.destroy();
            }
        };
    }, [chart]);

    return <canvas className={styles.chart} ref={canvasRef} />;
};

interface IChart {
    updateObjects: (objects: IDrawableObject[]) => void;
    destroy: () => void;
}

interface IPieChartProps {
    thickness?: number;
    data: IPieChartData[];
    aspectRatio?: number;
    showLabels?: boolean;
    maxHeight?: number;
}

export default PieChart;
