const getAnimatedValue = (
    startValue: number,
    endValue: number,
    progress: number,
) => {
    return startValue + (endValue - startValue) * progress;
};

const noopAnimate = () => {
    const updateFrame = (time: number) => false;
    const getValue = (value: number, startValue?: number) => value;

    return { updateFrame, getValue };
};

const animate = (
    duration: number,
    method: (progress: number) => number,
    delay = 0,
) => {
    let renderTime = 0;
    let progress = 1;

    const updateFrame = (time: number) => {
        if (!renderTime) {
            renderTime = time;
        }

        let isAnimating = false;
        if (time - renderTime < delay) {
            progress = 0;
            isAnimating = true;
        } else if (time - renderTime - delay <= duration) {
            progress = method((time - renderTime - delay) / duration);
            isAnimating = true;
        } else {
            progress = 1;
        }

        return isAnimating;
    };

    const getValue = (value: number, startValue = 0) => {
        return getAnimatedValue(startValue, value, progress);
    };

    return { updateFrame, getValue };
};

const loop = (duration: number, method: (progress: number) => number) => {
    let renderTime = 0;
    let progress = 1;

    const updateFrame = (time: number) => {
        if (!renderTime) {
            renderTime = time;
        }

        const isAnimating = true;
        progress = method(
            (progress = ((time - renderTime) % duration) / duration),
        );

        return isAnimating;
    };

    const getValue = (value: number, startValue = 0) => {
        return getAnimatedValue(startValue, value, progress);
    };

    return { updateFrame, getValue };
};

export { animate, loop, noopAnimate };
