import { ReactNode, useCallback, useContext, useMemo, useState } from "react";
import { IPropertyCategoriesContext } from ".";
import { UserPreferencesContext } from "..";
import { useLandlordPropertyCategories } from "../../utils/api/landlords";
import { IPropertyCategory } from "../../utils/api/misc";
import {
    onSelectedPropertyCategoriesChanged,
    onSelectedPropertyCategoriesChangedSubject,
} from "../../utils/propertyCategories";
import { UserContext } from "../UserProvider";
import PropertyCategoriesContext from "./PropertyCategoriesContext";

const PropertyCategoriesProvider = ({
    children,
}: IPropertyCategoriesProviderProps) => {
    const landlordPropertyCategories = useLandlordPropertyCategories({
        functionalOnly: true,
    });

    const { user } = useContext(UserContext);

    const propertyCategories = useMemo<IPropertyCategory[]>(() => {
        const contains = new Set();
        const result: IPropertyCategory[] = [];

        if (user.propertyCategories.length > 0) {
            user.propertyCategories
                .map((pc) => {
                    return {
                        id: pc.propertyCategoryId,
                        name: pc.propertyCategory.name,
                        displayName: pc.propertyCategory.displayName,
                        colour: pc.propertyCategory.colour,
                    };
                })
                .forEach((pc) => result.push(pc));
        } else {
            if (landlordPropertyCategories.loaded) {
                landlordPropertyCategories.value
                    .map((lpc) => lpc.propertyCategory)
                    .forEach((pc) => {
                        if (!contains.has(pc.id)) {
                            result.push(pc);
                            contains.add(pc.id);
                        }
                    });
            }
        }

        return result;
    }, [
        landlordPropertyCategories.loaded,
        landlordPropertyCategories.value,
        user,
    ]);

    const { preferences, updatePreferences, loading } = useContext(
        UserPreferencesContext,
    );

    const selectCategories = useCallback(
        (ids: number[]) => {
            const pref = { ...preferences };
            pref.fuel_type = ids.join(",");
            updatePreferences(pref).subscribe();
        },
        [preferences, updatePreferences],
    );

    const [isReady, setIsReady] = useState(false);

    const selectedCategories = useMemo(() => {
        if (propertyCategories.length > 0) {
            const categories: IPropertyCategory[] = [];
            setIsReady(true);

            if (propertyCategories.length === 1) {
                categories.push(propertyCategories[0]);
            } else {
                const fuelTypeString = preferences.fuel_type || "";
                const fuelTypes = fuelTypeString.split(",");

                categories.push(
                    ...propertyCategories.filter(
                        (c) =>
                            fuelTypes.map((f) => Number(f)).includes(c.id) ||
                            fuelTypes.includes(c.name),
                    ),
                );
            }

            onSelectedPropertyCategoriesChangedSubject.next(
                categories.map((c) => c.id),
            );

            return categories;
        }

        return [];
    }, [preferences.fuel_type, propertyCategories]);

    const context = useMemo<IPropertyCategoriesContext>(() => {
        return {
            onSelectedPropertyCategoriesChanged,
            propertyCategories,
            selectedCategories,
            selectCategories,
            isReady,
            loading,
        };
    }, [
        isReady,
        loading,
        propertyCategories,
        selectCategories,
        selectedCategories,
    ]);

    return (
        <PropertyCategoriesContext.Provider value={context}>
            {children}
        </PropertyCategoriesContext.Provider>
    );
};

interface IPropertyCategoriesProviderProps {
    children: ReactNode;
}

export default PropertyCategoriesProvider;
