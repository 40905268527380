import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { IRecentWorksCardProps } from ".";
import {
    Card,
    Icon,
    Link,
    Loading,
    PropertyCategoriesContext,
} from "../../../components";
import RecentWorks from "../RecentWorks";
import SingleRecentWorks from "../SingleRecentWorks";

const RecentWorksCard = ({
    loaded,
    data,
    maxHeight,
}: IRecentWorksCardProps) => {
    const { t } = useTranslation();

    const { selectedCategories } = useContext(PropertyCategoriesContext);

    return (
        <Card
            title={t("Recent works carried out")}
            maxHeight={maxHeight}
            fullHeight={true}
            actions={
                <Link url="/jobs/jobs" ariaLabel={t("View all jobs")}>
                    <Icon
                        icon="eye"
                        size={12}
                        display="inline-block"
                        cssRules={{ marginRight: "5px" }}
                        ariaHidden={true}
                    />

                    {t("View all")}
                </Link>
            }
        >
            {loaded ? (
                selectedCategories.length > 1 ? (
                    <RecentWorks works={data.recentWorks} />
                ) : (
                    <SingleRecentWorks works={data.recentWorks} />
                )
            ) : (
                <Loading />
            )}
        </Card>
    );
};

export default RecentWorksCard;
