import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ContractorRedirectPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate("/jobs");
    }, [navigate]);

    return <></>;
};

export default ContractorRedirectPage;
