import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { noop } from "rxjs";
import {
    Button,
    DatePicker,
    Icon,
    InputField,
    Popover,
} from "../../components";
import { useToggle } from "../../hooks";
import { toDateString } from "../../utils/dates";

const InputDatePicker = ({
    label,
    date,
    useMargin = true,
    canClearDate = false,
    onDateSelected,
    onClearDate = noop,
    isValid,
    error,
}: IInputDatePickerProps) => {
    const { t } = useTranslation();

    const { hide, show, visible } = useToggle();
    const datePickerInput = useRef<HTMLInputElement>(null);

    const handleDateSelect = (newDate: Date) => {
        onDateSelected(newDate);
        hide();
    };

    return (
        <>
            <InputField
                value={date && toDateString(date)}
                label={label}
                readOnly={true}
                useMargin={useMargin}
                setRef={datePickerInput}
                onFocus={show}
                isValid={isValid}
                error={error}
                append={
                    date &&
                    canClearDate && (
                        <Button size="small" onClick={onClearDate}>
                            <Icon icon="remove" ariaLabel={t("Clear date")} />
                        </Button>
                    )
                }
            />

            {visible && (
                <Popover
                    anchorElement={datePickerInput}
                    hide={hide}
                    placement="bottom-start"
                >
                    <DatePicker
                        openAtDate={date}
                        selectedFromDate={date}
                        selectedToDate={date}
                        onDateSelected={handleDateSelect}
                    />
                </Popover>
            )}
        </>
    );
};

interface IInputDatePickerProps {
    label: string;
    date?: Date;
    onDateSelected: (date: Date) => void;
    onClearDate?: () => void;
    canClearDate?: boolean;
    useMargin?: boolean;
    isValid?: boolean;
    error?: string;
}

export default InputDatePicker;
