import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Observable } from "rxjs";
import { IPropertyCategory, IUpdatePropertyCategory } from ".";
import { UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const useUpdatePropertyCategory = (
    propertyId: number,
): [
    (
        categoryId: number,
        data: IUpdatePropertyCategory,
    ) => Observable<IPropertyCategory>,
    boolean,
    string,
] => {
    const { t } = useTranslation();
    const { activeUserParentsIds } = useContext(UserContext);
    const { send, loading, error } = useApiRequest<IPropertyCategory>({
        errorMessage: t("Unexpected error occurred. Please try again later."),
    });
    const sendRequest = useXTagApiRequest();

    const updatePropertyRequest = useCallback(
        (categoryId: number, propertyCategory: IUpdatePropertyCategory) =>
            send(
                sendRequest({
                    url: `${config.propertiesApiUrl}/${propertyId}/categories/${categoryId}`,
                    method: "PUT",
                    body: propertyCategory,
                    urlParams: getGlobalUrlParameters([], activeUserParentsIds),
                }),
            ),
        [send, sendRequest, propertyId, activeUserParentsIds],
    );

    return [updatePropertyRequest, loading, error];
};

export default useUpdatePropertyCategory;
