import { useTranslation } from "react-i18next";
import { Link, Loading } from "../../../components";
import AuthorizedImage from "../../../components/AuthorizedImage";
import ComplianceCategory from "../../../components/ComplianceCategory";
import useRecentUnableToAccess from "../../../utils/api/jobs/useRecentUnableToAccess";
import { toDateTimeString } from "../../../utils/dates";
import styles from "./RecentUnableToAccess.module.scss";

const RecentUnableToAccess = () => {
    const { t } = useTranslation();
    const recentUtas = useRecentUnableToAccess();

    return (
        <>
            {recentUtas.loaded ? (
                recentUtas.records.map((uta) => (
                    <div className={styles.unableToAccess} key={uta.id}>
                        <div className={styles.imageWrapper}>
                            <AuthorizedImage
                                image={uta.imageUrl}
                                size={40}
                                circle={true}
                                testId="UnableToAccessImage_Div"
                            />
                        </div>

                        <div className={styles.textWrapper}>
                            <Link
                                url={`/issues/unabletoaccess/${uta.id}`}
                                testId="UnableToAccessDateTime_A"
                            >
                                {t(`days.${new Date(uta.date).getDay()}`)}
                                &nbsp;
                                {toDateTimeString(new Date(uta.date))}
                            </Link>
                            &nbsp;
                            {t("by")}&nbsp;
                            <Link
                                url={`/management/engineers/${uta.engineer.id}`}
                                testId="UnableToAccessEngineer_A"
                            >
                                {uta.engineer.name}
                            </Link>
                            <br />
                            <Link
                                url={`/management/properties/${uta.property.id}`}
                                testId="UnableToAccessPropertyAddressString_A"
                            >
                                {uta.property.addressString}
                            </Link>
                        </div>

                        <div>
                            <ComplianceCategory
                                displayName={uta.complianceType.displayName}
                                colour={uta.complianceType.colour}
                            />
                        </div>
                    </div>
                ))
            ) : (
                <Loading />
            )}
        </>
    );
};

export default RecentUnableToAccess;
