import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Link, Loading } from "../../../../components";
import { ISimpleJob } from "../../../../utils/api/jobs";
import { toDateString, toTimeString } from "../../../../utils/dates";
import styles from "./MatchJobRow.module.scss";

const MatchJobRow = ({ job, matchJob }: IMatchJobRowProps) => {
    const { t } = useTranslation();
    const jobDate = new Date(job.date);

    const [isMatching, setIsMatching] = useState(false);

    const matchJobRow = () => {
        matchJob(job.id);
        setIsMatching(true);
    };

    return (
        <div className={styles.container}>
            <div className={styles.jobColumn}>
                <Link url={`/jobs/jobs/${job.id}`}>#{job.id}</Link>
            </div>
            <div className={styles.dateColumn}>
                {toDateString(jobDate)} {t("at")} {toTimeString(jobDate)}
            </div>
            <div className={styles.taskColumn}>
                {job.totalTasks} {t("tasks carried out by")}&nbsp;
                {
                    <Link url={`/management/engineers/${job.engineer.id}`}>
                        {job.engineer.name}
                    </Link>
                }
            </div>
            <div className={styles.matchColumn}>
                <Button onClick={matchJobRow}>
                    {isMatching ? <Loading small={true} /> : t("Match Job")}
                </Button>
            </div>
        </div>
    );
};

interface IMatchJobRowProps {
    job: ISimpleJob;
    matchJob: (jobId: number) => void;
}

export default MatchJobRow;
