import { useTranslation } from "react-i18next";
import { useCssClasses } from "../../hooks";
import styles from "./ComplianceCategory.module.scss";

const ComplianceCategory = ({
    displayName,
    colour,
    displayBlock = false,
}: IComplianceCategoryProps) => {
    const cssClasses = useCssClasses(
        styles.compliance,
        displayBlock ? "" : styles.inline,
    );
    const { t } = useTranslation();

    return (
        <div
            className={cssClasses}
            style={{ backgroundColor: colour }}
            data-test-id="PropertyCategory_Div"
        >
            {t(displayName)}
        </div>
    );
};

interface IComplianceCategoryProps {
    displayName: string;
    colour: string;
    displayBlock?: boolean;
}

export default ComplianceCategory;
