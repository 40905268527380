import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    AssignUser,
    Button,
    Card,
    ColourPill,
    Form,
    InputField,
    Loading,
    SearchableSelect,
    SelectFilter,
} from "../../components";
import Alert, { AlertType } from "../../components/Alert";
import useCreateIssue from "../../utils/api/issues/useCreateIssue";
import { useJobs } from "../../utils/api/jobs";
import { usePropertyCategories } from "../../utils/api/misc";
import { useProperties } from "../../utils/api/properties";
import useAllUsers from "../../utils/api/users/useAllUsers";
import { getDifferenceInDays, getToday, toDateString } from "../../utils/dates";
import {
    isRequired,
    maxLength,
    useValidateField,
    validateForm,
} from "../../utils/validation";
import styles from "./CreateIssue.module.scss";

const CreateIssue = () => {
    const properties = useProperties();
    const jobs = useJobs({});
    const issueUsers = useAllUsers();
    const propertyCategories = usePropertyCategories();
    const createIssue = useCreateIssue();

    const [description, setDescription] = useState("");
    const [jobId, setJobId] = useState("");
    const [propertyId, setPropertyId] = useState("");
    const [propertyCategoryId, setPropertyCategoryId] = useState("");
    const [landlordId, setLandlordId] = useState("");
    const [assignedUserId, setAssignedUserId] = useState("");
    const [alertType, setAlertType] = useState<AlertType | "">("");

    const [usingImplicitFuelType, setUsingImplicitFuelType] = useState(false);

    const { t } = useTranslation();

    const maximumLength = 300;

    const handlePropertyChange = (value: string) => {
        const property = properties.records.find(
            (prop) => prop.id.toString() === value,
        );

        if (property) {
            setLandlordId(property.landlord.id.toString());
        }

        setPropertyId(value);
        setJobId("");

        if (
            propertyCategoryId &&
            !getFilteredPropertyCategories(value)
                .map((pc) => pc.id.toString())
                .includes(propertyCategoryId)
        ) {
            setPropertyCategoryId("");
        }

        jobs.refresh({
            "property.id": {
                filters: [{ function: "", value: value.toString() }],
            },
        });
    };

    const handleJobChange = (value: string) => {
        if (value) {
            const job = jobs.records.find((j) => j.id.toString() === value);

            if (job) {
                if (job.complianceType) {
                    setPropertyCategoryId(job.complianceType.id.toString());
                } else {
                    const gasFuelType = propertyCategories.records.find(
                        (pc) => pc.name === "gas",
                    );

                    if (gasFuelType) {
                        setPropertyCategoryId(gasFuelType.id.toString());
                    }
                }

                setUsingImplicitFuelType(true);
            }
        } else {
            setUsingImplicitFuelType(false);
            setPropertyCategoryId("");
        }

        setJobId(value);
    };

    const handleSubmitClicked = () => {
        setAlertType("");

        createIssue
            .createIssue({
                title: "Custom Issue",
                description,
                jobId: jobId ? Number(jobId) : undefined,
                propertyId: Number(propertyId),
                assignedUserId: assignedUserId
                    ? Number(assignedUserId)
                    : undefined,
                fuelTypeId: Number(propertyCategoryId),
                notificationType: "CustomIssue",
                notificationCategory: "issue",
                landlordId: Number(landlordId),
            })
            .subscribe(
                () => {
                    setJobId("");
                    setPropertyId("");
                    setPropertyCategoryId("");
                    setDescription("");
                    setLandlordId("");
                    setAssignedUserId("");
                    setAlertType("success");

                    descriptionValidator.reset();
                    propertyFieldValidator.reset();
                },
                () => {
                    setAlertType("error");
                },
            );
    };

    const parsedProperties = useMemo(() => {
        return properties.records.map((prop) => {
            let differenceInDays = 0;
            const date = prop.nextServiceDueDate
                ? toDateString(new Date(prop.nextServiceDueDate))
                : "";

            if (prop.nextServiceDueDate) {
                differenceInDays = Math.floor(
                    getDifferenceInDays(
                        getToday(),
                        new Date(prop.nextServiceDueDate),
                    ),
                );
            }

            return {
                label: (
                    <div
                        className={`${styles.propertyDropdownItem} ${
                            differenceInDays > 30
                                ? styles.compliantBorder
                                : differenceInDays >= 0
                                ? styles.dueSoonBorder
                                : styles.overdueBorder
                        }`}
                        key={prop.id}
                    >
                        <p>{prop.addressString}</p>
                        {differenceInDays > 0 ? (
                            <>
                                {t("Due in")}&nbsp;
                                <span
                                    className={
                                        differenceInDays > 30
                                            ? styles.compliant
                                            : styles.dueSoon
                                    }
                                >
                                    {differenceInDays}
                                </span>
                                &nbsp;
                                {t("daysCount", {
                                    count: differenceInDays,
                                })}
                                &nbsp;{`(${date})`}
                            </>
                        ) : (
                            <span className={styles.overdue}>
                                {t("Overdue")}
                            </span>
                        )}
                        {prop.categories.map((categories) => {
                            return (
                                <span
                                    className={styles.propertyCategory}
                                    key={categories.id}
                                >
                                    <ColourPill
                                        customColour={categories.colour}
                                        value={t(categories.displayName)}
                                    />
                                </span>
                            );
                        })}
                    </div>
                ),
                value: prop.id.toString(),
            };
        });
    }, [properties.records, t]);

    const parsedJobs = useMemo(() => {
        return jobs.records.map((j) => ({
            label: `${toDateString(new Date(j.date))} ${
                j.property.addressString
            } ${j.jobType || ""}`,
            value: j.id.toString(),
        }));
    }, [jobs.records]);

    const [propertyCategoryFilter, setPropertyCategoryFilter] = useState("");

    /***
     * Get the list of available property categories, based on the filter and the selected property.
     */
    const getFilteredPropertyCategories = useCallback(
        (propId: string) => {
            let result = propertyCategories.records.filter(
                (pc) =>
                    !propertyCategoryFilter ||
                    pc.displayName
                        .toLocaleLowerCase()
                        .includes(propertyCategoryFilter.toLocaleLowerCase()),
            );

            const property = properties.records.find(
                (prop) => prop.id.toString() === propId,
            );

            if (property) {
                result = result.filter((pc) =>
                    property.categories
                        .map((ppc) => ppc.propertyCategoryId)
                        .includes(pc.id),
                );
            }

            return result;
        },
        [
            properties.records,
            propertyCategories.records,
            propertyCategoryFilter,
        ],
    );

    const parsedPropertyCategories = useMemo(
        () =>
            getFilteredPropertyCategories(propertyId).map((pc) => ({
                label: pc.displayName,
                value: pc.id.toString(),
            })),
        [getFilteredPropertyCategories, propertyId],
    );

    const descriptionValidator = useValidateField(
        description,
        isRequired(),
        maxLength(maximumLength),
    );

    const propertyFieldValidator = useValidateField(propertyId, isRequired());

    const propertyCategoryIdValidator = useValidateField(
        propertyCategoryId,
        isRequired(),
    );

    const filterFormValidator = validateForm(() => [
        descriptionValidator,
        propertyFieldValidator,
        propertyCategoryIdValidator,
    ]);

    const customPropertyRender = (value: string) => {
        const property = properties.records.find(
            (prop) => prop.id.toString() === value,
        );

        return property && property.addressString;
    };

    return properties.loaded && jobs.loaded && issueUsers.loaded ? (
        <Card title={t("Add New Issue")}>
            <Form onSubmit={handleSubmitClicked} {...filterFormValidator}>
                <InputField
                    placeholder={t("Issue Description")}
                    value={description}
                    onChange={setDescription}
                    {...descriptionValidator}
                />
                <SelectFilter
                    label={t("Property")}
                    options={parsedProperties}
                    onChange={handlePropertyChange}
                    value={propertyId}
                    applyPadding={false}
                    {...properties}
                    {...propertyFieldValidator}
                    customRender={customPropertyRender}
                />
                <SelectFilter
                    label={t("Job")}
                    options={parsedJobs}
                    onChange={handleJobChange}
                    value={jobId}
                    disabled={parsedJobs.length <= 0}
                    {...jobs}
                />
                {!usingImplicitFuelType && (
                    <SearchableSelect
                        label={t("Fuel Type")}
                        placeholder={t("Search")}
                        options={parsedPropertyCategories}
                        value={propertyCategoryId}
                        onChange={setPropertyCategoryId}
                        applySearch={setPropertyCategoryFilter}
                        {...propertyCategoryIdValidator}
                    />
                )}
                <AssignUser
                    users={issueUsers.records}
                    onChange={setAssignedUserId}
                    value={assignedUserId}
                />
                {!createIssue.loading ? (
                    <Button
                        variant="primary"
                        type="submit"
                        cssRules={{ marginBottom: "10px" }}
                    >
                        {t("Create Issue")}
                    </Button>
                ) : (
                    <Loading small={true} />
                )}
                {alertType && (
                    <Alert type={alertType}>
                        {alertType === "success"
                            ? t("Successfully created issue")
                            : t("Failed to create issue")}
                    </Alert>
                )}
            </Form>
        </Card>
    ) : (
        <Loading />
    );
};

export default CreateIssue;
