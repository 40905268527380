import { createContext } from "react";
import { noop, of } from "rxjs";
import { IPropertyCategoriesContext } from ".";

const PropertyCategoriesContext = createContext<IPropertyCategoriesContext>({
    onSelectedPropertyCategoriesChanged: of([]),
    propertyCategories: [],
    selectedCategories: [],
    selectCategories: noop,
    loading: false,
    isReady: false,
});

export default PropertyCategoriesContext;
