import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Map } from "..";
import propertyImage from "../../images/map/property.svg";
import { IMapMarker } from "../Map";

const PropertyMap = ({ properties }: IPropertyMapProps) => {
    const { t } = useTranslation();
    const [markers, setMarkers] = useState<IMapMarker[]>([]);

    useEffect(() => {
        const array: IMapMarker[] = [];

        for (const landlordProperties of properties) {
            for (const property of landlordProperties) {
                const id = property[0];
                const lat = property[1] || 0;
                const lng = property[2] || 0;

                array.push({
                    type: "Feature",
                    geometry: {
                        type: "Point",
                        coordinates: [lng, lat],
                    },
                    id,
                    properties: {
                        icon: propertyImage,
                        infoWindow: `<div><a href="/management/properties/${id}">${t(
                            "View property",
                        )}</a></div>`,
                    },
                });
            }
        }

        setMarkers(array);
    }, [properties, t]);

    return (
        <Map
            clusterMarkers={true}
            markers={markers}
            height="100%"
            minHeight={300}
        />
    );
};

interface IPropertyMapProps {
    properties: [number, number | null, number | null, number][][];
}

export default PropertyMap;
