// Polyfills
import "intersection-observer";
//
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./i18n";
import "./index.scss";
import { unregister } from "./serviceWorker";
import "./styles/icomoon/style.css";

const container = document.getElementById("root");
if (container !== null) {
    const root = createRoot(container);

    root.render(
        <BrowserRouter>
            <App />
        </BrowserRouter>,
    );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
unregister();
