import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BarChart, Card, Loading } from "../../../components";
import InfoTooltip from "../../../components/InfoToolTip";
import { useAwaitingAllocationJobPriorities } from "../../../utils/api/allocatedJobs";
import { getToday } from "../../../utils/dates";

const UpcomingJobsDays = ({ priorities }: IUpcomingJobsDaysProps) => {
    const { t } = useTranslation();
    const [today] = useState(getToday());

    const { value, labels, loaded } = useAwaitingAllocationJobPriorities({
        jobPriorities: priorities,
        duration: 10,
        startDate: today,
        unit: "days",
    });

    return (
        <Card
            title={t("Upcoming jobs in the next 10 days")}
            fullHeight={true}
            actions={
                <InfoTooltip
                    tooltipText={t(
                        "Shows all awaiting allocation jobs with an SLA within the next 10 days, grouped by their SLA priority.",
                    )}
                />
            }
        >
            {loaded ? (
                <BarChart
                    data={value}
                    labels={labels}
                    tooltipLabels={labels}
                    maxHeight={240}
                />
            ) : (
                <Loading small={true} smallWidth={100} centerContent={true} />
            )}
        </Card>
    );
};

interface IUpcomingJobsDaysProps {
    priorities: string[];
}

export default UpcomingJobsDays;
