import { useContext, useEffect, useMemo } from "react";
import { map } from "rxjs";
import { PropertyCategoriesContext, UserContext } from "../../../components";
import config from "../../../config";
import { addDays, addHours, toDateString, toTimeString } from "../../dates";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiValue from "../useApiValue";

const useAwaitingAllocationJobPriorities = ({
    jobPriorities,
    startDate,
    duration,
    unit,
}: IUseAwaitingAllocationJobPrioritiesProps) => {
    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);
    const { value, loaded, send } = useApiValue<
        { label: string; values: number[] }[]
    >({});
    const sendRequest = useXTagApiRequest();

    const labels = useMemo(() => {
        const labelFunction = () => {
            if (unit === "days") return toDateString;
            return toTimeString;
        };

        const incrementFunction = () => {
            if (unit === "days") return addDays;
            return addHours;
        };

        const tempLabels: string[] = [];
        for (let i = 0; i <= duration; i++) {
            const time = incrementFunction()(startDate, i);
            tempLabels.push(labelFunction()(time));
        }
        return tempLabels;
    }, [duration, startDate, unit]);

    useEffect(() => {
        const timeFormat = unit === "hours" ? "hourly" : "daily";
        const endDate =
            unit === "hours"
                ? addHours(startDate, duration)
                : addDays(startDate, duration);

        const subscription = send(
            sendRequest<IGroupedJobPriorities[], unknown>({
                url: `${config.allocatedJobsApiUrl}/awaitingallocation/v2/priorities/${timeFormat}`,
                method: "GET",
                urlParams: {
                    ...(!jobPriorities.includes("All") && {
                        jobPriority: [
                            jobPriorities.map((j) => `=${j}`).join("{OR}"),
                        ],
                    }),
                    slaDate: [
                        `>=${startDate.toISOString()}{AND}<${endDate.toISOString()}`,
                    ],
                    ...getGlobalUrlParameters(
                        selectedCategories.map((c) => c.id),
                        activeUserParentsIds,
                    ),
                },
            }).pipe(
                map((jobs) => {
                    let comparerFunction: (
                        date: Date,
                        date2: Date,
                    ) => Boolean = () => false;
                    let incrementFunction: (
                        date: Date,
                        increment: number,
                    ) => Date = () => new Date();

                    switch (unit) {
                        case "days": {
                            incrementFunction = addDays;
                            comparerFunction = (date, date2) =>
                                date.getDate() === date2.getDate();
                            break;
                        }
                        case "hours": {
                            incrementFunction = addHours;
                            comparerFunction = (date, date2) =>
                                date.getTime() === date2.getTime();
                            break;
                        }
                    }

                    const dictionary: { [key: string]: number[] } = {};

                    if (jobs !== null) {
                        jobs.forEach(
                            (job) => (dictionary[job.jobPriority] = []),
                        );
                        for (let i = 0; i <= duration; i++) {
                            const time = incrementFunction(startDate, i);
                            for (const job of jobs) {
                                dictionary[job.jobPriority][i] = 0;
                                for (const dateCount of job.awaitingAllocationCountsByDate) {
                                    if (
                                        comparerFunction(
                                            new Date(dateCount.slaDate),
                                            time,
                                        )
                                    ) {
                                        dictionary[job.jobPriority][i] =
                                            dateCount.count;
                                    }
                                }
                            }
                        }
                    }
                    return Object.keys(dictionary).map((key) => ({
                        label: key,
                        values: dictionary[key],
                    }));
                }),
            ),
        ).subscribe();

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [
        activeUserParentsIds,
        duration,
        jobPriorities,
        selectedCategories,
        send,
        sendRequest,
        startDate,
        unit,
    ]);
    return {
        value,
        labels,
        loaded,
    };
};

interface IUseAwaitingAllocationJobPrioritiesProps {
    startDate: Date;
    duration: number;
    unit: "days" | "hours";
    jobPriorities: string[];
}

interface IGroupedJobPriorities {
    jobPriority: string;
    awaitingAllocationCountsByDate: JobCountBySlaDate[];
}

interface JobCountBySlaDate {
    count: number;
    slaDate: Date;
}

export default useAwaitingAllocationJobPriorities;
