import { AuditStatus } from "../../../utils/api/audits";
import TableActions from "./TableActions";

export interface ITableActionsProps {
    id: number;
    auditorUserId?: number;
    status: AuditStatus;
    userParentId: number | null;
    viewAudits: (ids: number[]) => void;
    startAuditing: (ids: number[]) => void;
    removeHold: (ids: number[]) => void;
}

export default TableActions;
