import { orderBy } from "lodash";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { map } from "rxjs";
import { PropertyCategoriesContext, UserContext } from "../../components";
import { getGlobalUrlParameters, useXTagApiRequest } from "../request";
import { getUrlParameters, IUrlParameters } from "../url";
import useApiValue from "./useApiValue";

function useFilterable<T>(
    url: string,
    {
        params,
        filtersUrl,
        discardPreviousValue,
        disableFuelTypeFiltering,
        disableUserParentsFiltering,
    }: IFilterableOptionalProps = {},
    onRecordsLoaded: (
        values: T[],
        currentPage: number,
        totalPages: number,
    ) => void = () => null,
) {
    const { onSelectedPropertyCategoriesChanged, selectedCategories } =
        useContext(PropertyCategoriesContext);
    const { onActiveUserParentsChanged, activeUserParentsIds } =
        useContext(UserContext);

    const getFilter = (value: string, operator: LogicalOperator) => {
        const selectedFilters = value.split(operator);

        const currentfilters: IFilter[] = [];
        for (const selectedFilter of selectedFilters) {
            if (selectedFilter.startsWith(">=")) {
                currentfilters.push({
                    function: ">=",
                    value: selectedFilter.substring(">=".length),
                });
            } else if (selectedFilter.startsWith("<=")) {
                currentfilters.push({
                    function: "<=",
                    value: selectedFilter.substring("<=".length),
                });
            } else if (selectedFilter.startsWith("=")) {
                currentfilters.push({
                    function: "=",
                    value: selectedFilter.substring("=".length),
                });
            } else if (selectedFilter.startsWith(">")) {
                currentfilters.push({
                    function: ">",
                    value: selectedFilter.substring(">".length),
                });
            } else if (selectedFilter.startsWith("<")) {
                currentfilters.push({
                    function: "<",
                    value: selectedFilter.substring("<".length),
                });
            } else if (selectedFilter.startsWith("!=")) {
                currentfilters.push({
                    function: "!=",
                    value: selectedFilter.substring("!=".length),
                });
            }
        }

        return currentfilters;
    };

    const defaults = useMemo(() => {
        const urlParams = getUrlParameters();
        const urlFilters: IFilters = {};

        for (const key of Object.keys(urlParams)) {
            const value = urlParams[key][0];

            if (value) {
                if (value.includes("{AND}")) {
                    urlFilters[key] = {
                        operator: "{AND}",
                        filters: getFilter(value, "{AND}"),
                    };
                } else {
                    urlFilters[key] = {
                        operator: "{OR}",
                        filters: getFilter(value, "{OR}"),
                    };
                }
            }
        }

        const values: IFilterableDefaults = {
            sortProperty: "",
            sortDirection: "asc",
            itemsPerPage: 10,
            filters: urlFilters,
        };

        return {
            ...values,
            ...params,
            filters: {
                ...values.filters,
                ...(params && params.filters),
            },
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [totalPages, setTotalPages] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(defaults.itemsPerPage);
    const [sortProperty, setSortProperty] = useState(defaults.sortProperty);
    const [sortDirection, setSortDirection] = useState<SortDirection>(
        defaults.sortDirection,
    );
    const [filters, setFilters] = useState<IFilters>(defaults.filters);
    const [search, setSearch] = useState("");
    const {
        send,
        value,
        error,
        loading,
        loaded,
        updateValue: updateInnerValue,
    } = useApiValue<T[]>({
        initialValue: [],
        discardPreviousValue,
    });

    useEffect(() => {
        if (disableUserParentsFiltering !== true) {
            const subscription = onActiveUserParentsChanged.subscribe(() => {
                refresh();
            });

            return () => {
                if (subscription) {
                    subscription.unsubscribe();
                }
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    useEffect(() => {
        if (disableFuelTypeFiltering !== true) {
            const subscription = onSelectedPropertyCategoriesChanged.subscribe(
                () => {
                    refresh();
                },
            );

            return () => {
                if (subscription) {
                    subscription.unsubscribe();
                }
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    const sendRequest = useXTagApiRequest();

    useEffect(() => {
        const urlParams = getUrlParams();

        const subscription = send(
            sendRequest<IFilterable<T>, unknown>({
                url,
                method: "GET",
                urlParams,
            }).pipe(
                map((response) => {
                    setTotalRecords(response.totalResults);
                    setTotalPages(response.totalPages);

                    onRecordsLoaded(
                        response.results,
                        currentPage,
                        response.totalPages,
                    );

                    return response.results;
                }),
            ),
        ).subscribe();

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        sendRequest,
        itemsPerPage,
        search,
        sortProperty,
        sortDirection,
        currentPage,
        filters,
    ]);

    const getAllRecords = () => {
        const urlParams: IUrlParameters = {
            ...getUrlParams(),
            fetchTotalResults: ["false"],
            page: ["1"],
            pageSize: [totalRecords.toString()],
        };

        return sendRequest<IFilterable<T>, unknown>({
            url,
            method: "GET",
            urlParams,
        }).pipe(map((response) => response.results));
    };

    const refresh = useCallback((newFilters?: IFilters) => {
        setCurrentPage(1);
        setFilters((f) => ({ ...f, ...newFilters }));
    }, []);

    const getFiltersUrlParams: () => IUrlParameters = () => {
        const urlParams: IUrlParameters = {};

        for (const key of Object.keys(filters)) {
            const filter = filters[key];

            const currentFilters = filter.filters
                .map((f) => `${f.function}${f.value}`)
                .join(filter.operator);

            if (currentFilters) {
                urlParams[key] = [currentFilters];
            }
        }

        return {
            ...urlParams,
            ...getGlobalUrlParameters(
                disableFuelTypeFiltering
                    ? []
                    : selectedCategories.map((c) => c.id),
                disableUserParentsFiltering ? [] : activeUserParentsIds,
            ),
        };
    };

    const getUrlParams = () => {
        const urlParams: IUrlParameters = {
            fetchTotalResults: ["true"],
            page: [currentPage.toFixed()],
            pageSize: [itemsPerPage.toString()],
        };

        if (search) {
            urlParams.queryString = [search.trim()];
        }

        if (sortProperty) {
            urlParams.sortBy = [sortProperty];
            urlParams.sortDirection = [sortDirection];
        }

        const filterParams = getFiltersUrlParams();

        return { ...urlParams, ...filterParams };
    };

    const getFilters = (field: string) => {
        const filtersUrlParams = getFiltersUrlParams();

        delete filtersUrlParams[field];

        return sendRequest<string[], unknown>({
            url: filtersUrl || `${url}/filters/unique`,
            method: "GET",
            urlParams: {
                field: [field],
                ...filtersUrlParams,
            },
        });
    };

    const goToPage = useCallback((page: number) => setCurrentPage(page), []);
    const goToPreviousPage = useCallback(
        () => setCurrentPage((p) => p - 1),
        [],
    );
    const goToNextPage = useCallback(() => setCurrentPage((p) => p + 1), []);

    const clearFilters = useCallback(
        (property: string) => {
            const tempFilters = { ...filters };
            const filter = tempFilters[property];

            if (!filter.readOnly) {
                delete tempFilters[property];

                setCurrentPage(1);
                setFilters(tempFilters);
            }
        },
        [filters],
    );

    const toggleFilter = (toggle: IFilterToggle[]) => {
        const tempFilters = { ...filters };

        for (const tFilter of toggle) {
            const filterProperty = tempFilters[tFilter.property];
            const readOnly =
                (filterProperty && filterProperty.readOnly) || false;

            if (!readOnly) {
                if (filterProperty && tFilter.appendFilters) {
                    const values = filterProperty.filters.map((f) => f.value);

                    const filterArray = [...filterProperty.filters];

                    for (const filter of tFilter.filterGroup.filters) {
                        const index = values.indexOf(filter.value);

                        if (index > -1) {
                            values.splice(index, 1);
                            filterArray.splice(index, 1);
                        } else {
                            values.push(filter.value);
                            filterArray.push(filter);
                        }
                    }

                    filterProperty.operator = tFilter.filterGroup.operator;
                    filterProperty.filters = filterArray;
                } else {
                    tempFilters[tFilter.property] = tFilter.filterGroup;
                }
            }
        }

        setCurrentPage(1);
        setFilters(tempFilters);
    };

    const sort = (property: string, direction: SortDirection = "asc") => {
        setSortProperty(property);
        setSortDirection(direction);
    };

    const updateItemsPerPage = (ipp: number) => {
        setCurrentPage(1);
        setItemsPerPage(ipp);
    };

    const applySearch = useCallback((query: string) => {
        setCurrentPage(1);
        setSearch(query);
    }, []);

    const updateValue = useCallback(
        (newValue: T[]) =>
            updateInnerValue(orderBy(newValue, sortProperty, sortDirection)),
        [sortDirection, sortProperty, updateInnerValue],
    );

    return {
        records: value,
        error,
        loading,
        loaded,
        applySearch,
        clearFilters,
        filters,
        getAllRecords,
        currentPage,
        goToPage,
        itemsPerPage,
        goToPreviousPage,
        goToNextPage,
        search,
        sort,
        sortDirection,
        sortProperty,
        toggleFilter,
        setFilters,
        totalPages,
        totalRecords,
        updateItemsPerPage,
        getFilters,
        refresh,
        updateValue,
    };
}

export interface IFilterableOptionalProps {
    params?: IFilterableParams;
    filtersUrl?: string;
    discardPreviousValue?: boolean;
    disableFuelTypeFiltering?: boolean;
    disableUserParentsFiltering?: boolean;
}

interface IFilterableDefaults {
    sortProperty: string;
    sortDirection: SortDirection;
    itemsPerPage: number;
    filters: IFilters;
}

export interface IFilterToggle {
    property: string;
    filterGroup: IFilterGroup;
    appendFilters: boolean;
}

export interface IFilters {
    [key: string]: IFilterGroup;
}

export interface IFilterableParams {
    sortProperty?: string;
    sortDirection?: SortDirection;
    itemsPerPage?: number;
    filters?: IFilters;
}

export type LogicalOperator = "{AND}" | "{OR}";
type FilterFunction = "" | "=" | "!=" | ">" | ">=" | "<" | "<=";
export type SortDirection = "asc" | "desc";

export interface IFilterable<T> {
    totalPages: number;
    totalResults: number;
    results: T[];
}

export interface IFilter {
    value: string;
    function: FilterFunction;
}

export interface IFilterGroup {
    // TODO: Move the operator to the ITableColumn interface.
    operator?: LogicalOperator;
    filters: IFilter[];
    readOnly?: boolean;
}

export default useFilterable;
