import { useMemo } from "react";
import { useToggle } from "../../../hooks";
import useClickableNonInteractiveElement from "../../../hooks/useClickableNonInteractiveElement";
import { IAnswer } from "../../../utils/api/answers";
import { IJobTask } from "../../../utils/api/jobs";
import JobTimelineAnswers from "../JobTimelineAnswers";
import JobTimelineTask from "../JobTimelineTask";
import styles from "./TaskContainer.module.scss";

const TaskContainer = ({
    answers,
    task,
    showTaskIcons,
    otherTask = false,
    isPortalStandard,
}: ITaskContainerProps) => {
    const { toggle, visible } = useToggle();

    const { onKeyDown } = useClickableNonInteractiveElement(toggle);

    const taskDisplayName = useMemo(() => {
        if (otherTask) {
            return task.type.displayName;
        } else if (
            task.type.displayName === "Electric Inspection Schedule Item"
        ) {
            return task.questionSetName;
        }

        return task.type.displayName;
    }, [task, otherTask]);

    return (
        <>
            <div
                className={styles.container}
                onClick={toggle}
                onKeyDown={onKeyDown}
                role="button"
                tabIndex={0}
            >
                <JobTimelineTask
                    showTaskIcons={showTaskIcons}
                    task={task}
                    dropdownArrow={visible ? "caret-down" : "caret-right"}
                    showAnswers={answers.length > 0}
                    taskDisplayName={taskDisplayName}
                    isPortalStandard={isPortalStandard}
                />
            </div>

            {answers.length > 0 && visible && (
                <JobTimelineAnswers
                    answers={answers.filter(
                        (answer) => answer.taskId === task.id,
                    )}
                />
            )}
        </>
    );
};

interface ITaskContainerProps {
    answers: IAnswer[];
    task: IJobTask;
    showTaskIcons?: boolean;
    otherTask?: boolean;
    isPortalStandard: boolean;
}

export default TaskContainer;
