import { useContext, useEffect } from "react";
import { filter, map } from "rxjs";
import RealtimeContext from "../../../components/RealtimeProvider/RealtimeContext";
import config from "../../../config";
import { clearCache } from "../../cache";
import { realtimeCreateList } from "../../realtime";
import { IJob, ISimpleJob } from "../jobs";
import useFilterable from "../useFilterable";

const useEngineerJobs = (
    engineerId: string,
    onRecordsLoaded?: (
        values: ISimpleJob[],
        currentPage: number,
        totalPages: number,
    ) => void,
) => {
    const {
        records,
        loading,
        loaded,
        currentPage,
        totalPages,
        goToNextPage,
        updateValue,
    } = useFilterable<ISimpleJob>(
        config.jobsApiUrl,
        {
            params: {
                sortProperty: "date",
                sortDirection: "desc",
                filters: {
                    engineerId: {
                        operator: "{AND}",
                        filters: [{ function: "=", value: engineerId }],
                    },
                    parentJobId: {
                        operator: "{AND}",
                        readOnly: true,
                        filters: [{ function: "=", value: "null" }],
                    },
                },
            },
        },
        onRecordsLoaded,
    );
    const { realtimeObservable } = useContext(RealtimeContext);

    useEffect(() => {
        const subscription = realtimeObservable
            .pipe(
                filter((e) => e.entity === "job" && e.event === "created"),
                map((e) => e.payload as IJob),
                filter((e) => Number(engineerId) === e.engineer.id),
            )
            .subscribe((value) => {
                // TODO: This works because the hook is used in a InfiniteScroll. Find a better way to do it.
                const updatedList = realtimeCreateList<ISimpleJob, IJob>(
                    value,
                    [],
                );

                if (updatedList) {
                    updateValue(updatedList);
                }

                clearCache();
            });

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [engineerId, realtimeObservable, records, updateValue]);

    return {
        loading,
        records,
        loaded,
        currentPage,
        totalPages,
        goToNextPage,
    };
};

export default useEngineerJobs;
