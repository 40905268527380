import { ILandlordDocumentPreferences } from "../../../../utils/api/landlords";
import { IPaperworkType } from "../../../../utils/api/misc";
import DocumentPreferencesForm from "./DocumentPreferencesForm";

export interface IDocumentPreferencesFormProps {
    paperworkTypes: IPaperworkType[];
    preferences: ILandlordDocumentPreferences;
    isLoading: boolean;
    error: string;
    updatePreferences: (preferences: ILandlordDocumentPreferences) => void;
}

export default DocumentPreferencesForm;
