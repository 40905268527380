import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Button,
    Card,
    Link,
    Loading,
    Modal,
    Portal,
    Table,
} from "../../components";
import ModalBody from "../../components/Modal/ModalBody";
import { ITableColumn } from "../../components/Table";
import { IEventLog, useEventLogs } from "../../utils/api/misc";
import AllocatedJobEventLogEntity from "./AllocatedJobEventLogEntity";
import styles from "./EventLogs.module.scss";
import ManualUploadEventLogEntity from "./ManualUploadEventLogEntity";
import PropertyCategoryEventLogEntity from "./PropertyCategoryEventLogEntity";

const EventLogs = () => {
    const { t } = useTranslation();
    const eventLogs = useEventLogs({
        sortProperty: "date",
        sortDirection: "desc",
    });
    const { loaded } = eventLogs;

    const [eventLog, setEventLog] = useState<IEventLog | null>(null);
    const handleClickHideModal = useCallback(() => {
        setEventLog(null);
    }, []);

    const columns = useMemo(() => {
        const renderProperty = (_: unknown, row: IEventLog) => (
            <Link url={`/management/properties/${row.property.id}`}>
                {row.property.addressString}
            </Link>
        );

        const renderActions = (_: unknown, row: IEventLog) => {
            const handleClickShowModal = () => {
                setEventLog(row);
            };

            return (
                <Button onClick={handleClickShowModal}>
                    {t("View details")}
                </Button>
            );
        };

        const col: { [key: string]: ITableColumn<IEventLog> } = {
            date: {
                title: t("Time"),
                type: "datetime",
            },
            "property.addressString": {
                title: t("Property"),
                render: renderProperty,
            },
            "user.name": {
                title: t("User"),
            },
            entity: {
                title: t("Entity"),
                render: (value: string) => t(value),
                filterFormatter: (value: string) => t(value),
            },
            type: {
                title: t("Event"),
                render: (value: string) => t(value),
                filterFormatter: (value: string) => t(value),
            },
            actions: {
                title: t("Actions"),
                filterable: false,
                sortable: false,
                canBeToggledByUser: false,
                render: renderActions,
            },
        };

        return col;
    }, [t]);

    return (
        <Card title={t("Event Logs")}>
            {loaded ? (
                <Table
                    preferences="eventlogs-table"
                    columns={columns}
                    canDownloadCsv={false}
                    {...eventLogs}
                />
            ) : (
                <Loading />
            )}

            {eventLog && (
                <Portal>
                    <Modal
                        title={t("Event log details")}
                        hide={handleClickHideModal}
                    >
                        <ModalBody withPadding={false}>
                            <table className={styles.table}>
                                {eventLog.entity === "AllocatedJob" && (
                                    <AllocatedJobEventLogEntity
                                        eventLog={eventLog}
                                    />
                                )}
                                {eventLog.entity === "PropertyCategory" && (
                                    <PropertyCategoryEventLogEntity
                                        eventLog={eventLog}
                                    />
                                )}
                                {eventLog.entity === "ManualUpload" && (
                                    <ManualUploadEventLogEntity
                                        eventLog={eventLog}
                                    />
                                )}
                            </table>
                        </ModalBody>
                    </Modal>
                </Portal>
            )}
        </Card>
    );
};

export default EventLogs;
