import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const LandlordRedirectPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate("/compliance");
    }, [navigate]);

    return <></>;
};

export default LandlordRedirectPage;
