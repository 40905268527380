import { useContext, useEffect } from "react";
import { filter, map } from "rxjs";
import { IJob, ISimpleJob } from ".";
import RealtimeContext from "../../../components/RealtimeProvider/RealtimeContext";
import config from "../../../config";
import { clearCache } from "../../cache";
import { realtimeCreateList, realtimeUpdateList } from "../../realtime";
import { IIssue } from "../issues";
import { IProperty } from "../properties";
import useFilterable from "../useFilterable";

const usePropertyJobs = (
    { propertyId, jobDate, recoveryJob }: IPropertyJobParameters,
    onRecordsLoaded?: (
        values: ISimpleJob[],
        currentPage: number,
        totalPages: number,
    ) => void,
) => {
    const { realtimeObservable } = useContext(RealtimeContext);

    const {
        records,
        loading,
        updateValue,
        currentPage,
        totalPages,
        goToNextPage,
    } = useFilterable<ISimpleJob>(
        config.jobsApiUrl,
        {
            params: {
                sortProperty: "date",
                sortDirection: "desc",
                filters: {
                    "property.id": {
                        operator: "{AND}",
                        filters: [{ function: "=", value: propertyId }],
                    },
                    parentJobId: {
                        operator: "{AND}",
                        readOnly: true,
                        filters: [{ function: "=", value: "null" }],
                    },
                    ...(jobDate && {
                        date: {
                            operator: "{AND}",
                            filters: [{ function: ">", value: jobDate }],
                        },
                    }),
                    ...(recoveryJob !== undefined && {
                        recoveryJob: {
                            operator: "{AND}",
                            readOnly: true,
                            filters: [
                                {
                                    function: "=",
                                    value: recoveryJob.toString(),
                                },
                            ],
                        },
                    }),
                },
            },
        },
        onRecordsLoaded,
    );

    useEffect(() => {
        const subscription = realtimeObservable
            .pipe(
                filter((e) => e.entity === "issue" && e.event === "created"),
                map((e) => e.payload as IIssue),
                filter((e) => Number(propertyId) === e.property.id),
            )
            .subscribe((value) => {
                const jobs = [...records];

                for (const job of jobs) {
                    if (value.job && value.job.id === job.id) {
                        job.totalIssues += 1;

                        updateValue(jobs);

                        break;
                    }
                }

                clearCache();
            });

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [propertyId, realtimeObservable, records, updateValue]);

    useEffect(() => {
        const subscription = realtimeObservable
            .pipe(
                filter((e) => e.entity === "property" && e.event === "updated"),
                map((e) => e.payload as IProperty),
                filter((e) => Number(propertyId) === e.id),
            )
            .subscribe((value) => {
                const jobs = [...records];

                for (const job of jobs) {
                    if (job.property.id === value.id) {
                        job.property = {
                            id: value.id,
                            addressString: value.addressString,
                            imageUrl: value.imageUrl,
                            lat: value.address.lat,
                            long: value.address.long,
                        };
                    }
                }

                updateValue(jobs);
                clearCache();
            });

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [propertyId, realtimeObservable, records, updateValue]);

    useEffect(() => {
        const subscription = realtimeObservable
            .pipe(
                filter((e) => e.entity === "job" && e.event === "created"),
                map((e) => e.payload as IJob),
                filter((e) => Number(propertyId) === e.property.id),
            )
            .subscribe((value) => {
                const updatedList = realtimeCreateList(value, records);

                if (updatedList) {
                    updateValue(updatedList);
                }

                clearCache();
            });

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [propertyId, realtimeObservable, records, updateValue]);

    useEffect(() => {
        const subscription = realtimeObservable
            .pipe(
                filter((e) => e.event === "updated" && e.entity === "job"),
                map((e) => e.payload as IJob),
            )
            .subscribe((value) => {
                const updatedList = realtimeUpdateList({ ...value }, records);

                if (updatedList) {
                    updateValue(updatedList);
                }

                clearCache();
            });

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [realtimeObservable, records, updateValue]);

    return { records, loading, currentPage, totalPages, goToNextPage };
};

interface IPropertyJobParameters {
    propertyId: string;
    jobDate?: string;
    recoveryJob?: boolean;
}

export default usePropertyJobs;
