import { IComplianceType } from "../../../utils/api/jobs";
import JobInformation from "./JobInformation";

export interface IJobInformationProps {
    jobId: number;
    jobType: string;
    engineer: string;
    propertyId: number;
    address: string;
    complianceType: IComplianceType | undefined;
    jobDate: Date;
}

export default JobInformation;
