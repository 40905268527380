import { ReactNode } from "react";
import { Observable } from "rxjs";
import EditableListItem from "./EditableListItem";

export interface IEditableListItemProps<T> extends IEditableListItem<T> {
    isEditing: boolean;
    deleteModalTitle: string;
    deleteModalMessage: string;
    entity?: T;
    onSave: (entity: T) => void;
    onCancel: (id: string) => void;
    onEdit?: (id: string) => void;
    onDelete?: (entity: T) => void;
    canEdit?: boolean;
}

export interface IEditableListItem<T> {
    getEntityId: (entity: T) => string;
    renderForm: (props: IEditableListItemFormProps<T>) => ReactNode;
    renderView: (entity: T) => ReactNode;
    deleteRequest: (id: string) => Observable<T>;
}

export interface IEditableListItemFormProps<T> {
    entity?: T;
    onSave: (entity: T) => void;
    onCancel: () => void;
}

export default EditableListItem;
