import { IEditableDetailsData } from "../EditableDetailsTable";
import Asset from "./Asset";

export interface IAssetProps {
    id: string;
    displayName: string;
    getColumns: (editingToggle: boolean) => IEditableDetailsData[];
}

export default Asset;
