import { IModalBodyProps } from ".";
import { useCssClasses } from "../../../hooks";
import styles from "./ModalBody.module.scss";

const ModalBody = ({ children, withPadding = true }: IModalBodyProps) => {
    const bodyCssClasses = useCssClasses(
        styles.body,
        withPadding ? styles.padding : "",
    );
    return <div className={bodyCssClasses}>{children}</div>;
};

export default ModalBody;
