import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import install from "../../../images/jobTypes/install.png";
import occupiedCap from "../../../images/jobTypes/occupied-cap.png";
import repair from "../../../images/jobTypes/repair.png";
import service from "../../../images/jobTypes/service.png";
import turnOnAndTest from "../../../images/jobTypes/turn-on-and-test.png";
import voidCap from "../../../images/jobTypes/void-cap.png";

const JobIcon = ({ jobType = "Service" }: IJobIconProps) => {
    const { t } = useTranslation();

    const jobImage = useMemo(() => {
        switch (jobType) {
            case "Repair":
                return repair;
            case "Install":
                return install;
            case "Occupied Cap":
            case "Occupied Cap Maintenance":
                return occupiedCap;
            case "Void Cap":
            case "Void Cap Maintenance":
                return voidCap;
            case "Turn on and Test":
                return turnOnAndTest;
            case "Service":
            default:
                return service;
        }
    }, [jobType]);

    return (
        <div>
            <img src={jobImage} alt={t(`{{jobType}} job`, { jobType })} />
        </div>
    );
};

interface IJobIconProps {
    jobType?: string;
}

export default JobIcon;
