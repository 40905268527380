import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IPropertyDetailsEditModalProps } from ".";
import {
    Button,
    EditablePill,
    Form,
    InputField,
    Modal,
    Portal,
    PropertyFlagEditor,
} from "../../../components";
import ModalBody from "../../../components/Modal/ModalBody";
import ModalFooter from "../../../components/Modal/ModalFooter";
import { useToggle } from "../../../hooks";
import { useFlags } from "../../../utils/api/misc";
import { useUpdateProperty } from "../../../utils/api/properties";
import { clearCache } from "../../../utils/cache";
import {
    isRequired,
    useValidateField,
    validateForm,
} from "../../../utils/validation";
import styles from "./PropertyDetailsEditModal.module.scss";

const PropertyDetailsEditModal = ({
    property,
    updateProperty,
    hide,
}: IPropertyDetailsEditModalProps) => {
    const { t } = useTranslation();

    const [addressOne, setAddressOne] = useState(property.address.addressOne);
    const [addressTwo, setAddressTwo] = useState(property.address.addressTwo);
    const [city, setCity] = useState(property.address.city);
    const [postcode, setPostcode] = useState(property.address.postcode);
    const [uprn, setUprn] = useState(property.uprn);
    const [propertyType, setPropertyType] = useState(property.propertyType);
    const [gasMeterLocation, setGasMeterLocation] = useState(
        property.gasMeterLocation,
    );

    const {
        show: flagsModalShow,
        hide: flagsModalHide,
        visible: flagsModalVisible,
    } = useToggle();

    const { value: flags, updateValue: updateFlags } = useFlags();

    const [selectedFlagsIds, setSelectedFlagsIds] = useState(
        property.flags.map((f) => f.id),
    );

    const handleSaveFlags = useCallback(
        (newFlags: number[]) => {
            flagsModalHide();
            setSelectedFlagsIds(newFlags);
        },
        [flagsModalHide],
    );

    const selectedFlags = useMemo(() => {
        if (flags) {
            return flags.filter((f) => selectedFlagsIds.includes(f.id));
        }
        return [];
    }, [flags, selectedFlagsIds]);

    const { update, loading } = useUpdateProperty();

    const handleSubmit = useCallback(() => {
        update(property.id, {
            id: property.id,
            address: {
                ...property.address,
                addressOne,
                addressTwo,
                city,
                postcode,
            },
            landlord: property.landlord,
            gasMeterLocation,
            tagString: property.tag?.tagString ?? "",
            tag: property.tag,
            propertyType,
            active: true,
            uprn,
            flag: selectedFlags,
            isCapped: property.isCapped,
            cappedDate: property.cappedDate,
            isVoid: property.isVoid,
            voidDate: property.voidDate,
            buildDate: property.buildDate,
        }).subscribe(() => {
            updateProperty({
                ...property,
                address: {
                    ...property.address,
                    addressOne,
                    addressTwo,
                    city,
                    postcode,
                },
                gasMeterLocation,
                propertyType,
                uprn,
                flags: selectedFlags,
            });
            clearCache();
            hide();
        });
    }, [
        property,
        addressOne,
        addressTwo,
        city,
        postcode,
        propertyType,
        selectedFlags,
        gasMeterLocation,
        uprn,
        updateProperty,
        update,
        hide,
    ]);

    const addressOneValidation = useValidateField(addressOne, isRequired());
    const postcodeValidation = useValidateField(postcode, isRequired());
    const formValidation = validateForm(() => [
        addressOneValidation,
        postcodeValidation,
    ]);

    return (
        <Portal>
            <Form onSubmit={handleSubmit} {...formValidation}>
                <Modal hide={hide} title={t("Edit property details")}>
                    <ModalBody>
                        <InputField
                            label={t("Address One")}
                            value={addressOne}
                            onChange={setAddressOne}
                            {...addressOneValidation}
                        />

                        <InputField
                            label={t("Address Two")}
                            value={addressTwo}
                            onChange={setAddressTwo}
                        />

                        <InputField
                            label={t("City")}
                            value={city}
                            onChange={setCity}
                        />

                        <InputField
                            label={t("Postcode")}
                            value={postcode}
                            onChange={setPostcode}
                            {...postcodeValidation}
                        />

                        <InputField
                            label={t("UPRN")}
                            value={uprn}
                            onChange={setUprn}
                        />

                        <InputField
                            label={t("Property Type")}
                            value={propertyType}
                            onChange={setPropertyType}
                        />

                        <InputField
                            label={t("Gas Meter Location")}
                            value={gasMeterLocation}
                            onChange={setGasMeterLocation}
                        />

                        <div className={styles.label}>{t("Flags")}</div>
                        {selectedFlags.map((item) => (
                            <EditablePill
                                key={item.id}
                                name={item.name}
                                colour={item.colour}
                                title={item.name}
                            />
                        ))}

                        <Button onClick={flagsModalShow} size="small">
                            {t("Edit")}
                        </Button>

                        {flagsModalVisible && (
                            <Portal>
                                <Modal
                                    hide={flagsModalHide}
                                    title={t("Edit Property Flags")}
                                >
                                    <ModalBody>
                                        <PropertyFlagEditor
                                            landlordId={property.landlord.id}
                                            flags={flags}
                                            selectedFlagsIds={selectedFlagsIds}
                                            onSave={handleSaveFlags}
                                            onCancel={flagsModalHide}
                                            updateValue={updateFlags}
                                        />
                                    </ModalBody>
                                </Modal>
                            </Portal>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            type="submit"
                            variant="primary"
                            disabled={loading}
                        >
                            {t("Save")}
                        </Button>
                    </ModalFooter>
                </Modal>
            </Form>
        </Portal>
    );
};

export default PropertyDetailsEditModal;
