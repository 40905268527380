import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Button,
    Form,
    Icon,
    InputField,
    Popover,
} from "../../../../components";
import { useCssClasses, useToggle } from "../../../../hooks";
import { colours } from "../../../../styles/colours";
import { IPhase } from "../../../../utils/api/properties";
import {
    greaterThan,
    isInteger,
    isNotForbidden,
    isRequired,
    lessThanOrEqual,
    useValidateField,
    validateForm,
} from "../../../../utils/validation";
import PhaseLimitsItem from "../PhaseLimitsItem";
import styles from "./PhaseLimitsRow.module.scss";

const PhaseLimitsRow = ({
    id,
    phase,
    isEditing,
    excludedValues,
    onToggleEdit,
    onUpdateClick,
    onDeleteClick,
    preventDelete = false,
}: IPhaseLimitsRowProps) => {
    const [title, setTitle] = useState(phase.title);
    const [phaseTimeFrame, setPhaseTimeFrame] = useState(
        phase.phaseTimeFrame.toString(),
    );
    const [colour, setColour] = useState(phase.colour);

    const { t } = useTranslation();

    const {
        visible: hoverVisible,
        show: onMouseOver,
        hide: onMouseOut,
    } = useToggle();

    const colorRef = useRef<HTMLDivElement>(null);
    const { hide, toggle, visible } = useToggle();

    const handleUpdateClick = () => {
        onUpdateClick(id, {
            title,
            phaseTimeFrame: Number(phaseTimeFrame),
            colour,
            dates: phase.dates,
        });
    };

    const titleValidator = useValidateField(title, isRequired());
    const phaseTimeFrameValidator = useValidateField(
        phaseTimeFrame,
        isRequired(),
        isInteger(),
        greaterThan(0),
        lessThanOrEqual(90),
        isNotForbidden(
            excludedValues.map((v) => v.toString()),
            t("Your number is already in use."),
        ),
    );
    const colourValidator = useValidateField(colour, isRequired());
    const formValidator = validateForm(() => [
        titleValidator,
        phaseTimeFrameValidator,
        colourValidator,
    ]);

    const cssClasses = useCssClasses(
        styles.td,
        hoverVisible ? "" : styles.hidden,
    );

    return (
        <tr
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            onFocus={onMouseOver}
            onBlur={onMouseOut}
            data-test-id="Phase_Tr"
        >
            <td className={styles.td} data-test-id="PhaseName_Td">
                {isEditing ? (
                    <InputField
                        value={title}
                        onChange={setTitle}
                        useMargin={false}
                        placeholder={t("Title")}
                        testId="PhaseName_Input"
                        {...titleValidator}
                    />
                ) : (
                    title
                )}
            </td>
            <td className={styles.td} data-test-id="PhaseTimeFrame_Td">
                {isEditing ? (
                    <InputField
                        value={phaseTimeFrame}
                        type="number"
                        onChange={setPhaseTimeFrame}
                        placeholder={t("Time frame")}
                        useMargin={false}
                        testId="PhaseTimeFrame_Input"
                        {...phaseTimeFrameValidator}
                    />
                ) : (
                    phase.phaseTimeFrame
                )}
            </td>
            <td className={styles.td}>
                <PhaseLimitsItem
                    setRef={colorRef}
                    onClick={toggle}
                    colour={colour}
                    isEditing={isEditing}
                    testId="PhaseColor_Div"
                />

                {visible && (
                    <Popover
                        anchorElement={colorRef}
                        hide={hide}
                        maxWidth={200}
                    >
                        {colours.colourPicker.map((color) => (
                            <PhaseLimitsItem
                                key={color}
                                colour={color}
                                isSelected={colour === color}
                                hide={hide}
                                updateColour={setColour}
                                isInline={true}
                                isEditing={isEditing}
                            />
                        ))}
                    </Popover>
                )}
            </td>
            <td className={cssClasses}>
                <Form onSubmit={handleUpdateClick} {...formValidator}>
                    {isEditing && (
                        <Button type="submit" testId="PhaseSubmit_Button">
                            <Icon
                                icon="check"
                                size={18}
                                color="green"
                                cssRules={{
                                    marginRight: "10px",
                                }}
                                ariaLabel={t("Save")}
                            />
                        </Button>
                    )}
                    {!isEditing && (
                        <Button
                            onClick={onToggleEdit}
                            clickParams={[id]}
                            testId="PhaseEdit_Button"
                            cssRules={{
                                marginRight: "10px",
                            }}
                        >
                            <Icon
                                icon="pencil"
                                size={18}
                                ariaLabel={t("Edit")}
                            />
                        </Button>
                    )}
                    <Button
                        variant="error"
                        onClick={onDeleteClick}
                        clickParams={[id]}
                        disabled={preventDelete}
                        testId="PhaseDelete_Button"
                    >
                        <Icon icon="remove" ariaLabel={t("Remove")} size={18} />
                    </Button>
                </Form>
            </td>
        </tr>
    );
};

interface IPhaseLimitsRowProps {
    id: number;
    phase: IPhase;
    isEditing: boolean;
    onToggleEdit: (id: number) => void;
    onUpdateClick: (id: number, phase: IPhase) => void;
    onDeleteClick: (id: number) => void;
    preventDelete?: boolean;
    excludedValues: number[];
}

export default PhaseLimitsRow;
