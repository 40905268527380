import { CSSProperties } from "react";
import { useCssClasses } from "../../../hooks";
import styles from "./Timeline.module.scss";

const Timeline = ({ index, length, cssRules }: ITimelineProps) => {
    const topBarCssClasses = useCssClasses(
        styles.timelineBar,
        length > 1 && index > 0 ? styles.visible : "",
    );

    const bottomBarCssClasses = useCssClasses(
        styles.timelineBar,
        length > 1 && index < length - 1 ? styles.visible : "",
    );

    return (
        <div className={styles.container} style={cssRules}>
            <div className={topBarCssClasses} />
            <div className={styles.timelineCircle} />
            <div className={bottomBarCssClasses} />
        </div>
    );
};

interface ITimelineProps {
    index: number;
    length: number;
    cssRules?: CSSProperties;
}

export default Timeline;
