import { useCallback, useContext } from "react";
import { switchMap, tap } from "rxjs";
import { PropertyCategoriesContext, UserContext } from "../components";
import config from "../config";
import { useFileUrl } from "../utils/api/misc";
import useApiRequest from "../utils/api/useApiRequest";
import { getGlobalUrlParameters, useXTagApiRequest } from "../utils/request";

const useDownloadPaperwork = () => {
    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);
    const { send } = useApiRequest<string>({});
    const sendRequest = useXTagApiRequest();

    const getFileUrl = useFileUrl();
    const download = useCallback(
        (
            paperworkId: number,
            documentUrl: string,
            disableFuelTypeFiltering: boolean = false,
        ) =>
            send(
                sendRequest({
                    url: `${config.lgsrsApiUrl}/documents/v2/download/${paperworkId}/markread`,
                    method: "POST",
                    urlParams: getGlobalUrlParameters(
                        disableFuelTypeFiltering
                            ? []
                            : selectedCategories.map((c) => c.id),
                        activeUserParentsIds,
                    ),
                }).pipe(
                    switchMap(() => getFileUrl(documentUrl)),
                    tap((url) => window.open(url)),
                ),
            ),
        [
            activeUserParentsIds,
            getFileUrl,
            selectedCategories,
            send,
            sendRequest,
        ],
    );

    return { download };
};

export default useDownloadPaperwork;
