import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card, GroupedBarChart, Loading } from "../../../components";
import { IGroupedData } from "../../../components/Charts/GroupedBarChart";
import { IComplianceDashboard } from "../../../utils/api/misc";
import { toDateString } from "../../../utils/dates";
import styles from "./ServiceDatesResetCard.module.scss";

const ServiceDatesResetCard = ({ loaded, data }: IServiceDateResetProps) => {
    const { t } = useTranslation();

    const propertyServiceDateResetCounts = useMemo(() => {
        let totalResetPropertiesCount = 0;
        let totalPropertyServiceDatesResetCount = 0;

        if (data) {
            for (const landlordResets of data.serviceDateResets) {
                if (landlordResets.totalResetProperties) {
                    totalResetPropertiesCount +=
                        landlordResets.totalResetProperties;
                }
                if (landlordResets.totalPropertyServiceDatesReset) {
                    totalPropertyServiceDatesResetCount +=
                        landlordResets.totalPropertyServiceDatesReset;
                }
            }
        }

        return {
            totalResetPropertiesCount,
            totalPropertyServiceDatesResetCount,
        };
    }, [data]);

    const groups: IGroupedData[] = useMemo(() => {
        const phaseData: { [key: string]: IGroupedData } = {};

        if (data) {
            for (const landlordResets of data.serviceDateResets) {
                if (landlordResets.phases) {
                    for (const phase of landlordResets.phases) {
                        if (!phaseData[phase.title]) {
                            phaseData[phase.title] = {
                                title: phase.title,
                                color: phase.colour,
                                data: phase.dates
                                    .sort(
                                        (a, b) =>
                                            +new Date(b.date) -
                                            +new Date(a.date),
                                    )
                                    .map((date) => ({
                                        label: toDateString(
                                            new Date(date.date),
                                        ),
                                        value: [
                                            {
                                                label: phase.title,
                                                value: date.count,
                                                colour: phase.colour,
                                            },
                                        ],
                                    })),
                            };
                        } else {
                            for (const date of phase.dates) {
                                const dataIndex = phaseData[
                                    phase.title
                                ].data.findIndex(
                                    (p) =>
                                        p.label ===
                                        toDateString(new Date(date.date)),
                                );

                                if (dataIndex === -1) {
                                    phaseData[phase.title].data.push({
                                        label: toDateString(
                                            new Date(date.date),
                                        ),
                                        value: [
                                            {
                                                label: phase.title,
                                                value: date.count,
                                                colour: phase.colour,
                                            },
                                        ],
                                    });
                                } else {
                                    phaseData[phase.title].data[
                                        dataIndex
                                    ].value[0].value += date.count;
                                }
                            }
                        }
                    }
                }
            }
        }

        return Object.values(phaseData);
    }, [data]);

    const labels = useMemo(() => {
        const array: string[] = [];

        if (data) {
            const activePhases = data.serviceDateResets.filter((r) => r.phases);

            if (activePhases.length > 0) {
                for (const phase of activePhases[0].phases) {
                    const phaseArray = Array<string>(
                        phase.phaseTimeframe - array.length,
                    ).fill("");

                    phaseArray[phaseArray.length - 1] =
                        phase.phaseTimeframe.toString();

                    array.push(...phaseArray);
                }

                if (array.length > 0) {
                    array[0] = "0";
                }
            }
        }

        return array;
    }, [data]);

    const tooltipLabels = useMemo(() => {
        const array: string[] = [];

        if (data) {
            for (const landlordResets of data.serviceDateResets) {
                if (landlordResets.phases) {
                    for (const phase of landlordResets.phases) {
                        array.push(
                            ...phase.dates.map((date) =>
                                toDateString(new Date(date.date)),
                            ),
                        );
                    }
                }
            }
        }

        return array;
    }, [data]);

    return (
        <Card title={t("Service dates reset")} fullHeight={true}>
            {loaded ? (
                <div className={styles.container}>
                    <div className={styles.leftColumn}>
                        <div className={styles.value}>
                            {
                                propertyServiceDateResetCounts.totalResetPropertiesCount
                            }
                        </div>
                        <div className={styles.label}>
                            {t("Reset properties")}
                        </div>
                        <div className={styles.value}>
                            {
                                propertyServiceDateResetCounts.totalPropertyServiceDatesResetCount
                            }
                        </div>
                        <div className={styles.label}>
                            {t("Service dates reset")}
                        </div>
                    </div>
                    <div className={styles.rightColumn}>
                        <GroupedBarChart
                            groups={groups}
                            labels={labels}
                            tooltipLabels={tooltipLabels}
                            maxHeight={200}
                        />
                    </div>
                </div>
            ) : (
                <Loading />
            )}
        </Card>
    );
};

interface IServiceDateResetProps {
    loaded: boolean;
    data: IComplianceDashboard;
}

export default ServiceDatesResetCard;
