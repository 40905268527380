import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { IGroupedData } from ".";
import { IDrawableObject } from "..";
import { animate } from "../animation";
import { bounce } from "../animations";
import { createChart } from "../chart";
import { roundedRectangle } from "../draw";
import getGrid from "../grid";
import getTooltip from "../tooltip";
import styles from "./GroupedBarChart.module.scss";

const GroupedBarChart = ({
    groups,
    labels,
    aspectRatio,
    tooltipLabels,
    maxHeight,
}: IGroupedBarChartProps) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [fontSize] = useState(12);

    const [, setResizeCount] = useState(0);

    const allValues = useMemo(
        () =>
            groups.flatMap((g) =>
                g.data.flatMap((d) => d.value.map((v) => v.value)),
            ),
        [groups],
    );

    const minValue = useMemo(
        () => (allValues.length > 0 ? Math.min(...allValues) : 0),
        [allValues],
    );
    const maxValue = useMemo(
        () => (allValues.length > 0 ? Math.max(...allValues) : 100),
        [allValues],
    );

    const getGroupBounds = (
        xAxisPositions: number[],
        barWidth: number,
        barMargin: number,
        barsOffset: number,
        groupDataLength: number,
    ) => {
        const left = xAxisPositions[barsOffset];
        const right =
            xAxisPositions[barsOffset + groupDataLength - 1] +
            barWidth +
            barMargin;
        const width = right - left;

        return {
            left,
            right,
            width,
        };
    };

    const getGroups = useCallback((): IDrawableObject => {
        const animation = animate(1500, bounce);
        const barMargin = 1;
        const grid = getGrid({
            minValue,
            maxValue,
            labels,
            gridType: "bar",
            drawYLines: false,
            drawXLines: false,
        });

        const tooltip = getTooltip({
            displayDataColorboxes: false,
        });

        let barWidth = 0;
        let gridHeight = 0;
        let xAxisPositions: number[] = [];
        let displayTooltip = false;

        const draw = (context: CanvasRenderingContext2D, time: number) => {
            const isAnimating = animation.updateFrame(time);
            const isGridAnimating = grid.draw(context, time);

            let barsOffset = 0;
            for (const group of groups) {
                const groupBounds = getGroupBounds(
                    xAxisPositions,
                    barWidth,
                    barMargin,
                    barsOffset,
                    group.data.length,
                );

                context.save();
                context.fillStyle = group.color;
                context.globalAlpha = 0.1;
                context.fillRect(
                    groupBounds.left,
                    0,
                    groupBounds.width,
                    gridHeight,
                );
                context.restore();

                context.save();
                context.rect(
                    groupBounds.left,
                    0,
                    groupBounds.width,
                    gridHeight,
                );
                context.clip();

                const ellipsis = "...";
                const ellipsisWidth = context.measureText(ellipsis).width;

                let title = group.title;
                let width = context.measureText(title).width;
                if (width > groupBounds.width) {
                    while (width + ellipsisWidth >= groupBounds.width) {
                        if (!title) {
                            break;
                        }

                        title = title.substring(0, title.length - 1);
                        width = context.measureText(title).width;
                    }

                    title = title + ellipsis;
                }

                context.fillStyle = group.color;
                context.textAlign = "left";
                context.fillText(title, groupBounds.left, fontSize);
                context.restore();

                for (let j = 0; j < group.data.length; j++) {
                    let valueHeight = 0;
                    for (const record of group.data[j].value) {
                        if (record.value === 0) {
                            continue;
                        }

                        const x =
                            xAxisPositions[barsOffset + j] + barMargin / 2;
                        const y = animation.getValue(
                            grid.getY(record.value),
                            gridHeight,
                        );

                        width = barWidth - barMargin;
                        const height = gridHeight - y;

                        if (width > 0 && height > 0) {
                            context.save();
                            roundedRectangle(
                                context,
                                x,
                                y - valueHeight,
                                width,
                                height,
                                2,
                                false,
                                false,
                                false,
                                false,
                            );

                            context.fillStyle = record.colour;
                            context.fill();
                            context.restore();
                        }

                        // Add to the total height
                        valueHeight += height;
                    }
                }

                barsOffset += group.data.length;
            }

            let isTooltipAnimating = false;
            if (displayTooltip) {
                isTooltipAnimating = tooltip.draw(context, time);
            }

            return isGridAnimating || isTooltipAnimating || isAnimating;
        };

        const getClickFunctionInPosition = (x: number, y: number) => {
            let barsOffset = 0;

            for (const group of groups) {
                const groupBounds = getGroupBounds(
                    xAxisPositions,
                    barWidth,
                    barMargin,
                    barsOffset,
                    group.data.length,
                );

                if (
                    x > groupBounds.left &&
                    x < groupBounds.right &&
                    y > 0 &&
                    y <= fontSize &&
                    group.onClick
                ) {
                    return group.onClick;
                }

                for (let j = 0; j < group.data.length; j++) {
                    const record = group.data[j];
                    const recordValue = record.value
                        .map((v) => v.value)
                        .reduce((a, b) => a + b, 0);

                    if (recordValue) {
                        const barX =
                            xAxisPositions[barsOffset + j] + barMargin / 2;
                        const barY = grid.getY(recordValue);

                        const width = barWidth - barMargin;
                        const height = gridHeight - barY;

                        if (
                            x > barX &&
                            x < barX + width &&
                            y > barY &&
                            y < barY + height &&
                            record.onClick
                        ) {
                            return record.onClick;
                        }
                    }
                }

                barsOffset += group.data.length;
            }
            return null;
        };

        const onClick = (x: number, y: number) => {
            const click = getClickFunctionInPosition(x, y);
            if (click) {
                click();
            }

            return false;
        };

        const onHover = (x: number, y: number) => {
            const requiresUpdate = grid.onHover(x, y);

            if (canvasRef.current) {
                const click = getClickFunctionInPosition(x, y);
                canvasRef.current.style.cursor = click ? "pointer" : "";
            }

            if (requiresUpdate) {
                const gridStatus = grid.getStatus();

                displayTooltip = gridStatus.hover !== null;
                if (gridStatus.hover !== null) {
                    let index = gridStatus.hover.group;

                    let group;

                    for (const g of groups) {
                        if (index < g.data.length) {
                            group = g;
                            break;
                        }

                        index -= g.data.length;
                    }

                    if (group) {
                        const values = [
                            `${tooltipLabels[gridStatus.hover.group]}`,
                        ];

                        for (const value of group.data[index].value) {
                            values.push(`${value.label}: ${value.value}`);
                        }

                        if (group.data[index].value.length > 1) {
                            const total = group.data[index].value
                                .map((v) => v.value)
                                .reduce((a, b) => a + b, 0);

                            values.push(`Total: ${total}`);
                        }

                        tooltip.setData(
                            values,
                            gridStatus.hover.min,
                            gridStatus.hover.max,
                            group.title,
                        );
                    }
                }
            }

            return requiresUpdate;
        };

        const onResize = (width: number, height: number) => {
            grid.onResize(width, height);
            tooltip.onResize(width, height);

            const gridStatus = grid.getStatus();

            barWidth = gridStatus.axis.x.width - barMargin;
            gridHeight = gridStatus.height;
            xAxisPositions = gridStatus.axis.x.positions;

            // TODO: Without this, hover is off by a few pixels.
            setResizeCount((c) => c + 1);
        };

        return { draw, onResize, onHover, onClick };
    }, [fontSize, groups, labels, maxValue, minValue, tooltipLabels]);

    useEffect(() => {
        if (canvasRef.current) {
            const chart = createChart({
                aspectRatio,
                canvas: canvasRef.current,
                objects: [getGroups()],
                fontSize,
                maxHeight,
            });

            return chart.destroy;
        }
    }, [
        groups,
        minValue,
        maxValue,
        aspectRatio,
        getGroups,
        fontSize,
        maxHeight,
    ]);

    return <canvas className={styles.chart} ref={canvasRef} />;
};

interface IGroupedBarChartProps {
    labels: string[];
    groups: IGroupedData[];
    tooltipLabels: string[];
    aspectRatio?: number;
    maxHeight?: number;
}

export default GroupedBarChart;
