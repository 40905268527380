import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { SearchableSelect } from "..";
import { IUser } from "../../utils/api/users";

const AssignUser = ({
    users,
    onChange,
    value,
    isValid,
    error,
}: IAssignUserProps) => {
    const { t } = useTranslation();

    const [usersFilter, setUsersFilter] = useState("");

    const usersSelectOptions = useMemo(() => {
        return users
            .filter((user) =>
                user.name.toLowerCase().includes(usersFilter.toLowerCase()),
            )
            .map((user) => ({
                label: user.name,
                value: user.id.toString(),
            }));
    }, [users, usersFilter]);

    const updateUser = (selectedUserId: string) => {
        onChange(selectedUserId);
    };

    return (
        <>
            <SearchableSelect
                options={usersSelectOptions}
                label={t("Please select a user to assign to")}
                placeholder={t("Search")}
                {...users}
                onChange={updateUser}
                value={value}
                applySearch={setUsersFilter}
                isValid={isValid}
                error={error}
            />
        </>
    );
};

interface IAssignUserProps {
    users: IUser[];
    onChange: (id: string) => void;
    value: string;
    isValid?: boolean;
    error?: string;
}

export default AssignUser;
