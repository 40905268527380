import { useCallback } from "react";
import { IFlag, IUpdateFlag } from ".";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const useCreateFlag = () => {
    const { send, loading, error, setError } = useApiRequest<IFlag[]>({});
    const sendRequest = useXTagApiRequest();

    const createNewFlag = useCallback(
        (flag: IUpdateFlag, landlordUserIds: number[]) =>
            send(
                sendRequest({
                    url: `${config.miscApiUrl}/flags`,
                    method: "POST",
                    urlParams: getGlobalUrlParameters([], landlordUserIds),
                    body: flag,
                }),
            ),
        [send, sendRequest],
    );

    return { createNewFlag, loading, error, setError };
};

export default useCreateFlag;
