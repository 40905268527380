import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card, TabGroup, TabGroupTab } from "../../components";
import useActiveTabFromUrl from "../../hooks/useActiveTabFromUrl";
import JobsTable from "./JobsTable";

const tabs = ["Jobs", "Sub-jobs"];

const Jobs = () => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useActiveTabFromUrl(tabs);

    const tabGroups = useMemo(
        () =>
            tabs.map((tab) => ({
                key: tab,
                header: t(tab.replace(/-/g, " ")),
            })),
        [t],
    );

    return (
        <Card title={t("Jobs")} padding={false}>
            <TabGroup currentTab={activeTab} onActivate={setActiveTab}>
                {tabGroups.map((tab) => (
                    <TabGroupTab header={tab.header} key={tab.key}>
                        <JobsTable rootJobs={tab.key === "Jobs"} />
                    </TabGroupTab>
                ))}
            </TabGroup>
        </Card>
    );
};

export default Jobs;
