import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, InputField, Select } from "..";
import { useCssClasses, useToggle } from "../../hooks";
import {
    isRequired,
    useValidateField,
    validateForm,
} from "../../utils/validation";
import styles from "./StatusSelector.module.scss";

const StatusSelector = ({
    statuses,
    currentStatus,
    onSave,
    loading,
    disableChange = false,
}: IStatusSelectorProps) => {
    const { hide, show, visible } = useToggle();
    const [reasonForChange, setReasonForChange] = useState("");
    const [selectedStatus, setSelectedStatus] = useState(currentStatus);

    const { t } = useTranslation();

    const handleCancelClicked = () => {
        handleChangeReason("");
        hide();
    };

    const handleSaveClicked = () => {
        onSave(selectedStatus, reasonForChange);
        handleChangeReason("");
        hide();
    };

    const handleChangeStatus = (value: string) => {
        setSelectedStatus(value);
    };

    const handleChangeReason = (value: string) => {
        setReasonForChange(value);
    };

    const availableStatuses = useMemo(
        () =>
            statuses.map((status) => ({
                label: t(status),
                value: status,
            })),
        [statuses, t],
    );

    const reasonValidator = useValidateField(reasonForChange, isRequired());

    const statusValidator = useValidateField(selectedStatus, isRequired());

    const formValidation = validateForm(() => [
        reasonValidator,
        statusValidator,
    ]);

    const cssClasses = useCssClasses(
        styles.status,
        currentStatus.toLowerCase(),
    );

    return (
        <>
            {!visible ? (
                <div className={cssClasses}>
                    {t(currentStatus)}
                    {!disableChange && (
                        <Button onClick={show}>{t("Change")}</Button>
                    )}
                </div>
            ) : (
                <Form onSubmit={handleSaveClicked} {...formValidation}>
                    <div className={styles.changeStatus}>
                        <div className={styles.leftContainer}>
                            <Select
                                onChange={handleChangeStatus}
                                options={availableStatuses}
                                useMargin={true}
                                value={selectedStatus}
                                {...statusValidator}
                            />
                            <InputField
                                value={reasonForChange}
                                onChange={handleChangeReason}
                                useMargin={true}
                                placeholder={t("Reason for change")}
                                {...reasonValidator}
                            />
                        </div>

                        <div className={styles.buttons}>
                            <Button onClick={handleCancelClicked}>
                                {t("Cancel")}
                            </Button>
                            <Button
                                type="submit"
                                disabled={loading}
                                cssRules={{ marginLeft: "10px" }}
                            >
                                {t("Save")}
                            </Button>
                        </div>
                    </div>
                </Form>
            )}
        </>
    );
};

interface IStatusSelectorProps {
    loading: boolean;
    statuses: string[];
    currentStatus: string;
    onSave: (newStatus: string, comment: string) => void;
    disableChange?: boolean;
}

export default StatusSelector;
