import { ReplaySubject, Subject } from "rxjs";

const onSelectedPropertyCategoriesChangedSubject = new Subject<number[]>();
const selectedPropertyCategoriesSubject = new ReplaySubject<number[]>(1);

const onSelectedPropertyCategoriesChanged = onSelectedPropertyCategoriesChangedSubject.asObservable();
const selectedPropertyCategories = selectedPropertyCategoriesSubject.asObservable();

onSelectedPropertyCategoriesChanged.subscribe((list) => {
    selectedPropertyCategoriesSubject.next(list);
});

export {
    onSelectedPropertyCategoriesChangedSubject,
    onSelectedPropertyCategoriesChanged,
    selectedPropertyCategories,
};
