import { useCallback } from "react";
import { IDashboardWidgetFilterGroupProps } from ".";
import ToggleButton from "../../ToggleButton";
import styles from "./DashboardWidgetFilterGroup.module.scss";

const DashboardWidgetFilterGroup = ({
    group,
}: IDashboardWidgetFilterGroupProps) => {
    const handleChange = useCallback(
        (checked: boolean, value?: string) => {
            if (typeof group.value === "string") {
                if (checked) {
                    group.changeValue(value || "");
                } else if (group.allowEmptyValue) {
                    group.changeValue("");
                }
            } else {
                group.changeValue(value || "");
            }
        },
        [group],
    );

    return (
        <div key={group.id} className={styles.filterGroup}>
            <p className={styles.filterGroupTitle}>{group.title}</p>

            {group.options.map((filter) => (
                <ToggleButton
                    key={filter.value}
                    value={filter.value}
                    checked={
                        typeof group.value === "string"
                            ? filter.value === group.value
                            : group.value.includes(filter.value)
                    }
                    onChange={handleChange}
                >
                    {filter.label}
                </ToggleButton>
            ))}
        </div>
    );
};

export default DashboardWidgetFilterGroup;
