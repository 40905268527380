// TODO: Use CSS modules and delete this file.
export const colours = {
    chart: {
        grey: "#757575",
        lightGrey: "#ddd",
        lighterGrey: "#f5f5f5",
        white: "#fff",
        blue: "#0657a7",
        colours: [
            "#1976d2",
            "#388e3c",
            "#700067",
            "#d32f2f",
            "#7b1fa2",
            "#303f9f",
            "#9e0142",
            "#00796b",
            "#5d4037",
            "#827717",
            "#21c15a",
            "#f6a623",
            "#016eb7",
            "#5dade2",
            "#85c1e9",
            "#3498db",
            "#cd6155",
            "#d98880",
            "#c0392b",
            "#229954",
            "#27ae60",
            "#1e8449",
            "#a569bd",
            "#bb8fce",
            "#8e44ad",
            "#f1c40f",
            "#f4d03f",
            "#d4ac0d",
            "#34495e",
            "#5d6d7e",
            "#283747",
            "#f39c12",
            "#f5b041",
            "#d68910",
            "#2471a3",
            "#2980b9",
            "#1f618d",
            "#17a589",
            "#1abc9c",
            "#148f77",
            "#268406",
            "#D0021B",
            "#266eb7",
        ],
    },
    colourPicker: [
        "#f44336",
        "#e91e63",
        "#9c27b0",
        "#673ab7",
        "#3f51b5",
        "#2196f3",
        "#03a9f4",
        "#00bcd4",
        "#009688",
        "#4caf50",
        "#8bc34a",
        "#cddc39",
        "#ffeb3b",
        "#ffc107",
        "#ff9800",
        "#ff5722",
        "#795548",
        "#607d8b",
    ],
    map: {
        clusterText: "#fff",
    },
};
