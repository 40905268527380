import { useMemo } from "react";
import Icon from "../../Icon";
import styles from "./FuelTypeIcon.module.scss";

const FuelTypeIcon = ({
    fuelType = "Gas",
    fuelTypeColour = "",
}: IFuelTypeIconProps) => {
    const iconName = useMemo(() => {
        return fuelType.toLowerCase().replace(" ", "_");
    }, [fuelType]);

    return (
        <div
            className={styles.container}
            style={{
                backgroundColor: fuelTypeColour,
                width: `${19}px`,
                height: `${19}px`,
            }}
        >
            <Icon
                icon={iconName}
                ariaLabel={fuelType}
                color="white"
                size={18}
            />
        </div>
    );
};

interface IFuelTypeIconProps {
    fuelType?: string;
    fuelTypeColour?: string;
}

export default FuelTypeIcon;
