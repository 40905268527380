import { useContext, useEffect } from "react";
import { map } from "rxjs";
import { ILandlord, ILandlordPreferences } from ".";
import { UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiValue from "../useApiValue";

const useLandlordPreferences = () => {
    const { user, activeUserParentsIds } = useContext(UserContext);
    const { send, value, loaded } = useApiValue<ILandlord[]>({});
    const sendRequest = useXTagApiRequest();

    useEffect(() => {
        const subscription = send(
            sendRequest<ILandlordPreferences[], unknown>({
                url: `${config.landlordsApiUrl}/preferences`,
                method: "GET",
                urlParams: getGlobalUrlParameters([], activeUserParentsIds),
            }).pipe(
                map((response) => {
                    const landlords: ILandlord[] = [];

                    user.userParents.forEach((userParent) => {
                        const landlord = response.find(
                            (landlordUserParent) =>
                                landlordUserParent.userId ===
                                userParent.userParentId,
                        );

                        if (landlord) {
                            landlords.push({
                                id: landlord.landlordId,
                                userId: landlord.userId,
                                name: userParent.landlord?.name ?? "",
                                preferences: landlord,
                                integrations: landlord.integrations,
                            });
                        }
                    });

                    return landlords;
                }),
            ),
        ).subscribe();

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [activeUserParentsIds, send, sendRequest, user.userParents]);

    return { value, loaded };
};

export default useLandlordPreferences;
