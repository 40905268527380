import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, Loading } from "../../components";
import {
    useCurrentContractor,
    useGenerateContractorVerificationDetails,
} from "../../utils/api/landlords";
import styles from "./ContractorDetails.module.scss";

const ContractorDetails = () => {
    const { t } = useTranslation();

    const {
        value,
        updateValue,
        loading: contractorLoading,
    } = useCurrentContractor();
    const { generateVerificationDetails, loading: generateLoading } =
        useGenerateContractorVerificationDetails();

    const handleRegenerateClick = useCallback(() => {
        generateVerificationDetails().subscribe((details) => {
            updateValue({
                ...value,
                verificationId: details.verificationId,
                verificationSecret: details.verificationSecret,
            });
        });
    }, [generateVerificationDetails, updateValue, value]);

    return (
        <Card title={t("Contractor Details")}>
            {contractorLoading && <Loading />}

            {value && (
                <>
                    <div className={styles.item}>
                        <span className={styles.label}>
                            {t("Verification id")}:
                        </span>
                        &nbsp;{value.verificationId}
                    </div>

                    <div className={styles.item}>
                        <span className={styles.label}>
                            {t("Verification secret")}:
                        </span>
                        &nbsp;{value.verificationSecret}
                    </div>

                    <Button
                        variant="primary"
                        onClick={handleRegenerateClick}
                        disabled={generateLoading}
                    >
                        {t("Re-generate")}
                    </Button>
                </>
            )}
        </Card>
    );
};

export default ContractorDetails;
