import { useTranslation } from "react-i18next";
import { IOverrideAnswerProps } from ".";
import { ISelectOption } from "../../../components/Select";
import useQuestionAnswerOptions from "../../../hooks/useQuestionAnswerOptions";
import OverrideDateAnswer from "../OverrideDateAnswer";
import OverrideInspectionScheduleAnswer from "../OverrideInspectionScheduleAnswer";
import OverrideMultiSelectAnswer from "../OverrideMultiSelectAnswer";
import OverrideSelectAnswer from "../OverrideSelectAnswer";
import OverrideTextAnswer from "../OverrideTextAnswer";
import styles from "./OverrideAnswer.module.scss";

const OverrideAnswer = ({
    documentType,
    answer,
    value,
    overrideAnswer,
}: IOverrideAnswerProps) => {
    const { t } = useTranslation();
    const options = useQuestionAnswerOptions(answer);

    if (
        answer.readOnly ||
        answer.questionName === "E_ResolvedIssueResult" ||
        answer.questionName === "E_CircuitNumber"
    ) {
        return null;
    }

    switch (answer.type) {
        case "TextField":
        case "TextArea":
        case "AutoComplete":
        case "ButtonAddRepeater": {
            if (typeof value !== "object") {
                return (
                    <OverrideTextAnswer
                        answer={answer}
                        value={value}
                        overrideAnswer={overrideAnswer}
                    />
                );
            }

            throw new Error(
                "Payload must be of type string for overriding Text answers.",
            );
        }
        case "DatePicker": {
            if (typeof value !== "object") {
                return (
                    <OverrideDateAnswer
                        answer={answer}
                        value={value}
                        overrideAnswer={overrideAnswer}
                    />
                );
            }

            throw new Error(
                "Payload must be of type string for overriding DatePicker answers.",
            );
        }
        case "MultiSelect": {
            if (typeof value !== "object") {
                return (
                    <div className={styles.flexGrow}>
                        <OverrideMultiSelectAnswer
                            answer={answer}
                            value={value}
                            overrideAnswer={overrideAnswer}
                        />
                    </div>
                );
            }

            throw new Error(
                "Payload must be of type string for overriding MultiSelect answers.",
            );
        }
        case "Dropdown":
        case "FilteredDropdown":
        case "Boolean":
        case "RadioButtonGroup": {
            if (typeof value !== "object") {
                return (
                    <div className={styles.flexGrow}>
                        <OverrideSelectAnswer
                            answer={answer}
                            value={value}
                            overrideAnswer={overrideAnswer}
                        />
                    </div>
                );
            }

            throw new Error(
                "Payload must be of type string for overriding Select answers.",
            );
        }
        case "Reading": {
            if (typeof value !== "object") {
                if (answer.questionName.endsWith("Item")) {
                    return (
                        <OverrideSelectAnswer
                            answer={answer}
                            value={value}
                            overrideAnswer={overrideAnswer}
                        />
                    );
                }

                return (
                    <OverrideTextAnswer
                        answer={answer}
                        value={value}
                        overrideAnswer={overrideAnswer}
                    />
                );
            }

            throw new Error(
                "Payload must be of type string for overriding Reading answers.",
            );
        }
        case "InspectionSchedule":
        case "ToggleButtonGroup": {
            const overrideOptions: ISelectOption[] = [];

            if (answer.type === "InspectionSchedule") {
                if (documentType === "EICR") {
                    overrideOptions.push(
                        {
                            label: t("Acceptable"),
                            value: "Acceptable",
                        },
                        {
                            label: t("N/V"),
                            value: "N/V",
                        },
                        {
                            label: t("N/A"),
                            value: "N/A",
                        },
                        {
                            label: t("Limitation"),
                            value: "LIM",
                        },
                        {
                            label: t("Observation"),
                            value: "OBS",
                        },
                    );
                } else if (documentType === "EIC") {
                    overrideOptions.push(
                        {
                            label: t("Pass"),
                            value: "Pass",
                        },
                        {
                            label: t("Fail"),
                            value: "Fail",
                        },
                        {
                            label: t("N/A"),
                            value: "N/A",
                        },
                        {
                            label: t("Comment"),
                            value: "Comment",
                        },
                    );
                }
            } else {
                overrideOptions.push(...options);
            }

            if (answer.payload.Comment) {
                return null;
            }

            return (
                <div className={styles.flexGrow}>
                    <OverrideInspectionScheduleAnswer
                        answer={answer}
                        value={value}
                        showObservationOption={!answer.payload.Observation}
                        overrideAnswer={overrideAnswer}
                        options={overrideOptions}
                    />
                </div>
            );
        }
        default: {
            return null;
        }
    }
};

export default OverrideAnswer;
