import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { IUserAuthenticationProps } from ".";
import { AuthenticationError } from "./components";

const UserAuthentication = ({ children }: IUserAuthenticationProps) => {
    const { loginWithRedirect, isAuthenticated, isLoading, error } = useAuth0();
    const [searchParams] = useSearchParams();
    const { pathname, search } = useLocation();

    useEffect(() => {
        if (!error && !isLoading && !isAuthenticated) {
            loginWithRedirect({
                appState: {
                    redirectTo: {
                        pathname,
                        search,
                    },
                },
            });
        }
    }, [
        error,
        isAuthenticated,
        isLoading,
        loginWithRedirect,
        pathname,
        search,
        searchParams,
    ]);

    return (
        <>
            {isAuthenticated && children}

            {error && <AuthenticationError errorCode={error.message} />}
        </>
    );
};

export default UserAuthentication;
