import {
    IAnswer,
    IInspectionSchedulePayload,
} from "../../../utils/api/answers";
import { OverriddenAnswers } from "../QualifiedSupervisorAuditRow";
import OverrideAnswers from "./OverrideAnswers";

export interface IOverrideAnswersProps {
    documentType: string | null;
    questionId: string;
    answers: IAnswer[];
    overriddenAnswers: OverriddenAnswers;
    errorMessage?: string;
    validateQuestion: (questionId: string, errorMessage?: string) => void;
    overrideAnswer: (
        answerId: number,
        answer?: string | IInspectionSchedulePayload,
        comment?: string,
    ) => void;
}

export default OverrideAnswers;
