import {
    IIntegration,
    ILandlordPropertyCategory,
} from "../../../../utils/api/landlords";
import PropertyCategoryCard from "./PropertyCategoryCard";

export interface IPropertyCategoryCardProps {
    propertyCategory: ILandlordPropertyCategory;
    integrations: IIntegration[];
    updatePropertyCategory: (
        propertyCategory: ILandlordPropertyCategory,
    ) => void;
}

export default PropertyCategoryCard;
