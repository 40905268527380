import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { IEngineer } from ".";
import config from "../../../config";
import { useXTagApiRequest } from "../../request";
import useApiValue from "../useApiValue";

const useSearchEngineer = () => {
    const { t } = useTranslation();

    const { send, value, loading, error, loaded, reset } =
        useApiValue<IEngineer>({
            discardPreviousValue: true,
            errorMessage: t(
                "This engineer cannot be found. Please ensure they are registered to Plentific.",
            ),
        });
    const sendRequest = useXTagApiRequest();

    const searchEngineer = useCallback(
        (data: ISearch) =>
            send(
                sendRequest({
                    url: `${config.engineersApiUrl}/engineercheck`,
                    method: "POST",
                    body: data,
                }),
            ),
        [send, sendRequest],
    );

    return { searchEngineer, value, loading, error, loaded, reset };
};

interface ISearch {
    licenceNumberV2: string;
    businessReg: string;
    engineerEmail: string;
}

export default useSearchEngineer;
