import { toDateString, toTimeSpan, toTimeString } from "./dates";

export type DataType =
    | "string"
    | "number"
    | "date"
    | "time"
    | "datetime"
    | "boolean"
    | "timespan";

const formatValue = (type: DataType) => {
    return (value: any) => {
        switch (type) {
            default:
            case "string":
            case "number":
            case "boolean":
                return value;
            case "date":
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                return value && toDateString(new Date(value));
            case "time":
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                return value && toTimeString(new Date(value));
            case "datetime":
                return (
                    value &&
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                    `${toDateString(new Date(value))} ${toTimeString(
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                        new Date(value),
                    )}`
                );
            case "timespan":
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                return value && toTimeSpan(value);
        }
    };
};

const getValueFromObject = (property: string, object: any) => {
    let value = object;

    for (const current of property.split(".")) {
        if (value) {
            value = value[current];
        }
    }

    return value;
};

export { formatValue, getValueFromObject };
