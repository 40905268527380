import { useEffect, useMemo, useRef, useState } from "react";
import { Badge, Checkbox, EngineerImg } from "../../../components";
import { ILandlordEngineer } from "../../../utils/api/landlords";

const EngineerCheckbox = ({
    engineer,
    selectedIds,
    scrollableAncestor,
    onChange,
    jobsCount = 0,
}: IEngineerCheckboxProps) => {
    const checkboxRef = useRef<HTMLDivElement>(null);

    const isChecked = useMemo(
        () => selectedIds.some((id) => id === engineer.id),
        [engineer, selectedIds],
    );

    const [canLoadImage, setCanLoadImage] = useState(false);

    useEffect(() => {
        handleScroll();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleScroll = () => {
        if (checkboxRef.current) {
            const containerTop = scrollableAncestor.scrollTop;
            const containerBottom =
                containerTop + scrollableAncestor.clientHeight;

            const top = checkboxRef.current.offsetTop;
            const bottom = top + checkboxRef.current.clientHeight;

            if (
                !canLoadImage &&
                top < containerBottom + 100 &&
                bottom > containerTop - 100
            ) {
                setCanLoadImage(true);
            }
        }
    };

    useEffect(() => {
        scrollableAncestor.addEventListener("scroll", handleScroll);

        return () =>
            scrollableAncestor.removeEventListener("scroll", handleScroll);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkboxRef.current, scrollableAncestor, engineer.image, canLoadImage]);

    return (
        <Checkbox
            onChange={onChange}
            value={engineer.id}
            checked={isChecked}
            setRef={checkboxRef}
        >
            <EngineerImg
                name={engineer.name}
                imageUrl={(canLoadImage && engineer.image?.key) || ""}
                size={30}
                display="inline-block"
                cssRules={{ marginRight: "5px", marginBottom: "-9px" }}
            />
            {engineer.name} <Badge color="green">{jobsCount}</Badge>
        </Checkbox>
    );
};

interface IEngineerCheckboxProps {
    onChange: (checked: boolean, value: number) => void;
    selectedIds: number[];
    engineer: ILandlordEngineer;
    scrollableAncestor: HTMLElement;
    jobsCount?: number;
}

export default EngineerCheckbox;
