import { IAuditObservation, IIssue, IStatus } from "../../../utils/api/audits";
import QualifiedSupervisorAuditObservations from "./QualifiedSupervisorAuditObservations";

export interface IObservation {
    id: string;
    questionText?: string;
    text: string;
}

export interface IQualifiedSupervisorAuditObservationsProps {
    observations: IIssue[];
    auditObservations: IAuditObservation[];
    auditStatus: IStatus;
}

export default QualifiedSupervisorAuditObservations;
