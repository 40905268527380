import { useCallback } from "react";
import { IFileUpload } from ".";
import config from "../../../config";
import { useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const useUploadFile = () => {
    const { send, error, loading } = useApiRequest<IFileUpload>({});
    const sendRequest = useXTagApiRequest();

    const uploadFile = useCallback(
        (filename: string, file: File, metadata?: Record<string, string>) => {
            const formData = new FormData();

            if (metadata !== undefined) {
                for (const key of Object.keys(metadata)) {
                    formData.append(key, metadata[key]);
                }
            }

            formData.append("file", file, filename);

            return send(
                sendRequest({
                    url: `${config.miscApiUrl}/files`,
                    method: "POST",
                    body: formData,
                    contentType: "multipart/form-data",
                }),
            );
        },
        [send, sendRequest],
    );

    return { error, loading, uploadFile };
};

export default useUploadFile;
