import { useCallback, useContext } from "react";
import { PropertyCategoriesContext, UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const useSendDocumentsToEmail = () => {
    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);
    const { send, loading } = useApiRequest({});
    const sendRequest = useXTagApiRequest();

    const sendEmail = useCallback(
        (ids: number[], emailAddress: string) =>
            send(
                sendRequest({
                    url: `${config.lgsrsApiUrl}/documents/v2/senddocumentasemail`,
                    method: "POST",
                    urlParams: getGlobalUrlParameters(
                        selectedCategories.map((c) => c.id),
                        activeUserParentsIds,
                    ),
                    body: {
                        ids,
                        recipient: emailAddress,
                    },
                }),
            ),
        [activeUserParentsIds, selectedCategories, send, sendRequest],
    );

    return { sendEmail, loading };
};

export default useSendDocumentsToEmail;
