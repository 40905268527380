import { IComplianceDashboard } from "../../../utils/api/misc";
import ComplianceDefaultDashboard from "./ComplianceDefaultDashboard";

export interface IComplianceDefaultDashboardProps {
    rowHeight: number;
    dashboard: IComplianceDashboard;
    loaded: boolean;
    mapFeature: boolean;
}

export default ComplianceDefaultDashboard;
