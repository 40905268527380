import { IValidationResult } from ".";

const minLength = (
    minimumLength: number,
    error = "You haven't entered enough characters.",
) => {
    const validate = (value: string): IValidationResult => {
        const isValid = value.length >= minimumLength;

        return {
            isValid,
            error: isValid ? "" : error,
        };
    };

    return { validate };
};

export default minLength;
