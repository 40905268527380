import { TabContainer } from "../../components/TabContainer";
import RepairDashboard from "../RepairDashboard";
import ServicingDashboard from "../ServicingDashboard";
import DashboardTab from "./DashboardTab";

const JobReports = () => {
	return (
		<TabContainer>
			<DashboardTab header="Repairs">
				<RepairDashboard />
			</DashboardTab>
			<DashboardTab header = "Servicing">
				<ServicingDashboard />
			</DashboardTab>
		</TabContainer>
	)
};

export default JobReports;
