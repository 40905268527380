import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { IFlag } from ".";
import { UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const useDeleteFlag = () => {
    const { t } = useTranslation();
    const { activeUserParentsIds } = useContext(UserContext);
    const { send, loading, error, setError } = useApiRequest<IFlag>({
        errorMessage: t(
            "This flag is assigned to properties and can't be deleted.",
        ),
    });
    const sendRequest = useXTagApiRequest();

    const deleteFlag = useCallback(
        (id: number) =>
            send(
                sendRequest({
                    url: `${config.miscApiUrl}/flags/${id}`,
                    method: "DELETE",
                    urlParams: getGlobalUrlParameters([], activeUserParentsIds),
                }),
            ),
        [activeUserParentsIds, send, sendRequest],
    );

    return { deleteFlag, loading, error, setError };
};

export default useDeleteFlag;
