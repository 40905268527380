import { useContext, useEffect, useMemo } from "react";
import { filter, map } from "rxjs";
import { IIssueComment } from ".";
import RealtimeContext from "../../../components/RealtimeProvider/RealtimeContext";
import config from "../../../config";
import { clearCache } from "../../cache";
import { realtimeCreateList } from "../../realtime";
import useAllFilterable from "../useAllFilterable";

const useIssueComments = (id: string) => {
    const { realtimeObservable } = useContext(RealtimeContext);
    const sortProperties = useMemo(() => ["createdAt"], []);

    const { records, loaded, updateValue } = useAllFilterable<IIssueComment>({
        url: `${config.issuesApiUrl}/${id}/comments`,
        sortProperties,
    });

    useEffect(() => {
        const subscription = realtimeObservable
            .pipe(
                filter(
                    (e) =>
                        e.event === "created" && e.entity === "issue-comment",
                ),
                map((e) => e.payload as IIssueComment),
                filter((e) => e.issueId === Number(id)),
            )
            .subscribe((value) => {
                const updatedList = realtimeCreateList(value, records);

                if (updatedList) {
                    updateValue(updatedList);
                }

                clearCache();
            });

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [id, realtimeObservable, records, updateValue]);

    return { records, loaded, updateValue };
};

export default useIssueComments;
