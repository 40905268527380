import { IValidationResult } from ".";

const lessThanOrEqual = (
    highestValue: number,
    error = "Your number must be less than or equal to " + highestValue,
) => {
    const validate = (value: string): IValidationResult => {
        const isValid = Number(value) <= highestValue;

        return {
            isValid,
            error: isValid ? "" : error,
        };
    };

    return { validate };
};

export default lessThanOrEqual;
