import { useContext } from "react";
import { ISimpleIssue } from ".";
import { UserContext } from "../../../components";
import config from "../../../config";
import useFilterable from "../useFilterable";

const useIssues = (status: string) => {
    const userContext = useContext(UserContext);

    const filterKey = status === "Inbox" ? "assignedUser.id" : "status";
    const filterValue =
        status === "Inbox" ? userContext.user.id.toString() : status;

    const filterable = useFilterable<ISimpleIssue>(config.issuesApiUrl, {
        params: {
            filters: {
                [filterKey]: {
                    operator: "{AND}",
                    readOnly: true,
                    filters: [{ function: "=", value: filterValue }],
                },
            },
        },
    });

    return filterable;
};

export default useIssues;
