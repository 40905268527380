import { useMemo } from "react";
import { IProgressBarProps } from ".";
import { useCssClasses } from "../../hooks";
import styles from "./ProgressBar.module.scss";

const ProgressBar = ({ percentage, color, cssClass }: IProgressBarProps) => {
    const normalizedPercentage = useMemo(
        () => Math.max(0, Math.min(100, percentage)),
        [percentage],
    );

    const cssClasses = useCssClasses(styles.container, cssClass || "");

    return (
        <div className={`${cssClasses}`}>
            <div
                role="progressbar"
                aria-valuenow={normalizedPercentage}
                aria-valuemin={0}
                aria-valuemax={100}
                className={styles.progress}
                style={{
                    width: `${normalizedPercentage}%`,
                    backgroundColor: color,
                }}
            />
        </div>
    );
};

export default ProgressBar;
