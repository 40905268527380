import { useContext, useEffect, useMemo, useState } from "react";
import { filter, map } from "rxjs";
import { INotification } from "..";
import { NotificationsContext } from "../..";
import { NotificationCategory } from "../../../utils/api/misc";
import Portal from "../../Portal";
import RealtimeContext from "../../RealtimeProvider/RealtimeContext";
import NotificationPopup from "./NotificationPopup";
import styles from "./RealtimeNotifications.module.scss";

const RealtimeNotifications = () => {
    const { realtimeObservable } = useContext(RealtimeContext);
    const {
        standardTypes,
        issueTypes,
        activeNotificationTypes,
        updateNotificationsCount,
    } = useContext(NotificationsContext);

    const [notifications, setNotifications] = useState<INotification[]>([]);

    const notificationCategories = useMemo<INotificationCategories>(() => {
        const categories: INotificationCategories = {};

        for (const type of standardTypes) {
            categories[type.id] = NotificationCategory.Standard;
        }

        for (const type of issueTypes) {
            categories[type.id] = NotificationCategory.Issue;
        }

        return categories;
    }, [standardTypes, issueTypes]);
    const userPreferences = useMemo<INotificationsPreferences>(() => {
        const preferences: INotificationsPreferences = {};

        for (const notification of activeNotificationTypes) {
            preferences[notification.notification_type] = {
                showPopup: notification.show_popup,
                category:
                    notificationCategories[notification.notification_type_id],
            };
        }

        return preferences;
    }, [activeNotificationTypes, notificationCategories]);

    useEffect(() => {
        const subscription = realtimeObservable
            .pipe(
                filter(
                    (value) =>
                        value.entity === "notification" &&
                        value.event === "created",
                ),
                map((value) => value.payload as INotification),
            )
            .subscribe((notification) => {
                const userPreference = userPreferences[notification.type];

                if (userPreference && userPreference.showPopup) {
                    setNotifications([...notifications, notification]);

                    setTimeout(
                        () => setNotifications((list) => list.slice(1)),
                        10000,
                    );
                }

                updateNotificationsCount(notification.category, 1);
            });

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [
        notifications,
        realtimeObservable,
        userPreferences,
        updateNotificationsCount,
    ]);

    return (
        <Portal>
            <div className={styles.container}>
                {notifications.map((notification) => (
                    <NotificationPopup
                        key={notification.id}
                        notification={notification}
                    />
                ))}
            </div>
        </Portal>
    );
};

interface INotificationsPreferences {
    [key: string]: {
        showPopup: boolean;
        category: NotificationCategory;
    };
}

interface INotificationCategories {
    [key: number]: NotificationCategory;
}

export default RealtimeNotifications;
