import { IAuditJobComment, IAuditObservation } from "../../../utils/api/audits";
import { IQualifiedSupervisorAuditsGroupingProps } from "../QualifiedSupervisorAuditsGrouping";
import QualifiedSupervisorHoldAudit from "./QualifiedSupervisorHoldAudit";

export interface IQualifiedSupervisorHoldAuditProps
    extends IQualifiedSupervisorAuditsGroupingProps {
    hasHold?: boolean;
    onHoldAcquired?: () => void;
    canOverrideAnswers?: boolean;
    addAuditJobComment: (jobComment: IAuditJobComment) => void;
    removeAuditJobComment: (jobCommentId: string) => void;
    addAuditObservation: (auditObservation: IAuditObservation) => void;
    removeAuditObservation: (auditObservationId: string) => void;
    removeAuditOverrides: () => void;
}

export default QualifiedSupervisorHoldAudit;
