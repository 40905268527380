import { useContext, useEffect } from "react";
import { ILimitationSet } from ".";
import { UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiValue from "../useApiValue";

const useLandlordContractorLimitationSet = (
    landlordId: number,
    contractorId: number,
) => {
    const { activeUserParentsIds } = useContext(UserContext);
    const { value, loaded, send, updateValue } = useApiValue<ILimitationSet>(
        {},
    );
    const sendRequest = useXTagApiRequest();

    useEffect(() => {
        const subscription = send(
            sendRequest({
                url: `${config.landlordsApiUrl}/limitationsets`,
                method: "GET",
                urlParams: {
                    landlordId: [landlordId.toString()],
                    contractorId: [contractorId.toString()],
                    ...getGlobalUrlParameters([], activeUserParentsIds),
                },
            }),
        ).subscribe();

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [activeUserParentsIds, contractorId, landlordId, send, sendRequest]);

    return { value, loaded, updateValue };
};

export default useLandlordContractorLimitationSet;
