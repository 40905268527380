import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Observable } from "rxjs";
import { ICreatePropertyCategory, IPropertyCategory } from ".";
import { UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const useCreatePropertyCategory = (
    propertyId: number,
): [
    (data: ICreatePropertyCategory) => Observable<IPropertyCategory>,
    boolean,
    string,
] => {
    const { t } = useTranslation();
    const { activeUserParentsIds } = useContext(UserContext);
    const { send, error, loading } = useApiRequest<IPropertyCategory>({
        errorMessage: t("Unexpected error occured. Please try again later."),
    });
    const sendRequest = useXTagApiRequest();

    const createPropertyCategory = useCallback(
        (propertyCategory: ICreatePropertyCategory) =>
            send(
                sendRequest({
                    url: `${config.propertiesApiUrl}/${propertyId}/categories`,
                    method: "POST",
                    urlParams: getGlobalUrlParameters([], activeUserParentsIds),
                    body: propertyCategory,
                }),
            ),
        [activeUserParentsIds, propertyId, send, sendRequest],
    );

    return [createPropertyCategory, loading, error];
};

export default useCreatePropertyCategory;
