import { useCallback, useEffect, useMemo, useState } from "react";
import { BehaviorSubject, distinctUntilChanged, scan } from "rxjs";
import { INotificationsContext, INotificationsProviderProps } from ".";
import {
    NotificationCategory,
    useNotificationCount,
} from "../../utils/api/misc";
import useNotificationTypes from "../../utils/api/users/useNotificationTypes";
import useUserNotificationTypes from "../../utils/api/users/useUserNotificationTypes";
import NotificationsContext from "./NotificationsContext";

const NotificationsProvider = ({ children }: INotificationsProviderProps) => {
    const [issueNotificationsSubject] = useState(
        new BehaviorSubject<number>(0),
    );
    const [issueNotificationsObservable] = useState(
        issueNotificationsSubject.asObservable().pipe(
            scan((acc, value) => {
                const sum = acc + value;

                if (sum >= 0) {
                    return sum;
                }

                return 0;
            }),
            distinctUntilChanged(),
        ),
    );
    const issueNotificationsCount = useNotificationCount(
        NotificationCategory.Issue,
        false,
    );
    useEffect(() => {
        issueNotificationsSubject.next(issueNotificationsCount.value);
    }, [issueNotificationsCount.value, issueNotificationsSubject]);

    const activeUserNotificationTypes = useUserNotificationTypes();
    const standardNotificationTypes = useNotificationTypes(1);
    const issueNotificationTypes = useNotificationTypes(2);

    const [standardNotificationsSubject] = useState(
        new BehaviorSubject<number>(0),
    );
    const [standardNotificationsObservable] = useState(
        standardNotificationsSubject.asObservable().pipe(
            scan((acc, value) => {
                const sum = acc + value;

                if (sum >= 0) {
                    return sum;
                }

                return 0;
            }),
            distinctUntilChanged(),
        ),
    );
    const standardNotificationsCount = useNotificationCount(
        NotificationCategory.Standard,
        false,
    );
    useEffect(() => {
        standardNotificationsSubject.next(standardNotificationsCount.value);
    }, [standardNotificationsCount.value, standardNotificationsSubject]);

    const updateNotificationsCount = useCallback(
        (category: NotificationCategory, count: number) => {
            switch (category) {
                case NotificationCategory.Issue: {
                    issueNotificationsSubject.next(count);
                    break;
                }
                case NotificationCategory.Standard: {
                    standardNotificationsSubject.next(count);
                    break;
                }
            }
        },
        [issueNotificationsSubject, standardNotificationsSubject],
    );

    const context = useMemo<INotificationsContext>(
        () => ({
            categories: {
                issue: issueNotificationsObservable,
                standard: standardNotificationsObservable,
            },
            activeNotificationTypes: activeUserNotificationTypes.value,
            standardTypes: standardNotificationTypes.value,
            issueTypes: issueNotificationTypes.value,
            updateNotificationsCount,
            isReady:
                activeUserNotificationTypes.loaded &&
                standardNotificationTypes.loaded &&
                issueNotificationTypes.loaded,
        }),
        [
            issueNotificationsObservable,
            standardNotificationsObservable,
            activeUserNotificationTypes.value,
            activeUserNotificationTypes.loaded,
            standardNotificationTypes.value,
            standardNotificationTypes.loaded,
            issueNotificationTypes.value,
            issueNotificationTypes.loaded,
            updateNotificationsCount,
        ],
    );

    return (
        <NotificationsContext.Provider value={context}>
            {children}
        </NotificationsContext.Provider>
    );
};

export default NotificationsProvider;
