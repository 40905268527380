import { useCallback } from "react";
import useClickableNonInteractiveElement from "../../../hooks/useClickableNonInteractiveElement";
import styles from "./EditablePill.module.scss";

const EditablePill = ({
    value,
    name,
    colour,
    title,
    remove,
}: IEditablePillProps) => {
    const handleClick = useCallback(() => {
        if (value && remove) {
            remove(value);
        }
    }, [remove, value]);

    const { onKeyDown } = useClickableNonInteractiveElement(handleClick);

    return (
        <div
            className={styles.container}
            style={{ backgroundColor: colour }}
            title={title}
        >
            {name}

            {value && remove && (
                <span
                    className={styles.pillRemove}
                    onClick={handleClick}
                    onKeyDown={onKeyDown}
                    role="button"
                    tabIndex={0}
                >
                    x
                </span>
            )}
        </div>
    );
};

interface IEditablePillProps {
    value?: number;
    name: string;
    colour?: string;
    title?: string;
    remove?: (value: number) => void;
}

export default EditablePill;
