import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { ITenantFormProps } from ".";
import { EditableListItemLayout, Form, InputField } from "../../../components";
import {
    useCreateTenant,
    useUpdateTenant,
} from "../../../utils/api/properties";
import {
    isEmail,
    isRequired,
    useValidateField,
    validateForm,
} from "../../../utils/validation";

const TenantForm = ({
    propertyId,
    entity,
    onSave,
    onCancel,
}: ITenantFormProps) => {
    const { t } = useTranslation();

    const [
        createTenant,
        createTenantLoading,
        createTenantError,
    ] = useCreateTenant(propertyId);
    const [
        updateTenant,
        updateTenantLoading,
        updateTenantError,
    ] = useUpdateTenant(propertyId);

    const [name, setName] = useState(entity?.name || "");
    const [phone, setPhone] = useState(entity?.phone || "");
    const [email, setEmail] = useState(entity?.email || "");
    const [residentNumber, setResidentNumber] = useState(
        entity?.residentNumber || "",
    );

    const nameValidation = useValidateField(name, isRequired());
    const emailValidation = useValidateField(email, isEmail());
    const formValidation = validateForm(() => [
        nameValidation,
        emailValidation,
    ]);

    const handleSubmit = useCallback(() => {
        if (entity) {
            updateTenant(entity.id, {
                name,
                email,
                phone,
                residentNumber,
            }).subscribe(onSave);
        } else {
            createTenant({
                name,
                email,
                phone,
                residentNumber,
            }).subscribe(onSave);
        }
    }, [
        createTenant,
        updateTenant,
        onSave,
        entity,
        name,
        email,
        phone,
        residentNumber,
    ]);

    return (
        <Form onSubmit={handleSubmit} {...formValidation}>
            <EditableListItemLayout
                isEditing={true}
                onCancel={onCancel}
                loading={createTenantLoading || updateTenantLoading}
                error={createTenantError || updateTenantError}
            >
                <InputField
                    label={t("Name")}
                    size="small"
                    value={name}
                    onChange={setName}
                    {...nameValidation}
                />

                <InputField
                    label={t("Email")}
                    size="small"
                    value={email}
                    onChange={setEmail}
                    {...emailValidation}
                />

                <InputField
                    label={t("Contact Number")}
                    size="small"
                    value={phone}
                    onChange={setPhone}
                />

                <InputField
                    label={t("Resident Number")}
                    size="small"
                    value={residentNumber}
                    onChange={setResidentNumber}
                />
            </EditableListItemLayout>
        </Form>
    );
};

export default TenantForm;
