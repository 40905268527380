import { IRecentUnableToAccess } from ".";
import config from "../../../config";
import useFilterable from "../useFilterable";

const useRecentUnableToAccess = () => {
    const filterable = useFilterable<IRecentUnableToAccess>(
        `${config.jobsApiUrl}/unabletoaccess/recent`,
        {
            params: {
                filters: {
                    assetId: {
                        operator: "{AND}",
                        filters: [{ function: "=", value: "null" }],
                    },
                },
            },
        },
    );

    return filterable;
};

export default useRecentUnableToAccess;
