import { useTranslation } from "react-i18next";
import { ITableActionsProps } from ".";
import { Dropdown, Icon, SendLgsrsModal } from "../../../components";
import { useToggle } from "../../../hooks";

const TableActions = ({ paperworkId }: ITableActionsProps) => {
    const { t } = useTranslation();
    const { visible, show, hide } = useToggle();

    return (
        <>
            <Dropdown
                label={<Icon icon="more" ariaLabel={t("Actions")} />}
                size="small"
                items={[
                    {
                        label: t("Send LGSR"),
                        onClick: show,
                    },
                ]}
            />
            {visible && (
                <SendLgsrsModal paperworkIds={[paperworkId]} hide={hide} />
            )}
        </>
    );
};

export default TableActions;
