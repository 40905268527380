import { Button } from "../../../components";
import AuthorizedImage from "../../../components/AuthorizedImage";

const ApplianceImage = ({
    imageUrl,
    onClick,
    clickParams,
}: IApplianceImageProps) => {
    return (
        <Button
            onClick={onClick}
            displayAsLink={true}
            clickParams={clickParams}
            cssRules={{ margin: "0 10px 10px 0" }}
        >
            <AuthorizedImage image={imageUrl} circle={true} size={50} />
        </Button>
    );
};

interface IApplianceImageProps {
    imageUrl: string;
    clickParams?: any[];
    onClick?: (...params: any[]) => void;
}

export default ApplianceImage;
