import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IQualifiedSupervisorAuditsModalProps } from ".";
import {
    Alert,
    Button,
    Icon,
    Loading,
    Modal,
    Portal,
} from "../../../components";
import ModalBody from "../../../components/Modal/ModalBody";
import { useToggle } from "../../../hooks";
import { useAudit } from "../../../utils/api/audits";
import { clearCache } from "../../../utils/cache";
import QualifiedSupervisorAuditsCloseModal from "../QualifiedSupervisorAuditsCloseModal";
import QualifiedSupervisorDependencies from "../QualifiedSupervisorDependencies";
import QualifiedSupervisorHoldAudit from "../QualifiedSupervisorHoldAudit";
import QualifiedSupervisorParentAudit from "../QualifiedSupervisorParentAudit";

const QualifiedSupervisorAuditsModal = ({
    hide,
    auditId,
    isReadOnly,
    auditNext,
    addAdditionalAudits,
}: IQualifiedSupervisorAuditsModalProps) => {
    const { t } = useTranslation();

    const {
        value,
        error,
        loaded,
        addComment,
        addAuditJobComment,
        addAuditObservation,
        removeAuditObservation,
        removeAuditOverrides,
        removeAuditJobComment,
    } = useAudit(auditId);

    const {
        visible: confirmCloseModalVisible,
        hide: cancelClose,
        show: showConfirmCloseModal,
    } = useToggle();

    const handleCloseQsModal = useCallback(() => {
        if (isReadOnly) {
            hide();
        } else {
            showConfirmCloseModal();
        }
    }, [showConfirmCloseModal, hide, isReadOnly]);

    const auditDependencies = useCallback(() => {
        addAdditionalAudits(value.auditDependencies.map((a) => a.id));
        clearCache();
    }, [addAdditionalAudits, value]);

    const auditDependencyUserIds = useMemo(
        () => value?.auditDependencies.map((a) => a.assignedUserId),
        [value],
    );

    const { visible: sidebarVisible, toggle: toggleSidebar } = useToggle();

    return (
        <Portal>
            <Modal
                title={
                    <>
                        {value?.auditDependencies.length === 0 && (
                            <Button
                                onClick={toggleSidebar}
                                displayAsLink={true}
                                size="small"
                            >
                                <Icon
                                    icon="menu"
                                    cssRules={{ marginRight: 5 }}
                                    ariaLabel={t("Toggle sidebar")}
                                />
                            </Button>
                        )}
                        {t("QS {{jobId}}", { jobId: value?.jobId })}
                    </>
                }
                hide={handleCloseQsModal}
                fullWidth={true}
                fullHeight={true}
            >
                {!loaded ? (
                    <ModalBody>
                        <Loading />
                    </ModalBody>
                ) : error ? (
                    <ModalBody>
                        <Alert type="error">{error}</Alert>
                    </ModalBody>
                ) : (
                    value &&
                    (isReadOnly || value.auditDependencies.length === 0 ? (
                        value.childAudits.length > 0 ? (
                            <QualifiedSupervisorParentAudit
                                audit={value}
                                isReadOnly={isReadOnly}
                                createComment={addComment}
                                onHoldAudit={auditNext}
                                onSaveAudit={auditNext}
                                addAuditJobComment={addAuditJobComment}
                                addAuditObservation={addAuditObservation}
                                removeAuditJobComment={removeAuditJobComment}
                                removeAuditObservation={removeAuditObservation}
                                removeAuditOverrides={removeAuditOverrides}
                                sidebarVisible={sidebarVisible}
                            />
                        ) : (
                            <QualifiedSupervisorHoldAudit
                                audit={value}
                                isReadOnly={isReadOnly}
                                createComment={addComment}
                                onHoldAudit={auditNext}
                                onSaveAudit={auditNext}
                                addAuditJobComment={addAuditJobComment}
                                addAuditObservation={addAuditObservation}
                                removeAuditJobComment={removeAuditJobComment}
                                removeAuditObservation={removeAuditObservation}
                                removeAuditOverrides={removeAuditOverrides}
                                sidebarVisible={sidebarVisible}
                            />
                        )
                    ) : (
                        <QualifiedSupervisorDependencies
                            auditDependencies={auditDependencies}
                            auditDependencyUserIds={auditDependencyUserIds}
                        />
                    ))
                )}
            </Modal>

            {confirmCloseModalVisible && (
                <QualifiedSupervisorAuditsCloseModal
                    id={auditId}
                    closeAudit={hide}
                    hide={cancelClose}
                />
            )}
        </Portal>
    );
};

export default QualifiedSupervisorAuditsModal;
