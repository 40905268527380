import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IIssueTimelineProps } from ".";
import {
    Button,
    Card,
    Icon,
    Loading,
    Modal,
    Portal,
} from "../../../components";
import MessageLog from "../../../components/MessageLog";
import SimpleTable, { IColumn } from "../../../components/SimpleTable";
import { useToggle } from "../../../hooks";
import {
    IMention,
    useIssueComments,
    useIssueSubscription,
    useSubmitIssueComment,
    useSubscribeToIssue,
} from "../../../utils/api/issues";
import useUnsubscribeFromIssue from "../../../utils/api/issues/useUnsubscribeFromIssue";
import styles from "./IssueTimeline.module.scss";

const IssueTimeline = ({ issueId, viewers }: IIssueTimelineProps) => {
    const { t } = useTranslation();
    const { records, loaded, updateValue } = useIssueComments(issueId);
    const subscribeToIssue = useSubscribeToIssue();
    const unsubscribeFromIssue = useUnsubscribeFromIssue();
    const submitIssueComment = useSubmitIssueComment();

    const [subscribedToIssue, setSubscribedToIssue] = useState(false);
    const { value } = useIssueSubscription(issueId);

    useEffect(() => {
        setSubscribedToIssue(value.isSubscribed);
    }, [value.isSubscribed]);

    const {
        show: viewedByModalShow,
        hide: viewedByModalHide,
        visible: viewedByModalVisible,
    } = useToggle();

    const issueViewersColumns = useMemo<IColumn[]>(
        () => [
            {
                title: t("Name"),
                type: "string",
                path: "user.name",
            },
            {
                title: t("Date Viewed"),
                type: "datetime",
                path: "updatedAt",
            },
        ],
        [t],
    );

    const handleSubscription = useCallback(() => {
        if (subscribedToIssue) {
            unsubscribeFromIssue
                .unsubscribeFromIssue(issueId)
                .subscribe(() => setSubscribedToIssue(false));
        } else {
            subscribeToIssue
                .subscribeToIssue(issueId)
                .subscribe(() => setSubscribedToIssue(true));
        }
        // eslint-disable-next-line
    }, [subscribedToIssue]);

    const handleCreateComment = useCallback(
        (comment: string, mentions: IMention[]) => {
            submitIssueComment
                .submitIssueComment(issueId, {
                    comment,
                    mentions,
                })
                .subscribe((response) => {
                    updateValue([...records, response]);
                });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [records, issueId],
    );

    const comments = useMemo(() => {
        if (records) {
            return records.map((comment) => {
                return {
                    imageUrl:
                        (comment.engineer && comment.engineer.imageUrl) ||
                        comment.user.imageUrl,
                    user:
                        (comment.engineer && comment.engineer.name) ||
                        comment.user.name,
                    text:
                        comment.status !== "Assigned User"
                            ? comment.comment
                            : "",
                    messageDateTime: new Date(comment.createdAt),
                    mentions: comment.mentions,
                    messageStatus: comment.status && (
                        <div
                            className={`${styles.messageStatus} ${
                                comment.status !== "Assigned User" &&
                                styles[comment.status]
                            }`}
                        >
                            {comment.status === "Assigned User"
                                ? t("Assigned User to ") + comment.comment
                                : t("Marked Issue as ") + comment.status}
                        </div>
                    ),
                };
            });
        }
    }, [records, t]);

    return (
        <Card
            title={t("Timeline")}
            actions={
                <>
                    <Button
                        onClick={viewedByModalShow}
                        cssRules={{ marginRight: "10px" }}
                    >
                        <Icon
                            icon="eye"
                            size={20}
                            ariaLabel={t("Open issue viewers modal")}
                        />
                    </Button>

                    <Button onClick={handleSubscription}>
                        {subscribedToIssue ? (
                            <Icon
                                icon="bell-filled"
                                size={20}
                                ariaLabel={t("Unsubscribe from issue")}
                            />
                        ) : (
                            <Icon
                                icon="bell"
                                size={20}
                                ariaLabel={t("Subscribe to issue")}
                            />
                        )}
                    </Button>
                </>
            }
        >
            {comments && loaded ? (
                <MessageLog
                    messages={comments}
                    onCreateComment={handleCreateComment}
                    loading={!loaded}
                />
            ) : (
                <Loading />
            )}

            <>
                <Portal>
                    {viewedByModalVisible && (
                        <Modal
                            title={t("Issue Seen By")}
                            hide={viewedByModalHide}
                        >
                            {viewers ? (
                                <SimpleTable
                                    columns={issueViewersColumns}
                                    data={viewers}
                                    withTimeline={true}
                                />
                            ) : (
                                t("Issue not viewed yet")
                            )}
                        </Modal>
                    )}
                </Portal>
            </>
        </Card>
    );
};

export default IssueTimeline;
