import { orderBy } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { of, switchMap } from "rxjs";
import {
    Alert,
    Button,
    Checkbox,
    Form,
    InputField,
    Modal,
    Portal,
    Select,
    SelectFilter,
} from "../../components";
import FileUpload from "../../components/FileUpload";
import InputDatePicker from "../../components/InputDatePicker";
import { ISelectOption } from "../../components/Select";
import useCreateDocument from "../../utils/api/documents/useCreateDocument";
import { useEngineers } from "../../utils/api/landlords";
import { usePaperworkTypes, useUploadFile } from "../../utils/api/misc";
import usePropertyStateTransition from "../../utils/api/properties/usePropertyStateTransition";
import useStateMachines from "../../utils/api/properties/useStateMachines";
import { getNow } from "../../utils/dates";
import {
    isRequired,
    useValidateField,
    validateForm,
} from "../../utils/validation";
import isTrue from "../../utils/validation/isTrue";
import ModalBody from "../Modal/ModalBody";
import ModalFooter from "../Modal/ModalFooter";
import styles from "./DocumentUploadModal.module.scss";

const DocumentUploadModal = ({
    hide,
    landlordId,
    propertyId,
    propertyLetterStateId,
    jobId,
    propertyAddress,
    onSuccess,
}: IDocumentUploadModalProps) => {
    const { t } = useTranslation();

    const uploadFile = useUploadFile();
    const createDocument = useCreateDocument();
    const engineers = useEngineers({
        sortProperty: "name",
        sortDirection: "asc",
    });
    const paperworkTypes = usePaperworkTypes();

    const { transitionState } = usePropertyStateTransition();

    const stateMachines = useStateMachines({
        landlordId,
        name: "Gas Lettering Process",
    });

    const [selectedEngineerId, setSelectedEngineerId] = useState("");
    const [selectedDocumentDate, setSelectedDocumentDate] = useState(getNow());
    const [selectedPaperworkTypeId, setSelectedPaperworkTypeId] = useState("");
    const [selectedLetterStateId, setSelectedLetterStateId] = useState(
        propertyLetterStateId ? propertyLetterStateId.toString() : "",
    );
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [paperworkName, setPaperworkName] = useState("");
    const [changeLetterStatus, setChangeLetterStatus] = useState(false);

    const [filename, setFilename] = useState("");
    const [file, setFile] = useState<File | null>(null);
    const handleFileSelected = useCallback(
        (newFilename: string, newFile: File | null) => {
            setFilename(newFilename);
            setFile(newFile);
        },
        [],
    );

    const updateSelectedPaperworkTypeId = useCallback(
        (paperworkTypeId: string) => {
            setSelectedPaperworkTypeId(paperworkTypeId);
            setPaperworkName("");
        },
        [setSelectedPaperworkTypeId, setPaperworkName],
    );

    const letterStateMachine = useMemo(
        () =>
            (stateMachines.records &&
                stateMachines.records.length > 0 &&
                stateMachines.records[0]) ||
            null,
        [stateMachines.records],
    );

    const letterStateMachineSelectOptions = useMemo<ISelectOption[]>(() => {
        return (
            (letterStateMachine &&
                letterStateMachine.states &&
                orderBy(letterStateMachine.states, (state) => state.id).map(
                    (state) => ({
                        label: t(
                            `${state.name}${
                                state.id === propertyLetterStateId
                                    ? " (Current)"
                                    : ""
                            }`,
                        ),
                        value: state.id.toString(),
                    }),
                )) ||
            []
        );
    }, [letterStateMachine, propertyLetterStateId, t]);

    const paperworkTypeSelectOptions = useMemo<ISelectOption[]>(() => {
        return paperworkTypes.value.map((paperworkType) => ({
            label: t(paperworkType.paperworkType),
            value: paperworkType.id.toString(),
        }));
    }, [paperworkTypes.value, t]);

    const submitDocument = () => {
        if (file) {
            uploadFile
                .uploadFile(
                    `landlord/${landlordId}/property/${propertyId}/${
                        jobId ? `job/${jobId}/` : ""
                    }documents/${filename}`,
                    file,
                )
                .pipe(
                    switchMap((result) =>
                        createDocument.createDocument({
                            date: selectedDocumentDate,
                            paperworkTypeId: Number(selectedPaperworkTypeId),
                            paperworkName: paperworkName,
                            paperworkUrl: result.filename,
                            engineerId: selectedEngineerId
                                ? Number(selectedEngineerId)
                                : undefined,
                            propertyId: propertyId,
                            jobId: jobId,
                        }),
                    ),
                    switchMap((result) => {
                        if (changeLetterStatus) {
                            return transitionState(propertyId, {
                                stateId: Number(selectedLetterStateId),
                                transitionDate: selectedDocumentDate,
                            });
                        }
                        return of(result);
                    }),
                )
                .subscribe(() => {
                    onSuccess();
                    hide();
                });
        }
    };

    const getPaperworkId = useCallback(
        (paperworkTypeName: string) => {
            const paperwork = paperworkTypes.value.find(
                (paperworkType) =>
                    paperworkType.paperworkType === paperworkTypeName,
            );

            if (paperwork) {
                return paperwork.id.toString();
            }
        },
        [paperworkTypes.value],
    );

    const otherPaperworkId = useMemo(
        () => getPaperworkId("Other"),
        [getPaperworkId],
    );

    const utaLetterPaperworkId = useMemo(
        () => getPaperworkId("UTA Letter"),
        [getPaperworkId],
    );

    const paperworkTypeIdValidator = useValidateField(
        selectedPaperworkTypeId,
        isRequired(),
    );

    const letterStateIdValidator = useValidateField(
        selectedLetterStateId,
        isRequired(),
    );

    const paperworkNameValidator = useValidateField(
        paperworkName,
        isRequired(),
    );
    const acceptTermsValidator = useValidateField(acceptTerms, isTrue());

    const fileValidator = useValidateField(file, isRequired());

    const documentUploadValidators = validateForm(() => {
        const validators = [
            acceptTermsValidator,
            paperworkTypeIdValidator,
            fileValidator,
        ];

        if (
            selectedPaperworkTypeId === otherPaperworkId ||
            selectedPaperworkTypeId === utaLetterPaperworkId
        ) {
            validators.push(paperworkNameValidator);
        }

        if (changeLetterStatus) {
            validators.push(letterStateIdValidator);
        }

        return validators;
    });

    const engineerOptions = useMemo<ISelectOption[]>(
        () =>
            engineers.records.map((property) => ({
                value: property.id.toString(),
                label: property.name,
            })),
        [engineers.records],
    );

    return (
        <Portal>
            <Form onSubmit={submitDocument} {...documentUploadValidators}>
                <Modal
                    hide={hide}
                    title={`${t("Upload Document to ")} ${
                        propertyAddress || t("Property")
                    }`}
                >
                    <ModalBody>
                        <p className={styles.warningText}>
                            {t(
                                `Uploading documents here will not reset the service date. To reset the service date, please use the manual uploads area and select upload a job.`,
                            )}
                        </p>

                        {!jobId && (
                            <SelectFilter
                                label={t("Engineer")}
                                value={selectedEngineerId}
                                onChange={setSelectedEngineerId}
                                {...engineers}
                                options={engineerOptions}
                            />
                        )}

                        <InputDatePicker
                            label={t("Document Date")}
                            date={selectedDocumentDate}
                            onDateSelected={setSelectedDocumentDate}
                        />

                        <Select
                            label={t("Select Paperwork Type")}
                            options={paperworkTypeSelectOptions}
                            onChange={updateSelectedPaperworkTypeId}
                            value={selectedPaperworkTypeId}
                            allowEmpty={true}
                            {...paperworkTypeIdValidator}
                        />

                        {(selectedPaperworkTypeId === otherPaperworkId ||
                            selectedPaperworkTypeId ===
                                utaLetterPaperworkId) && (
                            <InputField
                                label={t("Paperwork Name")}
                                value={paperworkName}
                                onChange={setPaperworkName}
                                {...paperworkNameValidator}
                            />
                        )}

                        {/* Only display letter status changing checkbox if all of the following conditions are met:
                        - This is a property upload modal
                        - There is a letter state machine on the landlord
                        - The currently selected paperwork type is UTA Letter
                    */}
                        {!jobId &&
                            letterStateMachine &&
                            selectedPaperworkTypeId ===
                                utaLetterPaperworkId && (
                                <Checkbox
                                    checked={changeLetterStatus}
                                    onChange={setChangeLetterStatus}
                                >
                                    {t(
                                        `Do you want to update the letter status?`,
                                    )}
                                </Checkbox>
                            )}

                        {changeLetterStatus && (
                            <Select
                                label={t("Letter Status")}
                                options={letterStateMachineSelectOptions}
                                onChange={setSelectedLetterStateId}
                                value={selectedLetterStateId}
                                allowEmpty={true}
                                {...letterStateIdValidator}
                            />
                        )}

                        <FileUpload
                            label={t("Document")}
                            maxFileSizeInMB={5}
                            onFileSelected={handleFileSelected}
                            isLoading={uploadFile.loading}
                            allowedFileTypes={[".pdf"]}
                            {...fileValidator}
                        />
                        <Checkbox
                            checked={acceptTerms}
                            onChange={setAcceptTerms}
                            {...acceptTermsValidator}
                        >
                            {t(
                                `I understand that this document was not created or uploaded by Plentific.
                        Its contents are the responsibility of the person who created it.
                        The details (other than the paperwork captured) in this form are my responsibility.`,
                            )}
                        </Checkbox>
                        {createDocument.error && (
                            <Alert type="error">
                                {t(createDocument.error)}
                            </Alert>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="primary" type="submit">
                            {t("Save")}
                        </Button>
                    </ModalFooter>
                </Modal>
            </Form>
        </Portal>
    );
};

interface IDocumentUploadModalProps {
    hide: () => void;
    landlordId: number;
    propertyId: number;
    propertyLetterStateId?: number;
    jobId?: number;
    propertyAddress: string;
    onSuccess: () => void;
}

export default DocumentUploadModal;
