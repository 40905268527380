import { useContext, useEffect } from "react";
import { ICount } from ".";
import { PropertyCategoriesContext, UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiValue from "../useApiValue";

export const usePropertyCounts = ({ safetyRiskLevel }: IFilterOptions) => {
    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);
    const { send, loading, value } = useApiValue<ICount>({});
    const sendRequest = useXTagApiRequest();

    useEffect(() => {
        const subscription = send(
            sendRequest({
                url: `${config.propertiesApiUrl}/count`,
                method: "GET",
                urlParams: {
                    ...(safetyRiskLevel === "true"
                        ? {
                              landlordUnsafeAssets: [">=1"],
                          }
                        : safetyRiskLevel === "false"
                        ? {
                              tenantUnsafeAssets: [">=1"],
                          }
                        : {
                              totalUnsafeAssets: [">=1"],
                          }),
                    ...getGlobalUrlParameters(
                        selectedCategories.map((c) => c.id),
                        activeUserParentsIds,
                    ),
                },
            }),
        ).subscribe();

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [
        activeUserParentsIds,
        safetyRiskLevel,
        selectedCategories,
        send,
        sendRequest,
    ]);

    return {
        loading,
        value,
    };
};

interface IFilterOptions {
    safetyRiskLevel?: string;
}

export default usePropertyCounts;
