import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, GridColumn, Icon } from "../../../components";
import { IFuelType, IRecentTasks } from "../../../utils/api/misc";
import styles from "./RecentTasks.module.scss";

interface IRecentTasksModel {
    [key: string]: IRecentTask;
}

interface IRecentTask {
    fuelType: IFuelType;
    tasksCount: number;
}

const RecentTasks = ({ tasks }: IRecentTasksProps) => {
    const { t } = useTranslation();

    const [recentTasks, setRecentTasks] = useState<IRecentTask[]>([]);

    useEffect(() => {
        const result: IRecentTasksModel = {};

        for (const landlordTasks of tasks.filter((task) => task.data)) {
            for (const taskData of landlordTasks.data) {
                if (taskData.total > 0) {
                    if (!result[taskData.fuelType.id]) {
                        result[taskData.fuelType.id] = {
                            fuelType: taskData.fuelType,
                            tasksCount: taskData.total,
                        };
                    } else {
                        result[taskData.fuelType.id].tasksCount +=
                            taskData.total;
                    }
                }
            }
        }

        setRecentTasks(
            Object.values(result).sort((a, b) =>
                a.fuelType.displayName < b.fuelType.displayName ? -1 : 1,
            ),
        );
    }, [tasks]);

    return (
        <div className={styles.centerContainer}>
            {recentTasks.map((task) => (
                <div
                    className={styles.recentTasksContainer}
                    key={task.fuelType.id}
                >
                    <Grid
                        margin={false}
                        alignCenter={true}
                        alignCenterVertical={true}
                    >
                        <GridColumn
                            size="oneQuarter"
                            cssRules={{
                                backgroundColor: task.fuelType.color,
                            }}
                        >
                            <div
                                className={`${styles.iconWrapper} ${styles.white}`}
                                title={task.fuelType.displayName}
                            >
                                <Icon
                                    icon={task.fuelType.name}
                                    size={50}
                                    ariaHidden={true}
                                />
                                <div className={styles.fuelTypeDisplay}>
                                    {task.fuelType.displayName}
                                </div>
                            </div>
                        </GridColumn>
                        <GridColumn size="threeQuarters">
                            <div className={styles.workWrapper}>
                                <div
                                    className={styles.count}
                                    data-test-id={`${task.fuelType.displayName.replace(
                                        " ",
                                        "_",
                                    )}_Tasks_Total_Span`}
                                >
                                    {task.tasksCount}
                                </div>
                                &nbsp;
                                {t("Tasks")}
                            </div>
                        </GridColumn>
                    </Grid>
                </div>
            ))}
        </div>
    );
};

interface IRecentTasksProps {
    tasks: IRecentTasks[];
}

export default RecentTasks;
