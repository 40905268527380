import { useCallback, useContext } from "react";
import { UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const useCancelAwaitingAllocationJob = () => {
    const { activeUserParentsIds } = useContext(UserContext);
    const { loading, send } = useApiRequest({});
    const sendRequest = useXTagApiRequest();

    const cancelAwaitingAllocationJob = useCallback(
        (awaitingIds: string[], reason: string) =>
            send(
                sendRequest({
                    url: `${config.allocatedJobsApiUrl}/awaitingallocation/cancel`,
                    method: "POST",
                    urlParams: getGlobalUrlParameters([], activeUserParentsIds),
                    body: {
                        awaitingIds,
                        reason,
                    },
                }),
            ),
        [activeUserParentsIds, send, sendRequest],
    );

    return { loading, cancelAwaitingAllocationJob };
};

export default useCancelAwaitingAllocationJob;
