import { CSSProperties, useMemo } from "react";
import { ColourPillColour } from ".";
import { useCssClasses } from "../../hooks";
import styles from "./ColourPill.module.scss";

const ColourPill = ({
    colour,
    customColour,
    value,
    fullWidth = false,
    textCenter = false,
}: IColourPillProps) => {
    const cssClasses = useCssClasses(
        styles.pill,
        fullWidth ? styles.fullWidth : "",
        textCenter ? styles.textCenter : "",
        colour === "orange"
            ? styles.orange
            : colour === "blue"
            ? styles.blue
            : colour === "green"
            ? styles.green
            : colour === "lightBlue"
            ? styles.lightBlue
            : "",
    );

    const cssProperties = useMemo<CSSProperties>(() => {
        if (customColour) {
            return { backgroundColor: customColour };
        }

        return {};
    }, [customColour]);

    return (
        <span className={cssClasses} style={cssProperties}>
            {value}
        </span>
    );
};

interface IColourPillProps {
    colour?: ColourPillColour;
    customColour?: string;
    value: string;
    fullWidth?: boolean;
    textCenter?: boolean;
}

export default ColourPill;
