import { t } from "i18next";
import { useCallback, useContext, useEffect } from "react";
import { IAnswerOverride } from ".";
import { PropertyCategoriesContext, UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiValue from "../useApiValue";

const useAuditOverriddenAnswers = (id: number) => {
    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);
    const { value, error, loading, send, updateValue } = useApiValue<
        IAnswerOverride[]
    >({
        initialValue: [],
        errorMessage: t("An error occured. Please try again."),
    });
    const sendRequest = useXTagApiRequest();

    useEffect(() => {
        const subscription = send(
            sendRequest({
                url: `${config.auditsApiUrl}/${id}/answers`,
                method: "GET",
                urlParams: getGlobalUrlParameters(
                    selectedCategories.map((c) => c.id),
                    activeUserParentsIds,
                ),
            }),
        ).subscribe();

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [id, selectedCategories, activeUserParentsIds, send, sendRequest]);

    const updateOverriddenAnswers = useCallback(
        (
            overrideAnswers: IAnswerOverride[],
            deleteOverriddenAnswers: number[],
        ) => {
            const dictionary: {
                [answerId: number]: {
                    answer: string;
                    comment: string | null;
                    isDeleted: boolean;
                };
            } = {};

            for (const answer of value) {
                dictionary[answer.answerId] = {
                    answer: answer.payload,
                    comment: answer.comments,
                    isDeleted: answer.isDeleted,
                };
            }

            for (const answer of overrideAnswers) {
                dictionary[answer.answerId] = {
                    answer: answer.payload,
                    comment: answer.comments,
                    isDeleted: answer.isDeleted,
                };
            }

            for (const answer of deleteOverriddenAnswers) {
                delete dictionary[answer];
            }

            const list = Object.entries(dictionary).map<IAnswerOverride>(
                ([answerId, group]) => ({
                    answerId: Number(answerId),
                    payload: group.answer,
                    comments: group.comment,
                    isDeleted: group.isDeleted,
                }),
            );

            updateValue(list);
        },
        [updateValue, value],
    );

    return {
        value,
        loading,
        error,
        updateOverriddenAnswers,
    };
};

export default useAuditOverriddenAnswers;
