import { ChangeEvent, useEffect, useRef, useState } from "react";
import { ValidationError } from "..";
import { useCssClasses } from "../../hooks";
import { useHtmlEntityId } from "../../hooks/useHtmlEntityId";
import styles from "./MultilineInputField.module.scss";

const MultilineInputField = ({
    label = "",
    value = "",
    useMargin = true,
    isValid,
    error,
    onChange = () => null,
}: ITextAreaProps) => {
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const [inputValue, setInputValue] = useState(value);
    useEffect(() => setInputValue(value), [value]);

    useEffect(() => {
        if (textAreaRef.current) {
            textAreaRef.current.style.height = "auto";
            textAreaRef.current.style.height =
                Math.ceil(textAreaRef.current.scrollHeight + 4) + "px";
        }
    }, [inputValue]);

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
        const newValue = event.target.value;

        setInputValue(newValue);
        onChange(newValue);
    };

    const id = useHtmlEntityId();

    const cssClasses = useCssClasses(useMargin ? styles.margin : "");

    return (
        <div className={cssClasses}>
            <label htmlFor={id} className={styles.label}>
                {label}
            </label>

            <textarea
                id={id}
                className={styles.textArea}
                ref={textAreaRef}
                value={inputValue}
                onChange={handleChange}
            />

            <ValidationError isValid={isValid} error={error} />
        </div>
    );
};

interface ITextAreaProps {
    label: string;
    value?: string;
    isValid?: boolean;
    error?: string;
    useMargin?: boolean;
    onChange?: (value: string) => void;
}

export default MultilineInputField;
