import { ISimpleJob } from ".";
import config from "../../../config";
import useFilterable, { SortDirection } from "../useFilterable";

const useRecoveryJobs = ({
    sortProperty = "",
    sortDirection = "asc",
}: ISortParameters) => {
    const filterable = useFilterable<ISimpleJob>(config.jobsApiUrl, {
        params: {
            sortProperty,
            sortDirection,
            filters: {
                recoveryJob: {
                    operator: "{AND}",
                    readOnly: true,
                    filters: [{ function: "=", value: "true" }],
                },
            },
        },
        filtersUrl: `${config.jobsApiUrl}/filters/unique`,
    });

    return filterable;
};

interface ISortParameters {
    sortProperty?: string;
    sortDirection?: SortDirection;
}

export default useRecoveryJobs;
