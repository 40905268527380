import { IPropertyServiceDates } from ".";
import config from "../../../config";
import useFilterable, { IFilterableParams } from "../useFilterable";

const usePropertyServiceDates = (params?: IFilterableParams) => {
    const filterable = useFilterable<IPropertyServiceDates>(config.propertiesApiUrl + '/servicedates', {
        params,
    });

    return filterable;
};

export default usePropertyServiceDates;
