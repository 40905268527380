import { useCallback } from "react";
import { IUpdateUserNotificationTypes } from ".";
import config from "../../../config";
import { useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const useUpdateUserNotificationTypes = () => {
    const { send, loading } = useApiRequest({});
    const sendRequest = useXTagApiRequest();

    const updateUserNotificationTypes = useCallback(
        (notificationTypes: IUpdateUserNotificationTypes[]) =>
            send(
                sendRequest({
                    url: `${config.usersApiUrl}/notifications`,
                    method: "PUT",
                    body: notificationTypes,
                }),
            ),
        [send, sendRequest],
    );

    return { updateUserNotificationTypes, loading };
};

export default useUpdateUserNotificationTypes;
