import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, Icon, InputField, Map } from "../../../components";
import { IMapMarker } from "../../../components/Map";
import { useHasUserParentPermission, useToggle } from "../../../hooks";
import {
    isNumber,
    isRequired,
    useValidateField,
    validateForm,
} from "../../../utils/validation";
import styles from "./EditableMap.module.scss";

// TODO: Recenter the map when typing the coordinates.
const EditableMap = ({
    icon,
    marker,
    loading,
    userParentId,
    onMarkerUpdate,
}: IEditableMapProps) => {
    const { t } = useTranslation();
    const { show, hide, visible } = useToggle();

    const canEditProperties = useHasUserParentPermission(
        "edit_properties",
        userParentId,
    );

    const [lat, setLat] = useState("");
    const [lng, setLng] = useState("");
    useEffect(() => {
        setLng(marker ? marker.geometry.coordinates[0].toString() : "");
        setLat(marker ? marker.geometry.coordinates[1].toString() : "");
    }, [marker]);

    const [editableMarker, setEditableMarker] = useState(marker);
    useEffect(() => {
        setEditableMarker({
            type: "Feature",
            geometry: {
                type: "Point",
                coordinates: [Number(lng), Number(lat)],
            },
            id: 0,
            properties: {
                icon,
                draggable: visible,
            },
        });
    }, [lat, lng, visible, icon]);

    const markers = useMemo(() => {
        const array: IMapMarker[] = [];

        if (editableMarker) {
            array.push(editableMarker);
        }

        return array;
    }, [editableMarker]);

    const handleMarkerDragEnd = useCallback(
        (newLat: number, newLng: number) => {
            setLat(newLat.toString());
            setLng(newLng.toString());
        },
        [],
    );

    const handleCancelClick = () => {
        setLng(marker ? marker.geometry.coordinates[0].toString() : "");
        setLat(marker ? marker.geometry.coordinates[1].toString() : "");

        hide();
    };

    const latValidator = useValidateField(lat, isRequired(), isNumber());
    const lngValidator = useValidateField(lng, isRequired(), isNumber());
    const formValidation = validateForm(() => [latValidator, lngValidator]);

    const handleFormSubmit = () => {
        hide();
        onMarkerUpdate(Number(lat), Number(lng));
    };

    return (
        <Map
            height="100%"
            minHeight={300}
            lat={Number(lat)}
            lng={Number(lng)}
            markers={markers}
            onMarkerDragEnd={handleMarkerDragEnd}
        >
            <Form onSubmit={handleFormSubmit} {...formValidation}>
                <div className={styles.container}>
                    {canEditProperties && !visible && (
                        <Button size="small" onClick={show}>
                            <Icon
                                icon="pencil"
                                ariaLabel={t("Edit coordinates")}
                            />
                        </Button>
                    )}

                    {visible && (
                        <>
                            <Button
                                size="small"
                                onClick={handleCancelClick}
                                cssRules={{ marginRight: "5px" }}
                            >
                                <Icon icon="close" ariaLabel={t("Cancel")} />
                            </Button>
                            <InputField
                                placeholder={t("Latitude")}
                                size="small"
                                value={lat}
                                onChange={setLat}
                                useMargin={false}
                                cssRules={{ marginRight: "5px" }}
                            />
                            <InputField
                                placeholder={t("Longitude")}
                                size="small"
                                value={lng}
                                onChange={setLng}
                                useMargin={false}
                                cssRules={{ marginRight: "5px" }}
                            />
                            <Button
                                size="small"
                                variant="primary"
                                type="submit"
                                disabled={!formValidation.isValid || loading}
                            >
                                <Icon icon="check" ariaLabel={t("Save")} />
                            </Button>
                        </>
                    )}
                </div>
            </Form>
        </Map>
    );
};

interface IEditableMapProps {
    marker?: IMapMarker;
    loading: boolean;
    icon: string;
    userParentId: number | null;
    onMarkerUpdate: (lat: number, lng: number) => void;
}

export default EditableMap;
