import { ReactNode } from "react";
import { Card } from "..";
import styles from "./HeroCard.module.scss";

const HeroCard = ({ title, children, subtitle }: IHeroCardProps) => {
    return (
        <Card padding={false}>
            <div className={styles.container}>
                {children}
                <div className={styles.titleContainer}>
                    <div className={styles.title}>{title}</div>
                    <div className={styles.subtitle}>{subtitle}</div>
                </div>
            </div>
        </Card>
    );
};

interface IHeroCardProps {
    title: string;
    children: ReactNode;
    subtitle?: string;
}

export default HeroCard;
