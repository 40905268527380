import { useTranslation } from "react-i18next";
import { BaseAppliance } from ".";
import { IEditableDetailsData } from "../../components/EditableDetailsTable";
import { IAppliance, IFireDoorAppliance } from "../../utils/api/appliances";

const FireDoorAppliance = ({ appliance }: IFireDoorApplianceProps) => {
    const { t } = useTranslation();

    const getColumns = () => {
        const detailTableColumns: IEditableDetailsData[] = [
            {
                title: t("Door Type"),
                type: "string",
                path: "doorType",
            },
            {
                title: t("Door Id"),
                type: "string",
                path: "doorId",
            },
            {
                title: t("Floor"),
                type: "string",
                path: "floor",
            },
        ];

        return detailTableColumns;
    };

    const patchUpdateModel = (model: IAppliance) => {
        return model;
    };

    return (
        <BaseAppliance
            appliance={appliance}
            getColumns={getColumns}
            patchUpdateModel={patchUpdateModel}
        />
    );
};

interface IFireDoorApplianceProps {
    appliance: IFireDoorAppliance;
}

export default FireDoorAppliance;
