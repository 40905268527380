import { IComplianceDashboard } from "../../../utils/api/misc";
import RecentWorksCard from "./RecentWorksCard";

export interface IRecentWorksCardProps {
    loaded: boolean;
    data: IComplianceDashboard;
    maxHeight: number;
}

export default RecentWorksCard;
