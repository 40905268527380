import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { IServiceDateApprovalModalProps } from ".";
import {
    Alert,
    Button,
    Form,
    Loading,
    Modal,
    Portal,
} from "../../../components";
import ModalBody from "../../../components/Modal/ModalBody";
import ModalFooter from "../../../components/Modal/ModalFooter";
import { useManualUploadResetApprovalUpdate } from "../../../utils/api/jobs";
import { clearCache } from "../../../utils/cache";

const ServiceDateApprovalModal = ({
    jobIds,
    updateType,
    updatedAction,
    hide,
}: IServiceDateApprovalModalProps) => {
    const { t } = useTranslation();

    const approvalUpdate = useManualUploadResetApprovalUpdate();

    const approvalHandler = useCallback(() => {
        approvalUpdate
            .update({ jobIds, approved: updateType === "approved" })
            .subscribe(() => {
                clearCache();
                hide();
                updatedAction();
            });
    }, [approvalUpdate, hide, jobIds, updateType, updatedAction]);

    return (
        <Portal>
            <Form onSubmit={approvalHandler}>
                <Modal
                    title={t(
                        `${
                            updateType === "approved" ? "Approve" : "Decline"
                        } service date update`,
                    )}
                    hide={hide}
                >
                    <ModalBody>
                        <p>
                            {t(
                                `Are you sure you want to ${
                                    updateType === "approved"
                                        ? "approve"
                                        : "decline"
                                } ${
                                    jobIds.length > 1 ? "these" : "this"
                                } service date update${
                                    jobIds.length > 1 ? "s" : ""
                                }?`,
                            )}
                        </p>
                        <p>{t("This action can not be undone.")}</p>

                        {approvalUpdate.loading ? (
                            <Loading small={true} centerContent={true} />
                        ) : (
                            approvalUpdate.error && (
                                <Alert type="error">
                                    {t(
                                        "An error occurred whilst updating the records, please try again later.",
                                    )}
                                </Alert>
                            )
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            type="submit"
                            variant="primary"
                            cssRules={{
                                marginRight: "10px",
                            }}
                            disabled={approvalUpdate.loading}
                        >
                            {t("Continue")}
                        </Button>
                        <Button onClick={hide}>{t("Cancel")}</Button>
                    </ModalFooter>
                </Modal>
            </Form>
        </Portal>
    );
};

export default ServiceDateApprovalModal;
