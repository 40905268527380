import { useCallback, useContext } from "react";
import { IProperty } from ".";
import { PropertyCategoriesContext, UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const usePropertyInfo = () => {
    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);
    const { send } = useApiRequest<IProperty>({});
    const sendRequest = useXTagApiRequest();

    const getProperty = useCallback(
        (id: string) =>
            send(
                sendRequest({
                    url: `${config.propertiesApiUrl}/${id}`,
                    method: "GET",
                    urlParams: getGlobalUrlParameters(
                        selectedCategories.map((c) => c.id),
                        activeUserParentsIds,
                    ),
                }),
            ),
        [activeUserParentsIds, selectedCategories, send, sendRequest],
    );

    return { getProperty };
};

export default usePropertyInfo;
