import { useCallback, useState } from "react";
import { v4 } from "uuid";

export const useGuidGenerator = () => {
    const [ids, setIds] = useState<{ [key: string]: true | undefined }>({});

    const generateUniqueId = useCallback((): string => {
        const id = v4();
        if (ids[id]) {
            return generateUniqueId();
        } else {
            setIds({ ...ids, [id]: true });
            return id;
        }
    }, [ids]);

    return generateUniqueId;
};
