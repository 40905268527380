import { ITableColumn } from "..";
import { IFilters } from "../../../utils/api/useFilterable";
import TableFilters from "./TableFilters";

export interface ITableFiltersProps {
    clearFilters: (property: string) => void;
    filters: IFilters;
    columns: { [key: string]: ITableColumn<any> };
}

export default TableFilters;
