import { IInspectionSchedulePayload } from "../../../utils/api/answers";
import { OverriddenAnswers } from "../QualifiedSupervisorAuditRow";
import { IQualifiedSupervisorAuditAnswer } from "../QualifiedSupervisorAuditsGrouping";
import QualifiedSupervisorAuditGridColumn from "./QualifiedSupervisorAuditGridColumn";

export interface IQualifiedSupervisorAuditGridColumnProps {
    questionId: string;
    answers: IQualifiedSupervisorAuditAnswer[] | undefined;
    overriddenAnswers: OverriddenAnswers;
    documentType: string | null;
    isEditing: boolean;
    errorMessage?: string;
    validateQuestion: (questionId: string, errorMessage?: string) => void;
    overrideAnswer: (
        answerId: number,
        answer?: string | IInspectionSchedulePayload,
        comment?: string,
    ) => void;
}

export default QualifiedSupervisorAuditGridColumn;
