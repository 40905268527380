import { useContext, useEffect } from "react";
import { INoaccessTotalCount } from ".";
import { PropertyCategoriesContext, UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiValue from "../useApiValue";

export const useNoAccessCounts = () => {
    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);
    const { send, value, loaded } = useApiValue<INoaccessTotalCount>({});
    const sendRequest = useXTagApiRequest();

    useEffect(() => {
        const subscription = send(
            sendRequest({
                url: `${config.jobsApiUrl}/v2/unabletoaccess/counts`,
                method: "GET",
                urlParams: getGlobalUrlParameters(
                    selectedCategories.map((c) => c.id),
                    activeUserParentsIds,
                ),
            }),
        ).subscribe();

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [activeUserParentsIds, selectedCategories, send, sendRequest]);

    return { value, loaded };
};

export default useNoAccessCounts;
