import { cloneDeep, set } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IEditableDetailsData } from ".";
import { Button, Card, InputField } from "../../components";
import SimpleTable, { IColumn } from "../SimpleTable";

/**
 *
 * @deprecated Do not use this component. Create a Form with validation instead.
 */
const EditableDetailsTable = ({
    title,
    data,
    entity,
    toggle,
    isEditing,
    canEdit = true,
    onSave = () => null,
}: IEditableDetailsTableProps) => {
    const [model, setModel] = useState<any>(null);
    const { t } = useTranslation();

    const handleSaveClick = () => {
        toggle();
        onSave(model);
    };

    const handleCancelClick = () => {
        const initial = cloneDeep(entity);
        setModel(initial);
        toggle();
    };

    useEffect(() => {
        const initial = cloneDeep(entity);
        setModel(initial);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const parsedFields = useMemo<IColumn[]>(() => {
        return data.map((item) => {
            switch (item.type) {
                case "string": {
                    const stringRender = (value: string) => (
                        <InputField
                            onChange={handleInputChange}
                            value={value}
                            placeholder={item.title}
                        />
                    );

                    const handleInputChange = (val: string) => {
                        const newModel = set(model, item.path, val);
                        setModel(newModel);
                    };

                    return {
                        title: item.title,
                        ...(isEditing && !item.disabled
                            ? { render: stringRender }
                            : {}),
                        type: item.type,
                        path: item.path,
                        hidden: item.hidden,
                    };
                }
                case "component":
                    return {
                        title: item.title,
                        ...(item.render
                            ? {
                                  render: item.render,
                              }
                            : {}),
                        type: item.type,
                        path: item.path,
                        hidden: item.hidden,
                    };
                default:
                    return {
                        title: item.title,
                        path: item.path,
                        type: item.type,
                        ...(item.render ? { render: item.render } : {}),
                        hidden: item.hidden,
                    };
            }
        });
    }, [data, isEditing, model]);

    return (
        <Card
            title={title}
            actions={
                canEdit &&
                (isEditing ? (
                    <>
                        <Button
                            onClick={handleSaveClick}
                            cssRules={{ marginRight: "10px" }}
                        >
                            {t("Save")}
                        </Button>
                        <Button onClick={handleCancelClick}>
                            {t("Cancel")}
                        </Button>
                    </>
                ) : (
                    <Button onClick={toggle}>{t("Edit")}</Button>
                ))
            }
        >
            <SimpleTable
                data={entity}
                columns={parsedFields.filter((f) => !f.hidden)}
                single={true}
            />
        </Card>
    );
};

interface IEditableDetailsTableProps {
    title?: string;
    data: IEditableDetailsData[];
    entity: any;
    toggle: () => void;
    isEditing: boolean;
    canEdit?: boolean;
    onSave?: (model: any) => void;
}

export default EditableDetailsTable;
