import { useCallback, useContext } from "react";
import { IPropertyCategory } from ".";
import { UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";

const useDeletePropertyCategoryRequest = (propertyId: number) => {
    const { activeUserParentsIds } = useContext(UserContext);
    const sendRequest = useXTagApiRequest();

    const request = useCallback(
        (categoryId: string) =>
            sendRequest<IPropertyCategory, unknown>({
                url: `${config.propertiesApiUrl}/${propertyId}/categories/${categoryId}`,
                method: "DELETE",
                urlParams: getGlobalUrlParameters([], activeUserParentsIds),
            }),
        [sendRequest, propertyId, activeUserParentsIds],
    );

    return request;
};

export default useDeletePropertyCategoryRequest;
