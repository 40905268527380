import { useCallback, useContext } from "react";
import { UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const useAssignIssue = () => {
    const { activeUserParentsIds } = useContext(UserContext);
    const { send, loading } = useApiRequest({});
    const sendRequest = useXTagApiRequest();

    const assignIssue = useCallback(
        (userId: number, issueIds: number[]) =>
            send(
                sendRequest({
                    url: `${config.issuesApiUrl}/assign`,
                    method: "POST",
                    urlParams: getGlobalUrlParameters([], activeUserParentsIds),
                    body: {
                        issueIds,
                        userId,
                    },
                }),
            ),
        [activeUserParentsIds, send, sendRequest],
    );

    return { assignIssue, loading };
};

export default useAssignIssue;
