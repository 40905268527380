import { useEffect, useMemo, useState } from "react";
import { Button, Card } from "..";

const TabCard = ({
    currentTab = 0,
    children,
    fullHeight,
    maxHeight,
    testId,
}: ITabCardProps) => {
    const [activeTab, setActiveTab] = useState(currentTab);
    useEffect(() => {
        setActiveTab(currentTab);
    }, [currentTab]);

    const tab = useMemo(
        () => children.filter((child, index) => index === activeTab)[0],
        [activeTab, children],
    );

    return (
        <Card
            testId={testId}
            title={
                <>
                    {children.map((child, index) => (
                        <Button
                            key={index}
                            disabled={index === activeTab}
                            onClick={setActiveTab}
                            clickParams={[index]}
                            cssRules={{
                                marginRight: "8px",
                                opacity: index === activeTab ? 1 : 0.5,
                            }}
                        >
                            {child.props.title}
                        </Button>
                    ))}
                </>
            }
            fullHeight={fullHeight}
            maxHeight={maxHeight}
            actions={tab.props.actions}
        >
            {tab}
        </Card>
    );
};

interface ITabCardProps {
    currentTab?: number;
    fullHeight?: boolean;
    maxHeight?: number;
    children: JSX.Element[];
    testId?: string;
}

export default TabCard;
