import { ReactNode, useContext, useMemo } from "react";
import { Loading, PropertyCategoriesContext, UserContext } from "../..";

const ProvidersStatus = ({ children }: IProvidersStatusProps) => {
    const propertyCategoriesContext = useContext(PropertyCategoriesContext);
    const userContext = useContext(UserContext);

    const isReady = useMemo(
        () =>
            [propertyCategoriesContext, userContext].every(
                (context) => context.isReady,
            ),
        [propertyCategoriesContext, userContext],
    );

    return isReady ? <>{children}</> : <Loading />;
};

interface IProvidersStatusProps {
    children: ReactNode;
}

export default ProvidersStatus;
