import { useCallback, useState } from "react";

const useToggle = (initialValue = false) => {
    const [visible, setVisible] = useState(initialValue);

    const show = useCallback(() => setVisible(true), []);
    const hide = useCallback(() => setVisible(false), []);
    const toggle = useCallback(() => setVisible((value) => !value), []);

    return {
        visible,
        show,
        hide,
        toggle,
    };
};

export default useToggle;
