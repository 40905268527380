import { useCallback, useContext, useEffect } from "react";
import { ICappedPropertiesGraph } from ".";
import { PropertyCategoriesContext, UserContext } from "../../../components";
import config from "../../../config";
import { clearCache } from "../../cache";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiValue from "../useApiValue";

const useCappedMaintenanceGraph = (occupied: string) => {
    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);
    const { send, value, loaded } = useApiValue<ICappedPropertiesGraph>({});
    const sendRequest = useXTagApiRequest();

    const refresh = useCallback(
        (shouldClearCache = true) => {
            if (shouldClearCache) {
                clearCache();
            }

            return send(
                sendRequest({
                    url: `${config.propertiesApiUrl}/graph/capped`,
                    method: "GET",
                    urlParams: {
                        cappedOccupied: [occupied],
                        ...getGlobalUrlParameters(
                            selectedCategories.map((c) => c.id),
                            activeUserParentsIds,
                        ),
                    },
                }),
            );
        },
        [activeUserParentsIds, occupied, selectedCategories, send, sendRequest],
    );

    useEffect(() => {
        const subscription = refresh(false).subscribe();

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [occupied, refresh]);

    return { refresh, value, loaded };
};

export default useCappedMaintenanceGraph;
