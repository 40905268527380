import { IUser } from ".";
import config from "../../../config";
import useFilterable, { IFilterableParams } from "../useFilterable";

const useUsers = (params?: IFilterableParams) => {
    const filterable = useFilterable<IUser>(`${config.usersApiUrl}/v2`, {
        params: {
            ...params,
            filters: {
                disabled: {
                    readOnly: true,
                    operator: "{AND}",
                    filters: [{ function: "=", value: "false" }],
                },
            },
        },
    });
    return filterable;
};

export default useUsers;
