import { useCssClasses } from "../../hooks";
import styles from "./ValidationError.module.scss";

const ValidationError = ({
    isValid = true,
    error = "",
    useMargin = true,
}: IValidationProps) => {
    const cssClasses = useCssClasses(
        styles.container,
        useMargin ? styles.margin : "",
    );

    if (!isValid && error) {
        return <div className={cssClasses}>{error}</div>;
    }

    return null;
};

interface IValidationProps {
    isValid?: boolean;
    error?: string;
    useMargin?: boolean;
}

export default ValidationError;
