import { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { first, switchMap } from "rxjs";
import {
    Card,
    Grid,
    GridColumn,
    Loading,
    UserPreferencesContext,
} from "../../../components";
import Link from "../../../components/Link/Link";
import { IPhase, useNextMaintenanceGraph } from "../../../utils/api/properties";
import { getToday } from "../../../utils/dates";
import { encodeUrl } from "../../../utils/url";
import ChartActions from "../ChartComponents/ChartActions/ChartActions";
import ChartOverview from "../ChartComponents/ChartOverview";
import PropertyGraph from "../ChartComponents/PropertyGraph";
import styles from "./NextMaintenance.module.scss";

const NextMaintenance = () => {
    const { t } = useTranslation();

    const { preferences, updatePreferences } = useContext(
        UserPreferencesContext,
    );

    const { value, loaded, refresh } = useNextMaintenanceGraph();

    const [showGraph, setShowGraph] = useState(
        preferences.fire_door_maintenance_view === "graph",
    );

    const onSave = useCallback(
        (phases: IPhase[]) => {
            updatePreferences({
                ...preferences,
                fire_door_maintenance_phases: phases.map((phase) => ({
                    colour: phase.colour,
                    days_until_deadline: phase.phaseTimeFrame,
                    title: phase.title,
                })),
            })
                .pipe(switchMap(() => refresh().pipe(first())))
                .subscribe();
        },
        [preferences, refresh, updatePreferences],
    );

    const maintenancePhases = useMemo(
        () => (value ? value.phases.filter((p) => p.dates.length > 0) : []),
        [value],
    );

    const toggleGraphOverview = (view: "graph" | "overview"): void => {
        updatePreferences({
            ...preferences,
            fire_door_maintenance_view: view,
        }).subscribe(() => setShowGraph(view === "graph"));
    };

    const maintenanceRating = useMemo(() => {
        if (loaded) {
            const percentage =
                100 - (value.overdueProperties / value.totalProperties) * 100;

            if (!isNaN(percentage)) {
                return percentage.toFixed(2);
            }
        }
    }, [loaded, value]);

    return (
        <Card
            title={t("Next maintenance date")}
            fullHeight={true}
            testId="NextMaintenanceDate_Widget"
            actions={
                <ChartActions
                    phases={maintenancePhases}
                    savePhaseLimits={onSave}
                    showGraph={showGraph}
                    toggleGraphOverview={toggleGraphOverview}
                    phaseTimeframe="Days Until Deadline"
                />
            }
        >
            {loaded ? (
                <div className={styles.container}>
                    <div className={styles.leftColumn}>
                        <Grid fullHeight={false}>
                            <GridColumn size="half">
                                <div className={styles.overdue}>
                                    <div className={styles.overdueValueLink}>
                                        <Link
                                            url={encodeUrl(
                                                "/management/properties",
                                                {
                                                    nextServiceDueDate: [
                                                        `<${getToday().toISOString()}`,
                                                    ],
                                                },
                                            )}
                                            color="red"
                                            testId="NextMaintenanceTotal_A"
                                        >
                                            {value.overdueProperties}
                                        </Link>
                                    </div>
                                    <div className={styles.overdueLabel}>
                                        {t("Overdue properties")}
                                    </div>
                                </div>
                                {maintenanceRating && (
                                    <>
                                        <div
                                            className={styles.value}
                                            data-test-id="MaintenanceRating_Percentage_Div"
                                        >
                                            {maintenanceRating}%
                                        </div>
                                        <div className={styles.overdueLabel}>
                                            {t("Maintenance rating")}
                                        </div>
                                    </>
                                )}
                            </GridColumn>
                        </Grid>
                    </div>
                    <div className={styles.rightColumn}>
                        {showGraph ? (
                            <PropertyGraph
                                phases={maintenancePhases}
                                maxHeight={200}
                                dateFilterField="nextServiceDueDate"
                                targetUrl="/management/properties"
                                phaseTimeFrameMultiplier={1}
                                areDatesInPast={false}
                            />
                        ) : (
                            <ChartOverview
                                phases={maintenancePhases}
                                dateFilterField="nextServiceDueDate"
                                targetUrl="/management/properties"
                                phaseTimeFrameMultiplier={1}
                                areDatesInPast={false}
                            />
                        )}
                        <div className={styles.chartLabel}>
                            {t("Days until next maintenance")}
                        </div>
                    </div>
                </div>
            ) : (
                <Loading />
            )}
        </Card>
    );
};

export default NextMaintenance;
