import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button, Icon, Popover, RadioButton } from "../../../../components";
import { useToggle } from "../../../../hooks";

const PropertyActiveFilter = ({
    activeStatus,
    onActiveStatusChange,
}: IPropertyActiveFilterProps) => {
    const { t } = useTranslation();

    const { hide, toggle, visible } = useToggle();
    const popoverButton = useRef<HTMLButtonElement>(null);

    const handleChange = (checked: boolean, newValue: string) => {
        if (checked) {
            onActiveStatusChange(newValue);
        }
    };

    return (
        <>
            <Button
                displayAsLink={true}
                variant="primary"
                onClick={toggle}
                setRef={popoverButton}
                cssRules={{ marginLeft: 10, fontSize: 12, fontWeight: "bold" }}
                testId="OccupiedStatus_Toggle_Button"
            >
                <Icon
                    icon="door"
                    size={18}
                    cssRules={{ marginRight: 5 }}
                    ariaHidden={true}
                />
                {activeStatus !== ""
                    ? activeStatus === "true"
                        ? t("Occupied")
                        : t("Void")
                    : t("Both")}
            </Button>

            {visible && (
                <Popover
                    anchorElement={popoverButton}
                    maxWidth={400}
                    hide={hide}
                >
                    <RadioButton
                        value="true"
                        checked={activeStatus === "true"}
                        onChange={handleChange}
                    >
                        {t("Occupied")}
                    </RadioButton>
                    <RadioButton
                        value="false"
                        checked={activeStatus === "false"}
                        onChange={handleChange}
                    >
                        {t("Void")}
                    </RadioButton>
                    <RadioButton
                        value=""
                        checked={activeStatus === ""}
                        onChange={handleChange}
                    >
                        {t("Both")}
                    </RadioButton>
                </Popover>
            )}
        </>
    );
};

interface IPropertyActiveFilterProps {
    activeStatus: string;
    onActiveStatusChange: (status: string) => void;
}

export default PropertyActiveFilter;
