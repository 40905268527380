import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Observable } from "rxjs";
import { ITenant, IUpdateTenant } from ".";
import { UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const useCreateTenant = (
    propertyId: number,
): [(data: IUpdateTenant) => Observable<ITenant>, boolean, string] => {
    const { t } = useTranslation();
    const { activeUserParentsIds } = useContext(UserContext);
    const { send, loading, error } = useApiRequest<ITenant>({
        errorMessage: t("Unexpected error occured. Please try again later."),
    });

    const sendRequest = useXTagApiRequest();

    const createTenant = useCallback(
        (data: IUpdateTenant) =>
            send(
                sendRequest({
                    url: `${config.propertiesApiUrl}/${propertyId}/tenants`,
                    method: "POST",
                    urlParams: getGlobalUrlParameters([], activeUserParentsIds),
                    body: data,
                }),
            ),
        [send, sendRequest, propertyId, activeUserParentsIds],
    );

    return [createTenant, loading, error];
};

export default useCreateTenant;
