import { IPaperwork } from "../../utils/api/jobs";
import PaperworkLogCard from "./PaperworkLogCard";

export interface IPaperworkLogCardProps {
    isLoaded: boolean;
    paperwork: IPaperwork[];
    refreshPaperwork: () => void;
    tenantEmail?: string;
    disableFuelTypeFiltering?: boolean;
    propertyId: number;
    landlordId: number;
    propertyAddress: string;
    jobId?: number;
    propertyLetterStateId?: number;
    fullHeight?: boolean;
}

export default PaperworkLogCard;
