import { IStepProps } from ".";
import { useCssClasses } from "../../../hooks";
import styles from "./Step.module.scss";

const Step = ({
    isActive,
    stepNumber,
    isCompleted,
    title,
    onClick,
}: IStepProps) => {
    const cssClasses = useCssClasses(
        styles.icon,
        isActive ? styles.active : isCompleted ? styles.completed : "",
    );

    return (
        <button className={styles.container} onClick={onClick} type="button">
            <span className={cssClasses}>{stepNumber}</span>
            <br />
            {title}
        </button>
    );
};

export default Step;
