import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card, Link, Loading } from "../../components";
import DownloadCsvModal from "../../components/DownloadCsvModal";
import Table, { ITableColumn } from "../../components/Table";
import config from "../../config";
import useDownloadCsv from "../../hooks/useDownloadCsv";
import { IContractor, useContractors } from "../../utils/api/landlords";
import useQueryString from "../../hooks/useQueryString";

const Contractors = () => {
    const { t } = useTranslation();
    const contractors = useContractors({
        sortProperty: "name",
        sortDirection: "asc",
    });

    const columns = useMemo(() => {
        const col: { [key: string]: ITableColumn<IContractor> } = {
            name: {
                title: t("Name"),
                canBeToggledByUser: false,
            },
            businessRegId: {
                title: t("Business registration id"),
                filterable: false,
            },
            contactName: { title: t("Contact name") },
            telephoneNum: { title: t("Tel") },
            addressString: { title: t("Address"), filterable: false },
            verificationId: {
                title: t("Verification id"),
                filterable: false,
                sortable: false,
            },
            verificationSecret: {
                title: t("Verification secret"),
                filterable: false,
                sortable: false,
            },
        };

        return col;
    }, [t]);
    const { getQueryString } = useQueryString(contractors.search);
    const { downloadUrl, hide, downloading, handleDownloadClick, visible } =
        useDownloadCsv({
            exportDataUrl: `${config.landlordsApiUrl}/contractors/export`,
            filters: contractors.filters,
            search: getQueryString(),
        });

    return (
        <>
            <Card
                title={t("Contractors")}
                actions={
                    <Link url="/management/contractors/add">
                        {t("Add new Contractor")}
                    </Link>
                }
            >
                {contractors.loaded ? (
                    <Table
                        preferences="contractors-table"
                        columns={columns}
                        {...contractors}
                        alternateCsvFunction={handleDownloadClick}
                    />
                ) : (
                    <Loading />
                )}
            </Card>

            {visible && (
                <DownloadCsvModal
                    downloadUrl={downloadUrl}
                    downloading={downloading}
                    hide={hide}
                />
            )}
        </>
    );
};

export default Contractors;
