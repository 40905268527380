import { useAuth0 } from "@auth0/auth0-react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card } from "../../..";
import styles from "./AuthenticationError.module.scss";

const AuthenticationError = ({ errorCode }: IAuthenticationErrorProps) => {
    const { t } = useTranslation();

    const { logout } = useAuth0();

    const handleLogoutClick = useCallback(() => {
        logout({ returnTo: window.location.origin });
    }, [logout]);

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <Card title={t(errorCode)} withMargin={false}>
                    <Button
                        variant="primary"
                        displayBlock={true}
                        onClick={handleLogoutClick}
                    >
                        {t("Sign out")}
                    </Button>
                </Card>
            </div>
        </div>
    );
};

interface IAuthenticationErrorProps {
    errorCode: string;
}

export default AuthenticationError;
