import { useCallback } from "react";
import { ITagHeaderProps } from ".";
import { useCssClasses } from "../../../hooks";
import useClickableNonInteractiveElement from "../../../hooks/useClickableNonInteractiveElement";
import styles from "./TabHeader.module.scss";

const TabHeader = ({ isActive, index, children, onClick }: ITagHeaderProps) => {
    const cssClasses = useCssClasses(
        styles.tabHeader,
        isActive ? styles.active : "",
    );

    const handleClick = useCallback(() => onClick(index), [index, onClick]);

    const { onKeyDown } = useClickableNonInteractiveElement(handleClick);

    return (
        <div
            className={cssClasses}
            onClick={handleClick}
            onKeyDown={onKeyDown}
            role="button"
            tabIndex={0}
        >
            {children}
        </div>
    );
};

export default TabHeader;
