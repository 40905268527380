import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { forkJoin, merge, Observable, of, switchMap, tap } from "rxjs";
import { ISectionEditButtonsProps } from ".";
import { Button, Icon, Loading } from "../../../components";
import { IInspectionSchedulePayload } from "../../../utils/api/answers";
import {
    IAnswerOverride,
    IAuditJobComment,
    IAuditJobCommentCreate,
    IAuditObservation,
    IAuditObservationCreate,
    useCreateJobComment,
    useCreateObservation,
    useDeleteJobComment,
    useDeleteObservation,
    useDeleteOverriddenAnswers,
    useOverrideAnswers,
} from "../../../utils/api/audits";
import { clearCache } from "../../../utils/cache";
import { ObservationTypesContext } from "../ObservationTypes";

const SectionEditButtons = ({
    auditId,
    allQuestionsAreValid,
    isEditing,
    questions,
    initialOverriddenAnswers,
    overriddenAnswers,
    hiddenAnswer,
    updateOverriddenAnswers,
    disableEditMode,
    enableEditMode,
    onCancelClick,
    addAuditJobComment,
    addAuditObservation,
    removeAuditJobComment,
    removeAuditObservation,
}: ISectionEditButtonsProps) => {
    const { t } = useTranslation();
    const { observationTypes } = useContext(ObservationTypesContext);

    const { overrideAnswers, loading: loadingOverrideAnswers } =
        useOverrideAnswers(auditId);
    const { deleteOverriddenAnswers, loading: loadingDeleteOverriddenAnswers } =
        useDeleteOverriddenAnswers(auditId);

    const {
        createObservation: createObservationRequest,
        loading: loadingCreateObservation,
    } = useCreateObservation(auditId);
    const createObservation = useCallback(
        (observation: IAuditObservationCreate): Observable<IAuditObservation> =>
            createObservationRequest(observation).pipe(
                tap((o) => addAuditObservation(o)),
            ),
        [addAuditObservation, createObservationRequest],
    );

    const {
        deleteObservation: deleteObservationRequest,
        loading: loadingDeleteObservation,
    } = useDeleteObservation(auditId);
    const deleteObservation = useCallback(
        (observationId: string): Observable<IAuditObservation> =>
            deleteObservationRequest(observationId).pipe(
                tap(() => removeAuditObservation(observationId)),
            ),
        [deleteObservationRequest, removeAuditObservation],
    );

    const {
        createJobComment: createJobCommentRequest,
        loading: loadingCreateJobComment,
    } = useCreateJobComment(auditId);
    const createJobComment = useCallback(
        (jobComment: IAuditJobCommentCreate): Observable<IAuditJobComment> =>
            createJobCommentRequest(jobComment).pipe(
                tap((c) => addAuditJobComment(c)),
            ),
        [addAuditJobComment, createJobCommentRequest],
    );

    const {
        deleteJobComment: deleteJobCommentRequest,
        loading: loadingDeleteJobComment,
    } = useDeleteJobComment(auditId);
    const deleteJobComment = useCallback(
        (jobCommentId: string): Observable<IAuditJobComment> =>
            deleteJobCommentRequest(jobCommentId).pipe(
                tap(() => removeAuditJobComment(jobCommentId)),
            ),
        [deleteJobCommentRequest, removeAuditJobComment],
    );

    const handleSaveClick = useCallback(() => {
        const payloadOverrideDictionary: Record<
            string,
            | {
                  payload: string | IInspectionSchedulePayload;
                  comments: string | null;
                  isDeleted: boolean;
              }
            | undefined
        > = {};
        const deletePayloadOverrides: number[] = [];

        const createEntitiesObservables: Observable<unknown>[] = [];
        const deleteEntitiesObservables: Observable<unknown>[] = [];

        const getInspectionSchedule = (
            payload: string | IInspectionSchedulePayload | undefined,
        ) => {
            let inspectionSchedule: IInspectionSchedulePayload | undefined;

            if (typeof payload === "string") {
                inspectionSchedule = JSON.parse(payload);
            } else {
                inspectionSchedule = payload;
            }

            return inspectionSchedule;
        };

        const deleteExistingInspectionSchedule = (answerId: number) => {
            const inspectionSchedule = getInspectionSchedule(
                initialOverriddenAnswers[answerId.toString()]?.answer,
            );

            if (inspectionSchedule?.Observation?.ObservationId) {
                deleteEntitiesObservables.push(
                    deleteObservation(
                        inspectionSchedule.Observation.ObservationId,
                    ),
                );
            }

            if (inspectionSchedule?.Comment?.CommentId) {
                deleteEntitiesObservables.push(
                    deleteJobComment(inspectionSchedule.Comment.CommentId),
                );
            }
        };

        let hiddenObservationCode: string | undefined;
        let hiddenObservationDescription: string | undefined;
        let hiddenCommentNotes: string | undefined;

        const answers = questions.flatMap((q) => q.children);

        for (const answer of answers) {
            if (
                answer.answer.id !== hiddenAnswer?.id &&
                Object.prototype.hasOwnProperty.call(
                    overriddenAnswers,
                    answer.answer.id.toString(),
                )
            ) {
                const payload = overriddenAnswers[answer.answer.id];

                if (payload === undefined) {
                    deletePayloadOverrides.push(answer.answer.id);
                    deletePayloadOverrides.push(...answer.relatedAnswerIds);
                    deleteExistingInspectionSchedule(answer.answer.id);
                } else {
                    payloadOverrideDictionary[answer.answer.id.toString()] = {
                        payload: payload.answer,
                        comments: payload.comment,
                        isDeleted: false,
                    };

                    for (const answerId of answer.relatedAnswerIds) {
                        payloadOverrideDictionary[answerId.toString()] = {
                            payload: "",
                            comments: null,
                            isDeleted: true,
                        };
                    }

                    if (
                        answer.answer.taskType.taskType ===
                            "electric_add_observation" &&
                        typeof payload.answer === "string"
                    ) {
                        switch (answer.answer.questionKey) {
                            case "ObservationCode": {
                                hiddenObservationCode = payload.answer.replace(
                                    /^"(.+)"$/,
                                    "$1",
                                );
                                break;
                            }
                            case "ObservationDescription": {
                                hiddenObservationDescription =
                                    payload.answer.replace(/^"(.+)"$/, "$1");
                                break;
                            }
                        }
                    } else if (
                        answer.answer.taskType.taskType === "add_comment" &&
                        typeof payload.answer === "string"
                    ) {
                        if (answer.answer.questionKey === "Notes") {
                            hiddenCommentNotes = payload.answer.replace(
                                /^"(.+)"$/,
                                "$1",
                            );
                        }
                    }

                    if (
                        (answer.answer.type === "InspectionSchedule" ||
                            answer.answer.type === "ToggleButtonGroup") &&
                        !(
                            answer.answer.payload.Observation ||
                            answer.answer.payload.Comment
                        )
                    ) {
                        deleteExistingInspectionSchedule(answer.answer.id);

                        const inspectionSchedule = getInspectionSchedule(
                            payload.answer,
                        );

                        if (inspectionSchedule) {
                            if (inspectionSchedule.Observation) {
                                const observationCode =
                                    inspectionSchedule.Observation.Code.toLowerCase();
                                createEntitiesObservables.push(
                                    createObservation({
                                        observationTypeId:
                                            observationTypes.find(
                                                (o) =>
                                                    o.name === observationCode,
                                            )?.id ?? 0,
                                        description:
                                            inspectionSchedule.Observation
                                                .Description,
                                    }).pipe(
                                        tap((o) => {
                                            if (
                                                inspectionSchedule.Observation
                                            ) {
                                                payloadOverrideDictionary[
                                                    answer.answer.id.toString()
                                                ] = {
                                                    payload: {
                                                        Text: inspectionSchedule.Text,
                                                        Observation: {
                                                            Code: inspectionSchedule
                                                                .Observation
                                                                .Code,
                                                            Description:
                                                                inspectionSchedule
                                                                    .Observation
                                                                    .Description,
                                                            ObservationId: o.id,
                                                        },
                                                    },
                                                    comments: payload.comment,
                                                    isDeleted: false,
                                                };
                                            }
                                        }),
                                    ),
                                );
                            } else if (inspectionSchedule.Comment) {
                                createEntitiesObservables.push(
                                    createJobComment({
                                        notes: inspectionSchedule.Comment.Notes,
                                    }).pipe(
                                        tap((c) => {
                                            if (inspectionSchedule.Comment) {
                                                payloadOverrideDictionary[
                                                    answer.answer.id.toString()
                                                ] = {
                                                    payload: {
                                                        Text: inspectionSchedule.Text,
                                                        Comment: {
                                                            Notes: inspectionSchedule
                                                                .Comment.Notes,
                                                            CommentId: c.id,
                                                        },
                                                    },
                                                    comments: payload.comment,
                                                    isDeleted: false,
                                                };
                                            }
                                        }),
                                    ),
                                );
                            }
                        }
                    }
                }
            }
        }

        if (hiddenAnswer) {
            if (hiddenAnswer.payload.Observation) {
                if (
                    hiddenAnswer.payload.Observation.Code !==
                        hiddenObservationCode ||
                    hiddenAnswer.payload.Observation.Description !==
                        hiddenObservationDescription
                ) {
                    payloadOverrideDictionary[hiddenAnswer.id.toString()] = {
                        payload: {
                            Text: hiddenAnswer.payload.Text,
                            Observation: {
                                ObservationId:
                                    hiddenAnswer.payload.Observation
                                        .ObservationId,
                                Code:
                                    hiddenObservationCode ??
                                    hiddenAnswer.payload.Observation.Code,
                                Description:
                                    hiddenObservationDescription ??
                                    hiddenAnswer.payload.Observation
                                        .Description,
                            },
                        },
                        comments: null,
                        isDeleted: false,
                    };
                }
            } else if (hiddenAnswer.payload.Comment) {
                if (hiddenAnswer.payload.Comment.Notes !== hiddenCommentNotes) {
                    payloadOverrideDictionary[hiddenAnswer.id.toString()] = {
                        payload: {
                            Text: hiddenAnswer.payload.Text,
                            Comment: {
                                CommentId:
                                    hiddenAnswer.payload.Comment.CommentId,
                                Notes:
                                    hiddenCommentNotes ??
                                    hiddenAnswer.payload.Comment.Notes,
                            },
                        },
                        comments: null,
                        isDeleted: false,
                    };
                }
            }
        }

        const getPayloadOverridesList = () =>
            Object.keys(payloadOverrideDictionary).map(
                (answerId): IAnswerOverride => {
                    let payload = "";

                    const override = payloadOverrideDictionary[answerId];
                    if (typeof override?.payload === "object") {
                        payload = JSON.stringify(override.payload);
                    } else {
                        payload = override?.payload ?? "";
                    }

                    return {
                        answerId: Number(answerId),
                        payload,
                        isDeleted: override?.isDeleted ?? false,
                        comments: override?.comments ?? null,
                    };
                },
            );

        let observable: Observable<unknown> = of(null);

        // Create related entities.
        if (createEntitiesObservables.length > 0) {
            observable = observable.pipe(
                switchMap(() => forkJoin(createEntitiesObservables)),
            );
        }

        // Update answer payload.
        observable = observable.pipe(
            switchMap(() => {
                const list: Observable<unknown>[] = [];

                const payloadOverrides = getPayloadOverridesList();
                if (payloadOverrides.length > 0) {
                    list.push(overrideAnswers(payloadOverrides));
                }
                if (deletePayloadOverrides.length > 0) {
                    list.push(deleteOverriddenAnswers(deletePayloadOverrides));
                }

                return list.length ? merge(...list) : of(null);
            }),
        );

        // Delete related entities.
        if (deleteEntitiesObservables.length > 0) {
            observable = observable.pipe(
                switchMap(() => merge(...deleteEntitiesObservables)),
            );
        }

        observable.subscribe(() => {
            const payloadOverrides = getPayloadOverridesList();

            updateOverriddenAnswers(payloadOverrides, deletePayloadOverrides);
            disableEditMode();
            clearCache();
        });
    }, [
        questions,
        hiddenAnswer,
        initialOverriddenAnswers,
        deleteObservation,
        deleteJobComment,
        overriddenAnswers,
        createObservation,
        observationTypes,
        createJobComment,
        overrideAnswers,
        deleteOverriddenAnswers,
        updateOverriddenAnswers,
        disableEditMode,
    ]);

    return (
        <>
            {loadingOverrideAnswers ||
            loadingDeleteOverriddenAnswers ||
            loadingCreateObservation ||
            loadingDeleteObservation ||
            loadingCreateJobComment ||
            loadingDeleteJobComment ? (
                <Loading small={true} />
            ) : !isEditing ? (
                <Button size="small" onClick={enableEditMode}>
                    <Icon icon="pencil" ariaLabel={t("Edit")} />
                </Button>
            ) : (
                <>
                    <Button
                        size="small"
                        onClick={handleSaveClick}
                        disabled={!allQuestionsAreValid}
                    >
                        <Icon icon="check" ariaLabel={t("Save")} size={20} />
                    </Button>
                    <Button size="small" onClick={onCancelClick}>
                        <Icon icon="close" ariaLabel={t("Cancel")} size={20} />
                    </Button>
                </>
            )}
        </>
    );
};

export default SectionEditButtons;
