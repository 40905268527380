import { IAllocatedJob } from "../allocatedJobs";
import { IJob } from "../jobs";
import { IPropertyCategory as IPropertyCategoryEventPayload } from "../properties";
import useAssignPropertyFlags from "./useAssignPropertyFlags";
import useComplianceDashboard from "./useComplianceDashboard";
import useCreateFlag from "./useCreateFlag";
import useDeleteFlag from "./useDeleteFlag";
import { useEventLogs } from "./useEventLogs";
import useFileUrl from "./useFileUrl";
import useFilteredPaperworkTypes from "./useFilteredPaperworkTypes";
import useFlags from "./useFlags";
import useIntegrationApprovalInboxNumbers from "./useIntegrationApprovalInboxNumbers";
import useIntegrationApprovalUpdate from "./useIntegrationApprovalUpdate";
import useIntegrationFailures from "./useIntegrationFailures";
import useIntegrationFailuresUpdate from "./useIntegrationFailuresUpdate";
import useKPIDates from "./useKPIDates";
import useKPIs from "./useKPIs";
import useManualUploadReasons from "./useManualUploadReasons";
import useMarkAllNotificationsRead from "./useMarkAllNotificationsRead";
import useNotificationCount from "./useNotificationCount";
import useNotifications from "./useNotifications";
import usePaperworkTypes from "./usePaperworkTypes";
import usePropertyCategories from "./usePropertyCategories";
import useRemovePropertyFlags from "./useRemovePropertyFlags";
import useUpdateFlag from "./useUpdateFlag";
import useUploadFile from "./useUploadFile";

export interface IFlag {
    id: number;
    name: string;
    description: string;
    colour: string;
    removeAfterService: boolean;
    locked: boolean;
    landlordId: number | null;
}

export interface IUpdateFlag {
    id: number;
    name: string;
    description: string;
    colour: string;
    removeAfterService: boolean;
    locked: boolean;
}

export interface IPropertyAssignFlags {
    properties: { id: number }[];
    flagIds: number[];
}

export interface IPropertyCategory {
    id: number;
    name: string;
    displayName: string;
    colour: string;
}

// TODO: Delete IFileUrl.
export interface IFileUrl {
    key: string;
    bucket: string;
}

export interface IFileUpload {
    filename: string;
}

export interface IManualUploadReason {
    id: number;
    description: string;
}

export interface IPaperworkType {
    id: number;
    paperworkType: string;
    shortName: string;
    isGasCheckRequired: boolean;
}

export interface IComplianceDashboard {
    totalJobs: ITotalJob[];
    totalSubJobs: ITotalJob[];
    netPromoterScores: INetPromoterScore[];
    averageJobTimes: IAverageJobTime[];
    averageSubJobTimes: IAverageJobTime[];
    applianceConditions: IApplianceCondition[];
    recentWorks: IFuelTypeRecentWork[];
    recentTasks: IRecentTasks[];
    manualUploads: IManualUpload[];
    serviceDateResets: IServiceDateResets[];
    eicrStatus: IEicrStatus[];
}

export interface IEicrStatus {
    data:
        | {
              label: string;
              value: number;
          }[]
        | null;
}

export interface IServiceDateResets {
    phases: IPhases[];
    totalResetProperties: number;
    totalPropertyServiceDatesReset: number;
}

interface IPhases {
    dates: IPhaseDates[];
    title: string;
    colour: string;
    phaseTimeframe: number;
    endDate: string;
}

interface IPhaseDates {
    date: string;
    count: number;
}

export interface IRecentWork {
    repairs: {
        data: {
            date: string;
            count: number;
        }[];
        total: number;
    };
    installs: {
        data: {
            date: string;
            count: number;
        }[];
        total: number;
    };
    services: {
        data: {
            date: string;
            count: number;
        }[];
        total: number;
    };
}

export interface IFuelTypeRecentWork extends IRecentWork {
    fuelType: IFuelType;
}

export interface IRecentTasks {
    data: IRecentTasksData[];
}

export interface IRecentTasksData {
    tasks: {
        date: string;
        count: number;
    }[];
    total: number;
    fuelType: IFuelType;
}

export interface IFuelType {
    id: number;
    name: string;
    displayName: string;
    color: string;
}

export interface IApplianceCondition {
    data: {
        label: string;
        value: number;
        landlordAppliance?: boolean;
    }[];
}

export interface IManualUpload {
    data: {
        label: string;
        value: number;
    }[];
}

export interface IAverageJobTime {
    week: {
        time: number;
        jobCount: number;
    };
    year: {
        time: number;
        jobCount: number;
    };
    month: {
        time: number;
        jobCount: number;
    };
}

export interface INetPromoterScore {
    data: {
        passives: number;
        promoters: number;
        detractors: number;
    };
}

export interface ITotalJob {
    week: {
        periodCounts: number[];
        total: number;
    };
    year: {
        periodCounts: number[];
        total: number;
    };
    month: {
        periodCounts: number[];
        total: number;
    };
}

export interface IIntegrationApprovalInboxNumbers {
    integrationApprovalCount: number;
    integrationDeclinedCount: number;
    integrationFailedCount: number;
}

export interface IRepairProperty {
    property_id: number;
    total: number;
    address_one: string;
    address_two: string;
    city: string;
    county: string;
    postcode: string;
    last_attempt: string;
}

export interface INotification {
    id: number;
    title: string;
    description: string;
    url: string;
    userId: number;
    read: boolean;
    notificationCategoryId: number;
    createdAt: string;
    updatedAt: string;
    notificationCategory: INotificationCategory;
    notificationType: INotificationCollectionType | null;
}

export interface IIntegrationFailures {
    id: number;
    entityId: string;
    integrationId: number;
    queueName: string;
    updatedEntity: string;
    originalEntity: string;
    updated: string;
    original: string;
    type: string;
    method: string;
    createdAt: string;
    updatedAt: string;
}

export interface IIntegrationFailuresUpdate {
    ids: number[];
    actionsTaken: string;
}

export interface IIntegrationJobApproval {
    id: number;
    jobId: number;
    propertyAddress: string;
    jobDate: string;
    jobType: "service" | "repair";
    changeSummary: string;
    engineerName: string;
    jobData: IJob;
    updatedEntity: string;
    originalEntity: string;
    diff: IJobAppliancesDiff;
    createdAt: string;
    updatedAt: string;
}

export interface IIntegrationJobApprovalUpdate {
    ids: number[];
    reason: string;
    approve: boolean;
}

export interface IJobAppliancesDiff {
    added: IJobAppliance[];
    removed: IJobAppliance[];
    modified: IApplaincesDiff[];
    unmodified: IJobAppliance[];
    [key: string]: IJobAppliance[] | IApplaincesDiff[];
}

export interface IApplaincesDiff {
    original?: IJobAppliance;
    current?: IJobAppliance;
}

export interface IJobAppliance {
    make: string;
    model: string;
    type: string;
    room: string;
    gasCouncilNumber: string;
    serialNumber: string;
    nextServiceDate: string;
    [key: string]: string;
}

interface INotificationCategory {
    id: number;
    category: string;
    createdAt: string;
    updatedAt: string;
}

interface INotificationCollectionType {
    id: number;
    notificationTypeDescription: string;
    title: string;
    displayName: string;
    icon: string;
    notificationCategoryId: number;
    createdAt: string;
    updatedAt: string;
}

export enum NotificationCategory {
    Standard = 1,
    Issue = 2,
}

export type IntegrationApprovalStatuses =
    | "Manual Hold"
    | "Reviewed & Declined"
    | "Integration Failure";

interface IUser {
    id: number;
    name: string;
}

interface ILandlord {
    id: number;
    name: string;
}

interface IProperty {
    id: number;
    addressString: string;
    landlord: ILandlord;
}

interface IJobModel {
    id: number;
}

interface IAbstractEventLog {
    id: number;
    user: IUser;
    property: IProperty;
    job: IJobModel | null;
    entity: unknown;
    type: "Created" | "Updated" | "Deleted";
    date: string;
    payload: unknown;
}

export interface IAllocatedJobEventLog extends IAbstractEventLog {
    entity: "AllocatedJob";
    payload: IAllocatedJob;
}

export interface IPropertyCategoryEventLog extends IAbstractEventLog {
    entity: "PropertyCategory";
    payload: IPropertyCategoryEventPayload;
}

export interface IManualUploadsEventLog extends IAbstractEventLog {
    entity: "ManualUpload";
    payload: IJob;
}

export type IEventLog =
    | IAllocatedJobEventLog
    | IPropertyCategoryEventLog
    | IManualUploadsEventLog;

export {
    useAssignPropertyFlags,
    useComplianceDashboard,
    useCreateFlag,
    useDeleteFlag,
    useEventLogs,
    useFileUrl,
    useFilteredPaperworkTypes,
    useFlags,
    useIntegrationApprovalInboxNumbers,
    useIntegrationApprovalUpdate,
    useIntegrationFailures,
    useIntegrationFailuresUpdate,
    useKPIDates,
    useKPIs,
    useManualUploadReasons,
    useMarkAllNotificationsRead,
    useNotificationCount,
    useNotifications,
    usePaperworkTypes,
    usePropertyCategories,
    useRemovePropertyFlags,
    useUpdateFlag,
    useUploadFile,
};
