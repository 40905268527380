import { useTranslation } from "react-i18next";
import { Card, TabGroup, TabGroupTab } from "../../components";
import { useInboxNumbers } from "../../utils/api/lgsrs";
import AuditsTable from "./AuditsTable";

const LgsrAudit = () => {
    const { t } = useTranslation();
    const inboxNumbers = useInboxNumbers();

    return (
        <Card title={t("Document Auditing")} padding={false}>
            <TabGroup>
                <TabGroupTab
                    header={
                        <>
                            {t("Waiting")}&nbsp;
                            {inboxNumbers.loaded &&
                                inboxNumbers.value.waitingCount > 0 &&
                                `(${inboxNumbers.value.waitingCount})`}
                        </>
                    }
                >
                    <AuditsTable
                        status="Approval Required"
                        onAuditCompleted={inboxNumbers.refresh}
                    />
                </TabGroupTab>
                <TabGroupTab header={t("On hold audits")}>
                    <AuditsTable
                        status="Approval Required"
                        inbox={true}
                        onAuditCompleted={inboxNumbers.refresh}
                    />
                </TabGroupTab>

                <TabGroupTab header={t("Failed")}>
                    <AuditsTable
                        status="Rejected"
                        onAuditCompleted={inboxNumbers.refresh}
                    />
                </TabGroupTab>

                <TabGroupTab header={t("Passed")}>
                    <AuditsTable
                        status="Approved"
                        onAuditCompleted={inboxNumbers.refresh}
                    />
                </TabGroupTab>
            </TabGroup>
        </Card>
    );
};

export default LgsrAudit;
