import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IDeleteManualUploadModalProps } from ".";
import { Alert, Button, Loading, Modal, Portal } from "..";
import useCheckDeleteManualUploadPossible from "../../utils/api/jobs/useCheckDeleteManualUploadPossible";
import useDeleteManualUpload from "../../utils/api/jobs/useDeleteManualUpload";
import { toDateString } from "../../utils/dates";
import ModalBody from "../Modal/ModalBody";
import ModalFooter from "../Modal/ModalFooter";

const DeleteManualUploadModal = ({
    hide,
    jobId,
}: IDeleteManualUploadModalProps) => {
    const { t } = useTranslation();
    const { canDeleteManualUpload, isCheckInProgress } =
        useCheckDeleteManualUploadPossible(jobId);

    const { deleteManualUpload, isDeleteInProgress } = useDeleteManualUpload();
    const [isDeleteCompleted, setIsDeleteCompleted] = useState(false);

    const handleDeleteButton = useCallback(() => {
        deleteManualUpload(jobId).subscribe(() => {
            setIsDeleteCompleted(true);
        });
    }, [deleteManualUpload, jobId]);

    const confirmationMessage = useMemo(() => {
        if (canDeleteManualUpload !== null) {
            switch (canDeleteManualUpload.serviceDateChange) {
                case "CanRevert":
                    return `${t(
                        "Deleting this manual upload will reset the Next Service Date to ",
                    )} ${toDateString(
                        new Date(canDeleteManualUpload.newNextServiceDate),
                    )} ${t(". Are you sure you want to continue?")}`;
                case "NoChange":
                    return t(
                        "Deleting this manual upload will have no effect on the Next Service Date. Are you sure you want to continue?",
                    );
                case "CannotRevert":
                    return t(
                        "This manual upload cannot be deleted. A job was added after this manual upload was created, affecting the Next Service Date. Please contact support for assistance.",
                    );
            }
        }
    }, [canDeleteManualUpload, t]);

    return (
        <Portal>
            <Modal title={t("Delete Manual Upload")} hide={hide}>
                <ModalBody>
                    <p>{confirmationMessage}</p>

                    {(isCheckInProgress || isDeleteInProgress) && <Loading />}

                    {isDeleteCompleted && (
                        <Alert type="success">
                            {t("Job deleted successfully!")}
                        </Alert>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button
                        variant="error"
                        onClick={handleDeleteButton}
                        disabled={
                            canDeleteManualUpload === null ||
                            canDeleteManualUpload.serviceDateChange ===
                                "CannotRevert" ||
                            isDeleteCompleted
                        }
                    >
                        {t("Delete")}
                    </Button>
                </ModalFooter>
            </Modal>
        </Portal>
    );
};

export default DeleteManualUploadModal;
