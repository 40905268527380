import { useCallback, useContext } from "react";
import { NotificationCategory } from ".";
import { PropertyCategoriesContext, UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const useMarkAllNotificationsRead = (
    notificationCategory: NotificationCategory,
) => {
    const { send } = useApiRequest({});
    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);
    const sendRequest = useXTagApiRequest();

    const markRead = useCallback(
        () =>
            send(
                sendRequest({
                    url: `${config.miscApiUrl}/notifications/read`,
                    method: "POST",
                    urlParams: {
                        category: [
                            notificationCategory === NotificationCategory.Issue
                                ? "issue"
                                : "standard",
                        ],
                        ...getGlobalUrlParameters(
                            selectedCategories.map((c) => c.id),
                            activeUserParentsIds,
                        ),
                    },
                }),
            ),
        [
            activeUserParentsIds,
            notificationCategory,
            selectedCategories,
            send,
            sendRequest,
        ],
    );

    return { markRead };
};

export default useMarkAllNotificationsRead;
