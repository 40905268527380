import { useMemo } from "react";
import { ISimpleJob } from ".";
import config from "../../../config";
import { addDays } from "../../dates";
import useFilterable from "../useFilterable";

const useJobLog = (
    fromDate: Date,
    toDate: Date,
    onRecordsLoaded: (
        values: ISimpleJob[],
        currentPage: number,
        totalPages: number,
    ) => void,
) => {
    const startDate = useMemo(() => fromDate.toISOString(), [fromDate]);
    const endDate = useMemo(() => addDays(toDate, 1).toISOString(), [toDate]);

    const filterable = useFilterable<ISimpleJob>(
        config.jobsApiUrl,
        {
            params: {
                sortProperty: "date",
                sortDirection: "desc",
                filters: {
                    date: {
                        operator: "{AND}",
                        filters: [
                            { function: ">=", value: startDate },
                            { function: "<", value: endDate },
                        ],
                    },
                    parentJobId: {
                        operator: "{AND}",
                        readOnly: true,
                        filters: [{ function: "=", value: "null" }],
                    },
                },
            },
        },
        onRecordsLoaded,
    );

    return filterable;
};

export default useJobLog;
