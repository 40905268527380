import { ReactNode } from "react";
import Table from "./Table";

export interface ITableProps {
    children: ReactNode;
    highlightColumn?: boolean;
    withMargin?: boolean;
}

export default Table;
