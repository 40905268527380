import { useTranslation } from "react-i18next";
import { useCssClasses } from "../../hooks";
import styles from "./JobType.module.scss";

const JobType = ({ jobType, margin = true }: IJobTypeProps) => {
    const { t } = useTranslation();

    const cssClasses = useCssClasses(
        styles.container,
        margin ? styles.margin : "",
        jobType === "Repair"
            ? styles.repair
            : jobType === "Service"
            ? styles.service
            : jobType === "Install"
            ? styles.install
            : jobType === "Other"
            ? styles.other
            : "",
    );

    if (jobType) {
        return <div className={cssClasses}>{t(jobType)}</div>;
    }

    return null;
};

interface IJobTypeProps {
    jobType: string;
    margin?: boolean;
}

export default JobType;
