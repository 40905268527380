import { IComplianceDashboard } from "../../../utils/api/misc";
import ComplianceGasDashboard from "./ComplianceGasDashboard";

export interface IComplianceGasDashboardProps {
    rowHeight: number;
    dashboard: IComplianceDashboard;
    loaded: boolean;
    mapFeature: boolean;
}

export default ComplianceGasDashboard;
