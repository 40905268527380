import { useMemo, useState } from "react";
import useSingleSelectedPropertyCategory from "../../hooks/useSingleSelectedPropertyCategory";
import { useComplianceDashboard } from "../../utils/api/misc";
import ComplianceDefaultDashboard from "./ComplianceDefaultDashboard";
import ComplianceElectricDashboard from "./ComplianceElectricDashboard";
import ComplianceFireDoorDashboard from "./ComplianceFireDoorDashboard";
import ComplianceGasDashboard from "./ComplianceGasDashboard";
import CompliancePropertyServicesDashboard from "./CompliancePropertyServicesDashboard";

const ComplianceDashboard = () => {
    const { value, loaded } = useComplianceDashboard();
    const [rowHeight] = useState(360);

    const category = useSingleSelectedPropertyCategory();

    // TODO: Create an env variable and get this feature flag from there XT-1142.
    const mapFeature = true;

    const layout = useMemo(() => {
        if (category) {
            switch (category.id) {
                case 1:
                    return "Gas";
                case 13:
                    return "FireDoor";
                case 14:
                    return "PropertyServices";
                case 20:
                    return "Electric";
            }
        }

        return "Default";
    }, [category]);

    return (
        <>
            {layout === "Gas" && (
                <ComplianceGasDashboard
                    loaded={loaded}
                    dashboard={value}
                    rowHeight={rowHeight}
                    mapFeature={mapFeature}
                />
            )}

            {layout === "FireDoor" && (
                <ComplianceFireDoorDashboard
                    loaded={loaded}
                    dashboard={value}
                    rowHeight={rowHeight}
                    mapFeature={mapFeature}
                />
            )}

            {layout === "PropertyServices" && (
                <CompliancePropertyServicesDashboard
                    loaded={loaded}
                    dashboard={value}
                    rowHeight={rowHeight}
                    mapFeature={mapFeature}
                />
            )}

            {layout === "Electric" && (
                <ComplianceElectricDashboard
                    loaded={loaded}
                    dashboard={value}
                    rowHeight={rowHeight}
                    mapFeature={mapFeature}
                />
            )}

            {layout === "Default" && (
                <ComplianceDefaultDashboard
                    loaded={loaded}
                    dashboard={value}
                    rowHeight={rowHeight}
                    mapFeature={mapFeature}
                />
            )}
        </>
    );
};

export default ComplianceDashboard;
