import { IValidationResult } from ".";

const isEmail = (error = "Please type a valid email address.") => {
    const validate = (value: string): IValidationResult => {
        const isValid =
            !value ||
            /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(value);

        return {
            isValid,
            error: isValid ? "" : error,
        };
    };

    return { validate };
};

export default isEmail;
