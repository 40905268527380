import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { FlagItem } from "../..";
import { useToggle } from "../../../hooks";
import { colours } from "../../../styles/colours";
import { IFlag } from "../../../utils/api/misc";
import Button from "../../Button";
import { Grid, GridColumn } from "../../Grid";
import EditFlagForm from "../EditFlagForm";
import styles from "./PropertyFlagEdit.module.scss";

const PropertyFlagEdit = ({
    flags,
    onFlagsCreate,
    onFlagUpdate,
    onFlagDelete,
    landlordId,
}: IPropertyFlagEditProps) => {
    const { t } = useTranslation();
    const { show, visible } = useToggle();

    const [selectedFlag, setSelectedFlag] = useState<IFlag | null>(null);

    const handleEditFlagClick = useCallback(
        (flag: IFlag) => {
            setSelectedFlag(flag);
            show();
        },
        [show],
    );

    const handleFlagsCreate = (createdFlags: IFlag[]) => {
        setSelectedFlag(null);
        onFlagsCreate(createdFlags);
    };

    const handleFlagUpdate = (flag: IFlag) => {
        setSelectedFlag(null);
        onFlagUpdate(flag);
    };

    const handleFlagDelete = (flag: IFlag) => {
        setSelectedFlag(null);
        onFlagDelete(flag);
    };

    const handleAddFlagClick = () => {
        setSelectedFlag({
            id: 0,
            name: "",
            description: "",
            colour: colours.colourPicker[0],
            removeAfterService: false,
            locked: false,
            landlordId: null,
        });

        show();
    };

    return (
        <Grid>
            <GridColumn size="half">
                {flags.map((flag) => (
                    <FlagItem
                        key={flag.id}
                        flag={flag}
                        onClick={handleEditFlagClick}
                    />
                ))}

                <div className={styles.addOption}>
                    <Button onClick={handleAddFlagClick}>
                        {t("Add Flag")}
                    </Button>
                </div>
            </GridColumn>

            {visible && selectedFlag && (
                <GridColumn size="half" fullHeight={true}>
                    <div className={styles.rightContainer}>
                        <EditFlagForm
                            flag={selectedFlag}
                            onFlagsCreate={handleFlagsCreate}
                            onFlagUpdate={handleFlagUpdate}
                            onFlagDelete={handleFlagDelete}
                            landlordId={landlordId}
                        />
                    </div>
                </GridColumn>
            )}
        </Grid>
    );
};

interface IPropertyFlagEditProps {
    flags: IFlag[];
    onFlagsCreate: (flags: IFlag[]) => void;
    onFlagUpdate: (flag: IFlag) => void;
    onFlagDelete: (flag: IFlag) => void;
    onClose: () => void;
    landlordId: number;
}

export default PropertyFlagEdit;
