import { useMemo } from "react";
import { PermissionType, useHasUserParentPermissionFunction } from ".";

/**
 * Check if current user has required permission for a specific userParent
 */
const useHasUserParentPermission = (
    permission: PermissionType,
    userParentId: number | null | undefined,
) => {
    const hasRequiredPermission = useHasUserParentPermissionFunction();

    const hasPermission = useMemo(
        () => hasRequiredPermission(permission, userParentId),
        [hasRequiredPermission, permission, userParentId],
    );

    return hasPermission;
};

export default useHasUserParentPermission;
