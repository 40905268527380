import EditableList from "./EditableList";
import { IEditableListItem } from "./EditableListItem";

export interface IEditableListProps<T> extends IEditableListItem<T> {
    emptyMessage: string;
    deleteModalTitle: string;
    deleteModalMessage: string;
    entities: T[];
    onCreate: (entity: T) => void;
    onUpdate: (entity: T) => void;
    onDelete: (entity: T) => void;
    canEdit?: boolean;
}

export default EditableList;
