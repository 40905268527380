import { groupBy, map } from "lodash";
import { Fragment, useMemo } from "react";
import { IAllocatedJob } from "../../../utils/api/allocatedJobs";
import { toDateString } from "../../../utils/dates";
import AllocatedJob from "../AllocatedJob";
import styles from "./AllocatedJobsGrouped.module.scss";

const AllocatedJobsGrouped = ({
    jobs,
    onClick,
}: IAllocatedJobsGroupedProps) => {
    const groups = useMemo(
        () => groupBy(jobs, (job) => toDateString(new Date(job.jobDate))),
        [jobs],
    );

    return (
        <>
            {map(groups, (group, date) => (
                <Fragment key={date}>
                    <div className={styles.groupHeader}>{date}</div>

                    {group.map((job) => (
                        <AllocatedJob
                            key={job.id}
                            allocatedJob={job}
                            onClick={onClick}
                        />
                    ))}
                </Fragment>
            ))}
        </>
    );
};

interface IAllocatedJobsGroupedProps {
    jobs: IAllocatedJob[];
    onClick: (job: IAllocatedJob) => void;
}

export default AllocatedJobsGrouped;
