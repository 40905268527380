import { IPropertyCategory } from "../../../utils/api/properties";
import ComplianceTypesCard from "./ComplianceTypesCard";

export interface IComplianceTypesCardProps {
    propertyId: number;
    userParentId: number | null;
    landlordId: number;
    categories: IPropertyCategory[];
    onCreate: (category: IPropertyCategory) => void;
    onUpdate: (category: IPropertyCategory) => void;
    onDelete: (category: IPropertyCategory) => void;
}

export default ComplianceTypesCard;
