import { useCallback, useContext } from "react";
import { IIntegrationFailuresUpdate } from ".";
import { PropertyCategoriesContext, UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const useIntegrationFailuresUpdate = () => {
    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);
    const { send } = useApiRequest<string>({});
    const sendRequest = useXTagApiRequest();

    const update = useCallback(
        (model: IIntegrationFailuresUpdate) =>
            send(
                sendRequest({
                    url: `${config.miscApiUrl}/integration/failures`,
                    method: "POST",
                    urlParams: getGlobalUrlParameters(
                        selectedCategories.map((c) => c.id),
                        activeUserParentsIds,
                    ),
                    body: model,
                }),
            ),
        [activeUserParentsIds, selectedCategories, send, sendRequest],
    );

    return { update };
};

export default useIntegrationFailuresUpdate;
