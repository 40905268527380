import { CSSProperties, useMemo } from "react";
import { Link } from "../../../components";
import AuthorizedImage from "../../../components/AuthorizedImage";
import { ISimpleProperty } from "../../../utils/api/properties";
import styles from "./PropertyListItem.module.scss";

const PropertyListItem = ({ property }: IPropertyListItemProps) => {
    const cssRules = useMemo(() => {
        if (!property.occupied) {
            const css: CSSProperties = {
                filter: "grayscale(100%)",
            };

            return css;
        }
    }, [property.occupied]);

    return (
        <div className={styles.container}>
            <div className={styles.imageContainer}>
                <AuthorizedImage
                    image={property.imageUrl}
                    size={92}
                    cssRules={cssRules}
                />
            </div>

            <div className={styles.linkContainer}>
                <Link url={`/management/properties/${property.id}`}>
                    {property.addressString}
                </Link>
            </div>
        </div>
    );
};

interface IPropertyListItemProps {
    property: ISimpleProperty;
}

export default PropertyListItem;
