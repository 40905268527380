import { IContractor } from ".";
import config from "../../../config";
import useFilterable, { IFilterableParams } from "../useFilterable";

const useContractors = (params?: IFilterableParams) => {
    const filterable = useFilterable<IContractor>(
        `${config.landlordsApiUrl}/contractors`,
        {
            params,
        },
    );

    return filterable;
};

export default useContractors;
