import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { INoAccessCountProps } from ".";
import { Grid, GridColumn, Link, Loading } from "../../../components";
import { useCssClasses } from "../../../hooks";
import useNoAccessCounts from "../../../utils/api/jobs/useNoAccessCounts";
import { encodeUrl } from "../../../utils/url";
import styles from "./NoAccessCount.module.scss";

const NoAccessCount = ({ type, children }: INoAccessCountProps) => {
    const { t } = useTranslation();

    const { value, loaded } = useNoAccessCounts();

    const url = useMemo(
        () =>
            type === "property"
                ? "/management/properties"
                : "/management/appliances",
        [type],
    );

    const counts = useMemo(() => {
        if (value) {
            return type === "property" ? value.properties : value.assets;
        }
    }, [type, value]);

    const otherAttempts = useMemo(() => {
        let other = 0;

        if (counts) {
            for (const key of Object.keys(counts.noAccessCounts)) {
                const keyNumber = Number(key);

                if (keyNumber > 3) {
                    other += counts.noAccessCounts[keyNumber];
                }
            }
        }

        return other;
    }, [counts]);

    const accessRate = useMemo(() => (counts ? counts.accessRate : 0), [
        counts,
    ]);

    const cssClasses = useCssClasses(
        styles.percentage,
        accessRate < 30 ? styles.small : "",
    );

    return counts && loaded ? (
        <div className={styles.centerContainer}>
            <div className={styles.accessRate}>
                <div className={styles.percentageWrapper}>
                    <div
                        className={cssClasses}
                        style={{ width: `${accessRate}%` }}
                    >
                        <span className={styles.text}>
                            {accessRate.toFixed(2)} %
                        </span>
                    </div>
                </div>

                {t("Access rate")}
            </div>

            <Grid fullHeight={true}>
                <GridColumn size="half">
                    <Link
                        url={encodeUrl(url, {
                            currentAttemptsCount: ["=1"],
                        })}
                        testId="NoAccessWidget1stAttemptTotal_Cta"
                    >
                        <div className={styles.center}>
                            <div
                                className={styles.firstAttempt}
                                data-test-id="NoAccessWidget1stAttemptTotal_Label"
                            >
                                {counts.noAccessCounts[1] || 0}
                            </div>
                            <span className={styles.attemptLabel}>
                                {t("1st")} {t("attempt")}
                            </span>
                        </div>
                    </Link>
                </GridColumn>

                <GridColumn size="half">
                    <div className={styles.centerContainer}>
                        <div>
                            <Link
                                url={encodeUrl(url, {
                                    currentAttemptsCount: ["=2"],
                                })}
                                testId="NoAccessWidget2ndAttemptTotal_Cta"
                            >
                                <div className={styles.secondAttempt}>
                                    <span
                                        className={styles.bold}
                                        data-test-id="NoAccessWidget2ndAttemptTotal_Label"
                                    >
                                        {counts.noAccessCounts[2] || 0}
                                        &nbsp;
                                    </span>
                                    <span className={styles.attemptLabel}>
                                        {t("2nd")} {t("attempt")}
                                    </span>
                                </div>
                            </Link>

                            <Link
                                url={encodeUrl(url, {
                                    currentAttemptsCount: ["=3"],
                                })}
                                testId="NoAccessWidget3rdAttemptTotal_Cta"
                            >
                                <div className={styles.thirdAttempt}>
                                    <span
                                        className={styles.bold}
                                        data-test-id="NoAccessWidget3rdAttemptTotal_Label"
                                    >
                                        {counts.noAccessCounts[3] || 0}
                                        &nbsp;
                                    </span>
                                    <span className={styles.attemptLabel}>
                                        {t("3rd")} {t("attempt")}
                                    </span>
                                </div>
                            </Link>

                            <Link
                                url={encodeUrl(url, {
                                    currentAttemptsCount: [">=4"],
                                })}
                                testId="NoAccessWidget4PlusAttemptTotal_Cta"
                            >
                                <div className={styles.fourthAttempt}>
                                    <span
                                        className={styles.bold}
                                        data-test-id="NoAccessWidget4PlusAttemptTotal_Label"
                                    >
                                        {otherAttempts}&nbsp;
                                    </span>
                                    <span className={styles.attemptLabel}>
                                        4+ {t("attempts")}
                                    </span>
                                </div>
                            </Link>
                        </div>
                    </div>
                </GridColumn>
            </Grid>

            {children}
        </div>
    ) : (
        <Loading small={true} smallWidth={100} centerContent={true} />
    );
};

export default NoAccessCount;
