import { useContext, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Badge, Button, Icon, Loading, UserContext } from "../../..";
import { useToggle } from "../../../../hooks";
import UserParentsPopover from "./UserParentsPopover";

const UserParentsDropdown = () => {
    const { t } = useTranslation();
    const { user, loading } = useContext(UserContext);

    const activeUserParents = useMemo(
        () =>
            user.userParents
                .filter((userParent) => userParent.isActive)
                .map((u) => u.landlord?.name ?? ""),
        [user.userParents],
    );

    const userParentsButton = useRef<HTMLButtonElement>(null);
    const { visible, hide, toggle } = useToggle();

    if (loading) {
        return <Loading small={true} smallWidth={34} />;
    }

    if (user.userParents.length > 1) {
        return (
            <>
                <Button
                    displayAsLink={true}
                    setRef={userParentsButton}
                    onClick={toggle}
                    testId="UserParentFilter_Button"
                >
                    <Icon icon="contacts" ariaLabel={t("Filter landlords")} />
                    <Badge
                        color="blue"
                        position="absolute"
                        topOffset={-19}
                        leftOffset={-9}
                    >
                        {activeUserParents.length}
                    </Badge>
                </Button>

                {visible && (
                    <UserParentsPopover
                        anchorElement={userParentsButton}
                        hide={hide}
                    />
                )}
            </>
        );
    }

    return <>{activeUserParents.map((u) => u)}</>;
};

export default UserParentsDropdown;
