import { useCallback, useContext } from "react";
import { UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const useSubscribeToIssue = () => {
    const { activeUserParentsIds } = useContext(UserContext);
    const { send } = useApiRequest({});
    const sendRequest = useXTagApiRequest();

    const subscribeToIssue = useCallback(
        (id: string) =>
            send(
                sendRequest({
                    url: `${config.issuesApiUrl}/${id}/subscribe`,
                    method: "POST",
                    urlParams: getGlobalUrlParameters([], activeUserParentsIds),
                }),
            ),
        [activeUserParentsIds, send, sendRequest],
    );

    return { subscribeToIssue };
};

export default useSubscribeToIssue;
