import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    IAssignContractorsComplianceType,
    IAssignContractorsModalProps,
} from ".";
import {
    Alert,
    Button,
    Form,
    Modal,
    Portal,
    ValidationError,
} from "../../../components";
import ComplianceCategory from "../../../components/ComplianceCategory";
import ModalBody from "../../../components/Modal/ModalBody";
import ModalFooter from "../../../components/Modal/ModalFooter";
import { ISelectOption } from "../../../components/Select";
import useAssignContractors from "../../../utils/api/properties/useAssignContractors";
import {
    isRequired,
    useValidateField,
    validateForm,
} from "../../../utils/validation";
import AddComplianceRow from "./AddComplianceRow";
import EditComplianceRow from "./EditComplianceRow";

const AssignContractorsModal = ({
    propertyIds,
    complianceTypes,
    contractors,
    hide,
}: IAssignContractorsModalProps) => {
    const { t } = useTranslation();
    const [contractorsComplianceTypes, setContractorsComplianceTypes] =
        useState<{
            [key: string]: string;
        }>({});
    const selectedComplianceTypes = useMemo(
        () => Object.keys(contractorsComplianceTypes),
        [contractorsComplianceTypes],
    );

    const setContractorId = useCallback(
        (complianceTypeId: string, contractorId: string) => {
            setContractorsComplianceTypes((c) => ({
                ...c,
                [complianceTypeId]: contractorId,
            }));
        },
        [],
    );
    const setComplianceTypeId = useCallback(
        (oldComplianceTypeId: string, newComplianceTypeId: string) => {
            setContractorsComplianceTypes((c) => {
                const contractorId = c[oldComplianceTypeId];
                delete c[oldComplianceTypeId];

                return { ...c, [newComplianceTypeId]: contractorId };
            });
        },
        [],
    );
    const removeComplianceType = useCallback((complianceTypeId: string) => {
        setContractorsComplianceTypes((c) => {
            delete c[complianceTypeId];

            return { ...c };
        });
    }, []);

    const { assignContractors, loading, error } =
        useAssignContractors(propertyIds);

    const handleAddRow = useCallback(
        (complianceId: string, contractorId: string) => {
            setContractorsComplianceTypes((c) => ({
                ...c,
                [complianceId]: contractorId,
            }));
        },
        [],
    );

    const complianceTypeSelectOption = useCallback(
        (c: IAssignContractorsComplianceType) => ({
            label: (
                <ComplianceCategory
                    colour={c.colour}
                    displayName={c.displayName}
                    displayBlock={true}
                />
            ),
            value: c.id.toString(),
        }),
        [],
    );

    const availableComplianceTypes = useMemo<ISelectOption[]>(
        () =>
            complianceTypes
                .filter(
                    (c) => !selectedComplianceTypes.includes(c.id.toString()),
                )
                .map(complianceTypeSelectOption),
        [complianceTypeSelectOption, complianceTypes, selectedComplianceTypes],
    );

    const allComplianceTypes = useMemo<ISelectOption[]>(
        () => complianceTypes.map(complianceTypeSelectOption),
        [complianceTypeSelectOption, complianceTypes],
    );

    const contractorOptions = useMemo<ISelectOption[]>(
        () =>
            contractors.map((c) => ({
                label: c.name,
                value: c.id.toString(),
            })),
        [contractors],
    );

    const selectedComplianceCategoriesValidator = useValidateField(
        selectedComplianceTypes.length,
        isRequired("Please add a row"),
    );
    const formValidator = validateForm(() => [
        selectedComplianceCategoriesValidator,
    ]);

    const handleSubmit = useCallback(() => {
        assignContractors(
            selectedComplianceTypes.map((complianceType) => ({
                propertyCategoryId: Number(complianceType),
                contractorId: Number(
                    contractorsComplianceTypes[complianceType],
                ),
            })),
        ).subscribe(hide);
    }, [
        contractorsComplianceTypes,
        hide,
        selectedComplianceTypes,
        assignContractors,
    ]);

    return (
        <Portal>
            <Modal hide={hide} title={t("Assign contractors")}>
                <ModalBody>
                    <AddComplianceRow
                        contractors={contractorOptions}
                        complianceTypes={availableComplianceTypes}
                        addRow={handleAddRow}
                    />

                    {selectedComplianceTypes.map((complianceType) => (
                        <EditComplianceRow
                            key={complianceType}
                            complianceTypes={allComplianceTypes}
                            contractors={contractorOptions}
                            complianceTypeId={complianceType}
                            contractorId={
                                contractorsComplianceTypes[complianceType]
                            }
                            setContractorId={setContractorId}
                            setComplianceTypeId={setComplianceTypeId}
                            removeComplianceType={removeComplianceType}
                        />
                    ))}

                    <ValidationError
                        {...selectedComplianceCategoriesValidator}
                    />
                    {error && <Alert type="error">{error}</Alert>}
                </ModalBody>
                <ModalFooter>
                    <Form onSubmit={handleSubmit} {...formValidator}>
                        <Button
                            type="submit"
                            variant="primary"
                            disabled={loading}
                        >
                            {t("Save")}
                        </Button>
                    </Form>
                </ModalFooter>
            </Modal>
        </Portal>
    );
};

export default AssignContractorsModal;
