import { ChangeEvent, useCallback } from "react";
import { IToggleButtonProps } from ".";
import { useCssClasses } from "../../hooks";
import { useHtmlEntityId } from "../../hooks/useHtmlEntityId";
import styles from "./ToggleButton.module.scss";

function ToggleButton<T>({
    children,
    value,
    checked = false,
    readonly = false,
    onChange,
}: IToggleButtonProps<T>) {
    const id = useHtmlEntityId();

    const handleChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>): void => {
            if (!readonly) {
                onChange(event.target.checked, value);
            }
        },
        [readonly, onChange, value],
    );

    const cssClasses = useCssClasses(
        styles.buttonText,
        checked ? styles.checked : "",
    );

    return (
        <>
            <input
                id={id}
                type="checkbox"
                className={styles.toggleButton}
                onChange={handleChange}
                checked={checked}
            />
            <label htmlFor={id} className={cssClasses}>
                {children}
            </label>
        </>
    );
}

export default ToggleButton;
