import ServiceDateApprovalModal from "./ServiceDateApprovalModal";

export interface IServiceDateApprovalModalProps {
    jobIds: number[];
    updateType: "approved" | "decline" | undefined;
    updatedAction: () => void;
    hide: () => void;
}

export default ServiceDateApprovalModal;
