import { IJob } from "..";
import { IAnswer } from "../../../utils/api/answers";
import JobTimelineJob from "./JobTimelineJob";

export interface IJobTimelineJobProps {
    job: IJob;
    isChecked: boolean;
    showTasks?: boolean;
    showTaskIcons?: boolean;
    showAddress?: boolean;
    showJobType?: boolean;
    loadTasksAsync?: boolean;
    onCheckedChange?: (checked: boolean, value: number) => void;
    isCompact?: boolean;
    answers: IAnswer[];
    showJobIcons?: boolean;
    openChildJobsInNewWindow?: boolean;
}

export default JobTimelineJob;
