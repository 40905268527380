import { noop } from "rxjs";
import { DateCellStyle } from ".";
import { useCssClasses } from "../../../hooks";
import styles from "./DateCell.module.scss";

const DateCell = ({
    date,
    currentMonth = false,
    style = "",
    onClick = noop,
}: IDateCellProps) => {
    const cssClasses = useCssClasses(
        styles.button,
        currentMonth ? "" : styles.off,
        style && styles[style],
    );
    const handleClick = () => onClick(date);

    return (
        <td className={styles.date}>
            <button className={cssClasses} onClick={handleClick} type="button">
                {date.getDate()}
            </button>
        </td>
    );
};

interface IDateCellProps {
    date: Date;
    currentMonth?: boolean;
    onClick?: (date: Date) => void;
    style?: DateCellStyle;
}

export default DateCell;
