import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, Grid, GridColumn } from "../../components";
import { useRequestChangePasswordEmail } from "../../utils/api/users";
import UserDetailsSection from "./UserDetailsSection";
import UserProfilePicture from "./UserProfilePicture";
import UserSignature from "./UserSignature";

const UserDetails = () => {
    const { t } = useTranslation();
    const { requestChangePasswordEmail } = useRequestChangePasswordEmail();

    const handleChangePasswordClick = useCallback(() => {
        requestChangePasswordEmail().subscribe();
    }, [requestChangePasswordEmail]);

    return (
        <Grid>
            <GridColumn size="half">
                <UserDetailsSection />

                <UserProfilePicture />
            </GridColumn>

            <GridColumn size="half">
                <UserSignature />

                <Card title={t("Change your password")}>
                    <Button
                        onClick={handleChangePasswordClick}
                        variant="primary"
                    >
                        {t("Send me a password reset email")}
                    </Button>
                </Card>
            </GridColumn>
        </Grid>
    );
};

export default UserDetails;
