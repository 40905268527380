import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button, Checkbox, InputField, Popover } from "../../../components";
import { useFilter, useSelectRecords, useToggle } from "../../../hooks";

const JobFilter = ({ label, values, applyFilter }: IJobFilterProps) => {
    const { t } = useTranslation();
    const { visible, hide, toggle } = useToggle();
    const popoverButton = useRef<HTMLButtonElement>(null);

    const filter = useFilter(values, (status) => [status]);
    const selectRows = useSelectRecords(values, true);

    const handleChange = (checked: boolean, value: string) => {
        if (value === "all") {
            selectRows.handleAllRowsSelect(checked);

            if (checked) {
                applyFilter(values);
            } else {
                applyFilter([]);
            }
        } else {
            const list = selectRows.handleRowSelect(checked, value);
            applyFilter(list);
        }
    };

    return (
        <>
            <Button
                onClick={toggle}
                setRef={popoverButton}
                displayBlock={true}
                cssRules={{ marginBottom: "10px" }}
            >
                {t(label)}
                {selectRows.selectedIds.length > 0 && ":"}&nbsp;
                {selectRows.allRowsChecked
                    ? t("All")
                    : selectRows.selectedIds
                          .map((value) => t(value))
                          .join(", ")}
            </Button>

            {visible && (
                <Popover anchorElement={popoverButton} hide={hide}>
                    <InputField
                        placeholder={t("Filter")}
                        value={filter.search}
                        onChange={filter.handleSearchChange}
                    />

                    <Checkbox
                        value="all"
                        onChange={handleChange}
                        checked={selectRows.allRowsChecked}
                    >
                        {t("All")}
                    </Checkbox>

                    {filter.filteredRecords.map((status) => {
                        const isChecked = selectRows.selectedIds.some(
                            (id) => status === id,
                        );

                        return (
                            <Checkbox
                                key={status}
                                value={status}
                                checked={isChecked}
                                onChange={handleChange}
                            >
                                {t(status)}
                            </Checkbox>
                        );
                    })}
                </Popover>
            )}
        </>
    );
};

interface IJobFilterProps {
    label: string;
    values: string[];
    applyFilter: (values: string[]) => void;
}

export default JobFilter;
