import { useCallback, useContext, useEffect } from "react";
import { IApplianceDueDate } from ".";
import { PropertyCategoriesContext, UserContext } from "../../../components";
import config from "../../../config";
import { clearCache } from "../../cache";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiValue from "../useApiValue";

const useApplianceDueDateDashboard = () => {
    const { send, value, loading } = useApiValue<IApplianceDueDate>({});
    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);
    const sendRequest = useXTagApiRequest();

    const refresh = useCallback(
        (shouldClearCache = true) => {
            if (shouldClearCache) {
                clearCache();
            }

            return send(
                sendRequest({
                    url: `${config.appliancesApiUrl}/dashboard/due-date`,
                    method: "GET",
                    urlParams: getGlobalUrlParameters(
                        selectedCategories.map((c) => c.id),
                        activeUserParentsIds,
                    ),
                }),
            );
        },
        [activeUserParentsIds, selectedCategories, send, sendRequest],
    );

    useEffect(() => {
        const subscription = refresh(false).subscribe();

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [refresh]);

    return { value, loading, refresh };
};

export default useApplianceDueDateDashboard;
