import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
    IOperationalLimitation,
    IQualifiedSupervisorAuditLimitationsProps,
} from ".";
import { IAgreedLimitation } from "../../../utils/api/audits";
import styles from "./QualifiedSupervisorAuditLimitations.module.scss";

const QualifiedSupervisorAuditLimitations = ({
    agreedLimitations,
    operationalLimitations,
    auditLimitations,
    auditStatus,
}: IQualifiedSupervisorAuditLimitationsProps) => {
    const { t } = useTranslation();

    const allAgreedLimitations = useMemo(() => {
        const list: IAgreedLimitation[] = [...agreedLimitations];

        if (!["Passed", "Failed"].includes(auditStatus.name)) {
            list.push(
                ...auditLimitations
                    .map((a) => a.agreedLimitation)
                    .filter((a): a is IAgreedLimitation => a !== null),
            );
        }

        const dictionary = list.reduce<Record<number, IAgreedLimitation>>(
            (dict, limitation) => ({ ...dict, [limitation.id]: limitation }),
            {},
        );
        const uniqueValues = Object.values(dictionary);

        return uniqueValues.sort((a, b) => (a.order > b.order ? 1 : -1));
    }, [agreedLimitations, auditLimitations, auditStatus]);

    const allOperationalLimitations = useMemo(() => {
        const list: IOperationalLimitation[] = [...operationalLimitations];

        if (!["Passed", "Failed"].includes(auditStatus.name)) {
            list.push(
                ...auditLimitations
                    .filter((a) => a.text !== null)
                    .map((a) => ({
                        id: a.id,
                        title: "",
                        description: a.text ?? "",
                    })),
            );
        }

        return list;
    }, [auditLimitations, operationalLimitations, auditStatus]);

    return (
        <>
            <div className={styles.section}>
                <p className={styles.title}>
                    {t("Agreed limitations to be included in this report")}
                </p>

                <ul className={styles.agreedLimitations}>
                    {allAgreedLimitations.length > 0 ? (
                        allAgreedLimitations.map((limitation) => (
                            <li key={limitation.id}>{limitation.text}</li>
                        ))
                    ) : (
                        <li>
                            {t(
                                "There are no limitations to display in this list",
                            )}
                        </li>
                    )}
                </ul>
            </div>

            <div className={styles.section}>
                <p className={styles.title}>
                    {t("Operational limitations included in this report")}
                </p>

                {allOperationalLimitations.length > 0 ? (
                    allOperationalLimitations.map((limitation) => (
                        <div
                            key={limitation.id}
                            className={styles.operationalLimitation}
                        >
                            <div>{limitation.title}</div>
                            <div>{limitation.description}</div>
                        </div>
                    ))
                ) : (
                    <div>
                        {t("There are no limitations to display in this list")}
                    </div>
                )}
            </div>
        </>
    );
};

export default QualifiedSupervisorAuditLimitations;
