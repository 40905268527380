import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { map, of, switchMap } from "rxjs";
import ManualUploadForm, {
    IManualUploadJob,
} from "../../components/ManualUploadForm";
import useCreateDocument from "../../utils/api/documents/useCreateDocument";
import { IJob, useCreateJob } from "../../utils/api/jobs";
import { IFileUpload, useUploadFile } from "../../utils/api/misc";

const AddManualUpload = () => {
    const { t } = useTranslation();

    const {
        createJob,
        loading: createJobLoading,
        error: createJobError,
    } = useCreateJob();
    const {
        createDocument,
        loading: createDocumentLoading,
        error: createDocumentError,
    } = useCreateDocument();
    const {
        uploadFile,
        loading: uploadFileLoading,
        error: uploadFileError,
    } = useUploadFile();

    const handleSubmit = useCallback(
        (job: IManualUploadJob) => {
            return createJob({
                engineerId: job.engineerId,
                propertyId: job.propertyId,
                fuelTypeId: job.fuelTypeId,
                jobType: job.jobType,
                date: job.date,
                isLgsrJob: job.isLgsrJob,
                resetNextServiceDate: job.resetNextServiceDate,
                manualUploadReasonId: job.manualUploadReasonId,
                manualUploadOtherReason: job.manualUploadOtherReason,
                paperworkTypeId: job.paperworkTypeId,
                safeApplianceIds: job.safeApplianceIds,
            }).pipe(
                switchMap((j) => {
                    if (job.file !== null) {
                        const metadata: Record<string, string> = {
                            jobId: j.id.toString(),
                            documentType: job.paperworkType,
                        };

                        if (job.propertyUprn) {
                            metadata["uprn"] = job.propertyUprn;
                        }

                        return uploadFile(
                            `landlord/${job.landlordId}/property/${job.propertyId}/jobs/${job.filename}`,
                            job.file,
                            metadata,
                        ).pipe(
                            map<IFileUpload, [IJob, IFileUpload | null]>(
                                (f) => [j, f],
                            ),
                        );
                    }

                    return of<[IJob, IFileUpload | null]>([j, null]);
                }),
                switchMap(([j, f]) => {
                    if (f === null) {
                        return of(null);
                    } else {
                        return createDocument({
                            paperworkTypeId: job.paperworkTypeId,
                            paperworkName: job.otherPaperworkName,
                            paperworkUrl: f.filename,
                            date: new Date(j.date),
                            jobId: j.id,
                        });
                    }
                }),
            );
        },
        [createDocument, createJob, uploadFile],
    );

    return (
        <ManualUploadForm
            cardTitle={t("Add a manual upload")}
            isLoading={
                createJobLoading || uploadFileLoading || createDocumentLoading
            }
            error={
                t(createJobError) || uploadFileError || t(createDocumentError)
            }
            dateFieldLabel={t("Job date")}
            showCommentsField={false}
            showUnsafeAppliances={true}
            showPaperworkTypes={true}
            successMessage={t("Recovery job was created successfully")}
            onSubmit={handleSubmit}
        />
    );
};

export default AddManualUpload;
