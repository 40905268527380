import { useTranslation } from "react-i18next";
import { ITableActionsProps } from ".";
import { Dropdown, Icon } from "../../../components";
import { useToggle } from "../../../hooks";
import DeleteUserModal from "../DeleteUserModal";
import ResetUserPasswordModal from "../ResetUserPasswordModal";
import UpdateUserModal from "../UpdateUserModal";

const TableActions = ({ user, onComplete }: ITableActionsProps) => {
    const { t } = useTranslation();

    const {
        visible: updateUserModalVisible,
        hide: updateUserModalHide,
        show: updateUserModalShow,
    } = useToggle();
    const {
        visible: deleteUserModalVisible,
        hide: deleteUserModalHide,
        show: deleteUserModalShow,
    } = useToggle();
    const {
        visible: resetPasswordModalVisible,
        hide: resetPasswordModalHide,
        show: resetPasswordModalShow,
    } = useToggle();

    return (
        <>
            <Dropdown
                label={<Icon icon="more" ariaLabel={t("Actions")} />}
                size="small"
                items={[
                    {
                        label: t("Update User"),
                        onClick: updateUserModalShow,
                    },
                    {
                        label: t("Delete User"),
                        onClick: deleteUserModalShow,
                    },
                    {
                        label: t("Reset Password"),
                        onClick: resetPasswordModalShow,
                    },
                ]}
            />
            {updateUserModalVisible && (
                <UpdateUserModal
                    user={user}
                    onUpdateUser={onComplete}
                    hide={updateUserModalHide}
                />
            )}

            {deleteUserModalVisible && (
                <DeleteUserModal
                    userId={user.id}
                    hide={deleteUserModalHide}
                    onDeleteUser={onComplete}
                />
            )}

            {resetPasswordModalVisible && (
                <ResetUserPasswordModal
                    userId={user.id}
                    onResetUserPassword={onComplete}
                    hide={resetPasswordModalHide}
                />
            )}
        </>
    );
};

export default TableActions;
