import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button, DatePicker, Icon, Popover } from "../../../components";
import { useToggle } from "../../../hooks";
import { addDays, toDateString } from "../../../utils/dates";
import styles from "./DateFilter.module.scss";

const DateFilter = ({
    date,
    onDateSelected,
    disabled = false,
}: IDateFilterProps) => {
    const { t } = useTranslation();
    const { visible, hide, toggle } = useToggle();
    const datePickerButton = useRef<HTMLButtonElement>(null);

    const handlePreviousDayClick = () => onDateSelected(addDays(date, -1));
    const handleNextDayClick = () => onDateSelected(addDays(date, 1));
    const handleDateSelect = (newDate: Date) => {
        onDateSelected(newDate);
        hide();
    };

    return (
        <>
            <div className={styles.container}>
                <Button onClick={handlePreviousDayClick} disabled={disabled}>
                    <Icon icon="caret-left" ariaLabel={t("Previous day")} />
                </Button>
                <Button
                    onClick={toggle}
                    setRef={datePickerButton}
                    cssRules={{ flexGrow: 1 }}
                    disabled={disabled}
                >
                    {toDateString(date)}
                </Button>
                <Button onClick={handleNextDayClick} disabled={disabled}>
                    <Icon icon="caret-right" ariaLabel={t("Next day")} />
                </Button>
            </div>

            {visible && (
                <Popover anchorElement={datePickerButton} hide={hide}>
                    <DatePicker
                        openAtDate={date}
                        selectedFromDate={date}
                        selectedToDate={date}
                        onDateSelected={handleDateSelect}
                    />
                </Popover>
            )}
        </>
    );
};

interface IDateFilterProps {
    date: Date;
    onDateSelected: (date: Date) => void;
    disabled?: boolean;
}

export default DateFilter;
