import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IObservation, IQualifiedSupervisorAuditObservationsProps } from ".";
import styles from "./QualifiedSupervisorAuditObservations.module.scss";

const QualifiedSupervisorAuditObservations = ({
    observations,
    auditObservations,
    auditStatus,
}: IQualifiedSupervisorAuditObservationsProps) => {
    const { t } = useTranslation();

    const resolvedObservations = useMemo(
        () => observations.filter((o) => o.isResolved),
        [observations],
    );

    const unresolvedObservations = useMemo(() => {
        const list: IObservation[] = observations
            .filter((o) => !o.isResolved)
            .map((o) => ({
                id: o.id.toString(),
                text: o.title,
                questionText: o.questionAnswer?.questionText,
            }));

        if (!["Passed", "Failed"].includes(auditStatus.name)) {
            list.push(
                ...auditObservations.map((o) => ({
                    id: o.id,
                    text: `${o.type.displayName} - ${o.description}`,
                })),
            );
        }

        return list;
    }, [observations, auditObservations, auditStatus]);

    return (
        <>
            <div className={styles.section}>
                <p className={styles.title}>{t("Unresolved observations")}</p>

                {unresolvedObservations.length > 0
                    ? unresolvedObservations.map((observation) => (
                          <div
                              key={observation.id}
                              className={styles.observation}
                          >
                              <div>{observation.questionText}</div>
                              <div>{observation.text}</div>
                          </div>
                      ))
                    : t("There are no observations to display in this list")}
            </div>

            <div className={styles.section}>
                <p className={styles.title}>{t("Resolved observations")}</p>

                {resolvedObservations.length > 0
                    ? resolvedObservations.map((observation) => (
                          <div
                              key={observation.id}
                              className={styles.observation}
                          >
                              {observation.questionAnswer && (
                                  <div>
                                      {observation.questionAnswer?.questionText}
                                  </div>
                              )}
                              <div>{observation.title}</div>
                          </div>
                      ))
                    : t("There are no observations to display in this list")}
            </div>
        </>
    );
};

export default QualifiedSupervisorAuditObservations;
