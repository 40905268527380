import { INavigationModalProps } from ".";
import { Icon, Link, Modal, Portal } from "..";
import ModalBody from "../Modal/ModalBody";
import styles from "./NavigationModal.module.scss";

const NavigationModal = ({
    title,
    hide,
    message,
    primaryLinks,
    description,
}: INavigationModalProps) => {
    return (
        <Portal>
            <Modal title={title} hide={hide}>
                <ModalBody>
                    {message && (
                        <div className={styles.message}>
                            <div className={styles.circle}>
                                <Icon
                                    icon="info"
                                    color="blue"
                                    ariaHidden={true}
                                />
                            </div>

                            <span className={styles.messagePara}>
                                {message}
                            </span>
                        </div>
                    )}

                    <div className={styles.primaryLinks}>
                        {primaryLinks.map((l, i) => (
                            <Link url={l.url} key={i}>
                                <div className={styles.linkContainer}>
                                    <Icon
                                        icon={l.icon}
                                        display="inline-block"
                                        size={32}
                                        ariaHidden={true}
                                    />

                                    <span className={styles.linkTitle}>
                                        {l.title}
                                    </span>
                                </div>
                            </Link>
                        ))}
                    </div>
                    {description && (
                        <p className={styles.description}>{description}</p>
                    )}
                </ModalBody>
            </Modal>
        </Portal>
    );
};

export default NavigationModal;
