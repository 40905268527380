import { useContext, useEffect } from "react";
import { filter, map } from "rxjs";
import { IObservation } from ".";
import {
    PropertyCategoriesContext,
    RealtimeContext,
    UserContext,
} from "../../../components";
import config from "../../../config";
import { clearCache } from "../../cache";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import { IProperty } from "../properties";
import useApiValue from "../useApiValue";

const useObservation = (id: string) => {
    const { realtimeObservable } = useContext(RealtimeContext);

    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);
    const { value, loaded, send, updateValue } = useApiValue<IObservation>({});
    const sendRequest = useXTagApiRequest();

    useEffect(() => {
        if (value) {
            const subscription = realtimeObservable
                .pipe(
                    filter(
                        (e) => e.event === "updated" && e.entity === "property",
                    ),
                    map((e) => e.payload as IProperty),
                    filter((e) => e.id === value.property.id),
                )
                .subscribe((property) => {
                    clearCache();
                    updateValue({
                        ...value,
                        property: {
                            id: property.id,
                            address: property.addressString,
                        },
                    });
                });

            return () => {
                if (subscription) {
                    subscription.unsubscribe();
                }
            };
        }
    }, [realtimeObservable, updateValue, value]);

    useEffect(() => {
        const subscription = send(
            sendRequest({
                url: `${config.issuesApiUrl}/observations/${id}`,
                method: "GET",
                urlParams: getGlobalUrlParameters(
                    selectedCategories.map((c) => c.id),
                    activeUserParentsIds,
                ),
            }),
        ).subscribe();

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [activeUserParentsIds, id, selectedCategories, send, sendRequest]);

    return { value, loaded };
};

export default useObservation;
