import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { ICreateQuestionAnswer, IQuestionAnswer } from ".";
import { PropertyCategoriesContext, UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

export const useCreateAnswers = (auditId: number) => {
    const { t } = useTranslation();
    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);
    const { send, loading, error } = useApiRequest<IQuestionAnswer[]>({
        errorMessage: t("An error occurred. Please try again."),
    });
    const sendRequest = useXTagApiRequest();

    const createAnswers = useCallback(
        (answers: ICreateQuestionAnswer[]) =>
            send(
                sendRequest({
                    url: `${config.auditsApiUrl}/${auditId}/answers/additional`,
                    method: "POST",
                    urlParams: getGlobalUrlParameters(
                        selectedCategories.map((c) => c.id),
                        activeUserParentsIds,
                    ),
                    body: answers,
                }),
            ),
        [activeUserParentsIds, auditId, selectedCategories, send, sendRequest],
    );

    return { createAnswers, loading, error };
};
