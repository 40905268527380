import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { tap } from "rxjs";
import { IUpdateUserDetails } from ".";
import { UserContext } from "../../../components";
import config from "../../../config";
import { clearCache } from "../../cache";
import { useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const useUpdateUser = () => {
    const { t } = useTranslation();
    const { send, loading, error } = useApiRequest({
        errorMessage: t("An error occurred. Please try again."),
    });
    const { user, updateUser: updateUserContext } = useContext(UserContext);
    const sendRequest = useXTagApiRequest();

    const updateUser = useCallback(
        (value: IUpdateUserDetails) =>
            send(
                sendRequest({
                    url: config.usersApiUrl,
                    method: "PUT",
                    body: value,
                }).pipe(
                    tap(() => {
                        const updatedUser = { ...user };

                        if (value.name) {
                            updatedUser.name = value.name;
                        }
                        if (value.profileImageUrl) {
                            updatedUser.profileImageUrl = value.profileImageUrl;
                        }
                        if (value.signatureUrl) {
                            updatedUser.signatureUrl = value.signatureUrl;
                        }

                        clearCache();
                        updateUserContext(updatedUser);
                    }),
                ),
            ),
        [send, sendRequest, updateUserContext, user],
    );

    return { loading, updateUser, error };
};

export default useUpdateUser;
