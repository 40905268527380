import { useCallback, useMemo, useState } from "react";
import { IJobsTabProps } from ".";
import { InfiniteScroll, JobPaperworkDropdown } from "../../../components";
import JobTimeline, { IJob } from "../../../components/JobTimeline";
import { IDocument } from "../../../utils/api/documents";
import { usePropertyJobs } from "../../../utils/api/jobs";
import styles from "./JobsTab.module.scss";

const JobsTab = ({ documents, propertyId }: IJobsTabProps) => {
    const paperwork = useMemo(() => {
        const dictionary: { [key: string]: IDocument } = {};

        for (const document of documents.filter((r) => r.job)) {
            if (!dictionary[document.documentName]) {
                dictionary[document.documentName] = document;
            }
        }

        return Object.values(dictionary);
    }, [documents]);

    const [jobs, setJobs] = useState<IJob[]>([]);
    const onAttemptsLoaded = useCallback(
        (values: IJob[], currentPage: number) => {
            setJobs((current) => {
                const newRecords = currentPage === 1 ? [] : [...current];

                newRecords.push(...values);

                return newRecords;
            });
        },
        [],
    );

    const { loading, currentPage, totalPages, goToNextPage } = usePropertyJobs(
        {
            propertyId: propertyId.toString(),
            recoveryJob: false,
        },
        onAttemptsLoaded,
    );

    const loadNextPage = useCallback(() => {
        if (currentPage < totalPages) {
            goToNextPage();
        }
    }, [currentPage, totalPages, goToNextPage]);

    return (
        <>
            {paperwork.length > 0 && (
                <div className={styles.actions}>
                    <JobPaperworkDropdown paperwork={paperwork} />
                </div>
            )}

            <InfiniteScroll loading={loading} onScrollToBottom={loadNextPage}>
                <JobTimeline
                    jobs={jobs}
                    showTasks={false}
                    showJobIcons={true}
                    isCompact={true}
                />
            </InfiniteScroll>
        </>
    );
};

export default JobsTab;
