import { useEffect } from "react";
import { IUserNotificationType } from ".";
import config from "../../../config";
import { useXTagApiRequest } from "../../request";
import useApiValue from "../useApiValue";

const useUserNotificationTypes = () => {
    const { send, value, loaded } = useApiValue<IUserNotificationType[]>({
        initialValue: [],
    });
    const sendRequest = useXTagApiRequest();

    useEffect(() => {
        const subscription = send(
            sendRequest({
                url: `${config.usersApiUrl}/notifications`,
                method: "GET",
            }),
        ).subscribe();

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [send, sendRequest]);

    return { value, loaded };
};

export default useUserNotificationTypes;
