import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IFilterProps } from "..";
import { Button, Form, Icon, InputField, Popover } from "../..";
import { useToggle } from "../../../hooks";
import { IFilter } from "../../../utils/api/useFilterable";
import {
    isNumber,
    isRequired,
    IValidateField,
    useValidateField,
    validateForm,
} from "../../../utils/validation";

const NumberFilter = ({
    property,
    toggleFilter,
    hover,
    activeFilters,
    loading,
    title,
}: IFilterProps) => {
    const { t } = useTranslation();
    const { visible, hide, toggle } = useToggle();
    const popoverButton = useRef<HTMLButtonElement>(null);

    const [minimumValue, setMinimumValue] = useState("0");
    const [maximumValue, setMaximumValue] = useState("0");

    const [displayMinimum, setDisplayMinimum] = useState(true);
    const [displayMaximum, setDisplayMaximum] = useState(false);

    useEffect(() => {
        if (activeFilters.length === 0) {
            setMinimumValue("0");
            setMaximumValue("0");
            handleMinimumClick();
        }
    }, [activeFilters]);

    const handleMinimumClick = () => {
        setDisplayMinimum(true);
        setDisplayMaximum(false);
    };

    const handleMaximumClick = () => {
        setDisplayMinimum(false);
        setDisplayMaximum(true);
    };

    const handleRangeClick = () => {
        setDisplayMinimum(true);
        setDisplayMaximum(true);
    };

    const minimumValidator = useValidateField(
        minimumValue,
        isRequired(),
        isNumber(),
    );
    const maximumValidator = useValidateField(
        maximumValue,
        isRequired(),
        isNumber(),
    );
    const filterFormValidator = validateForm(() => {
        const validators: IValidateField[] = [];

        if (displayMinimum) {
            validators.push(minimumValidator);
        }

        if (displayMaximum) {
            validators.push(maximumValidator);
        }

        return validators;
    });

    const handleFilterSubmit = () => {
        const filters: IFilter[] = [];

        if (displayMinimum) {
            filters.push({ function: ">=", value: minimumValue });
        }

        if (displayMaximum) {
            filters.push({ function: "<=", value: maximumValue });
        }

        toggleFilter([
            {
                property,
                filterGroup: {
                    operator: "{AND}",
                    filters,
                },
                appendFilters: false,
            },
        ]);
    };

    return (
        <>
            <Button
                onClick={toggle}
                setRef={popoverButton}
                size="small"
                cssRules={
                    hover
                        ? { marginLeft: "5px" }
                        : { marginLeft: "5px", opacity: 0 }
                }
            >
                <Icon
                    icon="list"
                    size={16}
                    ariaLabel={t(`Filter ${title ?? property}`)}
                />
            </Button>
            {visible && (
                <Popover anchorElement={popoverButton} hide={hide}>
                    <Button
                        onClick={handleMinimumClick}
                        variant={
                            displayMinimum && !displayMaximum ? "primary" : ""
                        }
                    >
                        &gt;=
                    </Button>
                    <Button
                        onClick={handleMaximumClick}
                        variant={
                            displayMaximum && !displayMinimum ? "primary" : ""
                        }
                    >
                        &lt;=
                    </Button>
                    <Button
                        onClick={handleRangeClick}
                        variant={
                            displayMinimum && displayMaximum ? "primary" : ""
                        }
                    >
                        {t("Range")}
                    </Button>

                    <Form
                        onSubmit={handleFilterSubmit}
                        {...filterFormValidator}
                    >
                        {displayMinimum && (
                            <InputField
                                label={t("Minimum Value")}
                                value={minimumValue}
                                onChange={setMinimumValue}
                                {...minimumValidator}
                            />
                        )}
                        {displayMaximum && (
                            <InputField
                                label={t("Maximum Value")}
                                value={maximumValue}
                                onChange={setMaximumValue}
                                {...maximumValidator}
                            />
                        )}

                        <Button
                            variant="primary"
                            type="submit"
                            disabled={loading}
                        >
                            {t("Submit")}
                        </Button>
                    </Form>
                </Popover>
            )}
            &nbsp;
        </>
    );
};

export default NumberFilter;
