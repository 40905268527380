import { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IComplianceTypesCardProps } from ".";
import {
    Grid,
    GridColumn,
    PropertyCategoriesContext,
} from "../../../components";
import ComplianceCategory from "../../../components/ComplianceCategory";
import EditableList from "../../../components/EditableList";
import { IEditableListItemFormProps } from "../../../components/EditableList/EditableListItem";
import { ISelectOption } from "../../../components/Select";
import { useHasUserParentPermission } from "../../../hooks";
import { useLandlordPropertyCategories } from "../../../utils/api/landlords";
import {
    IPropertyCategory,
    useDeletePropertyCategoryRequest,
} from "../../../utils/api/properties";
import ComplianceTypeForm from "../ComplianceTypeForm";

const ComplianceTypesCard = ({
    propertyId,
    userParentId,
    landlordId,
    categories,
    onCreate,
    onUpdate,
    onDelete,
}: IComplianceTypesCardProps) => {
    const { t } = useTranslation();
    const canEditProperties = useHasUserParentPermission(
        "edit_properties",
        userParentId,
    );

    const getPropertyCategoryId = useCallback(
        (category: IPropertyCategory) => category.id.toString(),
        [],
    );

    const deletePropertyCategoryRequest =
        useDeletePropertyCategoryRequest(propertyId);

    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const landlordPropertyCategories = useLandlordPropertyCategories({
        landlordId,
        functionalOnly: true,
    });

    const categoriesOptions = useMemo<ISelectOption[]>(
        () =>
            landlordPropertyCategories.value
                ? landlordPropertyCategories.value
                      .filter((category) => category.propertyCategory.id)
                      .filter(
                          (category) =>
                              !categories
                                  .map((c) => c.propertyCategoryId)
                                  .includes(category.propertyCategoryId),
                      )
                      .filter(
                          (category) =>
                              selectedCategories.length === 0 ||
                              selectedCategories
                                  .map((c) => c.id)
                                  .includes(category.propertyCategoryId),
                      )
                      .map((category) => ({
                          label: (
                              <ComplianceCategory
                                  colour={category.propertyCategory.colour}
                                  displayName={
                                      category.propertyCategory.displayName
                                  }
                                  displayBlock={true}
                              />
                          ),
                          value: category.propertyCategoryId.toString(),
                      }))
                : [],
        [categories, landlordPropertyCategories.value, selectedCategories],
    );

    const renderForm = useCallback(
        (props: IEditableListItemFormProps<IPropertyCategory>) => (
            <ComplianceTypeForm
                propertyId={propertyId}
                propertyCategories={categoriesOptions}
                {...props}
            />
        ),
        [categoriesOptions, propertyId],
    );

    const renderView = useCallback(
        (entity: IPropertyCategory) => (
            <Grid>
                <GridColumn size="thirtyPercent">
                    <ComplianceCategory
                        colour={entity.colour}
                        displayName={entity.displayName}
                        displayBlock={true}
                    />
                </GridColumn>
                <GridColumn size="seventyPercent">
                    {entity.contractors && entity.contractors.length > 0
                        ? entity.contractors
                              .map((contractor) => contractor.name)
                              .join(", ")
                        : t("No assigned contractor")}
                </GridColumn>
            </Grid>
        ),
        [t],
    );

    const activeCategories = useMemo(
        () =>
            categories.filter(
                (category) =>
                    selectedCategories.length === 0 ||
                    selectedCategories
                        .map((c) => c.id)
                        .includes(category.propertyCategoryId),
            ),
        [categories, selectedCategories],
    );

    return (
        <EditableList
            deleteModalTitle={t("Delete compliance type")}
            deleteModalMessage={t(
                "Are you sure you want to delete this compliance type?",
            )}
            emptyMessage={t("No compliance types found")}
            entities={activeCategories}
            onCreate={onCreate}
            onUpdate={onUpdate}
            onDelete={onDelete}
            getEntityId={getPropertyCategoryId}
            deleteRequest={deletePropertyCategoryRequest}
            renderForm={renderForm}
            renderView={renderView}
            canEdit={canEditProperties}
        />
    );
};

export default ComplianceTypesCard;
