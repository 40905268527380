import { useCallback, useContext } from "react";
import { ILockResult } from ".";
import { PropertyCategoriesContext, UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const useRemoveJobAuditLock = () => {
    const { send } = useApiRequest<ILockResult>({});
    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);
    const sendRequest = useXTagApiRequest();

    const removeLock = useCallback(
        (jobIds: number[]) =>
            send(
                sendRequest({
                    url: `${config.lgsrsApiUrl}/audit/unlock`,
                    method: "POST",
                    urlParams: getGlobalUrlParameters(
                        selectedCategories.map((c) => c.id),
                        activeUserParentsIds,
                    ),
                    body: { jobIds },
                }),
            ),
        [activeUserParentsIds, selectedCategories, send, sendRequest],
    );

    return { removeLock };
};

export default useRemoveJobAuditLock;
