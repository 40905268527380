import { CSSProperties, ReactNode } from "react";
import { useCssClasses } from "../../../hooks";
import styles from "./GridColumn.module.scss";

const GridColumn = ({
    children,
    size,
    fullHeight = false,
    cssRules = {},
}: IGridColumnProps) => {
    const cssClasses = useCssClasses(
        styles.container,
        fullHeight ? styles.fullHeight : "",
        size === "half"
            ? styles.half
            : size === "oneThird"
            ? styles.oneThird
            : size === "twoThirds"
            ? styles.twoThirds
            : size === "oneQuarter"
            ? styles.oneQuarter
            : size === "threeQuarters"
            ? styles.threeQuarters
            : size === "fiveOverTwelve"
            ? styles.fiveOverTwelve
            : size === "sevenOverTwelve"
            ? styles.sevenOverTwelve
            : size === "tenPercent"
            ? styles.tenPercent
            : size === "twentyPercent"
            ? styles.twentyPercent
            : size === "thirtyPercent"
            ? styles.thirtyPercent
            : size === "seventyPercent"
            ? styles.seventyPercent
            : size === "eightyPercent"
            ? styles.eightyPercent
            : size === "ninetyPercent"
            ? styles.ninetyPercent
            : "",
    );

    return (
        <div className={cssClasses} style={cssRules}>
            {children}
        </div>
    );
};

interface IGridColumnProps {
    size?:
        | "half"
        | "oneThird"
        | "twoThirds"
        | "oneQuarter"
        | "threeQuarters"
        | "fiveOverTwelve"
        | "sevenOverTwelve"
        | "tenPercent"
        | "twentyPercent"
        | "thirtyPercent"
        | "seventyPercent"
        | "eightyPercent"
        | "ninetyPercent";
    children: ReactNode;
    fullHeight?: boolean;
    cssRules?: CSSProperties;
}

export default GridColumn;
