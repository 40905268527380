import { IAverageJobTime, ITotalJob } from "./api/misc";
import { secondsToTimeSpan } from "./dates";

const aggregateData = (count: number[], aggregate: number[]) => {
    for (let i = 0; i < count.length; i++) {
        if (!aggregate[i]) {
            aggregate[i] = count[i];
        } else {
            aggregate[i] += count[i];
        }
    }
};

const mapChartData = (count: number[]) => {
    return count.map((i) => ({
        label: i.toString(),
        value: i,
    }));
};

const generateAverageJobTimeData = (jobTimes: IAverageJobTime[]) => {
    let weekTime = 0;
    let weekCount = 0;
    let monthTime = 0;
    let monthCount = 0;
    let yearTime = 0;
    let yearCount = 0;

    for (const jobTime of jobTimes) {
        weekTime += jobTime.week.time;
        weekCount += jobTime.week.jobCount;
        monthTime += jobTime.month.time;
        monthCount += jobTime.month.jobCount;
        yearTime += jobTime.year.time;
        yearCount += jobTime.year.jobCount;
    }

    return [
        {
            label: "week",
            time: secondsToTimeSpan(weekCount > 0 ? weekTime / weekCount : 0),
        },
        {
            label: "month",
            time: secondsToTimeSpan(
                monthCount > 0 ? monthTime / monthCount : 0,
            ),
        },
        {
            label: "year",
            time: secondsToTimeSpan(yearCount > 0 ? yearTime / yearCount : 0),
        },
    ];
};

const generateTotalJobsData = (totalJobs: ITotalJob[]) => {
    let week = 0;
    let month = 0;
    let year = 0;
    const weekCounts: number[] = [];
    const monthCounts: number[] = [];
    const yearCounts: number[] = [];

    for (const landlordJobs of totalJobs) {
        week += landlordJobs.week.total;
        month += landlordJobs.month.total;
        year += landlordJobs.year.total;

        aggregateData(landlordJobs.week.periodCounts, weekCounts);
        aggregateData(landlordJobs.month.periodCounts, monthCounts);
        aggregateData(landlordJobs.year.periodCounts, yearCounts);
    }

    return [
        {
            label: "This week",
            count: week,
            chart: mapChartData(weekCounts),
        },
        {
            label: "This month",
            count: month,
            chart: mapChartData(monthCounts),
        },
        {
            label: "This year",
            count: year,
            chart: mapChartData(yearCounts),
        },
    ];
};

export { generateTotalJobsData, generateAverageJobTimeData };
