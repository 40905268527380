import { useCallback, useContext } from "react";
import { IAddEngineer } from ".";
import { UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const useAddEngineer = () => {
    const { activeUserParentsIds } = useContext(UserContext);
    const { send, loading, error } = useApiRequest({});
    const sendRequest = useXTagApiRequest();

    const addEngineer = useCallback(
        (engineer: IAddEngineer) =>
            send(
                sendRequest({
                    url: `${config.landlordsApiUrl}/engineers`,
                    method: "POST",
                    urlParams: getGlobalUrlParameters([], activeUserParentsIds),
                    body: engineer,
                }),
            ),
        [activeUserParentsIds, send, sendRequest],
    );

    return { addEngineer, loading, error };
};

export default useAddEngineer;
