import { IAllocatedJob } from "./api/allocatedJobs";
import { getNow } from "./dates";

const allocatedJobStatus = (allocatedJob: IAllocatedJob) => {
    const date = new Date(allocatedJob.jobDate);
    const now = getNow();

    if (allocatedJob.manuallyMatched) {
        if (allocatedJob.unableToAccess) {
            return "UTA Reconciled";
        } else {
            return "Job Reconciled";
        }
    } else if (allocatedJob.isComplete) {
        return "Complete";
    } else if (allocatedJob.unableToAccess) {
        return "UTA";
    } else if (date > now) {
        return "Waiting";
    } else if (
        date < now &&
        date.getMonth() === now.getMonth() &&
        date.getDate() === now.getDate()
    ) {
        return "Late";
    }

    return "Missed";
};

export default allocatedJobStatus;
