import { Loading } from "../../../components";
import JobTimeline from "../../../components/JobTimeline";
import useRecentJobs from "../../../utils/api/jobs/useRecentJobs";

const RecentJobs = () => {
    const recentJobs = useRecentJobs();

    return (
        <>
            {recentJobs.loaded ? (
                <JobTimeline
                    showJobIcons={true}
                    jobs={recentJobs.records}
                    isCompact={true}
                />
            ) : (
                <Loading />
            )}
        </>
    );
};

export default RecentJobs;
