import { useMemo } from "react";
import { IObservationsNoticeProps } from ".";
import styles from "./ObservationsNotice.module.scss";
import useUnresolvedObservationCounts from "../../utils/api/observations/useUnresolvedObservationCounts";
import { Badge } from "..";
import { useTranslation } from "react-i18next";

const ObservationsNotice = ({ propertyId }: IObservationsNoticeProps) => {
    const { t } = useTranslation();
    const { value, loaded } = useUnresolvedObservationCounts(propertyId);

    const propertiesUnresolvedObservations = useMemo(
        () => (value?.length && value[0].count) || 0,
        [value],
    );

    return loaded ? (
        <div className={styles.observationsNoticeRow}>
            <Badge position="absolute" topOffset={6}>
                {propertiesUnresolvedObservations}
            </Badge>
            <div className={styles.observationsNotice}>
                {t("observationNotice", {
                    count: propertiesUnresolvedObservations,
                })}
            </div>
        </div>
    ) : (
        <></>
    );
};

export default ObservationsNotice;
