import { useMemo } from "react";
import { addDays, getDate, getToday } from "../utils/dates";

export const usePhaseBoundaries = <T extends IPhase>(
    phases: T[],
    phaseTimeFrameDateIncrement: number,
    areDatesInPast: boolean,
) => {
    const phasesGroups = useMemo(() => {
        const list: {
            from: Date;
            to: Date;
            phase: T;
        }[] = [];

        for (let i = 0; i < phases.length; i++) {
            const phase = phases[i];
            const phaseDates = phase.dates.map((d) =>
                new Date(d.date).getTime(),
            );

            const maxPhaseDate = new Date(Math.max(...phaseDates));
            const minPhaseDate = new Date(Math.min(...phaseDates));
            const phaseDateUpperBoundary =
                areDatesInPast && i === 0
                    ? addDays(getToday(), 1)
                    : addDays(maxPhaseDate, phaseTimeFrameDateIncrement);
            const to = getDate(phaseDateUpperBoundary);
            const from = getDate(minPhaseDate);

            list.push({
                to,
                from,
                phase,
            });
        }

        return list;
    }, [phases, phaseTimeFrameDateIncrement, areDatesInPast]);

    return phasesGroups;
};

interface IPhase {
    phaseTimeFrame: number;
    dates: IDate[];
}

interface IDate {
    date: string;
}
