import { IPropertyDataProps } from ".";
import { GridColumn } from "../../../components";
import styles from "./PropertyData.module.scss";

const PropertyData = ({ label, children }: IPropertyDataProps) => {
    return (
        <>
            <GridColumn size="oneQuarter">
                <span className={styles.label}>{label}</span>
            </GridColumn>

            <GridColumn size="oneQuarter">{children}</GridColumn>
        </>
    );
};

export default PropertyData;
