import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ITableActionsProps } from ".";
import { Dropdown, Icon, UserContext } from "../../../components";
import { IDropdownItem } from "../../../components/Dropdown";
import { useHasUserParentPermission } from "../../../hooks";

const TableActions = ({
    id,
    status,
    auditorUserId,
    userParentId,
    removeHold,
    startAuditing,
    viewAudits,
}: ITableActionsProps) => {
    const { t } = useTranslation();
    const { user } = useContext(UserContext);
    const canRemoveAuditHold = useHasUserParentPermission(
        "portal_admin",
        userParentId,
    );

    const items = useMemo(() => {
        const list: IDropdownItem[] = [];

        if (status === "Passed" || status === "Failed") {
            list.push({ label: t("View QS"), onClick: () => viewAudits([id]) });
        } else {
            if (status === "Waiting" || auditorUserId === user.id) {
                list.push({
                    label: t("Start QS"),
                    onClick: () => startAuditing([id]),
                });
            }

            if (
                status === "Hold" &&
                (canRemoveAuditHold || auditorUserId === user.id)
            ) {
                list.push({
                    label: t("Remove hold"),
                    onClick: () => removeHold([id]),
                });
            }
        }

        return list;
    }, [
        auditorUserId,
        canRemoveAuditHold,
        id,
        removeHold,
        startAuditing,
        status,
        t,
        user.id,
        viewAudits,
    ]);

    return (
        <Dropdown
            label={<Icon icon="more" ariaLabel={t("Actions")} />}
            size="small"
            items={items}
        />
    );
};

export default TableActions;
