import { orderBy } from "lodash";
import { useContext, useEffect, useState } from "react";
import { forkJoin } from "rxjs";
import { PropertyCategoriesContext, UserContext } from "../../components";
import useFilterable, { IFilters, IFilterToggle } from "./useFilterable";

/**
 *
 * @deprecated This hook needs to be deleted. Use pagination instead.
 */
function useAllFilterable<T>({
    url,
    sortProperties,
    sortOrders,
    filters,
}: IAllFilterableProps) {
    const [records, setRecords] = useState<T[]>([]);
    const [loaded, setLoaded] = useState(false);

    const onRecordsLoaded = (
        values: T[],
        currentPage: number,
        totalPages: number,
    ) => {
        const newRecords = currentPage === 1 ? [] : [...records];
        newRecords.push(...values);
        setRecords(orderBy(newRecords, sortProperties, sortOrders));

        if (currentPage < totalPages) {
            filterable.goToNextPage();
        } else {
            setLoaded(true);
        }
    };

    const filterable = useFilterable<T>(
        url,
        {
            params: {
                filters,
                itemsPerPage: 1000,
            },
            discardPreviousValue: true,
        },
        onRecordsLoaded,
    );

    const updateValue = (value: T[]) => {
        setRecords(orderBy(value, sortProperties, sortOrders));
    };

    const toggleFilter = (toggle: IFilterToggle[]) => {
        setLoaded(false);

        filterable.toggleFilter(toggle);
    };

    const { onActiveUserParentsChanged } = useContext(UserContext);
    const { onSelectedPropertyCategoriesChanged } = useContext(
        PropertyCategoriesContext,
    );
    useEffect(() => {
        const subscription = forkJoin([
            onSelectedPropertyCategoriesChanged,
            onActiveUserParentsChanged,
        ]).subscribe(() => {
            setLoaded(false);
        });

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [onActiveUserParentsChanged, onSelectedPropertyCategoriesChanged]);

    return {
        totalRecords: filterable.totalRecords,
        toggleFilter,
        filters: filterable.filters,
        refresh: filterable.refresh,
        records,
        loaded,
        updateValue,
    };
}

interface IAllFilterableProps {
    url: string;
    sortProperties: string[];
    sortOrders?: ("asc" | "desc")[];
    filters?: IFilters;
    refreshOnUserParentsChange?: boolean;
}

export default useAllFilterable;
