import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, GridColumn, Icon } from "../../../components";
import { colours } from "../../../styles/colours";
import { IFuelType, IFuelTypeRecentWork } from "../../../utils/api/misc";
import styles from "./RecentWorks.module.scss";

interface IRecentWorkModel {
    fuelType: IFuelType;
    servicesCount: number;
    installsCount: number;
    repairsCount: number;
}

const RecentWorks = ({ works }: IRecentWorksProps) => {
    const { t } = useTranslation();

    const [recentWork, setRecentWork] = useState<IRecentWorkModel[]>([]);

    useEffect(() => {
        const result: IRecentWorkModel[] = [];

        for (const landlordWorks of works.filter((work) => work.fuelType)) {
            // 1. Find the WorkData associated with the Fuel Type Id
            let workData = result.find(
                (v) => v.fuelType.id === landlordWorks.fuelType.id,
            );

            // 1.1 If the WorkData does not exist, create one and add it to the result
            if (!workData) {
                workData = {
                    fuelType: landlordWorks.fuelType,
                    servicesCount: 0,
                    installsCount: 0,
                    repairsCount: 0,
                };

                result.push(workData);
            }

            workData.servicesCount += landlordWorks.services.total;
            workData.installsCount += landlordWorks.installs.total;
            workData.repairsCount += landlordWorks.repairs.total;
        }

        // 2. Set the value sorted by display name of the fuel type
        setRecentWork(
            result
                .filter(
                    (work) =>
                        work.repairsCount > 0 ||
                        work.installsCount > 0 ||
                        work.servicesCount > 0,
                )
                .sort((a, b) =>
                    a.fuelType.displayName < b.fuelType.displayName ? -1 : 1,
                ),
        );
    }, [works]);

    return (
        <div className={styles.centerContainer}>
            {recentWork.map((work) => (
                <div className={styles.container} key={work.fuelType.id}>
                    <Grid
                        margin={false}
                        alignCenter={true}
                        alignCenterVertical={true}
                    >
                        <GridColumn
                            size="oneQuarter"
                            cssRules={{
                                backgroundColor: work.fuelType.color,
                            }}
                        >
                            <div
                                className={`${styles.iconWrapper} ${styles.white}`}
                                title={work.fuelType.displayName}
                            >
                                <Icon
                                    icon={work.fuelType.name}
                                    size={50}
                                    ariaHidden={true}
                                />
                                <span className={styles.fuelTypeDisplay}>
                                    {work.fuelType.displayName}
                                </span>
                            </div>
                        </GridColumn>

                        <GridColumn size="oneQuarter">
                            <div className={styles.workWrapper}>
                                <span
                                    className={`${styles.count} ${styles.green}`}
                                    data-test-id={`${work.fuelType.displayName.replace(
                                        " ",
                                        "_",
                                    )}_Services_Total_Span`}
                                >
                                    {work.servicesCount}
                                </span>
                                &nbsp;
                                {t("Services")}
                            </div>
                        </GridColumn>

                        <GridColumn size="oneQuarter">
                            <div className={styles.workWrapper}>
                                <span
                                    className={styles.count}
                                    style={{ color: colours.chart.colours[42] }}
                                    data-test-id={`${work.fuelType.displayName.replace(
                                        " ",
                                        "_",
                                    )}_Installs_Total_Span`}
                                >
                                    {work.installsCount}
                                </span>
                                &nbsp;
                                {t("Installs")}
                            </div>
                        </GridColumn>

                        <GridColumn size="oneQuarter">
                            <div className={styles.workWrapper}>
                                <span
                                    className={styles.count}
                                    style={{ color: colours.chart.colours[41] }}
                                    data-test-id={`${work.fuelType.displayName.replace(
                                        " ",
                                        "_",
                                    )}_Repairs_Total_Span`}
                                >
                                    {work.repairsCount}
                                </span>
                                &nbsp;
                                {t("Repairs")}
                            </div>
                        </GridColumn>
                    </Grid>
                </div>
            ))}
        </div>
    );
};

interface IRecentWorksProps {
    works: IFuelTypeRecentWork[];
    maxHeight?: number;
}

export default RecentWorks;
