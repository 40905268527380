import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IFlag } from "../../utils/api/misc";
import { clearCache } from "../../utils/cache";
import Button from "../Button";
import { EditablePill } from "../EditableDetailsTable";
import PropertyFlagSelector from "./PropertyFlagSelector/PropertyFlagSelector";

const PropertyFlagEditor = ({
    onSave,
    onCancel,
    selectedFlagsIds = [],
    flags,
    updateValue,
    landlordId,
}: IPropertyFlagEditorProps) => {
    const { t } = useTranslation();
    const [selectedIds, setSelectedIds] = useState(selectedFlagsIds);
    const [removedIds, setRemovedIds] = useState<number[]>([]);

    const landlordFlags = useMemo(() => {
        if (flags) {
            return flags.filter((flag) => flag.landlordId === landlordId);
        }
        return [];
    }, [flags, landlordId]);

    const selectedFlags = useMemo(() => {
        if (flags) {
            return flags.filter((f) => selectedIds.includes(f.id));
        }

        return [];
    }, [selectedIds, flags]);

    const handleSaveClick = useCallback(() => {
        onSave(selectedIds, removedIds);
    }, [onSave, removedIds, selectedIds]);

    const flagsCreate = useCallback(
        (createdFlags: IFlag[]) => {
            updateValue([...flags, ...createdFlags]);
            clearCache();
        },
        [flags, updateValue],
    );

    const flagsUpdate = useCallback(
        (flag: IFlag) => {
            const newFlags = [...flags];
            const index = newFlags.findIndex((f) => f.id === flag.id);

            if (index > -1) {
                newFlags[index] = flag;
                updateValue(newFlags);
                clearCache();
            }
        },
        [flags, updateValue],
    );

    const flagsDelete = useCallback(
        (flag: IFlag) => {
            const newFlags = flags.filter((f) => f.id !== flag.id);

            updateValue(newFlags);
            clearCache();
        },
        [flags, updateValue],
    );

    const notSelectedFlags = useMemo(
        () => landlordFlags.filter((flag) => !selectedIds.includes(flag.id)),
        [landlordFlags, selectedIds],
    );

    const handleAddFlag = useCallback((id: number) => {
        setSelectedIds((ids) => [...ids, id]);
        setRemovedIds((ids) => ids.filter((i) => i !== id));
    }, []);

    const handleRemoveFlag = useCallback((id: number) => {
        setSelectedIds((ids) => ids.filter((i) => i !== id));
        setRemovedIds((ids) => [...ids, id]);
    }, []);

    return (
        <>
            {selectedFlags.map((flag) => {
                return (
                    <EditablePill
                        value={flag.id}
                        key={flag.id}
                        name={flag.name}
                        colour={flag.colour}
                        title={flag.name}
                        remove={handleRemoveFlag}
                    />
                );
            })}
            <PropertyFlagSelector
                flags={landlordFlags}
                notSelectedFlags={notSelectedFlags}
                selectFlag={handleAddFlag}
                onFlagsCreate={flagsCreate}
                onFlagUpdate={flagsUpdate}
                onFlagDelete={flagsDelete}
                landlordId={landlordId}
            />
            <Button
                variant="primary"
                onClick={handleSaveClick}
                cssRules={{ marginRight: "10px" }}
            >
                {t("Save")}
            </Button>
            <Button onClick={onCancel}>{t("Cancel")}</Button>
        </>
    );
};

interface IPropertyFlagEditorProps {
    flags: IFlag[];
    onSave: (flagsToAssign: number[], flagsToRemove: number[]) => void;
    onCancel: () => void;
    selectedFlagsIds?: number[];
    updateValue: (value: IFlag[]) => void;
    landlordId: number;
}

export default PropertyFlagEditor;
