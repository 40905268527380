import { IComplianceElectricDashboardProps } from ".";
import { Grid, GridColumn } from "../../../components";
import ApplianceConditionCard from "../ApplianceConditionCard";
import CompliancePropertyMap from "../CompliancePropertyMap";
import EicrStatusCard from "../EicrStatusCard";
import JobsIssuesUTAsWidget from "../JobsIssuesUTAsWidget";
import NetPromoterScoreCard from "../NetPromoterScoreCard";
import NoAccessCountCard from "../NoAccessCountCard";
import PropertyStatus from "../PropertyStatus";
import RecentWorksCard from "../RecentWorksCard";
import SafetyRisks from "../SafetyRisks";
import TotalJobsAverageTimeCard from "../TotalJobsAverageTimeCard";
import UnresolvedObservationsStatusCard from "../UnresolvedObservationsStatusCard";

const ComplianceElectricDashboard = ({
    dashboard,
    loaded,
    rowHeight,
    mapFeature,
}: IComplianceElectricDashboardProps) => {
    return (
        <>
            <Grid>
                <GridColumn size="oneQuarter">
                    <NoAccessCountCard />
                </GridColumn>

                <GridColumn size="oneQuarter">
                    <TotalJobsAverageTimeCard
                        loaded={loaded}
                        data={dashboard}
                    />
                </GridColumn>

                <GridColumn size="oneQuarter">
                    <SafetyRisks />
                </GridColumn>

                <GridColumn size="oneQuarter">
                    <ApplianceConditionCard
                        loaded={loaded}
                        data={dashboard}
                        collapsed={true}
                    />
                </GridColumn>
            </Grid>

            <Grid>
                <GridColumn size="fiveOverTwelve">
                    <PropertyStatus />
                </GridColumn>

                <GridColumn size="oneQuarter">
                    <EicrStatusCard loaded={loaded} data={dashboard} />
                </GridColumn>

                <GridColumn size="oneThird">
                    <UnresolvedObservationsStatusCard />
                </GridColumn>
            </Grid>

            <Grid>
                <GridColumn size="oneThird">
                    <RecentWorksCard
                        loaded={loaded}
                        data={dashboard}
                        maxHeight={rowHeight}
                    />
                </GridColumn>

                <GridColumn size="oneThird">
                    <JobsIssuesUTAsWidget rowScrollHeight={rowHeight} />
                </GridColumn>

                {mapFeature && (
                    <GridColumn size="oneThird">
                        <CompliancePropertyMap />
                    </GridColumn>
                )}
            </Grid>

            <Grid>
                <GridColumn size="oneThird">
                    <NetPromoterScoreCard loaded={loaded} data={dashboard} />
                </GridColumn>
            </Grid>
        </>
    );
};

export default ComplianceElectricDashboard;
