import { ReactNode } from "react";
import SelectItem from "./SelectItem";

export interface ISelectItemProps {
    children: ReactNode;
    value: string;
    isSelected?: boolean;
    onClick: (value: string) => void;
    applyPadding?: boolean;
}

export default SelectItem;
