import { useCallback } from "react";
import { ITenantsTabProps } from ".";
import { ITenant } from "../../../utils/api/properties";
import { clearCache } from "../../../utils/cache";
import {
    realtimeCreateList,
    realtimeDeleteList,
    realtimeUpdateList,
} from "../../../utils/realtime";
import TenantsCard from "../TenantsCard";

const TenantsTab = ({ property, updateProperty }: ITenantsTabProps) => {
    const handleCreate = useCallback(
        (tenant: ITenant) => {
            const list = realtimeCreateList(tenant, property.tenants);
            if (list) {
                updateProperty({ ...property, tenants: list });
            }
            clearCache();
        },
        [property, updateProperty],
    );

    const handleUpdate = useCallback(
        (tenant: ITenant) => {
            const list = realtimeUpdateList(tenant, property.tenants);
            if (list) {
                updateProperty({ ...property, tenants: list });
            }
            clearCache();
        },
        [property, updateProperty],
    );

    const handleDelete = useCallback(
        (tenant: ITenant) => {
            const list = realtimeDeleteList(tenant, property.tenants);
            if (list) {
                updateProperty({ ...property, tenants: list });
            }
            clearCache();
        },
        [property, updateProperty],
    );

    return (
        <TenantsCard
            propertyId={property.id}
            userParentId={property.landlord.userId}
            tenants={property.tenants}
            onCreate={handleCreate}
            onUpdate={handleUpdate}
            onDelete={handleDelete}
        />
    );
};

export default TenantsTab;
