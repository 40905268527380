import { useCallback, useContext, useEffect } from "react";
import { PropertyCategoriesContext, UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiValue from "../useApiValue";

const useReconciliationStats = () => {
    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);
    const { value, send } = useApiValue<Record<string, number>>({});
    const sendRequest = useXTagApiRequest();

    const refresh = useCallback(
        () =>
            send(
                sendRequest({
                    url: `${config.reconciliationApiUrl}/stats`,
                    method: "GET",
                    urlParams: getGlobalUrlParameters(
                        selectedCategories.map((c) => c.id),
                        activeUserParentsIds,
                    ),
                }),
            ),
        [activeUserParentsIds, selectedCategories, send, sendRequest],
    );

    useEffect(() => {
        const subscription = refresh().subscribe();

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [refresh]);

    return { value, refresh };
};

export default useReconciliationStats;
