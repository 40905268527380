import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IObservationsLogProps } from ".";
import { Alert, Loading } from "..";
import {
    IObservation,
    ObservationStatus,
    useObservationsForModel,
} from "../../utils/api/observations";
import Link from "../Link";
import SimpleTable, { IColumn } from "../SimpleTable";
import styles from "./ObservationsLog.module.scss";

const ObservationsLog = ({ id, modelType }: IObservationsLogProps) => {
    const { t } = useTranslation();

    const { records, loaded, error } = useObservationsForModel({
        id,
        modelType,
    });

    const renderObservationLinkTitle = useCallback(
        (value: string, data: IObservation) => (
            <Link key={data.id} url={`/issues/observations/${data.id}`}>
                {data.type.displayName}: {value}
            </Link>
        ),
        [],
    );

    const renderStatus = useCallback(
        (value: ObservationStatus) => {
            return (
                <div className={`${styles.observationStatus} ${styles[value]}`}>
                    {t(value)}
                </div>
            );
        },
        [t],
    );

    const columns = useMemo<IColumn[]>(
        () => [
            {
                title: t("Date"),
                path: "dateRaised",
                type: "date",
            },
            {
                title: t("Title"),
                path: "description",
                type: "component",
                render: renderObservationLinkTitle,
            },
            {
                title: t("Status"),
                path: "status.displayName",
                type: "component",
                render: renderStatus,
            },
        ],
        [renderObservationLinkTitle, renderStatus, t],
    );

    return (
        <>
            {loaded ? (
                records.length > 0 ? (
                    <SimpleTable columns={columns} data={records} />
                ) : (
                    t("No issues found")
                )
            ) : error ? (
                <Alert type="error">{t("Observations failed to load")}</Alert>
            ) : (
                <Loading />
            )}
        </>
    );
};

export default ObservationsLog;
