import DashboardWidgetFilter from "./DashboardWidgetFilter";

interface IFilterGroupOption {
    label: string;
    value: string;
}

interface IGenericFilterGroup<T> {
    id: string;
    title: string;
    value: T;
    allowEmptyValue: boolean;
    changeValue: (value: string) => void;
    options: IFilterGroupOption[];
}

export interface IDashboardWidgetFilterProps {
    filters: IFilterGroup[];
}

export type IFilterGroup =
    | IGenericFilterGroup<string>
    | IGenericFilterGroup<string[]>;

export default DashboardWidgetFilter;
