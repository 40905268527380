import { useCallback, useContext } from "react";
import { PropertyCategoriesContext, UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const useManualUploadResetApprovalUpdate = () => {
    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);
    const { send, error, loading } = useApiRequest<string>({});
    const sendRequest = useXTagApiRequest();

    const update = useCallback(
        (model: IManualUploadResetApprovalUpdate) =>
            send(
                sendRequest({
                    url: `${config.jobsApiUrl}/manualuploadresetapproval/index`,
                    method: "POST",
                    urlParams: getGlobalUrlParameters(
                        selectedCategories.map((c) => c.id),
                        activeUserParentsIds,
                    ),
                    body: model,
                }),
            ),
        [activeUserParentsIds, selectedCategories, send, sendRequest],
    );

    return { update, error, loading };
};

export default useManualUploadResetApprovalUpdate;

interface IManualUploadResetApprovalUpdate {
    jobIds: number[];
    approved: boolean;
}
