import { difference, snakeCase, startCase } from "lodash";
import { useCallback, useMemo } from "react";
import SimpleTable, { IColumn } from "../../../components/SimpleTable";
import { toDateString } from "../../../utils/dates";
import styles from "./DifferenceTable.module.scss";

const DifferenceTable = ({
    original,
    current,
    suppress = [],
    showChangesOnly = false,
}: IDifferenceTableProps) => {
    const suppressMemo = useMemo(
        () => suppress.map((f) => f.toLowerCase()),
        [suppress],
    );

    const parseJson = useCallback((payload: string) => {
        if (!payload) {
            return undefined;
        }

        try {
            const o = JSON.parse(payload);

            if (o && typeof o === "object") {
                return snakeCaseKeys(o);
            }
        } catch (e) {
            return {};
        }
    }, []);

    const isDateFormat = useMemo(
        () =>
            /^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])/,
        [],
    );

    const snakeCaseKeys = (data: any) => {
        const converted: { [key: string]: any } = {};
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        Object.keys(data).forEach((k) => (converted[snakeCase(k)] = data[k]));

        return converted;
    };

    const originalMemo = useMemo(
        () => (typeof original === "string" ? parseJson(original) : original),
        [original, parseJson],
    );

    const currentMemo = useMemo(
        () => (typeof current === "string" ? parseJson(current) : current),
        [current, parseJson],
    );

    const dataRender = useCallback(
        (type: "original" | "current", value: any, data: any) => {
            if (data.current === data.original || !data.original) {
                return value;
            }

            const isOrig = value === data.original;
            value = value ? value.toString() : "";
            const compareAgainst = isOrig ? data.current : data.original;
            const delta = difference(
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                value.split(" "),
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                compareAgainst && compareAgainst.split(" "),
            );

            return (
                <div className={styles.diffBackground}>
                    {value.split(" ").map((w: string, i: number) =>
                        delta.includes(w) ? (
                            <span
                                className={styles.differenceHighlight}
                                key={i}
                            >
                                &nbsp;
                                {w}&nbsp;
                            </span>
                        ) : (
                            `${w} `
                        ),
                    )}
                </div>
            );
        },
        [],
    );

    const columns = useMemo((): IColumn[] => {
        const cols: IColumn[] = [
            {
                title: "",
                path: "title",
                type: "string",
            },
            {
                title: "Current",
                path: "current",
                type: "string",
                render: (value, data) => dataRender("current", value, data),
            },
        ];

        if (originalMemo) {
            cols.splice(1, 0, {
                title: "Original",
                path: "original",
                type: "string",
                render: (value, data) => dataRender("original", value, data),
            });
        }

        return cols;
    }, [dataRender, originalMemo]);

    const getFormattedValue = useCallback(
        (appliance: any, key: string) => {
            const value = appliance && appliance[key];

            return (
                value &&
                (typeof value !== "string"
                    ? value.toString()
                    : isDateFormat.test(value) && Date.parse(value) > 0
                    ? toDateString(new Date(value))
                    : value)
            );
        },
        [isDateFormat],
    );

    const buildModifiedData = useCallback(
        (orig: any, modified: any) => {
            const data: { title: any; original: any; current: any }[] = [];

            const obj = modified ? modified : orig;
            if (obj) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                Object.keys(obj).forEach((key) => {
                    if (
                        !suppressMemo.includes(key.toLowerCase()) &&
                        !(showChangesOnly && orig[key] === modified[key])
                    ) {
                        data.push({
                            title: startCase(key),
                            original: getFormattedValue(orig, key),
                            current: getFormattedValue(modified, key),
                        });
                    }
                });
            }

            return data;
        },
        [getFormattedValue, showChangesOnly, suppressMemo],
    );

    return (
        <SimpleTable
            data={buildModifiedData(originalMemo, currentMemo)}
            columns={columns}
            withHeaders={true}
        />
    );
};

interface IDifferenceTableProps {
    current: any;
    original?: any;
    suppress?: string[];
    showChangesOnly?: boolean;
}

export default DifferenceTable;
