import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { IOverriddenAnswerCommentProps } from ".";
import { Icon, Popover } from "../../../components";
import { useToggle } from "../../../hooks";
import styles from "./OverriddenAnswerComment.module.scss";

const OverriddenAnswerComment = ({
    comment,
}: IOverriddenAnswerCommentProps) => {
    const { t } = useTranslation();
    const { visible, show, hide } = useToggle();
    const popoverDiv = useRef<HTMLDivElement>(null);

    return (
        <>
            <div
                className={styles.container}
                onMouseOver={show}
                onFocus={show}
                onMouseOut={hide}
                onBlur={hide}
                ref={popoverDiv}
            >
                <Icon icon="comment" ariaLabel={t("Comments")} size={10} />
            </div>

            {visible && (
                <Popover hide={hide} anchorElement={popoverDiv}>
                    {comment}
                </Popover>
            )}
        </>
    );
};

export default OverriddenAnswerComment;
