import DocumentAudit from "./DocumentAudit";
import {
    IAuditableDocument,
    IDocumentAuditResult,
} from "../../../utils/api/lgsrs";

export interface IDocumentAuditProps {
    document: IAuditableDocument;
    readonly?: boolean;
    complianceTypeId: number;
    updateHandler: (documentId: number, result: IDocumentAuditResult) => void;
}

export default DocumentAudit;
