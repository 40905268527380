import { ReactNode, useMemo } from "react";
import { createPortal } from "react-dom";

const Portal = ({ children }: IPortalProps) => {
    const rootElement = useMemo(() => document.getElementById("root"), []);

    if (rootElement) {
        return createPortal(children, rootElement);
    }

    return null;
};

interface IPortalProps {
    children: ReactNode;
}

export default Portal;
