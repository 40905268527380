import { ITableColumn } from "..";
import { IFilterGroup } from "../../../utils/api/useFilterable";
import TableFilter from "./TableFilter";

export interface ITableFilterProps {
    column?: ITableColumn<unknown>;
    filterGroup: IFilterGroup;
    property: string;
    clearFilters: (property: string) => void;
    format: (value: string, column: ITableColumn<unknown>) => string;
}

export default TableFilter;
