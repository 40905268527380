import { ReactNode } from "react";
import { useCssClasses } from "../../hooks";
import styles from "./Grid.module.scss";

const Grid = ({
    children,
    fullHeight = false,
    alignCenter = false,
    margin = true,
    alignCenterVertical = false,
}: IGridProps) => {
    const cssClasses = useCssClasses(
        styles.container,
        margin ? styles.margin : "",
        fullHeight ? styles.fullHeight : "",
        alignCenter ? styles.alignCenter : "",
        alignCenterVertical ? styles.alignCenterVertical : "",
    );

    return <div className={cssClasses}>{children}</div>;
};

interface IGridProps {
    children: ReactNode;
    fullHeight?: boolean;
    alignCenter?: boolean;
    margin?: boolean;
    alignCenterVertical?: boolean;
}

export default Grid;
