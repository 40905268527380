import { useContext, useEffect } from "react";
import { map } from "rxjs";
import {
    IApplianceCondition,
    IAverageJobTime,
    IComplianceDashboard,
    IEicrStatus,
    IFuelType,
    IFuelTypeRecentWork,
    IManualUpload,
    INetPromoterScore,
    IRecentTasks,
    IRecentWork,
    IServiceDateResets,
    ITotalJob,
} from ".";
import { PropertyCategoriesContext, UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiValue from "../useApiValue";

const useComplianceDashboard = () => {
    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);
    const { value, loaded, send } = useApiValue<IComplianceDashboard>({});
    const sendRequest = useXTagApiRequest();

    useEffect(() => {
        const subscription = send(
            sendRequest<IComplianceDashboardResponse[], unknown>({
                url: `${config.miscApiUrl}/dashboards`,
                method: "GET",
                urlParams: getGlobalUrlParameters(
                    selectedCategories.map((c) => c.id),
                    activeUserParentsIds,
                ),
            }).pipe(
                map((response) => {
                    const totalJobs: ITotalJob[] = [];
                    const totalSubJobs: ITotalJob[] = [];
                    const netPromoterScores: INetPromoterScore[] = [];
                    const averageJobTimes: IAverageJobTime[] = [];
                    const averageSubJobTimes: IAverageJobTime[] = [];
                    const applianceConditions: IApplianceCondition[] = [];
                    const recentWorks: IFuelTypeRecentWork[] = [];
                    const manualUploads: IManualUpload[] = [];
                    const serviceDateResets: IServiceDateResets[] = [];
                    const recentTasks: IRecentTasks[] = [];
                    const eicrStatus: IEicrStatus[] = [];

                    for (const current of response) {
                        totalJobs.push(current.totalJobs);
                        netPromoterScores.push(current.netPromoterScore);
                        averageJobTimes.push(current.averageJobTime);
                        applianceConditions.push(current.applianceCondition);
                        recentWorks.push({
                            ...current.recentWorks,
                            fuelType: current.fuelType,
                        });
                        recentTasks.push(current.recentTasks);
                        manualUploads.push(current.manualUploads);
                        serviceDateResets.push(current.serviceDateResets);
                        eicrStatus.push(current.eicrStatus);

                        if (current.averageSubJobTime) {
                            averageSubJobTimes.push(current.averageSubJobTime);
                        }

                        if (current.totalSubJobs) {
                            totalSubJobs.push(current.totalSubJobs);
                        }
                    }

                    const dashboard: IComplianceDashboard = {
                        totalJobs,
                        totalSubJobs,
                        netPromoterScores,
                        averageJobTimes,
                        averageSubJobTimes,
                        applianceConditions,
                        recentWorks,
                        recentTasks,
                        manualUploads,
                        serviceDateResets,
                        eicrStatus,
                    };

                    return dashboard;
                }),
            ),
        ).subscribe();

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [activeUserParentsIds, selectedCategories, send, sendRequest]);

    return { value, loaded };
};

interface IComplianceDashboardResponse {
    totalJobs: ITotalJob;
    totalSubJobs: ITotalJob | null;
    netPromoterScore: INetPromoterScore;
    averageJobTime: IAverageJobTime;
    averageSubJobTime: IAverageJobTime | null;
    applianceCondition: IApplianceCondition;
    recentWorks: IRecentWork;
    recentTasks: IRecentTasks;
    manualUploads: IManualUpload;
    serviceDateResets: IServiceDateResets;
    eicrStatus: IEicrStatus;
    landlordId: number;
    fuelType: IFuelType;
}

export default useComplianceDashboard;
