import { useTranslation } from "react-i18next";
import { NavLink as ReactNavLink } from "react-router-dom";
import { INavLinkProps } from ".";
import { useCssClasses } from "../../hooks";
import styles from "./NavLink.module.scss";

const NavLink = ({ url, title, position, end }: INavigationLinkProps) => {
    const { t } = useTranslation();
    const cssClasses = useCssClasses(
        styles.link,
        position === "TopMenu" ? styles.topMenuLink : styles.leftMenuLink,
    );

    return (
        <ReactNavLink
            to={url}
            className={({ isActive }) =>
                `${cssClasses}${isActive ? ` ${styles.active}` : ""}`
            }
            end={end}
        >
            {t(title)}
        </ReactNavLink>
    );
};

interface INavigationLinkProps extends INavLinkProps {
    position: "TopMenu" | "SideMenu";
}

export default NavLink;
