import { Observable } from "rxjs";
import { ITableColumn } from "..";
import TableHeader from "./TableHeader";

export interface IColumn {
    [key: string]: {
        visible: boolean;
        title: string;
    };
}

export interface IVisibleColumn {
    key: string;
    title: string;
}

export interface ITableHeaders {
    totalRecords: number;
    applySearch: (query: string) => void;
    getAllRecords: () => Observable<any[]>;
    canDownloadCsv?: boolean;
    // TODO: Remove once table csv lambda will handle all tables
    alternateCsvFunction?: (visibleColumns: IVisibleColumn[]) => void;
}

export interface ITableHeaderProps extends ITableHeaders {
    columnsConfig: { [key: string]: ITableColumn<any> };
    columns: IColumn;
    visibleColumns: string[];
    handleToggleColumn: (checked: boolean, column: string) => void;
}

export default TableHeader;
