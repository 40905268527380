import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ITagsTableProps } from ".";
import { ITag } from "../../utils/api/properties";
import AuthorizedImage from "../AuthorizedImage";
import SimpleTable, { IColumn } from "../SimpleTable";

const TagsTable = ({ tags }: ITagsTableProps) => {
    const { t } = useTranslation();

    const columns = useMemo<IColumn[]>(() => {
        const imageRender = (value: string) => (
            <AuthorizedImage
                circle={true}
                image={value}
                size={50}
                cssRules={{ margin: "10px 0" }}
            />
        );
        const locationRender = (value: string, row: ITag) => {
            return (
                <>
                    {row.otherTagLocation
                        ? row.otherTagLocation
                        : row.tagLocation}
                </>
            );
        };

        return [
            {
                title: t(""),
                path: "imageUrl",
                type: "component",
                render: imageRender,
            },
            {
                title: t("Installation Date"),
                path: "installedDate",
                type: "date",
            },
            {
                title: t("Location"),
                path: "location",
                type: "component",
                render: locationRender,
            },
            {
                title: t("Installer"),
                path: "engineer.name",
                type: "string",
            },
            {
                title: t("Reason"),
                path: "installReason",
                type: "string",
            },
        ];
    }, [t]);

    return <SimpleTable columns={columns} data={tags} withHeaders={true} />;
};

export default TagsTable;
