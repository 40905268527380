import { IIssueTitleProps } from ".";
import { Card } from "../../../components";
import { useTranslation } from "react-i18next";
import { startCase } from "lodash";

const IssueTitle = ({ type, issue }: IIssueTitleProps) => {
    const { t } = useTranslation();
    return (
        <Card title={t(startCase(type)) + `# ${issue.id}`}>
            {t(issue.type.displayName)} - {t(issue.description)}
        </Card>
    );
};

export default IssueTitle;
