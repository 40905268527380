import { CSSProperties, ReactNode } from "react";
import { Display } from "../Img/Img";
import AuthorizedImage from "./AuthorizedImage";

export interface IAuthorizedImageProps {
    image: string | null;
    testId?: string;
    size?: number;
    cssRules?: CSSProperties;
    height?: number;
    circle?: boolean;
    display?: Display;
    children?: ReactNode;
}

export default AuthorizedImage;
