import { useTranslation } from "react-i18next";
import { Button, Modal, Portal } from "../../../components";
import ModalBody from "../../../components/Modal/ModalBody";
import ModalFooter from "../../../components/Modal/ModalFooter";
import { IAllocatedJob } from "../../../utils/api/allocatedJobs";
import {
    ISimpleJob,
    IUnableToAccess,
    useAllUnableToAccessList,
    usePropertyJobs,
} from "../../../utils/api/jobs";
import { addDays } from "../../../utils/dates";
import MatchJobRow from "./MatchJobRow";
import MatchUtaRow from "./MatchUtaRow";
import styles from "./ReconcileJobModal.module.scss";

const ReconcileJobModal = ({
    allocatedJob,
    hide,
    showJobDetails,
    matchJob,
    matchUta,
}: IReconcileJobModalProps) => {
    const { t } = useTranslation();
    const jobs = usePropertyJobs({
        propertyId: allocatedJob.property.id.toString(),
        jobDate: addDays(new Date(allocatedJob.jobDate), -3).toISOString(),
    });
    const utas = useAllUnableToAccessList(allocatedJob.property.id.toString(), {
        utaDate: addDays(new Date(allocatedJob.jobDate), -3).toISOString(),
    });

    return (
        <Portal>
            <Modal hide={hide} title={t("Recent visits to this property")}>
                <ModalBody>
                    <div>
                        {(utas.records.length > 0 ||
                            jobs.records.length > 0) && (
                            <div className={styles.matchesTitle}>
                                {t("Matches for missed job at")}&nbps;
                                {allocatedJob.property.addressString}
                            </div>
                        )}

                        <div className={styles.typeHeader}>{t("Jobs")}</div>
                        {jobs.records.length > 0
                            ? jobs.records.map((job: ISimpleJob) => {
                                  return (
                                      <MatchJobRow
                                          key={job.id}
                                          job={job}
                                          matchJob={matchJob}
                                      />
                                  );
                              })
                            : t(
                                  "The have been no jobs for this property within the last 3 days",
                              )}

                        <div className={styles.typeHeader}>
                            {t("Unable To Accesses")}
                        </div>
                        {utas.records.length > 0
                            ? utas.records.map((uta: IUnableToAccess) => {
                                  return (
                                      <MatchUtaRow
                                          key={uta.id}
                                          uta={uta}
                                          matchUta={matchUta}
                                      />
                                  );
                              })
                            : t(
                                  "The have been no unable to accesses for this property within the last 3 days",
                              )}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button variant="primary" onClick={showJobDetails}>
                        {t("Job Details")}
                    </Button>
                </ModalFooter>
            </Modal>
        </Portal>
    );
};

interface IReconcileJobModalProps {
    allocatedJob: IAllocatedJob;
    hide: () => void;
    showJobDetails: () => void;
    matchJob: (jobId: number) => void;
    matchUta: (utaId: number) => void;
}

export default ReconcileJobModal;
