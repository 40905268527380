import { ChangeEvent, ReactNode, RefObject, useCallback } from "react";
import { Icon } from "..";
import { useCssClasses } from "../../hooks";
import { useHtmlEntityId } from "../../hooks/useHtmlEntityId";
import ValidationError from "../ValidationError";
import styles from "./Checkbox.module.scss";

function Checkbox<T>(props: ICheckboxProps<T>) {
    const {
        onChange,
        disabled,
        checked,
        className,
        value = null as unknown as T,
        useMargin = true,
        displayInline,
        isValid,
        error,
        setRef,
    } = props;
    const ariaLabel = "ariaLabel" in props ? props.ariaLabel : undefined;
    const children = "children" in props ? props.children : undefined;

    const id = useHtmlEntityId();

    const containerCssClasses = useCssClasses(
        useMargin ? styles.margin : "",
        displayInline ? styles.inline : "",
        className ?? "",
    );

    const handleChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>): void => {
            if (!disabled) {
                onChange(event.target.checked, value);
            }
        },
        [disabled, onChange, value],
    );

    const cssClasses = useCssClasses(
        styles.checkbox,
        checked ? styles.checked : "",
    );

    return (
        <div className={containerCssClasses} ref={setRef}>
            <div className={cssClasses}>
                <input
                    id={id}
                    className={styles.input}
                    type="checkbox"
                    checked={checked}
                    onChange={handleChange}
                    aria-label={ariaLabel}
                />

                {checked && (
                    <span className={styles.checkedIcon}>
                        <Icon icon="check" ariaHidden={true} size={18} />
                    </span>
                )}
            </div>

            {children && (
                <>
                    &nbsp;<label htmlFor={id}>{children}</label>
                </>
            )}
            <ValidationError isValid={isValid} error={error} />
        </div>
    );
}

interface ICheckboxBaseProps<T> {
    value?: T;
    useMargin?: boolean;
    disabled?: boolean;
    checked: boolean;
    isValid?: boolean;
    error?: string;
    displayInline?: boolean;
    setRef?: RefObject<HTMLDivElement>;
    onChange(checked: boolean, value?: T): void;
    className?: string;
}

interface ICheckboxChildrenProps<T> extends ICheckboxBaseProps<T> {
    children: ReactNode;
}

interface ICheckboxAriaLabelProps<T> extends ICheckboxBaseProps<T> {
    ariaLabel: string;
}

type ICheckboxProps<T> = ICheckboxChildrenProps<T> | ICheckboxAriaLabelProps<T>;

export default Checkbox;
