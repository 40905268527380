import { IValidationResult } from ".";

const isTime = (error = "Please type a time in 24 hour format.") => {
    const validate = (value: string): IValidationResult => {
        const isValid = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(value);

        return {
            isValid,
            error: isValid ? "" : error,
        };
    };

    return { validate };
};

export default isTime;
