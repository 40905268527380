import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ITenantsCardProps } from ".";
import EditableList from "../../../components/EditableList";
import { IEditableListItemFormProps } from "../../../components/EditableList/EditableListItem";
import { useHasUserParentPermission } from "../../../hooks";
import { ITenant, useDeleteTenantRequest } from "../../../utils/api/properties";
import TenantForm from "../TenantForm";

const TenantsCard = ({
    propertyId,
    userParentId,
    tenants,
    onCreate,
    onUpdate,
    onDelete,
}: ITenantsCardProps) => {
    const { t } = useTranslation();
    const canEditProperties = useHasUserParentPermission(
        "edit_properties",
        userParentId,
    );

    const getTenantId = useCallback(
        (tenant: ITenant) => tenant.id.toString(),
        [],
    );

    const deleteTenantRequest = useDeleteTenantRequest(propertyId);

    const renderForm = useCallback(
        (props: IEditableListItemFormProps<ITenant>) => (
            <TenantForm propertyId={propertyId} {...props} />
        ),
        [propertyId],
    );

    const renderView = useCallback(
        (entity: ITenant) => (
            <>
                <div>{entity.name}</div>
                <div>{entity.email}</div>
                <div>{entity.phone}</div>
                <div>{entity.residentNumber}</div>
            </>
        ),
        [],
    );

    return (
        <EditableList
            deleteModalTitle={t("Delete tenant")}
            deleteModalMessage={t(
                "Are you sure you wish to delete the tenant from this property?",
            )}
            emptyMessage={t("No tenants found")}
            entities={tenants}
            onCreate={onCreate}
            onUpdate={onUpdate}
            onDelete={onDelete}
            getEntityId={getTenantId}
            deleteRequest={deleteTenantRequest}
            renderForm={renderForm}
            renderView={renderView}
            canEdit={canEditProperties}
        />
    );
};

export default TenantsCard;
