import { IValidationResult } from ".";

const maxLength = (
    maximumLength: number,
    error = "You have entered too many characters.",
) => {
    const validate = (value: string): IValidationResult => {
        const isValid = value.length < maximumLength;

        return {
            isValid,
            error: isValid ? "" : error,
        };
    };

    return { validate };
};

export default maxLength;
