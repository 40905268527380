import { IRecentJob } from ".";
import config from "../../../config";
import useFilterable from "../useFilterable";

const useRecentJobs = () => {
    const filterable = useFilterable<IRecentJob>(
        `${config.jobsApiUrl}/recent`,
        {
            params: {
                filters: {
                    recoveryJob: {
                        operator: "{AND}",
                        readOnly: true,
                        filters: [{ function: "=", value: "false" }],
                    },
                    parentJobId: {
                        operator: "{AND}",
                        readOnly: true,
                        filters: [{ function: "=", value: "null" }],
                    },
                },
            },
        },
    );

    return filterable;
};

export default useRecentJobs;
