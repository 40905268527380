import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IJobIssuesProps } from ".";
import { Link } from "../../../components";
import SimpleTable, { IColumn } from "../../../components/SimpleTable";
import styles from "./JobIssues.module.scss";

const JobIssues = ({ issues }: IJobIssuesProps) => {
    const { t } = useTranslation();

    const issueLinkRender = useCallback((value: string, data: any) => {
        return (
            <Link
                key={data.id}
                url={`/issues/${data.id}`}
                openInNewWindow={true}
            >
                {value}
            </Link>
        );
    }, []);

    const statusStyle = useCallback((status: string) => {
        switch (status) {
            case "Unresolved":
                return styles.unresolved;
            case "Resolved":
                return styles.resolved;
            case "Blocked":
                return styles.blocked;
        }
    }, []);

    const issueStatusRender = useCallback(
        (value: string) => {
            return (
                <div className={`${styles.status} ${statusStyle(value)}`}>
                    {t(value)}
                </div>
            );
        },
        [statusStyle, t],
    );

    const columns = useMemo<IColumn[]>(
        () => [
            {
                title: t("Title"),
                path: "title",
                type: "component",
                render: issueLinkRender,
            },
            {
                title: t("Status"),
                path: "status",
                type: "component",
                render: issueStatusRender,
            },
        ],
        [issueLinkRender, issueStatusRender, t],
    );

    return (
        <>
            {issues.length > 0 ? (
                <SimpleTable columns={columns} data={issues} />
            ) : (
                t("No issues found")
            )}
        </>
    );
};

export default JobIssues;
