import { Fragment, useMemo } from "react";
import { IQualifiedSupervisorAuditRowProps } from ".";
import { useOverriddenAnswerComment } from "../../../hooks/useOverriddenAnswerComment";
import useOverriddenAnswerText from "../../../hooks/useOverriddenAnswerText";
import OriginalAnswer from "../OriginalAnswer";
import OverrideAnswers from "../OverrideAnswers";
import OverridenAnswerComment from "../OverridenAnswerComment";
import styles from "./QualifiedSupervisorAuditRow.module.scss";

const QualifiedSupervisorAuditRow = ({
    questionId,
    question,
    answers,
    isReadOnly,
    isEditing,
    errorMessage,
    documentType,
    overriddenAnswers,
    overrideAnswer,
    validateQuestion,
}: IQualifiedSupervisorAuditRowProps) => {
    const answersList = useMemo(() => answers.map((a) => a.answer), [answers]);

    const overriddenAnswersText = useOverriddenAnswerText(
        answersList,
        overriddenAnswers,
    );
    const overriddenAnswerComment = useOverriddenAnswerComment(
        answersList,
        overriddenAnswers,
    );

    return (
        <tr>
            <td>{question}</td>
            <td>
                {answersList.map((answer) => (
                    <Fragment key={answer.id}>
                        <OriginalAnswer
                            answer={answer}
                            allAnswers={answersList}
                        />{" "}
                    </Fragment>
                ))}
            </td>
            <td className={styles.relative}>
                {isEditing ? (
                    <OverrideAnswers
                        questionId={questionId}
                        answers={answersList}
                        errorMessage={errorMessage}
                        documentType={documentType}
                        overriddenAnswers={overriddenAnswers}
                        overrideAnswer={overrideAnswer}
                        validateQuestion={validateQuestion}
                    />
                ) : (
                    <>
                        {overriddenAnswersText}
                        {overriddenAnswerComment && (
                            <OverridenAnswerComment
                                comment={overriddenAnswerComment}
                            />
                        )}
                    </>
                )}
            </td>
            {!isReadOnly && <td />}
        </tr>
    );
};

export default QualifiedSupervisorAuditRow;
