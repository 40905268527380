import { useEffect } from "react";
import { IManualUploadReason } from ".";
import config from "../../../config";
import { useXTagApiRequest } from "../../request";
import useApiValue from "../useApiValue";

export const useManualUploadReasons = () => {
    const { value, send } = useApiValue<IManualUploadReason[]>({
        initialValue: [],
    });
    const sendRequest = useXTagApiRequest();

    useEffect(() => {
        const subscription = send(
            sendRequest({
                url: `${config.miscApiUrl}/lookups/manualuploadreasons`,
                method: "GET",
            }),
        ).subscribe();

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [send, sendRequest]);

    return { value };
};

export default useManualUploadReasons;
