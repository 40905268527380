import { max } from "lodash";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Button,
    Card,
    Grid,
    GridColumn,
    ImageHero,
    InputDatePicker,
    JobTimeline,
    Link,
    Loading,
    Modal,
    Portal,
    Select,
    UserContext,
} from "../../components";
import ApplianceListItem from "../../components/ApplianceListItem";
import {
    EditableDetailsTable,
    IEditableDetailsData,
} from "../../components/EditableDetailsTable";
import IssuesLog from "../../components/IssuesLog";
import ModalBody from "../../components/Modal/ModalBody";
import ModalFooter from "../../components/Modal/ModalFooter";
import config from "../../config";
import { useHasUserParentPermission, useToggle } from "../../hooks";
import {
    IAppliance,
    ISimpleAppliance,
    useAppliancesAtProperty,
    useUpdateAppliance,
} from "../../utils/api/appliances";
import { useIssuesForModel } from "../../utils/api/issues";
import useGenerateAmendedLgsr from "../../utils/api/lgsrs/useGenerateAmendedLgsr";
import { getToday, toDateString } from "../../utils/dates";
import ApplianceImages from "./ApplianceImages";
import ApplianceUtaList from "./ApplianceUtaList";

const BaseAppliance = ({
    appliance,
    getColumns,
    patchUpdateModel,
}: IBaseApplianceProps) => {
    const { t } = useTranslation();
    const appliancesAtProperty = useAppliancesAtProperty(appliance.property.id);
    const updateAppliance = useUpdateAppliance();
    const issues = useIssuesForModel({
        id: appliance.id,
        modelType: "appliance",
    });
    const regenerateLgsr = useGenerateAmendedLgsr();

    const canEditAppliances = useHasUserParentPermission(
        "edit_appliances",
        appliance.property.landlord.userId,
    );

    const [otherAppliances, setOtherAppliances] = useState<ISimpleAppliance[]>(
        [],
    );
    const { visible: editingVisible, toggle: editingToggle } = useToggle();
    const {
        hide: regenerateLGSRModalHide,
        show: regenerateLGSRModalShow,
        visible: regenerateLGSRModalVisible,
    } = useToggle();

    const [fuelType, setFuelType] = useState("");

    const [applianceCondition, setApplianceCondition] = useState("");
    const [installDate, setInstallDate] = useState(getToday());

    const applianceConditionOptions = [
        t("Very Good"),
        t("Good"),
        t("Average"),
        t("Poor"),
        "",
    ];

    const userContext = useContext(UserContext);

    useEffect(() => {
        // Set initial value of dropdowns
        setFuelType(appliance.fuelType);
        setApplianceCondition(appliance.conditionOfAppliance);
        setInstallDate(new Date(appliance.installDate));
    }, [appliance, userContext.activeUserParentsIds]);

    useEffect(() => {
        setOtherAppliances(
            appliancesAtProperty.value.results.filter(
                (app) => app.id !== appliance.id,
            ),
        );
    }, [appliancesAtProperty.value.results, appliance.id]);

    const lastMaintainedDate = useMemo(() => {
        const jobs = appliance && appliance.jobs;

        if (jobs && jobs.length) {
            const date = new Date(max(jobs.map((job) => job.date)) || "");

            return date;
        }

        return null;
    }, [appliance]);

    const nextServiceDate = useMemo(() => {
        return appliance && appliance.nextServiceDate
            ? new Date(appliance.nextServiceDate)
            : null;
    }, [appliance]);

    const cappedDate = useMemo(() => {
        return appliance && appliance.cappedDate
            ? new Date(appliance.cappedDate)
            : null;
    }, [appliance]);

    const lgsrJobs = useMemo(() => {
        if (appliance && appliance.jobs) {
            return appliance.jobs
                .filter((j) => {
                    return j.isLgsrJob;
                })
                .sort(
                    (a, b) =>
                        new Date(b.date).getTime() - new Date(a.date).getTime(),
                );
        } else {
            return [];
        }
    }, [appliance]);

    const saveAppliance = (data: IAppliance) => {
        let applianceUpdateModel: IAppliance = {
            ...data,
            installDate: installDate.toISOString(),
            conditionOfAppliance: applianceCondition,
            fuelType,
        };

        applianceUpdateModel = patchUpdateModel(applianceUpdateModel);

        updateAppliance
            .updateAppliance(appliance.id, applianceUpdateModel)
            .subscribe(() => {
                if (config.isAmendLgsrEnabled && lgsrJobs.length > 0) {
                    regenerateLGSRModalShow();
                }
            });
    };

    const handleRegenerateLgsr = () => {
        regenerateLgsr
            .regenerateLgsr(lgsrJobs[0].id)
            .subscribe(regenerateLGSRModalHide);
    };
    const detailTableColumns: IEditableDetailsData[] = [
        {
            title: t("Type"),
            type: "string",
            path: "type.displayName",
            disabled: true,
        },
        {
            title: t("Location"),
            type: "string",
            path: "location",
        },
        {
            title: t("Last Maintained"),
            type: "date",
            path: "component",
            render: () => {
                return lastMaintainedDate
                    ? toDateString(new Date(lastMaintainedDate))
                    : "";
            },
            disabled: true,
        },
        {
            title: t("Install Date"),
            type: "date",
            path: "component",
            // eslint-disable-next-line react/display-name
            render: () => {
                return editingVisible ? (
                    <InputDatePicker
                        label=""
                        date={installDate}
                        onDateSelected={setInstallDate}
                    />
                ) : (
                    toDateString(installDate)
                );
            },
        },
        {
            title: t("Next Service Date"),
            type: "date",
            path: "component",
            render: () => {
                return nextServiceDate
                    ? toDateString(new Date(nextServiceDate))
                    : "";
            },
            disabled: true,
        },
        {
            title: t("Age"),
            type: "string",
            path: "ageOfAppliance",
            disabled: true,
        },
        {
            title: t("Condition"),
            type: "component",
            path: "conditionOfAppliance",
            // eslint-disable-next-line react/display-name
            render: (value: string) => {
                return editingVisible ? (
                    <Select
                        options={applianceConditionOptions.map((condition) => ({
                            label: condition,
                            value: condition,
                        }))}
                        value={applianceCondition}
                        onChange={setApplianceCondition}
                    />
                ) : (
                    value
                );
            },
        },
        {
            title: t("Serial Number"),
            type: "string",
            path: "serialNumber",
            disabled: true,
        },
        {
            title: t("Capped Date"),
            type: "date",
            path: "component",
            render: () => {
                return cappedDate
                    ? toDateString(new Date(cappedDate))
                    : "Not Capped";
            },
            disabled: true,
        },
        {
            title: t("Property"),
            type: "component",
            path: "property.addressString",
            // eslint-disable-next-line react/display-name
            render: (value: string, row: IAppliance) => (
                <Link url={`/management/properties/${row.property.id}`}>
                    {value}
                </Link>
            ),
        },
        {
            title: t("Installed"),
            type: "component",
            path: "isInstalled",
            render: (value: boolean) => (value ? t("Yes") : t("No")),
            disabled: true,
        },
        {
            title: t("Test Outcome"),
            type: "component",
            path: "isTestPassed",
            render: (value: boolean | null) => (value ? t("Pass") : t("Fail")),
            hidden: appliance.isTestPassed === null,
            disabled: true,
        },
        ...getColumns(editingVisible),
    ];

    return (
        <>
            {appliance && issues.loaded ? (
                <Grid>
                    <GridColumn size="twoThirds">
                        <Grid>
                            <GridColumn size="half">
                                <ImageHero
                                    imageUrl={appliance.imageUrl}
                                    title={`${appliance.type.displayName}: ${appliance.displayName}`}
                                    lastMaintainedDate={lastMaintainedDate}
                                />
                            </GridColumn>
                            <GridColumn size="half">
                                <EditableDetailsTable
                                    canEdit={canEditAppliances}
                                    isEditing={
                                        canEditAppliances && editingVisible
                                    }
                                    toggle={editingToggle}
                                    entity={appliance}
                                    title={t("Appliance Details")}
                                    data={detailTableColumns}
                                    onSave={saveAppliance}
                                />
                            </GridColumn>
                        </Grid>
                        <Card title={t("Work History")}>
                            <JobTimeline jobs={appliance.jobs} />
                        </Card>
                    </GridColumn>

                    <GridColumn size="oneThird">
                        <ApplianceImages images={appliance.recentImages} />
                        <Card title={t("Other appliances at property")}>
                            {otherAppliances.map((otherAppliance) => (
                                <ApplianceListItem
                                    key={otherAppliance.id}
                                    appliance={otherAppliance}
                                />
                            ))}
                        </Card>
                        <IssuesLog issues={issues.records} />

                        <ApplianceUtaList
                            propertyId={appliance.property.id}
                            applianceId={appliance.id}
                        />
                    </GridColumn>
                </Grid>
            ) : (
                <Loading />
            )}

            {regenerateLGSRModalVisible && (
                <Portal>
                    <Modal
                        title={t("Regenerate LGSR")}
                        hide={regenerateLGSRModalHide}
                    >
                        <ModalBody>
                            <p>
                                {t(
                                    "Would you like to regenerate the last LGSR that this appliance was inspected in?",
                                )}
                            </p>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                cssRules={{ marginRight: "10px" }}
                                variant="primary"
                                onClick={handleRegenerateLgsr}
                            >
                                {t("Yes")}
                            </Button>
                            <Button onClick={regenerateLGSRModalHide}>
                                {t("No")}
                            </Button>
                        </ModalFooter>
                    </Modal>
                </Portal>
            )}
        </>
    );
};

interface IBaseApplianceProps {
    appliance: IAppliance;
    getColumns: (editingToggle: boolean) => IEditableDetailsData[];
    patchUpdateModel: (appliance: IAppliance) => IAppliance;
}

export default BaseAppliance;
