import { IEventLog } from ".";
import config from "../../../config";
import useFilterable, { SortDirection } from "../useFilterable";

export const useEventLogs = ({
    sortProperty = "",
    sortDirection = "asc",
}: ISortParameters) => {
    const filterable = useFilterable<IEventLog>(`${config.miscApiUrl}/events`, {
        params: {
            sortProperty,
            sortDirection,
        },
    });

    return filterable;
};

interface ISortParameters {
    sortProperty?: string;
    sortDirection?: SortDirection;
}
