import { ILandlordEngineer } from ".";
import config from "../../../config";
import useFilterable, { IFilterableParams } from "../useFilterable";

const useEngineers = (params?: IFilterableParams) => {
    const filterable = useFilterable<ILandlordEngineer>(
        `${config.landlordsApiUrl}/engineers`,
        {
            params,
            disableFuelTypeFiltering: true,
        },
    );

    return filterable;
};

export default useEngineers;
