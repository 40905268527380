import { useCallback, useContext } from "react";
import { UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

export const useResetUserPassword = () => {
    const { send, loading } = useApiRequest({});
    const { activeUserParentsIds } = useContext(UserContext);
    const sendRequest = useXTagApiRequest();

    const resetUserPassword = useCallback(
        (userId: number) =>
            send(
                sendRequest({
                    url: `${config.usersApiUrl}/v2/${userId}/reset-password`,
                    method: "POST",
                    urlParams: getGlobalUrlParameters([], activeUserParentsIds),
                }),
            ),
        [activeUserParentsIds, send, sendRequest],
    );

    return { resetUserPassword, loading };
};
