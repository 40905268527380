import { IContractor } from "../../../utils/api/landlords";
import AssignContractorsModal from "./AssignContractorsModal";

export interface IAssignContractorsModalProps {
    propertyIds: number[];
    complianceTypes: IAssignContractorsComplianceType[];
    contractors: IContractor[];
    hide: () => void;
}

export interface IAssignContractorsComplianceType {
    id: number;
    displayName: string;
    colour: string;
}

export default AssignContractorsModal;
