import { ReactNode, useEffect, useRef } from "react";
import { Loading } from "..";
import { findScrollableAncestor } from "../../utils/scroll";
import styles from "./InfiniteScroll.module.scss";

const InfiniteScroll = ({
    children,
    loading,
    onScrollToBottom,
}: IInfiniteScrollProps) => {
    const targetElement = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (targetElement.current !== null) {
            const rootElement = findScrollableAncestor(targetElement.current);

            const observer = new IntersectionObserver(
                (entries) => {
                    if (!loading && entries.some((e) => e.isIntersecting)) {
                        onScrollToBottom();
                    }
                },
                {
                    root: rootElement,
                    threshold: 0.1,
                },
            );

            observer.observe(targetElement.current);

            return () => {
                observer.disconnect();
            };
        }
    }, [loading, onScrollToBottom]);

    return (
        <>
            {children}
            <div ref={targetElement} className={styles.triggerElement} />

            <Loading visible={loading} />
        </>
    );
};

export interface IInfiniteScrollProps {
    children: ReactNode;
    loading: boolean;
    onScrollToBottom: () => void;
}

export default InfiniteScroll;
