import { IAnswer, IEngineer } from "../../utils/api/answers";
import { ITask } from "../JobTimeline/JobTimelineTask";
import AssetJobTimeline from "./AssetJobTimeline";

export interface IAssetJobTimelineProps {
    assetId: string;
    displayName: string;
}

export interface ITaskMapped {
    jobId: number;
    task: ITask;
    engineer: IEngineer;
    answers: IAnswer[];
    date: string;
    time: string;
}

export default AssetJobTimeline;
