import { useTranslation } from "react-i18next";
import { IAppliancesTabProps } from ".";
import { Loading } from "../../../components";
import ApplianceListItem from "../../../components/ApplianceListItem";
import useSingleSelectedPropertyCategory from "../../../hooks/useSingleSelectedPropertyCategory";
import { useAppliancesAtProperty } from "../../../utils/api/appliances";
import AssetsGroupedList from "../AssetsGroupedList";

const AppliancesTab = ({ propertyId }: IAppliancesTabProps) => {
    const { t } = useTranslation();
    const { value, loaded } = useAppliancesAtProperty(propertyId);

    const category = useSingleSelectedPropertyCategory();

    return (
        <>
            {!loaded ? (
                <Loading />
            ) : value.results.length > 0 ? (
                category?.id === 13 ? (
                    <AssetsGroupedList assets={value.results} />
                ) : (
                    value.results.map((appliance) => (
                        <ApplianceListItem
                            key={appliance.id}
                            appliance={appliance}
                        />
                    ))
                )
            ) : (
                t("No assets found")
            )}
        </>
    );
};

export default AppliancesTab;
