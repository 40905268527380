import { IAllocateJobSimpleModalProps } from ".";
import { useAllEngineers } from "../../utils/api/landlords";
import AllocateJobModal from "../AllocateJobModal";

const AllocateJobSimpleModal = ({
    propertyId,
    propertyCategoryId,
    propertyCategories,
    addressString,
    awaitingAllocationJob,
    hide,
}: IAllocateJobSimpleModalProps) => {
    const { records } = useAllEngineers();

    return (
        <AllocateJobModal
            propertyId={propertyId}
            propertyCategoryId={propertyCategoryId}
            propertyCategories={propertyCategories}
            engineers={records}
            canSelectContractor={awaitingAllocationJob === undefined}
            awaitingAllocationJob={awaitingAllocationJob}
            addressString={addressString}
            hide={hide}
        />
    );
};

export default AllocateJobSimpleModal;
