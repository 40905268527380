import { cloneDeep } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { ITableColumn } from "../";
import { useCssClasses } from "../../../hooks";
import { getValueFromObject } from "../../../utils/values";
import TableCell from "../TableCell";
import styles from "./TableRow.module.scss";

const TableRow = ({
    record,
    isCheckboxColumnVisible,
    columns,
    visibleColumns,
    rowSelectRender,
}: ITableRowProps) => {
    const [rec, setRec] = useState(record);

    const setRowProperty = useCallback((row: any) => {
        setRec(cloneDeep(row));
    }, []);

    const cssClasses = useCssClasses(styles.row);

    const rowMemo = useMemo(() => {
        return (
            <tr className={cssClasses}>
                {isCheckboxColumnVisible && (
                    <TableCell
                        currentRow={rec}
                        currentValue={rec.id}
                        render={rowSelectRender}
                    />
                )}
                {visibleColumns.map((key) => {
                    const column = columns[key];
                    const property = column.field || key;
                    const value = getValueFromObject(property, rec);

                    return (
                        <TableCell
                            key={key}
                            currentRow={rec}
                            currentValue={value}
                            type={column.type}
                            render={column.render}
                            separator={column.separator}
                            set={setRowProperty}
                        />
                    );
                })}
            </tr>
        );
    }, [
        cssClasses,
        isCheckboxColumnVisible,
        rec,
        rowSelectRender,
        visibleColumns,
        columns,
        setRowProperty,
    ]);

    return rowMemo;
};

interface ITableRowProps {
    record: any;
    isCheckboxColumnVisible: boolean;
    columns: { [key: string]: ITableColumn<any> };
    visibleColumns: string[];
    rowSelectRender: (id: number) => JSX.Element;
    updateRecordProperty?: () => void;
}

export default TableRow;
