import { useContext, useEffect } from "react";
import { ICount } from ".";
import { PropertyCategoriesContext, UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiValue from "../useApiValue";

const useAwaitingAllocationJobCount = ({
    jobPriorities,
    jobStatus,
    startDate,
    endDate,
    completedWithJob,
    slaMet,
    uta,
}: IUseAwaitingAllocationJobCountProps) => {
    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);
    const { value, loaded, send } = useApiValue<ICount>({});
    const sendRequest = useXTagApiRequest();

    useEffect(() => {
        const subscription = send(
            sendRequest({
                url: `${config.allocatedJobsApiUrl}/awaitingallocation/v2/count`,
                method: "GET",
                urlParams: {
                    ...(uta !== undefined && {
                        uta: [`=${uta}`],
                    }),
                    ...(slaMet !== undefined && {
                        slaMet: [`=${slaMet}`],
                    }),
                    ...(completedWithJob !== undefined && {
                        completedWithJob: [`=${completedWithJob}`],
                    }),
                    ...(!jobPriorities.includes("All") && {
                        jobPriority: [
                            jobPriorities.map((j) => `=${j}`).join("{OR}"),
                        ],
                    }),
                    ...(jobStatus !== undefined && {
                        status: [jobStatus.map((j) => `=${j}`).join("{OR}")],
                    }),
                    ...(startDate !== undefined && endDate !== undefined
                        ? {
                              slaDate: [
                                  `>=${startDate.toISOString()}{AND}<${endDate.toISOString()}`,
                              ],
                          }
                        : startDate !== undefined
                        ? { slaDate: [`>=${startDate.toISOString()}`] }
                        : endDate !== undefined
                        ? { slaDate: [`<${endDate.toISOString()}`] }
                        : {}),

                    ...getGlobalUrlParameters(
                        selectedCategories.map((c) => c.id),
                        activeUserParentsIds,
                    ),
                },
            }),
        ).subscribe();

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [
        activeUserParentsIds,
        selectedCategories,
        jobPriorities,
        startDate,
        endDate,
        send,
        completedWithJob,
        slaMet,
        uta,
        jobStatus,
        sendRequest,
    ]);

    return {
        value,
        loaded,
    };
};

export interface IUseAwaitingAllocationJobCountProps {
    jobPriorities: string[];
    startDate?: Date;
    endDate?: Date;
    jobStatus?: string[];
    completedWithJob?: boolean;
    slaMet?: boolean;
    uta?: boolean;
}

export default useAwaitingAllocationJobCount;
