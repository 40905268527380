import { useCallback, useContext } from "react";
import { IAuditComment } from ".";
import { PropertyCategoriesContext, UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const useAuditComment = (id: number, isRemedial: boolean) => {
    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);
    const { send, loading } = useApiRequest<IAuditComment>({});
    const sendRequest = useXTagApiRequest();

    const saveComment = useCallback(
        (comment: string) =>
            send(
                sendRequest({
                    url: `${config.auditsApiUrl}/${id}/comment`,
                    method: "POST",
                    urlParams: getGlobalUrlParameters(
                        selectedCategories.map((c) => c.id),
                        activeUserParentsIds,
                    ),
                    body: { isRemedial, comment },
                }),
            ),
        [
            activeUserParentsIds,
            id,
            isRemedial,
            selectedCategories,
            send,
            sendRequest,
        ],
    );

    return { saveComment, loading };
};

export default useAuditComment;
