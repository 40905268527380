import { useTranslation } from "react-i18next";
import { Card, TabGroup, TabGroupTab } from "../../components";
import { useIntegrationApprovalInboxNumbers } from "../../utils/api/misc";
import IntegrationApprovalTable from "./IntegrationApprovalTable";
import IntegrationFailuresTable from "./IntegrationFailuresTable";

const IntegrationApproval = () => {
    const { t } = useTranslation();
    const inboxNumbers = useIntegrationApprovalInboxNumbers();

    return (
        <Card title={t("Integration Approval")} padding={false}>
            <TabGroup>
                <TabGroupTab
                    header={
                        <>
                            {t("Integration Failure")}&nbsp;
                            {inboxNumbers.loaded &&
                                inboxNumbers.value.integrationFailedCount > 0 &&
                                `(${inboxNumbers.value.integrationFailedCount})`}
                        </>
                    }
                >
                    <IntegrationFailuresTable />
                </TabGroupTab>
                <TabGroupTab
                    header={
                        <>
                            {t("Manual Hold")}&nbsp;
                            {inboxNumbers.loaded &&
                                inboxNumbers.value.integrationApprovalCount >
                                    0 &&
                                `(${inboxNumbers.value.integrationApprovalCount})`}
                        </>
                    }
                >
                    <IntegrationApprovalTable status="Manual Hold" />
                </TabGroupTab>
                <TabGroupTab
                    header={
                        <>
                            {t("Reviewed & declined")}&nbsp;
                            {inboxNumbers.loaded &&
                                inboxNumbers.value.integrationDeclinedCount >
                                    0 &&
                                `(${inboxNumbers.value.integrationDeclinedCount})`}
                        </>
                    }
                >
                    <IntegrationApprovalTable status={"Reviewed & Declined"} />
                </TabGroupTab>
            </TabGroup>
        </Card>
    );
};

export default IntegrationApproval;
