import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import useDownloadPaperwork from "../../hooks/useDownloadPaperwork";
import { ISimplePaperwork } from "../../utils/api/jobs";
import Dropdown, { IDropdownItem } from "../Dropdown";

const JobPaperworkDropdown = ({
    paperwork,
    disableFuelTypeCheck,
}: IJobPaperworkDropdownProps) => {
    const { t } = useTranslation();
    const downloadPaperwork = useDownloadPaperwork();

    const items = useMemo<IDropdownItem[]>(
        () =>
            paperwork.map((item) => ({
                label: item.documentName,
                value: item,
            })),
        [paperwork],
    );

    const handleClick = (item: ISimplePaperwork) => {
        downloadPaperwork
            .download(item.id, item.documentUrl, disableFuelTypeCheck)
            .subscribe();
    };

    return (
        <Dropdown label={t("Documents")} onClick={handleClick} items={items} />
    );
};

interface IJobPaperworkDropdownProps {
    paperwork: ISimplePaperwork[];
    disableFuelTypeCheck?: boolean;
}

export default JobPaperworkDropdown;
