import { useCallback, useContext } from "react";
import { UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const useCreateIssue = () => {
    const { activeUserParentsIds } = useContext(UserContext);
    const { send, loading } = useApiRequest({});
    const sendRequest = useXTagApiRequest();

    const createIssue = useCallback(
        (issue: ICreateIssue) =>
            send(
                sendRequest({
                    url: config.issuesApiUrl,
                    method: "POST",
                    urlParams: getGlobalUrlParameters([], activeUserParentsIds),
                    body: issue,
                }),
            ),
        [activeUserParentsIds, send, sendRequest],
    );

    return { createIssue, loading };
};

interface ICreateIssue {
    title: string;
    description: string;
    jobId?: number;
    propertyId?: number;
    assignedUserId?: number;
    fuelTypeId?: number;
    landlordId: number;
    notificationType: string;
    notificationCategory: string;
}

export default useCreateIssue;
