import { useCallback, useContext } from "react";
import { IUpdateLandlord } from ".";
import { UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const useUpdateLandlord = () => {
    const { activeUserParentsIds } = useContext(UserContext);
    const { send, loading } = useApiRequest({});
    const sendRequest = useXTagApiRequest();

    const update = useCallback(
        (id: number, landlord: IUpdateLandlord) =>
            send(
                sendRequest({
                    url: `${config.landlordsApiUrl}/${id}`,
                    method: "PUT",
                    urlParams: getGlobalUrlParameters([], activeUserParentsIds),
                    body: landlord,
                }),
            ),
        [activeUserParentsIds, send, sendRequest],
    );

    return { update, loading };
};

export default useUpdateLandlord;
