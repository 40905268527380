import { useCallback, useContext } from "react";
import { IAllocatedJob } from ".";
import { UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const useUpdateAllocatedJob = () => {
    const { activeUserParentsIds } = useContext(UserContext);
    const { loading, send } = useApiRequest({});
    const sendRequest = useXTagApiRequest();

    const updateAllocatedJob = useCallback(
        (id: string, job: IAllocatedJob) =>
            send(
                sendRequest({
                    url: `${config.allocatedJobsApiUrl}/${id}`,
                    method: "PUT",
                    urlParams: getGlobalUrlParameters([], activeUserParentsIds),
                    body: job,
                }),
            ),
        [activeUserParentsIds, send, sendRequest],
    );

    return { loading, updateAllocatedJob };
};

export default useUpdateAllocatedJob;
