import { RefObject } from "react";
import PhaseLimitsItem from "./PhaseLimitsItem";

interface IPhaseLimitsItemBaseProps {
    colour: string;
    isEditing: boolean;
}

interface IPhaseLimitsItemOptionProps extends IPhaseLimitsItemBaseProps {
    hide: () => void;
    updateColour: (colour: string) => void;
    isSelected: boolean;
    isInline: true;
}

interface IPhaseLimitsItemActiveProps extends IPhaseLimitsItemBaseProps {
    onClick: () => void;
    setRef: RefObject<HTMLDivElement>;
    testId: string;
}

export type IPhaseLimitsItemProps =
    | IPhaseLimitsItemOptionProps
    | IPhaseLimitsItemActiveProps;

export default PhaseLimitsItem;
