import { useContext, useEffect } from "react";
import { IDeliveryPercentageByPriority } from ".";
import { PropertyCategoriesContext, UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiValue from "../useApiValue";

const useAwaitingAllocationDeliveryPercentageByPriority = ({
    jobPriorities,
    startDate,
    endDate,
    completedWithJob,
}: IuseAwaitingAllocationDeliveryPercentageByPriorityProps) => {
    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);
    const { value, loaded, send } = useApiValue<
        IDeliveryPercentageByPriority[]
    >({});
    const sendRequest = useXTagApiRequest();

    useEffect(() => {
        const subscription = send(
            sendRequest({
                url: `${config.allocatedJobsApiUrl}/awaitingallocation/v2/count/priorities/totals`,
                method: "GET",
                urlParams: {
                    ...(!jobPriorities.includes("All") && {
                        jobPriority: [
                            jobPriorities.map((j) => `=${j}`).join("{OR}"),
                        ],
                    }),
                    slaDate: [
                        `>=${startDate.toISOString()}{AND}<${endDate.toISOString()}`,
                    ],
                    status: ["=Completed"],
                    ...(completedWithJob !== undefined && {
                        completedWithJob: [`=${completedWithJob}`],
                    }),
                    ...getGlobalUrlParameters(
                        selectedCategories.map((c) => c.id),
                        activeUserParentsIds,
                    ),
                },
            }),
        ).subscribe();

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [
        activeUserParentsIds,
        selectedCategories,
        jobPriorities,
        startDate,
        endDate,
        send,
        completedWithJob,
        sendRequest,
    ]);

    return {
        value,
        loaded,
    };
};

export interface IuseAwaitingAllocationDeliveryPercentageByPriorityProps {
    jobPriorities: string[];
    startDate: Date;
    endDate: Date;
    completedWithJob?: boolean;
}

export default useAwaitingAllocationDeliveryPercentageByPriority;
