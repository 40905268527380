import QualifiedSupervisorAuditsModal from "./QualifiedSupervisorAuditsModal";

export interface IQualifiedSupervisorAuditsModalProps {
    auditId: number;
    isReadOnly: boolean;
    hide: () => void;
    auditNext: () => void;
    addAdditionalAudits: (ids: number[]) => void;
}

export default QualifiedSupervisorAuditsModal;
