import { IDrawableObject } from ".";
import { colours } from "../../styles/colours";
import { animate } from "./animation";
import { bounce } from "./animations";

const mapValueToPosition = (
    value: number,
    minValue: number,
    maxValue: number,
    minPosition: number,
    maxPosition: number,
) => {
    const mappedValue =
        minPosition +
        ((maxPosition - minPosition) / (maxValue - minValue)) *
            (value - minValue);

    return minPosition + maxPosition - mappedValue;
};

const offsetMinValue = (
    minValue: number,
    maxValue: number,
    yAxisLinesCount: number,
    valuesStep: number,
) => {
    const calculatedMaxValue = minValue + valuesStep * (yAxisLinesCount - 1);
    const offsetedMinValue =
        minValue - Math.floor((calculatedMaxValue - maxValue) / 2);

    return offsetedMinValue < 0 && minValue >= 0 ? 0 : offsetedMinValue;
};

const getYAxisLines = (
    minValue: number,
    maxValue: number,
    yAxisLinesCount: number,
    gridHeight: number,
) => {
    if (maxValue === minValue) {
        minValue -= Math.min(Math.floor(yAxisLinesCount / 2), 0);
        maxValue += Math.floor(yAxisLinesCount / 2);
    }

    // TODO: Add support for decimal step values in valuesStep if values are too close to each other.
    const valuesStep = Math.ceil((maxValue - minValue) / (yAxisLinesCount - 1));
    const positionsStep = gridHeight / yAxisLinesCount;

    minValue = offsetMinValue(minValue, maxValue, yAxisLinesCount, valuesStep);

    const lines: IAxisLine<number>[] = [];
    for (let i = 0; i < yAxisLinesCount; i++) {
        const value = minValue + valuesStep * (yAxisLinesCount - i - 1);

        lines.push({
            position: positionsStep * (i + 1),
            value,
        });
    }

    return {
        lines,
        minValue,
        maxValue: minValue + valuesStep * (yAxisLinesCount - 1),
    };
};

const getXAxisLines = (
    labels: string[],
    gridWidth: number,
    leftPadding: number,
    gridType: GridType,
) => {
    const divisor =
        gridType === "bar" ? labels.length : Math.max(labels.length - 1, 1);

    const lines: IAxisLine<string>[] = labels.map((value, index) => ({
        value,
        position: (index * gridWidth) / divisor + leftPadding,
    }));

    return lines;
};

const drawYAxis = (
    context: CanvasRenderingContext2D,
    drawLines: boolean,
    drawLabels: boolean,
    lines: IAxisLine<number>[],
    gridWidth: number,
    gridHeight: number,
    leftPadding: number,
    animation: (value: number, startValue?: number) => number,
) => {
    lines.forEach((line) => {
        const y = animation(line.position, gridHeight);

        if (drawLines) {
            context.save();
            context.beginPath();
            context.moveTo(leftPadding, y);
            context.lineTo(gridWidth + leftPadding, y);
            context.strokeStyle = colours.chart.lightGrey;
            context.setLineDash([3, 3]);
            context.stroke();
            context.restore();
        }

        if (drawLabels) {
            context.save();
            context.fillStyle = colours.chart.grey;
            context.textBaseline = "bottom";
            context.textAlign = "right";
            context.fillText(line.value.toString(), leftPadding - 5, y + 2);
            context.restore();
        }
    });
};

const drawXAxis = (
    context: CanvasRenderingContext2D,
    drawLines: boolean,
    drawLabels: boolean,
    lines: IAxisLine<string>[],
    gridHeight: number,
    leftPadding: number,
    labelOffset: number,
    animation: (value: number, startValue?: number) => number,
) => {
    lines.forEach((line) => {
        if (drawLines) {
            const x = animation(line.position, leftPadding);

            context.save();
            context.beginPath();
            context.moveTo(x, 0);
            context.lineTo(x, gridHeight);
            context.strokeStyle = colours.chart.lightGrey;
            context.setLineDash([3, 3]);
            context.stroke();
            context.restore();
        }

        if (drawLabels) {
            const x = animation(line.position + labelOffset, leftPadding);

            context.save();
            context.fillStyle = colours.chart.grey;
            context.textAlign = "center";
            context.fillText(line.value, x, gridHeight + 18);
            context.restore();
        }
    });
};

const getGrid = ({
    minValue,
    maxValue,
    labels,
    gridType,
    leftPadding = 40,
    rightPadding = 20,
    bottomPadding = 20,
    yAxisLinesCount = 5,
    drawXLines = true,
    drawXLabels = true,
    drawYLines = true,
    drawYLabels = true,
}: IGridProps): IDrawableGrid => {
    const animation = animate(1500, bounce);

    let isAnimating = false;
    let hover: IHover | null = null;
    let gridHeight = 0;
    let gridWidth = 0;
    let groupWidth = 0;
    let groupsPositions: number[] = [];
    let labelOffset = 0;
    let hoverWidth = 0;
    let hoverOffset = 0;
    let yAxisLines: IYAxisLines = {
        lines: [],
        minValue,
        maxValue,
    };
    let xAxisLines: IAxisLine<string>[] = [];

    const getY = (value: number) => {
        return mapValueToPosition(
            value,
            yAxisLines.minValue,
            yAxisLines.maxValue,
            yAxisLines.lines[0].position,
            gridHeight,
        );
    };

    const getStatus = (): IGrid => {
        return {
            x: leftPadding,
            width: gridWidth,
            height: gridHeight,
            hover,
            axis: {
                x: {
                    positions: groupsPositions,
                    width: groupWidth,
                },
            },
        };
    };

    const onHover = (x: number, y: number) => {
        let requiresUpdate = false;

        if (!isAnimating) {
            let i = 0;

            while (i < groupsPositions.length) {
                if (
                    x >= groupsPositions[i] + hoverOffset &&
                    x <= groupsPositions[i] + hoverOffset + hoverWidth &&
                    y >= 0 &&
                    y <= gridHeight
                ) {
                    if (!hover || i !== hover.group) {
                        hover = {
                            group: i,
                            min: groupsPositions[i] + hoverOffset,
                            max: groupsPositions[i] + hoverOffset + hoverWidth,
                        };

                        requiresUpdate = true;
                    }

                    break;
                }

                i++;
            }

            if (i >= groupsPositions.length && hover !== null) {
                hover = null;
                requiresUpdate = true;
            }
        } else if (hover !== null) {
            hover = null;
            requiresUpdate = true;
        }

        return requiresUpdate;
    };

    const onResize = (width: number, height: number) => {
        gridHeight = height - bottomPadding;
        gridWidth = width - leftPadding - rightPadding;

        yAxisLines = getYAxisLines(
            minValue,
            maxValue,
            yAxisLinesCount,
            gridHeight,
        );

        xAxisLines = getXAxisLines(labels, gridWidth, leftPadding, gridType);
        groupsPositions = xAxisLines.map((line) => line.position);
        groupWidth = gridWidth / xAxisLines.length;

        if (gridType === "bar") {
            labelOffset = groupWidth / 2;
            hoverWidth = groupWidth;
            hoverOffset = 0;
        } else {
            labelOffset = 0;
            hoverWidth = 30;
            hoverOffset = -hoverWidth / 2;
        }
    };

    const draw = (context: CanvasRenderingContext2D, time: number) => {
        isAnimating = animation.updateFrame(time);

        if (drawYLabels || drawYLines) {
            drawYAxis(
                context,
                drawYLines,
                drawYLabels,
                yAxisLines.lines,
                gridWidth,
                gridHeight,
                leftPadding,
                animation.getValue,
            );
        }

        if (drawXLabels || drawXLines) {
            drawXAxis(
                context,
                drawXLines,
                drawXLabels,
                xAxisLines,
                gridHeight,
                leftPadding,
                labelOffset,
                animation.getValue,
            );
        }

        return isAnimating;
    };

    return { draw, onResize, onHover, getStatus, getY };
};

interface IGridProps {
    minValue: number;
    maxValue: number;
    labels: string[];
    gridType: GridType;
    leftPadding?: number;
    rightPadding?: number;
    bottomPadding?: number;
    yAxisLinesCount?: number;
    drawXLines?: boolean;
    drawXLabels?: boolean;
    drawYLines?: boolean;
    drawYLabels?: boolean;
}

interface IYAxisLines {
    lines: IAxisLine<number>[];
    minValue: number;
    maxValue: number;
}

interface IAxisLine<T> {
    value: T;
    position: number;
}

interface IGrid {
    x: number;
    width: number;
    height: number;
    hover: IHover | null;
    axis: {
        x: {
            positions: number[];
            width: number;
        };
    };
}

interface IHover {
    group: number;
    min: number;
    max: number;
}

interface IDrawableGrid extends IDrawableObject {
    onHover: (x: number, y: number) => boolean;
    getStatus: () => IGrid;
    getY: (value: number) => number;
}

type GridType = "line" | "bar";

export default getGrid;
