import { IRealtimeValue } from ".";

function realtimeDeleteList<T extends IRealtimeValue, U extends T>(
    realtimeValue: U,
    currentValue: T[],
) {
    const list = [...currentValue];

    const deleteIndex = list.findIndex((val) => val.id === realtimeValue.id);

    if (deleteIndex !== -1) {
        list.splice(deleteIndex, 1);

        return list;
    }

    return false;
}

export default realtimeDeleteList;
