import { useContext, useEffect } from "react";
import { NotificationCategory } from ".";
import { PropertyCategoriesContext, UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiValue from "../useApiValue";

const useNotificationCount = (
    notificationCategoryType: NotificationCategory,
    read: boolean,
) => {
    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);
    const { send, value } = useApiValue<number>({ initialValue: 0 });
    const sendRequest = useXTagApiRequest();

    useEffect(() => {
        const subscription = send(
            sendRequest({
                url: `${config.miscApiUrl}/notifications/count`,
                method: "GET",
                urlParams: {
                    notificationCategoryId: [
                        notificationCategoryType.toString(),
                    ],
                    read: [read.toString()],
                    ...getGlobalUrlParameters(
                        selectedCategories.map((c) => c.id),
                        activeUserParentsIds,
                    ),
                },
            }),
        ).subscribe();

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [
        activeUserParentsIds,
        notificationCategoryType,
        read,
        selectedCategories,
        send,
        sendRequest,
    ]);

    return { value };
};

export default useNotificationCount;
