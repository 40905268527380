import { IAppliancesProps } from ".";
import { ImageList, Link } from "../../../components";

const Appliances = ({ appliances }: IAppliancesProps) => {
    return (
        <>
            {appliances.map((appliance) => (
                <ImageList key={appliance.id} image={appliance.imageUrl}>
                    <Link
                        url={`/management/appliances/${appliance.id}`}
                        openInNewWindow={true}
                    >
                        {`${appliance.displayName}: ${appliance.make} - ${appliance.model}`}
                    </Link>
                </ImageList>
            ))}
        </>
    );
};

export default Appliances;
