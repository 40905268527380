import { Button } from "../..";
import { useCssClasses } from "../../../hooks";
import AuthorizedImage from "../../AuthorizedImage";
import styles from "./Thumbnail.module.scss";

const Thumbnail = ({
    imageUrl,
    onClick,
    clickParams,
    isCurrent = false,
}: IThumbnailProps) => {
    const cssClasses = useCssClasses(
        styles.container,
        isCurrent ? styles.current : "",
    );

    return (
        <div className={cssClasses}>
            <Button
                onClick={onClick}
                clickParams={clickParams}
                displayAsLink={true}
                displayBlock={true}
            >
                <AuthorizedImage image={imageUrl} size={50} />
            </Button>
        </div>
    );
};

interface IThumbnailProps {
    imageUrl: string;
    isCurrent?: boolean;
    clickParams?: any[];
    onClick?: (...params: any[]) => void;
}

export default Thumbnail;
