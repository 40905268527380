import { useTranslation } from "react-i18next";
import { noop } from "rxjs";
import { IEditableListItemLayoutProps } from ".";
import { Alert, Button, Grid, GridColumn, Icon } from "../..";
import styles from "./EditableListItemLayout.module.scss";

const EditableListItemLayout = ({
    children,
    isEditing,
    loading,
    error,
    canEdit = true,
    onEdit = noop,
    onDelete = noop,
    onCancel = noop,
}: IEditableListItemLayoutProps) => {
    const { t } = useTranslation();

    return (
        <Grid>
            <GridColumn size="eightyPercent">
                {children}

                {error && (
                    <div className={styles.margin}>
                        <Alert type="error">{error}</Alert>
                    </div>
                )}
            </GridColumn>
            <GridColumn size="twentyPercent" cssRules={{ textAlign: "right" }}>
                {canEdit &&
                    (isEditing ? (
                        <>
                            <Button
                                type="submit"
                                variant="primary"
                                disabled={loading}
                            >
                                <Icon
                                    icon="check"
                                    ariaLabel={t("Save")}
                                    size={16}
                                />
                            </Button>

                            <Button
                                cssRules={{
                                    marginLeft: "10px",
                                }}
                                onClick={onCancel}
                            >
                                <Icon
                                    icon="close"
                                    ariaLabel={t("Cancel")}
                                    size={16}
                                />
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button onClick={onEdit}>
                                <Icon
                                    icon="pencil"
                                    ariaLabel={t("Edit")}
                                    size={16}
                                />
                            </Button>
                            <Button
                                onClick={onDelete}
                                variant="error"
                                cssRules={{
                                    marginLeft: "10px",
                                }}
                            >
                                <Icon
                                    icon="remove"
                                    ariaLabel={t("Delete")}
                                    size={16}
                                />
                            </Button>
                        </>
                    ))}
            </GridColumn>
        </Grid>
    );
};

export default EditableListItemLayout;
