import { useEffect } from "react";
import { IAuthenticatedUser } from ".";
import config from "../../../config";
import { useXTagApiRequest } from "../../request";
import useApiValue from "../useApiValue";

const useUser = () => {
    const { value, send, error, loading } = useApiValue<IAuthenticatedUser>({});

    const sendRequest = useXTagApiRequest();

    useEffect(() => {
        const subscription = send(
            sendRequest({
                url: `${config.usersApiUrl}/v2/me`,
                method: "GET",
            }),
        ).subscribe();

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [send, sendRequest]);

    return { value, error, loading };
};

export default useUser;
