import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Alert } from "../../components";
import Button from "../../components/Button";
import Card from "../../components/Card";
import Form from "../../components/Form";
import InputField from "../../components/InputField";
import useUnregisterTag from "../../utils/api/properties/useUnregisterTag";
import isRequired from "../../utils/validation/isRequired";
import useValidateField from "../../utils/validation/useValidateField";
import validateForm from "../../utils/validation/validateForm";
import styles from "./UnregisterTag.module.scss";

const UnregisterTag = () => {
    const { t } = useTranslation();

    const [tagNumber, setTagNumber] = useState("");

    const tagNumberValidator = useValidateField(tagNumber, isRequired());
    const tagFormValidator = validateForm(() => [tagNumberValidator]);

    const [tagUnregisteredSuccess, setTagUnregisteredSuccess] = useState(false);

    useEffect(() => {
        if (tagNumber) setTagUnregisteredSuccess(false);
    }, [tagNumber]);

    const unregisterTag = useUnregisterTag();
    const handleUnregisterTag = () => {
        unregisterTag.unregisterTag(tagNumber).subscribe(() => {
            setTagNumber("");
            setTagUnregisteredSuccess(true);
        });
    };

    return (
        <div>
            <Form onSubmit={handleUnregisterTag} {...tagFormValidator}>
                <Card>
                    <div className={styles.header}>
                        {t(
                            "Please enter the bold digits of the tag you wish to unregister",
                        )}
                        &nbsp;GB XXXX <b>XXXX</b> XX
                    </div>
                    <InputField
                        label={t("Tag Number")}
                        value={tagNumber}
                        onChange={setTagNumber}
                        {...tagNumberValidator}
                    />

                    {unregisterTag.error && (
                        <Alert type="error">{unregisterTag.error}</Alert>
                    )}

                    <Button
                        type="submit"
                        variant="primary"
                        cssRules={{ margin: "10px 0" }}
                        displayBlock={true}
                        disabled={unregisterTag.loading}
                    >
                        {t("Unregister Tag")}
                    </Button>

                    {tagUnregisteredSuccess && (
                        <Alert type="success">
                            {t("Tag unregistered successfully")}
                        </Alert>
                    )}
                </Card>
            </Form>
        </div>
    );
};

export default UnregisterTag;
