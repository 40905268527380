import { useCallback, useContext } from "react";
import { ITenant } from ".";
import { UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";

const useDeleteTenantRequest = (propertyId: number) => {
    const { activeUserParentsIds } = useContext(UserContext);
    const sendRequest = useXTagApiRequest();

    const request = useCallback(
        (tenantId: string) =>
            sendRequest<ITenant, unknown>({
                url: `${config.propertiesApiUrl}/${propertyId}/tenants/${tenantId}`,
                method: "DELETE",
                urlParams: getGlobalUrlParameters([], activeUserParentsIds),
            }),
        [sendRequest, propertyId, activeUserParentsIds],
    );

    return request;
};

export default useDeleteTenantRequest;
