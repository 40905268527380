import { useCssClasses, useToggle } from "../../../hooks";
import styles from "./TableFlag.module.scss";

const TableFlag = ({ name, description, colour }: ITableFlagProps) => {
    const { visible, show, hide } = useToggle();

    const cssClasses = useCssClasses(
        styles.details,
        visible ? "" : styles.hidden,
    );

    return (
        <div className={styles.container}>
            <div
                className={styles.flag}
                style={{ backgroundColor: colour }}
                onMouseOver={show}
                onMouseOut={hide}
                onFocus={show}
                onBlur={hide}
            />
            <div className={cssClasses}>
                <div className={styles.header}>{name}</div>
                <div>{description}</div>
            </div>
        </div>
    );
};

interface ITableFlagProps {
    name: string;
    description: string;
    colour: string;
}

export default TableFlag;
