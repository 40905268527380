import { IAwaitingAllocationJob } from "../../utils/api/allocatedJobs";
import AllocateJobSimpleModal from "./AllocateJobSimpleModal";

export interface IAllocateJobSimpleModalProps {
    propertyId: number;
    propertyCategoryId?: number;
    propertyCategories: { id: number; displayName: string }[];
    addressString: string;
    awaitingAllocationJob?: IAwaitingAllocationJob;
    hide: () => void;
}

export default AllocateJobSimpleModal;
