import { IDocument } from "../../../utils/api/documents";
import { IProperty } from "../../../utils/api/properties";
import PropertyPaperworkCard from "./PropertyPaperworkCard";

export interface IPropertyPaperworkCardProps {
    property: IProperty;
    jobDocumentsRecords: IDocument[];
    jobDocumentsRefresh: () => void;
    jobDocumentsLoaded: boolean;
}

export default PropertyPaperworkCard;
