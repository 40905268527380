import { useContext, useEffect } from "react";
import { IAwaitingAllocationStatusCounts } from ".";
import { PropertyCategoriesContext, UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiValue from "../useApiValue";

const useAwaitingAllocationJobsStatusCount = () => {
    const { activeUserParentsIds } = useContext(UserContext);
    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { value, loaded, send } = useApiValue<
        IAwaitingAllocationStatusCounts[]
    >({
        initialValue: [],
    });
    const sendRequest = useXTagApiRequest();

    useEffect(() => {
        const subscription = send(
            sendRequest({
                url: `${config.allocatedJobsApiUrl}/awaitingallocation/statuscount`,
                method: "GET",
                urlParams: getGlobalUrlParameters(
                    selectedCategories.map((c) => c.id),
                    activeUserParentsIds,
                ),
            }),
        ).subscribe();

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [activeUserParentsIds, selectedCategories, send, sendRequest]);

    return { value, loaded };
};

export default useAwaitingAllocationJobsStatusCount;
