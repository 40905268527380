import { ReactNode } from "react";
import TabHeader from "./TabHeader";

export interface ITagHeaderProps {
    isActive: boolean;
    index: number;
    onClick: (index: number) => void;
    children: ReactNode;
}

export default TabHeader;
