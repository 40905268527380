import { useContext, useEffect } from "react";
import { IObservationCounts } from ".";
import { PropertyCategoriesContext, UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiValue from "../useApiValue";

const useUnresolvedObservationCounts = (propertyId: number) => {
    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);
    const { value, loaded, send } = useApiValue<IObservationCounts[]>({});
    const sendRequest = useXTagApiRequest();

    useEffect(() => {
        const subscription = send(
            sendRequest({
                url: `${config.issuesApiUrl}/observations/statuscount`,
                method: "GET",
                urlParams: {
                    ...getGlobalUrlParameters(
                        selectedCategories.map((c) => c.id),
                        activeUserParentsIds,
                    ),
                    "property.id": [propertyId.toString()],
                    "status.displayName": ["Unresolved"],
                },
            }),
        ).subscribe();

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [
        activeUserParentsIds,
        propertyId,
        selectedCategories,
        send,
        sendRequest,
    ]);

    return { value, loaded };
};

export default useUnresolvedObservationCounts;
