import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, Icon, Link, Loading, Table } from "../../components";
import { ITableColumn } from "../../components/Table";
import useDownloadPaperwork from "../../hooks/useDownloadPaperwork";
import { IAmendedLgsr } from "../../utils/api/lgsrs";
import useAmendedLgsrs from "../../utils/api/lgsrs/useAmendedLgsrs";
import TableActions from "./TableActions";

const AmendedDocuments = () => {
    const { t } = useTranslation();
    const { download } = useDownloadPaperwork();
    const lgsrs = useAmendedLgsrs({
        sortProperty: "amendDate",
        sortDirection: "desc",
    });

    // TODO: Lgsr sent filter not working
    const columns = useMemo(() => {
        const renderAddressString = (value: string, row: IAmendedLgsr) => (
            <Link url={`/management/properties/${row.propertyId}`}>
                {value}
            </Link>
        );

        const renderId = (value: number) => (
            <Link url={`/jobs/jobs/${value}`}>#{value}</Link>
        );

        const renderDocumentSent = (value: string) =>
            value === "Sent" ? (
                <Icon icon="check" ariaLabel={t("Document has been sent")} />
            ) : (
                ""
            );

        const renderPaperwork = (value: unknown, row: IAmendedLgsr) => {
            const handleClick = () => {
                download(row.paperworkId, row.paperworkUrl).subscribe();
            };

            return (
                <Button onClick={handleClick}>
                    {t("View Amended Document")}
                </Button>
            );
        };

        const renderActions = (value: unknown, row: IAmendedLgsr) => (
            <TableActions paperworkId={row.paperworkId} />
        );

        const col: {
            [key: string]: ITableColumn<IAmendedLgsr>;
        } = {
            addressString: {
                title: "Property",
                render: renderAddressString,
            },
            jobId: {
                title: t("Job #"),
                canBeToggledByUser: true,
                render: renderId,
                filterable: false,
                sortable: false,
            },
            jobDate: {
                title: t("Date of Job"),
                type: "date",
            },
            amendDate: {
                title: t("Date of Amend"),
                type: "date",
            },
            userResponsibleName: {
                title: t("User Responsible"),
            },
            lgsrSent: {
                title: t("Sent"),
                render: renderDocumentSent,
            },
            paperwork: {
                title: t("Paperwork"),
                filterable: false,
                sortable: false,
                render: renderPaperwork,
            },
            actions: {
                title: t("Actions"),
                filterable: false,
                sortable: false,
                canBeToggledByUser: false,
                render: renderActions,
            },
        };

        return col;
    }, [download, t]);

    return (
        <Card title={t("Amended Documents")}>
            {lgsrs.loaded ? (
                <Table
                    preferences="amended-lgsrs-table"
                    columns={columns}
                    {...lgsrs}
                />
            ) : (
                <Loading />
            )}
        </Card>
    );
};

export default AmendedDocuments;
