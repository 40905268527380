import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const useAddProperty = () => {
    const { t } = useTranslation();
    const { activeUserParentsIds } = useContext(UserContext);
    const { send, loading, error } = useApiRequest({
        errorMessage: t("Add Property Failed."),
    });
    const sendRequest = useXTagApiRequest();

    const addProperty = useCallback(
        (property: IAddProperty) =>
            send(
                sendRequest({
                    url: config.propertiesApiUrl,
                    method: "POST",
                    urlParams: getGlobalUrlParameters([], activeUserParentsIds),
                    body: property,
                }),
            ),
        [activeUserParentsIds, send, sendRequest],
    );

    return { addProperty, loading, error };
};

interface IAddProperty {
    address: IAddress;
    uprn: string;
    propertyType: string;
    nextServiceDueDate: Date;
    landlordUserId: string;
    propertyCategoryId: string;
}

interface IAddress {
    addressOne: string;
    addressTwo: string;
    city: string;
    county: string;
    postcode: string;
}

export default useAddProperty;
