import { useContext, useMemo } from "react";
import { useNavigate } from "react-router";
import { IUnresolvedObservationsStatusGraphProps } from ".";
import { PropertyCategoriesContext } from "../../../components";
import GroupedBarChart, {
    IGroupedData,
} from "../../../components/Charts/GroupedBarChart";
import { usePhaseBoundaries } from "../../../hooks/usePhaseBoundaries";
import { toDateString } from "../../../utils/dates";
import { encodeUrl } from "../../../utils/url";

const UnresolvedObservationsStatusGraph = ({
    phases,
    observationType,
}: IUnresolvedObservationsStatusGraphProps) => {
    const { selectedCategories } = useContext(PropertyCategoriesContext);

    const labels = useMemo(() => {
        const array: string[] = [];

        for (const phase of phases) {
            const phaseArray = Array<string>(
                phase.phaseTimeFrame - array.length,
            ).fill("");
            phaseArray[phaseArray.length - 1] = phase.phaseTimeFrame.toString();

            array.push(...phaseArray);
        }

        if (array.length > 0) {
            array[0] = "0";
        }

        return array;
    }, [phases]);

    const tooltipLabels = useMemo(() => {
        const array: string[] = [];

        for (const phase of phases) {
            array.push(
                ...phase.dates.map((date) => toDateString(new Date(date.date))),
            );
        }

        return array;
    }, [phases]);

    const navigate = useNavigate();

    const phasesGroups = usePhaseBoundaries(phases, 7, true);

    const groups = useMemo(() => {
        const array: IGroupedData[] = phasesGroups.map((group) => ({
            title: group.phase.title,
            color: group.phase.colour,
            data: group.phase.dates.map((date) => ({
                label: toDateString(new Date(date.date)),
                value: date.count.map((count) => ({
                    label: count.fuelType.displayName,
                    value: count.count,
                    colour:
                        selectedCategories.length === 0
                            ? count.fuelType.color
                            : group.phase.colour,
                })),
                onClick: () => {
                    navigate(
                        encodeUrl("/issues/observations", {
                            dateRaised: [
                                `>=${group.from.toISOString()}{AND}<${group.to.toISOString()}`,
                            ],
                            ...(observationType !== "" && {
                                "type.displayName": [`=${observationType}`],
                            }),
                        }),
                    );
                },
            })),
            onClick: () => {
                navigate(
                    encodeUrl("/issues/observations", {
                        dateRaised: [
                            `>=${group.from.toISOString()}{AND}<${group.to.toISOString()}`,
                        ],
                        ...(observationType !== "" && {
                            "type.displayName": [`=${observationType}`],
                        }),
                    }),
                );
            },
        }));

        return array;
    }, [navigate, observationType, phasesGroups, selectedCategories.length]);

    return (
        <GroupedBarChart
            groups={groups}
            labels={labels}
            tooltipLabels={tooltipLabels}
            maxHeight={200}
        />
    );
};

export default UnresolvedObservationsStatusGraph;
