import { useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { IAppliancesDueDateGraphProps } from ".";
import {
    GroupedBarChart,
    PropertyCategoriesContext,
} from "../../../../components";
import { usePhaseBoundaries } from "../../../../hooks/usePhaseBoundaries";
import { toDateString } from "../../../../utils/dates";
import { encodeUrl } from "../../../../utils/url";

const AppliancesDueDateGraph = ({
    phases,
    maxHeight,
}: IAppliancesDueDateGraphProps) => {
    const { selectedCategories } = useContext(PropertyCategoriesContext);

    const labels = useMemo(() => {
        const array: string[] = [];

        for (const phase of phases) {
            const phaseArray = Array<string>(
                phase.phaseTimeFrame - array.length,
            ).fill("");
            phaseArray[phaseArray.length - 1] = phase.phaseTimeFrame.toString();

            array.push(...phaseArray);
        }

        if (array.length > 0) {
            array[0] = "0";
        }

        return array;
    }, [phases]);

    const tooltipLabels = useMemo(
        () =>
            phases.flatMap((p) =>
                p.dates.map((date) => toDateString(new Date(date.date))),
            ),
        [phases],
    );

    const navigate = useNavigate();

    const phasesGroups = usePhaseBoundaries(phases, 1, false);

    const groups = useMemo(
        () =>
            phasesGroups.map((group) => ({
                title: group.phase.title,
                color: group.phase.colour,
                data: group.phase.dates.map((date) => ({
                    label: toDateString(new Date(date.date)),
                    value: date.count.map((count) => ({
                        label: count.fuelType.displayName,
                        value: count.count,
                        colour:
                            selectedCategories.length === 0
                                ? count.fuelType.color
                                : group.phase.colour,
                    })),
                    onClick: () => {
                        navigate(
                            encodeUrl("management/appliances", {
                                nextServiceDate: [
                                    `>=${group.from.toISOString()}{AND}<${group.to.toISOString()}`,
                                ],
                            }),
                        );
                    },
                })),
                onClick: () => {
                    navigate(
                        encodeUrl("management/appliances", {
                            nextServiceDate: [
                                `>=${group.from.toISOString()}{AND}<${group.to.toISOString()}`,
                            ],
                        }),
                    );
                },
            })),
        [navigate, phasesGroups, selectedCategories.length],
    );

    return (
        <GroupedBarChart
            groups={groups}
            labels={labels}
            tooltipLabels={tooltipLabels}
            maxHeight={maxHeight}
        />
    );
};

export default AppliancesDueDateGraph;
