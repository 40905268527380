import { AppState, Auth0Provider } from "@auth0/auth0-react";
import * as FullStory from "@fullstory/browser";
import { SegmentProvider } from "@newfront/react-segment-hooks";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MainLayout from "./components/MainLayout";
import UserAuthentication from "./components/UserAuthentication";
import config from "./config";

const App = () => {
    useEffect(() => {
        FullStory.init({
            orgId: config.fullStoryOrganizationId,
            devMode: !config.isPageTrackingEnabled,
        });
    }, []);

    const navigate = useNavigate();
    const handleRedirectCallback = useCallback(
        (appState?: AppState) => {
            if (appState) {
                const { pathname, search } = appState.redirectTo;

                if (
                    typeof pathname === "string" &&
                    typeof search === "string" &&
                    pathname !== "/"
                ) {
                    navigate(`${pathname}${search}`);
                }
            }
        },
        [navigate],
    );

    return (
        <Auth0Provider
            domain={config.auth0.domain}
            clientId={config.auth0.clientId}
            audience={config.auth0.audience}
            useRefreshTokens={true}
            redirectUri={window.location.origin}
            onRedirectCallback={handleRedirectCallback}
        >
            <UserAuthentication>
                <SegmentProvider apiKey={config.segmentApiKey}>
                    <MainLayout />
                </SegmentProvider>
            </UserAuthentication>
        </Auth0Provider>
    );
};

export default App;
