import { useContext, useEffect } from "react";
import { ICount } from ".";
import { PropertyCategoriesContext, UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiValue from "../useApiValue";

export const useApplianceCounts = ({
    unsafe,
    safetyRiskLevel,
}: IFilterOptions) => {
    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);
    const { send, loading, value } = useApiValue<ICount>({});
    const sendRequest = useXTagApiRequest();

    useEffect(() => {
        const subscription = send(
            sendRequest({
                url: `${config.appliancesApiUrl}/count`,
                method: "GET",
                urlParams: {
                    ...(unsafe !== undefined && {
                        safeToUseV2: [`=${unsafe ? "false" : "true"}`],
                    }),
                    ...(safetyRiskLevel && {
                        landlordsAppliance: [
                            `=${
                                safetyRiskLevel === "true"
                                    ? "Landlord"
                                    : "Tenant"
                            }`,
                        ],
                    }),
                    active: ["=true"],
                    ...getGlobalUrlParameters(
                        selectedCategories.map((c) => c.id),
                        activeUserParentsIds,
                    ),
                },
            }),
        ).subscribe();

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [
        activeUserParentsIds,
        safetyRiskLevel,
        selectedCategories,
        send,
        sendRequest,
        unsafe,
    ]);

    return {
        loading,
        value,
    };
};

interface IFilterOptions {
    unsafe?: boolean;
    safetyRiskLevel?: string;
}

export default useApplianceCounts;
