import { FormEvent, ReactNode } from "react";
import { noop } from "rxjs";

// TODO: Disable buttons while submitting a form to avoid submitting it twice.
const Form = ({
    onSubmit,
    children,
    validate = () => true,
    onInvalidSubmit = noop,
}: IFormProps) => {
    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        const isValid = validate();
        if (isValid) {
            onSubmit();
        } else {
            onInvalidSubmit();
        }
    };

    return <form onSubmit={handleSubmit}>{children}</form>;
};

interface IFormProps {
    children: ReactNode;
    validate?: () => boolean;
    onInvalidSubmit?: () => void;
    onSubmit: () => void;
}

export default Form;
