import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
    Card,
    EngineerImg,
    Grid,
    GridColumn,
    HeroCard,
    Link,
    Loading,
    Map,
} from "../../components";
import AuthorizedImage from "../../components/AuthorizedImage";
import { IMapMarker } from "../../components/Map";
import engineer from "../../images/map/engineer.svg";
import property from "../../images/map/property.svg";
import { useUnableToAccess } from "../../utils/api/jobs";
import { toDateString, toDateTimeString } from "../../utils/dates";
import MeterReadingCard from "./MeterReadingCard";
import SideContent from "./SideContent";

const UnableToAccessView = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { value, loaded } = useUnableToAccess(id ?? "");

    const markers = useMemo(() => {
        const array: IMapMarker[] = [];

        if (value) {
            array.push({
                type: "Feature",
                geometry: {
                    type: "Point",
                    coordinates: [value.long, value.lat],
                },
                id: value.id,
                properties: {
                    icon: engineer,
                },
            });

            if (value.property.lat && value.property.long) {
                array.push({
                    type: "Feature",
                    geometry: {
                        type: "Point",
                        coordinates: [value.property.long, value.property.lat],
                    },
                    id: value.property.id,
                    properties: {
                        icon: property,
                    },
                });
            }
        }

        return array;
    }, [value]);

    return (
        <>
            {loaded ? (
                <>
                    <HeroCard
                        title={`${t("Unable to Access")}${
                            value.complianceType
                                ? ` (${value.complianceType.displayName})`
                                : ""
                        }
                        ${
                            value.jobType
                                ? ` - ${t("Job Type")}: ${value.jobType}`
                                : ""
                        }`}
                        subtitle={t("Submitted at {{date}}", {
                            date: toDateTimeString(new Date(value.date)),
                        })}
                    >
                        <Map
                            lat={value.lat}
                            lng={value.long}
                            markers={markers}
                            zoomPosition={3}
                        />
                    </HeroCard>

                    <Grid>
                        <GridColumn size="half">
                            <Card title={t("Engineer")}>
                                <SideContent
                                    side={
                                        <EngineerImg
                                            name={value.engineer.name}
                                            imageUrl={value.engineer.imageUrl}
                                        />
                                    }
                                >
                                    <Link
                                        url={`/management/engineers/${value.engineer.id}`}
                                    >
                                        {value.engineer.name}
                                    </Link>
                                    <br />
                                    {value.engineer.lastActiveDate &&
                                        toDateTimeString(
                                            new Date(
                                                value.engineer.lastActiveDate,
                                            ),
                                        )}
                                </SideContent>
                            </Card>

                            <Card title={t("Front Door")}>
                                <AuthorizedImage image={value.imageUrl} />
                            </Card>
                        </GridColumn>

                        <GridColumn size="half">
                            {value.appliance && (
                                <Card title={t("Asset")}>
                                    <SideContent
                                        side={
                                            <AuthorizedImage
                                                image={value.appliance.imageUrl}
                                                circle={true}
                                                size={50}
                                            />
                                        }
                                    >
                                        <Link
                                            url={`/management/appliances/${value.appliance.id}`}
                                        >
                                            {value.appliance.type.displayName}
                                            :&nbsp;
                                            {value.appliance.displayName}
                                            <br />
                                            {value.appliance.nextServiceDate &&
                                                `${t(
                                                    "Next service date",
                                                )}: ${toDateString(
                                                    new Date(
                                                        value.appliance.nextServiceDate,
                                                    ),
                                                )}`}
                                        </Link>
                                    </SideContent>
                                </Card>
                            )}

                            <Card title={t("Property")}>
                                <SideContent
                                    side={
                                        <AuthorizedImage
                                            image={value.property.imageUrl}
                                            circle={true}
                                            size={50}
                                        />
                                    }
                                >
                                    <Link
                                        url={`/management/properties/${value.property.id}`}
                                    >
                                        {value.property.addressString}
                                    </Link>
                                </SideContent>
                            </Card>
                            <Card title={t("Notes")}>{value.comments}</Card>

                            {!value.appliance && (
                                <MeterReadingCard unableToAccess={value} />
                            )}
                        </GridColumn>
                    </Grid>
                </>
            ) : (
                <Loading />
            )}
        </>
    );
};

export default UnableToAccessView;
