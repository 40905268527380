import { useCallback, useContext } from "react";
import { UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const useUpdateEngineerPropertyCategories = () => {
    const { activeUserParentsIds } = useContext(UserContext);
    const { send } = useApiRequest({});
    const sendRequest = useXTagApiRequest();

    const updateEngineerPropertyCategories = useCallback(
        (
            engineerId: number,
            landlordIds: number[],
            propertyCategoriesIds: number[],
        ) =>
            send(
                sendRequest({
                    url: `${config.landlordsApiUrl}/propertycategory/engineer/${engineerId}`,
                    method: "POST",
                    urlParams: getGlobalUrlParameters([], activeUserParentsIds),
                    body: {
                        landlordIds,
                        categories: propertyCategoriesIds,
                    },
                }),
            ),
        [activeUserParentsIds, send, sendRequest],
    );

    return { updateEngineerPropertyCategories };
};

export default useUpdateEngineerPropertyCategories;
