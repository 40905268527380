import { ReactNode } from "react";
import { Tab } from "../../../components/TabContainer";
import {
    INotificationType,
    IUserNotificationType,
} from "../../../utils/api/users";
import NotificationItem from "../NotificationItem";

const NotificationTab = ({
    header,
    notificationTypes,
    activeNotificationTypes,
    updateActiveNotificationTypes,
    updatePopup,
}: INotificationTabProps) => {
    return (
        <Tab header={<>{header}</>}>
            {notificationTypes.map((notificationType) => {
                const activeNotification = activeNotificationTypes.find(
                    (selectedNotification) =>
                        notificationType.id ===
                        selectedNotification.notification_type_id,
                );

                return (
                    <NotificationItem
                        key={notificationType.id}
                        notification={notificationType}
                        onNotificationChange={updateActiveNotificationTypes}
                        onPopupChange={updatePopup}
                        active={!!activeNotification}
                        showPopup={
                            !!activeNotification &&
                            activeNotification.show_popup
                        }
                    />
                );
            })}
        </Tab>
    );
};

interface INotificationTabProps {
    header: ReactNode;
    notificationTypes: INotificationType[];
    activeNotificationTypes: IUserNotificationType[];
    updateActiveNotificationTypes: (
        updatedNotificationType: IUserNotificationType,
        active: boolean,
    ) => void;
    updatePopup: (updatedNotificationType: IUserNotificationType) => void;
}

export default NotificationTab;
