import { useEffect, useRef, useState } from "react";
import { IDrawableObject } from "..";
import { useCssClasses } from "../../../hooks";
import { colours } from "../../../styles/colours";
import { animate, loop } from "../animation";
import { linear } from "../animations";
import { createChart } from "../chart";
import styles from "./ProgressCircular.module.scss";

const ProgressCircular = ({
    value,
    max,
    thickness = 10,
    displayValue = true,
    isPercentage = false,
    spin = false,
    width = "",
    fontSize = 1,
    showValue = false,
}: IProgressCircularProps) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [textHeight, setTextHeight] = useState(0);

    const cssClasses = useCssClasses(styles.chart, width && styles[width]);

    useEffect(() => {
        if (canvasRef.current) {
            const getValue = (): IDrawableObject => {
                const animation = animate(1500, linear);

                const onResize = (newWidth: number, newHeight: number) => {
                    setTextHeight(newHeight / 2 + 10);
                };

                const draw = (
                    context: CanvasRenderingContext2D,
                    time: number,
                ) => {
                    const isAnimating = animation.updateFrame(time);

                    context.save();
                    context.textAlign = "center";
                    context.textBaseline = "middle";
                    context.fillStyle = colours.chart.grey;
                    context.restore();

                    return isAnimating;
                };

                return { draw, onResize };
            };

            const getCircles = (): IDrawableObject => {
                const animation = spin
                    ? loop(1500, linear)
                    : animate(1500, linear);

                let radius: number;
                let center: number;

                const onResize = (newWidth: number, newHeight: number) => {
                    radius = Math.max((newHeight - thickness) / 2, 0);
                    center = newHeight / 2;
                };

                const draw = (
                    context: CanvasRenderingContext2D,
                    time: number,
                ) => {
                    const isAnimating = animation.updateFrame(time);

                    context.save();
                    context.beginPath();
                    context.arc(center, center, radius, 0, 2 * Math.PI);
                    context.lineWidth = thickness;
                    context.strokeStyle = colours.chart.lighterGrey;
                    context.stroke();
                    context.restore();

                    const startAngle = 1.5 * Math.PI;
                    const angle =
                        startAngle +
                        ((value <= 0 ? value * -1 : value) / max) * 2 * Math.PI;
                    const endAngle = spin
                        ? angle
                        : animation.getValue(angle, startAngle);

                    if (endAngle > startAngle) {
                        context.save();

                        if (spin) {
                            context.translate(center, center);
                            context.rotate(animation.getValue(2 * Math.PI));
                            context.translate(-center, -center);
                        }

                        context.beginPath();
                        context.arc(
                            center,
                            center,
                            radius,
                            startAngle,
                            endAngle,
                        );
                        context.lineWidth = thickness;
                        context.strokeStyle = colours.chart.blue;
                        context.lineCap = "round";
                        context.stroke();

                        context.restore();
                    }

                    return isAnimating;
                };

                return { draw, onResize };
            };

            const objects: IDrawableObject[] = [getCircles()];

            if (displayValue) {
                objects.push(getValue());
            }

            const chart = createChart({
                canvas: canvasRef.current,
                objects,
                aspectRatio: 1,
                fontSize,
            });

            return chart.destroy;
        }
    }, [value, max, displayValue, fontSize, spin, thickness]);

    return (
        <>
            <canvas className={cssClasses} ref={canvasRef} />
            {showValue && (
                <div
                    className={styles.innerText}
                    style={{ top: `-${textHeight}px` }}
                >
                    {value}
                    {isPercentage ? "%" : ""}
                </div>
            )}
        </>
    );
};

interface IProgressCircularProps {
    value: number;
    max: number;
    spin?: boolean;
    thickness?: number;
    displayValue?: boolean;
    isPercentage?: boolean;
    width?: "width300" | "width200" | "";
    fontSize?: number;
    showValue?: boolean;
}

export default ProgressCircular;
