import { CSSProperties, RefObject, useMemo } from "react";
import { Display } from ".";
import { useCssClasses } from "../../hooks";
import styles from "./Icon.module.scss";

// TODO: Create a dictionary to map strings to icomoon classes, to avoid having duplicate icons.
// TODO: inherit size from font-size css property of ancestors.
const Icon = (props: IIconProps) => {
    const {
        icon,
        color,
        customColor,
        size = 24,
        display = "block",
        setRef,
        cssRules,
        onMouseEnter,
        onMouseLeave,
    } = props;
    const ariaLabel = "ariaLabel" in props ? props.ariaLabel : undefined;
    const ariaHidden = "ariaHidden" in props ? props.ariaHidden : undefined;

    const cssClasses = useCssClasses(
        "icon",
        icon,
        display === "block" ? styles.block : styles.inlineBlock,
        color === "blue"
            ? styles.blue
            : color === "green"
            ? styles.green
            : color === "red"
            ? styles.red
            : color === "white"
            ? styles.white
            : color === "grey10"
            ? styles.grey10
            : color === "grey30"
            ? styles.grey30
            : color === "grey70"
            ? styles.grey70
            : "",
    );

    const style = useMemo(() => {
        const css = { ...cssRules, fontSize: `${size}px` };

        if (customColor) {
            css.color = customColor;
        }

        return css;
    }, [customColor, cssRules, size]);

    return (
        <span
            aria-label={ariaLabel}
            aria-hidden={ariaHidden}
            className={cssClasses}
            ref={setRef}
            style={style}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        />
    );
};

interface IIconBaseProps {
    icon: string;
    size?: number;
    color?: "blue" | "green" | "red" | "white" | "grey10" | "grey30" | "grey70";
    customColor?: string;
    display?: Display;
    setRef?: RefObject<HTMLElement>;
    cssRules?: CSSProperties;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
}

interface IIconAriaLabelProps extends IIconBaseProps {
    ariaLabel: string;
}

interface IIconAriaHiddenProps extends IIconBaseProps {
    ariaHidden: true;
}

type IIconProps = IIconAriaLabelProps | IIconAriaHiddenProps;

export default Icon;
