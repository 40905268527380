import { IDrawableObject } from ".";

const hdpiMonitorScale = (
    canvas: HTMLCanvasElement,
    context: CanvasRenderingContext2D,
    width: number,
    height: number,
) => {
    const scale = window.devicePixelRatio;
    canvas.width = width * scale;
    canvas.height = height * scale;

    context.scale(scale, scale);
};

const resize = (
    context: CanvasRenderingContext2D,
    aspectRatio?: number,
    maxHeight?: number,
) => {
    let width = parseFloat(getComputedStyle(context.canvas).width || "0");

    // TODO: Canvas is blurry when the width is not an integer.
    if (Number.isInteger(width)) {
        context.canvas.style.paddingRight = "";
    } else {
        width = Math.floor(width);
        context.canvas.style.paddingRight = "0.5px";
    }

    const height = maxHeight
        ? maxHeight
        : aspectRatio
        ? width / aspectRatio
        : context.canvas.getBoundingClientRect().height;

    hdpiMonitorScale(context.canvas, context, width, height);

    return { width, height };
};

const initializeCanvas = (
    canvas: HTMLCanvasElement,
    objects: IDrawableObject[],
    aspectRatio?: number,
    fontSize = 12,
    maxHeight?: number,
) => {
    let size = { width: 0, height: 0 };
    let isMounted = true;
    let drawableObjects = objects;
    const handleUpdateObjects = (newObjects: IDrawableObject[]) => {
        drawableObjects = newObjects;
    };
    let handleUpdate: () => void = () => null;
    let handleResize: () => void = () => null;
    const handleDestroy = () => {
        isMounted = false;
    };

    const context = canvas.getContext("2d");
    if (context) {
        // TODO: Investigate why setting the font here doesn't work.
        context.font = `${fontSize}px Verdana`;

        const draw = () => {
            requestAnimationFrame((time) => {
                context.clearRect(0, 0, size.width, maxHeight || size.height);

                const animations = drawableObjects.map((object) =>
                    object.draw(context, time),
                );

                if (isMounted && animations.some((a) => a)) {
                    draw();
                }
            });
        };

        handleUpdate = () => draw();

        handleResize = () => {
            size = resize(context, aspectRatio, maxHeight);
            drawableObjects.forEach((object) => {
                object.onResize(size.width, size.height);
            });

            handleUpdate();
        };

        handleResize();
    }

    return {
        resize: handleResize,
        update: handleUpdate,
        updateObjects: handleUpdateObjects,
        destroy: handleDestroy,
    };
};

export { initializeCanvas };
