import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { IAgreedLimitationsProps } from ".";
import { Select } from "../../../components";
import styles from "./AgreedLimitations.module.scss";
import ContractorSelect from "./ContractorSelect";
import LimitationSet from "./LimitationSet";

const AgreedLimitations = ({ landlordOptions }: IAgreedLimitationsProps) => {
    const { t } = useTranslation();
    const [selectedLandlordId, setSelectedLandlordId] = useState("");
    const [selectedContractorId, setSelectedContractorId] = useState("");

    const setLandlordId = useCallback(
        (value: string) => {
            setSelectedLandlordId(value);
            setSelectedContractorId("");
        },
        [setSelectedLandlordId, setSelectedContractorId],
    );

    return (
        <>
            <h2 className={styles.title}>{t("Agreed Limitations")}</h2>
            <p>
                {t(
                    "Enter the agreed limitations for your properties. All limitations are stored against a job once it is created.",
                )}
            </p>

            <Select
                label={t("Select a landlord")}
                options={landlordOptions}
                onChange={setLandlordId}
                value={selectedLandlordId}
                allowEmpty={true}
            />

            {selectedLandlordId && (
                <>
                    <ContractorSelect
                        landlordId={selectedLandlordId}
                        contractorId={selectedContractorId}
                        setContractorId={setSelectedContractorId}
                    />
                    {selectedContractorId && (
                        <LimitationSet
                            landlordId={selectedLandlordId}
                            contractorId={selectedContractorId}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default AgreedLimitations;
