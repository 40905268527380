import { IAuthorizedImageProps } from ".";
import { Img } from "..";
import { useAuthorizedImage } from "../../hooks/useAuthorizedImage";

const AuthorizedImage = ({
    image,
    size,
    circle,
    testId,
    cssRules,
    display,
    children,
    height,
}: IAuthorizedImageProps) => {
    const imageUrl = useAuthorizedImage(image ?? "");

    return (
        <Img
            url={imageUrl}
            size={size}
            circle={circle}
            testId={testId}
            cssRules={cssRules}
            display={display}
            height={height}
        >
            {children}
        </Img>
    );
};

export default AuthorizedImage;
