import { useMemo } from "react";
import { AuditStatus, IFlaggedLgsr } from ".";
import config from "../../../config";
import useFilterable, { IFilters } from "../useFilterable";

const useFlaggedLgsrs = (inbox: boolean, status: AuditStatus) => {
    const filter = useMemo<IFilters>(() => {
        return {
            status: {
                operator: "{AND}",
                readOnly: true,
                filters: [{ function: "=", value: status }],
            },
            ...(status === "Approval Required" && {
                hasAuditor: {
                    operator: "{AND}",
                    readOnly: true,
                    filters: [{ function: "=", value: inbox.toString() }],
                },
            }),
        };
    }, [inbox, status]);

    const filterable = useFilterable<IFlaggedLgsr>(
        `${config.lgsrsApiUrl}/flagged`,
        {
            filtersUrl: `${config.lgsrsApiUrl}/flagged/filters/unique`,
            params: {
                filters: filter,
            },
        },
    );

    return filterable;
};

export default useFlaggedLgsrs;
