import { useMemo, useState } from "react";
import { IUpdateUserContext } from ".";
import {
    IUpdateUser,
    IUpdateUserParentRoles,
    IUser,
} from "../../../utils/api/users";
import {
    isRequired,
    isTrue,
    IValidateField,
    useValidateField,
    validateForm,
} from "../../../utils/validation";

export const useUpdateUserFields = (
    user: IUser | undefined,
    validators: IValidateField[],
): IUpdateUserContext => {
    const [firstName, setFirstName] = useState(
        () => user?.name.substring(0, user.name.indexOf(" ")) ?? "",
    );
    const firstNameValidation = useValidateField(firstName, isRequired());

    const [lastName, setLastName] = useState(
        () => user?.name.substring(user.name.indexOf(" ") + 1) ?? "",
    );
    const lastNameValidation = useValidateField(lastName, isRequired());

    const [propertyCategoryIds, setPropertyCategoryIds] = useState(
        user?.propertyCategories ?? [],
    );

    const [userParentRoles, setUserParentRoles] = useState<
        IUpdateUserParentRoles[]
    >(
        user?.userParentRoles.map((userParent) => ({
            id: userParent.id,
            roles: userParent.roles.map((role) => role.id),
        })) ?? [],
    );
    const userParentRolesValidation = useValidateField(
        userParentRoles.every((u) => u.roles.length > 0),
        isTrue("You must select at least one role for each landlord."),
    );

    const updatedUser = useMemo<IUpdateUser>(
        () => ({
            name: `${firstName} ${lastName}`,
            propertyCategories: propertyCategoryIds,
            userParentRoles: userParentRoles,
            profileImageUrl: user?.profileImageUrl ?? "",
        }),
        [firstName, lastName, propertyCategoryIds, userParentRoles, user],
    );

    const formValidation = validateForm(() => [
        firstNameValidation,
        lastNameValidation,
        userParentRolesValidation,
        ...validators,
    ]);

    return {
        firstName,
        setFirstName,
        firstNameValidation,
        lastName,
        setLastName,
        lastNameValidation,
        propertyCategoryIds,
        setPropertyCategoryIds,
        userParentRoles,
        setUserParentRoles,
        userParentRolesValidation,
        updatedUser,
        formValidation,
    };
};
