import { useContext, useMemo } from "react";
import { PermissionType } from ".";
import { UserContext } from "../components";

/**
 * Check if current user has required permission for any userParent
 */
const useHasPermission = (permission: PermissionType) => {
    const { user, activeUserParentsIds } = useContext(UserContext);

    const hasPermission = useMemo(() => {
        for (const userParent of Object.keys(user.userParentPermissions)) {
            if (
                user.userParentPermissions[Number(userParent)]
                    .map((p) => p.name)
                    .includes(permission) &&
                activeUserParentsIds.includes(Number(userParent))
            ) {
                return true;
            }
        }

        return false;
    }, [activeUserParentsIds, permission, user.userParentPermissions]);

    return hasPermission;
};

export default useHasPermission;
