import { useState } from "react";
import { getUrlParameters } from "../utils/url";

const useActiveTabFromUrl = (
    tabs: string[],
): [number, (value: number) => void] => {
    const [activeTab, setActiveTab] = useState(() => {
        const urlParams = getUrlParameters();

        const tabValues = urlParams["activeTab"];
        if (tabValues) {
            const tabKey = tabValues[0] || "";

            const index = tabs
                .map((t) => t.toLowerCase())
                .indexOf(tabKey.toLowerCase());

            if (index >= 0) {
                return index;
            }
        }

        return 0;
    });

    return [activeTab, setActiveTab];
};

export default useActiveTabFromUrl;
