import { useCallback } from "react";
import { IFlagColourProps } from ".";
import useClickableNonInteractiveElement from "../../../hooks/useClickableNonInteractiveElement";
import styles from "./FlagColour.module.scss";

const FlagColour = (props: IFlagColourProps) => {
    const { colour } = props;
    const selectColour =
        "selectColour" in props ? props.selectColour : undefined;
    const togglePopover =
        "togglePopover" in props ? props.togglePopover : undefined;
    const setRef = "setRef" in props ? props.setRef : undefined;

    const handleClick = useCallback(() => {
        if (togglePopover) {
            togglePopover();
        } else if (selectColour) {
            selectColour(colour);
        }
    }, [colour, selectColour, togglePopover]);

    const { onKeyDown } = useClickableNonInteractiveElement(handleClick);

    return (
        <div
            className={styles.flag}
            style={{ backgroundColor: colour }}
            onClick={handleClick}
            onKeyDown={onKeyDown}
            role="button"
            tabIndex={0}
            ref={setRef}
        />
    );
};

export default FlagColour;
