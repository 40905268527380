import { Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IAssetsGroupedListProps } from ".";
import ApplianceListItem from "../../../components/ApplianceListItem";
import { ISimpleAppliance } from "../../../utils/api/appliances";
import styles from "./AssetsGroupedList.module.scss";

const AssetsGroupedList = ({ assets }: IAssetsGroupedListProps) => {
    const { t } = useTranslation();

    const groupedAssets = useMemo(() => {
        const otherAssets: ISimpleAppliance[] = [];
        const floors: { [floorNumber: number]: ISimpleAppliance[] } = {};

        for (const asset of assets) {
            if (asset.floorNumber !== null) {
                let floorAssets = floors[asset.floorNumber];

                if (floorAssets === undefined) {
                    floorAssets = [];
                    floors[asset.floorNumber] = floorAssets;
                }

                floorAssets.push(asset);
            } else {
                otherAssets.push(asset);
            }
        }

        const sortedFloors = Object.keys(floors)
            .map((f) => Number(f))
            .sort((a, b) => (a > b ? 1 : -1))
            .map<{ floorNumber: number | null; assets: ISimpleAppliance[] }>(
                (f) => ({
                    floorNumber: f,
                    assets: floors[f],
                }),
            );

        sortedFloors.unshift({ floorNumber: null, assets: otherAssets });

        return sortedFloors;
    }, [assets]);

    return (
        <>
            {groupedAssets.map((group) => (
                <Fragment key={group.floorNumber ?? ""}>
                    {group.floorNumber !== null && (
                        <div className={styles.group}>
                            {t("Floor")} {group.floorNumber} (
                            {group.assets.length})
                        </div>
                    )}

                    {group.assets.map((asset) => (
                        <ApplianceListItem key={asset.id} appliance={asset} />
                    ))}
                </Fragment>
            ))}
        </>
    );
};

export default AssetsGroupedList;
