import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, DataSelect, Loading } from "../../../components";
import DashboardWidgetActiveFilters from "../../../components/DashboardWidgetActiveFilters";
import DashboardWidgetFilter from "../../../components/DashboardWidgetFilter";
import useJobLevelWidgetFilter from "../../../hooks/useJobLevelWidgetFilter";
import { IComplianceDashboard } from "../../../utils/api/misc";
import { generateAverageJobTimeData } from "../../../utils/chartData";
import styles from "./AverageJobTimeCard.module.scss";

const AverageJobTimeCard = ({ loaded, data }: IAverageJobTimeProps) => {
    const { t } = useTranslation();
    const [timeIndex, setTimeIndex] = useState(0);

    const { filters, jobTimes } = useJobLevelWidgetFilter(
        "average_job_time_job_level",
        data,
    );

    const times = useMemo(() => generateAverageJobTimeData(jobTimes), [
        jobTimes,
    ]);

    return (
        <Card
            title={t("Average job time")}
            fullHeight={true}
            testId="AverageJobTime_Widget"
            actions={<DashboardWidgetFilter filters={filters} />}
        >
            {loaded ? (
                <div className={styles.centerContainer}>
                    <div>
                        <div
                            className={styles.time}
                            data-test-id="AverageJobTime_Value_Div"
                        >
                            {times[timeIndex].time}
                        </div>
                        <DataSelect
                            current={timeIndex}
                            onChange={setTimeIndex}
                            dataLength={times.length}
                            label={`${t("This")} ${t(times[timeIndex].label)}`}
                        />
                    </div>

                    <DashboardWidgetActiveFilters filters={filters} />
                </div>
            ) : (
                <Loading small={true} smallWidth={100} centerContent={true} />
            )}
        </Card>
    );
};

interface IAverageJobTimeProps {
    data: IComplianceDashboard;
    loaded: boolean;
}

export default AverageJobTimeCard;
