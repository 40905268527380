import { useContext, useEffect, useMemo } from "react";
import { ILandlordPropertyCategory } from ".";
import { UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import { getFiltersUrlParams } from "../getFiltersUrlParams";
import useApiValue from "../useApiValue";
import { IFilters } from "../useFilterable";

const useLandlordPropertyCategories = ({
    functionalOnly,
    landlordId,
}: IFilterParameters) => {
    const { activeUserParentsIds } = useContext(UserContext);
    const { value, loaded, send } = useApiValue<ILandlordPropertyCategory[]>({
        initialValue: [],
    });
    const sendRequest = useXTagApiRequest();

    const filters = useMemo<IFilters>(() => {
        return {
            ...(landlordId && {
                landlordId: {
                    operator: "{AND}",
                    readOnly: true,
                    filters: [{ function: "=", value: landlordId.toString() }],
                },
            }),
            ...(functionalOnly && {
                cardTypeCount: {
                    operator: "{AND}",
                    readOnly: true,
                    filters: [{ function: ">", value: "0" }],
                },
            }),
        };
    }, [functionalOnly, landlordId]);

    useEffect(() => {
        const subscription = send(
            sendRequest({
                url: `${config.landlordsApiUrl}/propertycategories/index`,
                method: "GET",
                urlParams: {
                    ...getFiltersUrlParams(filters),
                    ...getGlobalUrlParameters([], activeUserParentsIds),
                },
            }),
        ).subscribe();

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [filters, activeUserParentsIds, send, sendRequest]);

    return { value, loaded };
};

interface IFilterParameters {
    functionalOnly?: boolean;
    landlordId?: number;
}

export default useLandlordPropertyCategories;
