import Stepper from "./Stepper";

export interface IStep {
    id: string;
    title: string;
    isCompleted: boolean;
    onClick: () => void;
}

export interface IStepperProps {
    steps: IStep[];
    activeStepId: string;
}

export default Stepper;
