import { useCallback, useMemo, useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { Loading } from "..";
import { useHtmlEntityId } from "../../hooks/useHtmlEntityId";
import { getNow } from "../../utils/dates";
import ValidationError from "../ValidationError";
import styles from "./FileUpload.module.scss";

const FileUpload = ({
    label,
    onFileSelected,
    isLoading,
    overrideFileName,
    maxFileSizeInMB = 2,
    allowedFileTypes,
    isValid,
    error,
}: IFileUploadProps) => {
    const [fileName, setFileName] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const { t } = useTranslation();

    const maxSize = useMemo(() => maxFileSizeInMB * 1024 * 1024, [
        maxFileSizeInMB,
    ]);

    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            const acceptedFile = acceptedFiles.pop();

            if (acceptedFile) {
                const fileNameParts = acceptedFile.name.split(".");
                const extension = fileNameParts[fileNameParts.length - 1];

                const generatedFileName = `${
                    overrideFileName || getNow().getTime()
                }.${extension}`;

                setFileName(acceptedFile.name);
                onFileSelected(generatedFileName, acceptedFile);
            }
        },
        [onFileSelected, overrideFileName],
    );

    const onDropRejected = useCallback(
        (fileRejections: FileRejection[]) => {
            if (
                fileRejections.some((r) =>
                    r.errors.some((e) => e.code === "file-too-large"),
                )
            ) {
                setErrorMessage(t("Max file size exceeded"));
            } else {
                const types = allowedFileTypes.map((f) => f.substring(1));

                const typesString = `${types.slice(0, -1).join(", ")}${
                    types.length > 1 ? " or " : ""
                }${types.slice(-1)}`;

                setErrorMessage(
                    `${t("Please select a {{typesString}} file", {
                        typesString,
                    })}.`,
                );
            }

            setFileName("");
            onFileSelected("", null);
        },
        [allowedFileTypes, onFileSelected, t],
    );
    const { getRootProps, getInputProps } = useDropzone({
        accept: allowedFileTypes,
        maxSize,
        onDrop,
        onDropRejected,
    });

    const id = useHtmlEntityId();

    return (
        <div className={styles.container}>
            <label htmlFor={id} className={styles.label}>
                {label}
            </label>

            <div className={styles.fileUpload} {...getRootProps()}>
                <input id={id} {...getInputProps()} />

                {!isLoading ? (
                    fileName ||
                    t("Max file size is {{fileSize}} MB", {
                        fileSize: maxFileSizeInMB,
                    })
                ) : (
                    <Loading />
                )}
            </div>

            <ValidationError isValid={isValid} error={errorMessage || error} />
        </div>
    );
};

interface IFileUploadProps {
    label: string;
    onFileSelected: (filename: string, file: File | null) => void;
    isLoading?: boolean;
    overrideFileName?: string;
    allowedFileTypes: FileType[];
    maxFileSizeInMB?: number;
    isValid?: boolean;
    error?: string;
}

type FileType = ".pdf" | ".csv" | ".png" | ".jpg" | ".jpeg";

export default FileUpload;
