import { ITableProps } from ".";
import { useCssClasses } from "../../../hooks";
import styles from "./Table.module.scss";

const Table = ({
    children,
    highlightColumn = false,
    withMargin = true,
}: ITableProps) => {
    const cssClasses = useCssClasses(
        styles.table,
        highlightColumn ? styles.highlightColumn : "",
        withMargin ? styles.margin : "",
    );

    return <table className={cssClasses}>{children}</table>;
};

export default Table;
