import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Button, Icon } from "../../../../components";
import { useToggle } from "../../../../hooks";
import { IPhase } from "../../../../utils/api/properties";
import PhaseLimits from "../PhaseLimits";
import styles from "./ChartActions.module.scss";

const ChartActions = ({
    phases,
    showGraph,
    phaseTimeframe,
    toggleGraphOverview,
    savePhaseLimits,
    children,
}: IChartActionsProps) => {
    const { t } = useTranslation();
    const { toggle, hide, visible } = useToggle();

    return (
        <div className={styles.actions}>
            <Button
                displayAsLink={true}
                variant="primary"
                onClick={toggle}
                cssRules={{ marginLeft: 10, fontSize: 12, fontWeight: "bold" }}
                testId="EditLimits_Button"
            >
                <Icon
                    icon="pencil"
                    size={18}
                    cssRules={{ marginRight: 5 }}
                    ariaHidden={true}
                />
                {t("Edit limits")}
            </Button>

            {showGraph ? (
                <Button
                    displayAsLink={true}
                    variant="primary"
                    onClick={toggleGraphOverview}
                    clickParams={["overview"]}
                    cssRules={{
                        marginLeft: 10,
                        fontSize: 12,
                        fontWeight: "bold",
                    }}
                    testId="SwitchToOverview_Button"
                >
                    <Icon
                        icon="all"
                        size={14}
                        cssRules={{ marginRight: 5 }}
                        ariaHidden={true}
                    />
                    {t("Switch to overview")}
                </Button>
            ) : (
                <Button
                    displayAsLink={true}
                    variant="primary"
                    onClick={toggleGraphOverview}
                    clickParams={["graph"]}
                    cssRules={{
                        marginLeft: 10,
                        fontSize: 12,
                        fontWeight: "bold",
                    }}
                    testId="SwitchToGraph_Button"
                >
                    <Icon
                        icon="bar-chart"
                        size={14}
                        cssRules={{ marginRight: 5 }}
                        ariaHidden={true}
                    />
                    {t("Switch to graph view")}
                </Button>
            )}

            {visible && (
                <PhaseLimits
                    phases={phases}
                    timeFrame={phaseTimeframe}
                    onSave={savePhaseLimits}
                    hide={hide}
                />
            )}
            {children}
        </div>
    );
};

interface IChartActionsProps {
    phases: IPhase[];
    showGraph: boolean;
    phaseTimeframe: string;
    toggleGraphOverview: (view: "graph" | "overview") => void;
    savePhaseLimits: (phases: IPhase[]) => void;
    children?: ReactNode;
}

export default ChartActions;
