import { useMemo } from "react";
import { IDashboardWidgetActiveFiltersProps } from ".";
import styles from "./DashboardWidgetActiveFilters.module.scss";

const DashboardWidgetActiveFilters = ({
    filters,
}: IDashboardWidgetActiveFiltersProps) => {
    const activeFilters = useMemo(
        () =>
            filters
                .filter((g) =>
                    typeof g.value === "string" ? g.value : g.value.length > 0,
                )
                .flatMap((g) =>
                    g.options
                        .filter((f) =>
                            typeof g.value === "string"
                                ? f.value === g.value
                                : g.value.includes(f.value),
                        )
                        .map((o) => ({
                            key: `${g.id}-${o.value}`,
                            label: o.label,
                        })),
                ),
        [filters],
    );

    return (
        <div className={styles.filters}>
            {activeFilters.map((filter) => (
                <span key={filter.key} className={styles.filter}>
                    {filter.label}
                </span>
            ))}
        </div>
    );
};

export default DashboardWidgetActiveFilters;
