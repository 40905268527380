import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card, JobTimeline, Loading } from "../../../components";
import { useAnswersForJob } from "../../../utils/api/answers";
import { useJob, useJobTasks } from "../../../utils/api/jobs";

const IssueJobApplianceTimeline = ({
    applianceId,
    jobId,
}: IIssueJobApplianceTimelineProps) => {
    const { t } = useTranslation();

    const { value: job, loading: jobLoading } = useJob(jobId.toString());
    const { value: answers, loading: answersLoading } = useAnswersForJob(
        jobId.toString(),
        {
            includeDeletedAnswers: false,
        },
    );
    const { value: tasks, loading: tasksLoading, getTasks } = useJobTasks();

    useEffect(() => {
        const subscription = getTasks(jobId).subscribe();

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [getTasks, jobId]);

    const applianceTasks = useMemo(
        () =>
            tasks
                ? tasks.filter(
                      (task) =>
                          task.appliance && task.appliance.id === applianceId,
                  )
                : [],
        [applianceId, tasks],
    );

    return jobLoading || tasksLoading || answersLoading ? (
        <Loading />
    ) : job && answers && applianceTasks.length > 0 ? (
        <Card title={t("Job Appliance Timeline")}>
            <JobTimeline
                jobs={[
                    {
                        ...job,
                        engineer: job.engineer,
                        property: {
                            id: job.property.id,
                            addressString: job.property.addressString,
                        },
                        tasks: applianceTasks,
                    },
                ]}
                showAddress={false}
                showJobType={false}
                showTasks={true}
                answers={answers}
            />
        </Card>
    ) : (
        <></>
    );
};

interface IIssueJobApplianceTimelineProps {
    applianceId: string;
    jobId: number;
}

export default IssueJobApplianceTimeline;
