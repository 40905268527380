import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
    Button,
    Card,
    ColourPill,
    Grid,
    GridColumn,
    JobPaperworkDropdown,
    JobTimeline,
    Link,
    Loading,
    Map,
    PaperworkLogCard,
} from "../../components";
import Breadcrumb from "../../components/Breadcrumb";
import BreadcrumbItem from "../../components/Breadcrumb/BreadcrumbItem";
import DeleteManualUploadModal from "../../components/DeleteManualUploadModal";
import IssuesLog from "../../components/IssuesLog";
import JobCommentsTimeline from "../../components/JobCommentsTimeline";
import ObservationsLog from "../../components/ObservationsLog";
import SimpleTable, { IColumn } from "../../components/SimpleTable";
import { useHasUserParentPermission, useToggle } from "../../hooks";
import { useFollowOnJobs } from "../../utils/api/allocatedJobs";
import { useAnswersForJob } from "../../utils/api/answers";
import { useDocuments } from "../../utils/api/documents";
import { useIssuesForModel } from "../../utils/api/issues";
import {
    IAgreedLimitationSet,
    IComplianceType,
    IJob,
    useJob,
    useJobComments,
    useJobTasks,
} from "../../utils/api/jobs";
import { useFileUrl } from "../../utils/api/misc";
import {
    defaultDate,
    getDifferenceInSeconds,
    secondsToTimeSpan,
    toDateString,
    toDateTimeString,
} from "../../utils/dates";
import { getMidPoint } from "../../utils/locations";
import PageNotFound from "../PageNotFound";
import styles from "./Job.module.scss";
import { useJobMapFeature } from "./useJobMapFeature";

const Job = () => {
    const { id } = useParams();
    const job = useJob(id ?? "");
    const jobDocuments = useDocuments({
        jobId: id,
        disableFuelTypeFiltering: true,
    });

    const comments = useJobComments(id ?? "");

    const issues = useIssuesForModel({
        id: id ?? "",
        modelType: "job",
    });

    const answers = useAnswersForJob(id ?? "", {
        includeDeletedAnswers: false,
    });
    const followOnJobs = useFollowOnJobs(id ?? "");

    const {
        show: showDeleteManualUploadModal,
        hide: hideDeleteManualUploadModal,
        visible: isDeleteManualUploadModalVisible,
    } = useToggle();

    const canDeleteManualUpload = useHasUserParentPermission(
        "portal_admin",
        job.value?.landlord.userId,
    );

    const navigate = useNavigate();
    const goBack = useCallback(() => {
        hideDeleteManualUploadModal();
        navigate(-1);
    }, [hideDeleteManualUploadModal, navigate]);

    const [manualUploadPaperworkUrl, setManualUploadPaperworkUrl] =
        useState("");

    const getFileUrl = useFileUrl();
    const manualUploadPaperwork = useMemo(() => {
        if (job.value && job.value.paperwork.length > 0) {
            const paperwork = job.value.paperwork[0];

            getFileUrl(paperwork.documentUrl).subscribe((url) =>
                setManualUploadPaperworkUrl(url),
            );

            return paperwork;
        }
    }, [getFileUrl, job.value]);

    const isElectricJob = useMemo<boolean>(
        () => job.value !== null && job.value.complianceType.id === 20,
        [job.value],
    );

    const { t } = useTranslation();

    const { value: tasks, loaded: tasksLoaded, getTasks } = useJobTasks();
    useEffect(() => {
        getTasks(Number(id)).subscribe();
    }, [id, getTasks]);

    const columns = useMemo<IColumn[]>(() => {
        const renderPaperwork = (value: unknown, data: IJob) =>
            data.paperwork.length !== 0 ? (
                <JobPaperworkDropdown
                    paperwork={data.paperwork}
                    disableFuelTypeCheck={true}
                />
            ) : (
                <p>{t("No Paperwork Found")}</p>
            );

        const renderComplianceType = (complianceType: IComplianceType) =>
            complianceType && (
                <ColourPill
                    customColour={complianceType.colour}
                    value={t(complianceType.displayName)}
                />
            );

        const renderEngineer = (value: string, data: IJob) => (
            <Link url={`/management/engineers/${value}`}>
                {data.engineer.name}
            </Link>
        );

        const renderProperty = (value: string, data: IJob) => (
            <Link url={`/management/properties/${value}`}>
                {data.property.addressString}
            </Link>
        );

        const list: IColumn[] = [
            {
                title: t("Paperwork"),
                path: "paperwork",
                type: "component",
                render: renderPaperwork,
            },
            {
                title: t("Job Number"),
                path: "id",
                type: "component",
                render: (value: string) => `#${value}`,
            },
            {
                title: t("Compliance Category"),
                type: "component",
                render: renderComplianceType,
                path: "complianceType",
            },
            {
                title: t("Job Type"),
                type: "string",
                path: "jobType",
            },
            {
                title: t("Date"),
                type: "date",
                path: "date",
            },
            {
                title: t("Started At"),
                type: "time",
                path: "date",
                hidden: job.value && job.value.recoveryJob,
            },
            {
                title: t("Ended At"),
                type: "time",
                path: "endDate",
                hidden: job.value && job.value.recoveryJob,
            },
            {
                title: t("Engineer"),
                type: "component",
                path: "engineer.id",
                render: renderEngineer,
            },
            {
                title: t("Property"),
                path: "property.id",
                type: "component",
                render: renderProperty,
            },
            {
                title: t("Time Taken"),
                path: "totalTime",
                type: "component",
                hidden: job.value && job.value.recoveryJob,
                render: (value, data: IJob) => {
                    if (data.endDate !== defaultDate) {
                        return secondsToTimeSpan(
                            getDifferenceInSeconds(
                                new Date(data.date),
                                new Date(data.endDate),
                            ),
                        );
                    }
                },
            },
            {
                title: t("Distance Between Job and Property"),
                path: "distanceBetween",
                type: "component",
                hidden: job.value && job.value.recoveryJob,
                render: (value: number, data: IJob) => `${value}km`,
            },
            {
                title: t("Total Tasks"),
                path: "tasks",
                type: "component",
                hidden: job.value && job.value.recoveryJob,
                render: () => (tasks ? tasks.length : 0),
            },
            {
                title: t("SLA Hit"),
                type: "string",
                path: "slaHit",
                hidden: job.value && job.value.recoveryJob,
            },
            {
                title: t("UPRN"),
                type: "string",
                path: "uprn",
                hidden: job.value && job.value.recoveryJob,
            },
            {
                title: t("Upload Date"),
                type: "date",
                path: "createdAt",
                hidden: job.value && !job.value.recoveryJob,
            },
            {
                title: t("Onsite Audit Carried Out"),
                type: "component",
                path: "onsiteAudit",
                render: (value) => (value ? "Yes" : "No"),
            },
            {
                title: t("Onsite Audit Date"),
                type: "component",
                path: "onsiteAudit",
                render: (value: string | null) =>
                    value ? toDateString(new Date(value)) : "",
            },
            {
                title: t("Uploaded By"),
                type: "component",
                path: "createdAt",
                render: () =>
                    manualUploadPaperwork && manualUploadPaperwork.user
                        ? manualUploadPaperwork.user.name
                        : "",
                hidden: job.value && !job.value.recoveryJob,
            },
            {
                title: t("Service Date Reset"),
                type: "component",
                path: "forceServiceDateReset",
                hidden: job.value && !job.value.recoveryJob,
                render: (value) => (value ? "Yes" : "No"),
            },
        ];

        if (followOnJobs.value && followOnJobs.value.allocatedJobs.length > 0) {
            const renderFollowOnJobs = () => (
                <>
                    {followOnJobs.value.allocatedJobs.map((followOn) => (
                        <div key={followOn.id}>
                            {toDateTimeString(new Date(followOn.jobDate))}
                            &nbsp;
                            {followOn.isComplete && followOn.job ? (
                                <Link url={`/jobs/jobs/${followOn.job.id}`}>
                                    {t("Complete")}
                                </Link>
                            ) : followOn.unableToAccess ? (
                                <Link
                                    url={`/issues/unabletoaccess/${followOn.unableToAccessId}`}
                                >
                                    {t("UTA")}
                                </Link>
                            ) : (
                                t("Allocated")
                            )}
                        </div>
                    ))}
                    {followOnJobs.value.completed
                        ? t("SLA job completed")
                        : t("Follow on work required")}
                </>
            );

            list.push({
                title: t("Follow on jobs"),
                type: "component",
                path: "",
                render: renderFollowOnJobs,
            });
        }

        if (job.value && job.value.eicr) {
            list.push({
                title: t("Certificate status"),
                type: "string",
                path: "eicr.paperworkStatus.status",
                hidden: job.value && job.value.recoveryJob,
            });
        }

        if (job.value && job.value.externalJobReference) {
            list.splice(2, 0, {
                title: t("External Reference Number"),
                path: "externalJobReference",
                type: "component",
                render: (value: string) => `#${value}`,
            });
        }

        if (job.value && job.value.limitationSet) {
            list.push({
                title: t("Agreed Limitations"),
                path: "limitationSet",
                type: "component",
                render: (value: IAgreedLimitationSet) => {
                    return value.limitations
                        .sort((a, b) => a.order - b.order)
                        .map((lim) => {
                            return (
                                <div key={lim.order + lim.text}>{lim.text}</div>
                            );
                        });
                },
            });
        }

        return list;
    }, [t, job.value, followOnJobs.value, tasks, manualUploadPaperwork]);

    // TODO: Create an env variable and get this feature flag from there XT-1142.
    const mapFeature = true;
    const { markers } = useJobMapFeature(mapFeature, job);

    return (
        <>
            {job.loaded &&
            issues.loaded &&
            tasksLoaded &&
            answers.loaded &&
            comments.loaded &&
            followOnJobs.loaded ? (
                <Grid>
                    {job.value.parentJobId !== null && (
                        <GridColumn>
                            <Card>
                                <Breadcrumb>
                                    <BreadcrumbItem isActive={false}>
                                        <Link
                                            url={`/jobs/jobs/${job.value.parentJobId}`}
                                        >
                                            {t("Parent job")} #
                                            {job.value.parentJobId}
                                        </Link>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem isActive={true}>
                                        #{job.value.id}
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            </Card>
                        </GridColumn>
                    )}

                    <GridColumn size="half">
                        <Card
                            title={
                                job.value.recoveryJob ? (
                                    <>{t("Manual Upload")}</>
                                ) : (
                                    t("Job Details")
                                )
                            }
                            actions={
                                canDeleteManualUpload &&
                                job.value.recoveryJob && (
                                    <>
                                        <Button
                                            onClick={
                                                showDeleteManualUploadModal
                                            }
                                        >
                                            {t("Delete")}
                                        </Button>
                                    </>
                                )
                            }
                        >
                            <SimpleTable
                                single={true}
                                columns={columns}
                                data={job.value}
                            />
                        </Card>
                        {canDeleteManualUpload &&
                            isDeleteManualUploadModalVisible &&
                            job.value !== null && (
                                <DeleteManualUploadModal
                                    hide={goBack}
                                    jobId={job.value.id}
                                />
                            )}
                        {!job.value.recoveryJob && (
                            <>
                                <IssuesLog issues={issues.records} />
                                {id && isElectricJob && (
                                    <Card title={t("Observations")}>
                                        <ObservationsLog
                                            id={id}
                                            modelType="job"
                                        />
                                    </Card>
                                )}
                            </>
                        )}

                        {isElectricJob && (
                            <JobCommentsTimeline comments={comments.value} />
                        )}
                    </GridColumn>

                    <GridColumn size="half">
                        <Card padding={false} flexBasis={true}>
                            {!job.value.recoveryJob ? (
                                mapFeature && (
                                    <Map
                                        lat={
                                            job.value.property.lat
                                                ? getMidPoint(
                                                      job.value.property.lat,
                                                      job.value.lat,
                                                  )
                                                : 0
                                        }
                                        lng={
                                            job.value.property.long
                                                ? getMidPoint(
                                                      job.value.property.long,
                                                      job.value.long,
                                                  )
                                                : 0
                                        }
                                        markers={markers}
                                    />
                                )
                            ) : (
                                <object
                                    data={manualUploadPaperworkUrl}
                                    className={styles.documentViewer}
                                    aria-label={t("Manual upload paperwork")}
                                />
                            )}
                        </Card>
                        {!job.value.recoveryJob && (
                            <Card title={t("Timeline")}>
                                <JobTimeline
                                    jobs={[
                                        {
                                            ...job.value,
                                            engineer: job.value.engineer,
                                            property: {
                                                id: job.value.property.id,
                                                addressString:
                                                    job.value.property
                                                        .addressString,
                                            },
                                            tasks: tasks,
                                        },
                                    ]}
                                    openChildJobsInNewWindow={true}
                                    showAddress={false}
                                    showJobType={false}
                                    showTasks={true}
                                    answers={answers.value}
                                />
                            </Card>
                        )}

                        <PaperworkLogCard
                            paperwork={jobDocuments.records}
                            refreshPaperwork={jobDocuments.refresh}
                            disableFuelTypeFiltering={true}
                            landlordId={job.value.landlord.id}
                            propertyId={job.value.property.id}
                            jobId={job.value.id}
                            propertyAddress={job.value.property.addressString}
                            isLoaded={jobDocuments.loaded}
                        />
                    </GridColumn>
                </Grid>
            ) : !job.error ? (
                <Loading />
            ) : (
                <PageNotFound />
            )}
        </>
    );
};

export default Job;
