import { IRealtimeValue } from ".";

function realtimeUpdateList<T extends IRealtimeValue, U extends T>(
    realtimeValue: U,
    currentValue: T[],
) {
    const list = [...currentValue];

    const updateIndex = list.findIndex((val) => val.id === realtimeValue.id);

    if (updateIndex !== -1) {
        Object.keys(list[0]).forEach((key) => {
            if (Object.prototype.hasOwnProperty.call(realtimeValue, key)) {
                (list[updateIndex] as any)[key] = realtimeValue[key];
            }
        });

        return list;
    }

    return false;
}

export default realtimeUpdateList;
