import { useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { PropertyCategoriesContext } from "../../../../components";
import GroupedBarChart, {
    IGroupedData,
} from "../../../../components/Charts/GroupedBarChart";
import { usePhaseBoundaries } from "../../../../hooks/usePhaseBoundaries";
import { IPhase } from "../../../../utils/api/properties";
import { toDateString } from "../../../../utils/dates";
import { IUrlParameters, encodeUrl } from "../../../../utils/url";

const PropertyGraph = ({
    phases,
    maxHeight,
    dateFilterField,
    targetUrl,
    targetUrlParams,
    phaseTimeFrameMultiplier,
    areDatesInPast,
}: IPropertyGraphProps) => {
    const { selectedCategories } = useContext(PropertyCategoriesContext);

    const labels = useMemo(() => {
        const array: string[] = [];

        for (const phase of phases) {
            const phaseArray = Array<string>(
                phase.phaseTimeFrame - array.length,
            ).fill("");
            phaseArray[phaseArray.length - 1] = phase.phaseTimeFrame.toString();

            array.push(...phaseArray);
        }

        if (array.length > 0) {
            array[0] = "0";
        }

        return array;
    }, [phases]);

    const tooltipLabels = useMemo(() => {
        const array: string[] = [];

        for (const phase of phases) {
            array.push(
                ...phase.dates.map((date) => toDateString(new Date(date.date))),
            );
        }

        return array;
    }, [phases]);

    const navigate = useNavigate();

    const phasesGroups = usePhaseBoundaries(
        phases,
        phaseTimeFrameMultiplier,
        areDatesInPast,
    );

    const groups = useMemo(() => {
        const array: IGroupedData[] = phasesGroups.map((group) => ({
            title: group.phase.title,
            color: group.phase.colour,
            data: group.phase.dates.map((date) => ({
                label: toDateString(new Date(date.date)),
                value: date.count.map((count) => ({
                    label: count.fuelType.displayName,
                    value: count.count,
                    colour:
                        selectedCategories.length === 0
                            ? count.fuelType.color
                            : group.phase.colour,
                })),
                onClick: () => {
                    navigate(
                        encodeUrl(targetUrl, {
                            ...targetUrlParams,
                            [dateFilterField]: [
                                `>=${group.from.toISOString()}{AND}<${group.to.toISOString()}`,
                            ],
                        }),
                    );
                },
            })),
            onClick: () => {
                navigate(
                    encodeUrl(targetUrl, {
                        ...targetUrlParams,
                        [dateFilterField]: [
                            `>=${group.from.toISOString()}{AND}<${group.to.toISOString()}`,
                        ],
                    }),
                );
            },
        }));

        return array;
    }, [
        phasesGroups,
        selectedCategories.length,
        navigate,
        targetUrl,
        targetUrlParams,
        dateFilterField,
    ]);

    return (
        <GroupedBarChart
            groups={groups}
            labels={labels}
            tooltipLabels={tooltipLabels}
            maxHeight={maxHeight}
        />
    );
};

interface IPropertyGraphProps {
    phases: IPhase[];
    maxHeight?: number;
    dateFilterField: string;
    targetUrl: string;
    targetUrlParams?: IUrlParameters;
    phaseTimeFrameMultiplier: number;
    areDatesInPast: boolean;
}

export default PropertyGraph;
