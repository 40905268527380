import { IPropertyCategory } from ".";
import config from "../../../config";
import useFilterable from "../useFilterable";

const usePropertyCategories = () => {
    const filterable = useFilterable<IPropertyCategory>(
        `${config.miscApiUrl}/lookups/propertycategories`,
        {
            params: {
                itemsPerPage: 100,
            },
        },
    );
    return filterable;
};

export default usePropertyCategories;
