import { useTranslation } from "react-i18next";
import useActiveTagCount from "../../../utils/api/properties/useActiveTagCount";
import Loading from "../../Loading/Loading";
import styles from "./ActiveTags.module.scss";

const ActiveTags = () => {
    const { t } = useTranslation();
    const activeTagCount = useActiveTagCount();

    return (
        <div className={styles.container} data-test-id="TagsRegistered_Div">
            {activeTagCount.loaded ? (
                <>
                    {t("Tags Registered")}
                    <br />
                    {activeTagCount.value.active} / {activeTagCount.value.total}
                </>
            ) : (
                <Loading small={true} smallWidth={34} />
            )}
        </div>
    );
};

export default ActiveTags;
