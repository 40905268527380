import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Select } from "../../../../components";
import { ILandlordPropertyCategory } from "../../../../utils/api/landlords";

const PropertyCategoryAccessLevel = ({
    propertyCategory,
    updatePropertyCategory,
}: IPropertyCategoryAccessLevelProps) => {
    const { t } = useTranslation();

    const value = useMemo(() => {
        if (propertyCategory.isOpen) {
            return "All Users";
        } else if (propertyCategory.isBlocked) {
            return "Blocked";
        }

        return "Only Approved";
    }, [propertyCategory]);

    const options = useMemo(
        () => [
            {
                label: t("All Users"),
                value: "All Users",
            },
            {
                label: t("Only Approved"),
                value: "Only Approved",
            },
            {
                label: t("Blocked"),
                value: "Blocked",
            },
        ],
        [t],
    );

    const handleChange = useCallback(
        (newValue: string) => {
            updatePropertyCategory({
                ...propertyCategory,
                isBlocked: newValue === "Blocked",
                isRestricted: newValue === "Only Approved",
                isOpen: newValue === "All Users",
            });
        },
        [updatePropertyCategory, propertyCategory],
    );

    return (
        <>
            <Select
                allowEmpty={true}
                options={options}
                onChange={handleChange}
                value={value}
            />
        </>
    );
};

interface IPropertyCategoryAccessLevelProps {
    propertyCategory: ILandlordPropertyCategory;
    updatePropertyCategory: (
        propertyCategory: ILandlordPropertyCategory,
    ) => void;
}

export default PropertyCategoryAccessLevel;
