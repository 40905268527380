import { IValidationResult } from ".";

const isNumber = (error = "Please type a valid number.") => {
    const validate = (value: string): IValidationResult => {
        const isValid = (value.trim() ? true : false) && !isNaN(Number(value));

        return {
            isValid,
            error: isValid ? "" : error,
        };
    };

    return { validate };
};

export default isNumber;
