import { keys, pickBy, some } from "lodash";
import { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import {
    Button,
    Card,
    Checkbox,
    Form,
    InputField,
    Loading,
    UserContext,
    ValidationError,
} from "../../components";
import {
    useAddContractor,
    useSearchContractor,
} from "../../utils/api/landlords";
import {
    isInteger,
    isRequired,
    useValidateField,
    validateForm,
} from "../../utils/validation";
import isTrue from "../../utils/validation/isTrue";
import styles from "./ContractorAdd.module.scss";

const ContractorAdd = () => {
    const { t } = useTranslation();
    const [businessRegistration, setBusinessRegistration] = useState("");

    const { user } = useContext(UserContext);
    const {
        value: contractor,
        getContractor,
        error: getContractorError,
        loading: getContractorLoading,
    } = useSearchContractor();
    const { addContractor, loading, error } = useAddContractor();

    const businessRegistrationValidator = useValidateField(
        businessRegistration,
        isRequired(),
        isInteger(),
    );
    const searchFormValidator = validateForm(() => [
        businessRegistrationValidator,
    ]);

    const handleSearch = useCallback(() => {
        getContractor(businessRegistration).subscribe();
    }, [businessRegistration, getContractor]);

    const [landlords, setLandlords] = useState<{ [key: string]: boolean }>({});
    const landlordsValidator = useValidateField(
        some(landlords),
        isTrue(t("Please select at least one landlord.")),
    );
    const addFormValidator = validateForm(() => [landlordsValidator]);

    const handleLandlordClick = (checked: boolean, value: string) => {
        const tempLandlords = { ...landlords };

        tempLandlords[value] = checked;
        setLandlords(tempLandlords);
    };

    const navigate = useNavigate();
    const handleAddContractor = () => {
        if (contractor) {
            addContractor({
                contractorId: contractor.id,
                userParentIds: keys(
                    pickBy(landlords, (landlord) => landlord),
                ).map((landlord) => Number(landlord)),
            }).subscribe(() => navigate("/management/contractors"));
        }
    };

    const activeUserParents = useMemo(
        () => user.userParents.filter((u) => u.isActive),
        [user.userParents],
    );

    return (
        <>
            <Form onSubmit={handleSearch} {...searchFormValidator}>
                <Card>
                    <InputField
                        label={t("Gas Safe business registration number")}
                        placeholder={t("6 Digit Number")}
                        value={businessRegistration}
                        onChange={setBusinessRegistration}
                        {...businessRegistrationValidator}
                    />

                    {getContractorLoading ? (
                        <Loading />
                    ) : (
                        <Button type="submit" variant="primary">
                            {t("Search")}
                        </Button>
                    )}

                    <ValidationError
                        error={
                            getContractorError === "ajax error 504"
                                ? t(
                                      "We are unable to connect to the Gas Safe Register to verify your details. Please try later.",
                                  )
                                : t(
                                      "This Gas Safe number can not be found. Please check the number and resubmit the form.",
                                  )
                        }
                        isValid={!getContractorError}
                    />
                </Card>
            </Form>

            {loading && (
                <Card>
                    <Loading visible={true} />
                </Card>
            )}

            {contractor && (
                <Form onSubmit={handleAddContractor} {...addFormValidator}>
                    <Card title={contractor.name}>
                        {contractor.addressString
                            .split(",")
                            .map((line, index) => {
                                return <div key={index}>{line}</div>;
                            })}
                        <div>
                            <div className={styles.selectLandlord}>
                                {t("Select a Landlord")}
                            </div>

                            {activeUserParents.map((userParent) => (
                                <Checkbox
                                    key={userParent.id}
                                    value={userParent.userParentId.toString()}
                                    checked={
                                        landlords[
                                            userParent.userParentId.toString()
                                        ]
                                    }
                                    onChange={handleLandlordClick}
                                >
                                    {userParent.landlord?.name ?? ""}
                                </Checkbox>
                            ))}

                            {!landlordsValidator.isValid && (
                                <ValidationError {...landlordsValidator} />
                            )}
                        </div>
                        <Button
                            type="submit"
                            variant="primary"
                            cssRules={{ marginTop: 10 }}
                            disabled={loading}
                        >
                            {t("Add as contractor")}
                        </Button>
                        <ValidationError error={error} isValid={!error} />
                    </Card>
                </Form>
            )}
        </>
    );
};

export default ContractorAdd;
