import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IHeadersAccordionProps } from ".";
import Collapse from "../../../components/Collapse";
import CommentsList from "../CommentsList";
import styles from "./HeadersAccordion.module.scss";

const HeadersAccordion = ({
    auditId,
    comments,
    remedialActions,
    issues,
    isReadOnly,
    createComment,
}: IHeadersAccordionProps) => {
    const { t } = useTranslation();

    const [expandedSection, setExpandedSection] = useState("");

    return (
        <>
            <Collapse
                title={t("Comments ({{count}})", { count: comments.length })}
                sectionId="comments"
                expandedSectionId={expandedSection}
                onExpandToggle={setExpandedSection}
            >
                <CommentsList
                    auditId={auditId}
                    comments={comments}
                    createComment={createComment}
                    isReadOnly={isReadOnly}
                    isRemedial={false}
                />
            </Collapse>

            <Collapse
                title={t("Remedial actions ({{count}})", {
                    count: remedialActions.length,
                })}
                sectionId="remedial"
                expandedSectionId={expandedSection}
                onExpandToggle={setExpandedSection}
            >
                <CommentsList
                    auditId={auditId}
                    comments={remedialActions}
                    createComment={createComment}
                    isReadOnly={isReadOnly}
                    isRemedial={true}
                />
            </Collapse>

            <Collapse
                title={t("Issues ({{count}})", { count: issues.length })}
                sectionId="issues"
                expandedSectionId={expandedSection}
                onExpandToggle={setExpandedSection}
            >
                {issues.map((issue) => (
                    <div key={issue.id} className={styles.issue}>
                        <div
                            className={`${styles.issueStatus} ${
                                issue.isResolved
                                    ? styles.resolved
                                    : styles.unresolved
                            }`}
                        >
                            {t(issue.isResolved ? "Resolved" : "Unresolved")}
                        </div>
                        {issue.title}
                    </div>
                ))}
            </Collapse>
        </>
    );
};

export default HeadersAccordion;
