import { useContext, useEffect } from "react";
import { filter, map } from "rxjs";
import { IMapProperty, IProperty } from ".";
import { RealtimeContext } from "../../../components";
import config from "../../../config";
import { clearCache } from "../../cache";
import { realtimeUpdateList } from "../../realtime";
import useFilterable from "../useFilterable";

const useMapProperties = () => {
    const { realtimeObservable } = useContext(RealtimeContext);
    const { records, loaded, updateValue, applySearch, toggleFilter } =
        useFilterable<IMapProperty>(`${config.propertiesApiUrl}/map`);

    useEffect(() => {
        if (records) {
            const propertyRealtimeSubscription = realtimeObservable
                .pipe(
                    filter(
                        (e) => e.event === "updated" && e.entity === "property",
                    ),
                    map((e) => e.payload as IProperty),
                )
                .subscribe((value) => {
                    const updatedList = realtimeUpdateList(
                        {
                            id: value.id,
                            lat: value.lat,
                            long: value.long,
                        },
                        records,
                    );

                    if (updatedList) {
                        updateValue(updatedList);
                    }

                    clearCache();
                });

            return () => {
                if (propertyRealtimeSubscription) {
                    propertyRealtimeSubscription.unsubscribe();
                }
            };
        }
    }, [realtimeObservable, records, updateValue]);

    return { records, loaded, applySearch, toggleFilter };
};

export default useMapProperties;
