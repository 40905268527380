import { ReactNode } from "react";
import { Link as ReactLink } from "react-router-dom";
import { LinkVariant } from ".";
import { useCssClasses } from "../../hooks";
import styles from "./Link.module.scss";

const Link = ({
    url,
    ariaLabel,
    children,
    display = "",
    margin,
    align = "",
    variant = "",
    color,
    testId,
    openInNewWindow = false,
    onFocus,
    onBlur,
}: ILinkProps) => {
    const cssClasses = useCssClasses(
        variant === "bordered" ? styles.bordered : "",
        display === "block" ? styles.block : "",
        align === "center" ? styles.textCenter : "",
    );

    const linkCssClasses = useCssClasses(
        styles.link,
        color === "red"
            ? styles.red
            : color === "black"
            ? styles.black
            : color === "orange"
            ? styles.orange
            : color === "green"
            ? styles.green
            : color === "grey"
            ? styles.grey
            : color === "darkGrey"
            ? styles.darkGrey
            : "",
    );

    return (
        <span
            className={cssClasses}
            style={{ margin }}
            onMouseOver={onFocus}
            onMouseOut={onBlur}
            onFocus={onFocus}
            onBlur={onBlur}
        >
            {url.startsWith("http") ? (
                <a
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    data-test-id={testId}
                    aria-label={ariaLabel}
                    className={linkCssClasses}
                >
                    {children}
                </a>
            ) : (
                <ReactLink
                    to={url}
                    data-test-id={testId}
                    target={openInNewWindow ? "_blank" : ""}
                    aria-label={ariaLabel}
                    className={linkCssClasses}
                >
                    {children}
                </ReactLink>
            )}
        </span>
    );
};

export type Align = "center" | "";
export type Display = "block" | "";

interface ILinkProps {
    url: string;
    children: ReactNode;
    ariaLabel?: string;
    display?: Display;
    margin?: string;
    align?: Align;
    variant?: LinkVariant;
    color?: "red" | "black" | "orange" | "green" | "grey" | "darkGrey";
    testId?: string;
    openInNewWindow?: boolean;
    onFocus?: () => void;
    onBlur?: () => void;
}

export default Link;
