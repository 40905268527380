import { useCallback } from "react";
import { IQualifiedSupervisorAuditSectionHeaderProps } from ".";
import { Icon } from "../../../components";
import { useCssClasses, useToggle } from "../../../hooks";
import useClickableNonInteractiveElement from "../../../hooks/useClickableNonInteractiveElement";
import styles from "./QualifiedSupervisorAuditSectionHeader.module.scss";

const QualifiedSupervisorAuditSectionHeader = ({
    id,
    title,
    isReviewed,
    paddingLeft = false,
    children,
}: IQualifiedSupervisorAuditSectionHeaderProps) => {
    const { toggle, visible } = useToggle();

    const cssClasses = useCssClasses(
        styles.sideItem,
        isReviewed ? styles.reviewed : "",
        paddingLeft ? styles.paddingLeft : "",
        children ? styles.collapse : "",
    );

    const iconCssClasses = useCssClasses(
        styles.icon,
        visible ? "" : styles.collapsed,
    );

    const handleClick = useCallback(() => {
        if (children) {
            toggle();
        } else {
            const element = document.getElementById(id);
            element?.scrollIntoView(true);
        }
    }, [children, id, toggle]);

    const { onKeyDown } = useClickableNonInteractiveElement(handleClick);

    return (
        <>
            <div
                onClick={handleClick}
                onKeyDown={onKeyDown}
                role="button"
                tabIndex={0}
                className={cssClasses}
            >
                {title}

                {children && (
                    <span className={iconCssClasses}>
                        <Icon icon="chevron-up" ariaHidden={true} />
                    </span>
                )}
            </div>

            {visible && children}
        </>
    );
};

export default QualifiedSupervisorAuditSectionHeader;
