import { IQualifiedSupervisorAuditSectionProps } from ".";
import { useCssClasses } from "../../../hooks";
import useClickableNonInteractiveElement from "../../../hooks/useClickableNonInteractiveElement";
import styles from "./QualifiedSupervisorAuditSection.module.scss";

const QualifiedSupervisorAuditSection = ({
    id,
    title,
    isReviewed,
    isReadOnly,
    toggleIsReviewed,
    children,
}: IQualifiedSupervisorAuditSectionProps) => {
    const cssClasses = useCssClasses(
        styles.sectionTitle,
        isReviewed ? styles.reviewed : "",
        isReadOnly ? "" : styles.clickable,
    );

    const { onKeyDown } = useClickableNonInteractiveElement(toggleIsReviewed);

    return (
        <div id={id} className={styles.section}>
            <div
                className={cssClasses}
                onClick={isReadOnly ? undefined : toggleIsReviewed}
                onKeyDown={isReadOnly ? undefined : onKeyDown}
                role={isReadOnly ? undefined : "button"}
                tabIndex={isReadOnly ? undefined : 0}
            >
                {title}
            </div>
            {children}
        </div>
    );
};

export default QualifiedSupervisorAuditSection;
