import { IOriginalAnswerProps } from ".";
import { toDateString } from "../../../utils/dates";
import ViewImageAnswer from "../ViewImageAnswer";
import styles from "./OriginalAnswer.module.scss";

const OriginalAnswer = ({ answer, allAnswers }: IOriginalAnswerProps) => {
    switch (answer.type) {
        case "PhotoButton":
        case "SignatureButton": {
            if (answer.questionName.endsWith("Result")) {
                const notesQuestionKey = `${answer.questionName.substring(
                    0,
                    answer.questionName.length - "Result".length,
                )}Notes`;
                const notes = allAnswers.find(
                    (a) => a.questionName === notesQuestionKey,
                );

                return (
                    <ViewImageAnswer
                        imageUrl={answer.payload ?? ""}
                        description={notes?.payload}
                    />
                );
            }

            return null;
        }
        case "ButtonAddRepeater": {
            return <div className={styles.multiLine}>{answer.payload}</div>;
        }
        case "InspectionSchedule":
        case "ToggleButtonGroup": {
            if (answer.payload.Observation) {
                return <>{answer.payload.Observation.Code}</>;
            }

            return <>{answer.payload.Text}</>;
        }
        case "DatePicker": {
            if (answer.payload) {
                const date = new Date(answer.payload);
                return (
                    <>
                        {date.toString() !== "Invalid Date"
                            ? toDateString(date)
                            : ""}
                    </>
                );
            }

            return <></>;
        }
        default: {
            return <>{answer.payload}</>;
        }
    }
};

export default OriginalAnswer;
