import { useCallback, useContext } from "react";
import { UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const useAdminDisableUser = () => {
    const { activeUserParentsIds } = useContext(UserContext);
    const { send, loading } = useApiRequest({});
    const sendRequest = useXTagApiRequest();

    const disableUser = useCallback(
        (userId: number) =>
            send(
                sendRequest({
                    url: `${config.usersApiUrl}/v2/${userId}/disable`,
                    method: "POST",
                    urlParams: getGlobalUrlParameters([], activeUserParentsIds),
                }),
            ),
        [activeUserParentsIds, send, sendRequest],
    );

    return { disableUser, loading };
};

export default useAdminDisableUser;
