import { RefObject } from "react";
import { IPropertyCategory } from "../../../../../utils/api/misc";
import ComplianceTypesPopover from "./ComplianceTypesPopover";

export interface IComplianceTypesPopoverProps {
    anchorElement: RefObject<HTMLButtonElement>;
    selectedCategories: IPropertyCategory[];
    allCategory: IPropertyCategory;
    hide: () => void;
    onSave: (ids: number[]) => void;
}

export default ComplianceTypesPopover;
