import { useContext, useEffect } from "react";
import { filter, map } from "rxjs";
import { IObservation, IObservationCounts } from ".";
import {
    PropertyCategoriesContext,
    RealtimeContext,
    UserContext,
} from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiValue from "../useApiValue";

const useObservationCounts = () => {
    const { realtimeObservable } = useContext(RealtimeContext);

    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);
    const { value, send } = useApiValue<IObservationCounts[]>({
        initialValue: [],
    });
    const sendRequest = useXTagApiRequest();

    useEffect(() => {
        if (value.length > 0) {
            const subscription = realtimeObservable
                .pipe(
                    filter((e) => e.entity === "issue"),
                    map((e) => {
                        return {
                            event: e.event,
                            payload: e.payload as IObservation,
                        };
                    }),
                )
                .subscribe(({ payload }) => {
                    const statuses = [...value];

                    const status = statuses.find(
                        (c) => c.statusLabel === payload.status.displayName,
                    );

                    if (status) {
                        status.count++;

                        if (payload.status.displayName === "Resolved") {
                            const unresolved = statuses.find(
                                (c) => c.statusLabel === "Unresolved",
                            );
                            unresolved && --unresolved.count;
                        }
                    }
                });

            return () => {
                if (subscription) {
                    subscription.unsubscribe();
                }
            };
        }
    }, [realtimeObservable, value]);

    useEffect(() => {
        const subscription = send(
            sendRequest({
                url: `${config.issuesApiUrl}/observations/statuscount`,
                method: "GET",
                urlParams: getGlobalUrlParameters(
                    selectedCategories.map((c) => c.id),
                    activeUserParentsIds,
                ),
            }),
        ).subscribe();

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [activeUserParentsIds, selectedCategories, send, sendRequest]);

    return { value };
};

export default useObservationCounts;
