import { IProperty } from "../../../utils/api/properties";
import PropertyDetailsEditModal from "./PropertyDetailsEditModal";

export interface IPropertyDetailsEditModalProps {
    property: IProperty;
    updateProperty: (property: IProperty) => void;
    hide: () => void;
}

export default PropertyDetailsEditModal;
