import { useCallback } from "react";
import config from "../../../config";
import { useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const useRequestChangePasswordEmail = () => {
    const { send, loading } = useApiRequest({});
    const sendRequest = useXTagApiRequest();

    const requestChangePasswordEmail = useCallback(
        () =>
            send(
                sendRequest({
                    url: `${config.usersApiUrl}/v2/request-change-password`,
                    method: "POST",
                }),
            ),
        [send, sendRequest],
    );

    return { requestChangePasswordEmail, loading };
};

export default useRequestChangePasswordEmail;
