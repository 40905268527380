import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Loading } from "../../../components";
import { useAppliances } from "../../../utils/api/appliances";
import { IFilterableParams } from "../../../utils/api/useFilterable";
import UnsafeApplianceRow from "../UnsafeApplianceRow";

const UnsafeAppliances = ({
    propertyId,
    safeApplianceIds,
    assignSafeApplianceIds,
}: IUnsafeAppliancesProps) => {
    const { t } = useTranslation();
    const propertyFilter = useMemo<IFilterableParams>(
        () => ({
            filters: {
                ...(propertyId && {
                    propertyId: {
                        operator: "{AND}",
                        filters: [{ function: "=", value: propertyId }],
                    },
                    safeToUse: {
                        operator: "{AND}",
                        filters: [{ function: "=", value: "Unsafe" }],
                    },
                }),
            },
        }),
        [propertyId],
    );

    const unsafeAppliances = useAppliances(propertyFilter);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => unsafeAppliances.refresh(propertyFilter.filters), [
        propertyId,
    ]);

    const setApplianceSafetyStatus = (applianceId: string, isSafe: boolean) => {
        let list = [...safeApplianceIds];

        if (isSafe) {
            if (!list.includes(applianceId)) {
                list.push(applianceId);
            }
        } else {
            if (list.includes(applianceId)) {
                list = list.filter((apl) => apl !== applianceId);
            }
        }

        assignSafeApplianceIds(list);
    };

    return unsafeAppliances.records ? (
        <>
            {unsafeAppliances.records.length > 0 && (
                <div>
                    {t("Does the document change an asset's status to safe?")}
                </div>
            )}
            {unsafeAppliances.records.map((appliance) => {
                return (
                    <UnsafeApplianceRow
                        key={appliance.id}
                        appliance={appliance}
                        isSafe={safeApplianceIds.includes(appliance.id)}
                        setSafetyStatus={setApplianceSafetyStatus}
                    />
                );
            })}
        </>
    ) : (
        <Loading />
    );
};

interface IUnsafeAppliancesProps {
    propertyId: string;
    safeApplianceIds: string[];
    assignSafeApplianceIds: (value: string[]) => void;
}

export default UnsafeAppliances;
