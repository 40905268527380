import { useTranslation } from "react-i18next";
import { IRecentTasksCardProps } from ".";
import { Card, Icon, Link, Loading } from "../../../components";
import RecentTasks from "../RecentTasks";

const RecentTasksCard = ({
    loaded,
    maxHeight,
    data,
}: IRecentTasksCardProps) => {
    const { t } = useTranslation();

    return (
        <Card
            title={t("Recent tasks carried out")}
            maxHeight={maxHeight}
            fullHeight={true}
            testId="RecentTasksCarriedOut_Widget"
            actions={
                <Link url="/jobs/jobs" ariaLabel={t("View all jobs")}>
                    <Icon
                        icon="eye"
                        size={12}
                        display="inline-block"
                        cssRules={{ marginRight: "5px" }}
                        ariaHidden={true}
                    />

                    {t("View all")}
                </Link>
            }
        >
            {loaded ? <RecentTasks tasks={data.recentTasks} /> : <Loading />}
        </Card>
    );
};

export default RecentTasksCard;
