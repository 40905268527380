import { useCallback, useContext } from "react";
import { IReferenceNumber } from ".";
import { UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const useGenerateReferenceNumber = () => {
    const { activeUserParentsIds } = useContext(UserContext);
    const { send, loading } = useApiRequest<IReferenceNumber>({});
    const sendRequest = useXTagApiRequest();

    const generateReferenceNumber = useCallback(
        (propertyId: number) =>
            send(
                sendRequest({
                    url: `${config.allocatedJobsApiUrl}/nextreferencenumber`,
                    method: "POST",
                    urlParams: {
                        propertyId: [propertyId.toString()],
                        ...getGlobalUrlParameters([], activeUserParentsIds),
                    },
                }),
            ),
        [activeUserParentsIds, send, sendRequest],
    );

    return { generateReferenceNumber, loading };
};

export default useGenerateReferenceNumber;
