import {
    ChangeEvent,
    CSSProperties,
    KeyboardEvent,
    ReactNode,
    RefObject,
    useEffect,
    useState,
} from "react";
import { noop } from "rxjs";
import { InputSize } from ".";
import { useCssClasses } from "../../hooks";
import { useHtmlEntityId } from "../../hooks/useHtmlEntityId";
import ValidationError from "../ValidationError";
import styles from "./InputField.module.scss";

const InputField = (props: IInputFieldProps) => {
    const {
        value = "",
        disabled = false,
        type = "text",
        useMargin = true,
        append = null,
        readOnly = false,
        size = "",
        cssRules,
        setRef,
        isValid,
        error,
        onChange = noop,
        onFocus = noop,
        onKeyDown = noop,
        testId,
    } = props;
    const label = "label" in props ? props.label : undefined;
    const placeholder = "placeholder" in props ? props.placeholder : undefined;
    const ariaLabel = "ariaLabel" in props ? props.ariaLabel : placeholder;

    const [inputValue, setInputValue] = useState(value);
    useEffect(() => setInputValue(value), [value]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const newValue = event.target.value;

        setInputValue(newValue);
        onChange(newValue);
    };

    const id = useHtmlEntityId();

    const containerCssClasses = useCssClasses(
        styles.container,
        useMargin ? styles.margin : "",
    );

    const inputCssClasses = useCssClasses(
        styles.input,
        size === "small" ? styles.small : "",
    );

    return (
        <div className={containerCssClasses} style={cssRules}>
            <label htmlFor={id} className={styles.label}>
                {label}
            </label>

            <div className={styles.border}>
                <input
                    id={id}
                    className={inputCssClasses}
                    type={type}
                    onChange={handleChange}
                    onFocus={onFocus}
                    onKeyDown={onKeyDown}
                    placeholder={placeholder}
                    value={inputValue}
                    readOnly={readOnly}
                    ref={setRef}
                    autoComplete="off"
                    disabled={disabled}
                    aria-label={ariaLabel}
                    data-test-id={testId}
                />
                {append} &nbsp;
            </div>

            {!disabled && <ValidationError isValid={isValid} error={error} />}
        </div>
    );
};

interface IInputFieldBaseProps {
    value?: string;
    type?: "text" | "password" | "time" | "number";
    disabled?: boolean;
    isValid?: boolean;
    error?: string;
    useMargin?: boolean;
    readOnly?: boolean;
    append?: ReactNode;
    setRef?: RefObject<HTMLInputElement>;
    size?: InputSize;
    cssRules?: CSSProperties;
    onChange?: (value: string) => void;
    onFocus?: () => void;
    onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
    testId?: string;
}

interface IInputFieldLabelProps extends IInputFieldBaseProps {
    label: string;
}

interface IInputFieldPlaceholderProps extends IInputFieldBaseProps {
    placeholder: string;
    ariaLabel?: string;
}

type IInputFieldProps = IInputFieldLabelProps | IInputFieldPlaceholderProps;

export default InputField;
