import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IDocumentPreferencesProps } from ".";
import { Loading, Select } from "../../../components";
import {
    ILandlordDocumentPreferences,
    useLandlordDocumentPreferences,
    useUpdateLandlordDocumentPreferences,
} from "../../../utils/api/landlords";
import { usePaperworkTypes } from "../../../utils/api/misc";
import styles from "./DocumentPreferences.module.scss";
import DocumentPreferencesForm from "./DocumentPreferencesForm";

const DocumentPreferences = ({
    landlordOptions,
}: IDocumentPreferencesProps) => {
    const { t } = useTranslation();

    const paperworkTypes = usePaperworkTypes();
    const landlordPreferences = useLandlordDocumentPreferences();
    const updateLandlordDocumentPreferences =
        useUpdateLandlordDocumentPreferences();

    const [landlordId, setLandlordId] = useState("");

    const preferences = useMemo(() => {
        let landlord = landlordPreferences.value?.find(
            (p) => p.landlordId === Number(landlordId),
        );

        if (landlordId && !landlord) {
            landlord = {
                landlordId: Number(landlordId),
                preferences: [],
            };
        }

        return landlord;
    }, [landlordId, landlordPreferences.value]);

    const handleUpdatePreferences = useCallback(
        (newPreferences: ILandlordDocumentPreferences) => {
            const list = landlordPreferences.value.filter(
                (p) => p.landlordId !== Number(landlordId),
            );

            list.push(newPreferences);

            updateLandlordDocumentPreferences.update(list).subscribe();
        },
        [
            landlordId,
            landlordPreferences.value,
            updateLandlordDocumentPreferences,
        ],
    );

    return (
        <>
            <h2 className={styles.title}>{t("Document Auditing")}</h2>
            <p>
                {t(
                    "Set your preferences for which documents you would like to audit",
                )}
            </p>

            <Select
                label={t("Select a landlord")}
                options={landlordOptions}
                onChange={setLandlordId}
                value={landlordId}
                allowEmpty={true}
            />

            {paperworkTypes.loaded && landlordPreferences.loaded ? (
                preferences && (
                    <DocumentPreferencesForm
                        preferences={preferences}
                        updatePreferences={handleUpdatePreferences}
                        paperworkTypes={paperworkTypes.value}
                        isLoading={updateLandlordDocumentPreferences.loading}
                        error={updateLandlordDocumentPreferences.error}
                    />
                )
            ) : (
                <Loading />
            )}
        </>
    );
};

export default DocumentPreferences;
