import { useCallback } from "react";
import { forkJoin, Observable } from "rxjs";
import {
    useAssignPropertyFlags,
    useRemovePropertyFlags,
} from "../utils/api/misc";
import { clearCache } from "../utils/cache";

export const useUpdatePropertyFlags = (
    propertyIds: number[],
    canRemoveFlags: boolean,
    refresh: () => void,
    hide: () => void,
) => {
    const { assignPropertyFlags } = useAssignPropertyFlags();
    const { removePropertyFlags } = useRemovePropertyFlags();

    const updatePropertyFlags = useCallback(
        (flagsToAssign: number[], flagsToRemove: number[]) => {
            const propertiesToUpdate = propertyIds.map((id) => ({ id }));

            const observables: Observable<unknown>[] = [];

            if (flagsToAssign.length > 0) {
                observables.push(
                    assignPropertyFlags({
                        properties: propertiesToUpdate,
                        flagIds: flagsToAssign,
                    }),
                );
            }

            if (canRemoveFlags && flagsToRemove.length > 0) {
                observables.push(
                    removePropertyFlags({
                        properties: propertiesToUpdate,
                        flagIds: flagsToRemove,
                    }),
                );
            }

            if (observables.length > 0) {
                forkJoin(observables).subscribe(() => {
                    clearCache();
                    refresh();
                    hide();
                });
            } else {
                hide();
            }
        },
        [
            propertyIds,
            canRemoveFlags,
            assignPropertyFlags,
            removePropertyFlags,
            refresh,
            hide,
        ],
    );

    return { updatePropertyFlags };
};
