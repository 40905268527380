import { ReactNode } from "react";

const TabGroupTab = ({ children }: ITabProps) => {
    return <>{children}</>;
};

interface ITabProps {
    header: ReactNode;
    children: ReactNode;
}

export default TabGroupTab;
