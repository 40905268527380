import { ReactNode } from "react";
import { useCssClasses, useToggle } from "../../../hooks";
import ColumnActions, { IColumnActions } from "../ColumnActions";
import styles from "./TableHeaderCell.module.scss";

const TableHeaderCell = (props: ITableHeaderCellProps) => {
    const { visible, show, hide } = useToggle();

    const cssClasses = useCssClasses(
        styles.header,
        props.separator ? styles.separator : "",
    );

    return (
        <th
            className={cssClasses}
            onMouseOver={show}
            onMouseOut={hide}
            onFocus={show}
            onBlur={hide}
        >
            <ColumnActions {...props} visible={visible} />
            {props.children ?? props.title}
        </th>
    );
};

export interface ITableHeaderCellProps extends IColumnActions {
    title?: string;
    children?: ReactNode;
    filterFormatter?: (value: string) => string;
    separator?: boolean;
}

export default TableHeaderCell;
