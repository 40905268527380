import { useTranslation } from "react-i18next";
import { Card, Link, Loading, TabGroup, TabGroupTab } from "../../components";
import { IIssueCounts, useIssueCounts } from "../../utils/api/issues";
import useAllUsers from "../../utils/api/users/useAllUsers";
import IssuesTable from "./IssuesTable";

const Issues = () => {
    const inboxCounts = useIssueCounts();
    const { t } = useTranslation();

    const issueUsers = useAllUsers();

    const getStatusCount = (statusCounts: IIssueCounts[], status: string) => {
        const count = statusCounts.find(
            (statusCount) => statusCount.statusLabel === status,
        );
        return count && count.count > 0 ? `(${count.count})` : "";
    };

    return inboxCounts.loaded && issueUsers.loaded ? (
        <Card
            title={t("Issues")}
            actions={
                <Link url="/issues/create-issue">{t("Add New Issue")}</Link>
            }
            padding={false}
        >
            <TabGroup>
                <TabGroupTab
                    header={
                        <>
                            {`${t("My Issues")} ${getStatusCount(
                                inboxCounts.value,
                                "Inbox",
                            )}`}
                        </>
                    }
                >
                    <IssuesTable status="Inbox" users={issueUsers.records} />
                </TabGroupTab>
                <TabGroupTab
                    header={
                        <>
                            {`${t("Unresolved")} ${getStatusCount(
                                inboxCounts.value,
                                "Unresolved",
                            )}`}
                        </>
                    }
                >
                    <IssuesTable
                        status="Unresolved"
                        users={issueUsers.records}
                    />
                </TabGroupTab>
                <TabGroupTab
                    header={
                        <>
                            {`${t("Resolved")} ${getStatusCount(
                                inboxCounts.value,
                                "Resolved",
                            )}`}
                        </>
                    }
                >
                    <IssuesTable status="Resolved" users={issueUsers.records} />
                </TabGroupTab>
                <TabGroupTab
                    header={
                        <>
                            {`${t("Blocked")} ${getStatusCount(
                                inboxCounts.value,
                                "Blocked",
                            )}`}
                        </>
                    }
                >
                    <IssuesTable status="Blocked" users={issueUsers.records} />
                </TabGroupTab>
            </TabGroup>
        </Card>
    ) : (
        <Loading />
    );
};

export default Issues;
