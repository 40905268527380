import { CSSProperties, ReactNode, RefObject } from "react";
import { noop } from "rxjs";
import { ButtonColor, ButtonSize, ButtonVariant } from ".";
import { useCssClasses } from "../../hooks";
import styles from "./Button.module.scss";

const Button = ({
    onClick = noop,
    children = null,
    variant = "",
    disabled = false,
    size = "",
    type = "button",
    displayBlock = false,
    displayAsLink,
    cssRules = {},
    clickParams = [],
    color = "",
    setRef,
    testId,
}: IButtonProps) => {
    const cssClasses = useCssClasses(
        styles.button,
        variant && styles[variant],
        displayBlock ? styles.block : "",
        size && styles[size],
        color && styles[color],
        displayAsLink ? styles.link : "",
    );

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const handleClick = () => onClick(...clickParams);

    return (
        <button
            className={cssClasses}
            onClick={handleClick}
            disabled={disabled}
            type={type}
            ref={setRef}
            style={cssRules}
            data-test-id={testId}
        >
            {children}
        </button>
    );
};

interface IButtonProps {
    children?: ReactNode;
    disabled?: boolean;
    variant?: ButtonVariant;
    size?: ButtonSize;
    type?: "button" | "submit";
    displayBlock?: boolean;
    displayAsLink?: boolean;
    cssRules?: CSSProperties;
    clickParams?: any[];
    color?: ButtonColor;
    onClick?: (...params: any[]) => void;
    setRef?: RefObject<HTMLButtonElement>;
    testId?: string;
}

export default Button;
