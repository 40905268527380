import { ICircuit } from ".";
import config from "../../../config";
import useAllFilterable from "../useAllFilterable";

const useCircuitsAtProperty = (propertyId: number) => {
    const { records, loaded } = useAllFilterable<ICircuit>({
        url: `${config.assetsApiUrl}/circuits`,
        sortProperties: ["id"],
        filters: {
            propertyId: {
                operator: "{AND}",
                readOnly: true,
                filters: [{ function: "=", value: propertyId.toString() }],
            },
            active: {
                operator: "{AND}",
                readOnly: true,
                filters: [{ function: "=", value: "true" }],
            },
        },
    });

    return { records, loaded };
};

export default useCircuitsAtProperty;
