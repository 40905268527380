import { useTranslation } from "react-i18next";
import { IEicrStatusCardProps } from ".";
import { Card, Icon, Link, Loading } from "../../../components";
import { encodeUrl } from "../../../utils/url";
import EicrStatus from "../EicrStatus";

const EicrStatusCard = ({ loaded, data }: IEicrStatusCardProps) => {
    const { t } = useTranslation();

    return (
        <Card
            title={t("EICR status")}
            fullHeight={true}
            actions={
                <Link
                    url={encodeUrl("/documents")}
                    ariaLabel={t("View all EICR documents")}
                >
                    <Icon
                        icon="eye"
                        size={12}
                        display="inline-block"
                        cssRules={{ marginRight: "5px" }}
                        ariaHidden={true}
                    />
                    {t("View all")}
                </Link>
            }
        >
            {loaded ? <EicrStatus eicrStatus={data.eicrStatus} /> : <Loading />}
        </Card>
    );
};

export default EicrStatusCard;
