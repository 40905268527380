import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { KeyValueWidget, Link } from "../../../components";
import { IColumn } from "../../../components/SimpleTable";
import { useAwaitingAllocationMultipleRepairsCount } from "../../../utils/api/allocatedJobs";
import { IRepairProperty } from "../../../utils/api/misc";

const MultipleRepairs = ({ startDate, endDate }: IMultipleRepairsProps) => {
    const { t } = useTranslation();

    const { value, loaded } = useAwaitingAllocationMultipleRepairsCount({
        startDate,
        endDate,
    });

    const data = useMemo(() => {
        if (value === null) return [];

        const response = value.map((p) => ({
            property_id: p.id,
            address_one: p.address1,
            address_two: p.address2,
            city: p.city,
            county: p.city,
            postcode: p.postcode,
            last_attempt: p.lastAttempt,
            total: p.repairCounts,
        }));

        return response;
    }, [value]);

    const summary = useMemo(() => {
        let repairs2 = 0;
        let repairs3 = 0;
        let repairs4Plus = 0;

        if (value === null) return [];

        value.forEach((p) => {
            if (p.repairCounts === 2) repairs2++;
            else if (p.repairCounts === 3) repairs3++;
            else repairs4Plus++;
        });

        return [
            {
                key: t("2 repairs"),
                value: repairs2,
            },
            {
                key: t("3 repairs"),
                value: repairs3,
            },
            {
                key: t("4 or more repairs"),
                value: repairs4Plus,
            },
        ];
    }, [t, value]);

    const columns = useMemo<IColumn[]>(() => {
        const renderAddress = (address: string, row: IRepairProperty) => (
            <Link url={`/management/properties/${row.property_id}`}>
                {address}
            </Link>
        );

        return [
            {
                title: t("Address"),
                path: "address_one",
                type: "component",
                render: renderAddress,
            },
            {
                title: "",
                path: "address_two",
                type: "string",
            },
            {
                title: t("City"),
                path: "city",
                type: "string",
            },
            {
                title: t("County"),
                path: "county",
                type: "string",
            },
            {
                title: t("Postcode"),
                path: "postcode",
                type: "string",
            },
            {
                title: t("Vist count"),
                path: "total",
                type: "string",
            },
            {
                title: t("Last visit"),
                path: "last_attempt",
                type: "date",
            },
        ];
    }, [t]);

    return (
        <KeyValueWidget
            title={t("Multiple Repairs")}
            summary={summary}
            columns={columns}
            url=""
            data={data}
            tooltipText={t(
                "Shows the amount of properties with multiple repair visits within the time frame supplied.",
            )}
            loaded={loaded}
        />
    );
};

interface IMultipleRepairsProps {
    startDate: Date;
    endDate: Date;
}

export default MultipleRepairs;
