import { useEffect, useMemo, useRef } from "react";
import { IChartData, IDrawableObject } from "..";
import { colours } from "../../../styles/colours";
import { animate } from "../animation";
import { bounce } from "../animations";
import { createChart } from "../chart";
import { roundedRectangle } from "../draw";
import getGrid from "../grid";
import getTooltip from "../tooltip";
import styles from "./BarChart.module.scss";

const BarChart = ({
    data,
    labels,
    tooltipLabels,
    aspectRatio,
    maxHeight,
}: IBarChartProps) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);

    const minValue = useMemo(
        () => Math.min(...data.flatMap((record) => record.values)) || 0,
        [data],
    );
    const maxValue = useMemo(
        () => Math.max(...data.flatMap((record) => record.values)) || 0,
        [data],
    );

    const getBars = (): IDrawableObject => {
        const animation = animate(1500, bounce);
        const groupMargin = 10;
        const barMargin = 1;
        const grid = getGrid({
            minValue,
            maxValue,
            labels,
            gridType: "bar",
            drawYLines: false,
        });
        const tooltip = getTooltip({});

        let barWidth = 0;
        let gridHeight = 0;
        let xAxisPositions: number[] = [];
        let displayTooltip = false;

        const onHover = (x: number, y: number) => {
            const requiresUpdate = grid.onHover(x, y);

            if (requiresUpdate) {
                const gridStatus = grid.getStatus();

                displayTooltip = gridStatus.hover !== null;
                if (gridStatus.hover !== null) {
                    const index = gridStatus.hover.group;

                    tooltip.setData(
                        data.map(
                            (record) =>
                                `${record.label}: ${record.values[index]}`,
                        ),
                        gridStatus.hover.min,
                        gridStatus.hover.max,
                        tooltipLabels && tooltipLabels[index],
                    );
                }
            }

            return requiresUpdate;
        };

        const onResize = (width: number, height: number) => {
            grid.onResize(width, height);
            tooltip.onResize(width, height);

            const gridStatus = grid.getStatus();

            barWidth = (gridStatus.axis.x.width - groupMargin) / data.length;
            gridHeight = gridStatus.height;
            xAxisPositions = gridStatus.axis.x.positions;
        };

        const draw = (context: CanvasRenderingContext2D, time: number) => {
            const isAnimating = animation.updateFrame(time);
            const isGridAnimating = grid.draw(context, time);

            context.save();
            data.forEach((group, colorIndex) => {
                group.values.forEach((value, valueIndex) => {
                    const x =
                        xAxisPositions[valueIndex] +
                        barWidth * colorIndex +
                        barMargin / 2 +
                        groupMargin / 2;
                    const y = animation.getValue(grid.getY(value), gridHeight);

                    const width = barWidth - barMargin;
                    const height = gridHeight - y;

                    if (width > 0 && height > 0) {
                        roundedRectangle(
                            context,
                            x,
                            y,
                            width,
                            height,
                            2,
                            true,
                            true,
                            false,
                            false,
                        );
                        context.fillStyle = group.color
                            ? group.color
                            : colours.chart.colours[colorIndex];
                        context.fill();
                    }
                });
            });
            context.restore();

            let isTooltipAnimating = false;
            if (displayTooltip) {
                isTooltipAnimating = tooltip.draw(context, time);
            }

            return isGridAnimating || isTooltipAnimating || isAnimating;
        };

        return { draw, onResize, onHover };
    };

    useEffect(() => {
        if (canvasRef.current) {
            const chart = createChart({
                aspectRatio,
                canvas: canvasRef.current,
                objects: [getBars()],
                maxHeight,
            });

            return chart.destroy;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        data,
        labels,
        minValue,
        maxValue,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        canvasRef.current ? canvasRef.current.clientWidth : 0,
    ]);

    return <canvas className={styles.chart} ref={canvasRef} />;
};

interface IBarChartProps {
    labels: string[];
    data: IChartData[];
    tooltipLabels?: string[];
    aspectRatio?: number;
    maxHeight?: number;
}

export default BarChart;
