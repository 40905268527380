import Notes from "./Notes";

export interface INote {
    url: string;
    text: string;
    subtitle: string;
    date: string;
}

export default Notes;
