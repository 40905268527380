import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Button,
    Checkbox,
    Form,
    InputField,
    Popover,
    ValidationError,
} from "../..";
import { useToggle } from "../../../hooks";
import { colours } from "../../../styles/colours";
import { useLandlordPreferences } from "../../../utils/api/landlords";
import {
    IFlag,
    useCreateFlag,
    useDeleteFlag,
    useUpdateFlag,
} from "../../../utils/api/misc";
import {
    isRequired,
    useValidateField,
    validateForm,
} from "../../../utils/validation";
import Loading from "../../Loading";
import FlagColour from "../FlagColour";

const EditFlagForm = ({
    flag,
    onFlagsCreate,
    onFlagUpdate,
    onFlagDelete,
    landlordId,
}: IEditFlagFormProps) => {
    const { t } = useTranslation();
    const { hide, toggle, visible } = useToggle();
    const colourPopoverRef = useRef<HTMLDivElement>(null);

    const landlordPreferences = useLandlordPreferences();

    const createFlag = useCreateFlag();
    const updateFlag = useUpdateFlag();
    const deleteFlag = useDeleteFlag();

    const [name, setName] = useState(flag.name);
    const [colour, setColour] = useState(flag.colour);
    const [description, setDescription] = useState(flag.description);
    const [removeAfterService, setRemoveAfterService] = useState(
        flag.removeAfterService,
    );

    const nameValidation = useValidateField(name, isRequired());
    const colourValidation = useValidateField(colour, isRequired());
    const formValidation = validateForm(() => [
        nameValidation,
        colourValidation,
    ]);

    const landlordUserId = useMemo(() => {
        const landlord = landlordPreferences.value?.find(
            (landlordPreference) => landlordPreference.id === landlordId,
        );

        if (landlord) {
            return landlord.preferences.userId;
        }

        return null;
    }, [landlordId, landlordPreferences.value]);

    useEffect(() => {
        setName(flag.name);
        setColour(flag.colour);
        setDescription(flag.description);
        setRemoveAfterService(flag.removeAfterService);

        nameValidation.reset();
        colourValidation.reset();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flag]);

    const clearErrors = () => {
        createFlag.setError("");
        updateFlag.setError("");
        deleteFlag.setError("");
    };

    const handleDeleteFlagClick = () => {
        clearErrors();

        deleteFlag.deleteFlag(flag.id).subscribe((deletedFlag) => {
            onFlagDelete(deletedFlag);
        });
    };

    const handleFormSubmit = () => {
        clearErrors();

        const editFlag = {
            ...flag,
            name,
            colour,
            description,
            removeAfterService,
        };

        if (landlordUserId) {
            if (editFlag.id === 0) {
                createFlag
                    .createNewFlag(editFlag, [landlordUserId])
                    .subscribe((createdFlags) => {
                        onFlagsCreate(createdFlags);
                    });
            } else {
                updateFlag.updateFlag(editFlag).subscribe((updatedFlag) => {
                    onFlagUpdate(updatedFlag);
                });
            }
        }
    };

    const changeColour = useCallback(
        (color: string) => {
            setColour(color);
            hide();
        },
        [hide],
    );

    return landlordPreferences.loaded ? (
        <Form onSubmit={handleFormSubmit} {...formValidation}>
            <InputField
                label={t("Name")}
                value={name}
                onChange={setName}
                {...nameValidation}
            />
            <InputField
                label={t("Description")}
                value={description}
                onChange={setDescription}
            />
            <FlagColour
                colour={colour}
                togglePopover={toggle}
                setRef={colourPopoverRef}
            />
            {!colourValidation.isValid && (
                <ValidationError {...colourValidation} />
            )}

            {visible && (
                <Popover
                    hide={hide}
                    anchorElement={colourPopoverRef}
                    maxWidth={290}
                >
                    {colours.colourPicker.map((color, index) => (
                        <FlagColour
                            key={index}
                            colour={color}
                            selectColour={changeColour}
                        />
                    ))}
                </Popover>
            )}

            <Checkbox
                onChange={setRemoveAfterService}
                checked={removeAfterService}
            >
                {t("Remove after service")}
            </Checkbox>
            <Button
                type="submit"
                variant="primary"
                disabled={
                    createFlag.loading ||
                    updateFlag.loading ||
                    deleteFlag.loading
                }
            >
                {t("Save")}
            </Button>
            {flag.id !== 0 && (
                <Button
                    onClick={handleDeleteFlagClick}
                    variant="error"
                    cssRules={{ marginLeft: "10px" }}
                    disabled={deleteFlag.loading || updateFlag.loading}
                >
                    {t("Delete")}
                </Button>
            )}
            {createFlag.error ||
                updateFlag.error ||
                (deleteFlag.error && (
                    <ValidationError
                        isValid={false}
                        error={
                            createFlag.error ||
                            updateFlag.error ||
                            deleteFlag.error
                        }
                    />
                ))}
        </Form>
    ) : (
        <Loading />
    );
};

interface IEditFlagFormProps {
    flag: IFlag;
    onFlagsCreate: (flags: IFlag[]) => void;
    onFlagUpdate: (flag: IFlag) => void;
    onFlagDelete: (flag: IFlag) => void;
    landlordId: number;
}

export default EditFlagForm;
