import { useEffect, useState } from "react";
import { noop } from "rxjs";
import { useCssClasses } from "../../hooks";
import styles from "./TabGroup.module.scss";
import TabGroupHeader from "./TabGroupHeader";

const TabGroup = ({
    currentTab = 0,
    children,
    onActivate = noop,
    headersPosition = "left",
    contentMaxHeight,
    overflowAuto = true,
    fullHeight,
}: ITabGroupProps) => {
    const [activeTab, setActiveTab] = useState(currentTab);

    useEffect(() => {
        setActiveTab(currentTab);
    }, [currentTab]);

    useEffect(() => {
        onActivate(activeTab);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab]);

    const containerCssClasses = useCssClasses(
        headersPosition === "left" ? styles.left : "",
        fullHeight ? styles.fullHeight : "",
    );

    const tabContentCssClasses = useCssClasses(
        styles.tabContent,
        overflowAuto ? styles.overflowAuto : "",
        headersPosition === "left" ? styles.padding : "",
    );

    return (
        <div className={containerCssClasses}>
            <div className={styles.tabHeaders}>
                {children.map(
                    (tab, index) =>
                        typeof tab !== "boolean" && (
                            <TabGroupHeader
                                key={index}
                                isActive={index === activeTab}
                                onClick={setActiveTab}
                                index={index}
                                position={headersPosition}
                            >
                                {tab.props.header}
                            </TabGroupHeader>
                        ),
                )}
            </div>

            <div
                className={tabContentCssClasses}
                style={{ maxHeight: contentMaxHeight }}
            >
                {children.map((tab, index) => index === activeTab && tab)}
            </div>
        </div>
    );
};

type Position = "top" | "left";

interface ITabGroupProps {
    currentTab?: number;
    headersPosition?: Position;
    onActivate?: (index: number) => void;
    contentMaxHeight?: number;
    overflowAuto?: boolean;
    fullHeight?: boolean;
    children: (JSX.Element | boolean)[];
}

export default TabGroup;
