import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ITask } from ".";
import { Link } from "../..";
import { useCssClasses } from "../../../hooks";
import { getDifferenceInMinutes, toTimeString } from "../../../utils/dates";
import Icon from "../../Icon";
import TaskIcon from "../../TaskIcon/TaskIcon";
import styles from "./JobTimelineTask.module.scss";

const JobTimelineTask = ({
    showTaskIcons = false,
    task,
    dropdownArrow = "",
    showAnswers,
    taskDisplayName,
    isPortalStandard,
}: IJobTimelineTaskProps) => {
    const { t } = useTranslation();

    const minutes = useMemo(
        () =>
            Math.ceil(
                getDifferenceInMinutes(
                    new Date(task.startDate),
                    new Date(task.endDate),
                ),
            ) || 1,
        [task],
    );

    const cssClasses = useCssClasses(
        styles.task,
        showAnswers ? styles.cursorPointer : "",
    );

    return (
        <div className={cssClasses}>
            <div className={styles.small} style={{ width: 30 }}>
                {toTimeString(new Date(task.startDate))}
            </div>

            <div className={styles.taskDetails}>
                {showTaskIcons && <TaskIcon taskType={task.type.taskType} />}
                <span>{taskDisplayName} </span>
                {task.appliance && task.appliance.type && (
                    <>
                        &nbsp;{t("on")}&nbsp;
                        {isPortalStandard &&
                        task.appliance.type.displayName !==
                            "Distribution Board" ? (
                            <Link
                                url={`/management/${
                                    task.appliance.type.displayName ===
                                        "Circuit" ||
                                    task.appliance.pageType === "circuit"
                                        ? "circuits"
                                        : "appliances"
                                }/${task.appliance.id}`}
                            >
                                {task.appliance.type.displayName}
                                {task.appliance.make !== "N/A" &&
                                    task.appliance.displayName !== null &&
                                    `: ${task.appliance.displayName}`}
                            </Link>
                        ) : (
                            <>
                                {task.appliance.type.displayName}:&nbsp;
                                {task.appliance.displayName}
                            </>
                        )}
                    </>
                )}
            </div>

            <div className={styles.small}>
                {t("duration.minutes", { count: minutes })}
            </div>
            {showAnswers && dropdownArrow && (
                <Icon icon={dropdownArrow} color="blue" ariaHidden={true} />
            )}
        </div>
    );
};

interface IJobTimelineTaskProps {
    task: ITask;
    showTaskIcons?: boolean;
    dropdownArrow?: string;
    showAnswers: boolean;
    taskDisplayName: string;
    isPortalStandard: boolean;
}

export default JobTimelineTask;
