import { groupBy, map, orderBy } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IJobTimelineProps } from ".";
import { toDateString } from "../../utils/dates";
import styles from "./JobTimeline.module.scss";
import JobTimelineJob from "./JobTimelineJob";

const JobTimeline = ({
    jobs,
    selectedIds = [],
    showTasks,
    showAddress,
    showJobType,
    showTaskIcons,
    loadTasksAsync,
    onCheckedChange,
    isCompact,
    openChildJobsInNewWindow,
    answers = [],
    showJobIcons = false,
}: IJobTimelineProps) => {
    const { t } = useTranslation();
    const groupedJobs = useMemo(() => {
        const jobsWithSortedTasks = jobs.map((job) => ({
            ...job,
            tasks: job.tasks?.sort((a, b) =>
                a.startDate > b.startDate ? 1 : -1,
            ),
        }));

        return groupBy(
            orderBy(
                jobsWithSortedTasks,
                [
                    (job) => job.date && job.date.split("T")[0],
                    (job) => job.date && job.date.split("T")[1],
                ],
                ["desc", "asc"],
            ),
            (job) => toDateString(new Date(job.date || 0)),
        );
    }, [jobs]);

    return (
        <div>
            {jobs && jobs.length > 0
                ? map(groupedJobs, (group, date) => (
                      <div key={date}>
                          <div
                              className={styles.group}
                              data-test-id="JobDate_Div"
                          >
                              {date}
                          </div>

                          {group.map((job) => (
                              <JobTimelineJob
                                  key={job.id}
                                  job={job}
                                  showTasks={showTasks}
                                  showTaskIcons={showTaskIcons}
                                  showAddress={showAddress}
                                  showJobType={showJobType}
                                  loadTasksAsync={loadTasksAsync}
                                  isChecked={selectedIds.includes(job.id)}
                                  onCheckedChange={onCheckedChange}
                                  isCompact={isCompact}
                                  answers={answers}
                                  showJobIcons={showJobIcons}
                                  openChildJobsInNewWindow={
                                      openChildJobsInNewWindow
                                  }
                              />
                          ))}
                      </div>
                  ))
                : t("No Jobs Found")}
        </div>
    );
};

export default JobTimeline;
