import { useCallback, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { IBooleanFilterProps } from ".";
import { Button, Checkbox, Icon, Popover } from "../..";
import { useToggle } from "../../../hooks";

const BooleanFilter = ({
    getBooleanFilters,
    property,
    toggleFilter,
    hover,
    activeFilters,
    title,
}: IBooleanFilterProps) => {
    const { t } = useTranslation();

    const { visible, hide, toggle } = useToggle();
    const popoverButton = useRef<HTMLButtonElement>(null);

    const filters = useMemo(
        () =>
            getBooleanFilters
                ? getBooleanFilters()
                : [
                      { value: "true", displayText: t("Yes") },
                      { value: "false", displayText: t("No") },
                  ],

        [getBooleanFilters, t],
    );

    const handleFilter = useCallback(
        (checked: boolean, value: string) =>
            toggleFilter([
                {
                    property,
                    filterGroup: {
                        operator: "{OR}",
                        filters: [{ function: "=", value }],
                    },
                    appendFilters: false,
                },
            ]),
        [property, toggleFilter],
    );

    const selectedFilters = useMemo(() => {
        const dictionary: { [key: string]: boolean | undefined } = {};

        for (const currentFilter of activeFilters) {
            dictionary[currentFilter.value] = true;
        }

        return dictionary;
    }, [activeFilters]);

    return (
        <>
            <Button
                onClick={toggle}
                setRef={popoverButton}
                size="small"
                cssRules={
                    hover
                        ? { marginLeft: "5px" }
                        : { marginLeft: "5px", opacity: 0 }
                }
            >
                <Icon
                    icon="list"
                    size={16}
                    ariaLabel={t(`Filter ${title ?? property}`)}
                />
            </Button>

            {visible && (
                <Popover
                    anchorElement={popoverButton}
                    maxWidth={400}
                    hide={hide}
                >
                    {filters.map((l) => (
                        <Checkbox
                            key={l.value}
                            value={l.value}
                            checked={selectedFilters[l.value] || false}
                            onChange={handleFilter}
                        >
                            {l.displayText}
                        </Checkbox>
                    ))}
                </Popover>
            )}
        </>
    );
};

export default BooleanFilter;
