import { IComplianceDashboard } from "../../../utils/api/misc";
import RecentTasksCard from "./RecentTasksCard";

export interface IRecentTasksCardProps {
    loaded: boolean;
    data: IComplianceDashboard;
    maxHeight: number;
}

export default RecentTasksCard;
