import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LineChartWithTotal } from "../../../components";
import { ILineChartWithTotal } from "../../../components/LineChartWithTotal";
import { ITotalJob } from "../../../utils/api/misc";
import { generateTotalJobsData } from "../../../utils/chartData";
import styles from "./TotalJobs.module.scss";

const TotalJobs = ({ totalJobs }: ITotalJobsProps) => {
    const { t } = useTranslation();
    const [jobsData, setJobsData] = useState<ILineChartWithTotal[]>([]);

    useEffect(() => {
        setJobsData(
            generateTotalJobsData(totalJobs).map((total) => ({
                ...total,
                label: t(total.label),
            })),
        );
    }, [t, totalJobs]);

    return (
        <div className={styles.centerContainer}>
            <LineChartWithTotal
                data={jobsData}
                maxHeight={100}
                testId="TotalJobs_Value_Div"
            />
        </div>
    );
};

interface ITotalJobsProps {
    totalJobs: ITotalJob[];
}

export default TotalJobs;
