import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, Icon, Link } from "../../../components";
import DashboardWidgetActiveFilters from "../../../components/DashboardWidgetActiveFilters";
import DashboardWidgetFilter, {
    IFilterGroup,
} from "../../../components/DashboardWidgetFilter";
import useSingleSelectedPropertyCategory from "../../../hooks/useSingleSelectedPropertyCategory";
import NoAccessCount from "../NoAccessCount";

const NoAccessCountCard = () => {
    const { t } = useTranslation();

    const category = useSingleSelectedPropertyCategory();
    const [utaTypeFilter, setUtaTypeFilter] = useState(
        category?.id === 13 ? "asset" : "property",
    );

    const filters = useMemo(() => {
        const list: IFilterGroup[] = [
            {
                id: "uta_type",
                title: t("UTA Type"),
                value: utaTypeFilter,
                changeValue: setUtaTypeFilter,
                allowEmptyValue: false,
                options: [
                    { label: t("Property"), value: "property" },
                    { label: t("Asset"), value: "asset" },
                ],
            },
        ];

        return list;
    }, [t, utaTypeFilter]);

    const url = useMemo(
        () =>
            utaTypeFilter === "property"
                ? "/issues/unabletoaccess"
                : "/issues/asset-uta",
        [utaTypeFilter],
    );

    return (
        <Card
            title={t("No access")}
            fullHeight={true}
            testId="NoAccess_Widget"
            actions={
                <>
                    <Link
                        url={url}
                        testId="NoAccessWidgetViewAll_Cta"
                        ariaLabel={t("View all unable to access attempts")}
                    >
                        <Icon
                            icon="eye"
                            display="inline-block"
                            size={12}
                            cssRules={{ marginRight: "5px" }}
                            ariaHidden={true}
                        />
                        {t("View all")}
                    </Link>

                    <DashboardWidgetFilter filters={filters} />
                </>
            }
        >
            <NoAccessCount type={utaTypeFilter}>
                <DashboardWidgetActiveFilters filters={filters} />
            </NoAccessCount>
        </Card>
    );
};

export default NoAccessCountCard;
