import { IComplianceDashboard } from "../../../utils/api/misc";
import ComplianceElectricDashboard from "./ComplianceElectricDashboard";

export interface IComplianceElectricDashboardProps {
    rowHeight: number;
    dashboard: IComplianceDashboard;
    loaded: boolean;
    mapFeature: boolean;
}

export default ComplianceElectricDashboard;
