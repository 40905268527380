/* eslint-disable no-process-env */

const environmentVariables = {
    REACT_APP_AWS: process.env.REACT_APP_ENVIRONMENT === "aws",
    REACT_APP_API_DOMAIN: process.env.REACT_APP_API_DOMAIN,
    REACT_APP_APPLIANCES_DOMAIN: process.env.REACT_APP_APPLIANCES_DOMAIN,
    REACT_APP_ASSETS_DOMAIN: process.env.REACT_APP_ASSETS_DOMAIN,
    REACT_APP_PROPERTIES_DOMAIN: process.env.REACT_APP_PROPERTIES_DOMAIN,
    REACT_APP_JOBS_DOMAIN: process.env.REACT_APP_JOBS_DOMAIN,
    REACT_APP_ENGINEERS_DOMAIN: process.env.REACT_APP_ENGINEERS_DOMAIN,
    REACT_APP_LANDLORDS_DOMAIN: process.env.REACT_APP_LANDLORDS_DOMAIN,
    REACT_APP_USERS_DOMAIN: process.env.REACT_APP_USERS_DOMAIN,
    REACT_APP_LGSRS_DOMAIN: process.env.REACT_APP_LGSRS_DOMAIN,
    REACT_APP_MISC_DOMAIN: process.env.REACT_APP_MISC_DOMAIN,
    REACT_APP_ALLOCATED_JOBS_DOMAIN:
        process.env.REACT_APP_ALLOCATED_JOBS_DOMAIN,
    REACT_APP_ISSUES_DOMAIN: process.env.REACT_APP_ISSUES_DOMAIN,
    REACT_APP_ANSWERS_DOMAIN: process.env.REACT_APP_ANSWERS_DOMAIN,
    REACT_APP_RECONCILIATION_DOMAIN:
        process.env.REACT_APP_RECONCILIATION_DOMAIN,
    REACT_APP_PHP_API_DOMAIN: process.env.REACT_APP_PHP_API_DOMAIN,
    REACT_APP_WEBSOCKET_DOMAIN: process.env.REACT_APP_WEBSOCKET_DOMAIN,
    REACT_APP_AUDITS_DOMAIN: process.env.REACT_APP_AUDITS_DOMAIN,
    REACT_APP_GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    REACT_APP_SEGMENT_API_KEY: process.env.REACT_APP_SEGMENT_API_KEY,
    REACT_APP_AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN,
    REACT_APP_AUTH0_CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID,
    REACT_APP_ENABLE_PAGE_TRACKING:
        process.env.REACT_APP_ENABLE_PAGE_TRACKING === "true",
    REACT_APP_FULLSTORY_ORGANIZATION_ID:
        process.env.REACT_APP_FULLSTORY_ORGANIZATION_ID,
};

export default environmentVariables;
