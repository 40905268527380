import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
    Card,
    Grid,
    GridColumn,
    Img,
    InfiniteScroll,
    JobTimeline,
    Loading,
} from "../../components";
import { IJob } from "../../components/JobTimeline";
import { useAuthorizedImage } from "../../hooks/useAuthorizedImage";
import { useEngineer, useEngineerTotalJobs } from "../../utils/api/engineers";
import { ISimpleJob, useEngineerJobs } from "../../utils/api/jobs";
import { secondsToTimeSpan, toDateTimeString } from "../../utils/dates";
import PageNotFound from "../PageNotFound";
import styles from "./Engineer.module.scss";
import JobsCompleted from "./JobsCompleted";

const Engineer = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const engineer = useEngineer(id ?? "");
    const engineerTotalJobs = useEngineerTotalJobs(id ?? "");

    const [jobs, setJobs] = useState<IJob[]>([]);
    const onRecordsLoaded = useCallback(
        (values: ISimpleJob[], currentPage: number) => {
            const newRecords = currentPage === 1 ? [] : [...jobs];

            newRecords.push(
                ...values.map((job) => ({
                    id: job.id,
                    date: job.date,
                    engineer: {
                        id: job.engineer.id,
                        name: job.engineer.name,
                    },
                    property: {
                        id: job.property.id,
                        addressString: job.property.addressString,
                    },
                    childJobs: job.childJobs,
                    totalTasks: job.totalTasks,
                    jobType: job.jobType,
                })),
            );

            setJobs(newRecords);
        },
        [jobs],
    );
    const engineerJobs = useEngineerJobs(id ?? "", onRecordsLoaded);

    const engineerImage = useAuthorizedImage(engineer.value?.imageUrl ?? "");

    const loadNextPage = () => {
        if (engineerJobs.currentPage < engineerJobs.totalPages) {
            engineerJobs.goToNextPage();
        }
    };

    const getAverageJobTime = (averageTime: number) => {
        return secondsToTimeSpan(averageTime);
    };

    if (engineer.loaded && engineerJobs.loaded && engineerTotalJobs.loaded) {
        return (
            <>
                <Grid>
                    <GridColumn size="oneThird">
                        <Card fullHeight={true}>
                            <Img
                                url={engineerImage}
                                circle={true}
                                size={50}
                                cssRules={{
                                    float: "left",
                                    marginRight: "10px",
                                }}
                            />
                            <h2>{engineer.value.name}</h2>
                            <p className={styles.lastActive}>
                                {t("Last Active")}:&nbsp;
                                {engineer.value.lastActiveDate
                                    ? toDateTimeString(
                                          new Date(
                                              engineer.value.lastActiveDate,
                                          ),
                                      )
                                    : t("No activity registered")}
                            </p>
                        </Card>
                    </GridColumn>
                    <GridColumn size="oneThird">
                        <Card title={t("Jobs Completed")}>
                            <JobsCompleted
                                jobCounts={engineerTotalJobs.value}
                            />
                        </Card>
                    </GridColumn>
                    <GridColumn size="oneThird">
                        {engineer.value.totalJobTime > 0 &&
                            engineer.value.jobCount > 0 && (
                                <Card
                                    title={t("Average Job Time")}
                                    fullHeight={true}
                                >
                                    <div className={styles.averageJobTime}>
                                        {getAverageJobTime(
                                            engineer.value.totalJobTime /
                                                engineer.value.jobCount,
                                        )}
                                    </div>
                                </Card>
                            )}
                    </GridColumn>
                </Grid>
                <Card title={t("Jobs")} bodyScroll={false}>
                    <InfiniteScroll
                        loading={engineerJobs.loading}
                        onScrollToBottom={loadNextPage}
                    >
                        {engineerJobs.loaded && jobs.length > 0 && (
                            <JobTimeline jobs={jobs} showTasks={false} />
                        )}
                    </InfiniteScroll>
                </Card>
            </>
        );
    } else if (!engineer.error) {
        return <Loading />;
    } else {
        return <PageNotFound />;
    }
};

export default Engineer;
