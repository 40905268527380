import { capitalize, padStart } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { of, switchMap } from "rxjs";
import {
    Button,
    Dropdown,
    Form,
    Icon,
    Link,
    Loading,
    Modal,
    MultilineInputField,
    Portal,
} from "../../../components";
import ModalBody from "../../../components/Modal/ModalBody";
import ModalFooter from "../../../components/Modal/ModalFooter";
import Table, { IBulkAction, ITableColumn } from "../../../components/Table";
import { useToggle } from "../../../hooks";
import {
    IIntegrationFailures,
    useIntegrationFailures,
    useIntegrationFailuresUpdate,
} from "../../../utils/api/misc";
import { clearCache } from "../../../utils/cache";
import {
    isRequired,
    useValidateField,
    validateForm,
} from "../../../utils/validation";
import DifferenceTable from "../DifferenceTable";

const IntegrationFailuresTable = () => {
    const { t } = useTranslation();

    const {
        show: changesModalShow,
        hide: changesModalHide,
        visible: changesModalVisible,
    } = useToggle();

    const integrationFailures = useIntegrationFailures(false);
    const integrationFailuresUpdate = useIntegrationFailuresUpdate();

    const [selectedUpdatedEntity, setSelectedUpdatedEntity] = useState("");
    const [selectedOriginalEntity, setSelectedOriginalEntity] = useState("");
    const [selectedRowId, setSelectedRowId] = useState<number>(0);

    const [idsToRequeue, setIdsToRequeue] = useState<number[] | undefined>(
        undefined,
    );

    const [selectedEntityType, setSelectedEntityType] = useState("");

    const [actionsTaken, setActionsTaken] = useState("");

    const actionsTakenValidator = useValidateField(actionsTaken, isRequired());

    const updateFormValidation = validateForm(() => [actionsTakenValidator]);

    const {
        show: resendModalShow,
        hide: resendModalHide,
        visible: resendModalVisible,
    } = useToggle();

    const plural = useMemo(
        () => (idsToRequeue && idsToRequeue.length > 1 ? "s" : ""),
        [idsToRequeue],
    );

    const displayResendModal = useCallback(
        (ids: number[]) => {
            setIdsToRequeue(ids);
            actionsTakenValidator.reset();
            resendModalShow();
        },
        [actionsTakenValidator, resendModalShow],
    );

    const handleRequeueTask = () => {
        if (idsToRequeue && idsToRequeue.length > 0) {
            integrationFailuresUpdate
                .update({ ids: idsToRequeue, actionsTaken })
                .pipe(
                    switchMap(() => {
                        clearCache();
                        return of(integrationFailures.refresh());
                    }),
                )
                .subscribe();
        }

        resendModalHide();
    };

    const columns = useMemo(() => {
        const renderUpdated = (value: string, row: IIntegrationFailures) => {
            const handleViewChanges = () => {
                setSelectedUpdatedEntity(value);
                setSelectedOriginalEntity(row.original);
                setSelectedEntityType(row.type);
                setSelectedRowId(row.id);

                changesModalShow();
            };

            return <Button onClick={handleViewChanges}>{t("View")}</Button>;
        };

        const renderActions = (_: unknown, row: IIntegrationFailures) => (
            <Dropdown
                label={<Icon icon="more" ariaLabel={t("Actions")} />}
                size="small"
                items={[
                    {
                        label: t("Resend"),
                        onClick: () => displayResendModal([row.id]),
                    },
                ]}
            />
        );

        const col: { [key: string]: ITableColumn<IIntegrationFailures> } = {
            id: {
                title: t("Id"),
                render: (value: string) => padStart(value, 5, "0"),
                filterable: false,
            },
            type: {
                title: t("Type"),
                render: (value: string, row: IIntegrationFailures) => {
                    if (row.entityId) {
                        let url = "";
                        switch (value) {
                            case "Appliance": {
                                url = `/management/appliances/${row.entityId}`;
                                break;
                            }
                            case "Job": {
                                url = `/jobs/jobs/${row.entityId}`;
                                break;
                            }
                        }

                        if (url) {
                            return <Link url={url}>{t(value)}</Link>;
                        }
                    }

                    return value;
                },
            },
            method: {
                title: t("Update Type"),
                render: (value: string) => capitalize(value),
            },
            errorCategory: {
                title: t("Error Category"),
            },
            errorReason: {
                title: t("Error Reason"),
            },
            errorCode: {
                title: t("Error Code"),
                hidden: true,
            },
            createdAt: {
                title: t("Created at"),
                type: "datetime",
            },
            updatedAt: {
                title: t("Updated at"),
                type: "datetime",
                hidden: true,
            },
            updated: {
                title: t("Changes"),
                filterable: false,
                sortable: false,
                render: renderUpdated,
            },
            actions: {
                title: t("Actions"),
                filterable: false,
                sortable: false,
                canBeToggledByUser: false,
                render: renderActions,
            },
        };
        return col;
    }, [displayResendModal, changesModalShow, t]);

    const bulkActions = useMemo<IBulkAction[]>(
        () => [
            {
                value: "resend",
                label: t("Resend All Selected"),
                onSubmit: displayResendModal,
            },
        ],
        [displayResendModal, t],
    );

    return integrationFailures.loaded ? (
        <>
            <Table
                preferences="integration-failures-table"
                columns={columns}
                bulkActions={bulkActions}
                {...integrationFailures}
            />

            {changesModalVisible && (
                <Portal>
                    <Modal
                        title={`#${padStart(
                            selectedRowId.toString(),
                            5,
                            "0",
                        )} - ${selectedEntityType} Details`}
                        hide={changesModalHide}
                    >
                        <ModalBody>
                            <DifferenceTable
                                original={selectedOriginalEntity}
                                current={selectedUpdatedEntity}
                                suppress={[
                                    "id",
                                    "TasksArray",
                                    "EngineerDetails",
                                    "LGSR",
                                ]}
                            />
                        </ModalBody>
                    </Modal>
                </Portal>
            )}

            {resendModalVisible && idsToRequeue && (
                <Portal>
                    <Form
                        onSubmit={handleRequeueTask}
                        {...actionsTakenValidator}
                    >
                        <Modal
                            title={t(`Resend Job${plural} Confirmation`)}
                            hide={resendModalHide}
                        >
                            <ModalBody>
                                <MultilineInputField
                                    label={t(
                                        `Please enter any actions undertaken to resend the job${plural}`,
                                    )}
                                    value={actionsTaken}
                                    onChange={setActionsTaken}
                                    {...updateFormValidation}
                                    error="You must details actions taken"
                                />
                            </ModalBody>
                            <ModalFooter>
                                <Button variant="primary" type="submit">
                                    {t("Resend")}
                                </Button>
                            </ModalFooter>
                        </Modal>
                    </Form>
                </Portal>
            )}
        </>
    ) : (
        <Loading />
    );
};

export default IntegrationFailuresTable;
