import { ReactNode } from "react";
import { AlertType } from ".";
import { useCssClasses } from "../../hooks";
import styles from "./Alert.module.scss";

const Alert = ({ type, children }: IAlertProps) => {
    const cssClasses = useCssClasses(styles.alert, styles[type]);

    return <div className={cssClasses}>{children}</div>;
};

interface IAlertProps {
    type: AlertType;
    children: ReactNode;
}

export default Alert;
