import { useMemo } from "react";
import { Card, Loading, PropertyMap } from "../../../components";
import { useMapProperties } from "../../../utils/api/properties";

const CompliancePropertyMap = () => {
    const { records, loaded } = useMapProperties();

    const map = useMemo<
        [number, number | null, number | null, number][][]
    >(() => {
        if (records) {
            return [
                records.map((property) => [
                    property.id,
                    property.lat,
                    property.long,
                    1,
                ]),
            ];
        }

        return [];
    }, [records]);

    return (
        <Card padding={false} fullHeight={true} flexBasis={true}>
            {loaded ? <PropertyMap properties={map} /> : <Loading />}
        </Card>
    );
};

export default CompliancePropertyMap;
