import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { IDashboardWidgetFilterProps } from ".";
import { Button, Icon, Popover } from "..";
import { useToggle } from "../../hooks";
import DashboardWidgetFilterGroup from "./DashboardWidgetFilterGroup";

const DashboardWidgetFilter = ({ filters }: IDashboardWidgetFilterProps) => {
    const { t } = useTranslation();

    const { toggle, visible, hide } = useToggle();
    const popoverButton = useRef<HTMLButtonElement>(null);

    return (
        <>
            <Button
                displayAsLink={true}
                variant="primary"
                onClick={toggle}
                setRef={popoverButton}
                cssRules={{ marginLeft: 10, fontSize: 12, fontWeight: "bold" }}
            >
                <Icon
                    icon="menu"
                    size={14}
                    cssRules={{ marginRight: 5 }}
                    ariaHidden={true}
                />
                {t("Filter")}
            </Button>

            {visible && (
                <Popover
                    anchorElement={popoverButton}
                    hide={hide}
                    maxWidth={400}
                >
                    {filters.map((group) => (
                        <DashboardWidgetFilterGroup
                            key={group.id}
                            group={group}
                        />
                    ))}
                </Popover>
            )}
        </>
    );
};

export default DashboardWidgetFilter;
