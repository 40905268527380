import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Button,
    Card,
    Grid,
    GridColumn,
    Loading,
    SelectFilter,
} from "../../components";
import AllocateJobExtendedModal from "../../components/AllocateJobExtendedModal";
import { ISelectOption } from "../../components/Select";
import { useSelectRecords, useToggle } from "../../hooks";
import allocatedJobStatus from "../../utils/allocatedJobStatus";
import {
    IAllocatedJob,
    useAllAllocatedJobs,
    useDeleteAllocatedJob,
    useMatchAllocatedJob,
} from "../../utils/api/allocatedJobs";
import useMatchAllocatedUta from "../../utils/api/allocatedJobs/useMatchAllocatedUta";
import { useAllEngineers } from "../../utils/api/landlords";
import { useProperties } from "../../utils/api/properties";
import { clearCache } from "../../utils/cache";
import { addDays, getToday } from "../../utils/dates";
import AllocatedJob from "./AllocatedJob";
import AllocatedJobsGrouped from "./AllocatedJobsGrouped";
import styles from "./AllocateJobs.module.scss";
import DateFilter from "./DateFilter";
import EngineersFilter from "./EngineersFilter";
import JobFilter from "./JobFilter";
import ManuallyCompleteJobModal from "./ManuallyCompleteJobModal";
import ReconcileJobModal from "./ReconcileJobModal";

const AllocateJobs = () => {
    const { t } = useTranslation();
    const today = useMemo(() => getToday(), []);
    const [date, setDate] = useState(today);
    const [showProperty, setShowProperty] = useState(false);

    const engineers = useAllEngineers();
    const allocatedJobs = useAllAllocatedJobs({
        startDate: date,
        endDate: addDays(date, 1),
    });

    const deleteAllocatedJob = useDeleteAllocatedJob();
    const handleDeleteAllocatedJob = (jobId: string) => {
        deleteAllocatedJob.deleteAllocatedJob(jobId).subscribe(() => {
            allocateJobModalHide();
            allocatedJobs.updateValue(
                allocatedJobs?.records.filter((job) => job.id !== jobId),
            );
        });
    };

    const properties = useProperties({
        sortProperty: "addressString",
        sortDirection: "asc",
    });

    const engineerIds = useMemo(
        () => engineers.records.map((engineer) => engineer.id),
        [engineers.records],
    );
    const selectEngineers = useSelectRecords(engineerIds, true);

    const engineerJobsCount = useMemo(() => {
        const obj: { [key: string]: number } = {};

        for (const job of allocatedJobs.records) {
            obj[job.engineer.id.toString()] =
                (obj[job.engineer.id.toString()] || 0) + 1;
        }

        return obj;
    }, [allocatedJobs.records]);

    const [allJobStatusFilters] = useState([
        "Waiting",
        "Complete",
        "Late",
        "Missed",
        "UTA Reconciled",
        "Job Reconciled",
        "UTA",
    ]);
    const [selectedJobStatusFilters, setSelectedJobStatusFilters] =
        useState(allJobStatusFilters);

    const [allJobTypeFilters] = useState([
        "Repair",
        "Service",
        "Install",
        "Other",
    ]);
    const [selectedJobTypeFilters, setSelectedJobTypeFilters] =
        useState(allJobTypeFilters);

    const filteredAllocatedJobs = useMemo(
        () =>
            allocatedJobs.records.filter(
                (job) =>
                    selectedJobTypeFilters.includes(job.jobType) &&
                    selectedJobStatusFilters.includes(
                        allocatedJobStatus(job),
                    ) &&
                    selectEngineers.selectedIds.includes(job.engineer.id),
            ),
        [
            allocatedJobs.records,
            selectEngineers.selectedIds,
            selectedJobStatusFilters,
            selectedJobTypeFilters,
        ],
    );

    const getJobsByDate = (selectedDate: Date) => {
        allocatedJobs.toggleFilter([
            {
                property: "jobDate",
                filterGroup: {
                    operator: "{AND}",
                    filters: [
                        {
                            function: ">=",
                            value: selectedDate.toISOString(),
                        },
                        {
                            function: "<",
                            value: addDays(selectedDate, 1).toISOString(),
                        },
                    ],
                },
                appendFilters: false,
            },
            {
                property: "property.id",
                filterGroup: {
                    operator: "{AND}",
                    filters: [],
                },
                appendFilters: false,
            },
        ]);
    };

    const getJobsByProperty = (id: string) => {
        allocatedJobs.toggleFilter([
            {
                property: "property.id",
                filterGroup: {
                    operator: "{AND}",
                    filters: [{ function: "=", value: id }],
                },
                appendFilters: false,
            },
            {
                property: "jobDate",
                filterGroup: {
                    operator: "{AND}",
                    filters: [],
                },
                appendFilters: false,
            },
        ]);
    };

    const handleDateSelected = (selectedDate: Date) => {
        setDate(selectedDate);
        getJobsByDate(selectedDate);
    };

    const {
        show: allocateJobModalShow,
        hide: allocateJobModalHide,
        visible: allocateJobModalVisible,
    } = useToggle();

    const closeAllocatedJobModal = useCallback(() => {
        allocateJobModalHide();
        clearCache();
        allocatedJobs.refresh();
    }, [allocateJobModalHide, allocatedJobs]);

    const [selectedJob, setSelectedJob] = useState<IAllocatedJob>();
    const handleJobClick = useCallback(
        (job: IAllocatedJob) => {
            setSelectedJob(job);
            allocateJobModalShow();
        },
        [allocateJobModalShow],
    );

    const handleAllocateClick = () => {
        setSelectedJob(undefined);
        allocateJobModalShow();
    };

    const [propertyId, setPropertyId] = useState("");
    const handlePropertyChange = (id: string) => {
        setPropertyId(id);

        if (id) {
            setShowProperty(true);
            getJobsByProperty(id);
        } else {
            setShowProperty(false);
            getJobsByDate(date);
        }
    };

    const canAddNewJob = useMemo(
        () => date >= today && !showProperty,
        [date, today, showProperty],
    );

    const {
        show: manuallyCompleteJobModalShow,
        hide: manuallyCompleteJobModalHide,
        visible: manuallyCompleteJobModalVisible,
    } = useToggle();
    const handleManuallyCompleteAllocatedJob = () => {
        allocateJobModalHide();
        manuallyCompleteJobModalShow();
    };

    const {
        show: reconcileJobModalShow,
        hide: reconcileJobModalHide,
        visible: reconcileJobModalVisible,
    } = useToggle();
    const handleReconcileAllocatedJob = () => {
        allocateJobModalHide();
        reconcileJobModalShow();
    };

    const handleShowJobDetails = () => {
        allocateJobModalShow();
        reconcileJobModalHide();
    };

    const { matchAllocatedJob } = useMatchAllocatedJob();
    const matchJob = (jobId: number) => {
        if (selectedJob) {
            matchAllocatedJob(selectedJob.id, jobId).subscribe(() =>
                reconcileJobModalHide(),
            );
        }
    };

    const matchAllocatedUta = useMatchAllocatedUta();
    const matchUta = (utaId: number) => {
        if (selectedJob) {
            matchAllocatedUta
                .matchAllocatedUta(selectedJob.id, utaId)
                .subscribe(() => reconcileJobModalHide());
        }
    };

    const propertyOptions = useMemo<ISelectOption[]>(
        () =>
            properties.records.map((property) => ({
                value: property.id.toString(),
                label: property.addressString,
            })),
        [properties.records],
    );

    return (
        <>
            {engineers.loaded ? (
                <>
                    <Grid fullHeight={true}>
                        <GridColumn size="oneThird" fullHeight={true}>
                            <Card fullHeight={true}>
                                <SelectFilter
                                    label={t("Property")}
                                    value={propertyId}
                                    onChange={handlePropertyChange}
                                    {...properties}
                                    options={propertyOptions}
                                />
                                {!showProperty && (
                                    <DateFilter
                                        date={date}
                                        onDateSelected={handleDateSelected}
                                        disabled={!allocatedJobs.loaded}
                                    />
                                )}
                                <JobFilter
                                    applyFilter={setSelectedJobStatusFilters}
                                    label={t("Job status")}
                                    values={allJobStatusFilters}
                                />
                                <JobFilter
                                    applyFilter={setSelectedJobTypeFilters}
                                    label={t("Job type")}
                                    values={allJobTypeFilters}
                                />
                                {t("Total jobs")}:&nbsp;
                                {filteredAllocatedJobs.length}
                            </Card>
                        </GridColumn>

                        <GridColumn size="oneThird" fullHeight={true}>
                            <Card fullHeight={true}>
                                <EngineersFilter
                                    engineers={engineers.records}
                                    isShowAllChecked={
                                        selectEngineers.allRowsChecked
                                    }
                                    selectedIds={selectEngineers.selectedIds}
                                    onEngineerCheck={
                                        selectEngineers.handleRowSelect
                                    }
                                    onShowAllCheck={
                                        selectEngineers.handleAllRowsSelect
                                    }
                                    jobsCount={engineerJobsCount}
                                />
                            </Card>
                        </GridColumn>

                        <GridColumn size="oneThird" fullHeight={true}>
                            {canAddNewJob && (
                                <Card>
                                    <Button
                                        onClick={handleAllocateClick}
                                        displayBlock={true}
                                    >
                                        {t("Allocate new job")}
                                    </Button>
                                </Card>
                            )}
                            {allocatedJobs.loaded ? (
                                <>
                                    {showProperty ? (
                                        <AllocatedJobsGrouped
                                            jobs={filteredAllocatedJobs}
                                            onClick={handleJobClick}
                                        />
                                    ) : (
                                        filteredAllocatedJobs.map((job) => (
                                            <AllocatedJob
                                                key={job.id}
                                                allocatedJob={job}
                                                onClick={handleJobClick}
                                            />
                                        ))
                                    )}

                                    {filteredAllocatedJobs.length === 0 && (
                                        <Card>
                                            <div className={styles.noJobs}>
                                                {t("No jobs allocated yet")}
                                            </div>
                                        </Card>
                                    )}
                                </>
                            ) : (
                                <Card>
                                    <Loading />
                                </Card>
                            )}
                        </GridColumn>
                    </Grid>

                    {allocateJobModalVisible && (
                        <AllocateJobExtendedModal
                            allocatedJob={selectedJob}
                            onManuallyCompleteAllocatedJob={
                                handleManuallyCompleteAllocatedJob
                            }
                            onReconcileAllocatedJob={
                                handleReconcileAllocatedJob
                            }
                            engineers={engineers.records}
                            hide={closeAllocatedJobModal}
                            loading={deleteAllocatedJob.loading}
                            onDeleteAllocatedJob={handleDeleteAllocatedJob}
                            defaultJobDate={date}
                        />
                    )}

                    {selectedJob && manuallyCompleteJobModalVisible && (
                        <ManuallyCompleteJobModal
                            allocatedJob={selectedJob}
                            engineers={engineers.records}
                            hide={manuallyCompleteJobModalHide}
                        />
                    )}

                    {selectedJob && reconcileJobModalVisible && (
                        <ReconcileJobModal
                            allocatedJob={selectedJob}
                            showJobDetails={handleShowJobDetails}
                            matchJob={matchJob}
                            matchUta={matchUta}
                            hide={reconcileJobModalHide}
                        />
                    )}
                </>
            ) : (
                <Loading />
            )}
        </>
    );
};

export default AllocateJobs;
