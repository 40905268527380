import { IColumnFilters, ITableFilters } from "..";
import { LogicalOperator } from "../../../utils/api/useFilterable";
import { DataType } from "../../../utils/values";
import { ISortingProps } from "../Sorting";
import ColumnActions from "./ColumnActions";

export interface IColumnActions
    extends ISortingProps,
        IColumnFilters,
        ITableFilters {
    sortable?: boolean;
    filterable?: boolean;
    type?: DataType;
    filterOperator?: LogicalOperator;
}

export interface IColumnActionsProps extends IColumnActions {
    visible: boolean;
    title?: string;
    filterFormatter?: (value: string) => string;
}

export default ColumnActions;
