import { map } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, LandlordSelector, Loading } from "../../components";
import Select, { ISelectOption } from "../../components/Select";
import SimpleTable, { IColumn } from "../../components/SimpleTable";
import { useKPIDates, useKPIs } from "../../utils/api/misc";
import { downloadCsv } from "../../utils/csv";
import { getNow, toDateTimeString } from "../../utils/dates";

const KpiReport = () => {
    const { t } = useTranslation();

    const [selectedLandlordId, setSelectedLandlordId] = useState("");
    const [selectedPropertyType, setSelectedPropertyType] = useState("");
    const [selectedKpiDate, setSelectedKpiDate] = useState("");

    const kpiDates = useKPIDates();
    const { getKPIs, value, loaded } = useKPIs();

    useEffect(() => {
        if (selectedLandlordId && selectedKpiDate) {
            getKPIs(selectedLandlordId, selectedKpiDate).subscribe();
        }
    }, [selectedLandlordId, selectedKpiDate, getKPIs]);

    const dateOptions = useMemo<ISelectOption[]>(() => {
        if (kpiDates.value) {
            return kpiDates.value
                .map((date) => ({
                    label: `${t(
                        `months.${new Date(date).getMonth()}`,
                    )} ${new Date(date).getFullYear()}`,
                    value: date,
                }))
                .sort(
                    (date, previousDate) =>
                        new Date(previousDate.value).getTime() -
                        new Date(date.value).getTime(),
                );
        }
        return [];
    }, [kpiDates.value, t]);

    const propertyTypeOptions = useMemo<ISelectOption[]>(
        () => [
            {
                label: t("Commercial"),
                value: "dataCommercial",
            },
            {
                label: t("Non Commercial"),
                value: "data",
            },
        ],
        [t],
    );

    const data = useMemo(() => {
        if (selectedPropertyType && value) {
            try {
                const output = JSON.parse(value[selectedPropertyType])[0];

                output["Percentage Of Valid Certs"] = output[
                    "No Of Valid Certificates"
                ]
                    ? parseFloat(
                          (
                              (output["No Of Valid Certificates"] /
                                  output["No Of LGSRS Checked By ML"]) *
                              100
                          ).toString(),
                      ).toFixed(2) + " %"
                    : "N/A";
                output["Percentage of Services Completed at 1st Appointment"] =
                    output[
                        "Total Number Of Services Completed At First Appointment"
                    ]
                        ? parseFloat(
                              (
                                  (output[
                                      "Total Number Of Services Completed At First Appointment"
                                  ] /
                                      output[
                                          "Total Number Of Appointments Made In Period"
                                      ]) *
                                  100
                              ).toString(),
                          ).toFixed(2) + " %"
                        : "N/A";
                output[
                    "Percentage of properties with an in date SF Certificate"
                ] = output["No Of Properties With Solid Fuel"]
                    ? parseFloat(
                          (
                              (output[
                                  "Number Of Properties With indate SFCert"
                              ] /
                                  output["No Of Properties With Solid Fuel"]) *
                              100
                          ).toString(),
                      ).toFixed(2) + " %"
                    : "N/A";
                output[
                    "Percentage of properties with an in date OF Certificate"
                ] = output["Number Of Properties With in date Oil Fuel Cert"]
                    ? parseFloat(
                          (
                              (output[
                                  "Number Of Properties With in date Oil Fuel Cert"
                              ] /
                                  output[
                                      "Number Of Properties With Oil Fuel"
                                  ]) *
                              100
                          ).toString(),
                      ).toFixed(2) + " %"
                    : "N/A";
                output[
                    "Percentage of properties with an in date UV certficate"
                ] = output["Number Of Properties With In Date UV Cert"]
                    ? parseFloat(
                          (
                              (output[
                                  "Number Of Properties With In Date UV Cert"
                              ] /
                                  output["No Of Properties With UV"]) *
                              100
                          ).toString(),
                      ).toFixed(2) + " %"
                    : "N/A";
                output["Percentage of Properties with valid ASHP certificate"] =
                    output["Number Of Properties With in date ASHP Cert"]
                        ? parseFloat(
                              (
                                  (output[
                                      "Number Of Properties With in date ASHP Cert"
                                  ] /
                                      output[
                                          "Number Of Properties with ASHP"
                                      ]) *
                                  100
                              ).toString(),
                          ).toFixed(2) + " %"
                        : "N/A";
                output["Percentage of Engineers with Valid Qualifications"] =
                    output["No Engineers With Valid Qualifications On File"]
                        ? parseFloat(
                              (
                                  (output[
                                      "No Engineers With Valid Qualifications On File"
                                  ] /
                                      output["No Of Engineers On Contract"]) *
                                  100
                              ).toString(),
                          ).toFixed(2) + " %"
                        : "N/A";

                return output;
            } catch {
                return [];
            }
        }
    }, [value, selectedPropertyType]);

    const columns = useMemo<IColumn[]>(() => {
        if (value && data) {
            return map<{ [key: string]: string }[], IColumn>(
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                data,
                (record, index) => ({
                    title: String(index),
                    path: String(index),
                    type: "string",
                }),
            );
        }
        return [];
    }, [data, value]);

    const download = () => {
        const now = getNow();

        downloadCsv(
            `KPI - ${toDateTimeString(now)
                .replace("/", "-")
                .replace(":", "_")}.csv`,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            Object.keys(data),
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            [Object.values(data)],
        );
    };

    return (
        <Card title={t("KPI Report")}>
            {kpiDates.loaded ? (
                <>
                    <LandlordSelector
                        onChange={setSelectedLandlordId}
                        value={selectedLandlordId}
                    />
                    <Select
                        label={t("Select a Month")}
                        options={dateOptions}
                        allowEmpty={true}
                        value={selectedKpiDate}
                        onChange={setSelectedKpiDate}
                    />
                    <Select
                        label={t("Select a property type")}
                        options={propertyTypeOptions}
                        allowEmpty={true}
                        value={selectedPropertyType}
                        onChange={setSelectedPropertyType}
                    />
                    {loaded && data && (
                        <>
                            <Button onClick={download}>
                                {t("Download CSV")}
                            </Button>
                            <SimpleTable
                                data={data}
                                columns={columns}
                                single={true}
                            />
                        </>
                    )}
                </>
            ) : (
                <Loading />
            )}
        </Card>
    );
};

export default KpiReport;
