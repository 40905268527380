import { IMultiSelectAnswer } from "../../../utils/api/answers";
import OverrideMultiSelectAnswer from "./OverrideMultiSelectAnswer";

export interface IOverrideMultiSelectAnswerProps {
    answer: IMultiSelectAnswer;
    value?: string;
    overrideAnswer: (answerId: number, answer?: string) => void;
}

export default OverrideMultiSelectAnswer;
