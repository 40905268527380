import {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Badge, Icon, NotificationsContext, Popover } from "..";
import { useToggle } from "../../hooks";
import { NotificationCategory } from "../../utils/api/misc";
import Button from "../Button";
import NotificationDropdown from "./NotificationDropdown/NotificationDropdown";

const Notification = ({
    notificationCategory,
    icon,
    testId,
}: INotificationProps) => {
    const ref = useRef<HTMLButtonElement>(null);
    const { hide, toggle, visible } = useToggle();
    const { t } = useTranslation();

    const [count, setCount] = useState(0);
    const { categories, updateNotificationsCount } =
        useContext(NotificationsContext);

    const ariaLabel = useMemo(
        () =>
            notificationCategory === NotificationCategory.Issue
                ? t("Issue notifications")
                : t("Standard notifications"),
        [notificationCategory, t],
    );

    useEffect(() => {
        categories[
            notificationCategory === NotificationCategory.Issue
                ? "issue"
                : "standard"
        ].subscribe((value) => setCount(value));
    }, [notificationCategory, categories]);

    const updateNotificationCount = useCallback(
        (all: boolean) => {
            updateNotificationsCount(notificationCategory, all ? -count : -1);

            if (!all) {
                hide();
            }
        },
        [count, hide, notificationCategory, updateNotificationsCount],
    );

    return (
        <>
            <Button
                displayAsLink={true}
                onClick={toggle}
                setRef={ref}
                testId={testId}
            >
                <Icon icon={icon} ariaLabel={ariaLabel} />
                {count > 0 && (
                    <Badge
                        color="blue"
                        position="absolute"
                        topOffset={-19}
                        leftOffset={-9}
                    >
                        {count}
                    </Badge>
                )}
            </Button>

            {visible && (
                <Popover anchorElement={ref} hide={hide} width={450}>
                    <NotificationDropdown
                        notificationCategory={notificationCategory}
                        updateNotificationCount={updateNotificationCount}
                        hide={hide}
                    />
                </Popover>
            )}
        </>
    );
};

interface INotificationProps {
    notificationCategory: NotificationCategory;
    icon: string;
    testId?: string;
}

export default Notification;
