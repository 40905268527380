import { IAnswer, IInspectionScheduleAnswer } from "../../../utils/api/answers";
import {
    IAnswerOverride,
    IAuditJobComment,
    IAuditObservation,
} from "../../../utils/api/audits";
import { IAnswerSectionGroup } from "../QualifiedSupervisorAudit";
import { IQualifiedSupervisorAuditAnswer } from "../QualifiedSupervisorAuditsGrouping";
import QualifiedSupervisorAuditGridRow from "./QualifiedSupervisorAuditGridRow";

export interface IQualifiedSupervisorAuditGridRowProps {
    id: string;
    auditId: number;
    isReadOnly: boolean;
    rowTitle: string;
    documentType: string | null;
    columns: { id: string; title: string }[];
    answers: { [key: string]: IQualifiedSupervisorAuditAnswer[] | undefined };
    overriddenAnswers: IAnswerOverride[];
    questions: IAnswerSectionGroup<IQualifiedSupervisorAuditAnswer>[];
    hiddenAnswer?: IInspectionScheduleAnswer;
    updateOverriddenAnswers: (
        overrideAnswers: IAnswerOverride[],
        deleteOverriddenAnswers: number[],
    ) => void;
    addAdditionalAnswers: (answers: IAnswer[]) => void;
    validate: (id: string, isValid: boolean) => void;
    addAuditJobComment: (jobComment: IAuditJobComment) => void;
    removeAuditJobComment: (jobCommentId: string) => void;
    addAuditObservation: (auditObservation: IAuditObservation) => void;
    removeAuditObservation: (auditObservationId: string) => void;
}

export default QualifiedSupervisorAuditGridRow;
