import { useCallback, useContext } from "react";
import { ILandlordIssuesPreferencesUpdate } from ".";
import { UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const useUpdateLandlordIssuePreferences = () => {
    const { activeUserParentsIds } = useContext(UserContext);
    const { send, loading } = useApiRequest({});
    const sendRequest = useXTagApiRequest();

    const updateLandlordIssuePreferences = useCallback(
        (preferences: ILandlordIssuesPreferencesUpdate) =>
            send(
                sendRequest({
                    url: `${config.landlordsApiUrl}/issuepreferences`,
                    method: "POST",
                    urlParams: getGlobalUrlParameters([], activeUserParentsIds),
                    body: preferences,
                }),
            ),
        [activeUserParentsIds, send, sendRequest],
    );

    return { updateLandlordIssuePreferences, loading };
};

export default useUpdateLandlordIssuePreferences;
