import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IIssueStatusProps } from ".";
import { Card, SimpleTable } from "../../../components";
import { IColumn } from "../../../components/SimpleTable";
import StatusSelector from "../../../components/StatusSelector";
import { IIssueUser, useUpdateIssueStatus } from "../../../utils/api/issues";
import { IIssueBase } from "../../../utils/api/observations";
import {
    getDisplayDifference,
    getNow,
    toDateTimeString,
} from "../../../utils/dates";

const IssueStatus = ({ issue, type }: IIssueStatusProps) => {
    const { t } = useTranslation();

    const { updateIssueStatus, loading } = useUpdateIssueStatus();

    const handleStatusChange = useCallback(
        (newStatus: string, comment: string) => {
            updateIssueStatus(
                issue.id.toString(),
                newStatus,
                comment,
            ).subscribe();
        },
        [issue.id, updateIssueStatus],
    );

    const statuses = useMemo(() => {
        return ["Resolved", "Unresolved", "Blocked"];
    }, []);

    const statusTableData = useMemo<IColumn[]>(
        () => [
            {
                title: t("Date raised"),
                type: "date",
                path: "dateRaised",
            },
            {
                title: t("Date resolved"),
                path: "dateResolved",
                type: "component",
                render: (value: string | null) =>
                    value ? toDateTimeString(new Date(value)) : t("Unresolved"),
            },
            {
                title: t("Resolved by"),
                path: "resolvedBy",
                type: "component",
                render: (value: IIssueUser | null) =>
                    value ? value.name : t("Unresolved"),
            },
            {
                title: t("Resolution time"),
                path: "dateResolved",
                type: "component",
                render: (value: string | null, row: IIssueBase) => {
                    if (!value) {
                        return t("Unresolved");
                    }
                    const resolvedDate = value
                        ? getDisplayDifference(new Date(value), getNow())
                        : getDisplayDifference(
                              new Date(row.dateRaised),
                              getNow(),
                          );
                    return t(resolvedDate.key, {
                        count: resolvedDate.count,
                    });
                },
            },
        ],
        [t],
    );

    return (
        <Card title={t("Status")}>
            <StatusSelector
                statuses={statuses}
                currentStatus={issue.status.displayName}
                onSave={handleStatusChange}
                loading={loading}
                disableChange={type === "observation"}
            />
            <SimpleTable single={true} columns={statusTableData} data={issue} />
        </Card>
    );
};

export default IssueStatus;
