import { useCallback, useEffect, useState } from "react";
import { from } from "rxjs";
import SignaturePad from "signature_pad";

const useSignaturePad = () => {
    const [signaturePad, setSignaturePad] = useState<SignaturePad | null>(null);
    const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null);

    const canvasRef = useCallback((element: HTMLCanvasElement | null) => {
        if (element) {
            setCanvas(element);
            setSignaturePad(
                new SignaturePad(element, {
                    backgroundColor: "rgb(255,255,255)",
                }),
            );
        }
    }, []);

    useEffect(() => {
        if (canvas && signaturePad) {
            const resizeCanvas = () => {
                const context = canvas.getContext("2d");

                if (context) {
                    const ratio = window.devicePixelRatio;
                    canvas.width = canvas.offsetWidth * ratio;
                    canvas.height = canvas.offsetHeight * ratio;

                    context.scale(ratio, ratio);
                    // TODO: Read the image from the canvas before resizing it and write it back after.
                    signaturePad.clear();
                }
            };

            resizeCanvas();
            window.addEventListener("resize", resizeCanvas);

            return () => window.removeEventListener("resize", resizeCanvas);
        }
    }, [canvas, signaturePad]);

    const isEmpty = useCallback(
        () => (signaturePad ? signaturePad.isEmpty() : true),
        [signaturePad],
    );

    const clear = useCallback(() => {
        if (signaturePad) {
            signaturePad.clear();
        }
    }, [signaturePad]);

    const getImage = useCallback(
        (fileName: string, mimeType: string) => {
            const cropCanvas = (visibleCanvas: HTMLCanvasElement) => {
                const visibleCanvasContext = visibleCanvas.getContext("2d");

                const croppedCanvas = document.createElement("canvas");
                const croppedContext = croppedCanvas.getContext("2d");

                if (visibleCanvasContext && croppedContext) {
                    const imageData = visibleCanvasContext.getImageData(
                        0,
                        0,
                        visibleCanvas.width,
                        visibleCanvas.height,
                    );

                    interface IRGB {
                        r: number;
                        g: number;
                        b: number;
                    }

                    const getColorValue = (x: number, y: number): IRGB => {
                        const index = (imageData.width * y + x) * 4;

                        return {
                            r: imageData.data[index],
                            g: imageData.data[index + 1],
                            b: imageData.data[index + 2],
                        };
                    };

                    const isWhite = ({ r, g, b }: IRGB) =>
                        r === 255 && g === 255 && b === 255;

                    const scanY = (fromTop: boolean) => {
                        const offset = fromTop ? 1 : -1;

                        for (
                            let y = fromTop ? 0 : imageData.height - 1;
                            fromTop ? y < imageData.height : y > -1;
                            y += offset
                        ) {
                            for (let x = 0; x < imageData.width; x++) {
                                if (!isWhite(getColorValue(x, y))) {
                                    return y;
                                }
                            }
                        }
                    };

                    const scanX = (fromLeft: boolean) => {
                        const offset = fromLeft ? 1 : -1;

                        for (
                            let x = fromLeft ? 0 : imageData.width - 1;
                            fromLeft ? x < imageData.width : x > -1;
                            x += offset
                        ) {
                            for (let y = 0; y < imageData.height; y++) {
                                if (!isWhite(getColorValue(x, y))) {
                                    return x;
                                }
                            }
                        }
                    };

                    const cropTop = scanY(true) || 0;
                    const cropBottom = scanY(false) || imageData.height - 1;
                    const cropLeft = scanX(true) || 0;
                    const cropRight = scanX(false) || imageData.width - 1;

                    const cropWidth = cropRight - cropLeft + 1;
                    const cropHeight = cropBottom - cropTop + 1;

                    croppedCanvas.width = cropWidth;
                    croppedCanvas.height = cropHeight;

                    croppedContext.drawImage(
                        visibleCanvas,
                        cropLeft,
                        cropTop,
                        cropWidth,
                        cropHeight,
                        0,
                        0,
                        croppedCanvas.width,
                        croppedCanvas.height,
                    );
                }

                return croppedCanvas;
            };

            return from(
                new Promise<File>((resolve, reject) => {
                    if (canvas) {
                        const croppedCanvas = cropCanvas(canvas);

                        croppedCanvas.toBlob((blob: Blob | null) => {
                            if (blob) {
                                const file = new File([blob], fileName, {
                                    type: mimeType,
                                });

                                resolve(file);
                            } else {
                                reject(
                                    new Error(
                                        "Could not convert canvas to blob!",
                                    ),
                                );
                            }
                        }, mimeType);
                    } else {
                        reject(new Error("Canvas not found!"));
                    }
                }),
            );
        },
        [canvas],
    );

    return { canvasRef, clear, isEmpty, getImage };
};

export default useSignaturePad;
