import { useEffect } from "react";
import { map } from "rxjs";
import { IUserPreferences, IUserPreferencesResponse } from ".";
import config from "../../../config";
import { useXTagApiRequest } from "../../request";
import useApiValue from "../useApiValue";

const useGetPreferences = () => {
    const { send, value, loading, updateValue, error } =
        useApiValue<IUserPreferences>({
            initialValue: {
                id: 0,
                preferences: {},
            },
        });
    const sendRequest = useXTagApiRequest();

    useEffect(() => {
        const subscription = send(
            sendRequest<IUserPreferencesResponse, unknown>({
                url: `${config.usersApiUrl}/preferences`,
                method: "GET",
            }).pipe(
                map((response) => ({
                    id: response.id,
                    preferences: JSON.parse(response.preferences),
                })),
            ),
        ).subscribe();

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [send, sendRequest]);

    return { value, loading, updateValue, error };
};

export default useGetPreferences;
