import { IAnswer, IInspectionScheduleAnswer } from "../../../utils/api/answers";
import {
    IAnswerOverride,
    IAuditJobComment,
    IAuditObservation,
} from "../../../utils/api/audits";
import { IAnswerSectionGroup } from "../QualifiedSupervisorAudit";
import { IQualifiedSupervisorAuditAnswer } from "../QualifiedSupervisorAuditsGrouping";
import QualifiedSupervisorAuditQuestionsSection from "./QualifiedSupervisorAuditQuestionsSection";

export interface IQualifiedSupervisorAuditQuestionsSectionProps {
    id: string;
    auditId: number;
    isReadOnly: boolean;
    documentType: string | null;
    hiddenAnswer?: IInspectionScheduleAnswer;
    questions: IAnswerSectionGroup<IQualifiedSupervisorAuditAnswer>[];
    overriddenAnswers: IAnswerOverride[];
    updateOverriddenAnswers: (
        overrideAnswers: IAnswerOverride[],
        deleteOverriddenAnswers: number[],
    ) => void;
    addAdditionalAnswers: (answers: IAnswer[]) => void;
    validate: (id: string, isValid: boolean) => void;
    addAuditJobComment: (jobComment: IAuditJobComment) => void;
    removeAuditJobComment: (jobCommentId: string) => void;
    addAuditObservation: (auditObservation: IAuditObservation) => void;
    removeAuditObservation: (auditObservationId: string) => void;
}

export default QualifiedSupervisorAuditQuestionsSection;
