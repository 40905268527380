import { ReactNode } from "react";
import { Img } from "..";
import { useAuthorizedImage } from "../../hooks/useAuthorizedImage";
import styles from "./ImageList.module.scss";

const ImageList = ({ image, children, bottomBanner }: IImageListProps) => {
    const img = useAuthorizedImage(image);

    return (
        <>
            <div className={styles.container}>
                <div className={styles.imgContainer}>
                    <Img circle={true} url={img} size={50} />
                </div>

                {children}
            </div>
            {bottomBanner}
        </>
    );
};

interface IImageListProps {
    image: string;
    bottomBanner?: ReactNode;
    children?: ReactNode;
}

export default ImageList;
