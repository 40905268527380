import { IUser } from "../../../utils/api/users";
import UpdateUserModal from "./UpdateUserModal";

export interface IUpdateUserModalProps {
    user: IUser;
    onUpdateUser: () => void;
    hide: () => void;
}

export default UpdateUserModal;
