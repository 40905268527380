import { useParams } from "react-router-dom";
import { AlarmAppliance } from ".";
import { Loading } from "../../components";
import {
    IAlarmAppliance,
    IFireDoorAppliance,
    IGasAppliance,
    useAppliance,
} from "../../utils/api/appliances";
import PageNotFound from "../PageNotFound";
import FireDoorAppliance from "./FireDoorAppliance";
import GasAppliance from "./GasAppliance";

const Appliance = () => {
    const { id } = useParams();
    const appliance = useAppliance(id ?? "");

    return appliance.loaded ? (
        appliance.value.fuelType === "Fire Door" ? (
            <FireDoorAppliance
                appliance={appliance.value as IFireDoorAppliance}
            />
        ) : ["Smoke Alarm", "CO Alarm", "Heat Alarm", "Fire Alarm"].includes(
              appliance.value.type.displayName,
          ) ? (
            <AlarmAppliance appliance={appliance.value as IAlarmAppliance} />
        ) : (
            <GasAppliance appliance={appliance.value as IGasAppliance} />
        )
    ) : !appliance.error ? (
        <Loading />
    ) : (
        <PageNotFound />
    );
};

export default Appliance;
