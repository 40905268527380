import { IPopoverListItemProps } from ".";
import { useCssClasses } from "../../../hooks";
import useClickableNonInteractiveElement from "../../../hooks/useClickableNonInteractiveElement";
import styles from "./PopoverListItem.module.scss";

/**
 *
 * @deprecated Use PopoverItem instead.
 */
const PopoverListItem = ({
    children,
    onClick,
    isSelected = false,
    applyPadding = true,
}: IPopoverListItemProps) => {
    const cssClasses = useCssClasses(
        styles.container,
        isSelected ? styles.selected : "",
        applyPadding ? styles.padding : "",
    );

    const { onKeyDown } = useClickableNonInteractiveElement(onClick);

    return (
        <div
            className={cssClasses}
            onClick={onClick}
            onKeyDown={onKeyDown}
            role="button"
            tabIndex={0}
        >
            {children}
        </div>
    );
};

export default PopoverListItem;
