import { createContext } from "react";
import { Observable } from "rxjs";
import { IRealtimeContext } from ".";
import { IRealtimeEvent } from "./RealtimeProvider";

const RealtimeContext = createContext<IRealtimeContext>({
    realtimeObservable: new Observable<IRealtimeEvent>(),
});

export default RealtimeContext;
