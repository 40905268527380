import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IBoardDetailsProps } from ".";
import { Icon, Link } from "../../../../components";
import SimpleTable, { IColumn } from "../../../../components/SimpleTable";
import { useToggle } from "../../../../hooks";
import { CircuitResult } from "../../../../utils/api/assets";
import styles from "./BoardDetails.module.scss";

const BoardDetails = ({ boardDetails }: IBoardDetailsProps) => {
    const { t } = useTranslation();
    const { visible, toggle } = useToggle();

    const columns = useMemo<IColumn[]>(() => {
        const renderCircuitNameLink = (value: string, row: any) => (
            <Link url={`/management/circuits/${row.id}`}>{t(value)}</Link>
        );

        const renderStatus = (value: CircuitResult) => (
            <div
                className={`${styles.circuitStatus} ${
                    value === "Pass" ? styles.pass : styles.fail
                }`}
            >
                {t(value)}
            </div>
        );

        return [
            {
                title: t("Name"),
                type: "component",
                path: "description",
                render: renderCircuitNameLink,
            },
            {
                title: t("Inspection Date"),
                type: "date",
                path: "inspectedDate",
            },
            {
                title: t("Status"),
                type: "component",
                path: "result",
                render: renderStatus,
            },
        ];
    }, [t]);

    return (
        <div className={styles.board}>
            <div>{boardDetails.name}</div>
            <button type="button" className={styles.summary} onClick={toggle}>
                <div>
                    {boardDetails.circuits.length} {t("circuits")}
                </div>
                <div>
                    <Icon
                        icon={visible ? "caret-down" : "caret-right"}
                        color="blue"
                        ariaLabel={t("Expand")}
                        size={30}
                    />
                </div>
            </button>
            {visible && (
                <div>
                    <SimpleTable
                        columns={columns}
                        data={boardDetails.circuits}
                    />
                </div>
            )}
        </div>
    );
};

export default BoardDetails;
