import { IInspectionScheduleAnswer } from "../../../utils/api/answers";
import {
    IAnswerOverride,
    IAuditJobComment,
    IAuditObservation,
} from "../../../utils/api/audits";
import { IAnswerSectionGroup } from "../QualifiedSupervisorAudit";
import { OverriddenAnswers } from "../QualifiedSupervisorAuditRow";
import { IQualifiedSupervisorAuditAnswer } from "../QualifiedSupervisorAuditsGrouping";
import SectionEditButtons from "./SectionEditButtons";

export interface ISectionEditButtonsProps {
    auditId: number;
    allQuestionsAreValid: boolean;
    questions: IAnswerSectionGroup<IQualifiedSupervisorAuditAnswer>[];
    isEditing: boolean;
    initialOverriddenAnswers: OverriddenAnswers;
    overriddenAnswers: OverriddenAnswers;
    hiddenAnswer?: IInspectionScheduleAnswer;
    updateOverriddenAnswers: (
        overrideAnswers: IAnswerOverride[],
        deleteOverriddenAnswers: number[],
    ) => void;
    disableEditMode: () => void;
    enableEditMode: () => void;
    onCancelClick: () => void;
    addAuditJobComment: (jobComment: IAuditJobComment) => void;
    removeAuditJobComment: (jobCommentId: string) => void;
    addAuditObservation: (auditObservation: IAuditObservation) => void;
    removeAuditObservation: (auditObservationId: string) => void;
}

export default SectionEditButtons;
