import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Observable, of, switchMap } from "rxjs";
import ManualUploadForm, {
    IManualUploadJob,
} from "../../components/ManualUploadForm";
import { useCreateUnableToAccess } from "../../utils/api/jobs";
import { IFileUpload, useUploadFile } from "../../utils/api/misc";

const AddUtaManualUpload = () => {
    const { t } = useTranslation();

    const {
        createUnableToAccess,
        loading: createUnableToAccessLoading,
        error: createUnableToAccessError,
    } = useCreateUnableToAccess();
    const {
        uploadFile,
        loading: uploadFileLoading,
        error: uploadFileError,
    } = useUploadFile();

    const handleSubmit = useCallback(
        (job: IManualUploadJob) => {
            let request: Observable<IFileUpload | null>;

            if (job.file !== null) {
                request = uploadFile(
                    `landlord/${job.landlordId}/property/${job.propertyId}/utas/${job.filename}`,
                    job.file,
                );
            } else {
                request = of(null);
            }

            return request.pipe(
                switchMap((f) =>
                    createUnableToAccess({
                        engineerId: job.engineerId,
                        propertyId: job.propertyId,
                        fuelTypeId: job.fuelTypeId,
                        jobType: job.jobType,
                        date: job.date,
                        manualUploadReasonId: job.manualUploadReasonId,
                        manualUploadOtherReason: job.manualUploadOtherReason,
                        comments: job.comments,
                        imageUrl: f?.filename,
                    }),
                ),
            );
        },
        [createUnableToAccess, uploadFile],
    );

    return (
        <ManualUploadForm
            cardTitle={t("Add an unable to access upload")}
            isLoading={createUnableToAccessLoading || uploadFileLoading}
            error={t(createUnableToAccessError) || uploadFileError}
            dateFieldLabel={t("UTA date")}
            showCommentsField={true}
            showUnsafeAppliances={false}
            showPaperworkTypes={false}
            successMessage={t(
                "The unable to access upload was created successfully",
            )}
            onSubmit={handleSubmit}
        />
    );
};

export default AddUtaManualUpload;
