import { useContext, useEffect, useMemo } from "react";
import {
    IObservationTypesContext,
    IObservationTypesProviderProps,
    ObservationTypesContext,
} from ".";
import { PropertyCategoriesContext, UserContext } from "../../../components";
import config from "../../../config";
import { IObservationType } from "../../../utils/api/issues";
import useApiValue from "../../../utils/api/useApiValue";
import {
    getGlobalUrlParameters,
    useXTagApiRequest,
} from "../../../utils/request";

export const ObservationTypesProvider = ({
    canLoadTypes,
    children,
}: IObservationTypesProviderProps) => {
    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);
    const { value, send, reset } = useApiValue<IObservationType[]>({
        initialValue: [],
    });
    const sendRequest = useXTagApiRequest();

    useEffect(() => {
        if (canLoadTypes) {
            const subscription = send(
                sendRequest({
                    url: `${config.issuesApiUrl}/observation-types`,
                    method: "GET",
                    urlParams: getGlobalUrlParameters(
                        selectedCategories.map((c) => c.id),
                        activeUserParentsIds,
                    ),
                }),
            ).subscribe();

            return () => {
                if (subscription) {
                    subscription.unsubscribe();
                }
            };
        } else {
            reset();
        }
    }, [
        activeUserParentsIds,
        canLoadTypes,
        reset,
        selectedCategories,
        send,
        sendRequest,
    ]);

    const context = useMemo(() => {
        const temp: IObservationTypesContext = {
            observationTypes: value,
        };

        return temp;
    }, [value]);

    return (
        <ObservationTypesContext.Provider value={context}>
            {children}
        </ObservationTypesContext.Provider>
    );
};
