import { useCallback, useContext } from "react";
import { UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const useCreateAllocatedJob = () => {
    const { activeUserParentsIds } = useContext(UserContext);
    const { send, loading, error } = useApiRequest({});
    const sendRequest = useXTagApiRequest();

    // TODO: Replace any with the correct type.
    const createAllocatedJob = useCallback(
        (job: any) =>
            send(
                sendRequest({
                    url: config.allocatedJobsApiUrl,
                    method: "POST",
                    urlParams: getGlobalUrlParameters([], activeUserParentsIds),
                    body: job,
                }),
            ),
        [activeUserParentsIds, send, sendRequest],
    );

    return { createAllocatedJob, loading, error };
};

export default useCreateAllocatedJob;
