import { CSSProperties, ReactNode } from "react";
import SimpleTable from "./SimpleTable";

export interface ISimpleTableProps {
    single?: boolean;
    // TODO: Remove any.
    data: any[] | any;
    withTimeline?: boolean;
    columns: IColumn[];
    withHeaders?: boolean;
}

export interface ISimpleTableData {
    currentValue: string;
    currentRow: IColumn;
    type?: string;
    // TODO: Remove any.
    render?: (value: string, data: any) => ReactNode;
}

export interface IColumn {
    title: string;
    path: string;
    type: "string" | "date" | "time" | "component" | "select" | "datetime";
    // TODO: Remove any.
    render?: (value: any, data: any) => ReactNode;
    hidden?: boolean;
    cssRules?: CSSProperties;
}

export default SimpleTable;
