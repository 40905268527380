import { useEffect } from "react";
import { useToggle } from ".";

const useToast = (timeout = 10000) => {
    const { show, hide, visible } = useToggle();

    useEffect(
        () => {
            if (visible) {
                const timeoutId = setTimeout(() => {
                    hide();
                }, timeout);

                return () => {
                    clearTimeout(timeoutId);
                };
            }
        },
        [visible, hide, timeout],
    );

    return { show, hide, visible };
};

export default useToast;
