import { IQualifiedSupervisorAuditGridRowProps } from ".";
import useOverrideAnswersQS from "../hooks/useOverrideAnswersQS";
import useUnansweredQuestions from "../hooks/useUnansweredQuestions";
import QualifiedSupervisorAuditGridColumn from "../QualifiedSupervisorAuditGridColumn";
import SectionEditButtons from "../SectionEditButtons";
import styles from "./QualifiedSupervisorAuditGridRow.module.scss";

const QualifiedSupervisorAuditGridRow = ({
    id,
    auditId,
    rowTitle,
    columns,
    answers,
    documentType,
    overriddenAnswers,
    questions,
    isReadOnly,
    hiddenAnswer,
    updateOverriddenAnswers,
    validate,
    addAuditJobComment,
    addAuditObservation,
    removeAuditJobComment,
    removeAuditObservation,
    addAdditionalAnswers,
}: IQualifiedSupervisorAuditGridRowProps) => {
    const {
        isEditing,
        disableEditMode,
        enableEditMode,
        questionsValidation,
        validateQuestion,
        initialOverriddenAnswers,
        updatedOverriddenAnswers,
        overrideAnswer,
        handleCancelEditClick,
        allQuestionsAreValid,
    } = useOverrideAnswersQS(id, questions, overriddenAnswers, validate);

    useUnansweredQuestions(auditId, isEditing, questions, addAdditionalAnswers);

    return (
        <tr>
            <td className={styles.headerColumn}>{rowTitle}</td>

            {!isReadOnly && (
                <td className={styles.editColumn}>
                    <SectionEditButtons
                        auditId={auditId}
                        questions={questions}
                        initialOverriddenAnswers={initialOverriddenAnswers}
                        updateOverriddenAnswers={updateOverriddenAnswers}
                        disableEditMode={disableEditMode}
                        enableEditMode={enableEditMode}
                        isEditing={isEditing}
                        hiddenAnswer={hiddenAnswer}
                        allQuestionsAreValid={allQuestionsAreValid}
                        overriddenAnswers={updatedOverriddenAnswers}
                        onCancelClick={handleCancelEditClick}
                        addAuditJobComment={addAuditJobComment}
                        addAuditObservation={addAuditObservation}
                        removeAuditJobComment={removeAuditJobComment}
                        removeAuditObservation={removeAuditObservation}
                    />
                </td>
            )}

            {columns.map((c) => (
                <QualifiedSupervisorAuditGridColumn
                    key={c.id}
                    questionId={c.id}
                    answers={answers[c.id]}
                    documentType={documentType}
                    overriddenAnswers={updatedOverriddenAnswers}
                    validateQuestion={validateQuestion}
                    overrideAnswer={overrideAnswer}
                    errorMessage={questionsValidation[c.id]}
                    isEditing={isEditing}
                />
            ))}
        </tr>
    );
};

export default QualifiedSupervisorAuditGridRow;
