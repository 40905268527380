import { IFilterProps, ITableFilters } from "..";
import { LogicalOperator } from "../../../utils/api/useFilterable";
import ListFilter from "./ListFilter";

export interface IListFilterProps extends IFilterProps, ITableFilters {
    operator?: LogicalOperator;
    filterFormatter?: (value: string) => string;
}

export default ListFilter;
