import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { Subject } from "rxjs";
import { IUserContext, UserContext } from ".";
import {
    IAuthenticatedUser,
    useUpdateUserParents,
} from "../../utils/api/users";
import useUser from "../../utils/api/users/useUser";
import Loading from "../Loading";
import { AuthenticationError } from "../UserAuthentication/components";

const UserProvider = ({ children }: IUserProviderProps) => {
    const { updateUserParents, loading } = useUpdateUserParents();
    const { value, error: userError, loading: userLoading } = useUser();
    const [activeUserParentsIds, setActiveUserParentsIds] = useState<number[]>(
        [],
    );

    const [user, setUser] = useState<IAuthenticatedUser>(value);

    useEffect(() => {
        if (value) {
            setUser(value);
            setActiveUserParentsIds(
                value.userParents
                    .filter((userParent) => userParent.isActive)
                    .map((userParent) => userParent.userParentId),
            );
        }
    }, [value]);

    const [{ onActiveUserParentsChangedSubject, onActiveUserParentsChanged }] =
        useState(() => {
            const subject = new Subject<number[]>();
            const observable = subject.asObservable();

            return {
                onActiveUserParentsChangedSubject: subject,
                onActiveUserParentsChanged: observable,
            };
        });

    const activateUserParents = useCallback(
        (userIds: number[]) => {
            const users = user.userParents.map((u) => ({
                ...u,
                isActive: userIds.includes(u.userParentId),
            }));

            updateUserParents(userIds).subscribe(() => {
                setUser({ ...user, userParents: users });
                setActiveUserParentsIds(userIds);
                onActiveUserParentsChangedSubject.next(userIds);
            });
        },
        [onActiveUserParentsChangedSubject, updateUserParents, user],
    );

    const context = useMemo<IUserContext>(
        () => ({
            onActiveUserParentsChanged,
            activeUserParentsIds,
            user,
            updateUser: setUser,
            activateUserParents,
            loading,
            isReady: !!user,
        }),
        [
            activeUserParentsIds,
            loading,
            onActiveUserParentsChanged,
            activateUserParents,
            user,
        ],
    );

    return (
        <>
            {userLoading && <Loading />}

            {user && !userLoading && (
                <UserContext.Provider value={context}>
                    {children}
                </UserContext.Provider>
            )}

            {userError && (
                <AuthenticationError
                    errorCode={
                        "A problem occurred when trying to retrieve your account details. Please sign out and try again. If the problem persists, contact support."
                    }
                />
            )}
        </>
    );
};

interface IUserProviderProps {
    children: ReactNode;
}

export default UserProvider;
