import { useTranslation } from "react-i18next";
import { IPropertyCategoryEventLogEntityProps } from ".";
import ComplianceCategory from "../../../components/ComplianceCategory";

const PropertyCategoryEventLogEntity = ({
    eventLog,
}: IPropertyCategoryEventLogEntityProps) => {
    const { t } = useTranslation();
    return (
        <tbody>
            <tr>
                <td>{t("Property")}</td>
                <td>{eventLog.property.addressString}</td>
            </tr>
            <tr>
                <td>{t("Compliance type")}</td>
                <td>
                    <ComplianceCategory
                        displayName={eventLog.payload.displayName}
                        colour={eventLog.payload.colour}
                    />
                </td>
            </tr>
            <tr>
                <td>{t("Assigned Contractor")}</td>
                <td>
                    {eventLog.payload.contractors.map((c) => c.name).join(", ")}
                </td>
            </tr>
        </tbody>
    );
};

export default PropertyCategoryEventLogEntity;
