import { CSSProperties, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import PieChart, {
    IPieChartData,
} from "../../../../components/Charts/PieChart";
import { useCssClasses } from "../../../../hooks";
import { colours } from "../../../../styles/colours";
import { IManualUpload } from "../../../../utils/api/misc";
import styles from "./ManualUpload.module.scss";

const ManualUpload = ({
    manualUploads,
    collapsed = false,
    maxHeight,
}: IManualUploadProps) => {
    const { t } = useTranslation();
    const [hoverIndex, setHoverIndex] = useState(-1);
    const uploadCssClasses = useCssClasses(
        styles.upload,
        collapsed ? styles.uploadCollapsed : "",
    );
    const chartContainerCssClasses = useCssClasses(
        styles.chartContainer,
        collapsed ? styles.chartContainerCollapsed : "",
    );
    const valueCssClasses = useCssClasses(
        styles.value,
        collapsed ? styles.valueCollapsed : "",
    );

    const pieChartData = useMemo<IPieChartData[]>(() => {
        const dictionary: { [key: string]: number } = {};
        for (const uploads of manualUploads) {
            for (const upload of uploads.data) {
                const label = upload.label || "Unknown";
                const value = upload.value;

                if (!dictionary[label]) {
                    dictionary[label] = value;
                } else {
                    dictionary[label] += value;
                }
            }
        }

        return Object.entries(dictionary).map((data, index) => ({
            label: data[0],
            value: data[1],
            color:
                hoverIndex === -1 || hoverIndex === index
                    ? data[0] === "Unknown"
                        ? colours.chart.grey
                        : colours.chart.colours[hoverIndex]
                    : colours.chart.lightGrey,
        }));
    }, [manualUploads, hoverIndex]);

    const total = useMemo(() => {
        let count = 0;

        for (const data of pieChartData) {
            count += data.value;
        }

        return count;
    }, [pieChartData]);

    const style = useMemo(() => {
        const css: CSSProperties = {
            maxHeight: maxHeight,
        };

        return css;
    }, [maxHeight]);

    return (
        <div className={styles.centerContainer}>
            <div className={styles.manualUploadContainer} style={style}>
                <div>
                    <div className={chartContainerCssClasses}>
                        <PieChart
                            data={pieChartData}
                            showLabels={false}
                            thickness={collapsed ? 10 : 30}
                            aspectRatio={1}
                        />

                        <div className={styles.total}>
                            <div
                                className={styles.count}
                                data-test-id="ManualUploads_Total_Div"
                            >
                                {total}
                            </div>
                            <div className={styles.label}>
                                {t("Upload Total")}
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ overflowY: "scroll" }}>
                    {pieChartData.map((data, index) => {
                        const onMouseOver = () => setHoverIndex(index);
                        const onMouseOut = () => setHoverIndex(-1);

                        return (
                            <div
                                key={index}
                                onMouseOver={onMouseOver}
                                onMouseOut={onMouseOut}
                                onFocus={onMouseOver}
                                onBlur={onMouseOut}
                            >
                                <div className={uploadCssClasses}>
                                    <div
                                        className={styles.uploadColour}
                                        style={{
                                            backgroundColor:
                                                data.label === "Unknown"
                                                    ? colours.chart.grey
                                                    : colours.chart.colours[
                                                          index
                                                      ],
                                        }}
                                    />
                                    <div
                                        className={valueCssClasses}
                                        style={{
                                            color:
                                                data.label === "Unknown"
                                                    ? colours.chart.grey
                                                    : colours.chart.colours[
                                                          index
                                                      ],
                                        }}
                                        data-test-id={`ManualUploads_${data.label.replace(
                                            " ",
                                            "_",
                                        )}_Total_Div`}
                                    >
                                        {data.value}
                                    </div>
                                    <div className={styles.label}>
                                        {t(data.label)}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

interface IManualUploadProps {
    manualUploads: IManualUpload[];
    collapsed?: boolean;
    maxHeight: number;
}

export default ManualUpload;
