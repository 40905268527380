import { useCallback, useState } from "react";
import { Observable, tap } from "rxjs";
import useApiRequest, { IApiRequestParams } from "./useApiRequest";

function useApiValue<T>({
    initialValue = null as unknown as T, // TODO: Remove this casting.
    errorMessage,
    discardPreviousValue = false,
}: IApiValueParams<T>): IApiValue<T> {
    const {
        send: sendRequest,
        error,
        loading,
        setError,
    } = useApiRequest<T>({
        errorMessage,
    });
    const [value, setValue] = useState(initialValue);
    const [loaded, setLoaded] = useState(false);

    const [init] = useState(initialValue);
    const reset = useCallback(() => {
        setLoaded(false);
        setValue(init);
    }, [init]);

    const send = useCallback(
        (observable: Observable<T>) => {
            if (discardPreviousValue) {
                reset();
            }

            return sendRequest(
                observable.pipe(
                    tap(
                        (response) => {
                            setValue(response);
                            setLoaded(true);
                        },
                        () => {
                            reset();
                        },
                    ),
                ),
            );
        },
        [discardPreviousValue, reset, sendRequest],
    );

    return {
        value,
        send,
        loaded,
        updateValue: setValue,
        error: error,
        loading: loading,
        setError: setError,
        reset,
    };
}
type UpdateValue<T> = T | ((previousValue: T) => T);

interface IApiValue<T> {
    value: T;
    send: (observable: Observable<T>) => Observable<T>;
    error: string;
    setError: (error: string) => void;
    loaded: boolean;
    updateValue: (value: UpdateValue<T>) => void;
    loading: boolean;
    reset: () => void;
}

interface IApiValueParams<T> extends IApiRequestParams {
    initialValue?: T;
    discardPreviousValue?: boolean;
}

export default useApiValue;
