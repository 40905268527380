import { ReactNode } from "react";
import MessageLog from "./MessageLog";
import { IMention } from "../../utils/api/issues";

export interface IMessage {
    user: string;
    imageUrl: string;
    text: string;
    messageStatus?: ReactNode;
    messageDateTime: Date;
    mentions: IMention[];
}

export default MessageLog;
