import {
    IAudit,
    IAuditComment,
    IAuditJobComment,
    IAuditObservation,
} from "../../../utils/api/audits";
import QualifiedSupervisorParentAudit from "./QualifiedSupervisorParentAudit";

export interface IQualifiedSupervisorParentAuditProps {
    audit: IAudit;
    isReadOnly: boolean;
    onSaveAudit: () => void;
    onHoldAudit: () => void;
    createComment: (comment: IAuditComment) => void;
    addAuditJobComment: (jobComment: IAuditJobComment) => void;
    removeAuditJobComment: (jobCommentId: string) => void;
    addAuditObservation: (auditObservation: IAuditObservation) => void;
    removeAuditObservation: (auditObservationId: string) => void;
    removeAuditOverrides: () => void;
    sidebarVisible: boolean;
}

export default QualifiedSupervisorParentAudit;
