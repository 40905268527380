import { useCallback, useContext } from "react";
import { IContractorVerificationDetails } from ".";
import { UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const useGenerateContractorVerificationDetails = () => {
    const { send, loading } = useApiRequest<IContractorVerificationDetails>({});
    const { activeUserParentsIds } = useContext(UserContext);
    const sendRequest = useXTagApiRequest();

    const generateVerificationDetails = useCallback(() => {
        return send(
            sendRequest({
                url: `${config.landlordsApiUrl}/contractors/verification`,
                method: "PUT",
                urlParams: getGlobalUrlParameters([], activeUserParentsIds),
            }),
        );
    }, [activeUserParentsIds, send, sendRequest]);

    return { loading, generateVerificationDetails };
};

export default useGenerateContractorVerificationDetails;
