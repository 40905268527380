import { ReactNode, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { SearchableSelect } from "..";
import { InputSize } from "../InputField";
import { ISelectOption } from "../Select";

const SelectFilter = ({
    label,
    options,
    search,
    value,
    defaultValue,
    currentPage,
    totalPages,
    goToNextPage,
    applySearch,
    onChange,
    isValid,
    error,
    useMargin,
    applyPadding = true,
    customRender,
    disabled = false,
    size,
}: ISelectFilterProps) => {
    const { t } = useTranslation();
    const [selectOptions, setSelectOptions] = useState<ISelectOption[]>([]);

    const defaultValueOptions = useMemo(() => {
        if (
            defaultValue &&
            defaultValue.value === value &&
            selectOptions.findIndex((option) => option.value === value) === -1
        ) {
            return [defaultValue, ...selectOptions];
        }

        return selectOptions;
    }, [selectOptions, defaultValue, value]);

    useEffect(() => {
        if (currentPage > 1) {
            const newOptions = [...selectOptions];

            newOptions.push(...options);
            setSelectOptions(newOptions);
        } else {
            setSelectOptions(options);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options]);

    const hasMore = useMemo(() => currentPage < totalPages, [
        currentPage,
        totalPages,
    ]);
    const loadNextPage = () => {
        if (hasMore) {
            goToNextPage();
        }
    };

    return (
        <>
            <SearchableSelect
                placeholder={t("Search")}
                options={defaultValueOptions}
                label={label}
                value={value}
                search={search}
                onChange={onChange}
                hasMore={hasMore}
                loadNextPage={loadNextPage}
                applySearch={applySearch}
                isValid={isValid}
                error={error}
                applyPadding={applyPadding}
                customRender={customRender}
                disabled={disabled}
                useMargin={useMargin}
                size={size}
            />
        </>
    );
};

interface ISelectFilterProps {
    label?: string;
    value?: string;
    defaultValue?: ISelectOption | null;
    options: ISelectOption[];
    search: string;
    currentPage: number;
    totalPages: number;
    goToNextPage: () => void;
    applySearch: (query: string) => void;
    onChange: (id: string) => void;
    isValid?: boolean;
    applyPadding?: boolean;
    error?: string;
    customRender?: (value: string) => ReactNode;
    disabled?: boolean;
    useMargin?: boolean;
    size?: InputSize;
}

export default SelectFilter;
