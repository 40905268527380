import { useContext, useEffect } from "react";
import { filter, map } from "rxjs";
import { IPortalPropertyNote } from ".";
import {
    PropertyCategoriesContext,
    RealtimeContext,
    UserContext,
} from "../../../components";
import config from "../../../config";
import { clearCache } from "../../cache";
import { realtimeCreateList } from "../../realtime";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiValue from "../useApiValue";

const usePortalPropertyNotes = (id: number) => {
    const { realtimeObservable } = useContext(RealtimeContext);

    const { activeUserParentsIds } = useContext(UserContext);
    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { value, loaded, send, updateValue } = useApiValue<
        IPortalPropertyNote[]
    >({
        initialValue: [],
        discardPreviousValue: true,
    });
    const sendRequest = useXTagApiRequest();

    useEffect(() => {
        if (value) {
            const subscription = realtimeObservable
                .pipe(
                    filter(
                        (e) =>
                            e.entity === "property-note" &&
                            e.event === "created",
                    ),
                    map((e) => e.payload as IPortalPropertyNote),
                    filter((e) => Number(id) === e.propertyId),
                )
                .subscribe((newValue) => {
                    const updatedList = realtimeCreateList(newValue, value);

                    if (updatedList) {
                        updateValue(updatedList);
                    }

                    clearCache();
                });

            return () => {
                if (subscription) {
                    subscription.unsubscribe();
                }
            };
        }
    }, [id, realtimeObservable, updateValue, value]);

    useEffect(() => {
        const subscription = send(
            sendRequest({
                url: `${config.propertiesApiUrl}/${id}/portalnotes`,
                method: "GET",
                urlParams: getGlobalUrlParameters(
                    selectedCategories.map((c) => c.id),
                    activeUserParentsIds,
                ),
            }),
        ).subscribe();

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [id, selectedCategories, activeUserParentsIds, send, sendRequest]);

    return { value, loaded, updateValue };
};

export default usePortalPropertyNotes;
