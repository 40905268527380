import { useTranslation } from "react-i18next";
import { Button, Icon } from "..";
import styles from "./DataSelect.module.scss";

const DataSelect = ({
    label,
    current,
    dataLength,
    onChange,
}: IDataSelectProps) => {
    const { t } = useTranslation();

    const handlePreviousClick = () => {
        let index = current - 1;

        if (index < 0) {
            index = dataLength - 1;
        }

        onChange(index);
    };

    const handleNextClick = () => {
        let index = current + 1;

        if (index >= dataLength) {
            index = 0;
        }

        onChange(index);
    };

    return (
        <div className={styles.container}>
            <Button
                displayAsLink={true}
                size="small"
                onClick={handlePreviousClick}
                testId="DataSelect_Left_Button"
            >
                <Icon icon="caret-left" ariaLabel={t("Previous")} />
            </Button>

            <span className={styles.label} data-test-id="DataSelect_Label_Span">
                {label}
            </span>

            <Button
                displayAsLink={true}
                size="small"
                onClick={handleNextClick}
                testId="DataSelect_Right_Button"
            >
                <Icon icon="caret-right" ariaLabel={t("Next")} />
            </Button>
        </div>
    );
};

interface IDataSelectProps {
    label: string;
    current: number;
    dataLength: number;
    onChange: (index: number) => void;
}

export default DataSelect;
