import { useTranslation } from "react-i18next";
import { IApplianceUtaListProps } from ".";
import { Card, ImageList, Link } from "../../../components";
import { useAllUnableToAccessList } from "../../../utils/api/jobs";
import { toDateTimeString } from "../../../utils/dates";

const ApplianceUtaList = ({
    propertyId,
    applianceId,
}: IApplianceUtaListProps) => {
    const { t } = useTranslation();

    const { records } = useAllUnableToAccessList(propertyId.toString(), {
        applianceId,
    });

    return (
        <Card title={t("Unable to Access")} maxHeight={400}>
            {records.length > 0
                ? records.map((uta) => (
                      <ImageList key={uta.id} image={uta.imageUrl}>
                          <div>
                              <Link url={`/issues/unabletoaccess/${uta.id}`}>
                                  {toDateTimeString(new Date(uta.date))}
                              </Link>
                              &nbsp;{t("by")}
                              &nbsp;
                              <Link
                                  url={`/management/engineers/${uta.engineer.id}`}
                              >
                                  {uta.engineer.name}
                              </Link>
                          </div>
                      </ImageList>
                  ))
                : t("No unable to accesses found")}
        </Card>
    );
};

export default ApplianceUtaList;
