import { IPropertyTabHeaderProps } from ".";
import { Icon } from "../../../components";
import styles from "./PropertyTabHeader.module.scss";

const PropertyTabHeader = ({ icon, label }: IPropertyTabHeaderProps) => {
    return (
        <div className={styles.container}>
            <Icon
                icon={icon}
                display="inline-block"
                ariaHidden={true}
                cssRules={{ marginRight: "10px" }}
                color="grey70"
            />
            {label}
        </div>
    );
};

export default PropertyTabHeader;
