import { useCallback, useMemo } from "react";
import { IOverrideMultiSelectAnswerProps } from ".";
import { Select } from "../../../components";
import useQuestionAnswerOptions from "../../../hooks/useQuestionAnswerOptions";

const OverrideMultiSelectAnswer = ({
    answer,
    value,
    overrideAnswer,
}: IOverrideMultiSelectAnswerProps) => {
    const handleChange = useCallback(
        (newValue: string) =>
            overrideAnswer(answer.id, newValue ? `["${newValue}"]` : undefined),
        [answer, overrideAnswer],
    );

    const options = useQuestionAnswerOptions(answer);

    const selectedValue = useMemo(
        () => (value || "").replace(/^\["(.+)"\]$/, "$1"),
        [value],
    );

    return (
        <>
            {options.length > 0 && (
                <Select
                    options={options}
                    value={selectedValue}
                    onChange={handleChange}
                    allowEmpty={true}
                    canDeselect={true}
                    useMargin={false}
                />
            )}
        </>
    );
};

export default OverrideMultiSelectAnswer;
