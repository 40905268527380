import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useCssClasses } from "../../../../hooks";
import useClickableNonInteractiveElement from "../../../../hooks/useClickableNonInteractiveElement";
import { useNow } from "../../../../hooks/useNow";
import { getDisplayDifference } from "../../../../utils/dates";
import AuthorizedImage from "../../../AuthorizedImage";
import Link from "../../../Link/Link";
import styles from "./NotificationDropdownItem.module.scss";

const NotificationDropdownItem = ({
    id,
    title,
    description,
    icon,
    date,
    read,
    url,
    markAsRead,
}: INotificationDropdownItemProps) => {
    const { t } = useTranslation();
    const now = useNow();

    const timeAgo = useMemo(
        () => getDisplayDifference(new Date(date), now),
        [date, now],
    );

    const markNotificationAsRead = useCallback(() => {
        markAsRead(id);
    }, [id, markAsRead]);

    const { onKeyDown } = useClickableNonInteractiveElement(
        markNotificationAsRead,
    );

    const cssClasses = useCssClasses(
        styles.container,
        read ? "" : styles.unread,
    );

    return (
        <div className={cssClasses}>
            {/* TODO: Check why loading background colour isn't being removed making transparent necessary */}
            <div className={styles.image}>
                <AuthorizedImage
                    image={`PortalIcons/NotificationImages/${icon}`}
                    size={50}
                    cssRules={{ backgroundColor: "transparent" }}
                />
            </div>

            <div
                className={styles.details}
                onClick={markNotificationAsRead}
                onKeyDown={onKeyDown}
                role="button"
                tabIndex={0}
            >
                <Link url={url}>
                    <div>
                        <span className={styles.time}>
                            {t(timeAgo.key, { count: timeAgo.count })}
                        </span>
                        {t(title)}
                    </div>
                    <div className={styles.description}>{t(description)}</div>
                </Link>
            </div>
        </div>
    );
};

interface INotificationDropdownItemProps {
    id: number;
    title: string;
    description: string;
    icon: string;
    date: string;
    read: boolean;
    url: string;
    markAsRead: (id: number) => void;
}

export default NotificationDropdownItem;
