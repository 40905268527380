import { useTranslation } from "react-i18next";
import { IToolbarProps } from ".";
import { Icon, Link, NavLink } from "../..";
import Button from "../../../components/Button";
import {
    Notification,
    RealtimeNotifications,
} from "../../../components/Notification";
import { useHasPermission, useNavigationLinks } from "../../../hooks";
import logo from "../../../images/logo.svg";
import { NotificationCategory } from "../../../utils/api/misc";
import UserProfile from "../UserProfile/UserProfile";
import ComplianceTypesDropdown from "./ComplianceTypesDropdown";
import styles from "./Toolbar.module.scss";
import UserParentsDropdown from "./UserParentsDropdown";

const Toolbar = ({ appRoutes }: IToolbarProps) => {
    const { t } = useTranslation();
    const links = useNavigationLinks(appRoutes);
    const isPortalStandard = useHasPermission("portal_standard");

    return (
        <header className={styles.container}>
            <div className={styles.logoContainer}>
                <Link url="/" display="block">
                    <img src={logo} alt="home" className={styles.logo} />
                </Link>
            </div>

            <div
                className={styles.middle}
                aria-label={t("Main menu")}
                role="navigation"
            >
                {links.map((link) => (
                    <NavLink key={link.url} {...link} position="TopMenu" />
                ))}
            </div>

            <div className={styles.actions}>
                {isPortalStandard && <UserParentsDropdown />}

                <Notification
                    notificationCategory={NotificationCategory.Standard}
                    icon="bell"
                    testId="Notifications_Button"
                />
                <Notification
                    notificationCategory={NotificationCategory.Issue}
                    icon="bolt"
                    testId="Issues_Button"
                />
                <RealtimeNotifications />

                <ComplianceTypesDropdown />

                <UserProfile />

                <div className={`beamerTrigger ${styles.beamer}`}>
                    <Button displayAsLink={true}>
                        <Icon icon="info" ariaHidden={true} />
                    </Button>
                </div>
            </div>
        </header>
    );
};

export default Toolbar;
