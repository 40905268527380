import { useTranslation } from "react-i18next";
import { IManualUploadEventLogEntityProps } from ".";
import ComplianceCategory from "../../../components/ComplianceCategory";
import { toDateTimeString } from "../../../utils/dates";

const ManualUploadEventLogEntity = ({
    eventLog,
}: IManualUploadEventLogEntityProps) => {
    const { t } = useTranslation();

    return (
        <tbody>
            <tr>
                <td>{t("Job id")}</td>
                <td>{eventLog.job?.id}</td>
            </tr>
            <tr>
                <td>{t("Engineer Name")}</td>
                <td>{eventLog.payload.engineer.name}</td>
            </tr>
            <tr>
                <td>{t("Job Type")}</td>
                <td>{eventLog.payload.jobType}</td>
            </tr>
            <tr>
                <td>{t("Job Date")}</td>
                <td>{toDateTimeString(new Date(eventLog.payload.date))}</td>
            </tr>
            <tr>
                <td>{t("Compliance type")}</td>
                <td>
                    <ComplianceCategory
                        displayName={
                            eventLog.payload.complianceType.displayName
                        }
                        colour={eventLog.payload.complianceType.colour}
                    />
                </td>
            </tr>
        </tbody>
    );
};

export default ManualUploadEventLogEntity;
