import { useContext, useMemo } from "react";
import { PropertyCategoriesContext } from "../components";

const useSingleSelectedPropertyCategory = () => {
    const { selectedCategories } = useContext(PropertyCategoriesContext);

    const category = useMemo(() => {
        if (selectedCategories.length === 1) {
            return selectedCategories[0];
        }
    }, [selectedCategories]);

    return category;
};

export default useSingleSelectedPropertyCategory;
