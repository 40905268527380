import { createContext } from "react";
import { of } from "rxjs";
import { IUserPreferencesContext } from ".";

const UserPreferencesContext = createContext<IUserPreferencesContext>({
    preferences: {},
    loading: false,
    updatePreferences: () => of(),
});

export default UserPreferencesContext;
