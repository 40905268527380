import { IBreadcrumbItemProps } from ".";
import { useCssClasses } from "../../../hooks";
import styles from "./BreadcrumbItem.module.scss";

const BreadcrumbItem = ({ isActive, children }: IBreadcrumbItemProps) => {
    const cssClasses = useCssClasses(
        styles.container,
        isActive ? styles.active : "",
    );

    return <li className={cssClasses}>{children}</li>;
};

export default BreadcrumbItem;
