import {
    IAnswer,
    IInspectionSchedulePayload,
} from "../../../utils/api/answers";
import OverrideAnswer from "./OverrideAnswer";

export interface IOverrideAnswerProps {
    documentType: string | null;
    answer: IAnswer;
    value?: string | IInspectionSchedulePayload;
    overrideAnswer: (
        answerId: number,
        answer?: string | IInspectionSchedulePayload,
    ) => void;
}

export default OverrideAnswer;
