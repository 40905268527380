import { useCallback } from "react";
import config from "../../../config";
import { useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const useUnregisterTag = () => {
    const { send, error, loading } = useApiRequest({});
    const sendRequest = useXTagApiRequest();

    const unregisterTag = useCallback(
        (tagNumber: string) =>
            send(
                sendRequest({
                    url: `${config.propertiesApiUrl}/tag/${tagNumber}`,
                    method: "DELETE",
                }),
            ),
        [send, sendRequest],
    );

    return { unregisterTag, error, loading };
};

export default useUnregisterTag;
