import { IUnresolvedObservationsChartOverviewProps } from ".";
import { Grid, GridColumn, Link } from "../../../components";
import { usePhaseBoundaries } from "../../../hooks/usePhaseBoundaries";
import { encodeUrl } from "../../../utils/url";
import OverviewCard from "../ChartComponents/OverviewCard";

const UnresolvedObservationsChartOverview = ({
    phases,
    observationType,
}: IUnresolvedObservationsChartOverviewProps) => {
    const phasesGroups = usePhaseBoundaries(phases, 7, true);

    return (
        <Grid>
            {phasesGroups.map((group, index) => (
                <GridColumn size="half" key={index}>
                    <Link
                        url={encodeUrl("/issues/observations", {
                            dateRaised: [
                                `>=${group.from.toISOString()}{AND}<${group.to.toISOString()}`,
                            ],
                            ...(observationType !== "" && {
                                "type.displayName": [`=${observationType}`],
                            }),
                        })}
                    >
                        <OverviewCard phase={group.phase} />
                    </Link>
                </GridColumn>
            ))}
        </Grid>
    );
};

export default UnresolvedObservationsChartOverview;
