import { IDrawableObject } from ".";
import { initializeCanvas } from "./canvas";

const hover = (
    event: MouseEvent,
    canvas: HTMLCanvasElement,
    objects: IDrawableObject[],
    update: () => void,
) => {
    const canvasBounds = canvas.getBoundingClientRect();

    let x = 0;
    let y = 0;

    if (event.type === "mouseleave") {
        x = -1;
        y = -1;
    } else {
        x = event.clientX - canvasBounds.left;
        y = event.clientY - canvasBounds.top;
    }

    objects.forEach((object) => {
        if (object.onHover) {
            const requiresUpdate = object.onHover(x, y);

            if (requiresUpdate) {
                update();
            }
        }
    });
};

const click = (
    event: MouseEvent,
    canvas: HTMLCanvasElement,
    objects: IDrawableObject[],
    update: () => void,
) => {
    const canvasBounds = canvas.getBoundingClientRect();

    const x = event.clientX - canvasBounds.left;
    const y = event.clientY - canvasBounds.top;

    objects.forEach((object) => {
        if (object.onClick) {
            const requiresUpdate = object.onClick(x, y);

            if (requiresUpdate) {
                update();
            }
        }
    });
};

const createChart = ({
    canvas,
    objects,
    aspectRatio,
    fontSize,
    maxHeight,
}: IChartProps) => {
    const chart = initializeCanvas(
        canvas,
        objects,
        aspectRatio,
        fontSize,
        maxHeight,
    );

    const handleResize = () => chart.resize();

    const handleHover = (event: MouseEvent) => {
        hover(event, canvas, objects, () => chart.update());
    };

    const handleClick = (event: MouseEvent) => {
        click(event, canvas, objects, () => chart.update());
    };

    window.addEventListener("resize", handleResize);
    canvas.addEventListener("mousemove", handleHover);
    canvas.addEventListener("mouseleave", handleHover);
    canvas.addEventListener("click", handleClick);

    return {
        updateObjects: (newObjects: IDrawableObject[]) => {
            chart.updateObjects(newObjects);
            chart.resize();
        },
        destroy: () => {
            chart.destroy();
            window.removeEventListener("resize", handleResize);
            canvas.removeEventListener("mousemove", handleHover);
            canvas.removeEventListener("mouseleave", handleHover);
            canvas.removeEventListener("click", handleClick);
        },
    };
};

interface IChartProps {
    canvas: HTMLCanvasElement;
    objects: IDrawableObject[];
    aspectRatio?: number;
    fontSize?: number;
    maxHeight?: number;
}

export { createChart };
