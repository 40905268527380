import { useEffect, useState } from "react";
import { v4 } from "uuid";

const ids: { [key: string]: true | undefined } = {};

export const useHtmlEntityId = () => {
    const [uniqueId, setUniqueId] = useState<string>();

    useEffect(() => {
        const getUniqueId = (): string => {
            const id = v4();
            if (ids[id]) {
                return getUniqueId();
            } else {
                return id;
            }
        };

        const id = getUniqueId();

        ids[id] = true;
        setUniqueId(id);

        return () => {
            delete ids[id];
        };
    }, []);

    return uniqueId;
};
