import { ReactNode } from "react";

const TabCardTab = ({ children }: ITabCardTabProps) => {
    return <>{children}</>;
};

interface ITabCardTabProps {
    title: string;
    actions?: ReactNode;
    children: ReactNode;
}

export default TabCardTab;
