import { KeyboardEvent, RefObject, useCallback } from "react";

const useFocusTrap = (
    ref: RefObject<HTMLElement>,
    isEnabled: boolean,
    hide: () => void,
) => {
    const onKeyDown = useCallback(
        (e: KeyboardEvent<HTMLElement>) => {
            if (isEnabled) {
                if (e.key === "Escape") {
                    e.stopPropagation();
                    hide();
                } else if (e.key === "Tab") {
                    if (ref.current) {
                        const focusableElements = ref.current.querySelectorAll(
                            'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
                        );

                        if (focusableElements.length > 0) {
                            const focusedElement =
                                document.querySelector(":focus");

                            const firstElement = focusableElements[0];
                            const lastElement =
                                focusableElements[focusableElements.length - 1];

                            if (e.shiftKey) {
                                if (focusedElement === firstElement) {
                                    e.preventDefault();
                                    (lastElement as HTMLElement).focus();
                                }
                            } else if (focusedElement === lastElement) {
                                e.preventDefault();
                                (firstElement as HTMLElement).focus();
                            }
                        }
                    }
                }
            }
        },
        [isEnabled, hide, ref],
    );

    return { onKeyDown };
};

export default useFocusTrap;
