import { useTranslation } from "react-i18next";
import { IMeterReadingCardProps } from ".";
import { Card } from "../../../components";
import AuthorizedImage from "../../../components/AuthorizedImage";

const MeterReadingCard = ({ unableToAccess }: IMeterReadingCardProps) => {
    const { t } = useTranslation();

    return (
        <Card
            title={
                t("Meter Reading") + `: ${unableToAccess.meterReading ?? ""}`
            }
        >
            {unableToAccess.meterReadingImageUrl ? (
                <AuthorizedImage image={unableToAccess.meterReadingImageUrl} />
            ) : (
                t("No meter reading supplied")
            )}
        </Card>
    );
};

export default MeterReadingCard;
