import { Grid, GridColumn, Link } from "../../../../components";
import { usePhaseBoundaries } from "../../../../hooks/usePhaseBoundaries";
import { IPhase } from "../../../../utils/api/properties";
import { IUrlParameters, encodeUrl } from "../../../../utils/url";
import OverviewCard from "../OverviewCard";

const ChartOverview = ({
    phases,
    dateFilterField,
    targetUrl,
    targetUrlParams,
    phaseTimeFrameMultiplier,
    areDatesInPast,
}: IChartOverviewProps) => {
    const phasesGroups = usePhaseBoundaries(
        phases,
        phaseTimeFrameMultiplier,
        areDatesInPast,
    );

    return (
        <Grid>
            {phasesGroups.map((group, index) => (
                <GridColumn size="half" key={index}>
                    <Link
                        url={encodeUrl(targetUrl, {
                            ...targetUrlParams,
                            [dateFilterField]: [
                                `>=${group.from.toISOString()}{AND}<${group.to.toISOString()}`,
                            ],
                        })}
                    >
                        <OverviewCard phase={group.phase} />
                    </Link>
                </GridColumn>
            ))}
        </Grid>
    );
};

interface IChartOverviewProps {
    phases: IPhase[];
    dateFilterField: string;
    targetUrl: string;
    targetUrlParams?: IUrlParameters;
    phaseTimeFrameMultiplier: number;
    areDatesInPast: boolean;
}

export default ChartOverview;
