import { useEffect } from "react";
import { IRole } from ".";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiValue from "../useApiValue";

export const useRoles = () => {
    const { send, value, loaded } = useApiValue<IRole[]>({ initialValue: [] });

    const sendRequest = useXTagApiRequest();

    useEffect(() => {
        const subscription = send(
            sendRequest({
                url: `${config.usersApiUrl}/v2/roles`,
                method: "GET",
                urlParams: getGlobalUrlParameters([], []),
            }),
        ).subscribe();

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [send, sendRequest]);

    return { value, loaded };
};

export default useRoles;
