import { first, sortBy } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Observable, of, switchMap } from "rxjs";
import {
    Alert,
    Button,
    Checkbox,
    Form,
    InputField,
    Loading,
    Modal,
    Portal,
    RadioButton,
    Select,
} from "../../../components";
import FileUpload from "../../../components/FileUpload";
import InputDatePicker from "../../../components/InputDatePicker";
import ModalBody from "../../../components/Modal/ModalBody";
import ModalFooter from "../../../components/Modal/ModalFooter";
import { ISelectOption } from "../../../components/Select";
import { IAllocatedJob } from "../../../utils/api/allocatedJobs";
import useCreateDocument from "../../../utils/api/documents/useCreateDocument";
import { useCreateJob, useCreateUnableToAccess } from "../../../utils/api/jobs";
import { ILandlordEngineer } from "../../../utils/api/landlords";
import {
    IFileUpload,
    useManualUploadReasons,
    usePaperworkTypes,
} from "../../../utils/api/misc";
import useUploadFile from "../../../utils/api/misc/useUploadFile";
import { getDate } from "../../../utils/dates";
import {
    isRequired,
    IValidateField,
    useValidateField,
    validateForm,
} from "../../../utils/validation";
import styles from "./ManuallyCompleteJobModal.module.scss";

const ManuallyCompleteJobModal = ({
    allocatedJob,
    hide,
}: IManuallyCompleteJobModalProps) => {
    const { t } = useTranslation();

    const createJob = useCreateJob();
    const createDocument = useCreateDocument();
    const createUnableToAccess = useCreateUnableToAccess();
    const uploadFile = useUploadFile();

    const paperworkTypes = usePaperworkTypes();

    const [type, setType] = useState<"job" | "uta">("job");

    const handleTypeSelection = useCallback(
        (checked: boolean, value: "job" | "uta") => {
            if (checked) {
                setType(value);
            }
        },
        [],
    );

    const [otherReason, setOtherReason] = useState("");
    const otherReasonValidator = useValidateField(otherReason, isRequired());

    const [selectedUploadReason, setSelectedUploadReason] = useState("");

    const manualUploadReasons = useManualUploadReasons();

    const manualUploadReasonsOptions = useMemo<ISelectOption[]>(
        () =>
            manualUploadReasons.value.map((reason) => ({
                label: t(reason.description),
                value: reason.id.toString(),
            })),
        [manualUploadReasons.value, t],
    );

    const [filename, setFilename] = useState("");
    const [file, setFile] = useState<File | null>(null);
    const handleFileSelected = useCallback(
        (selectedFilename: string, selectedFile: File | null) => {
            setFilename(selectedFilename);
            setFile(selectedFile);
        },
        [],
    );
    const fileValidator = useValidateField(file, isRequired());

    const [utaFilename, setUtaFilename] = useState("");
    const [utaFile, setUtaFile] = useState<File | null>(null);
    const handleUtaFileSelected = useCallback(
        (selectedFilename: string, selectedFile: File | null) => {
            setUtaFilename(selectedFilename);
            setUtaFile(selectedFile);
        },
        [],
    );
    const utaFileValidator = useValidateField(utaFile, isRequired());

    const [jobDate, setJobDate] = useState(
        getDate(new Date(allocatedJob.jobDate)),
    );

    const [utaDate, setUtaDate] = useState(
        getDate(new Date(allocatedJob.jobDate)),
    );

    const jobDateValidator = useValidateField(jobDate, isRequired());

    const utaDateValidator = useValidateField(utaDate, isRequired());

    const [showOtherDocument, setShowOtherDocument] = useState(false);
    const [otherDocument, setOherDocument] = useState("");
    const otherDocumentValidator = useValidateField(
        otherDocument,
        isRequired(),
    );

    const [showFileUpload, setShowFileUpload] = useState(false);

    const [paperworkTypeId, setPaperworkTypeId] = useState("");

    const paperworkTypeIdValidator = useValidateField(
        paperworkTypeId,
        isRequired(),
    );
    const paperworkTypesOptions = useMemo<ISelectOption[]>(
        () =>
            sortBy(
                paperworkTypes.value.map((paperworkType) => ({
                    label: paperworkType.paperworkType,
                    value: paperworkType.id.toString(),
                })),
                (paperworkType) => paperworkType.label,
            ),
        [paperworkTypes.value],
    );

    const [utaComments, setUtaComments] = useState("");
    const utaCommentsValidator = useValidateField(utaComments, isRequired());

    const getOtherReasonId = useCallback(
        (reason: string) => {
            const manualUploadedReason = manualUploadReasons.value.find(
                (r) => r.description === reason,
            );

            return manualUploadedReason?.id.toString();
        },
        [manualUploadReasons.value],
    );

    const handlePaperworkTypeIdChange = (id: string) => {
        setPaperworkTypeId(id);
        setOherDocument("");

        const paperworkType = first(
            paperworkTypes.value.filter((tp) => tp.id.toString() === id),
        );

        setShowOtherDocument(
            !!paperworkType && paperworkType.paperworkType === "Other",
        );
        setShowFileUpload(
            !!paperworkType && paperworkType.paperworkType !== "None",
        );
    };

    const [resetServiceDate, setResetServiceDate] = useState(false);

    const [consent, setConsent] = useState(false);
    const consentValidator = useValidateField(consent, isRequired());

    const uploadReasonValidator = useValidateField(
        selectedUploadReason,
        isRequired(),
    );

    const formValidator = validateForm(() => {
        const validators: IValidateField[] = [
            jobDateValidator,
            consentValidator,
            uploadReasonValidator,
        ];

        if (selectedUploadReason === getOtherReasonId("Other")) {
            validators.push(otherReasonValidator);
        }

        if (type === "job") {
            validators.push(paperworkTypeIdValidator);

            if (showOtherDocument) {
                validators.push(otherDocumentValidator);
            }

            if (showFileUpload) {
                validators.push(fileValidator);
            }
        } else {
            validators.push(utaDateValidator);
            validators.push(utaCommentsValidator);
            validators.push(utaFileValidator);
        }

        return validators;
    });

    const nonePaperworkId = useMemo(() => {
        const paperwork = paperworkTypes.value.find(
            (paperworkType) => paperworkType.paperworkType === "None",
        );

        if (paperwork) {
            return paperwork.id.toString();
        }
    }, [paperworkTypes.value]);

    const isLgsrJob = useMemo(() => {
        const paperwork = paperworkTypes.value.find(
            (paperworkType) =>
                paperworkType.paperworkType === "LGSR" ||
                paperworkType.paperworkType === "GSR",
        );

        if (paperwork !== undefined) {
            return paperworkTypeId === paperwork.id.toString();
        }

        return false;
    }, [paperworkTypeId, paperworkTypes.value]);

    const handleFormSubmit = () => {
        if (type === "job") {
            createJob
                .createJob({
                    allocatedJobId: allocatedJob.id,
                    engineerId: allocatedJob.engineer.id,
                    propertyId: allocatedJob.property.id,
                    fuelTypeId: allocatedJob.complianceType.id,
                    jobType: allocatedJob.jobType,
                    date: jobDate.toISOString(),
                    isLgsrJob: isLgsrJob,
                    resetNextServiceDate: resetServiceDate,
                    manualUploadReasonId: Number(selectedUploadReason),
                    manualUploadOtherReason: otherReason,
                    paperworkTypeId: Number(paperworkTypeId),
                    safeApplianceIds: [],
                })
                .pipe(
                    switchMap((result) => {
                        if (paperworkTypeId === nonePaperworkId || !file) {
                            return of(result);
                        }

                        // Then, attach the Paperwork
                        return uploadFile
                            .uploadFile(
                                `landlord/${allocatedJob.property.landlord.id}/property/${allocatedJob.property.id}/jobs/${filename}`,
                                file,
                            )
                            .pipe(
                                switchMap((fileUploadResult) =>
                                    createDocument.createDocument({
                                        paperworkTypeId:
                                            Number(paperworkTypeId),
                                        paperworkName: otherDocument,
                                        paperworkUrl: fileUploadResult.filename,
                                        date: new Date(result.date),
                                        jobId: result.id,
                                    }),
                                ),
                            );
                    }),
                )
                .subscribe(() => {
                    hide();
                });
        } else {
            let request: Observable<IFileUpload | null>;
            if (utaFile) {
                request = uploadFile.uploadFile(
                    `landlord/${allocatedJob.property.landlord.id}/property/${allocatedJob.property.id}/utas/${utaFilename}`,
                    utaFile,
                );
            } else {
                request = of(null);
            }

            request
                .pipe(
                    switchMap((result) =>
                        createUnableToAccess.createUnableToAccess({
                            allocatedJobId: allocatedJob.id,
                            engineerId: allocatedJob.engineer.id,
                            propertyId: allocatedJob.property.id,
                            fuelTypeId: allocatedJob.complianceType.id,
                            jobType: allocatedJob.jobType,
                            date: utaDate.toISOString(),
                            manualUploadReasonId: Number(selectedUploadReason),
                            manualUploadOtherReason: otherReason,
                            comments: utaComments,
                            imageUrl: result?.filename,
                        }),
                    ),
                )
                .subscribe(() => {
                    hide();
                });
        }
    };

    return (
        <Portal>
            <Form onSubmit={handleFormSubmit} {...formValidator}>
                <Modal hide={hide} title={t("Create Recovery Job")}>
                    <ModalBody>
                        <div className={styles.label}>
                            {t("Was the work carried out successfully?")}
                        </div>
                        <RadioButton
                            onChange={handleTypeSelection}
                            value="job"
                            checked={type === "job"}
                        >
                            {t("Yes - Manually complete the job")}
                        </RadioButton>

                        <RadioButton
                            onChange={handleTypeSelection}
                            value="uta"
                            checked={type === "uta"}
                        >
                            {t("No - Upload UTA evidence")}
                        </RadioButton>

                        <InputField
                            label={t("Property")}
                            value={
                                allocatedJob &&
                                allocatedJob.property.addressString
                            }
                            disabled={true}
                        />

                        <InputField
                            label={t("Engineer")}
                            value={allocatedJob.engineer.name}
                            disabled={true}
                        />

                        <InputField
                            label={t("Job type")}
                            value={allocatedJob.jobType}
                            disabled={true}
                        />

                        <Select
                            label={t("Reason")}
                            value={selectedUploadReason}
                            onChange={setSelectedUploadReason}
                            options={manualUploadReasonsOptions}
                            allowEmpty={true}
                            {...uploadReasonValidator}
                        />
                        {selectedUploadReason &&
                            selectedUploadReason ===
                                getOtherReasonId("Other") && (
                                <InputField
                                    label={t("Other Reason")}
                                    value={otherReason}
                                    onChange={setOtherReason}
                                    {...otherReasonValidator}
                                />
                            )}

                        {type === "job" ? (
                            <>
                                <InputDatePicker
                                    label={t("Job date")}
                                    date={jobDate}
                                    onDateSelected={setJobDate}
                                    {...jobDateValidator}
                                />

                                <Select
                                    label={t("Paperwork type")}
                                    options={paperworkTypesOptions}
                                    value={paperworkTypeId}
                                    allowEmpty={true}
                                    onChange={handlePaperworkTypeIdChange}
                                    {...paperworkTypeIdValidator}
                                />
                                {showOtherDocument && (
                                    <InputField
                                        label={t(
                                            "Please specify the type of document",
                                        )}
                                        value={otherDocument}
                                        onChange={setOherDocument}
                                        {...otherDocumentValidator}
                                    />
                                )}
                                {showFileUpload && (
                                    <>
                                        <Checkbox
                                            checked={resetServiceDate}
                                            onChange={setResetServiceDate}
                                        >
                                            {t(
                                                "Would you like to reset the property next service date upon submission?",
                                            )}
                                        </Checkbox>

                                        <FileUpload
                                            label={t("Paperwork")}
                                            maxFileSizeInMB={5}
                                            onFileSelected={handleFileSelected}
                                            isLoading={uploadFile.loading}
                                            allowedFileTypes={[".pdf"]}
                                            {...fileValidator}
                                        />
                                    </>
                                )}
                            </>
                        ) : (
                            <>
                                <InputDatePicker
                                    label={t("UTA date")}
                                    date={utaDate}
                                    onDateSelected={setUtaDate}
                                    {...utaDateValidator}
                                />

                                <InputField
                                    label={t("Comments")}
                                    value={utaComments}
                                    onChange={setUtaComments}
                                    {...utaCommentsValidator}
                                />

                                <FileUpload
                                    label={t("Unable to access evidence")}
                                    maxFileSizeInMB={5}
                                    onFileSelected={handleUtaFileSelected}
                                    isLoading={uploadFile.loading}
                                    allowedFileTypes={[
                                        ".jpeg",
                                        ".jpg",
                                        ".png",
                                        ".pdf",
                                    ]}
                                    {...utaFileValidator}
                                />
                            </>
                        )}
                        <Checkbox
                            checked={consent}
                            onChange={setConsent}
                            {...consentValidator}
                        >
                            {t(
                                `I understand that this document was not created or uploaded by Plentific.
                        Its contents are the responsibility of the person who created it.
                        The details (other than the paperwork captured) in this form are my responsibility.`,
                            )}
                        </Checkbox>

                        {(createJob.error ||
                            createUnableToAccess.error ||
                            uploadFile.error ||
                            createDocument.error) && (
                            <Alert type="error">
                                {t(createJob.error) ||
                                    t(createUnableToAccess.error) ||
                                    uploadFile.error ||
                                    t(createDocument.error)}
                            </Alert>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        {createJob.loading ||
                        createUnableToAccess.loading ||
                        uploadFile.loading ||
                        createDocument.loading ? (
                            <Loading small={true} centerContent={true} />
                        ) : (
                            <Button variant="primary" type="submit">
                                {t("Upload")}
                            </Button>
                        )}
                    </ModalFooter>
                </Modal>
            </Form>
        </Portal>
    );
};

interface IManuallyCompleteJobModalProps {
    allocatedJob: IAllocatedJob;
    hide: () => void;
    engineers: ILandlordEngineer[];
}

export default ManuallyCompleteJobModal;
