import { useRef } from "react";
import { Icon, Popover } from "..";
import { useToggle } from "../../hooks";

const InfoTooltip = ({ tooltipText }: IInfoTooltip) => {
    const { show, hide, visible } = useToggle();

    const popoverIcon = useRef<HTMLButtonElement>(null);
    return (
        <>
            <Icon
                icon="info"
                size={12}
                display="inline-block"
                cssRules={{ marginRight: "5px", marginLeft: "5px" }}
                ariaHidden={true}
                setRef={popoverIcon}
                onMouseEnter={show}
                onMouseLeave={hide}
            />
            {visible && (
                <Popover anchorElement={popoverIcon} hide={hide}>
                    {tooltipText}
                </Popover>
            )}
        </>
    );
};

interface IInfoTooltip {
    tooltipText: string;
}

export default InfoTooltip;
