import { IUser } from ".";
import config from "../../../config";
import useAllFilterable from "../useAllFilterable";

const useAllUsers = () => {
    const filterable = useAllFilterable<IUser>({
        url: `${config.usersApiUrl}/v2/all`,
        sortProperties: ["name"],
        filters: {
            disabled: {
                readOnly: true,
                operator: "{AND}",
                filters: [{ function: "=", value: "false" }],
            },
        },
    });
    return filterable;
};

export default useAllUsers;
