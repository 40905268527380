import { IManualUploadResetApproval } from ".";
import config from "../../../config";
import useFilterable, { SortDirection } from "../useFilterable";

const useManualUploadResetApprovals = ({
    type,
    sortProperty = "",
    sortDirection = "asc",
}: ISortParameters) => {
    const filterable = useFilterable<IManualUploadResetApproval>(
        `${config.jobsApiUrl}/manualuploadresetapproval/index`,
        {
            params: {
                sortProperty,
                sortDirection,
                filters: {
                    status: {
                        operator: "{AND}",
                        readOnly: true,
                        filters: [{ function: "=", value: type }],
                    },
                },
            },
            filtersUrl: `${config.jobsApiUrl}/manualuploadresetapproval/filters/unique`,
        },
    );

    return filterable;
};

interface ISortParameters {
    type: "waiting" | "approved" | "declined" | "lapsed";
    sortProperty?: string;
    sortDirection?: SortDirection;
}

export default useManualUploadResetApprovals;
