import { useContext, useEffect } from "react";
import { filter, map } from "rxjs";
import { ITAppliance } from ".";
import { PropertyCategoriesContext, UserContext } from "../../../components";
import RealtimeContext from "../../../components/RealtimeProvider/RealtimeContext";
import config from "../../../config";
import { clearCache } from "../../cache";
import { realtimeUpdateList } from "../../realtime";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import { IJob } from "../jobs";
import { IProperty } from "../properties";
import useApiValue from "../useApiValue";

const useAppliance = (id: string) => {
    const { realtimeObservable } = useContext(RealtimeContext);

    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);
    const { value, loaded, error, send, updateValue } =
        useApiValue<ITAppliance>({});
    const sendRequest = useXTagApiRequest();

    useEffect(() => {
        if (value) {
            const subscription = realtimeObservable
                .pipe(
                    filter(
                        (e) => e.event === "updated" && e.entity === "property",
                    ),
                    map((e) => e.payload as IProperty),
                    filter((e) => e.id === value.property.id),
                )
                .subscribe((newValue) => {
                    clearCache();
                    updateValue({
                        ...value,
                        property: {
                            id: newValue.id,
                            addressString: newValue.addressString,
                            uprn: newValue.uprn,
                            landlord: {
                                id: newValue.landlord.id,
                                userId: newValue.landlord.userId,
                            },
                        },
                    });
                });

            return () => {
                if (subscription) {
                    subscription.unsubscribe();
                }
            };
        }
    }, [realtimeObservable, updateValue, value]);

    useEffect(() => {
        if (value) {
            const subscription = realtimeObservable
                .pipe(
                    filter((e) => e.event === "updated" && e.entity === "job"),
                    map((e) => e.payload as IJob),
                )
                .subscribe((newValue) => {
                    const updatedList = realtimeUpdateList(
                        {
                            ...newValue,
                            tasks: [],
                            isLgsrJob: null,
                        },
                        value.jobs,
                    );

                    if (updatedList) {
                        updateValue({
                            ...value,
                            jobs: updatedList,
                        });
                    }

                    clearCache();
                });

            return () => {
                if (subscription) {
                    subscription.unsubscribe();
                }
            };
        }
    }, [realtimeObservable, updateValue, value]);

    useEffect(() => {
        if (value) {
            const subscription = realtimeObservable
                .pipe(
                    filter(
                        (e) =>
                            e.event === "updated" && e.entity === "appliance",
                    ),
                    map((e) => e.payload as ITAppliance),
                    filter((e) => e.id === id),
                )
                .subscribe((newValue) => {
                    updateValue({
                        ...value,
                        ...newValue,
                    });
                    clearCache();
                });
            return () => {
                if (subscription) {
                    subscription.unsubscribe();
                }
            };
        }
    }, [id, realtimeObservable, updateValue, value]);

    useEffect(() => {
        const subscription = send(
            sendRequest({
                url: `${config.appliancesApiUrl}/${id}`,
                method: "GET",
                urlParams: getGlobalUrlParameters(
                    selectedCategories.map((c) => c.id),
                    activeUserParentsIds,
                ),
            }),
        ).subscribe();

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [activeUserParentsIds, id, selectedCategories, send, sendRequest]);

    return { value, error, loaded };
};

export default useAppliance;
