import { useContext, useMemo } from "react";
import { ISelectOption } from "../components/Select";
import { AgreedLimitationsContext } from "../pages/QualifiedSupervisorAudits/AgreedLimitations";
import { IAnswer } from "../utils/api/answers";

const useQuestionAnswerOptions = (answer: IAnswer) => {
    const { agreedLimitations } = useContext(AgreedLimitationsContext);

    const options = useMemo(() => {
        const list: ISelectOption[] = [];

        if (answer.taskType.taskType === "electric_add_limitation") {
            for (const agreedLimitation of agreedLimitations) {
                list.push({
                    label: agreedLimitation.text,
                    value: agreedLimitation.text,
                });
            }
        } else if (answer.answerOptions) {
            const json: unknown = JSON.parse(answer.answerOptions);

            if (isStringAnswerOptions(json)) {
                for (const option of json) {
                    list.push({
                        label: option,
                        value: option,
                    });
                }
            } else if (typeof json === "object" && json !== null) {
                if (isBooleanAnswerOptions(json)) {
                    list.push(
                        {
                            label: json.positive_value,
                            value: json.positive_value,
                        },
                        {
                            label: json.negative_value,
                            value: json.negative_value,
                        },
                    );
                } else if (isDropdownAnswerOptions(json)) {
                    for (const option of json.choices) {
                        let label = option;
                        if (
                            answer.questionName === "E_CableTypeV2" ||
                            answer.questionName === "E_ReferenceMethodV2"
                        ) {
                            label = label.split("-")[0].trim();
                        }

                        list.push({
                            label: label,
                            value: option,
                        });
                    }
                } else if (isUnitAnswerOptions(json)) {
                    for (const option of json.units) {
                        list.push({
                            label: option,
                            value: option,
                        });
                    }
                } else if (isToggleButtonGroupOptions(json)) {
                    for (const option of json.buttons) {
                        list.push({
                            label: option.label,
                            value: option.label,
                        });
                    }
                }
            }
        }

        return list;
    }, [agreedLimitations, answer]);

    return options;
};

const isStringAnswerOptions = (object: unknown): object is string[] => {
    return Array.isArray(object) && object.every((o) => typeof o === "string");
};

interface IBooleanAnswerOptions {
    positive_value: string;
    negative_value: string;
}

const isBooleanAnswerOptions = (
    object: Record<keyof IBooleanAnswerOptions, unknown> | object,
): object is IBooleanAnswerOptions => {
    const positive =
        "positive_value" in object && typeof object.positive_value === "string";
    const negative =
        "negative_value" in object && typeof object.negative_value === "string";

    return positive && negative;
};

interface IDropdownAnswerOptions {
    choices: string[];
}

const isDropdownAnswerOptions = (
    object: Record<keyof IDropdownAnswerOptions, unknown> | object,
): object is IDropdownAnswerOptions => {
    const options =
        "choices" in object &&
        Array.isArray(object.choices) &&
        object.choices.every((o: unknown) => typeof o === "string");

    return options;
};

interface IUnitAnswerOptions {
    units: string[];
}

const isUnitAnswerOptions = (
    object: Record<keyof IUnitAnswerOptions, unknown> | object,
): object is IUnitAnswerOptions => {
    const options =
        "units" in object &&
        Array.isArray(object.units) &&
        object.units.every((o) => typeof o === "string");

    return options;
};

interface IToggleButtonGroupOptions {
    buttons: { label: string }[];
}

const isToggleButtonGroupOptions = (
    object: Record<keyof IToggleButtonGroupOptions, unknown> | object,
): object is IToggleButtonGroupOptions => {
    const options =
        "buttons" in object &&
        Array.isArray(object.buttons) &&
        object.buttons.every(
            (o) =>
                typeof o === "object" &&
                "label" in o &&
                typeof o.label === "string",
        );

    return options;
};

export default useQuestionAnswerOptions;
