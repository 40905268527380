import { useCallback, useContext } from "react";
import { UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const useAddContractor = () => {
    const { activeUserParentsIds } = useContext(UserContext);
    const { send, loading, error } = useApiRequest({});
    const sendRequest = useXTagApiRequest();

    const addContractor = useCallback(
        (data: IAddContractor) =>
            send(
                sendRequest({
                    url: `${config.landlordsApiUrl}/contractors`,
                    method: "POST",
                    urlParams: getGlobalUrlParameters([], activeUserParentsIds),
                    body: data,
                }),
            ),
        [send, sendRequest, activeUserParentsIds],
    );

    return { addContractor, loading, error };
};

interface IAddContractor {
    contractorId: number;
    userParentIds: number[];
}

export default useAddContractor;
