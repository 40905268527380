import { useContext, useEffect } from "react";
import { IJobComment } from ".";
import { UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiValue from "../useApiValue";

const useJobComments = (jobId: string) => {
    const { activeUserParentsIds } = useContext(UserContext);
    const { value, loaded, send } = useApiValue<IJobComment[]>({
        discardPreviousValue: true,
    });
    const sendRequest = useXTagApiRequest();

    useEffect(() => {
        const subscription = send(
            sendRequest({
                url: `${config.jobsApiUrl}/${jobId}/comments`,
                method: "GET",
                urlParams: getGlobalUrlParameters([], activeUserParentsIds),
            }),
        ).subscribe();

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [jobId, activeUserParentsIds, send, sendRequest]);

    return { value, loaded };
};

export default useJobComments;
