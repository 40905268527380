import { IAllocatedJob } from "../../utils/api/allocatedJobs";
import { ILandlordEngineer } from "../../utils/api/landlords";
import AllocateJobExtendedModal from "./AllocateJobExtendedModal";

export interface IAllocateJobExtendedModalProps {
    allocatedJob?: IAllocatedJob;
    engineers: ILandlordEngineer[];
    loading: boolean;
    defaultJobDate: Date;
    hide: () => void;
    onManuallyCompleteAllocatedJob: () => void;
    onReconcileAllocatedJob: () => void;
    onDeleteAllocatedJob: (jobId: string) => void;
}

export default AllocateJobExtendedModal;
