import { IDocumentAuditPreferences } from "../../../../utils/api/landlords";
import { IPaperworkType } from "../../../../utils/api/misc";
import DocumentPreferencesRow from "./DocumentPreferencesRow";

export interface IDocumentPreferencesRowProps {
    documentPreferences: IDocumentAuditPreferences[];
    paperworkType: IPaperworkType;
    onPreferencesChange: (preference: IDocumentAuditPreferences) => void;
}

export default DocumentPreferencesRow;
