import { useTranslation } from "react-i18next";
import { IDistributionBoardsProps } from ".";
import { Loading } from "../../../components";
import useBoardsAtProperty from "../../../utils/api/assets/useBoardsAtProperty";
import BoardDetails from "./BoardDetails";

const DistributionBoards = ({ propertyId }: IDistributionBoardsProps) => {
    const { t } = useTranslation();

    const { orderedRecords, loaded } = useBoardsAtProperty(propertyId);

    return (
        <>
            {loaded ? (
                orderedRecords.length > 0 ? (
                    orderedRecords.map((board) => (
                        <BoardDetails key={board.id} boardDetails={board} />
                    ))
                ) : (
                    t("No distribution boards")
                )
            ) : (
                <Loading />
            )}
        </>
    );
};

export default DistributionBoards;
