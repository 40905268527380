import { createContext } from "react";
import { of } from "rxjs";
import { INotificationsContext } from ".";

const NotificationsContext = createContext<INotificationsContext>({
    categories: {
        issue: of(),
        standard: of(),
    },
    activeNotificationTypes: [],
    standardTypes: [],
    issueTypes: [],
    updateNotificationsCount: () => null,
    isReady: false,
});

export default NotificationsContext;
