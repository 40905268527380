import { useCallback } from "react";
import { ICommentsListProps } from ".";
import PostComment from "../../../components/MessageLog/PostComment";
import useAuditComment from "../../../utils/api/audits/useAuditComment";
import { toDateTimeString } from "../../../utils/dates";
import styles from "./CommentsList.module.scss";

const CommentsList = ({
    auditId,
    isRemedial,
    isReadOnly,
    comments,
    createComment,
}: ICommentsListProps) => {
    const { saveComment, loading } = useAuditComment(auditId, isRemedial);

    const handleSubmit = useCallback(
        (comment: string) => {
            saveComment(comment).subscribe((createdComment) =>
                createComment(createdComment),
            );
        },
        [createComment, saveComment],
    );

    return (
        <>
            {comments.map((comment) => (
                <div key={comment.id} className={styles.comment}>
                    <div className={styles.metadata}>
                        {comment.author.name} -&nbsp;
                        {toDateTimeString(new Date(comment.createdAt))}
                    </div>
                    {comment.comment}
                </div>
            ))}

            {!isReadOnly && (
                <PostComment handleSubmit={handleSubmit} loading={loading} />
            )}
        </>
    );
};

export default CommentsList;
