import { ReactNode } from "react";
import PopoverListItem from "./PopoverListItem";

export interface IPopoverListItemProps {
    children: ReactNode;
    isSelected?: boolean;
    applyPadding?: boolean;
    onClick: () => void;
}

export default PopoverListItem;
