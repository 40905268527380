import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Icon from "../Icon/Icon";
import styles from "./TaskIcon.module.scss";

const TaskIcon = ({ size = 15, taskType }: ITaskIconProps) => {
    const { t } = useTranslation();
    const componentSize = useMemo(() => size + 12, [size]);

    if (taskType) {
        return (
            <div
                className={styles.container}
                style={{ width: componentSize, height: componentSize }}
            >
                <Icon
                    size={size}
                    color="grey30"
                    icon={taskType}
                    ariaLabel={t(taskType)}
                />
            </div>
        );
    }

    return null;
};

interface ITaskIconProps {
    taskType: string;
    size?: number;
}

export default TaskIcon;
