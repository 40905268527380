import { useTranslation } from "react-i18next";
import { IAllocatedJobEventLogEntityProps } from ".";
import ComplianceCategory from "../../../components/ComplianceCategory";
import { secondsToTimeSpan, toDateTimeString } from "../../../utils/dates";

const AllocatedJobEventLogEntity = ({
    eventLog,
}: IAllocatedJobEventLogEntityProps) => {
    const { t } = useTranslation();

    return (
        <tbody>
            <tr>
                <td>{t("Property")}</td>
                <td>{eventLog.property.addressString}</td>
            </tr>
            <tr>
                <td>{t("Compliance type")}</td>
                <td>
                    <ComplianceCategory
                        displayName={
                            eventLog.payload.complianceType.displayName
                        }
                        colour={eventLog.payload.complianceType.colour}
                    />
                </td>
            </tr>
            <tr>
                <td>{t("Engineer")}</td>
                <td>{eventLog.payload.engineer.name}</td>
            </tr>
            <tr>
                <td>{t("External job reference")}</td>
                <td>{eventLog.payload.externalJobReference}</td>
            </tr>
            <tr>
                <td>{t("Job type")}</td>
                <td>{eventLog.payload.jobType}</td>
            </tr>
            <tr>
                <td>{t("Estimated job duration")}</td>
                <td>
                    {secondsToTimeSpan(
                        eventLog.payload.estimatedTimeMinutes * 60 +
                            eventLog.payload.estimatedTimeHours * 3600,
                    )}
                </td>
            </tr>
            <tr>
                <td>{t("Job number")}</td>
                <td>{eventLog.payload.jobNumber}</td>
            </tr>
            <tr>
                <td>{t("Part number")}</td>
                <td>{eventLog.payload.partNumber}</td>
            </tr>
            <tr>
                <td>{t("Notes for engineer")}</td>
                <td>{eventLog.payload.description}</td>
            </tr>
            <tr>
                <td>{t("Job date")}</td>
                <td>{toDateTimeString(new Date(eventLog.payload.jobDate))}</td>
            </tr>
            <tr>
                <td>{t("Append tenant details?")}</td>
                <td>
                    {eventLog.payload.appendTenantDetails ? t("Yes") : t("No")}
                </td>
            </tr>
        </tbody>
    );
};

export default AllocatedJobEventLogEntity;
