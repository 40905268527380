import { MouseEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, GridColumn, Link } from "../../../components";
import AuthorizedImage from "../../../components/AuthorizedImage";
import ComplianceCategory from "../../../components/ComplianceCategory";
import { useCssClasses } from "../../../hooks";
import useClickableNonInteractiveElement from "../../../hooks/useClickableNonInteractiveElement";
import allocatedJobStatus from "../../../utils/allocatedJobStatus";
import {
    IAllocatedJob,
    useMatchAllocatedJob,
} from "../../../utils/api/allocatedJobs";
import {
    getDifferenceInSeconds,
    secondsToTimeSpan,
    toTimeString,
} from "../../../utils/dates";
import styles from "./AllocatedJob.module.scss";

const AllocatedJob = ({ allocatedJob, onClick }: IAllocatedJobProps) => {
    const { t } = useTranslation();
    const [isMatching, setIsMatching] = useState(false);

    useEffect(() => {
        setIsMatching(false);
    }, [allocatedJob.isComplete]);

    const duration = useMemo(
        () => {
            let seconds: number;
            if (allocatedJob.isComplete && allocatedJob.job) {
                seconds = getDifferenceInSeconds(
                    new Date(allocatedJob.job.date),
                    new Date(allocatedJob.job.endDate),
                );
            } else {
                seconds =
                    allocatedJob.estimatedTimeMinutes * 60 +
                    allocatedJob.estimatedTimeHours * 3600;
            }

            return secondsToTimeSpan(seconds);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [allocatedJob, allocatedJob.isComplete],
    );

    const link = useMemo(
        () => {
            if (allocatedJob.isComplete && allocatedJob.job) {
                return `/jobs/jobs/${allocatedJob.job.id}`;
            }

            if (allocatedJob.unableToAccess) {
                return `/issues/unabletoaccess/${allocatedJob.unableToAccessId}`;
            }

            return "";
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [allocatedJob, allocatedJob.unableToAccess, allocatedJob.isComplete],
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const status = useMemo(() => allocatedJobStatus(allocatedJob), [
        allocatedJob,
        allocatedJob.job,
        allocatedJob.unableToAccess,
    ]);

    const { unmatchAllocatedJob } = useMatchAllocatedJob();

    const unmatchJob = useCallback(() => {
        setIsMatching(true);
        unmatchAllocatedJob(allocatedJob.id).subscribe();
    }, [allocatedJob, unmatchAllocatedJob]);

    const handleUnmatchJobClick = useCallback(
        (e: MouseEvent<HTMLElement>) => {
            e.stopPropagation();
            unmatchJob();
        },
        [unmatchJob],
    );

    const handleClick = useCallback(() => onClick(allocatedJob), [
        allocatedJob,
        onClick,
    ]);

    const containerClasses = useCssClasses(
        styles.container,
        status === "Late" || status === "Missed"
            ? styles.missed
            : status === "UTA"
            ? styles.uta
            : status === "Waiting"
            ? styles.waiting
            : styles.completed,
    );

    const { onKeyDown } = useClickableNonInteractiveElement(handleClick);
    const {
        onKeyDown: onUnmatchJobKeyDown,
    } = useClickableNonInteractiveElement(unmatchJob);

    return (
        <div
            className={containerClasses}
            onClick={handleClick}
            onKeyDown={onKeyDown}
            tabIndex={0}
            role="button"
        >
            <div>{allocatedJob.property.addressString}</div>
            <AuthorizedImage
                image={allocatedJob.engineer.imageUrl}
                size={20}
                circle={true}
                display="inline-block"
            />
            <span className={styles.engineer}>
                {allocatedJob.engineer.name}
            </span>
            {allocatedJob.job && !allocatedJob.job.recoveryJob ? (
                <div className={styles.time}>
                    {toTimeString(new Date(allocatedJob.job.date))}
                </div>
            ) : (
                <div className={styles.time}>
                    {toTimeString(new Date(allocatedJob.jobDate))}
                </div>
            )}
            <Grid>
                <GridColumn size="half">
                    <div className={styles.status}>
                        {!allocatedJob.manuallyMatched &&
                        allocatedJob.isComplete &&
                        allocatedJob.job ? (
                            <>
                                {allocatedJob.job.recoveryJob ? (
                                    <>{t("Job Manually Completed")}&nbsp;</>
                                ) : (
                                    <>
                                        {t("Completed at")}&nbsp;
                                        {toTimeString(
                                            new Date(allocatedJob.job.endDate),
                                        )}
                                        <span
                                            className={`${styles.jobDuration} ${styles.completed}`}
                                        >
                                            {duration}
                                        </span>
                                    </>
                                )}
                            </>
                        ) : (
                            <>
                                {t(status)}
                                <span className={styles.jobDuration}>
                                    {duration}
                                </span>
                            </>
                        )}
                        {allocatedJob.manuallyMatched && (
                            <div>
                                <span
                                    className={styles.removeReconcile}
                                    onClick={handleUnmatchJobClick}
                                    onKeyDown={onUnmatchJobKeyDown}
                                    tabIndex={0}
                                    role="button"
                                >
                                    {isMatching
                                        ? t("Removing ...")
                                        : t("Remove Match")}
                                </span>
                            </div>
                        )}
                        {link && (
                            <div>
                                <Link url={link}>{t("View")}</Link>
                            </div>
                        )}
                    </div>
                </GridColumn>
                <GridColumn
                    size="half"
                    cssRules={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                    }}
                >
                    <ComplianceCategory
                        displayName={allocatedJob.complianceType.displayName}
                        colour={allocatedJob.complianceType.colour}
                    />
                </GridColumn>
            </Grid>
        </div>
    );
};

interface IAllocatedJobProps {
    allocatedJob: IAllocatedJob;
    onClick: (job: IAllocatedJob) => void;
}

export default AllocatedJob;
