import { useTranslation } from "react-i18next";
import { IModalProps } from ".";
import { Backdrop, Button, Icon } from "..";
import { useCssClasses } from "../../hooks";
import styles from "./Modal.module.scss";

const Modal = ({
    children,
    title,
    fullWidth = false,
    fullHeight = false,
    hide,
}: IModalProps) => {
    const { t } = useTranslation();
    const containerCssClasses = useCssClasses(
        styles.container,
        fullWidth ? styles.fullWidth : "",
        fullHeight ? styles.fullHeight : "",
    );

    return (
        <Backdrop hide={hide} alignCenter={true}>
            <div className={containerCssClasses}>
                <div className={styles.header}>
                    <div className={styles.title}>{title}</div>
                    <Button onClick={hide} displayAsLink={true}>
                        <Icon icon="close" ariaLabel={t("Close")} />
                    </Button>
                </div>

                {children}
            </div>
        </Backdrop>
    );
};

export default Modal;
