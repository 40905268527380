import { useCallback, useMemo } from "react";
import { IPropertyPaperworkCardProps } from ".";
import { PaperworkLogCard } from "../../../components";
import { useDocuments } from "../../../utils/api/documents";

const PropertyPaperworkCard = ({
    property,
    jobDocumentsRecords,
    jobDocumentsRefresh,
    jobDocumentsLoaded,
}: IPropertyPaperworkCardProps) => {
    const {
        records: propertyDocumentsRecords,
        refresh: propertyDocumentsRefresh,
        loaded,
    } = useDocuments({
        propertyId: property.id.toString(),
    });

    const handleRefreshDocuments = useCallback(() => {
        propertyDocumentsRefresh();
        jobDocumentsRefresh();
    }, [propertyDocumentsRefresh, jobDocumentsRefresh]);

    const paperwork = useMemo(
        () =>
            propertyDocumentsRecords
                .concat(jobDocumentsRecords)
                .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1)),
        [jobDocumentsRecords, propertyDocumentsRecords],
    );

    return (
        <PaperworkLogCard
            fullHeight={true}
            isLoaded={loaded && jobDocumentsLoaded}
            paperwork={paperwork}
            refreshPaperwork={handleRefreshDocuments}
            tenantEmail={
                property.tenants.length > 0 ? property.tenants[0].email : ""
            }
            propertyId={property.id}
            landlordId={property.landlord.id}
            propertyAddress={property.addressString}
            propertyLetterStateId={property.letterState?.id}
        />
    );
};

export default PropertyPaperworkCard;
