import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { IEditableListItemDeleteModalProps } from ".";
import { Alert, Button, Modal, Portal } from "../..";
import useApiRequest from "../../../utils/api/useApiRequest";
import ModalBody from "../../Modal/ModalBody";
import ModalFooter from "../../Modal/ModalFooter";

function EditableListItemDeleteModal<T>({
    id,
    title,
    message,
    hide,
    onDelete,
    deleteRequest,
}: IEditableListItemDeleteModalProps<T>) {
    const { t } = useTranslation();
    const { send, loading, error } = useApiRequest<T>({
        errorMessage: t("Unexpected error occured. Please try again later."),
    });

    const handleDeleteSubmitClick = useCallback(() => {
        send(deleteRequest(id)).subscribe((entity) => {
            onDelete(entity);
            hide();
        });
    }, [deleteRequest, hide, id, onDelete, send]);

    return (
        <Portal>
            <Modal hide={hide} title={title}>
                <ModalBody>
                    <p>{message}</p>

                    {error && <Alert type="error">{error}</Alert>}
                </ModalBody>
                <ModalFooter>
                    <Button
                        variant="error"
                        cssRules={{ marginRight: "10px" }}
                        onClick={handleDeleteSubmitClick}
                        disabled={loading}
                    >
                        {t("Yes")}
                    </Button>

                    <Button onClick={hide}>{t("No")}</Button>
                </ModalFooter>
            </Modal>
        </Portal>
    );
}

export default EditableListItemDeleteModal;
