import { useCallback, useContext } from "react";
import { ICreateJob, IJob } from ".";
import { UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const useCreateJob = () => {
    const { activeUserParentsIds } = useContext(UserContext);
    const { send, loading, error } = useApiRequest<IJob>({});
    const sendRequest = useXTagApiRequest();

    const createJob = useCallback(
        (job: ICreateJob) =>
            send(
                sendRequest({
                    url: config.jobsApiUrl,
                    method: "POST",
                    urlParams: getGlobalUrlParameters([], activeUserParentsIds),
                    body: job,
                }),
            ),
        [activeUserParentsIds, send, sendRequest],
    );

    return { createJob, loading, error };
};

export default useCreateJob;
