import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DataSelect, Grid, GridColumn } from "../../../components";
import { ILineChartWithTotal } from "../../../components/LineChartWithTotal";
import { IAverageJobTime, ITotalJob } from "../../../utils/api/misc";
import {
    generateAverageJobTimeData,
    generateTotalJobsData,
} from "../../../utils/chartData";
import styles from "./TotalJobsAverageTime.module.scss";

const TotalJobsAverageTime = ({ totalJobs, jobTimes }: ITotalJobsProps) => {
    const { t } = useTranslation();

    const [tabIndex, setTabIndex] = useState(0);
    const [jobsData, setJobsData] = useState<ILineChartWithTotal[]>([]);

    const times = useMemo(() => {
        return generateAverageJobTimeData(jobTimes);
    }, [jobTimes]);

    useEffect(() => {
        setJobsData(generateTotalJobsData(totalJobs));
    }, [totalJobs]);

    return (
        <>
            {jobsData.length > 0 && (
                <div className={styles.centerContainer}>
                    <Grid fullHeight={false}>
                        <GridColumn size="half">
                            <div className={styles.center}>
                                <span className={styles.label}>
                                    {t("Total Jobs")}
                                </span>
                                <div
                                    className={styles.count}
                                    data-test-id="TotalJobs_Value_Div"
                                >
                                    {jobsData[tabIndex].count}
                                </div>
                            </div>
                        </GridColumn>

                        <GridColumn size="half">
                            <div className={styles.dataContainer}>
                                <span className={styles.label}>
                                    {t("Average Job Time")}
                                </span>
                                <div
                                    className={styles.time}
                                    data-test-id="AverageJobTime_Value_Div"
                                >
                                    {times[tabIndex].time}
                                </div>
                            </div>
                        </GridColumn>
                    </Grid>

                    <DataSelect
                        current={tabIndex}
                        onChange={setTabIndex}
                        dataLength={jobsData.length}
                        label={t(jobsData[tabIndex].label)}
                    />
                </div>
            )}
        </>
    );
};

interface ITotalJobsProps {
    totalJobs: ITotalJob[];
    jobTimes: IAverageJobTime[];
}

export default TotalJobsAverageTime;
