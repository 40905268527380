import { IPropertyDataRowProps } from ".";
import { Grid } from "../../../components";
import styles from "./PropertyDataRow.module.scss";

const PropertyDataRow = ({ children }: IPropertyDataRowProps) => {
    return (
        <div className={styles.container}>
            <Grid>{children}</Grid>
        </div>
    );
};

export default PropertyDataRow;
