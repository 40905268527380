import { useMemo } from "react";
import { IPropertyNote } from "../../utils/api/properties";
import Notes, { INote } from "../Notes";

const EngineerNotes = ({ notes }: IEngineerNotesProps) => {
    const engineerNotes = useMemo<INote[]>(() => {
        return notes.map((note) => ({
            url: "/management/engineers/" + note.engineer.id,
            text: note.note,
            subtitle: note.engineer.name,
            date: note.date || "",
        }));
    }, [notes]);

    return <Notes notes={engineerNotes} />;
};

interface IEngineerNotesProps {
    notes: IPropertyNote[];
}

export default EngineerNotes;
