import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { addDays, addMonths, getToday } from "../../../utils/dates";
import Button from "../../Button";
import DatePicker from "../DatePicker";
import styles from "./DateRangePicker.module.scss";

const DateRangePicker = ({
    selectedFromDate = null,
    selectedToDate = null,
    onDatesSelected = () => null,
}: IDateRangePickerProps) => {
    const { t } = useTranslation();
    const [fromDate, setFromDate] = useState<Date | null>(selectedFromDate);
    useEffect(() => setFromDate(selectedFromDate), [selectedFromDate]);

    const [toDate, setToDate] = useState<Date | null>(selectedToDate);
    useEffect(() => setToDate(selectedToDate), [selectedToDate]);

    const secondOpenAtDate = useMemo(() => selectedToDate || getToday(), [
        selectedToDate,
    ]);
    const firstOpenAtDate = useMemo(
        () => selectedFromDate || addMonths(secondOpenAtDate, -1),
        [selectedFromDate, secondOpenAtDate],
    );

    const handleDateSelect = (date: Date) => {
        if (fromDate && !toDate && date >= fromDate) {
            setToDate(date);

            onDatesSelected(fromDate, date);
        } else {
            setFromDate(date);
            setToDate(null);
        }
    };

    const predefinedOptions = useMemo(() => {
        const options: IPredefinedOption[] = [
            { text: t("Last 60 Days"), days: [-60, 0] },
            { text: t("Last 30 Days"), days: [-30, 0] },
            { text: t("Last 7 Days"), days: [-7, 0] },
            { text: t("Yesterday"), days: [-1, -1] },
            { text: t("Today"), days: [0, 0] },
            { text: t("Tomorrow"), days: [1, 1] },
            { text: t("7 Days from Today"), days: [0, 7] },
            { text: t("30 Days from Today"), days: [0, 30] },
            { text: t("60 Days from Today"), days: [0, 60] },
        ];

        return options;
    }, [t]);

    const handleOptionClick = (from: number, to: number) => {
        const startDate = addDays(getToday(), from);
        const endDate = addDays(getToday(), to);

        setFromDate(startDate);
        setToDate(endDate);

        onDatesSelected(startDate, endDate);
    };

    return (
        <div className={styles.container}>
            <div className={styles.predefinedOptions}>
                {predefinedOptions.map((option) => (
                    <Button
                        key={option.text}
                        displayBlock={true}
                        onClick={handleOptionClick}
                        clickParams={option.days}
                    >
                        {option.text}
                    </Button>
                ))}
            </div>
            <DatePicker
                openAtDate={firstOpenAtDate}
                onDateSelected={handleDateSelect}
                selectedFromDate={fromDate}
                selectedToDate={toDate}
                margin={true}
            />
            <DatePicker
                openAtDate={secondOpenAtDate}
                onDateSelected={handleDateSelect}
                selectedFromDate={fromDate}
                selectedToDate={toDate}
            />
        </div>
    );
};

interface IDateRangePickerProps {
    selectedFromDate?: Date | null;
    selectedToDate?: Date | null;
    onDatesSelected?: (fromDate: Date, toDate: Date) => void;
}

interface IPredefinedOption {
    text: string;
    days: [number, number];
}

export default DateRangePicker;
