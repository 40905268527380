import { startCase } from "lodash";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card, TabGroup, TabGroupTab } from "../../components";
import {
    ObservationStatus,
    useObservationCounts,
} from "../../utils/api/observations";
import ObservationsTable from "./ObservationsTable";

const Observations = () => {
    const { t } = useTranslation();

    const observation = useObservationCounts();

    const tabs = useMemo<ObservationStatus[]>(
        () => ["Unresolved", "Resolved"],
        [],
    );

    const getHeader = useCallback(
        (status: ObservationStatus) => {
            const count = observation.value.find(
                (c) => c.statusLabel === status,
            )?.count;

            const countStr = count ? ` (${count})` : "";

            return startCase(t(status)) + countStr;
        },
        [observation.value, t],
    );

    const tabGroups = useMemo(
        () =>
            tabs.map((tab) => {
                return {
                    tab,
                    header: getHeader(tab),
                };
            }),
        [getHeader, tabs],
    );

    return (
        <Card title={t("Observations")} padding={false}>
            <TabGroup>
                {tabGroups.map((tab, index) => (
                    <TabGroupTab header={tab.header} key={index}>
                        <ObservationsTable status={tab.tab} />
                    </TabGroupTab>
                ))}
            </TabGroup>
        </Card>
    );
};

export default Observations;
