import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IJobInformationProps } from ".";
import { ColourPill, JobType, Link, SimpleTable } from "../../../components";
import { IColumn } from "../../../components/SimpleTable";
import { IComplianceType } from "../../../utils/api/jobs";

const JobInformation = (jobdata: IJobInformationProps) => {
    const { t } = useTranslation();

    const renderComplianceType = useCallback(
        (value: IComplianceType) =>
            value && (
                <ColourPill
                    customColour={value.colour}
                    value={t(value.displayName)}
                />
            ),
        [t],
    );

    const renderJobType = useCallback((value: string) => {
        return <JobType jobType={value} margin={false} />;
    }, []);

    const renderLink = useCallback((value: string) => {
        return (
            <Link url={`/jobs/jobs/${value}`} openInNewWindow={true}>
                #{value}
            </Link>
        );
    }, []);

    const renderPropertyLink = useCallback(
        (value: string, row: IJobInformationProps) => {
            return (
                <Link
                    url={`/management/properties/${row.propertyId}`}
                    openInNewWindow={true}
                >
                    {value}
                </Link>
            );
        },
        [],
    );

    const rows = useMemo<IColumn[]>(
        () => [
            {
                title: t("Job Id"),
                path: "jobId",
                type: "string",
                render: renderLink,
            },
            {
                title: t("Compliance Category"),
                path: "complianceType",
                type: "string",
                render: renderComplianceType,
            },
            {
                title: t("Job type"),
                path: "jobType",
                type: "string",
                render: renderJobType,
            },
            {
                title: t("Job date"),
                path: "jobDate",
                type: "date",
            },
            {
                title: t("Engineer"),
                path: "engineer",
                type: "string",
            },
            {
                title: t("Address"),
                path: "address",
                type: "string",
                render: renderPropertyLink,
            },
        ],
        [
            renderComplianceType,
            renderJobType,
            renderLink,
            renderPropertyLink,
            t,
        ],
    );

    return <SimpleTable columns={rows} single={true} data={{ ...jobdata }} />;
};

export default JobInformation;
