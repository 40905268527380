import { useCallback, useEffect, useMemo } from "react";
import { IStateMachine } from ".";
import config from "../../../config";
import useFilterable, { IFilters } from "../useFilterable";

const useStateMachines = ({ landlordId, name }: IUseStateMachines) => {
    const filter = useMemo<IFilters>(() => {
        return {
            ...(landlordId && {
                landlordId: {
                    operator: "{AND}",
                    readOnly: true,
                    filters: [{ function: "=", value: landlordId.toString() }],
                },
            }),
            ...(name && {
                name: {
                    operator: "{AND}",
                    readOnly: true,
                    filters: [{ function: "=", value: name }],
                },
            }),
        };
    }, [landlordId, name]);

    const filterable = useFilterable<IStateMachine>(
        `${config.propertiesApiUrl}/statemachine`,
        {
            params: {
                itemsPerPage: 100,
                filters: filter,
            },
        },
    );

    const refresh = useCallback(() => {
        filterable.refresh(filter);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    return { ...filterable, refresh };
};

interface IUseStateMachines {
    landlordId?: number;
    name?: string;
}

export default useStateMachines;
