import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IAllocatedJobsTabProps } from ".";
import { Loading, Notes } from "../../../components";
import { useAllAllocatedJobs } from "../../../utils/api/allocatedJobs";
import { getToday } from "../../../utils/dates";

const AllocatedJobsTab = ({ propertyId }: IAllocatedJobsTabProps) => {
    const { t } = useTranslation();

    const allocatedJobs = useAllAllocatedJobs({
        startDate: getToday(),
        propertyId,
    });

    const { records, loaded } = allocatedJobs;

    const value = useMemo(() => {
        return allocatedJobs?.records.map((allocatedJob) => ({
            url: "/management/engineers/" + allocatedJob.engineer.id,
            text: allocatedJob.jobType,
            subtitle: allocatedJob.engineer.name,
            date: allocatedJob.jobDate,
        }));
    }, [allocatedJobs]);

    return (
        <>
            {loaded ? (
                records.length > 0 ? (
                    <Notes notes={value} />
                ) : (
                    t("No allocated jobs found")
                )
            ) : (
                <Loading />
            )}
        </>
    );
};

export default AllocatedJobsTab;
