import { IAwaitingAllocationJob } from ".";
import config from "../../../config";
import useFilterable from "../useFilterable";

const useAwaitingAllocation = (status: string[]) => {
    const filterable = useFilterable<IAwaitingAllocationJob>(
        `${config.allocatedJobsApiUrl}/awaitingallocation`,
        {
            params: {
                filters: {
                    status: {
                        operator: "{OR}",
                        filters: status.map((s) => ({
                            function: "=",
                            value: s,
                        })),
                    },
                },
            },
        },
    );

    return filterable;
};

export default useAwaitingAllocation;
