import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { IResetUserPasswordModalProps } from ".";
import { Button, Loading, Modal, Portal } from "../../../components";
import ModalBody from "../../../components/Modal/ModalBody";
import ModalFooter from "../../../components/Modal/ModalFooter";
import { useResetUserPassword } from "../../../utils/api/users";

const ResetUserPasswordModal = ({
    userId,
    onResetUserPassword,
    hide,
}: IResetUserPasswordModalProps) => {
    const { t } = useTranslation();
    const { resetUserPassword, loading } = useResetUserPassword();

    const handleResetUserPasswordClick = useCallback(() => {
        resetUserPassword(userId).subscribe(() => {
            hide();
            onResetUserPassword();
        });
    }, [resetUserPassword, userId, hide, onResetUserPassword]);

    return (
        <Portal>
            <Modal title={t("Reset Password")} hide={hide}>
                <ModalBody>
                    <p>
                        {t(
                            "Are you sure you want to reset this users password? They will receive an email with instructions to setup a new password.",
                        )}
                    </p>

                    {loading && <Loading small={true} />}
                </ModalBody>
                <ModalFooter>
                    <Button
                        variant="primary"
                        onClick={handleResetUserPasswordClick}
                        disabled={loading}
                        cssRules={{ marginRight: "10px" }}
                    >
                        {t("Reset")}
                    </Button>
                    <Button onClick={hide}>{t("Cancel")}</Button>
                </ModalFooter>
            </Modal>
        </Portal>
    );
};

export default ResetUserPasswordModal;
