import { INotification, NotificationCategory } from ".";
import config from "../../../config";
import useFilterable from "../useFilterable";

const useNotifications = (
    category: NotificationCategory,
    read?: boolean,
    onRecordsLoaded?: (
        values: INotification[],
        currentPage: number,
        totalPages: number,
    ) => void,
) => {
    const filterable = useFilterable<INotification>(
        `${config.miscApiUrl}/notifications`,
        {
            disableFuelTypeFiltering: true,
            disableUserParentsFiltering: true,
            params: {
                sortProperty: "createdAt",
                sortDirection: "desc",
                filters: {
                    notificationCategoryId: {
                        operator: "{AND}",
                        filters: [
                            {
                                function: "=",
                                value: category.toString(),
                            },
                        ],
                    },
                    ...(read !== undefined && {
                        read: {
                            operator: "{AND}",
                            filters: [
                                { function: "=", value: read.toString() },
                            ],
                        },
                    }),
                },
            },
        },
        onRecordsLoaded,
    );
    return filterable;
};

export default useNotifications;
