import { startCase } from "lodash";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card, TabGroup, TabGroupTab } from "../../../components";
import { IJobAppliance, IJobAppliancesDiff } from "../../../utils/api/misc";
import DifferenceTable from "../DifferenceTable";
import styles from "./IntegrationJobDifferences.module.scss";

const IntegrationJobDifferences = ({ diff }: IIntegrationJobDiffProps) => {
    const { t } = useTranslation();

    const get = useCallback(
        (key: string): IJobAppliance[] => {
            if (key !== "modified") {
                return diff[key] as IJobAppliance[];
            }
            return [];
        },
        [diff],
    );

    const withData = useMemo(
        () =>
            Object.keys(diff).filter(
                (comparison) => diff[comparison].length > 0,
            ),
        [diff],
    );
    return (
        <div>
            <TabGroup headersPosition="top">
                {withData.map((comparison, index) => (
                    <TabGroupTab
                        header={`${t(startCase(comparison))} : ${
                            diff[comparison].length
                        }`}
                        key={index}
                    >
                        <div className={styles.details}>
                            {comparison === "modified"
                                ? diff.modified.map((d, tableIndex) => (
                                      <div key={tableIndex}>
                                          <Card
                                              title={
                                                  d.current && d.current.type
                                              }
                                          >
                                              <DifferenceTable
                                                  original={d.original}
                                                  current={d.current}
                                                  suppress={["id"]}
                                              />
                                          </Card>
                                      </div>
                                  ))
                                : get(comparison).map((d, tableIndex) => (
                                      <div key={tableIndex}>
                                          <Card title={d.type}>
                                              <DifferenceTable
                                                  current={d}
                                                  suppress={["id"]}
                                              />
                                          </Card>
                                      </div>
                                  ))}
                        </div>
                    </TabGroupTab>
                ))}
            </TabGroup>
        </div>
    );
};

interface IIntegrationJobDiffProps {
    diff: IJobAppliancesDiff;
}

export default IntegrationJobDifferences;
