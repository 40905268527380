import { Outlet } from "react-router-dom";
import { ISideMenuContainerProps } from ".";
import SideMenu from "../SideMenu/SideMenu";
import styles from "./SideMenuContainer.module.scss";

const SideMenuContainer = ({ appRoutes }: ISideMenuContainerProps) => {
    return (
        <div className={styles.container}>
            <div className={styles.menuContainer}>
                <SideMenu appRoutes={appRoutes} />
            </div>
            <div className={styles.pageContainer}>
                <Outlet />
            </div>
        </div>
    );
};

export default SideMenuContainer;
