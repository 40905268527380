import { keys, pickBy, some } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import {
    Button,
    Card,
    Checkbox,
    Form,
    InputField,
    Loading,
    ValidationError,
} from "../../components";
import { useToggle } from "../../hooks";
import { useSearchEngineer } from "../../utils/api/engineers";
import {
    useAddEngineer,
    useLandlordPreferences,
} from "../../utils/api/landlords";
import {
    isEmail,
    isInteger,
    isRequired,
    maxLength,
    useValidateField,
    validateForm,
} from "../../utils/validation";
import isTrue from "../../utils/validation/isTrue";
import styles from "./EngineerAdd.module.scss";

const EngineerAdd = () => {
    const { t } = useTranslation();
    const landlordPreferences = useLandlordPreferences();

    const [businessRegistration, setBusinessRegistration] = useState("");
    const [licenceCard, setLicenceCard] = useState("");
    const [engineerEmail, setEngineerEmail] = useState("");

    const { visible, show, hide } = useToggle();

    const searchEngineer = useSearchEngineer();
    const addEngineer = useAddEngineer();

    const changedFormEvent = () => {
        setBusinessRegistration("");
        setLicenceCard("");
        setEngineerEmail("");

        searchEngineer.reset();
        if (visible) {
            hide();
        } else {
            show();
        }
    };

    const businessRegistrationValidator = useValidateField(
        businessRegistration,
        isRequired(),
        isInteger(),
    );
    const licenceCardValidator = useValidateField(
        licenceCard,
        isRequired(),
        maxLength(20),
    );
    const engineerEmailValidator = useValidateField(
        engineerEmail,
        isRequired(),
        isEmail(),
    );

    const activeValidators = visible
        ? [businessRegistrationValidator, licenceCardValidator]
        : [engineerEmailValidator];

    const searchFormValidator = validateForm(() => activeValidators);

    const handleSearch = () => {
        searchEngineer
            .searchEngineer({
                licenceNumberV2: licenceCard,
                businessReg: businessRegistration,
                engineerEmail: engineerEmail,
            })
            .subscribe();
    };

    const [landlords, setLandlords] = useState<{ [key: string]: boolean }>({});
    const landlordsValidator = useValidateField(
        some(landlords),
        isTrue(t("Please select at least one landlord.")),
    );
    const addFormValidator = validateForm(() => [landlordsValidator]);

    const handleLandlordClick = (checked: boolean, value: string) => {
        const tempLandlords = { ...landlords };

        tempLandlords[value] = checked;
        setLandlords(tempLandlords);
    };

    const navigate = useNavigate();
    const handleAddEngineer = () => {
        addEngineer
            .addEngineer({
                engineerId: searchEngineer.value.id,
                landlordIds: keys(
                    pickBy(landlords, (landlord) => landlord),
                ).map((landlord) => Number(landlord)),
            })
            .subscribe(() => navigate("/management/engineers"));
    };

    return (
        <>
            <Card title={t("Assign an engineer")}>
                <Form onSubmit={handleSearch} {...searchFormValidator}>
                    {visible ? (
                        <>
                            <InputField
                                label={t("Gas business registration number")}
                                value={businessRegistration}
                                onChange={setBusinessRegistration}
                                {...businessRegistrationValidator}
                            />

                            <InputField
                                label={t("Gas licence card number")}
                                value={licenceCard}
                                onChange={setLicenceCard}
                                {...licenceCardValidator}
                            />

                            <Button onClick={changedFormEvent}>
                                {t("Return to email")}
                            </Button>
                        </>
                    ) : (
                        <>
                            <InputField
                                label={t("Add email")}
                                value={engineerEmail}
                                onChange={setEngineerEmail}
                                {...engineerEmailValidator}
                            />

                            <Button onClick={changedFormEvent}>
                                {t("I don't have an email")}
                            </Button>
                        </>
                    )}

                    <div className={styles.searchContainer}>
                        <Button
                            type="submit"
                            variant="primary"
                            disabled={searchEngineer.loading}
                        >
                            {t("Search")}
                        </Button>
                    </div>

                    <ValidationError
                        error={searchEngineer.error}
                        isValid={
                            !searchFormValidator.isValid ||
                            !searchEngineer.error
                        }
                    />
                </Form>

                {searchEngineer.loading && <Loading />}

                {searchEngineer.loaded && landlordPreferences.loaded && (
                    <Form onSubmit={handleAddEngineer} {...addFormValidator}>
                        <Card title={searchEngineer.value.name}>
                            <p>{t("Select a Landlord")}</p>
                            {landlordPreferences.value.map((landlord) => (
                                <Checkbox
                                    key={landlord.id}
                                    checked={landlords[landlord.id.toString()]}
                                    value={landlord.id.toString()}
                                    onChange={handleLandlordClick}
                                >
                                    {landlord.name}
                                </Checkbox>
                            ))}
                            {!landlordsValidator.isValid && (
                                <ValidationError {...landlordsValidator} />
                            )}
                            <Button
                                type="submit"
                                variant="primary"
                                disabled={addEngineer.loading}
                            >
                                {t("Confirm Engineer")}
                            </Button>
                            <ValidationError
                                error={addEngineer.error}
                                isValid={!addEngineer.error}
                            />
                        </Card>
                    </Form>
                )}
            </Card>
        </>
    );
};

export default EngineerAdd;
