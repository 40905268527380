import { useCallback, useContext } from "react";
import { ILandlordPropertyCategory } from ".";
import { UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const useUpdateLandlordPropertyCategories = () => {
    const { send, loading } = useApiRequest({});
    const { activeUserParentsIds } = useContext(UserContext);
    const sendRequest = useXTagApiRequest();

    const update = useCallback(
        (
            landlordId: number,
            landlordPropertyCategories: ILandlordPropertyCategory[],
        ) =>
            send(
                sendRequest({
                    url: `${config.landlordsApiUrl}/propertycategories/setpropertycategories/${landlordId}`,
                    method: "POST",
                    urlParams: getGlobalUrlParameters([], activeUserParentsIds),
                    body: landlordPropertyCategories,
                }),
            ),
        [send, sendRequest, activeUserParentsIds],
    );

    return { update, loading };
};

export default useUpdateLandlordPropertyCategories;
