import { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IOverrideInspectionScheduleAnswerProps } from ".";
import { InputField } from "../../../components";
import Select, { ISelectOption } from "../../../components/Select";
import { IInspectionSchedulePayload } from "../../../utils/api/answers";
import { ObservationTypesContext } from "../ObservationTypes";
import styles from "./OverrideInspectionScheduleAnswer.module.scss";

const OverrideInspectionScheduleAnswer = ({
    answer,
    value,
    options,
    showObservationOption,
    overrideAnswer,
}: IOverrideInspectionScheduleAnswerProps) => {
    const { t } = useTranslation();

    const payload = useMemo(() => {
        let result: IInspectionSchedulePayload | undefined;

        if (typeof value === "string") {
            result = JSON.parse(value) as IInspectionSchedulePayload;
        } else {
            result = value;
        }

        if (result && !showObservationOption && result.Observation) {
            result = {
                Text: "",
            };
        }

        return result;
    }, [showObservationOption, value]);

    const filteredOptions = useMemo(() => {
        let list = options;

        if (!showObservationOption) {
            list = list.filter((o) => o.value !== "OBS");
        }

        return list;
    }, [options, showObservationOption]);

    const handleCommentNotesChange = useCallback(
        (newValue: string) => {
            if (payload?.Comment) {
                const override: IInspectionSchedulePayload = {
                    Text: payload.Text,
                    Comment: {
                        ...payload.Comment,
                        Notes: newValue,
                    },
                };

                overrideAnswer(answer.id, override);
            }
        },
        [answer.id, payload, overrideAnswer],
    );

    const handleObservationDescriptionChange = useCallback(
        (newValue: string) => {
            if (payload?.Observation) {
                const override: IInspectionSchedulePayload = {
                    Text: payload.Text,
                    Observation: {
                        ...payload.Observation,
                        Description: newValue,
                    },
                };

                overrideAnswer(answer.id, override);
            }
        },
        [answer.id, payload, overrideAnswer],
    );

    const handleObservationCodeChange = useCallback(
        (newValue: string) => {
            if (payload?.Observation) {
                const override: IInspectionSchedulePayload = {
                    Text: payload.Text,
                    Observation: {
                        ...payload.Observation,
                        Code: newValue,
                    },
                };

                overrideAnswer(answer.id, override);
            }
        },
        [answer.id, payload, overrideAnswer],
    );

    const handleChange = useCallback(
        (newValue: string) => {
            if (newValue) {
                const override: IInspectionSchedulePayload = {
                    Text: newValue,
                };

                if (newValue === "OBS") {
                    override.Observation = {
                        Code: "",
                        Description: "",
                        ObservationId: "",
                    };
                } else if (newValue === "Comment") {
                    override.Comment = {
                        Notes: "",
                        CommentId: "",
                    };
                }

                overrideAnswer(answer.id, override);
            } else {
                overrideAnswer(answer.id, undefined);
            }
        },
        [answer, overrideAnswer],
    );

    const { observationTypes } = useContext(ObservationTypesContext);

    const observationCodes = useMemo(() => {
        const list: ISelectOption[] = [];

        for (const observationType of observationTypes) {
            list.push({
                label: observationType.displayName,
                value: observationType.name,
            });
        }

        return list;
    }, [observationTypes]);

    return (
        <>
            {filteredOptions.length > 0 && (
                <Select
                    options={filteredOptions}
                    value={payload?.Text ?? ""}
                    onChange={handleChange}
                    allowEmpty={true}
                    canDeselect={true}
                    useMargin={false}
                />
            )}

            {showObservationOption && payload?.Observation && (
                <>
                    <div className={styles.fieldContainer}>
                        <Select
                            label={t("Code")}
                            options={observationCodes}
                            value={payload.Observation.Code}
                            onChange={handleObservationCodeChange}
                            allowEmpty={true}
                            canDeselect={true}
                            useMargin={false}
                        />
                    </div>

                    <div className={styles.fieldContainer}>
                        <InputField
                            label={t("Description")}
                            value={payload.Observation.Description}
                            onChange={handleObservationDescriptionChange}
                            useMargin={false}
                        />
                    </div>
                </>
            )}

            {payload?.Comment && (
                <div className={styles.fieldContainer}>
                    <InputField
                        label={t("Notes")}
                        value={payload.Comment.Notes}
                        onChange={handleCommentNotesChange}
                        useMargin={false}
                    />
                </div>
            )}
        </>
    );
};

export default OverrideInspectionScheduleAnswer;
