import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, Form, InputField, TabGroup, TabGroupTab } from "..";
import {
    useMarkAsSentByPost,
    useSendDocumentsToEmail,
} from "../../utils/api/lgsrs";
import {
    isEmail,
    isRequired,
    useValidateField,
    validateForm,
} from "../../utils/validation";
import styles from "./PaperworkSender.module.scss";

const PaperworkSender = ({
    paperworkId,
    hasBeenSent,
    emailAddress,
    sentHandler,
}: IPaperworkSenderProps) => {
    const { t } = useTranslation();
    const [sendEmailAddress, setSendEmailAddress] = useState(emailAddress);
    const sendEmailAddressValidator = useValidateField(
        sendEmailAddress,
        isRequired(),
        isEmail(),
    );

    const [disableButton, setDisableButton] = useState<boolean>(false);

    const markAsSentByPost = useMarkAsSentByPost();
    const { sendEmail } = useSendDocumentsToEmail();

    const formValidation = validateForm(() => [sendEmailAddressValidator]);

    const handleEmailDocument = useCallback(() => {
        if (paperworkId !== null) {
            setDisableButton(true);
            sendEmail([paperworkId], sendEmailAddress).subscribe({
                next: () => sentHandler(true, "email", sendEmailAddress),
                error: () => sentHandler(false, "email", sendEmailAddress),
            });
        }
    }, [paperworkId, sendEmail, sendEmailAddress, sentHandler]);

    const handleMarkAsSent = useCallback(() => {
        if (paperworkId !== null) {
            setDisableButton(true);
            markAsSentByPost.markAsSentByPost([paperworkId]).subscribe({
                next: () => sentHandler(true, "post"),
                error: () => sentHandler(false, "post"),
            });
        }
    }, [markAsSentByPost, paperworkId, sentHandler]);

    return (
        <Card withMargin={false}>
            <TabGroup headersPosition="top">
                <TabGroupTab
                    header={
                        hasBeenSent
                            ? t("Send again by email")
                            : t("Send by email")
                    }
                >
                    <Form onSubmit={handleEmailDocument} {...formValidation}>
                        <div className={styles.message}>
                            {t(
                                "What email address would you like to send the documents to?",
                            )}
                            <InputField
                                placeholder={t("Email")}
                                type="text"
                                value={sendEmailAddress}
                                onChange={setSendEmailAddress}
                                {...sendEmailAddressValidator}
                            />
                        </div>

                        <div className={styles.footer}>
                            <Button
                                type="submit"
                                variant="primary"
                                disabled={disableButton}
                            >
                                {t("Send")}
                            </Button>
                        </div>
                    </Form>
                </TabGroupTab>

                <TabGroupTab header={t("Mark as sent by post")}>
                    <Form onSubmit={handleMarkAsSent}>
                        <div className={styles.message}>
                            {t(
                                "If you have sent the document by post you can mark it as dispatched for your records.",
                            )}
                        </div>

                        <div className={styles.footer}>
                            <Button
                                type="submit"
                                variant="primary"
                                disabled={disableButton}
                            >
                                {t("Mark")}
                            </Button>
                        </div>
                    </Form>
                </TabGroupTab>
            </TabGroup>
        </Card>
    );
};

interface IPaperworkSenderProps {
    paperworkId: number | null;
    hasBeenSent: boolean;
    emailAddress: string;
    sentHandler: (
        success: boolean,
        type: "email" | "post",
        to?: string,
    ) => void;
}

export default PaperworkSender;
