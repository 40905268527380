import { IRecentIssue } from ".";
import config from "../../../config";
import useFilterable from "../useFilterable";

const useRecentIssues = () => {
    const filterable = useFilterable<IRecentIssue>(
        `${config.issuesApiUrl}/recent`,
    );

    return filterable;
};

export default useRecentIssues;
