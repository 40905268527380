import { useCallback, useEffect, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal, Portal } from "../../../components";
import Accordion, { IAccordionItem } from "../../../components/Accordion";
import ModalBody from "../../../components/Modal/ModalBody";
import {
    IAuditableDocument,
    IDocumentAuditResult,
    IJobAudit,
} from "../../../utils/api/lgsrs";
import useSubmitAudit from "../../../utils/api/lgsrs/useSubmitAudit";
import { useFileUrl } from "../../../utils/api/misc";
import Appliances from "../Appliances";
import ComplianceType from "../ComplianceTypes";
import DocumentAudit from "../DocumentAudit";
import DocumentAuditHeader from "../DocumentAuditHeader";
import JobInformation from "../JobInformation";
import JobIssues from "../JobIssues";
import OtherDocuments from "../OtherDocuments";
import styles from "./LgsrAuditApprover.module.scss";

const LgsrAuditApprover = ({
    goToNextLgsr,
    hide,
    auditData,
    readOnly,
    onHoldHandler,
}: ILgsrAuditApproverProps) => {
    const { t } = useTranslation();

    const [fileUrl, setFileUrl] = useState("");
    const [currentDocument, setCurrentDocument] = useState(-1);
    const [warnings, setWarnings] = useState<string[]>([]);

    const [showingWarnings, setShowingWarnings] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [isPdf, setIsPdf] = useState(true);

    const getFileUrl = useFileUrl();
    const fetchAndSetFileUrl = useCallback(
        (key: string) => {
            if (typeof key === "string") {
                setIsPdf(key.toLowerCase().endsWith(".pdf"));

                setFileUrl("");

                getFileUrl(key).subscribe((url) => {
                    setFileUrl(url);
                });
            }
        },
        [getFileUrl],
    );

    const activePointerHandler = useCallback(
        (identifier: string) => {
            return identifier === "d_" + currentDocument;
        },
        [currentDocument],
    );

    const updateOutstandingActions = useCallback(() => {
        const warningMessages = auditData.auditableDocuments
            .filter(
                (document) =>
                    !(
                        document.auditResult?.status === "pass" ||
                        (document.auditResult?.status === "fail" &&
                            document.auditResult?.failReasons &&
                            document.auditResult?.failReasons?.length > 0)
                    ),
            )
            .map((document) => {
                if (!document.auditResult) {
                    return document.documentName + t(" - hasn't been audited");
                } else {
                    return (
                        document.documentName +
                        t(" - must have at least one failure reason")
                    );
                }
            });

        setShowingWarnings(true);
        setWarnings(warningMessages);
    }, [auditData.auditableDocuments, t]);

    const updateAuditStatus = useCallback(
        (documentId: number, result: IDocumentAuditResult) => {
            const doc = auditData.auditableDocuments.find(
                (d) => d.id === documentId,
            );

            if (doc) {
                doc.auditResult = result;
            }

            dispatch({ id: "d_" + documentId, expanded: true });

            setShowingWarnings((value) => {
                (value || showingWarnings) && updateOutstandingActions();

                return value;
            });
        },

        [
            auditData.auditableDocuments,
            showingWarnings,
            updateOutstandingActions,
        ],
    );

    const documentHeader = useCallback(
        (document: IAuditableDocument, expanded: boolean, index?: number) => {
            const status = document.auditResult?.status;

            return (
                <DocumentAuditHeader
                    index={index ?? -1}
                    document={{ ...document }}
                    status={status}
                    expanded={expanded}
                />
            );
        },
        [],
    );

    const expandedHandler = useCallback(
        (identifier: string, expanded: boolean) => {
            dispatch({
                id: identifier,
                expanded: expanded,
            });

            const doc = auditData?.auditableDocuments.find(
                (d) => "d_" + d.id === identifier,
            );

            if (doc) {
                setCurrentDocument(doc.id);

                fetchAndSetFileUrl(doc.documentUrl);
            }
        },
        [auditData, fetchAndSetFileUrl],
    );

    const viewOtherDocumentHandler = useCallback(
        (identifier: string) => {
            fetchAndSetFileUrl(identifier);
        },
        [fetchAndSetFileUrl],
    );

    const init = useCallback(
        (accordionContent?: IJobAudit) => {
            if (!accordionContent) {
                return {};
            }

            const items: { [key: string]: IAccordionItem } = {};

            accordionContent.auditableDocuments.length > 1 &&
                fetchAndSetFileUrl(
                    accordionContent.auditableDocuments[0].documentUrl,
                );

            accordionContent.auditableDocuments.forEach((doc, index) => {
                items["d_" + doc.id.toString()] = {
                    header: documentHeader(doc, false, index),
                    content: (
                        <DocumentAudit
                            document={doc}
                            updateHandler={updateAuditStatus}
                            complianceTypeId={
                                // default to gas if no compliance is found
                                auditData.complianceType
                                    ? auditData.complianceType.id
                                    : 1
                            }
                            readonly={readOnly}
                        />
                    ),
                    expanded: false,
                    exclusiveExpandedKey: "docs",
                    activePointerHandler: activePointerHandler,
                    expandedHandler: expandedHandler,
                    data: doc,
                    index,
                };
            });

            return {
                jobInformation: {
                    header: t("Job information"),
                    content: (
                        <JobInformation
                            jobId={accordionContent.jobId}
                            jobType={accordionContent.jobType}
                            engineer={accordionContent.engineer}
                            propertyId={accordionContent.property.id}
                            address={accordionContent.address}
                            complianceType={accordionContent.complianceType}
                            jobDate={new Date(accordionContent.jobDate)}
                        />
                    ),
                    expanded: false,
                },
                complianceTypes: {
                    header:
                        t("Compliance types") +
                        ` (${accordionContent.property.complianceTypes.length})`,
                    content: (
                        <ComplianceType
                            complianceTypes={
                                accordionContent.property.complianceTypes
                            }
                        />
                    ),
                    expanded: false,
                },
                ...(accordionContent.issues &&
                    accordionContent.issues.length > 0 && {
                        issues: {
                            header:
                                t("Issues") +
                                ` (${accordionContent.issues.length})`,
                            content: (
                                <JobIssues issues={accordionContent.issues} />
                            ),
                            expanded: false,
                        },
                    }),
                ...(accordionContent.appliances &&
                    accordionContent.appliances.length > 0 && {
                        appliances: {
                            header:
                                t("Appliances") +
                                ` (${accordionContent.appliances.length})`,
                            content: (
                                <Appliances
                                    appliances={accordionContent.appliances}
                                />
                            ),
                            expanded: false,
                        },
                    }),

                ...items,

                ...(accordionContent.otherDocuments &&
                    accordionContent.otherDocuments.length > 0 && {
                        otherDocuments: {
                            header:
                                t("Other documents") +
                                ` (${accordionContent.otherDocuments.length})`,
                            expanded: false,
                            exclusiveExpandedKey: "docs",
                            content: (
                                <OtherDocuments
                                    documents={accordionContent.otherDocuments}
                                    showOtherDocument={viewOtherDocumentHandler}
                                />
                            ),
                        },
                    }),
            };
        },
        [
            activePointerHandler,
            auditData.complianceType,
            documentHeader,
            expandedHandler,
            fetchAndSetFileUrl,
            readOnly,
            t,
            updateAuditStatus,
            viewOtherDocumentHandler,
        ],
    );

    const reducer = (
        state: { [key: string]: IAccordionItem },
        action: { id: string; expanded: boolean },
    ) => {
        const currentState = state[action.id];

        if (currentState && currentState.data) {
            currentState.header = documentHeader(
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                currentState.data,
                action.expanded,
                currentState.index,
            );
        }

        return { ...state };
    };

    const [accordionState, dispatch] = useReducer(reducer, auditData, init);

    useEffect(() => {
        const firstDoc =
            auditData.auditableDocuments.length > 0
                ? auditData.auditableDocuments[0].documentUrl
                : auditData.otherDocuments?.length > 0
                ? auditData.otherDocuments[0].documentUrl
                : "";

        fetchAndSetFileUrl(firstDoc);
    }, [auditData, fetchAndSetFileUrl]);

    const { submitAudit } = useSubmitAudit();

    const saveAuditData = useCallback(
        (auditDataToSave: IJobAudit) => {
            setSubmitDisabled(true);

            submitAudit(auditDataToSave).subscribe(
                () => {
                    setSubmitDisabled(false);
                    goToNextLgsr();
                },
                () => {
                    setSubmitDisabled(false);
                },
            );
        },
        [goToNextLgsr, submitAudit],
    );

    const holdHandler = useCallback(() => {
        saveAuditData(auditData);
        onHoldHandler();
    }, [auditData, onHoldHandler, saveAuditData]);

    const submitHandler = useCallback(() => {
        setShowingWarnings(true);
        updateOutstandingActions();

        setWarnings((w) => {
            if (w.length === 0) {
                const anyFailed = auditData.auditableDocuments.some(
                    (d) => d.auditResult?.status === "fail",
                );

                auditData.auditStatus = anyFailed ? "Rejected" : "Approved";

                saveAuditData(auditData);
            }
            return w;
        });
    }, [auditData, saveAuditData, updateOutstandingActions]);

    return (
        <Portal>
            <Modal
                title={t("Auditing Job ") + auditData.jobId}
                hide={hide}
                fullWidth={true}
                fullHeight={true}
            >
                <ModalBody withPadding={false}>
                    <div className={styles.container}>
                        <div className={styles.navigation}>
                            <div className={styles.navigationComponents}>
                                <div className={styles.accordionContainer}>
                                    <Accordion content={accordionState} />
                                </div>
                                <div className={styles.auditSummary}>
                                    {warnings.length > 0 && (
                                        <div
                                            className={`${styles.outstandingActions} ${styles.warningAlert}`}
                                        >
                                            {t("Outstanding actions")}:
                                            <ul className={styles.warningList}>
                                                {warnings.map(
                                                    (warning, index) => (
                                                        <li
                                                            key={index}
                                                            className={
                                                                styles.actionItem
                                                            }
                                                        >
                                                            {warning}
                                                        </li>
                                                    ),
                                                )}
                                            </ul>
                                        </div>
                                    )}
                                    <div className={styles.auditCommands}>
                                        {!readOnly ? (
                                            <>
                                                <Button
                                                    onClick={holdHandler}
                                                    disabled={submitDisabled}
                                                >
                                                    {t("Hold")}
                                                </Button>
                                                <Button
                                                    onClick={submitHandler}
                                                    variant="primary"
                                                    disabled={submitDisabled}
                                                >
                                                    {t("Submit")}
                                                </Button>
                                            </>
                                        ) : (
                                            <>
                                                <Button onClick={holdHandler}>
                                                    {t("Save")}
                                                </Button>
                                                <Button onClick={hide}>
                                                    {t("Close")}
                                                </Button>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.documentViewer}>
                            {fileUrl &&
                                (isPdf ? (
                                    <object
                                        data={fileUrl}
                                        className={styles.pdfViewer}
                                    >
                                        {t("viewer")}
                                    </object>
                                ) : (
                                    <div>
                                        <object
                                            data={fileUrl}
                                            className={styles.otherViewer}
                                        >
                                            {t("viewer")}
                                        </object>
                                    </div>
                                ))}
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </Portal>
    );
};

interface ILgsrAuditApproverProps {
    auditData: IJobAudit;
    hide: () => void;
    goToNextLgsr: () => void;
    onHoldHandler: () => void;
    readOnly: boolean;
}

export default LgsrAuditApprover;
