import { IAnswer, IInspectionScheduleAnswer } from "../../../utils/api/answers";
import {
    IAnswerOverride,
    IAudit,
    IAuditComment,
    IAuditJobComment,
    IAuditObservation,
} from "../../../utils/api/audits";
import { IQualifiedSupervisorAuditAnswer } from "../QualifiedSupervisorAuditsGrouping";
import QualifiedSupervisorAudit from "./QualifiedSupervisorAudit";

export interface IAnswerSectionGroup<T> {
    id: string;
    title: string;
    displayChildrenOnMenu?: boolean;
    displayAsGrid?: boolean;
    children: T[];
}

export interface IAnswerSectionRootGroup<T> extends IAnswerSectionGroup<T> {
    hiddenAnswer?: IInspectionScheduleAnswer;
}

export type IAnswerSection = IAnswerSectionRootGroup<
    IAnswerSectionGroup<IAnswerSectionGroup<IQualifiedSupervisorAuditAnswer>>
>;

export interface IQualifiedSupervisorAuditProps {
    audit: IAudit;
    answersSections: IAnswerSection[];
    isReadOnly: boolean;
    overriddenAnswers: IAnswerOverride[];
    updateOverriddenAnswers: (
        overrideAnswers: IAnswerOverride[],
        deleteOverriddenAnswers: number[],
    ) => void;
    addAdditionalAnswers: (answers: IAnswer[]) => void;
    createComment: (comment: IAuditComment) => void;
    onSaveAudit: () => void;
    onHoldAudit: () => void;
    isSaveDisabled?: boolean;
    canOverrideAnswers?: boolean;
    addAuditJobComment: (jobComment: IAuditJobComment) => void;
    removeAuditJobComment: (jobCommentId: string) => void;
    addAuditObservation: (auditObservation: IAuditObservation) => void;
    removeAuditObservation: (auditObservationId: string) => void;
    sidebarVisible: boolean;
}

export default QualifiedSupervisorAudit;
