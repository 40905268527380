import { IValidationResult } from ".";

const isNotForbidden = (
    forbiddenValues: string[],
    error = "Please select a valid value.",
) => {
    const validate = (value: string): IValidationResult => {
        const isValid = !forbiddenValues.includes(value);

        return {
            isValid,
            error: isValid ? "" : error,
        };
    };

    return { validate };
};

export default isNotForbidden;
