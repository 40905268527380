import { useTranslation } from "react-i18next";
import { ITableFooterProps } from ".";
import { Button } from "../..";
import Pagination from "../Pagination";
import styles from "./TableFooter.module.scss";

const TableFooter = (props: ITableFooterProps) => {
    const { t } = useTranslation();
    const itemsPerPageOptions = [10, 25, 50, 100];

    return (
        <div className={styles.container}>
            <div>
                {t("Page")}
                <Pagination {...props} />
            </div>
            <div>
                <div>{t("Records per page")}</div>
                {itemsPerPageOptions.map((i) => (
                    <Button
                        variant={props.itemsPerPage === i ? "primary" : ""}
                        key={i}
                        clickParams={[i]}
                        onClick={props.updateItemsPerPage}
                    >
                        {i}
                    </Button>
                ))}
            </div>
        </div>
    );
};

export default TableFooter;
