import { IIntegrationJobApproval, IntegrationApprovalStatuses } from ".";
import config from "../../../config";
import useFilterable from "../useFilterable";

const useIntegrationApproval = (status: IntegrationApprovalStatuses) => {
    const filterable = useFilterable<IIntegrationJobApproval>(
        `${config.miscApiUrl}/integration/approvals`,
        {
            params: {
                sortProperty: "id",
                sortDirection: "asc",
                filters: {
                    approvalStatus: {
                        operator: "{AND}",
                        readOnly: true,
                        filters: [
                            {
                                function: "=",
                                value: status,
                            },
                        ],
                    },
                },
            },
        },
    );

    return filterable;
};

export default useIntegrationApproval;
