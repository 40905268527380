import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import {
    Alert,
    Button,
    Card,
    Form,
    UserContext,
    ValidationError,
} from "../../../components";
import { useToggle } from "../../../hooks";
import { useAuthorizedImage } from "../../../hooks/useAuthorizedImage";
import useUploadUserSignature from "../../../hooks/useUploadUserSignature";
import styles from "./UserSignature.module.scss";

const UserSignature = () => {
    const { t } = useTranslation();

    const { user } = useContext(UserContext);

    const {
        show: enableEditMode,
        hide: disableEditMode,
        visible: isEditMode,
    } = useToggle();

    const signatureUrl = useAuthorizedImage(user.signatureUrl);

    const {
        uploadSignature,
        clearCanvas,
        canvasRef,
        hasUploadError,
        isSignatureNotEmpty,
        loading,
    } = useUploadUserSignature(disableEditMode);

    const handleEnableEditModeClick = useCallback(() => {
        clearCanvas();
        enableEditMode();
    }, [clearCanvas, enableEditMode]);

    return (
        <Card title={t("Signature")}>
            {isEditMode ? (
                <Form onSubmit={uploadSignature}>
                    <div className={styles.container}>
                        <canvas className={styles.canvas} ref={canvasRef} />

                        <ValidationError
                            isValid={isSignatureNotEmpty}
                            error={t("Please draw your signature.")}
                        />
                    </div>

                    {hasUploadError && (
                        <div className={styles.container}>
                            <Alert type="error">
                                {t("An error occured. Please try again.")}
                            </Alert>
                        </div>
                    )}

                    <div>
                        <Button
                            type="submit"
                            variant="primary"
                            disabled={loading}
                        >
                            {t("Save")}
                        </Button>
                        &nbsp;
                        <Button onClick={disableEditMode}>{t("Cancel")}</Button>
                    </div>
                </Form>
            ) : (
                <>
                    {signatureUrl && (
                        <div className={styles.container}>
                            <img
                                src={signatureUrl}
                                alt={t("User signature")}
                                className={styles.image}
                            />
                        </div>
                    )}

                    <Button onClick={handleEnableEditModeClick}>
                        {t("Edit")}
                    </Button>
                </>
            )}
        </Card>
    );
};

export default UserSignature;
