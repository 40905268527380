import { useCallback, useContext } from "react";
import { ICreateLimitationSet, ILimitationSet } from ".";
import { UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const useUpdateLandlordContractorLimitationSet = () => {
    const { send, error, loading } = useApiRequest<ILimitationSet>({});
    const { activeUserParentsIds } = useContext(UserContext);
    const sendRequest = useXTagApiRequest();

    const update = useCallback(
        (limitationSet: ICreateLimitationSet) =>
            send(
                sendRequest({
                    url: `${config.landlordsApiUrl}/limitationsets`,
                    method: "POST",
                    urlParams: getGlobalUrlParameters([], activeUserParentsIds),
                    body: limitationSet,
                }),
            ),
        [send, sendRequest, activeUserParentsIds],
    );

    return { error, loading, update };
};

export default useUpdateLandlordContractorLimitationSet;
