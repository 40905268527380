import { useEffect } from "react";
import { IPaperworkType } from ".";
import config from "../../../config";
import { useXTagApiRequest } from "../../request";
import useApiValue from "../useApiValue";

export const useFilteredPaperworkTypes = (complianceTypeId: string) => {
    const { value, send } = useApiValue<IPaperworkType[]>({
        initialValue: [],
    });
    const sendRequest = useXTagApiRequest();

    useEffect(() => {
        if (complianceTypeId !== "") {
            const subscription = send(
                sendRequest({
                    url: `${config.miscApiUrl}/lookups/paperworktypes/compliance`,
                    method: "GET",
                    urlParams: { complianceTypeId: [complianceTypeId] },
                }),
            ).subscribe();

            return () => {
                if (subscription) {
                    subscription.unsubscribe();
                }
            };
        }
    }, [complianceTypeId, send, sendRequest]);

    return { value };
};

export default useFilteredPaperworkTypes;
