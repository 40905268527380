import { result } from "lodash";
import { Fragment } from "react";
import { ISimpleTableData, ISimpleTableProps } from ".";
import {
    toDateString,
    toDateTimeString,
    toTimeString,
} from "../../utils/dates";
import styles from "./SimpleTable.module.scss";
import Timeline from "./Timeline";

/**
 *
 * @deprecated Do not use this component. You get extra points if you create a replacement component that's actually simple.
 */
const SimpleTable = ({
    withTimeline = false,
    data,
    columns,
    single = false,
    withHeaders = false,
}: ISimpleTableProps) => {
    // This component is designed to handle both row based data for collections of models
    // or a single model.

    const parseTableData = ({
        currentValue,
        currentRow,
        type = "string",
        // TODO: Parameter type is not string.
        render = (value: string) => {
            switch (type) {
                case "string":
                case "number":
                case "component":
                    return value;
                case "date":
                    return value && toDateString(new Date(value));
                case "datetime":
                    return value && toDateTimeString(new Date(value));
                case "time":
                    return value && toTimeString(new Date(value));
            }
        },
    }: ISimpleTableData) => {
        return render(currentValue, currentRow);
    };

    return (
        <table className={styles.table}>
            <tbody>
                {withHeaders && data.length > 0 && (
                    <tr className={styles.row}>
                        {columns.map((col, index) => {
                            return (
                                <td
                                    className={styles.bold}
                                    title={col.title}
                                    key={index}
                                >
                                    {col.title}
                                </td>
                            );
                        })}
                    </tr>
                )}
                {!single
                    ? data.map((row: any, rowIndex: number) => (
                          <tr className={styles.row} key={rowIndex}>
                              {withTimeline && (
                                  <td>
                                      <Timeline
                                          index={rowIndex}
                                          length={data.length}
                                          cssRules={{ height: 40 }}
                                      />
                                  </td>
                              )}
                              {columns.map((column, index) => {
                                  // TODO: Remove any.
                                  const value: any = result(row, column.path);

                                  return (
                                      <td key={index}>
                                          {parseTableData({
                                              currentValue: value,
                                              currentRow: row,
                                              type: column.type,
                                              render: column.render,
                                          })}
                                      </td>
                                  );
                              })}
                          </tr>
                      ))
                    : columns.map((row, index) => {
                          // TODO: Remove any.
                          const value: any = result(data, row.path);

                          return (
                              <Fragment key={index}>
                                  {!row.hidden && (
                                      <tr className={styles.row}>
                                          <td>{row.title}</td>
                                          <td>
                                              {parseTableData({
                                                  currentValue: value,
                                                  currentRow: data,
                                                  type: row.type,
                                                  render: row.render,
                                              })}
                                          </td>
                                      </tr>
                                  )}
                              </Fragment>
                          );
                      })}
            </tbody>
        </table>
    );
};

export default SimpleTable;
