import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal, MultilineInputField, Portal } from "..";
import ModalBody from "../Modal/ModalBody";
import ModalFooter from "../Modal/ModalFooter";

const AddNote = ({ propertyId, onSave, onCancel }: IAddNoteProps) => {
    const { t } = useTranslation();
    const [note, setNote] = useState("");

    const handleSaveClick = () => {
        if (propertyId) {
            onSave(propertyId, note);
        }
    };

    return (
        <Portal>
            <Modal title={t("Add Note")} hide={onCancel}>
                <ModalBody>
                    <MultilineInputField
                        label={t("Please type note below:")}
                        value={note}
                        onChange={setNote}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button
                        variant="primary"
                        onClick={handleSaveClick}
                        cssRules={{ marginRight: "10px" }}
                    >
                        {t("Save Note")}
                    </Button>
                    <Button onClick={onCancel}>{t("Cancel")}</Button>
                </ModalFooter>
            </Modal>
        </Portal>
    );
};

interface IAddNoteProps {
    propertyId?: number;
    onSave: (propertyId: number, note: string) => void;
    onCancel: () => void;
}

export default AddNote;
