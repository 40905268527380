import { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { noop } from "rxjs";
import { IComplianceTypesPopoverProps } from ".";
import {
    Button,
    Checkbox,
    Icon,
    Popover,
    PropertyCategoriesContext,
} from "../../../..";
import PopoverItem from "../../../../../components/Popover/PopoverItem";
import { IPropertyCategory } from "../../../../../utils/api/misc";
import styles from "./ComplianceTypesPopover.module.scss";

const ComplianceTypesPopover = ({
    anchorElement,
    selectedCategories,
    allCategory,
    hide,
    onSave,
}: IComplianceTypesPopoverProps) => {
    const { t } = useTranslation();

    const { propertyCategories } = useContext(PropertyCategoriesContext);

    const [selected, setSelected] = useState(
        selectedCategories.map((s) => s.id),
    );

    const disabled = useMemo(() => {
        const original = selectedCategories
            .map((u) => u.id)
            .sort((a, b) => (a > b ? 1 : -1))
            .toString();
        const current = selected.sort((a, b) => (a > b ? 1 : -1)).toString();

        return original === current;
    }, [selected, selectedCategories]);

    const options: IPropertyCategory[] = useMemo(
        () =>
            propertyCategories.length === 1
                ? propertyCategories
                : [allCategory, ...propertyCategories],
        [allCategory, propertyCategories],
    );

    const handleOptionClick = useCallback(
        (id: number) => {
            if (propertyCategories.length > 1) {
                if (id === 0) {
                    setSelected([]);
                } else {
                    const index = selected.indexOf(id);

                    if (index === -1) {
                        if (propertyCategories.length === selected.length + 1) {
                            setSelected([]);
                        } else {
                            setSelected([...selected, id]);
                        }
                    } else {
                        const newSelected = [...selected];
                        newSelected.splice(index, 1);
                        setSelected(newSelected);
                    }
                }
            }
        },
        [propertyCategories, selected],
    );

    const handleSaveClick = useCallback(() => {
        onSave(selected);
        hide();
    }, [hide, onSave, selected]);

    return (
        <Popover
            anchorElement={anchorElement}
            hide={hide}
            width={240}
            applyPadding={false}
        >
            <div className={styles.container}>
                {options.map((option) => {
                    const isSelected =
                        (option.id === 0 && selected.length === 0) ||
                        selected.includes(option.id);

                    return (
                        <PopoverItem
                            key={option.id}
                            onClick={() => handleOptionClick(option.id)}
                            className={`${styles.option} ${
                                isSelected ? styles.selected : ""
                            }`}
                        >
                            <span className={styles.icon}>
                                <Icon icon={option.name} ariaHidden={true} />
                            </span>

                            <span className={styles.label}>
                                {t(option.displayName)}
                            </span>

                            <Checkbox
                                value=""
                                checked={isSelected}
                                onChange={noop}
                                useMargin={false}
                                ariaLabel={t(option.displayName)}
                                className={styles.checkbox}
                            />
                        </PopoverItem>
                    );
                })}
            </div>

            <div className={styles.footer}>
                <Button
                    variant="primary"
                    displayBlock={true}
                    disabled={disabled}
                    onClick={handleSaveClick}
                >
                    {t("Save")}
                </Button>
            </div>
        </Popover>
    );
};

export default ComplianceTypesPopover;
