import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { ICreateUserModalProps } from ".";
import { Button, Form, InputField, Modal, Portal } from "../../../components";
import ModalBody from "../../../components/Modal/ModalBody";
import ModalFooter from "../../../components/Modal/ModalFooter";
import useAdminCreateUser from "../../../utils/api/users/useAdminCreateUser";
import {
    isEmail,
    isRequired,
    useValidateField,
} from "../../../utils/validation";
import { UpdateUserContext, useUpdateUserFields } from "../UpdateUserContext";
import UpdateUserFields from "../UpdateUserFields";

const CreateUserModal = ({ onCreateUser, hide }: ICreateUserModalProps) => {
    const { t } = useTranslation();
    const { createUser, error, loading } = useAdminCreateUser();

    const [emailAddress, setEmailAddress] = useState("");
    const emailAddressValidation = useValidateField(
        emailAddress,
        isEmail(),
        isRequired(),
    );

    const updateUserContext = useUpdateUserFields(undefined, [
        emailAddressValidation,
    ]);
    const { updatedUser, formValidation } = updateUserContext;

    const handleSubmit = useCallback(() => {
        createUser({ ...updatedUser, email: emailAddress }).subscribe(() => {
            hide();
            onCreateUser();
        });
    }, [createUser, emailAddress, hide, onCreateUser, updatedUser]);

    return (
        <Portal>
            <UpdateUserContext.Provider value={updateUserContext}>
                <Form onSubmit={handleSubmit} {...formValidation}>
                    <Modal title={t("Add User")} hide={hide}>
                        <ModalBody>
                            <UpdateUserFields error={error}>
                                <InputField
                                    label={t("Email")}
                                    value={emailAddress}
                                    onChange={setEmailAddress}
                                    {...emailAddressValidation}
                                />
                            </UpdateUserFields>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                variant="primary"
                                type="submit"
                                disabled={loading}
                            >
                                {t("Add User")}
                            </Button>
                        </ModalFooter>
                    </Modal>
                </Form>
            </UpdateUserContext.Provider>
        </Portal>
    );
};

export default CreateUserModal;
