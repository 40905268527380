import { useCallback } from "react";
import { throwError } from "rxjs";
import config from "../../../config";
import { useXTagApiRequest } from "../../request";

const useFileUrl = () => {
    const sendRequest = useXTagApiRequest();

    const getFileUrl = useCallback(
        (fileUrl: string) => {
            if (fileUrl) {
                // TODO: Some engineer images coming back with leading / causing S3 404, fix it in the back end.
                const url = fileUrl[0] === "/" ? fileUrl.substring(1) : fileUrl;

                return sendRequest<string, unknown>({
                    url: `${config.miscApiUrl}/files`,
                    method: "GET",
                    urlParams: {
                        filename: [url],
                    },
                });
            }

            return throwError(() => new Error("Invalid file url."));
        },
        [sendRequest],
    );

    return getFileUrl;
};

export default useFileUrl;
