import { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IUpdateUserFieldsProps } from ".";
import {
    Alert,
    EditablePill,
    Grid,
    GridColumn,
    InputField,
    Loading,
    PropertyCategoriesContext,
    Select,
    UserContext,
    ValidationError,
} from "../../../components";
import PropertyCategorySelector from "../../../components/PropertyCategorySelector";
import { ISelectOption } from "../../../components/Select";
import { useRoles } from "../../../utils/api/users";
import { UpdateUserContext } from "../UpdateUserContext";
import UserParentRoles from "../UserParentRoles";
import styles from "./UpdateUserFields.module.scss";

const UpdateUserFields = ({ error, children }: IUpdateUserFieldsProps) => {
    const { t } = useTranslation();
    const { user } = useContext(UserContext);
    const { propertyCategories } = useContext(PropertyCategoriesContext);

    const { value: availableRoles, loaded: availableRolesLoaded } = useRoles();

    const {
        firstName,
        setFirstName,
        firstNameValidation,
        lastName,
        setLastName,
        lastNameValidation,
        propertyCategoryIds,
        setPropertyCategoryIds,
        userParentRoles,
        setUserParentRoles,
        userParentRolesValidation,
    } = useContext(UpdateUserContext);

    const selectedPropertyCategories = useMemo(
        () =>
            propertyCategories.filter((pc) =>
                propertyCategoryIds.includes(pc.id),
            ),
        [propertyCategoryIds, propertyCategories],
    );

    const addPropertyCategory = useCallback(
        (id: string) => {
            setPropertyCategoryIds([...propertyCategoryIds, Number(id)]);
        },
        [propertyCategoryIds, setPropertyCategoryIds],
    );

    const removePropertyCategory = useCallback(
        (id: number) => {
            setPropertyCategoryIds(
                propertyCategoryIds.filter(
                    (propertyCategoryId) => propertyCategoryId !== id,
                ),
            );
        },
        [propertyCategoryIds, setPropertyCategoryIds],
    );

    const userParentIdsOptions = useMemo<ISelectOption[]>(() => {
        return user.userParents
            .filter(
                (userParent) =>
                    !userParentRoles
                        .map((u) => u.id)
                        .includes(userParent.userParentId) &&
                    userParent.isActive &&
                    user.userParentPermissions[userParent.userParentId]
                        .map((up) => up.name)
                        .includes("portal_admin"),
            )
            .map((userParent) => ({
                label: userParent.landlord?.name ?? "",
                value: userParent.userParentId.toString(),
            }));
    }, [user, userParentRoles]);

    const handleAddUserParent = useCallback(
        (id: string) => {
            const selectedUserParent = user.userParents.find(
                (userParent) => userParent.userParentId.toString() === id,
            );

            if (selectedUserParent) {
                setUserParentRoles([
                    ...userParentRoles,
                    {
                        id: Number(id),
                        roles: [],
                    },
                ]);
            }
        },
        [setUserParentRoles, user, userParentRoles],
    );

    return availableRolesLoaded ? (
        <>
            <Grid>
                <GridColumn size="half">
                    <InputField
                        label={t("First Name")}
                        value={firstName}
                        onChange={setFirstName}
                        {...firstNameValidation}
                    />
                </GridColumn>
                <GridColumn size="half">
                    <InputField
                        label={t("Last Name")}
                        value={lastName}
                        onChange={setLastName}
                        {...lastNameValidation}
                    />
                </GridColumn>
            </Grid>

            {children}

            <PropertyCategorySelector
                label={t("Select a Compliance Type")}
                value=""
                onChange={addPropertyCategory}
            />

            <div>
                {selectedPropertyCategories.map((propertyCategory) => (
                    <EditablePill
                        value={propertyCategory.id}
                        name={propertyCategory.displayName}
                        remove={removePropertyCategory}
                        key={propertyCategory.id}
                        colour={propertyCategory.colour}
                    />
                ))}
            </div>

            <Select
                label={t("Select a landlord")}
                options={userParentIdsOptions}
                onChange={handleAddUserParent}
                value=""
                allowEmpty={true}
            />
            {userParentRoles.map((userParent) => (
                <UserParentRoles
                    key={userParent.id}
                    userParent={userParent}
                    roles={availableRoles}
                />
            ))}
            <ValidationError {...userParentRolesValidation} />

            {error && (
                <div className={styles.errorContainer}>
                    <Alert type="error">{error}</Alert>
                </div>
            )}
        </>
    ) : (
        <Loading />
    );
};

export default UpdateUserFields;
