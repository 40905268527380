import { useContext, useEffect } from "react";
import { filter, map } from "rxjs";
import { IIssue, IIssueCounts } from ".";
import { PropertyCategoriesContext, UserContext } from "../../../components";
import RealtimeContext from "../../../components/RealtimeProvider/RealtimeContext";
import config from "../../../config";
import { clearCache } from "../../cache";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiValue from "../useApiValue";

const useIssueCounts = () => {
    const { realtimeObservable } = useContext(RealtimeContext);

    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { user, activeUserParentsIds } = useContext(UserContext);
    const { value, loaded, send, updateValue } = useApiValue<IIssueCounts[]>({
        initialValue: [],
    });
    const sendRequest = useXTagApiRequest();

    // TODO: Find a way to update the counts when an issue status is changed.
    useEffect(() => {
        if (value.length > 0) {
            const subscription = realtimeObservable
                .pipe(
                    filter(
                        (e) => e.event === "created" && e.entity === "issue",
                    ),
                    map((e) => e.payload as IIssue),
                )
                .subscribe((newValue) => {
                    const statuses = [...value];

                    for (const status of statuses) {
                        let label = newValue.status;

                        if (
                            newValue.assignedUser &&
                            newValue.assignedUser.id === user.id
                        ) {
                            label = "Inbox";
                        }

                        if (status.statusLabel === label) {
                            status.count += 1;

                            updateValue(statuses);

                            break;
                        }
                    }

                    clearCache();
                });

            return () => {
                if (subscription) {
                    subscription.unsubscribe();
                }
            };
        }
    }, [realtimeObservable, updateValue, user.id, value]);

    useEffect(() => {
        const subscription = send(
            sendRequest({
                url: `${config.issuesApiUrl}/statuscount`,
                method: "GET",
                urlParams: getGlobalUrlParameters(
                    selectedCategories.map((c) => c.id),
                    activeUserParentsIds,
                ),
            }),
        ).subscribe();

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [activeUserParentsIds, selectedCategories, send, sendRequest]);

    return { value, loaded };
};

export default useIssueCounts;
