import { debounce } from "lodash";
import { useCallback, useContext, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Button,
    Card,
    DateRangePickerDropdown,
    Grid,
    GridColumn,
    Icon,
    InfiniteScroll,
    InputField,
    JobTimeline,
    Loading,
    PropertyCategoriesContext,
} from "../../components";
import { IJob } from "../../components/JobTimeline";
import { useToggle } from "../../hooks";
import { ISimpleJob, useJobLog } from "../../utils/api/jobs";
import {
    useDocumentsDownload,
    useDocumentsRangeDownload,
} from "../../utils/api/lgsrs";
import {
    addDays,
    addMonths,
    getDifferenceInDays,
    getToday,
    toDateString,
} from "../../utils/dates";
import downloadFile from "../../utils/files";
import styles from "./JobLog.module.scss";

const JobLog = () => {
    const { t } = useTranslation();
    const [startDate, setStartDate] = useState(addMonths(getToday(), -1));
    const [endDate, setEndDate] = useState(addDays(getToday(), 1));
    const [selectedJobIds, setSelectedJobIds] = useState<number[]>([]);

    const [jobLog, setJobLog] = useState<IJob[]>([]);
    const onRecordsLoaded = useCallback(
        (values: ISimpleJob[], currentPage: number) => {
            const newRecords = currentPage === 1 ? [] : [...jobLog];

            newRecords.push(
                ...values.map((job) => ({
                    ...job,
                    engineer: {
                        id: job.engineer.id,
                        name: job.engineer.name,
                    },
                    property: {
                        id: job.property.id,
                        addressString: job.property.addressString,
                    },
                })),
            );

            setJobLog(newRecords);
        },
        [jobLog],
    );
    const {
        applySearch,
        currentPage,
        totalPages,
        goToNextPage,
        toggleFilter,
        loading,
    } = useJobLog(startDate, endDate, onRecordsLoaded);

    const { visible, hide, toggle } = useToggle();
    const datePickerButton = useRef<HTMLButtonElement>(null);

    const canDownloadDocumentsByDateRange = useMemo(
        () => getDifferenceInDays(startDate, endDate) <= 25,
        [startDate, endDate],
    );

    const handleChange = useMemo(
        () =>
            debounce((value: string) => {
                applySearch(value);
            }, 300),
        [applySearch],
    );

    const loadNextPage = () => {
        if (currentPage < totalPages) {
            goToNextPage();
        }
    };

    const handleDatesSelected = (fromDate: Date, toDate: Date) => {
        setStartDate(fromDate);
        setEndDate(toDate);
    };

    const handleJobCheckedChange = (checked: boolean, value: number) => {
        const list = selectedJobIds.filter((j) => j !== value);

        if (checked) {
            list.push(value);
        }

        setSelectedJobIds(list);
    };

    const documentsDownload = useDocumentsDownload();
    const documentsRangeDownload = useDocumentsRangeDownload();

    const selectedPaperworkIds = useMemo(
        () =>
            jobLog
                .filter((j) => selectedJobIds.includes(j.id))
                .flatMap((j) =>
                    j.paperwork ? j.paperwork.map((p) => p.id) : [],
                ),
        [jobLog, selectedJobIds],
    );
    const selectedLgsrIds = useMemo(
        () =>
            jobLog
                .filter((j) => selectedJobIds.includes(j.id))
                .flatMap((j) =>
                    j.paperwork
                        ? j.paperwork
                              .filter((p) => p.documentName === "LGSR")
                              .map((p) => p.id)
                        : [],
                ),
        [jobLog, selectedJobIds],
    );

    const selectedEicrIds = useMemo(
        () =>
            jobLog
                .filter((j) => selectedJobIds.includes(j.id))
                .flatMap((j) =>
                    j.paperwork
                        ? j.paperwork
                              .filter((p) => p.documentName === "EICR")
                              .map((p) => p.id)
                        : [],
                ),
        [jobLog, selectedJobIds],
    );

    const downloadLgsrs = () => {
        if (selectedLgsrIds.length > 0) {
            documentsDownload.download(selectedLgsrIds).subscribe((url) => {
                downloadFile(url);
            });
        } else {
            documentsRangeDownload
                .downloadDocuments(
                    startDate,
                    startDate === endDate ? addDays(endDate, 1) : endDate,
                    "LGSR",
                )
                .subscribe((url) => {
                    downloadFile(url);
                });
        }
    };

    const downloadEicrs = () => {
        if (selectedEicrIds.length > 0) {
            documentsDownload.download(selectedEicrIds).subscribe((url) => {
                downloadFile(url);
            });
        } else {
            documentsRangeDownload
                .downloadDocuments(
                    startDate,
                    startDate === endDate ? addDays(endDate, 1) : endDate,
                    "EICR",
                )
                .subscribe((url) => {
                    downloadFile(url);
                });
        }
    };

    const downloadDocuments = () => {
        if (selectedPaperworkIds.length > 0) {
            documentsDownload
                .download(selectedPaperworkIds)
                .subscribe((url) => {
                    downloadFile(url);
                });
        } else {
            documentsRangeDownload
                .downloadDocuments(
                    startDate,
                    startDate === endDate ? addDays(endDate, 1) : endDate,
                )
                .subscribe((url) => {
                    downloadFile(url);
                });
        }
    };

    const { selectedCategories } = useContext(PropertyCategoriesContext);

    const isGasSelected = useMemo(
        () =>
            selectedCategories.length === 0 ||
            selectedCategories.some((c) => c.id === 1),
        [selectedCategories],
    );

    const isElectricSelected = useMemo(
        () =>
            selectedCategories.length === 0 ||
            selectedCategories.some((c) => c.id === 20),
        [selectedCategories],
    );

    return (
        <Card title={t("Job Log")} bodyScroll={false}>
            <Grid>
                <GridColumn size="twoThirds">
                    <div className={styles.buttonsContainer}>
                        <Button onClick={toggle} setRef={datePickerButton}>
                            <Icon
                                icon="calendar"
                                display="inline-block"
                                ariaHidden={true}
                            />
                            &nbsp;
                            {toDateString(startDate)} - {toDateString(endDate)}
                        </Button>

                        {visible && (
                            <DateRangePickerDropdown
                                property="date"
                                toggleFilter={toggleFilter}
                                buttonElement={datePickerButton}
                                startDate={startDate}
                                endDate={endDate}
                                onDatesSelected={handleDatesSelected}
                                hide={hide}
                            />
                        )}

                        {documentsRangeDownload.loading ||
                        documentsDownload.loading ? (
                            <div className={styles.generatingContainer}>
                                {t("Generating")}
                                <Loading small={true} />
                            </div>
                        ) : (
                            <>
                                {(canDownloadDocumentsByDateRange ||
                                    selectedLgsrIds.length > 0) &&
                                    isGasSelected && (
                                        <Button
                                            cssRules={{ marginLeft: "5px" }}
                                            onClick={downloadLgsrs}
                                        >
                                            {t("Download LGSRs")}
                                        </Button>
                                    )}

                                {(canDownloadDocumentsByDateRange ||
                                    selectedLgsrIds.length > 0) &&
                                    isElectricSelected && (
                                        <Button
                                            cssRules={{ marginLeft: "5px" }}
                                            onClick={downloadEicrs}
                                        >
                                            {t("Download EICRs")}
                                        </Button>
                                    )}

                                {(canDownloadDocumentsByDateRange ||
                                    selectedPaperworkIds.length > 0) && (
                                    <Button
                                        cssRules={{ marginLeft: "5px" }}
                                        onClick={downloadDocuments}
                                    >
                                        {t("Download documents")}
                                    </Button>
                                )}
                            </>
                        )}
                    </div>
                </GridColumn>
                <GridColumn size="oneThird">
                    <InputField
                        placeholder={t("Search")}
                        onChange={handleChange}
                        append={<Icon icon="search" ariaHidden={true} />}
                    />
                </GridColumn>
            </Grid>
            <InfiniteScroll loading={loading} onScrollToBottom={loadNextPage}>
                {jobLog.length > 0 && (
                    <JobTimeline
                        jobs={jobLog}
                        loadTasksAsync={true}
                        selectedIds={selectedJobIds}
                        onCheckedChange={handleJobCheckedChange}
                        showTaskIcons={true}
                    />
                )}
            </InfiniteScroll>
        </Card>
    );
};

export default JobLog;
