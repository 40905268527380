import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { IEditComplianceRowProps } from ".";
import { Button, Grid, GridColumn, Icon, Select } from "../../../../components";
import styles from "./EditComplianceRow.module.scss";

const EditComplianceRow = ({
    complianceTypes,
    contractors,
    complianceTypeId,
    contractorId,
    setComplianceTypeId,
    setContractorId,
    removeComplianceType,
}: IEditComplianceRowProps) => {
    const { t } = useTranslation();

    const handleComplianceTypeChange = useCallback(
        (value: string) => {
            setComplianceTypeId(complianceTypeId, value);
        },
        [complianceTypeId, setComplianceTypeId],
    );

    const handleContractorIdChange = useCallback(
        (value: string) => {
            setContractorId(complianceTypeId, value);
        },
        [complianceTypeId, setContractorId],
    );

    const handleRemoveComplianceTypeClick = useCallback(() => {
        removeComplianceType(complianceTypeId);
    }, [complianceTypeId, removeComplianceType]);

    return (
        <div className={styles.row}>
            <Grid>
                <GridColumn size="oneQuarter">
                    <Select
                        value={complianceTypeId}
                        onChange={handleComplianceTypeChange}
                        options={complianceTypes}
                        useMargin={false}
                        size="small"
                    />
                </GridColumn>
                <GridColumn size="half">
                    <Select
                        value={contractorId}
                        onChange={handleContractorIdChange}
                        options={contractors}
                        useMargin={false}
                        size="small"
                    />
                </GridColumn>
                <GridColumn size="oneQuarter">
                    <div className={styles.actions}>
                        <Button
                            variant="error"
                            onClick={handleRemoveComplianceTypeClick}
                        >
                            <Icon icon="remove" ariaLabel={t("Remove")} />
                        </Button>
                    </div>
                </GridColumn>
            </Grid>
        </div>
    );
};

export default EditComplianceRow;
