import { useMemo } from "react";
import { IMapProperty } from "../../utils/api/properties";

export const usePropertyMapFeature = (
    mapFeature: boolean,
    mapPropertiesRecords: IMapProperty[],
) => {
    const map = useMemo<
        [number, number | null, number | null, number][][]
    >(() => {
        if (mapFeature && mapPropertiesRecords) {
            return [
                mapPropertiesRecords.map((property) => [
                    property.id,
                    property.lat,
                    property.long,
                    1,
                ]),
            ];
        }

        return [];
    }, [mapFeature, mapPropertiesRecords]);

    return { map };
};
