import { orderBy } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BarChart, IChartData } from "../../../components/Charts";
import { colours } from "../../../styles/colours";
import { IRecentWork } from "../../../utils/api/misc";
import styles from "./SingleRecentWorks.module.scss";

const SingleRecentWorks = ({ works }: IRecentWorksProps) => {
    const { t } = useTranslation();

    const [services, setServices] = useState(0);
    const [installs, setInstalls] = useState(0);
    const [repairs, setRepairs] = useState(0);
    const [chartData, setChartData] = useState<IChartData[]>([]);
    const [labels, setLabels] = useState<string[]>([]);

    useEffect(() => {
        let servicesCount = 0;
        let installsCount = 0;
        let repairsCount = 0;
        const chartDictionary: {
            [key: string]: {
                services: number;
                installs: number;
                repairs: number;
            };
        } = {};

        for (const landlordWorks of works) {
            servicesCount += landlordWorks.services.total;
            installsCount += landlordWorks.installs.total;
            repairsCount += landlordWorks.repairs.total;

            for (const service of landlordWorks.services.data) {
                if (chartDictionary[service.date]) {
                    chartDictionary[service.date].services += service.count;
                    // TODO: Update the API to not return records when the count is zero.
                } else if (service.count > 0) {
                    chartDictionary[service.date] = {
                        services: service.count,
                        installs: 0,
                        repairs: 0,
                    };
                }
            }

            for (const service of landlordWorks.installs.data) {
                if (chartDictionary[service.date]) {
                    chartDictionary[service.date].installs += service.count;
                } else if (service.count > 0) {
                    chartDictionary[service.date] = {
                        services: 0,
                        installs: service.count,
                        repairs: 0,
                    };
                }
            }

            for (const service of landlordWorks.repairs.data) {
                if (chartDictionary[service.date]) {
                    chartDictionary[service.date].repairs += service.count;
                } else if (service.count > 0) {
                    chartDictionary[service.date] = {
                        services: 0,
                        installs: 0,
                        repairs: service.count,
                    };
                }
            }
        }

        const chartLabels: string[] = [];
        const chartServicesValues: number[] = [];
        const chartInstallsValues: number[] = [];
        const chartRepairsValues: number[] = [];

        const array = orderBy(
            Object.entries(chartDictionary),
            (data) => data[0],
            ["asc"],
        );
        for (const data of array) {
            const date = new Date(data[0]);
            chartLabels.push(
                `${date.getDate()} ${t(`monthsShort.${date.getMonth()}`)}`,
            );

            chartServicesValues.push(data[1].services);
            chartInstallsValues.push(data[1].installs);
            chartRepairsValues.push(data[1].repairs);
        }

        setLabels(chartLabels);
        setChartData([
            {
                label: t("Services"),
                values: chartServicesValues,
                color: colours.chart.colours[40],
            },
            {
                label: t("Installs"),
                values: chartInstallsValues,
                color: colours.chart.colours[42],
            },
            {
                label: t("Repairs"),
                values: chartRepairsValues,
                color: colours.chart.colours[41],
            },
        ]);
        setServices(servicesCount);
        setInstalls(installsCount);
        setRepairs(repairsCount);
    }, [t, works]);

    return (
        <div className={styles.centerContainer}>
            <div>
                <div className={styles.container}>
                    <div>
                        <span
                            className={styles.count}
                            style={{ color: colours.chart.colours[40] }}
                            data-test-id="Services_Total_Span"
                        >
                            {services}
                        </span>
                        &nbsp;
                        {t("services")}
                    </div>
                    <div>
                        <span
                            className={styles.count}
                            style={{ color: colours.chart.colours[42] }}
                            data-test-id="Installs_Total_Span"
                        >
                            {installs}
                        </span>
                        &nbsp;
                        {t("installs")}
                    </div>
                    <div>
                        <span
                            className={styles.count}
                            style={{ color: colours.chart.colours[41] }}
                            data-test-id="Repairs_Total_Span"
                        >
                            {repairs}
                        </span>
                        &nbsp;
                        {t("repairs")}
                    </div>
                </div>

                <BarChart data={chartData} labels={labels} maxHeight={200} />
                <div className={styles.chartLabel}>{t("Week Commencing")}</div>
            </div>
        </div>
    );
};

interface IRecentWorksProps {
    works: IRecentWork[];
}

export default SingleRecentWorks;
