import { Observable } from "rxjs";

let cache: { [key: string]: { [key: string]: ICacheable } } = {};

const clearCache = () => {
    cache = {};
};

interface ICacheable {
    observable: Observable<any>;
    expiresAt: Date;
}

export { cache, clearCache };
