import { IValidateField } from "../../../utils/validation";
import ContractorSelect from "./ContractorSelect";

export interface IContractorSelectProps {
    contractorId: string;
    setContractorId: (contractorId: string) => void;
    validator: IValidateField;
}

export default ContractorSelect;
