import { useCallback, useState } from "react";
import { INotificationTabProps } from ".";
import { InfiniteScroll } from "../..";
import { INotification, useNotifications } from "../../../utils/api/misc";
import useMarkNotificationRead from "../../../utils/api/misc/useMarkNotificationRead";
import NotificationDropdownItem from "../NotificationDropdown/NotificationDropdownItem";

const NotificationTab = ({
    notificationCategory,
    updateNotificationCount,
    read,
}: INotificationTabProps) => {
    const [notifications, setNotifications] = useState<INotification[]>([]);
    const onNotificationsLoaded = useCallback(
        (values: INotification[], currentPage: number) => {
            setNotifications((current) => {
                const newRecords = currentPage === 1 ? [] : [...current];

                newRecords.push(...values);

                return newRecords;
            });
        },
        [],
    );

    const { loading, currentPage, totalPages, goToNextPage } = useNotifications(
        notificationCategory,
        read,
        onNotificationsLoaded,
    );

    const loadNextPage = useCallback(() => {
        if (currentPage < totalPages) {
            goToNextPage();
        }
    }, [currentPage, totalPages, goToNextPage]);

    const { markRead } = useMarkNotificationRead();

    const markAsRead = useCallback(
        (id: number) => {
            markRead(id).subscribe(() => updateNotificationCount(false));
        },
        [markRead, updateNotificationCount],
    );

    return (
        <InfiniteScroll loading={loading} onScrollToBottom={loadNextPage}>
            {notifications.map((notification: INotification) => (
                <NotificationDropdownItem
                    key={notification.id}
                    id={notification.id}
                    title={notification.title}
                    description={notification.description}
                    icon={notification.notificationType?.icon ?? ""}
                    date={notification.createdAt}
                    read={notification.read}
                    url={notification.url}
                    markAsRead={markAsRead}
                />
            ))}
        </InfiniteScroll>
    );
};

export default NotificationTab;
