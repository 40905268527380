import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "..";
import { ISimpleIssue } from "../../utils/api/issues";
import Link from "../Link";
import SimpleTable, { IColumn } from "../SimpleTable";
import styles from "./IssuesLog.module.scss";

const IssuesLog = ({ issues, fullHeight }: IIssuesLogProps) => {
    const { t } = useTranslation();

    const columns = useMemo<IColumn[]>(() => {
        const renderTitle = (value: string, data: ISimpleIssue) => (
            <Link key={data.id} url={`/issues/${data.id}`}>
                {value}
            </Link>
        );

        const renderStatus = (value: string) => (
            <div
                className={`${styles.issueStatus} ${
                    value === "Blocked"
                        ? styles.blocked
                        : value === "Resolved"
                        ? styles.resolved
                        : value === "Unresolved"
                        ? styles.unresolved
                        : ""
                }`}
            >
                {t(value)}
            </div>
        );

        return [
            {
                title: t("Date"),
                path: "createdAt",
                type: "date",
            },
            {
                title: t("Title"),
                path: "title",
                type: "component",
                render: renderTitle,
            },
            {
                title: t("Status"),
                path: "status",
                type: "component",
                render: renderStatus,
            },
        ];
    }, [t]);

    return (
        <Card title={t("Issues Log")} fullHeight={fullHeight}>
            {issues.length > 0 ? (
                <SimpleTable columns={columns} data={issues} />
            ) : (
                t("No issues found")
            )}
        </Card>
    );
};

interface IIssuesLogProps {
    issues: ISimpleIssue[];
    fullHeight?: boolean;
}

export default IssuesLog;
