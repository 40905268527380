import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IEditLimitation, limitationsValidation } from ".";
import {
    Alert,
    Button,
    Form,
    Grid,
    GridColumn,
    InputField,
    Loading,
} from "../../../../components";
import { useToggle } from "../../../../hooks";
import { useGuidGenerator } from "../../../../hooks/useGuidGenerator";
import {
    useLandlordContractorLimitationSet,
    useUpdateLandlordContractorLimitationSet,
} from "../../../../utils/api/landlords";
import {
    isRequired,
    useValidateField,
    validateForm,
} from "../../../../utils/validation";
import LimitationRow from "../LimitationRow";
import styles from "./LimitationSet.module.scss";

const LimitationSet = ({ landlordId, contractorId }: ILimitationSetProps) => {
    const {
        value: limitationSet,
        updateValue: updateLimitationSet,
        loaded,
    } = useLandlordContractorLimitationSet(
        Number(landlordId),
        Number(contractorId),
    );

    const [agreedWith, setAgreedWith] = useState("");
    const [limitations, setLimitations] = useState<IEditLimitation[]>([]);

    useEffect(() => {
        if (limitationSet) {
            setAgreedWith(limitationSet.agreedWith);
            setLimitations(
                limitationSet.limitations.map((l) => ({
                    id: l.order.toString(),
                    text: l.text,
                })),
            );
        }
    }, [limitationSet]);

    const { show, hide, visible } = useToggle();
    const { t } = useTranslation();
    const { update, error, loading } =
        useUpdateLandlordContractorLimitationSet();

    const handleSubmit = useCallback(() => {
        update({
            landlordId: Number(landlordId),
            contractorId: Number(contractorId),
            previousId: limitationSet?.id,
            agreedWith,
            limitations: limitations.map((l, i) => ({
                text: l.text,
                order: i + 1,
            })),
        }).subscribe((newLimitationSet) => {
            updateLimitationSet(newLimitationSet);
            hide();
        });
    }, [
        contractorId,
        hide,
        landlordId,
        agreedWith,
        limitationSet,
        limitations,
        update,
        updateLimitationSet,
    ]);

    const generateId = useGuidGenerator();

    const handleEditClick = useCallback(() => {
        if (limitationSet?.limitations.length > 0) {
            setLimitations(
                limitationSet.limitations.map((l) => ({
                    id: l.order.toString(),
                    text: l.text,
                })),
            );
        } else {
            setLimitations([{ id: generateId(), text: "" }]);
        }

        show();
    }, [generateId, limitationSet, show]);

    const handleChange = useCallback((id: string, value: string) => {
        setLimitations((list) => {
            const newList = [...list];

            const index = newList.findIndex((l) => l.id === id);

            return [
                ...newList.slice(0, index),
                { id, text: value },
                ...newList.slice(index + 1),
            ];
        });
    }, []);

    const handleRemove = useCallback((id: string) => {
        setLimitations((list) => list.filter((l) => l.id !== id));
    }, []);

    const handleAddMoreClick = useCallback(() => {
        setLimitations((l) => [...l, { id: generateId(), text: "" }]);
    }, [generateId]);

    const handleCancelClick = useCallback(() => {
        setAgreedWith(limitationSet?.agreedWith ?? "");
        setLimitations(
            limitationSet?.limitations.map((l) => ({
                id: l.order.toString(),
                text: l.text,
            })) ?? [],
        );
        hide();
    }, [hide, limitationSet]);

    const limitationsValidator = useValidateField(
        limitations,
        limitationsValidation,
    );
    const agreedWithValidator = useValidateField(agreedWith, isRequired());

    const formValidator = validateForm(() => [
        limitationsValidator,
        agreedWithValidator,
    ]);

    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    const handleInvalidSubmit = useCallback(() => {
        setIsFormSubmitted(true);
    }, []);

    return loaded ? (
        <>
            <InputField
                value={agreedWith}
                onChange={setAgreedWith}
                label={t("Agreed with")}
                disabled={!visible}
                {...agreedWithValidator}
            />

            <Grid margin={false}>
                <GridColumn size="tenPercent">
                    <span className={styles.tableHeader}>{t("Number")}</span>
                </GridColumn>
                <GridColumn size="ninetyPercent">
                    <span className={styles.tableHeader}>{t("Title")}</span>
                </GridColumn>

                {limitations.map((limitation, index) => (
                    <LimitationRow
                        key={limitation.id}
                        order={index + 1}
                        id={limitation.id}
                        value={limitation.text}
                        isEditing={visible}
                        onChange={handleChange}
                        onRemove={handleRemove}
                        isFormSubmitted={isFormSubmitted}
                    />
                ))}
            </Grid>

            <div className={styles.marginTop}>
                {visible && (
                    <Form
                        onSubmit={handleSubmit}
                        {...formValidator}
                        onInvalidSubmit={handleInvalidSubmit}
                    >
                        {error && (
                            <Alert type="error">
                                {t(
                                    "Failed to save changes. Please refresh the page and try again.",
                                )}
                            </Alert>
                        )}
                        <div className={styles.buttonContainer}>
                            <Button
                                variant="primary"
                                onClick={handleAddMoreClick}
                                cssRules={{
                                    marginLeft: "auto",
                                }}
                                disabled={loading}
                            >
                                {t("Add more")}
                            </Button>
                        </div>

                        <div className={styles.buttonContainer}>
                            <Button
                                onClick={handleCancelClick}
                                cssRules={{ marginRight: "10px" }}
                                disabled={loading}
                            >
                                {t("Cancel")}
                            </Button>
                            <Button
                                type="submit"
                                variant="primary"
                                disabled={loading}
                            >
                                {t("Save")}
                            </Button>
                        </div>
                    </Form>
                )}
                {!visible && (
                    <Button onClick={handleEditClick}>{t("Edit")}</Button>
                )}
            </div>
        </>
    ) : (
        <Loading />
    );
};

interface ILimitationSetProps {
    landlordId: string;
    contractorId: string;
}

export default LimitationSet;
