import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { ISideMenuProps } from ".";
import { NavLink } from "../..";
import Carousel, { ICarouselImage } from "../../../components/Carousel";
import { useHasPermission, useNavigationLinks } from "../../../hooks";
import fra from "../../../images/banners/fra.svg";
import repairsManager from "../../../images/banners/repairs-manager.svg";
import ActiveTags from "../ActiveTags";
import styles from "./SideMenu.module.scss";

const SideMenu = ({ appRoutes }: ISideMenuProps) => {
    const { t } = useTranslation();
    const links = useNavigationLinks(appRoutes);
    const isPortalStandard = useHasPermission("portal_standard");

    const { pathname } = useLocation();
    const images = useMemo(() => {
        const list: ICarouselImage[] = [];

        if (pathname.startsWith("/compliance")) {
            list.push(
                {
                    image: repairsManager,
                    navigateTo:
                        "https://www.plentific.com/en-gb/repairs-manager",
                    title: t("Repairs Manager"),
                    analytics: {
                        title: "Repairs Manager",
                        description:
                            "Sign up for our Repairs Manager product...",
                        type: "XTag Repairs Manager banner",
                    },
                },
                {
                    image: fra,
                    navigateTo:
                        "https://www.plentific.com/en-gb/compliance-manager/fire-safety",
                    title: t("Fire Risk Assessment"),
                    analytics: {
                        title: "Fire Risk Assessment",
                        description: "Sign up for our FRA product...",
                        type: "XTag FRA banner",
                    },
                },
            );
        }

        return list;
    }, [pathname, t]);

    return (
        <div className={styles.container}>
            <div
                className={styles.menu}
                aria-label={t("Side menu")}
                role="navigation"
            >
                {links.map((link) => (
                    <NavLink key={link.url} {...link} position="SideMenu" />
                ))}

                {images.length > 0 && (
                    <div className={styles.carousel}>
                        <Carousel images={images} />
                    </div>
                )}
            </div>

            {isPortalStandard && <ActiveTags />}
        </div>
    );
};

export default SideMenu;
