import { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { switchMap } from "rxjs";
import {
    Card,
    Link,
    Loading,
    UserPreferencesContext,
} from "../../../components";
import DashboardWidgetFilter, {
    IFilterGroup,
} from "../../../components/DashboardWidgetFilter";
import {
    IPhase,
    useUnresolvedObservationStatusGraph,
} from "../../../utils/api/issues";
import { encodeUrl } from "../../../utils/url";
import ChartActions from "../ChartComponents/ChartActions";
import UnresolvedObservationsChartOverview from "../UnresolvedObservationsChartOverview";
import UnresolvedObservationsStatusGraph from "../UnresolvedObservationsStatusGraph";
import styles from "./UnresolvedObservationsStatusCard.module.scss";

const UnresolvedObservationsStatusCard = () => {
    const { t } = useTranslation();

    const { preferences, updatePreferences } = useContext(
        UserPreferencesContext,
    );

    const [showGraph, setShowGraph] = useState(
        preferences.observation_count_view === "graph",
    );

    const observationType = useMemo(
        () => preferences.unresolved_observation_type || "",
        [preferences.unresolved_observation_type],
    );

    const { value, loading, refresh } =
        useUnresolvedObservationStatusGraph(observationType);

    const handleSavePhaseLimits = useCallback(
        (phases: IPhase[]) => {
            updatePreferences({
                ...preferences,
                observation_count_phases: phases.map((phase) => ({
                    colour: phase.colour,
                    weeks_since: phase.phaseTimeFrame,
                    title: phase.title,
                })),
            })
                .pipe(switchMap(() => refresh()))
                .subscribe();
        },
        [preferences, refresh, updatePreferences],
    );

    const handleToggleGraphOverview = useCallback(
        (view: "graph" | "overview"): void => {
            updatePreferences({
                ...preferences,
                observation_count_view: view,
            }).subscribe(() => setShowGraph(view === "graph"));
        },
        [preferences, updatePreferences],
    );

    const filters = useMemo(() => {
        const handleChangeValue = (type: string) => {
            updatePreferences({
                ...preferences,
                unresolved_observation_type: type,
            }).subscribe();
        };

        const list: IFilterGroup[] = [
            {
                id: "observation_types",
                title: t("Observation types"),
                value: observationType,
                changeValue: handleChangeValue,
                allowEmptyValue: true,
                options: [
                    { label: t("All"), value: "" },
                    { label: t("C1"), value: "C1" },
                    { label: t("C2"), value: "C2" },
                    { label: t("C3"), value: "C3" },
                    { label: t("FI"), value: "FI" },
                ],
            },
        ];

        return list;
    }, [observationType, preferences, t, updatePreferences]);

    const unresolvedObservationStatusPhases = useMemo(
        () => (value ? value.phases.filter((p) => p.dates.length > 0) : []),
        [value],
    );

    return (
        <Card
            title={t("Unresolved observations")}
            fullHeight={true}
            actions={
                <ChartActions
                    phases={(value && value.phases) || []}
                    savePhaseLimits={handleSavePhaseLimits}
                    showGraph={showGraph}
                    toggleGraphOverview={handleToggleGraphOverview}
                    phaseTimeframe={t("Weeks since observation raised")}
                >
                    <DashboardWidgetFilter filters={filters} />
                </ChartActions>
            }
        >
            {loading ? (
                <Loading />
            ) : (
                value && (
                    <div className={styles.container}>
                        <div className={styles.leftColumn}>
                            <div className={styles.legend}>
                                <div className={styles.label}>{t("Total")}</div>
                                <Link url="/issues/observations" color="black">
                                    {value.total}
                                </Link>
                            </div>

                            <div className={styles.legend}>
                                <div className={styles.label}>{t("C1")}</div>
                                <Link
                                    url={encodeUrl("/issues/observations", {
                                        "type.displayName": ["=c1"],
                                    })}
                                    color="red"
                                >
                                    {value.c1}
                                </Link>
                            </div>

                            <div className={styles.legend}>
                                <div className={styles.label}>{t("C2")}</div>
                                <Link
                                    url={encodeUrl("/issues/observations", {
                                        "type.displayName": ["=c2"],
                                    })}
                                    color="orange"
                                >
                                    {value.c2}
                                </Link>
                            </div>

                            <div className={styles.legend}>
                                <div className={styles.label}>{t("C3")}</div>
                                <Link
                                    url={encodeUrl("/issues/observations", {
                                        "type.displayName": ["=c3"],
                                    })}
                                >
                                    {value.c3}
                                </Link>
                            </div>

                            <div className={styles.legend}>
                                <div className={styles.label}>{t("FI")}</div>
                                <Link
                                    url={encodeUrl("/issues/observations", {
                                        "type.displayName": ["=fi"],
                                    })}
                                    color="green"
                                >
                                    {value.fi}
                                </Link>
                            </div>
                        </div>

                        <div className={styles.rightColumn}>
                            {showGraph ? (
                                <UnresolvedObservationsStatusGraph
                                    phases={unresolvedObservationStatusPhases}
                                    observationType={observationType}
                                />
                            ) : (
                                <UnresolvedObservationsChartOverview
                                    phases={unresolvedObservationStatusPhases}
                                    observationType={observationType}
                                />
                            )}

                            <div className={styles.subText}>
                                {t("Weeks since unresolved observation raised")}
                            </div>
                        </div>
                    </div>
                )
            )}
        </Card>
    );
};

export default UnresolvedObservationsStatusCard;
