import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { BarChart, Card, Loading } from "../../../components";
import InfoTooltip from "../../../components/InfoToolTip";
import { useNow } from "../../../hooks/useNow";
import { useAwaitingAllocationJobPriorities } from "../../../utils/api/allocatedJobs";

const UpcomingJobsHours = ({ priorities }: IUpcomingJobsHoursProps) => {
    const { t } = useTranslation();

    const now = useNow();
    const startDate = useMemo(() => new Date(now.setMinutes(0, 0, 0)), [now]);
    const { value, labels, loaded } = useAwaitingAllocationJobPriorities({
        jobPriorities: priorities,
        duration: 48,
        startDate: startDate,
        unit: "hours",
    });

    const nextHoursRepairSlaLabels = useMemo(
        () => labels.map((label, index) => (index % 4 === 0 ? label : "")),
        [labels],
    );

    return (
        <Card
            title={t("Upcoming jobs in the next 48 hours")}
            fullHeight={true}
            actions={
                <InfoTooltip
                    tooltipText={t(
                        "Shows all awaiting allocation jobs with an SLA within the next 48 hours, grouped by their SLA priority.",
                    )}
                />
            }
        >
            {loaded ? (
                <BarChart
                    data={value}
                    labels={nextHoursRepairSlaLabels}
                    tooltipLabels={labels}
                    maxHeight={240}
                />
            ) : (
                <Loading small={true} smallWidth={100} centerContent={true} />
            )}
        </Card>
    );
};

interface IUpcomingJobsHoursProps {
    priorities: string[];
}

export default UpcomingJobsHours;
