import { saveAs } from "file-saver";

const config = {
    byteOrderMark: "\ufeff",
    quoteChar: '"',
    delimiter: ",",
    lineSeparator: "\r\n",
    charset: "utf-8",
};

const escapeValue = (value: any) => {
    let result =
        typeof value === "object" || value === undefined || value === null
            ? ""
            : String(value);

    result = result.replace(
        config.quoteChar,
        config.quoteChar + config.quoteChar,
    );

    const regex: RegExp = new RegExp(
        `(\n|\r|${config.byteOrderMark}|${config.quoteChar}|${
            config.delimiter
        })`,
        "g",
    );
    if (
        result.charAt(0) === " " ||
        result.charAt(result.length - 1) === " " ||
        regex.test(result)
    ) {
        result = config.quoteChar + result + config.quoteChar;
    }

    return result;
};

const generateCsv = (headers: string[], rows: any[][]) => {
    let csv = "";

    if (headers.length > 0) {
        csv += headers
            .map((header) => escapeValue(header))
            .join(config.delimiter);
        csv += config.lineSeparator;
    }

    for (const row of rows) {
        csv += row.map((column) => escapeValue(column)).join(config.delimiter);
        csv += config.lineSeparator;
    }

    return csv;
};

const downloadCsv = (filename: string, headers: string[], rows: any[][]) => {
    const csv = generateCsv(headers, rows);
    const blob = new Blob([csv], {
        type: `text/csv;charset=${config.charset}`,
    });

    saveAs(blob, filename);
};

export { downloadCsv };
