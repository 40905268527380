import { useEffect, useMemo } from "react";
import { ITask } from ".";
import config from "../../../config";
import useFilterable, { IFilters, SortDirection } from "../useFilterable";

const useTasksForAsset = ({
    assetId,
    sortProperty = "",
    sortDirection = "asc",
}: ITaskFilterableParameters) => {
    const filters = useMemo(() => {
        const filterGroups: IFilters = {
            "asset.id": {
                operator: "{AND}",
                filters: [{ function: "=", value: assetId }],
            },
        };

        return filterGroups;
    }, [assetId]);

    const { records, refresh, loaded, error } = useFilterable<ITask>(
        `${config.answersApiUrl}/tasks`,
        {
            params: {
                sortProperty,
                sortDirection,
                filters,
            },
        },
    );

    useEffect(() => {
        refresh(filters);
    }, [filters, refresh]);

    return { records, loaded, error };
};

interface ITaskFilterableParameters {
    assetId: string;
    sortProperty?: string;
    sortDirection?: SortDirection;
}

export default useTasksForAsset;
