import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Badge, Button, Card, TabGroup, TabGroupTab } from "../../components";
import {
    ReconciliationEntityType,
    useReconciliationStats,
} from "../../utils/api/reconciliation";
import styles from "./Reconciliation.module.scss";
import ReconciliationTable from "./ReconciliationTable";

const Reconciliation = () => {
    const { t } = useTranslation();

    const { value, refresh } = useReconciliationStats();
    const handleReconciliationUpdate = useCallback(() => {
        refresh().subscribe();
    }, [refresh]);

    const entities = useMemo<IReconciliationEntity[]>(
        () => [
            {
                active: true,
                entity: "engineers",
                displayName: t("Engineers"),
                reconcile: {
                    primaryTitle: t("Engineers Name"),
                    secondaryTitle: t("Employee No."),
                },
                reconciledValue: {
                    title: t("Reconciled With"),
                },
                reconciledValidation: {
                    title: t("Gas Safe Register No."),
                },
            },
            {
                entity: "assets",
                displayName: t("Assets"),
                reconcile: {
                    primaryTitle: t("Asset Name"),
                    secondaryTitle: t("Asset Id"),
                },
                reconciledValue: {
                    title: t("Reconciled With"),
                },
            },
        ],
        [t],
    );

    const [selectedEntity, setSelectedEntity] = useState<
        IReconciliationEntity | undefined
    >(entities.find((e) => e.active));

    const handleEntityClick = useCallback(
        (entity: IReconciliationEntity) => {
            entities.map((e) => (e.active = e === entity));
            setSelectedEntity(entity);
        },
        [entities],
    );

    return (
        <Card title={t("Reconciliation")} bodyScroll={false}>
            <div className={styles.container}>
                {entities.map((entity) => (
                    <span key={entity.entity}>
                        <Button
                            onClick={handleEntityClick}
                            clickParams={[entity]}
                            variant={entity.active ? "primary" : ""}
                        >
                            {t(entity.displayName)}
                        </Button>
                        {value && value[entity.entity] > 0 && (
                            <Badge
                                position="absolute"
                                topOffset={-30}
                                leftOffset={-23}
                            >
                                {value && value[entity.entity]}
                            </Badge>
                        )}
                    </span>
                ))}
            </div>

            {selectedEntity && selectedEntity.entity === "engineers" && (
                <TabGroup>
                    <TabGroupTab header={t("Unreconciled")}>
                        <ReconciliationTable
                            onReconciliationUpdate={handleReconciliationUpdate}
                            {...selectedEntity}
                            status="unreconciled"
                        />
                    </TabGroupTab>

                    <TabGroupTab header={t("Reconciled")}>
                        <ReconciliationTable
                            onReconciliationUpdate={handleReconciliationUpdate}
                            {...selectedEntity}
                            status="reconciled"
                        />
                    </TabGroupTab>
                </TabGroup>
            )}

            {selectedEntity && selectedEntity.entity === "assets" && (
                <TabGroup>
                    <TabGroupTab header={t("Unreconciled")}>
                        <ReconciliationTable
                            onReconciliationUpdate={handleReconciliationUpdate}
                            {...selectedEntity}
                            status="unreconciled"
                        />
                    </TabGroupTab>

                    <TabGroupTab header={t("Reconciled")}>
                        <ReconciliationTable
                            onReconciliationUpdate={handleReconciliationUpdate}
                            {...selectedEntity}
                            status="reconciled"
                        />
                    </TabGroupTab>
                </TabGroup>
            )}
        </Card>
    );
};

interface IReconciliationEntity {
    active?: boolean;
    entity: ReconciliationEntityType;
    displayName: string;
    reconcile: {
        primaryTitle: string;
        secondaryTitle?: string;
    };
    reconciledValue: {
        title: string;
    };
    reconciledValidation?: {
        title: string;
    };
}

export default Reconciliation;
