import { useCallback } from "react";
import { IQualifiedSupervisorAuditQuestionsAnswersSectionProps } from ".";
import QualifiedSupervisorAuditQuestionsGridSection from "../QualifiedSupervisorAuditQuestionsGridSection";
import QualifiedSupervisorAuditQuestionsSection from "../QualifiedSupervisorAuditQuestionsSection";
import QualifiedSupervisorAuditSection from "../QualifiedSupervisorAuditSection";

const QualifiedSupervisorAuditQuestionsAnswersSection = ({
    id,
    auditId,
    title,
    isReviewed,
    isReadOnly,
    questionGroups,
    documentType,
    overriddenAnswers,
    hiddenAnswer,
    displayAsGrid = false,
    updateOverriddenAnswers,
    toggleIsReviewed,
    validate,
    canOverrideAnswers,
    addAuditJobComment,
    addAuditObservation,
    removeAuditJobComment,
    removeAuditObservation,
    addAdditionalAnswers,
}: IQualifiedSupervisorAuditQuestionsAnswersSectionProps) => {
    const toggleReview = useCallback(
        () => toggleIsReviewed(id),
        [id, toggleIsReviewed],
    );

    return (
        <QualifiedSupervisorAuditSection
            id={id}
            title={title}
            isReviewed={isReviewed}
            isReadOnly={isReadOnly || !canOverrideAnswers}
            toggleIsReviewed={toggleReview}
        >
            {displayAsGrid ? (
                <QualifiedSupervisorAuditQuestionsGridSection
                    id={id}
                    auditId={auditId}
                    questionGroups={questionGroups}
                    documentType={documentType}
                    isReadOnly={isReadOnly || !canOverrideAnswers}
                    overriddenAnswers={overriddenAnswers}
                    hiddenAnswer={hiddenAnswer}
                    updateOverriddenAnswers={updateOverriddenAnswers}
                    validate={validate}
                    addAuditJobComment={addAuditJobComment}
                    addAuditObservation={addAuditObservation}
                    removeAuditJobComment={removeAuditJobComment}
                    removeAuditObservation={removeAuditObservation}
                    addAdditionalAnswers={addAdditionalAnswers}
                />
            ) : (
                questionGroups.map((group) => (
                    <QualifiedSupervisorAuditQuestionsSection
                        key={group.id}
                        id={`${id}-${group.id}`}
                        auditId={auditId}
                        documentType={documentType}
                        isReadOnly={isReadOnly || !canOverrideAnswers}
                        questions={group.children}
                        overriddenAnswers={overriddenAnswers}
                        hiddenAnswer={hiddenAnswer}
                        updateOverriddenAnswers={updateOverriddenAnswers}
                        validate={validate}
                        addAuditJobComment={addAuditJobComment}
                        addAuditObservation={addAuditObservation}
                        removeAuditJobComment={removeAuditJobComment}
                        removeAuditObservation={removeAuditObservation}
                        addAdditionalAnswers={addAdditionalAnswers}
                    />
                ))
            )}
        </QualifiedSupervisorAuditSection>
    );
};

export default QualifiedSupervisorAuditQuestionsAnswersSection;
