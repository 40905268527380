import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { toDateTimeString } from "../../utils/dates";
import AuthorizedImage from "../AuthorizedImage";
import HeroCard from "../HeroCard";

const ImageHero = ({
    imageUrl,
    title,
    lastMaintainedDate = null,
}: IImageHeroProps) => {
    const { t } = useTranslation();
    const lastMaintainedDateTime = useMemo(
        () => (lastMaintainedDate ? toDateTimeString(lastMaintainedDate) : ""),
        [lastMaintainedDate],
    );

    return (
        <HeroCard
            title={title}
            subtitle={
                lastMaintainedDateTime
                    ? t("Last maintained on {{date}}", {
                          date: lastMaintainedDateTime,
                      })
                    : t("Not yet maintained")
            }
        >
            <AuthorizedImage image={imageUrl} />
        </HeroCard>
    );
};

interface IImageHeroProps {
    imageUrl: string;
    title: string;
    lastMaintainedDate?: Date | null;
}

export default ImageHero;
