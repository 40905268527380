import { useCallback, useContext } from "react";
import { IAuditObservation, IAuditObservationCreate } from ".";
import { PropertyCategoriesContext, UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

export const useCreateObservation = (auditId: number) => {
    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);
    const { send, loading } = useApiRequest<IAuditObservation>({});
    const sendRequest = useXTagApiRequest();

    const createObservation = useCallback(
        (observation: IAuditObservationCreate) =>
            send(
                sendRequest({
                    url: `${config.auditsApiUrl}/${auditId}/observation`,
                    method: "POST",
                    urlParams: getGlobalUrlParameters(
                        selectedCategories.map((c) => c.id),
                        activeUserParentsIds,
                    ),
                    body: observation,
                }),
            ),
        [send, sendRequest, auditId, selectedCategories, activeUserParentsIds],
    );

    return { createObservation, loading };
};
