import { ReactNode } from "react";
import { Tab } from "../../../components/TabContainer";
import styles from "./DashboardTab.module.scss";

const DashboardTab = ({
	header,
	children,
}: IDashboardTabProps) => {
	return (
		<Tab header={header}>
			<div className={styles.container}>
				{children}
			</div>
		</Tab>
	);
};

interface IDashboardTabProps {
	header: string;
	children: ReactNode;
}

export default DashboardTab;
