import { IInspectionSchedulePayload } from "../../../utils/api/answers";
import { IQualifiedSupervisorAuditAnswer } from "../QualifiedSupervisorAuditsGrouping";
import QualifiedSupervisorAuditRow from "./QualifiedSupervisorAuditRow";

export type OverriddenAnswers = Record<
    string,
    | { answer: string | IInspectionSchedulePayload; comment: string | null }
    | undefined
>;

export interface IQualifiedSupervisorAuditRowProps {
    questionId: string;
    question: string;
    answers: IQualifiedSupervisorAuditAnswer[];
    documentType: string | null;
    overriddenAnswers: OverriddenAnswers;
    isReadOnly: boolean;
    isEditing: boolean;
    errorMessage?: string;
    validateQuestion: (questionId: string, errorMessage?: string) => void;
    overrideAnswer: (
        answerId: number,
        answer?: string | IInspectionSchedulePayload,
        comment?: string,
    ) => void;
}

export default QualifiedSupervisorAuditRow;
