import { KeyboardEvent, useCallback } from "react";

const useClickableNonInteractiveElement = (onClick: () => void) => {
    const onKeyDown = useCallback(
        (e: KeyboardEvent<HTMLElement>) => {
            if (e.key === "Enter" || e.key === " ") {
                e.stopPropagation();
                e.preventDefault();
                onClick();
            }
        },
        [onClick],
    );

    return { onKeyDown };
};

export default useClickableNonInteractiveElement;
