import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
    Card,
    Grid,
    GridColumn,
    Link,
    Loading,
    UserPreferencesContext,
} from "../../../components";
import DashboardWidgetActiveFilters from "../../../components/DashboardWidgetActiveFilters";
import DashboardWidgetFilter, {
    IFilterGroup,
} from "../../../components/DashboardWidgetFilter";
import { useApplianceCounts } from "../../../utils/api/appliances";
import { usePropertyCounts } from "../../../utils/api/properties";
import { encodeUrl } from "../../../utils/url";
import styles from "./SafetyRisks.module.scss";

const SafetyRisks = () => {
    const { t } = useTranslation();
    const { preferences, updatePreferences } = useContext(
        UserPreferencesContext,
    );

    const safetyRiskLevel = useMemo(
        () => preferences.safety_risk_appliance_level || "",
        [preferences.safety_risk_appliance_level],
    );

    const { loading: applianceLoading, value: applianceCount } =
        useApplianceCounts({
            unsafe: true,
            safetyRiskLevel,
        });

    const { loading: propertiesLoading, value: propertiesCount } =
        usePropertyCounts({
            safetyRiskLevel,
        });

    const filters = useMemo(() => {
        const changeLandlordsAsset = (value: string) => {
            updatePreferences({
                ...preferences,
                safety_risk_appliance_level: value,
            }).subscribe();
        };

        const list: IFilterGroup[] = [
            {
                id: "appliance_level",
                title: t("Appliance owner"),
                value: safetyRiskLevel,
                changeValue: changeLandlordsAsset,
                allowEmptyValue: true,
                options: [
                    { label: t("Tenant"), value: "false" },
                    { label: t("Landlord"), value: "true" },
                ],
            },
        ];

        return list;
    }, [preferences, t, updatePreferences, safetyRiskLevel]);

    return (
        <Card
            title={t("Safety risks")}
            fullHeight={true}
            actions={<DashboardWidgetFilter filters={filters} />}
        >
            <div className={styles.centerContainer}>
                <Grid fullHeight={false}>
                    <GridColumn size="half">
                        <div className={styles.center}>
                            <Link
                                url={encodeUrl("/management/appliances", {
                                    safeToUseV2: [`=false`],
                                    ...(safetyRiskLevel && {
                                        landlordsApplianceV2: [
                                            `=${safetyRiskLevel}`,
                                        ],
                                    }),
                                })}
                            >
                                <span className={styles.label}>
                                    {t("Unsafe assets")}
                                </span>
                                {!applianceLoading && applianceCount ? (
                                    <div className={styles.riskCount}>
                                        {applianceCount.count}
                                    </div>
                                ) : (
                                    <Loading
                                        small={true}
                                        centerContent={true}
                                    />
                                )}
                            </Link>
                        </div>
                    </GridColumn>

                    <GridColumn size="half">
                        <div className={styles.dataContainer}>
                            <Link
                                url={encodeUrl("/management/properties", {
                                    ...(safetyRiskLevel === "true"
                                        ? {
                                              landlordUnsafeAssets: [`>=1`],
                                          }
                                        : safetyRiskLevel === "false"
                                        ? {
                                              tenantUnsafeAssets: [`>=1`],
                                          }
                                        : {
                                              totalUnsafeAssets: [`>=1`],
                                          }),
                                })}
                            >
                                <span className={styles.label}>
                                    {t("Unsafe properties")}
                                </span>
                                {!propertiesLoading && propertiesCount ? (
                                    <div className={styles.riskCount}>
                                        {propertiesCount.count}
                                    </div>
                                ) : (
                                    <Loading
                                        small={true}
                                        centerContent={true}
                                    />
                                )}
                            </Link>
                        </div>
                    </GridColumn>
                </Grid>

                <DashboardWidgetActiveFilters filters={filters} />
            </div>
        </Card>
    );
};

export default SafetyRisks;
