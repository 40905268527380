import { useTranslation } from "react-i18next";
import { IViewImageAnswerProps } from ".";
import { Button } from "../../../components";
import ImagePopup from "../../../components/ImagePopup";
import { useToggle } from "../../../hooks";

const ViewImageAnswer = ({ imageUrl, description }: IViewImageAnswerProps) => {
    const { t } = useTranslation();
    const { show, hide, visible } = useToggle();

    return (
        <>
            {imageUrl && (
                <Button size="small" onClick={show}>
                    {t("view image")}
                </Button>
            )}

            {visible && (
                <ImagePopup
                    imageUrl={imageUrl}
                    caption={description}
                    hide={hide}
                />
            )}
        </>
    );
};

export default ViewImageAnswer;
