import { useCallback } from "react";
import { IAnswer, IInspectionSchedulePayload } from "../utils/api/answers";
import { toDateString } from "../utils/dates";

const useDisplayAnswerPayload = () => {
    const displayAnswerPayload = useCallback(
        (payload: string | IInspectionSchedulePayload, answer: IAnswer) => {
            if (typeof payload === "string") {
                let result = payload.replace(/^\[?"(.+)"\]?$/, "$1");

                if (
                    answer.questionName === "E_CableTypeV2" ||
                    answer.questionName === "E_ReferenceMethodV2"
                ) {
                    result = result.split("-")[0].trim();
                }

                switch (answer.type) {
                    case "DatePicker": {
                        const date = new Date(result);
                        if (date.toString() !== "Invalid Date") {
                            result = toDateString(date);
                        }

                        break;
                    }
                    case "InspectionSchedule":
                    case "ToggleButtonGroup": {
                        if (result) {
                            const inspectionSchedule: IInspectionSchedulePayload =
                                JSON.parse(result);

                            result =
                                inspectionSchedule.Observation?.Code.toUpperCase() ??
                                inspectionSchedule.Text;
                        }

                        break;
                    }
                    default: {
                        break;
                    }
                }

                return result;
            }

            return payload.Observation?.Code.toUpperCase() ?? payload.Text;
        },
        [],
    );

    return displayAnswerPayload;
};

export default useDisplayAnswerPayload;
