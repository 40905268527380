import { useCallback, useMemo } from "react";
import { IOverrideTextAnswerProps } from ".";
import { InputField } from "../../../components";

const OverrideTextAnswer = ({
    answer,
    value,
    overrideAnswer,
}: IOverrideTextAnswerProps) => {
    const handleChange = useCallback(
        (newValue: string) =>
            overrideAnswer(answer.id, newValue ? `"${newValue}"` : undefined),
        [answer, overrideAnswer],
    );

    const selectedValue = useMemo(
        () => (value || "").replace(/^"(.+)"$/, "$1"),
        [value],
    );

    return (
        <InputField
            placeholder={answer.questionText}
            value={selectedValue}
            onChange={handleChange}
            useMargin={false}
        />
    );
};

export default OverrideTextAnswer;
