import { ReactNode } from "react";
import QualifiedSupervisorAuditSection from "./QualifiedSupervisorAuditSection";

export interface IQualifiedSupervisorAuditSectionProps {
    id: string;
    title: string;
    isReviewed: boolean;
    isReadOnly: boolean;
    toggleIsReviewed: () => void;
    children: ReactNode;
}

export default QualifiedSupervisorAuditSection;
