import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IMapProps } from ".";
import config from "../../config";
import { IGoogleMapsApi } from "./GoogleMaps";
import MapElement from "./MapElement";

const Map = (props: IMapProps) => {
    const { i18n } = useTranslation();
    const [googleMapsApi, setGoogleMapsApi] = useState<IGoogleMapsApi | null>(
        null,
    );

    const initialize = useCallback(() => {
        if ((window as any).google && (window as any).google.maps) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            setGoogleMapsApi((window as any).google.maps);
        }
    }, []);

    useEffect(() => {
        const id = "google-maps-script";
        const url = `https://maps.googleapis.com/maps/api/js?key=${config.googleMapsApiKey}&libraries=visualization&language=${i18n.language}`;

        let script = document.getElementById(id);

        if (script) {
            const currentUrl = script.getAttribute("src");

            if (currentUrl && currentUrl === url) {
                initialize();
            } else {
                // TODO: Verify that this works when the language is changed.
                script.setAttribute("src", url);
                script.onload = () => initialize();
            }
        } else {
            script = document.createElement("script");
            script.setAttribute("id", id);
            script.setAttribute("src", url);
            script.setAttribute("async", "true");
            script.setAttribute("defer", "true");
            script.onload = () => initialize();
            document.body.appendChild(script);
        }
    }, [i18n.language, initialize]);

    return (
        <>
            {googleMapsApi && (
                <MapElement {...props} googleMapsApi={googleMapsApi} />
            )}
        </>
    );
};

export default Map;
