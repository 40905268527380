import { keyBy, mapValues } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Button,
    Checkbox,
    DateRangePicker,
    Grid,
    GridColumn,
    Loading,
    Popover,
} from "../../components";
import { useToggle } from "../../hooks";
import { useLandlordPriorities } from "../../utils/api/landlords";
import { addDays, getToday, toDateString } from "../../utils/dates";
import CustomerVisits from "./CustomerVisits";
import DeliveryPercentage from "./DeliveryPercentage";
import MissedSlaComplete from "./MissedSlaComplete";
import MissedSlaIncomplete from "./MissedSlaIncomplete";
import MultipleRepairs from "./MultipleRepairs";
import styles from "./RepairDashboard.module.scss";
import SlaJobsCompleted from "./SlaJobsCompleted";
import UpcomingJobsDays from "./UpcomingJobsDays";
import UpcomingJobsHours from "./UpcomingJobsHours";
import UtaProperty from "./UtaProperty";

const RepairDashboard = () => {
    const { t } = useTranslation();
    const [selectedPriorities, setSelectedPriorities] = useState(["All"]);
    const [startDate, setStartDate] = useState(addDays(getToday(), -6));
    const [endDate, setEndDate] = useState(addDays(getToday(), 1));

    const landlordPriorities = useLandlordPriorities();

    const handleDatesSelected = useCallback((fromDate: Date, toDate: Date) => {
        setStartDate(fromDate);
        setEndDate(toDate);
    }, []);

    const [priorities, setPriorities] = useState<IPriorityOptions>({});
    const [prioritiesCount, setPrioritiesCount] = useState(0);

    useEffect(() => {
        let count = 0;
        const selected: string[] = [];

        const keys = Object.keys(priorities);
        for (const key of keys) {
            if (key !== "All" && priorities[key]) {
                count = count + 1;
                selected.push(key);
            }
        }

        if (keys.length > 0) {
            if (count === keys.length - 1) {
                setSelectedPriorities(["All"]);
            } else {
                setSelectedPriorities(selected);
            }
        }

        setPrioritiesCount(count);
    }, [priorities]);

    useEffect(() => {
        if (landlordPriorities.value.length > 0) {
            setPriorities({
                All: true,
                ...mapValues(
                    keyBy(
                        landlordPriorities.value,
                        (priority) => priority.priority,
                    ),
                    () => true,
                ),
            });
        }
    }, [landlordPriorities.value]);

    const handlePriorityChange = useCallback(
        (checked: boolean, value: string) => {
            const newPriorities = { ...priorities };

            if (value === "All") {
                for (const key of Object.keys(priorities)) {
                    newPriorities[key] = checked;
                }
            } else {
                newPriorities[value] = checked;

                let count = 0;
                const keys = Object.keys(priorities);
                for (const key of keys) {
                    if (key !== "All" && newPriorities[key]) {
                        count = count + 1;
                    }
                }

                newPriorities.All = count === keys.length - 1;
            }

            setPriorities(newPriorities);
        },
        [priorities],
    );

    const {
        toggle: datePickerToggle,
        hide: datePickerHide,
        visible: datePickerVisible,
    } = useToggle();
    const datePickerButton = useRef<HTMLButtonElement>(null);

    const {
        toggle: prioritiesPopoverToggle,
        hide: prioritiesPopoverHide,
        visible: prioritiesPopoverVisible,
    } = useToggle();
    const prioritiesPopoverButton = useRef<HTMLButtonElement>(null);

    return landlordPriorities.loaded ? (
        <>
            <Button
                onClick={prioritiesPopoverToggle}
                setRef={prioritiesPopoverButton}
                cssRules={{ marginBottom: "26px" }}
            >
                {t("{{count}} priorities selected", {
                    count: prioritiesCount,
                })}
            </Button>

            {prioritiesPopoverVisible && (
                <Popover
                    anchorElement={prioritiesPopoverButton}
                    hide={prioritiesPopoverHide}
                >
                    {Object.keys(priorities).map((priority) => (
                        <Checkbox
                            key={priority}
                            value={priority}
                            checked={priorities[priority]}
                            onChange={handlePriorityChange}
                        >
                            {t(priority)}
                        </Checkbox>
                    ))}
                </Popover>
            )}

            <UpcomingJobsHours priorities={selectedPriorities} />

            <UpcomingJobsDays priorities={selectedPriorities} />

            <Grid>
                <GridColumn size="half">
                    <MissedSlaIncomplete priorities={selectedPriorities} />
                </GridColumn>
            </Grid>

            <div className={styles.datepicker}>
                <Button onClick={datePickerToggle} setRef={datePickerButton}>
                    {toDateString(startDate)} - {toDateString(endDate)}
                </Button>

                {datePickerVisible && (
                    <Popover
                        anchorElement={datePickerButton}
                        hide={datePickerHide}
                    >
                        <DateRangePicker
                            onDatesSelected={handleDatesSelected}
                            selectedFromDate={startDate}
                            selectedToDate={endDate}
                        />
                    </Popover>
                )}
            </div>

            <SlaJobsCompleted
                priorities={selectedPriorities}
                startDate={startDate}
                endDate={endDate}
            />

            <Grid>
                <GridColumn size="eightyPercent">
                    <MissedSlaComplete
                        priorities={selectedPriorities}
                        startDate={startDate}
                        endDate={endDate}
                    />
                </GridColumn>
                <GridColumn size="twentyPercent">
                    <UtaProperty
                        priorities={selectedPriorities}
                        startDate={startDate}
                        endDate={endDate}
                    />
                </GridColumn>
            </Grid>

            <DeliveryPercentage
                priorities={selectedPriorities}
                startDate={startDate}
                endDate={endDate}
            />

            <Grid>
                <GridColumn size="eightyPercent">
                    <MultipleRepairs startDate={startDate} endDate={endDate} />
                </GridColumn>
                <GridColumn size="twentyPercent">
                    <CustomerVisits startDate={startDate} endDate={endDate} />
                </GridColumn>
            </Grid>
        </>
    ) : (
        <Loading />
    );
};

interface IPriorityOptions {
    [key: string]: boolean;
}

export default RepairDashboard;
