import { useCallback, useContext } from "react";
import { PropertyCategoriesContext, UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const useDocumentsRangeDownload = () => {
    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);
    const { send, loading } = useApiRequest<string>({});
    const sendRequest = useXTagApiRequest();

    const downloadDocuments = useCallback(
        (startDate: Date, endDate: Date, paperworkType?: string) =>
            send(
                sendRequest({
                    url: `${config.lgsrsApiUrl}/documents/v2/download/daterange`,
                    method: "POST",
                    urlParams: {
                        ...getGlobalUrlParameters(
                            selectedCategories.map((c) => c.id),
                            activeUserParentsIds,
                        ),
                        startDate: [startDate.toISOString()],
                        endDate: [endDate.toISOString()],
                        paperworkType: [paperworkType ?? ""],
                    },
                }),
            ),
        [activeUserParentsIds, selectedCategories, send, sendRequest],
    );

    return { downloadDocuments, loading };
};

export default useDocumentsRangeDownload;
