import { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IUserParentRolesProps } from ".";
import { Button, Checkbox, Icon, UserContext } from "../../../components";
import { IUpdateUserParentRoles } from "../../../utils/api/users";
import { UpdateUserContext } from "../UpdateUserContext";
import styles from "./UserParentRoles.module.scss";

const UserParentRoles = ({ userParent, roles }: IUserParentRolesProps) => {
    const { t } = useTranslation();
    const { user } = useContext(UserContext);
    const { userParentRoles, setUserParentRoles } =
        useContext(UpdateUserContext);

    const userParentName = useMemo(
        () =>
            user.userParents.find((u) => u.userParentId === userParent.id)
                ?.landlord?.name ?? "",
        [user, userParent],
    );

    const handleRemoveUserParentClick = useCallback(() => {
        setUserParentRoles(
            userParentRoles.filter((u) => u.id !== userParent.id),
        );
    }, [setUserParentRoles, userParent, userParentRoles]);

    const handleRoleChange = useCallback(
        (checked: boolean, value: number) => {
            const newUserParent: IUpdateUserParentRoles = {
                id: userParent.id,
                roles: [...userParent.roles],
            };

            if (checked) {
                newUserParent.roles.push(value);
            } else {
                newUserParent.roles = newUserParent.roles.filter(
                    (id) => id !== value,
                );
            }

            const index = userParentRoles.findIndex(
                (u) => u.id === newUserParent.id,
            );

            if (index > -1) {
                const list = [...userParentRoles];
                list.splice(index, 1, newUserParent);

                setUserParentRoles(list);
            } else {
                setUserParentRoles([...userParentRoles, newUserParent]);
            }
        },
        [setUserParentRoles, userParent, userParentRoles],
    );

    return (
        <div className={styles.container}>
            <div className={styles.userParentContainer}>
                <div className={styles.userParent}>{userParentName}</div>
                <div className={styles.removeUserParent}>
                    <Button
                        onClick={handleRemoveUserParentClick}
                        variant="error"
                    >
                        <Icon icon="remove" ariaLabel={t("Delete")} size={16} />
                    </Button>
                </div>
            </div>

            <div className={styles.rolesContainer}>
                {roles.map((role) => (
                    <Checkbox
                        key={role.id}
                        checked={userParent.roles.includes(role.id)}
                        onChange={handleRoleChange}
                        value={role.id}
                        useMargin={false}
                    >
                        {t(role.displayName)}
                    </Checkbox>
                ))}
            </div>
        </div>
    );
};

export default UserParentRoles;
