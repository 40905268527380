import { useCallback } from "react";
import { IOtherDocumentsProps } from ".";
import { Button } from "../../../components";
import styles from "./OtherDocuments.module.scss";

const OtherDocuments = ({
    documents,
    showOtherDocument,
}: IOtherDocumentsProps) => {
    const viewButtonHandler = useCallback(
        (identifier: string) => {
            showOtherDocument(identifier);
        },
        [showOtherDocument],
    );

    return (
        <div className={styles.list}>
            {documents.map((document, index) => (
                <div key={index} className={styles.documentTitle}>
                    <Button
                        onClick={viewButtonHandler}
                        clickParams={[document.documentUrl]}
                    >
                        {document.documentName}
                    </Button>
                </div>
            ))}
        </div>
    );
};

export default OtherDocuments;
