import { useContext, useEffect, useMemo } from "react";
import { map } from "rxjs";
import { IAgreedLimitationsContext, IAgreedLimitationsProviderProps } from ".";
import { UserContext } from "../../../components";
import config from "../../../config";
import { ILimitation, ILimitationSet } from "../../../utils/api/landlords";
import useApiValue from "../../../utils/api/useApiValue";
import {
    getGlobalUrlParameters,
    useXTagApiRequest,
} from "../../../utils/request";
import { AgreedLimitationsContext } from "./AgreedLimitationsContext";

export const AgreedLimitationsProvider = ({
    canLoadTypes,
    children,
    landlordId,
    contractorId,
}: IAgreedLimitationsProviderProps) => {
    const { activeUserParentsIds } = useContext(UserContext);
    const { value, send, reset } = useApiValue<ILimitation[]>({
        initialValue: [],
    });
    const sendRequest = useXTagApiRequest();

    useEffect(() => {
        if (canLoadTypes && contractorId !== undefined) {
            const subscription = send(
                sendRequest<ILimitationSet, unknown>({
                    url: `${config.landlordsApiUrl}/limitationsets`,
                    method: "GET",
                    urlParams: {
                        landlordId: [landlordId.toString()],
                        contractorId: [contractorId.toString()],
                        ...getGlobalUrlParameters([], activeUserParentsIds),
                    },
                }).pipe(map((s) => s.limitations.flatMap((l) => l))),
            ).subscribe();

            return () => {
                if (subscription) {
                    subscription.unsubscribe();
                }
            };
        } else {
            reset();
        }
    }, [
        activeUserParentsIds,
        canLoadTypes,
        contractorId,
        landlordId,
        reset,
        send,
        sendRequest,
    ]);

    const context = useMemo(() => {
        const temp: IAgreedLimitationsContext = {
            agreedLimitations: value,
        };

        return temp;
    }, [value]);

    return (
        <AgreedLimitationsContext.Provider value={context}>
            {children}
        </AgreedLimitationsContext.Provider>
    );
};
