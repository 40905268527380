import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card, TabGroup, TabGroupTab } from "../../components";
import useActiveTabFromUrl from "../../hooks/useActiveTabFromUrl";
import { AuditStatus, useAuditCounts } from "../../utils/api/audits";
import QualifiedSupervisorAuditsTable from "./QualifiedSupervisorAuditsTable";

const tabs: AuditStatus[] = ["Waiting", "Hold", "Passed", "Failed"];

const QualifiedSupervisorAudits = () => {
    const { t } = useTranslation();
    const { value, refresh } = useAuditCounts();
    const [activeTab, setActiveTab] = useActiveTabFromUrl(tabs);

    const refreshCounts = useCallback(() => refresh().subscribe(), [refresh]);

    const tabGroups = useMemo(() => {
        const dictionary: { [key: string]: number | undefined } = {};

        for (const record of value) {
            dictionary[record.statusLabel] = record.count;
        }

        return tabs.map((status) => {
            const count = dictionary[status];

            return {
                header: t(status) + (count ? ` (${count})` : ""),
                status,
            };
        });
    }, [t, value]);

    return (
        <Card title={t("Qualified Supervisor Documents")} padding={false}>
            <TabGroup currentTab={activeTab} onActivate={setActiveTab}>
                {tabGroups.map((tab) => (
                    <TabGroupTab header={tab.header} key={tab.status}>
                        <QualifiedSupervisorAuditsTable
                            status={tab.status}
                            refreshCounts={refreshCounts}
                        />
                    </TabGroupTab>
                ))}
            </TabGroup>
        </Card>
    );
};

export default QualifiedSupervisorAudits;
