import { useTranslation } from "react-i18next";
import { ISortingComponentProps } from ".";
import { Button, Icon } from "../..";
import { useCssClasses } from "../../../hooks";
import styles from "./Sorting.module.scss";

const Sorting = ({
    property,
    sort,
    sortDirection,
    sortProperty,
    visible,
    title = "",
}: ISortingComponentProps) => {
    const { t } = useTranslation();

    const cssClasses = useCssClasses(
        styles.container,
        visible ? "" : styles.hidden,
    );

    return (
        <div className={cssClasses}>
            <Button
                onClick={sort}
                clickParams={[property, "asc"]}
                size="small"
                variant={
                    property === sortProperty && sortDirection === "asc"
                        ? "primary"
                        : ""
                }
            >
                <Icon
                    icon="caret-up"
                    ariaLabel={t(`Sort ${title ?? property} ascending`)}
                />
            </Button>
            <Button
                onClick={sort}
                clickParams={[property, "desc"]}
                size="small"
                variant={
                    property === sortProperty && sortDirection === "desc"
                        ? "primary"
                        : ""
                }
            >
                <Icon
                    icon="caret-down"
                    ariaLabel={t(`Sort ${title ?? property} descending`)}
                />
            </Button>
        </div>
    );
};

export default Sorting;
