import { useCallback, useContext, useState } from "react";
import { switchMap } from "rxjs";
import { useToggle } from ".";
import { PropertyCategoriesContext, UserContext } from "../components";
import { IVisibleColumn } from "../components/Table/TableHeader";
import { getFiltersUrlParams } from "../utils/api/getFiltersUrlParams";
import { useFileUrl } from "../utils/api/misc";
import useApiRequest from "../utils/api/useApiRequest";
import { IUrlParameters } from "../utils/url";
import { IFilters } from "../utils/api/useFilterable";
import { getGlobalUrlParameters, useXTagApiRequest } from "../utils/request";

const useDownloadCsv = ({
    exportDataUrl,
    filters,
    filterColumns = () => true,
    search,
}: IDownloadCsvParams) => {
    const { show, hide, visible } = useToggle();
    const [downloadUrl, setDownloadUrl] = useState("");
    const [downloading, setDownloading] = useState(false);

    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);
    const { send } = useApiRequest<string>({});
    const sendRequest = useXTagApiRequest();

    const getFileUrl = useFileUrl();
    const handleDownloadClick = useCallback(
        (visibleColumns: IVisibleColumn[]) => {
            show();
            setDownloadUrl("");
            setDownloading(true);

            send(
                sendRequest({
                    url: exportDataUrl,
                    method: "POST",
                    urlParams: {
                        ...getFiltersUrlParams(filters),
                        ...getGlobalUrlParameters(
                            selectedCategories.map((c) => c.id),
                            activeUserParentsIds,
                        ),
                        ...search,
                    },
                    body: visibleColumns.filter(filterColumns),
                }),
            )
                // TODO: Wait for the user to click the download link before signing the url to make sure that it is not expired.
                .pipe(switchMap((url) => getFileUrl(url)))
                .subscribe((url) => {
                    setDownloadUrl(url);
                    setDownloading(false);
                });
        },
        [
            activeUserParentsIds,
            exportDataUrl,
            filterColumns,
            filters,
            getFileUrl,
            selectedCategories,
            send,
            sendRequest,
            show,
            search,
        ],
    );

    return { handleDownloadClick, hide, visible, downloadUrl, downloading };
};

export interface IDownloadCsvParams {
    exportDataUrl: string;
    filters: IFilters;
    filterColumns?: (column: IVisibleColumn) => boolean;
    search?: IUrlParameters;
}

export default useDownloadCsv;
