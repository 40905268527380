import { useEffect, useMemo } from "react";
import {
    IAnswer,
    IInspectionSchedulePayload,
    useQuestions,
} from "../../../utils/api/answers";
import {
    ICreateQuestionAnswer,
    useCreateAnswers,
} from "../../../utils/api/audits";
import { IAnswerSectionGroup } from "../QualifiedSupervisorAudit";
import { IQualifiedSupervisorAuditAnswer } from "../QualifiedSupervisorAuditsGrouping";

const useUnansweredQuestions = (
    auditId: number,
    isEditing: boolean,
    questions: IAnswerSectionGroup<IQualifiedSupervisorAuditAnswer>[],
    addAdditionalAnswers: (answers: IAnswer[]) => void,
) => {
    const questionSetIds = useMemo(() => {
        if (isEditing) {
            const questionSets = questions
                .flatMap((q) => q.children.map((c) => c.answer))
                .reduce<Record<string, IAnswer>>(
                    (d, a) => ({
                        ...d,
                        [a.questionSet.questionsetId]: a,
                    }),
                    {},
                );

            return Object.keys(questionSets);
        }

        return [];
    }, [isEditing, questions]);

    const { createAnswers } = useCreateAnswers(auditId);
    const { value } = useQuestions(questionSetIds);

    useEffect(() => {
        const list: ICreateQuestionAnswer[] = [];

        if (isEditing) {
            const answer = questions.flatMap((q) =>
                q.children.map((a) => a.answer),
            )[0];

            if (answer) {
                const unansweredQuestions = value.filter((v) =>
                    questions.every((q) =>
                        q.children.every(
                            (c) =>
                                c.answer.questionId !== v.questionId &&
                                [
                                    "TextField",
                                    "TextArea",
                                    "AutoComplete",
                                    "ButtonAddRepeater",
                                    "DatePicker",
                                    "MultiSelect",
                                    "Dropdown",
                                    "FilteredDropdown",
                                    "Boolean",
                                    "RadioButtonGroup",
                                    "Reading",
                                    "InspectionSchedule",
                                    "ToggleButtonGroup",
                                ].includes(c.answer.type),
                        ),
                    ),
                );

                for (const unansweredQuestion of unansweredQuestions) {
                    switch (unansweredQuestion.type) {
                        case "DatePicker":
                        case "Boolean":
                        case "PhotoButton": {
                            list.push(
                                {
                                    taskId: answer.taskId,
                                    questionSetId:
                                        unansweredQuestion.questionSetId,
                                    questionId: unansweredQuestion.questionId,
                                    questionName: `E_${unansweredQuestion.questionKey}`,
                                    payload: '""',
                                },
                                {
                                    taskId: answer.taskId,
                                    questionSetId:
                                        unansweredQuestion.questionSetId,
                                    questionId: unansweredQuestion.questionId,
                                    questionName: `E_${unansweredQuestion.questionKey}Result`,
                                    payload: '""',
                                },
                            );
                            break;
                        }
                        case "Reading": {
                            list.push(
                                {
                                    taskId: answer.taskId,
                                    questionSetId:
                                        unansweredQuestion.questionSetId,
                                    questionId: unansweredQuestion.questionId,
                                    questionName: `E_${unansweredQuestion.questionKey}`,
                                    payload: '""',
                                },
                                {
                                    taskId: answer.taskId,
                                    questionSetId:
                                        unansweredQuestion.questionSetId,
                                    questionId: unansweredQuestion.questionId,
                                    questionName: `E_${unansweredQuestion.questionKey}Item`,
                                    payload: '""',
                                },
                            );
                            break;
                        }
                        case "InspectionSchedule":
                        case "ToggleButtonGroup": {
                            const payload: IInspectionSchedulePayload = {
                                Text: "",
                            };

                            list.push({
                                taskId: answer.taskId,
                                questionSetId: unansweredQuestion.questionSetId,
                                questionId: unansweredQuestion.questionId,
                                questionName: `E_${unansweredQuestion.questionKey}`,
                                payload: JSON.stringify(payload),
                            });
                            break;
                        }
                        default: {
                            list.push({
                                taskId: answer.taskId,
                                questionSetId: unansweredQuestion.questionSetId,
                                questionId: unansweredQuestion.questionId,
                                questionName: `E_${unansweredQuestion.questionKey}`,
                                payload: '""',
                            });
                            break;
                        }
                    }
                }

                if (list.length > 0) {
                    createAnswers(list).subscribe((createdAnswers) => {
                        addAdditionalAnswers(
                            createdAnswers.map((a) => ({
                                answerOptions: a.answerOptions,
                                id: a.id,
                                isAnsweredByAuditor: true,
                                order: a.order,
                                questionSetOrder: a.questionSetOrder,
                                taskEndDate: a.taskEndDate,
                                taskId: a.taskId,
                                override: null,
                                payload: a.payload,
                                questionId: a.questionId,
                                questionKey: a.questionKey,
                                questionName: a.questionName,
                                questionText: a.questionText,
                                questionSet: a.questionSet,
                                readOnly: false,
                                asset: answer.asset,
                                taskType: answer.taskType,
                                type: a.type,
                            })),
                        );
                    });
                }
            }
        }
    }, [addAdditionalAnswers, createAnswers, isEditing, questions, value]);
};

export default useUnansweredQuestions;
