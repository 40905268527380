import { useCallback, useContext } from "react";
import { UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const useMatchAllocatedUta = () => {
    const { activeUserParentsIds } = useContext(UserContext);
    const { send } = useApiRequest({});
    const sendRequest = useXTagApiRequest();

    const matchAllocatedUta = useCallback(
        (allocatedJobId: string, utaId: number) =>
            send(
                sendRequest({
                    url: `${config.allocatedJobsApiUrl}/${allocatedJobId}/matchuta`,
                    method: "POST",
                    urlParams: getGlobalUrlParameters([], activeUserParentsIds),
                    body: { utaId },
                }),
            ),
        [activeUserParentsIds, send, sendRequest],
    );

    return { matchAllocatedUta };
};

export default useMatchAllocatedUta;
