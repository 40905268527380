import { useContext, useEffect } from "react";
import { IPropertyNote } from ".";
import { PropertyCategoriesContext, UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiValue from "../useApiValue";

const usePropertyNotes = (id: Number) => {
    const { activeUserParentsIds } = useContext(UserContext);
    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { value, loaded, send } = useApiValue<IPropertyNote[]>({
        initialValue: [],
        discardPreviousValue: true,
    });
    const sendRequest = useXTagApiRequest();

    useEffect(() => {
        if (id) {
            const subscription = send(
                sendRequest({
                    url: `${config.propertiesApiUrl}/${id}/notes`,
                    method: "GET",
                    urlParams: getGlobalUrlParameters(
                        selectedCategories.map((c) => c.id),
                        activeUserParentsIds,
                    ),
                }),
            ).subscribe();

            return () => {
                if (subscription) {
                    subscription.unsubscribe();
                }
            };
        }
    }, [id, activeUserParentsIds, selectedCategories, send, sendRequest]);

    return { value, loaded };
};

export default usePropertyNotes;
