import { ISingleLineChartData } from "../Charts/SingleLineChart";
import LineChartWithTotal from "./LineChartWithTotal";

export interface ILineChartWithTotal {
    label: string;
    count: number;
    chart: ISingleLineChartData[];
}

export default LineChartWithTotal;
