import { useContext, useEffect } from "react";
import { IMissedSlaCounts } from ".";
import { PropertyCategoriesContext, UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiValue from "../useApiValue";

const useAwaitingAllocationMissedSLACompletedCounts = ({
    jobPriorities,
    startDate,
    endDate,
}: IuseAwaitingAllocationMissedSLACompletedCounts) => {
    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);
    const { value, loaded, send } = useApiValue<IMissedSlaCounts>({});
    const sendRequest = useXTagApiRequest();

    useEffect(() => {
        const subscription = send(
            sendRequest({
                url: `${config.allocatedJobsApiUrl}/awaitingallocation/v2/slamissed/counts`,
                method: "GET",
                urlParams: {
                    ...(!jobPriorities.includes("All") && {
                        jobPriority: [
                            jobPriorities.map((j) => `=${j}`).join("{OR}"),
                        ],
                    }),
                    slaDate: [
                        `>=${startDate.toISOString()}{AND}<${endDate.toISOString()}`,
                    ],
                    slaMet: ["=false"],
                    status: ["=Completed"],
                    ...getGlobalUrlParameters(
                        selectedCategories.map((c) => c.id),
                        activeUserParentsIds,
                    ),
                },
            }),
        ).subscribe();

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [
        activeUserParentsIds,
        selectedCategories,
        jobPriorities,
        startDate,
        endDate,
        send,
        sendRequest,
    ]);

    return {
        value,
        loaded,
    };
};

interface IuseAwaitingAllocationMissedSLACompletedCounts {
    jobPriorities: string[];
    startDate: Date;
    endDate: Date;
}

export default useAwaitingAllocationMissedSLACompletedCounts;
