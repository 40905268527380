import { ReactNode } from "react";
import QualifiedSupervisorAuditSectionHeader from "./QualifiedSupervisorAuditSectionHeader";

export interface IQualifiedSupervisorAuditSectionHeaderProps {
    id: string;
    title: string;
    isReviewed: boolean;
    paddingLeft?: boolean;
    children?: ReactNode;
}

export default QualifiedSupervisorAuditSectionHeader;
