import { getValueFromObject } from "../values";
import { IFilters } from "./useFilterable";

const filterLocal = (filters: IFilters, entity: any) => {
    const results: boolean[] = [];

    for (const key of Object.keys(filters)) {
        const keyFilters: boolean[] = [];

        for (const filter of filters[key].filters) {
            if (filter.function === "" || filter.function === "=") {
                keyFilters.push(
                    getValueFromObject(key, entity).toString() === filter.value,
                );
            } else if (filter.function === "<") {
                keyFilters.push(
                    getValueFromObject(key, entity).toString() < filter.value,
                );
            } else if (filter.function === "<=") {
                keyFilters.push(
                    getValueFromObject(key, entity).toString() <= filter.value,
                );
            } else if (filter.function === ">") {
                keyFilters.push(
                    getValueFromObject(key, entity).toString() > filter.value,
                );
            } else if (filter.function === ">=") {
                keyFilters.push(
                    getValueFromObject(key, entity).toString() >= filter.value,
                );
            } else if (filter.function === "!=") {
                keyFilters.push(
                    getValueFromObject(key, entity).toString() !== filter.value,
                );
            }
        }

        if (filters[key].operator === "{OR}") {
            results.push(keyFilters.some((a) => a));
        } else {
            results.push(keyFilters.every((a) => a));
        }
    }

    return results.every((a) => a);
};

export default filterLocal;
