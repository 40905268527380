import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { noop } from "rxjs";
import { UserContext, ValidationError } from "..";
import Select, { ISelectOption } from "../Select";

const LandlordSelector = ({
    label = "Please select a landlord",
    onChange = noop,
    value = "",
    isValid,
    error,
}: ILandlordSelectorProps) => {
    const { t } = useTranslation();
    const { user } = useContext(UserContext);

    const landlordSelectOptions = useMemo<ISelectOption[]>(() => {
        return user.userParents.map((u) => ({
            label: u.landlord?.name ?? "",
            value: u.userParentId.toString(),
        }));
    }, [user]);

    return (
        <>
            <Select
                label={t(label)}
                options={landlordSelectOptions}
                onChange={onChange}
                value={value}
                allowEmpty={true}
            />
            <ValidationError isValid={isValid} error={error} />
        </>
    );
};

interface ILandlordSelectorProps {
    label?: string;
    value?: string;
    onChange?: (landlordId: string) => void;
    isValid?: boolean;
    error?: string;
}

export default LandlordSelector;
