import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IAccordionProps } from ".";
import { Icon } from "..";
import styles from "./Accordion.module.scss";

const Accordion = ({ content }: IAccordionProps) => {
    const { t } = useTranslation();

    const [expandedStates, setExpandedStates] = useState<{
        [key: string]: boolean;
    }>({});

    const [contentRefs, setContentRefs] = useState<{
        [key: string]: HTMLDivElement;
    }>({});

    useEffect(() => {
        const statestmp: { [key: string]: boolean } = {};

        Object.entries(content).forEach(([key, value]) => {
            statestmp[key] = value.expanded;
        });

        setExpandedStates(statestmp);
    }, [content]);

    const headerClickHandler = useCallback(
        (accordionId: string) => {
            const item = content[accordionId];

            const statestmp = { ...expandedStates };
            statestmp[accordionId] = !statestmp[accordionId];

            if (item) {
                item.expanded = statestmp[accordionId];

                item.expandedHandler &&
                    item.expandedHandler(accordionId, item.expanded);

                Object.entries(content)
                    .filter(
                        ([key, value]) =>
                            item.exclusiveExpandedKey &&
                            item.exclusiveExpandedKey ===
                                value.exclusiveExpandedKey &&
                            key !== accordionId &&
                            value.expanded === true,
                    )
                    .forEach(([key, value]) => {
                        value.expanded = false;
                        statestmp[key] = value.expanded;
                        value.expandedHandler &&
                            value.expandedHandler(key, false);
                    });
            }

            setExpandedStates(statestmp);
        },
        [content, expandedStates],
    );

    const expandedStyle = useCallback(
        (id: string) => {
            return `${styles.accordionContent} ${
                expandedStates[id] ? styles.expanded : styles.collapsed
            }`;
        },
        [expandedStates],
    );

    const headerStyle = useCallback(
        (id: string) => {
            const item = content[id];
            let active = false;

            if (item && item.activePointerHandler) {
                active = item.activePointerHandler(id);
            }

            return `${styles.accordionHeader} ${
                active ? styles.activeContent : ""
            }`;
        },
        [content],
    );

    const onRefSet = useCallback(
        (instance: HTMLDivElement | null) => {
            if (instance !== null) {
                contentRefs[instance.id] = instance;
                setContentRefs(contentRefs);
            }
        },
        [contentRefs],
    );

    return (
        <div className={styles.accordionSection}>
            {Object.entries(content).map(([key, value]) => {
                return (
                    <div className={styles.accordionItem} key={key}>
                        {value.content ? (
                            <button
                                type="button"
                                onClick={() => headerClickHandler(key)}
                                className={headerStyle(key)}
                            >
                                {typeof value.header === "string" ? (
                                    <>
                                        <div className={styles.plainTextHeader}>
                                            {value.header}
                                        </div>
                                        <div
                                            className={`${styles.chevron} ${
                                                expandedStates[key]
                                                    ? styles.rotate
                                                    : ""
                                            }`}
                                        >
                                            <Icon
                                                ariaLabel={t("Expand")}
                                                icon="chevron-down"
                                                size={30}
                                            />
                                        </div>
                                    </>
                                ) : (
                                    value.header
                                )}
                            </button>
                        ) : (
                            <div className={styles.sectionHeading}>
                                {value.header}
                            </div>
                        )}

                        <div
                            className={expandedStyle(key)}
                            ref={onRefSet}
                            id={key}
                            style={{
                                maxHeight: expandedStates[key]
                                    ? contentRefs[key].scrollHeight + 30
                                    : 0,
                            }}
                        >
                            <div className={styles.accordionItemContent}>
                                {value.content}
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default Accordion;
