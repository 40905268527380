import { NotificationCategory } from "../../../utils/api/misc";
import NotificationTab from "./NotificationTab";

export interface INotificationTabProps {
    notificationCategory: NotificationCategory;
    updateNotificationCount: (all: boolean) => void;
    read?: boolean;
}

export default NotificationTab;
