import { IAnswer } from "../../../utils/api/answers";
import {
    IAudit,
    IAuditComment,
    IAuditJobComment,
    IAuditObservation,
} from "../../../utils/api/audits";
import QualifiedSupervisorAuditsGrouping from "./QualifiedSupervisorAuditsGrouping";

interface IGroupValue<T> {
    order: number;
    title: string;
    displayChildrenOnMenu?: boolean;
    displayAsGrid?: boolean;
    link?: string;
    value: T;
}

interface IRootGroupValue<T> extends IGroupValue<T> {
    inspectionScheduleSection?: string;
    inspectionScheduleItem?: string;
}

export interface IQualifiedSupervisorAuditAnswer {
    answer: IAnswer;
    relatedAnswerIds: number[];
}

export interface IGroup<T> {
    [key: string]: IGroupValue<T>;
}

export interface IRootGroup<T> {
    [key: string]: IRootGroupValue<T>;
}

export interface IQualifiedSupervisorAuditsGroupingProps {
    audit: IAudit;
    isReadOnly: boolean;
    onSaveAudit: () => void;
    onHoldAudit: () => void;
    createComment: (comment: IAuditComment) => void;
    canCompleteAudit?: boolean;
    isSaveDisabled?: boolean;
    canOverrideAnswers?: boolean;
    addAuditJobComment: (jobComment: IAuditJobComment) => void;
    removeAuditJobComment: (jobCommentId: string) => void;
    addAuditObservation: (auditObservation: IAuditObservation) => void;
    removeAuditObservation: (auditObservationId: string) => void;
    sidebarVisible: boolean;
}

export default QualifiedSupervisorAuditsGrouping;
