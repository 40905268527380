import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { KeyValueWidget } from "../../../components";
import { useAwaitingAllocationMissedSLACompletedCounts } from "../../../utils/api/allocatedJobs";
import { encodeUrl } from "../../../utils/url";

const MissedSlaComplete = ({
    priorities,
    startDate,
    endDate,
}: IMissedSlaCompleteProps) => {
    const { t } = useTranslation();

    const { value, loaded } = useAwaitingAllocationMissedSLACompletedCounts({
        jobPriorities: priorities,
        startDate,
        endDate,
    });

    const summary = useMemo(() => {
        return [
            {
                key: t("1 day late"),
                value: value?.countDays1,
            },
            {
                key: t("2 day late"),
                value: value?.countDays2,
            },
            {
                key: t("3+ day late"),
                value: value?.countDays3Plus,
            },
        ];
    }, [t, value]);

    const url = useMemo(
        () =>
            encodeUrl("/jobs/awaiting-allocation", {
                activeTab: ["completed"],
                slaMet: ["=false"],
                slaDate: [
                    `>=${startDate.toISOString()}{AND}<${endDate.toISOString()}`,
                ],
                ...(!priorities.includes("All") && {
                    jobPriority: [priorities.map((j) => `=${j}`).join("{OR}")],
                }),
            }),
        [endDate, priorities, startDate],
    );

    return (
        <KeyValueWidget
            title={t("SLA missed and complete")}
            summary={summary}
            url={url}
            tooltipText={t(
                "Total allocated jobs linked to an awaiting allocation job, total completed with SLA, percentage of missed SLA jobs within the date filters selected",
            )}
            loaded={loaded}
        />
    );
};

interface IMissedSlaCompleteProps {
    priorities: string[];
    startDate: Date;
    endDate: Date;
}

export default MissedSlaComplete;
