import { KeyboardEvent, useCallback, useEffect, useState } from "react";
import { ISelectOption } from "../components/Select";

const useAccessibleCustomSelect = (
    hasInputField: boolean,
    value: string,
    options: ISelectOption[],
    isVisible: boolean,
    show: () => void,
    hide: () => void,
    onClick: (v: string) => void,
    resetFocusOnChange: boolean,
) => {
    const [focusedIndex, setFocusedIndex] = useState(0);

    useEffect(() => {
        if (resetFocusOnChange && isVisible) {
            const index = Math.max(
                options.map((o) => o.value).indexOf(value),
                0,
            );

            setFocusedIndex(index);
        }
    }, [isVisible, options, resetFocusOnChange, value]);

    const onKeyDown = useCallback(
        (e: KeyboardEvent<HTMLInputElement>) => {
            if (e.key === "Escape") {
                e.stopPropagation();
                hide();
            } else if (isVisible && options.length > 0) {
                if (e.key === "ArrowUp") {
                    e.preventDefault();
                    setFocusedIndex((i) =>
                        i === 0 ? options.length - 1 : i - 1,
                    );
                } else if (e.key === "ArrowDown") {
                    e.preventDefault();
                    setFocusedIndex((i) =>
                        i === options.length - 1 ? 0 : i + 1,
                    );
                } else if (e.key === "Enter" || e.key === "Tab") {
                    e.stopPropagation();
                    e.preventDefault();
                    onClick(options[focusedIndex].value);
                } else if (e.key === " " && !hasInputField) {
                    e.preventDefault();
                }
            } else if (e.key === "Enter" || e.key === " ") {
                e.stopPropagation();
                e.preventDefault();
                show();
            }
        },
        [isVisible, options, hide, onClick, focusedIndex, hasInputField, show],
    );

    return { onKeyDown, focusedIndex };
};

export default useAccessibleCustomSelect;
