import { useEffect } from "react";
import SuperCluster from "supercluster";
import { IMapMarkerOptions } from "..";
import { colours } from "../../../styles/colours";
import { GoogleMap, IGoogleMapIcon, IGoogleMapsApi } from "../GoogleMaps";

const MapMarker = ({
    map,
    googleMapsApi,
    superCluster,
    lat,
    lng,
    icon,
    cluster = false,
    draggable = false,
    infoWindow,
    clusterId = 0,
    pointsInCluster = 0,
    onMarkerDragEnd,
    markerIcon,
    clusterIcon,
}: IMapMarkerProps) => {
    useEffect(() => {
        const mapMarker = new googleMapsApi.Marker({
            map,
            position: {
                lng,
                lat,
            },
            label: cluster
                ? {
                      text: `${pointsInCluster}`,
                      color: colours.map.clusterText,
                  }
                : undefined,
            icon: cluster
                ? clusterIcon
                : {
                      url: icon,
                      ...markerIcon,
                  },
            draggable,
        });

        if (cluster) {
            mapMarker.addListener("click", () => {
                const newZoom = superCluster.getClusterExpansionZoom(clusterId);

                map.setZoom(newZoom);
                map.setCenter({
                    lng,
                    lat,
                });
            });
        } else if (infoWindow) {
            const popupWindow = new googleMapsApi.InfoWindow({
                content: infoWindow,
            });

            mapMarker.addListener("click", () => {
                popupWindow.open(map, mapMarker);
            });
        }

        if (draggable && onMarkerDragEnd) {
            mapMarker.addListener(
                "dragend",
                (event: {
                    latLng: {
                        lat: () => number;
                        lng: () => number;
                    };
                }) => {
                    onMarkerDragEnd(event.latLng.lat(), event.latLng.lng());
                },
            );
        }

        return () => {
            mapMarker.setMap(null);
            googleMapsApi.event.clearListeners(mapMarker, "click");
            googleMapsApi.event.clearListeners(mapMarker, "dragend");
        };
    }, [
        map,
        googleMapsApi,
        superCluster,
        lat,
        lng,
        onMarkerDragEnd,
        markerIcon,
        clusterIcon,
        cluster,
        clusterId,
        draggable,
        icon,
        infoWindow,
        pointsInCluster,
    ]);

    return <></>;
};

interface IMapMarkerProps {
    map: GoogleMap;
    lat: number;
    lng: number;
    cluster?: boolean;
    draggable?: boolean;
    clusterId?: number;
    infoWindow?: string;
    icon?: string;
    pointsInCluster?: number;
    googleMapsApi: IGoogleMapsApi;
    onMarkerDragEnd?: (lat: number, lng: number) => void;
    markerIcon: IGoogleMapIcon;
    clusterIcon: IGoogleMapIcon;
    superCluster: SuperCluster<IMapMarkerOptions>;
}

export default MapMarker;
