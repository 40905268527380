import { ICircuit } from ".";
import config from "../../../config";
import useFilterable from "../useFilterable";

const useCircuits = () => {
    const filterable = useFilterable<ICircuit>(
        `${config.assetsApiUrl}/circuits`,
        {
            params: {
                filters: {
                    active: {
                        readOnly: true,
                        operator: "{AND}",
                        filters: [{ function: "=", value: "true" }],
                    },
                    spare: {
                        readOnly: true,
                        operator: "{AND}",
                        filters: [{ function: "=", value: "false" }],
                    },
                },
            },
        },
    );

    return filterable;
};

export default useCircuits;
