import { Link } from "react-router-dom";
import { IPopoverItemProps } from ".";
import { useCssClasses } from "../../../hooks";
import styles from "./PopoverItem.module.scss";

const PopoverItem = ({
    children,
    onClick,
    url,
    className,
}: IPopoverItemProps) => {
    const cssClasses = useCssClasses(
        styles.container,
        onClick || url ? styles.clickable : "",
        className ?? "",
    );

    if (onClick) {
        return (
            <button onClick={onClick} className={cssClasses} type="button">
                {children}
            </button>
        );
    } else if (url) {
        return (
            <Link to={url} className={cssClasses}>
                {children}
            </Link>
        );
    }

    return <div className={cssClasses}>{children}</div>;
};

export default PopoverItem;
