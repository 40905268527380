import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IFilterProps } from "..";
import { Button, DateRangePickerDropdown, Icon } from "../..";
import { useToggle } from "../../../hooks";

const DateFilter = ({
    property,
    toggleFilter,
    hover,
    activeFilters,
    title,
}: IFilterProps) => {
    const { t } = useTranslation();
    const { hide, toggle, visible } = useToggle();
    const datePickerButton = useRef<HTMLButtonElement>(null);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);

    useEffect(() => {
        if (activeFilters.length === 0) {
            setStartDate(null);
            setEndDate(null);
        }
    }, [activeFilters]);

    const handleDatesSelected = (fromDate: Date, toDate: Date) => {
        setStartDate(fromDate);
        setEndDate(toDate);
    };

    return (
        <>
            <Button
                size="small"
                onClick={toggle}
                setRef={datePickerButton}
                cssRules={
                    hover
                        ? { marginLeft: "5px" }
                        : { marginLeft: "5px", opacity: 0 }
                }
            >
                <Icon
                    icon="calendar"
                    ariaLabel={t(`Filter ${title ?? property}`)}
                />
            </Button>

            {visible && (
                <DateRangePickerDropdown
                    property={property}
                    toggleFilter={toggleFilter}
                    buttonElement={datePickerButton}
                    startDate={startDate}
                    endDate={endDate}
                    onDatesSelected={handleDatesSelected}
                    hide={hide}
                />
            )}
        </>
    );
};

export default DateFilter;
