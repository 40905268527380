import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Loading } from "../../../components";
import useRecentIssues from "../../../utils/api/issues/useRecentIssues";
import {
    getDisplayDifference,
    getNow,
    toDateString,
} from "../../../utils/dates";
import styles from "./RecentIssues.module.scss";

const RecentIssues = () => {
    const recentIssues = useRecentIssues();

    const [now, setNow] = useState(getNow());
    useEffect(() => {
        const intervalId = setInterval(() => {
            setNow(getNow());
        }, 10000);

        return () => clearInterval(intervalId);
    }, []);

    const { t } = useTranslation();
    const formatDate = (date: Date) => {
        const value = getDisplayDifference(now, date);

        if (
            value.key === "timeAgo.months" ||
            value.key === "timeAgo.years" ||
            (value.key === "timeAgo.days" && value.count > 3)
        ) {
            return toDateString(date);
        }

        return t(value.key, { count: value.count });
    };

    return (
        <>
            {recentIssues.loaded ? (
                recentIssues.records.map((issue) => (
                    <div className={styles.issue} key={issue.id}>
                        <div>
                            <Link
                                url={`/issues/${issue.id}`}
                                display="block"
                                testId="IssueTitle_A"
                            >
                                {issue.title}
                            </Link>
                            <div data-test-id="IssueAddressString_Div">
                                {issue.property.addressString}
                            </div>
                        </div>

                        <div
                            className={styles.dateWrapper}
                            data-test-id="IssueTimeSince_Div"
                        >
                            {formatDate(new Date(issue.createdAt))}
                        </div>
                    </div>
                ))
            ) : (
                <Loading />
            )}
        </>
    );
};

export default RecentIssues;
