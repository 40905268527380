import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button, Icon, Img, Popover } from "../../../components";
import PopoverListItem from "../../../components/Popover/PopoverListItem";
import { useToggle } from "../../../hooks";
import { useAuthorizedImage } from "../../../hooks/useAuthorizedImage";
import {
    INotificationType,
    IUserNotificationType,
} from "../../../utils/api/users";
import styles from "./NotificationItem.module.scss";

const NotificationItem = ({
    notification,
    active,
    showPopup,
    onPopupChange,
    onNotificationChange,
}: INotificationItemProps) => {
    const { t } = useTranslation();
    const { hide, toggle, visible } = useToggle();
    const popoverRef = useRef<HTMLButtonElement>(null);

    const image = useAuthorizedImage(
        `PortalIcons/NotificationImages/${notification.icon}`,
    );

    const notificationToggle = (value: boolean) => {
        const updatedNotification = {
            id: notification.id,
            display_name: notification.displayName,
            notification_type: notification.title,
            notification_type_id: notification.id,
            show_popup: showPopup,
        };

        onNotificationChange(updatedNotification, value);
    };

    const notificationEnable = () => {
        notificationToggle(true);
    };

    const notificationDisable = () => {
        notificationToggle(false);
    };

    const popupClicked = () => {
        const updatedNotification = {
            id: notification.id,
            display_name: notification.displayName,
            notification_type: notification.title,
            notification_type_id: notification.id,
            show_popup: showPopup ? false : true,
        };
        onPopupChange(updatedNotification);
    };

    return (
        <div className={styles.container}>
            <div className={styles.containerLeft}>
                <Img
                    url={image}
                    size={50}
                    cssRules={{ backgroundColor: "transparent" }}
                />
                <div className={styles.notificationBody}>
                    <div className={styles.notificationTitle}>
                        {t(notification.title)}
                    </div>
                    <div className={styles.notificationDescription}>
                        {t(notification.displayName)}
                    </div>
                </div>
            </div>

            <div className={styles.containerRight}>
                <Button setRef={popoverRef} onClick={toggle}>
                    {active ? t("On") : t("Off")}
                </Button>

                {visible && (
                    <Popover anchorElement={popoverRef} hide={hide}>
                        <PopoverListItem onClick={notificationEnable}>
                            {active ? <b>{t("On")}</b> : t("On")}
                        </PopoverListItem>
                        <PopoverListItem onClick={notificationDisable}>
                            {!active ? <b>{t("Off")}</b> : t("Off")}
                        </PopoverListItem>
                        <hr />
                        <PopoverListItem onClick={popupClicked}>
                            <div className={styles.showPopup}>
                                {t("Show Popup?")}
                                {showPopup ? (
                                    <Icon icon="check" ariaLabel={t("Yes")} />
                                ) : (
                                    ""
                                )}
                            </div>
                        </PopoverListItem>
                    </Popover>
                )}
            </div>
        </div>
    );
};

interface INotificationItemProps {
    notification: INotificationType;
    active: boolean;
    showPopup: boolean;
    onNotificationChange: (
        notification: IUserNotificationType,
        active: boolean,
    ) => void;
    onPopupChange: (notification: IUserNotificationType) => void;
}

export default NotificationItem;
