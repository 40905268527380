const linear = (progress: number) => {
    return progress;
};

const bounce = (progress: number) => {
    const s = 7.5625;
    const p = 2.75;
    let l;

    if (progress < 1 / p) {
        l = s * progress * progress;
    } else {
        if (progress < 2 / p) {
            progress -= 1.5 / p;
            l = s * progress * progress + 0.75;
        } else {
            if (progress < 2.5 / p) {
                progress -= 2.25 / p;
                l = s * progress * progress + 0.9375;
            } else {
                progress -= 2.625 / p;
                l = s * progress * progress + 0.984375;
            }
        }
    }

    return l;
};

export { linear, bounce };
