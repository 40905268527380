import { Fragment, useMemo } from "react";
import { IQualifiedSupervisorAuditGridColumnProps } from ".";
import { useCssClasses } from "../../../hooks";
import { useOverriddenAnswerComment } from "../../../hooks/useOverriddenAnswerComment";
import useOverriddenAnswerText from "../../../hooks/useOverriddenAnswerText";
import OriginalAnswer from "../OriginalAnswer";
import OverrideAnswers from "../OverrideAnswers";
import OverridenAnswerComment from "../OverridenAnswerComment";
import styles from "./QualifiedSupervisorAuditGridColumn.module.scss";

const QualifiedSupervisorAuditGridColumn = ({
    questionId,
    answers,
    overriddenAnswers,
    documentType,
    errorMessage,
    isEditing,
    validateQuestion,
    overrideAnswer,
}: IQualifiedSupervisorAuditGridColumnProps) => {
    const answersList = useMemo(
        () => answers?.map((a) => a.answer) ?? [],
        [answers],
    );

    const overriddenAnswersText = useOverriddenAnswerText(
        answersList,
        overriddenAnswers,
    );
    const overriddenAnswerComment = useOverriddenAnswerComment(
        answersList,
        overriddenAnswers,
    );

    const cssClasses = useCssClasses(
        isEditing ? styles.margin : "",
        overriddenAnswersText !== "" ? styles.strikeThrough : "",
    );

    return (
        <td className={styles.relative}>
            <div className={cssClasses}>
                {answersList.map((answer) => (
                    <Fragment key={answer.id}>
                        <OriginalAnswer
                            answer={answer}
                            allAnswers={answersList}
                        />{" "}
                    </Fragment>
                ))}
            </div>

            {isEditing ? (
                <OverrideAnswers
                    questionId={questionId}
                    answers={answersList}
                    errorMessage={errorMessage}
                    documentType={documentType}
                    overriddenAnswers={overriddenAnswers}
                    overrideAnswer={overrideAnswer}
                    validateQuestion={validateQuestion}
                />
            ) : (
                <>
                    {overriddenAnswersText}
                    {overriddenAnswerComment && (
                        <OverridenAnswerComment
                            comment={overriddenAnswerComment}
                        />
                    )}
                </>
            )}
        </td>
    );
};

export default QualifiedSupervisorAuditGridColumn;
