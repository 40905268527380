import { useContext, useEffect } from "react";
import { filter, map } from "rxjs";
import { IJob } from ".";
import {
    PropertyCategoriesContext,
    RealtimeContext,
    UserContext,
} from "../../../components";
import config from "../../../config";
import { clearCache } from "../../cache";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiValue from "../useApiValue";

const useJob = (id: string) => {
    const { realtimeObservable } = useContext(RealtimeContext);

    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);
    const { value, loading, loaded, error, send, updateValue } =
        useApiValue<IJob>({});
    const sendRequest = useXTagApiRequest();

    useEffect(() => {
        const subscription = send(
            sendRequest({
                url: `${config.jobsApiUrl}/${id}`,
                method: "GET",
                urlParams: getGlobalUrlParameters(
                    selectedCategories.map((c) => c.id),
                    activeUserParentsIds,
                ),
            }),
        ).subscribe();

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [activeUserParentsIds, id, selectedCategories, send, sendRequest]);

    useEffect(() => {
        if (value) {
            const subscription = realtimeObservable
                .pipe(
                    filter((e) => e.event === "updated" && e.entity === "job"),
                    map((e) => e.payload as IJob),
                    filter((e) => e.id === Number(id)),
                )
                .subscribe((newValue) => {
                    updateValue({
                        ...value,
                        ...newValue,
                    });
                    clearCache();
                });

            return () => {
                if (subscription) {
                    subscription.unsubscribe();
                }
            };
        }
    }, [id, realtimeObservable, updateValue, value]);

    return { value, loading, loaded, error };
};

export default useJob;
