import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { PropertyCategoriesContext, UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const useBulkRemoveHoldAudit = () => {
    const { t } = useTranslation();
    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);
    const { send, loading, error } = useApiRequest({
        errorMessage: t("Failed to remove hold."),
    });
    const sendRequest = useXTagApiRequest();

    const removeHoldAudit = useCallback(
        (ids: number[]) =>
            send(
                sendRequest({
                    url: `${config.auditsApiUrl}/remove-hold`,
                    method: "POST",
                    urlParams: getGlobalUrlParameters(
                        selectedCategories.map((c) => c.id),
                        activeUserParentsIds,
                    ),
                    body: ids,
                }),
            ),
        [activeUserParentsIds, selectedCategories, send, sendRequest],
    );

    return { removeHoldAudit, loading, error };
};

export default useBulkRemoveHoldAudit;
