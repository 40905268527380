import { createContext } from "react";
import { noop, of } from "rxjs";
import { IUserContext } from ".";

const UserContext = createContext<IUserContext>({
    onActiveUserParentsChanged: of([]),
    activeUserParentsIds: [],
    user: {
        id: 0,
        email: "",
        name: "",
        profileImageUrl: "",
        signatureUrl: "",
        userParents: [],
        userParentPermissions: {},
        propertyCategories: [],
    },
    loading: false,
    updateUser: noop,
    activateUserParents: noop,
    isReady: false,
});

export default UserContext;
