import { useTranslation } from "react-i18next";
import { Dropdown, Icon } from "../../../components";

const TableActions = ({
    openAllocateJobModal,
    openCancelAwaitingAllocationModal,
}: ITableActionsProps) => {
    const { t } = useTranslation();

    return (
        <Dropdown
            label={<Icon icon="more" ariaLabel={t("Actions")} />}
            size="small"
            items={[
                {
                    label: t("Allocate Job"),
                    onClick: openAllocateJobModal,
                },
                {
                    label: t("Cancel Job"),
                    onClick: openCancelAwaitingAllocationModal,
                },
            ]}
        />
    );
};

interface ITableActionsProps {
    openAllocateJobModal: () => void;
    openCancelAwaitingAllocationModal: () => void;
}

export default TableActions;
