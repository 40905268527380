import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IPropertyCategoriesProps } from ".";
import {
    Button,
    Grid,
    GridColumn,
    Loading,
    PropertyCategoriesContext,
    Select,
} from "../../../components";
import {
    IIntegration,
    ILandlordPropertyCategory,
    useLandlordPropertyCategories,
    useUpdateLandlordPropertyCategories,
} from "../../../utils/api/landlords";
import styles from "./PropertyCategories.module.scss";
import PropertyCategoryCard from "./PropertyCategoryCard";

const PropertyCategories = ({
    landlordOptions,
    integrations,
}: IPropertyCategoriesProps) => {
    const { t } = useTranslation();

    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { value, loaded } = useLandlordPropertyCategories({});
    const { update, loading } = useUpdateLandlordPropertyCategories();

    const [landlordId, setLandlordId] = useState("");
    const [propertyCategories, setPropertyCategories] = useState<
        ILandlordPropertyCategory[]
    >([]);
    const [landlordIntegrations, setLandlordIntegrations] = useState<
        IIntegration[]
    >([]);

    useEffect(() => {
        if (value) {
            setPropertyCategories(
                value
                    .filter(
                        (propertyCategory) =>
                            propertyCategory.landlordId === Number(landlordId),
                    )
                    .filter(
                        (category) =>
                            selectedCategories.length === 0 ||
                            selectedCategories
                                .map((c) => c.id)
                                .includes(category.propertyCategoryId),
                    ),
            );
            setLandlordIntegrations(integrations[landlordId]);
        } else {
            setPropertyCategories([]);
            setLandlordIntegrations([]);
        }
    }, [value, landlordId, selectedCategories, integrations]);

    const handleUpdatePropertyCategory = useCallback(
        (propertyCategory: ILandlordPropertyCategory) => {
            const index = propertyCategories.findIndex(
                (c) =>
                    c.propertyCategoryId ===
                    propertyCategory.propertyCategoryId,
            );

            if (index > -1) {
                const list = [...propertyCategories];
                list.splice(index, 1, propertyCategory);

                setPropertyCategories(list);
            }
        },
        [propertyCategories],
    );

    const handleSave = useCallback(() => {
        update(Number(landlordId), propertyCategories).subscribe();
    }, [landlordId, propertyCategories, update]);

    return loaded ? (
        <>
            <h2 className={styles.title}>{t("Property Categories")}</h2>
            <p>
                {t(
                    "Set your preferences for which property categories engineers are allowed to work on",
                )}
            </p>

            <Select
                label={t("Select a landlord")}
                allowEmpty={true}
                options={landlordOptions}
                value={landlordId}
                onChange={setLandlordId}
            />

            <Grid>
                {propertyCategories.map((propertyCategory) => (
                    <GridColumn
                        key={propertyCategory.propertyCategoryId}
                        size="oneThird"
                    >
                        <PropertyCategoryCard
                            propertyCategory={propertyCategory}
                            integrations={landlordIntegrations}
                            updatePropertyCategory={
                                handleUpdatePropertyCategory
                            }
                        />
                    </GridColumn>
                ))}
            </Grid>

            {!loading ? (
                landlordId && (
                    <Button onClick={handleSave} variant="primary">
                        {t("Save")}
                    </Button>
                )
            ) : (
                <Loading small={true} />
            )}
        </>
    ) : (
        <Loading />
    );
};

export default PropertyCategories;
