import { useMemo } from "react";
import { IRouteProps } from ".";
import SideMenuContainer from "../../components/MainLayout/SideMenuContainer";
import AllocateJobs from "../../pages/AllocateJobs";
import ContractorDetails from "../../pages/ContractorDetails";
import ContractorRedirectPage from "../../pages/ContractorRedirectPage";
import Engineers from "../../pages/Engineers";
import JobLog from "../../pages/JobLog";

export const useContractorStandardRoutes = () => {
    const routes = useMemo(() => {
        const getJobs = (): IRouteProps => {
            const children: IRouteProps[] = [
                {
                    title: "Job Log",
                    index: true,
                    element: <JobLog />,
                },
                {
                    title: "Allocate Jobs",
                    path: "allocate-jobs",
                    element: <AllocateJobs />,
                },
            ];

            return {
                title: "Jobs",
                path: "/jobs",
                element: <SideMenuContainer appRoutes={children} />,
                children: children,
            };
        };

        const getManagement = (): IRouteProps => {
            const children: IRouteProps[] = [
                {
                    title: "Engineers",
                    index: true,
                    element: <Engineers />,
                },
            ];

            return {
                title: "Management",
                path: "/management",
                element: <SideMenuContainer appRoutes={children} />,
                children: children,
            };
        };

        const getAdministration = (): IRouteProps => {
            const children: IRouteProps[] = [
                {
                    title: "Contractor Details",
                    index: true,
                    element: <ContractorDetails />,
                },
            ];

            const route: IRouteProps = {
                title: "Administration",
                path: "/administration",
                element: <SideMenuContainer appRoutes={children} />,
                children: children,
            };

            return route;
        };

        return [
            {
                path: "/",
                element: <ContractorRedirectPage />,
            },
            getJobs(),
            getManagement(),
            getAdministration(),
        ];
    }, []);

    return routes;
};
