import { useMemo, useRef, useState } from "react";
import {
    Button,
    DateRangePicker,
    Grid,
    GridColumn,
    Popover,
} from "../../components";
import { useToggle } from "../../hooks";
import { addDays, getToday, toDateString } from "../../utils/dates";
import UpcomingJobsDays from "../RepairDashboard/UpcomingJobsDays";
import UpcomingJobsHours from "../RepairDashboard/UpcomingJobsHours";
import UtaProperty from "../RepairDashboard/UtaProperty";

const ServicingDashboard = () => {
    const [startDate, setStartDate] = useState(getToday());
    const [endDate, setEndDate] = useState(addDays(getToday(), 10));

    const handleDatesSelected = (fromDate: Date, toDate: Date) => {
        setStartDate(fromDate);
        setEndDate(toDate);
    };

    const { hide, toggle, visible } = useToggle();
    const datePickerButton = useRef<HTMLButtonElement>(null);

    const priorities = useMemo(() => ["All"], []);

    return (
        <>
            <Button
                onClick={toggle}
                setRef={datePickerButton}
                cssRules={{ marginBottom: "10px" }}
            >
                {toDateString(startDate)} - {toDateString(endDate)}
            </Button>

            {visible && (
                <Popover anchorElement={datePickerButton} hide={hide}>
                    <DateRangePicker
                        onDatesSelected={handleDatesSelected}
                        selectedFromDate={startDate}
                        selectedToDate={endDate}
                    />
                </Popover>
            )}

            <UpcomingJobsHours priorities={priorities} />

            <Grid>
                <GridColumn size="eightyPercent">
                    <UpcomingJobsDays priorities={priorities} />
                </GridColumn>
                <GridColumn size="twentyPercent">
                    <UtaProperty
                        priorities={priorities}
                        startDate={startDate}
                        endDate={endDate}
                    />
                </GridColumn>
            </Grid>
        </>
    );
};

export default ServicingDashboard;
