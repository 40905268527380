import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { combineLatest } from "rxjs";
import {
    Button,
    Checkbox,
    Form,
    InputField,
    Loading,
    Select,
} from "../../../components";
import { ISelectOption } from "../../../components/Select";
import { useToggle } from "../../../hooks";
import { formatAddress } from "../../../utils/addresses";
import {
    useLandlordPreferences,
    useUpdateLandlord,
    useUpdateLandlordPreferences,
} from "../../../utils/api/landlords";
import {
    greaterThan,
    isNumber,
    isRequired,
    useValidateField,
    validateForm,
} from "../../../utils/validation";
import styles from "./General.module.scss";

const General = ({ landlordOptions }: IGeneralProps) => {
    const updateLandlordPreferences = useUpdateLandlordPreferences();
    const landlordPreferences = useLandlordPreferences();
    const updateLandlord = useUpdateLandlord();
    const { show, hide, visible } = useToggle();

    const { t } = useTranslation();

    const [selectedLandlordId, setSelectedLandlordId] = useState("");

    const [addressOne, setAddressOne] = useState("");
    const [addressTwo, setAddressTwo] = useState("");
    const [city, setCity] = useState("");
    const [county, setCounty] = useState("");
    const [postcode, setPostcode] = useState("");
    const [engineerDayLimit, setEngineerDayLimit] = useState(false);
    const [engineerDayLimitCount, setEngineerDayLimitCount] = useState(0);

    const [serviceDateApprovalApp, setServiceDateApprovalApp] =
        useState<boolean>(true);
    const [serviceDateApprovalPortal, setServiceDateApprovalPortal] =
        useState(true);

    const updateEngineerDayLimitCount = (value: string) => {
        setEngineerDayLimitCount(Number(value));
    };

    const updatePreferences = () => {
        combineLatest([
            updateLandlordPreferences.update({
                landlordId: Number(selectedLandlordId),
                preferences: {
                    service_date_approval_app: serviceDateApprovalApp,
                    service_date_approval_portal: serviceDateApprovalPortal,
                    engineer_day_limit: engineerDayLimitCount,
                    limit_engineer_days: engineerDayLimit,
                },
            }),
            updateLandlord.update(Number(selectedLandlordId), {
                address: {
                    addressOne,
                    addressTwo,
                    city,
                    county,
                    postcode,
                    lat: 0,
                    long: 0,
                    country: "GB",
                    countryShort: "GB",
                },
            }),
        ]).subscribe(() => hide());
    };

    useEffect(() => {
        const landlordPrefs = landlordPreferences.value?.find(
            (p) => p.id.toString() === selectedLandlordId,
        );

        if (landlordPrefs && selectedLandlordId !== null) {
            const { preferences, address } = landlordPrefs.preferences;

            setServiceDateApprovalApp(preferences.service_date_approval_app);
            setServiceDateApprovalPortal(
                preferences.service_date_approval_portal,
            );
            setEngineerDayLimit(preferences.limit_engineer_days);
            setEngineerDayLimitCount(preferences.engineer_day_limit || 0);

            setAddressOne(address.addressOne);
            setAddressTwo(address.addressTwo);
            setCity(address.city);
            setCounty(address.county);
            setPostcode(address.postcode);
        }
    }, [landlordPreferences.value, selectedLandlordId]);

    const selectedLandlordIdValidator = useValidateField(
        selectedLandlordId,
        isRequired(),
    );
    const addressOneValidator = useValidateField(addressOne, isRequired());
    const addressPostcodeValidator = useValidateField(postcode, isRequired());
    const dayLimitValidator = useValidateField(
        engineerDayLimitCount.toString(),
        isNumber(),
        greaterThan(-1),
    );

    const generalFormValidator = validateForm(() => [
        addressOneValidator,
        addressPostcodeValidator,
        dayLimitValidator,
        selectedLandlordIdValidator,
    ]);

    return landlordPreferences.loaded ? (
        <>
            <h2 className={styles.title}>{t("General")}</h2>
            <Form onSubmit={updatePreferences} {...generalFormValidator}>
                <Select
                    label={t(
                        "Select general preferences for your organisation",
                    )}
                    options={landlordOptions}
                    onChange={setSelectedLandlordId}
                    value={selectedLandlordId}
                    allowEmpty={true}
                    {...selectedLandlordIdValidator}
                />
                {selectedLandlordId && (
                    <>
                        <p className={styles.label}>
                            {t(
                                "Manual upload service date approval preferences",
                            )}
                            :
                        </p>
                        {!visible ? (
                            <>
                                <p>
                                    {serviceDateApprovalApp
                                        ? t(
                                              "Changes made in the app must be approved",
                                          )
                                        : t(
                                              "Changes made in the app are automatically approved",
                                          )}
                                </p>

                                <p>
                                    {serviceDateApprovalPortal
                                        ? t(
                                              "Changes made in the portal must be approved",
                                          )
                                        : t(
                                              "Changes made in the portal are automatically approved",
                                          )}
                                </p>
                            </>
                        ) : (
                            <>
                                <Checkbox
                                    checked={serviceDateApprovalApp}
                                    onChange={setServiceDateApprovalApp}
                                >
                                    {t(
                                        "Do changes made in the app need to be approved?",
                                    )}
                                </Checkbox>

                                <Checkbox
                                    checked={serviceDateApprovalPortal}
                                    onChange={setServiceDateApprovalPortal}
                                >
                                    {t(
                                        "Do changes made in the portal need to be approved?",
                                    )}
                                </Checkbox>
                            </>
                        )}
                        <p className={styles.label}>
                            {t("Limit on allocated jobs visible to engineers")}
                        </p>
                        {!visible ? (
                            <p>
                                {engineerDayLimit
                                    ? `${t("dayLimit", {
                                          count: engineerDayLimitCount,
                                      })}`
                                    : t("No Limit")}
                            </p>
                        ) : (
                            <>
                                <Checkbox
                                    checked={engineerDayLimit}
                                    onChange={setEngineerDayLimit}
                                >
                                    {t(
                                        "Limit number of jobs sent to engineer devices?",
                                    )}
                                </Checkbox>
                                {engineerDayLimit && (
                                    <InputField
                                        label={t("Number of days to limit")}
                                        value={engineerDayLimitCount.toString()}
                                        onChange={updateEngineerDayLimitCount}
                                        type="number"
                                        {...dayLimitValidator}
                                    />
                                )}
                            </>
                        )}
                        <p className={styles.label}>{t("Address")}</p>
                        {/* TODO: Update the landlords model in the api and switch to addressString */}
                        {!visible ? (
                            <p>
                                {formatAddress({
                                    addressOne,
                                    addressTwo,
                                    city,
                                    county,
                                    postcode,
                                })}
                            </p>
                        ) : (
                            <>
                                <InputField
                                    label={t("Address Line 1")}
                                    value={addressOne}
                                    onChange={setAddressOne}
                                    {...addressOneValidator}
                                />
                                <InputField
                                    label={t("Address Line 2")}
                                    value={addressTwo}
                                    onChange={setAddressTwo}
                                />
                                <InputField
                                    label={t("City")}
                                    value={city}
                                    onChange={setCity}
                                />
                                <InputField
                                    label={t("County")}
                                    value={county}
                                    onChange={setCounty}
                                />
                                <InputField
                                    label={t("Postcode")}
                                    value={postcode}
                                    onChange={setPostcode}
                                    {...addressPostcodeValidator}
                                />
                            </>
                        )}
                        {visible &&
                            (!updateLandlordPreferences.loading &&
                            !updateLandlord.loading ? (
                                <>
                                    <Button
                                        type="submit"
                                        variant="primary"
                                        cssRules={{ marginRight: "10px" }}
                                    >
                                        {t("Save")}
                                    </Button>
                                    <Button onClick={hide}>
                                        {t("Cancel")}
                                    </Button>
                                </>
                            ) : (
                                <Loading small={true} />
                            ))}
                        {!visible && (
                            <Button onClick={show}>{t("Edit")}</Button>
                        )}
                    </>
                )}
            </Form>
        </>
    ) : (
        <Loading visible={true} />
    );
};

interface IGeneralProps {
    landlordOptions: ISelectOption[];
}

export default General;
