import { padStart } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { of, switchMap } from "rxjs";
import {
    Button,
    Dropdown,
    Form,
    Icon,
    JobType,
    Link,
    Loading,
    Modal,
    MultilineInputField,
    Portal,
} from "../../../components";
import ModalBody from "../../../components/Modal/ModalBody";
import ModalFooter from "../../../components/Modal/ModalFooter";
import Table, { IBulkAction, ITableColumn } from "../../../components/Table";
import { useToggle } from "../../../hooks";
import {
    IIntegrationJobApproval,
    IJobAppliancesDiff,
    IntegrationApprovalStatuses,
    useIntegrationApprovalUpdate,
} from "../../../utils/api/misc";
import useIntegrationApproval from "../../../utils/api/misc/useIntegrationApproval";
import { clearCache } from "../../../utils/cache";
import {
    isRequired,
    useValidateField,
    validateForm,
} from "../../../utils/validation";
import IntegrationJobDifferences from "../IntegrationJobDifferences";

const IntegrationApprovalTable = ({ status }: IAuditsTableProps) => {
    const { t } = useTranslation();

    const integrationApprovals = useIntegrationApproval(status);

    const integrationApprovalUpdates = useIntegrationApprovalUpdate();

    const {
        visible: approvalModalVisible,
        hide: approvalModalHide,
        show: approvalModalShow,
    } = useToggle();

    const {
        show: differencesModalShow,
        hide: differencesModalHide,
        visible: differencesModalVisible,
    } = useToggle();

    const [idsToAction, setIdsToAction] = useState<number[] | undefined>(
        undefined,
    );

    const plural = useMemo(
        () => (idsToAction && idsToAction.length > 1 ? "s" : ""),
        [idsToAction],
    );

    const [actionType, setActionType] = useState<"approve" | "decline" | "">(
        "",
    );

    const [reason, setReason] = useState("");

    const [jobDiff, setJobDiff] = useState<IJobAppliancesDiff | undefined>(
        undefined,
    );
    const [jobId, setJobId] = useState<number | undefined>(undefined);

    const reasonValidator = useValidateField(reason, isRequired());

    const updateFormValidation = validateForm(() => [reasonValidator]);

    const displayApprovalModal = useCallback(
        (ids: number[], action: "approve" | "decline") => {
            setIdsToAction(ids);
            setActionType(action);
            setReason("");
            reasonValidator.reset();
            approvalModalShow();
        },
        [approvalModalShow, reasonValidator],
    );

    const updateJobs = () => {
        if (idsToAction && idsToAction.length > 0 && actionType) {
            integrationApprovalUpdates
                .updateApproval({
                    ids: idsToAction,
                    reason,
                    approve: actionType === "approve",
                })
                .pipe(
                    switchMap(() => {
                        clearCache();
                        return of(integrationApprovals.refresh());
                    }),
                )
                .subscribe();
        }

        approvalModalHide();
    };

    const showDiff = useCallback(
        (approvalJob: IIntegrationJobApproval) => {
            setJobId(approvalJob.jobId);
            setJobDiff(approvalJob.diff);
            differencesModalShow();
        },
        [differencesModalShow],
    );

    const columns = useMemo(() => {
        const renderJobId = (value: string) => (
            <Link url={`/jobs/jobs/${value}`}>#{value}</Link>
        );

        const renderJobType = (value: string) => <JobType jobType={value} />;

        const renderChangeSummary = (
            value: string,
            row: IIntegrationJobApproval,
        ) => (
            <Button
                onClick={showDiff}
                clickParams={[row]}
                cssRules={{ width: "100%" }}
            >
                {row.diff.unmodified.length > 0 &&
                row.diff.modified.length === 0 &&
                row.diff.removed.length === 0 &&
                row.diff.added.length === 0
                    ? t("Unmodified")
                    : t("View Changes")}
            </Button>
        );

        const renderActions = (_: unknown, row: IIntegrationJobApproval) => (
            <Dropdown
                label={<Icon icon="more" ariaLabel={t("Actions")} />}
                size="small"
                items={[
                    {
                        label: t("Approve"),
                        onClick: () =>
                            displayApprovalModal([row.id], "approve"),
                    },
                    // only add decline if status is 'manual hold'
                    ...(status === "Manual Hold"
                        ? [
                              {
                                  label: t("Decline"),
                                  onClick: () =>
                                      displayApprovalModal([row.id], "decline"),
                              },
                          ]
                        : []),
                ]}
            />
        );

        const col: {
            [key: string]: ITableColumn<IIntegrationJobApproval>;
        } = {
            id: {
                filterable: false,
                title: t("Id"),
                render: (value: string) => padStart(value, 5, "0"),
            },
            jobId: {
                title: t("Job Id"),
                render: renderJobId,
            },
            propertyAddress: {
                title: t("Address"),
            },
            jobDate: {
                title: t("Job Date"),
                type: "date",
            },
            jobType: {
                title: t("Job Type"),
                render: renderJobType,
            },
            changeSummary: {
                filterable: false,
                sortable: false,
                title: t("Change Summary"),
                render: renderChangeSummary,
            },
            engineer: {
                title: t("Engineer"),
            },
            reason: {
                title: t("Decline Reason"),
                hidden: status !== "Reviewed & Declined",
            },
            approvalStatusId: {
                title: t("StatusId"),
                hidden: true,
            },
            actions: {
                title: t("Actions"),
                filterable: false,
                sortable: false,
                canBeToggledByUser: false,
                render: renderActions,
            },
        };
        return col;
    }, [displayApprovalModal, showDiff, status, t]);

    const bulkActions = useMemo<IBulkAction[]>(
        () => [
            {
                value: "approve",
                label: t("Approve All"),
                onSubmit: (ids: number[]) =>
                    displayApprovalModal(ids, "approve"),
            },
            // only add decline if status is 'manual hold'
            ...(status === "Manual Hold"
                ? [
                      {
                          value: "decline",
                          label: t("Decline All"),
                          onSubmit: (ids: number[]) =>
                              displayApprovalModal(ids, "decline"),
                      },
                  ]
                : []),
        ],
        [displayApprovalModal, status, t],
    );

    return (
        <>
            {integrationApprovals.loaded ? (
                <Table
                    preferences="integration-failures-table"
                    columns={columns}
                    bulkActions={bulkActions}
                    {...integrationApprovals}
                />
            ) : (
                <Loading />
            )}

            {approvalModalVisible && actionType && idsToAction && (
                <Portal>
                    <Form onSubmit={updateJobs} {...reasonValidator}>
                        <Modal
                            title={t(
                                `Job${plural} ${
                                    actionType === "approve"
                                        ? "Approval"
                                        : "Decline"
                                } Confirmation`,
                            )}
                            hide={approvalModalHide}
                        >
                            <ModalBody>
                                <MultilineInputField
                                    label={
                                        actionType === "approve"
                                            ? t(
                                                  `Please enter the reason for approving the selected job${plural}`,
                                              )
                                            : t(
                                                  `Please enter the reason for declining the selected job${plural}`,
                                              )
                                    }
                                    value={reason}
                                    onChange={setReason}
                                    {...updateFormValidation}
                                    error="You must supply a reason"
                                />
                            </ModalBody>
                            <ModalFooter>
                                <Button variant="primary" type="submit">
                                    {actionType === "approve"
                                        ? t("Approval")
                                        : t("Decline")}
                                </Button>
                            </ModalFooter>
                        </Modal>
                    </Form>
                </Portal>
            )}

            {differencesModalVisible && jobDiff && jobId && (
                <Portal>
                    <Modal
                        title={`Job #${jobId} - ${t("Appliance Differences")}`}
                        hide={differencesModalHide}
                    >
                        <ModalBody>
                            <IntegrationJobDifferences diff={jobDiff} />
                        </ModalBody>
                    </Modal>
                </Portal>
            )}
        </>
    );
};

interface IAuditsTableProps {
    status: IntegrationApprovalStatuses;
}

export default IntegrationApprovalTable;
