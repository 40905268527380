import { useCallback, useContext } from "react";
import { PermissionType } from ".";
import { UserContext } from "../components";

/**
 * Check if current user has required permission for a specific userParent
 */
const useHasUserParentPermissionFunction = () => {
    const { user } = useContext(UserContext);

    const hasRequiredPermission = useCallback(
        (
            permission: PermissionType,
            userParentId: number | null | undefined,
        ) => {
            return (
                userParentId !== undefined &&
                userParentId !== null &&
                user.userParentPermissions[userParentId] !== undefined &&
                user.userParentPermissions[userParentId]
                    .map((p) => p.name)
                    .includes(permission)
            );
        },
        [user.userParentPermissions],
    );
    return hasRequiredPermission;
};

export default useHasUserParentPermissionFunction;
