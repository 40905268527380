import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { IQualifiedSupervisorAuditsCloseModalProps } from ".";
import { Alert, Button, Loading, Modal } from "../../../components";
import ModalBody from "../../../components/Modal/ModalBody";
import ModalFooter from "../../../components/Modal/ModalFooter";
import { useBulkRemoveHoldAudit } from "../../../utils/api/audits";

const QualifiedSupervisorAuditsCloseModal = ({
    id,
    closeAudit,
    hide,
}: IQualifiedSupervisorAuditsCloseModalProps) => {
    const { t } = useTranslation();

    const { removeHoldAudit, loading, error } = useBulkRemoveHoldAudit();

    const removeHoldAndCloseModal = useCallback(() => {
        removeHoldAudit([id]).subscribe(() => {
            closeAudit();
        });
    }, [closeAudit, id, removeHoldAudit]);

    return (
        <Modal title={t("Exit audit process")} hide={hide}>
            <ModalBody>
                <p>
                    {t(
                        "You are about to leave the auditing process. This will remove the hold from the audit and any changes will not be saved. If you want to keep the hold on the audit please click the 'Hold' button instead.",
                    )}
                </p>
                <p>{t("Are you sure you want to exit?")}</p>

                {error && <Alert type="error">{error}</Alert>}

                {loading && <Loading />}
            </ModalBody>
            <ModalFooter>
                <Button variant="primary" onClick={removeHoldAndCloseModal}>
                    {t("Exit")}
                </Button>
                &nbsp;
                <Button onClick={hide}>{t("Cancel")}</Button>
            </ModalFooter>
        </Modal>
    );
};

export default QualifiedSupervisorAuditsCloseModal;
