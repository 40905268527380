import { useCallback, useMemo } from "react";
import { IMapMarker } from "../components/Map";
import propertyImage from "../images/map/property.svg";
import { IProperty, useUpdateProperty } from "../utils/api/properties";

export const usePropertyMapFeature = (property: IProperty) => {
    const marker = useMemo(() => {
        if (property && property.lat && property.long) {
            const temp: IMapMarker = {
                type: "Feature",
                geometry: {
                    type: "Point",
                    coordinates: [property.long, property.lat],
                },
                id: property.id,
                properties: {
                    icon: propertyImage,
                    draggable: false,
                },
            };
            return temp;
        }
    }, [property]);

    const { update, loading } = useUpdateProperty();

    const handleCoordinatesUpdate = useCallback(
        (lat: number, lng: number) => {
            update(property.id, {
                id: property.id,
                address: { ...property.address, lat, long: lng },
                propertyType: property.propertyType,
                landlord: property.landlord,
                cappedDate: property.cappedDate,
                voidDate: property.voidDate,
                flag: property.flags,
                active: true,
                tagString: property.tag?.tagString || "",
                uprn: property.uprn,
                isCapped: property.isCapped,
                isVoid: property.isVoid,
                gasMeterLocation: property.gasMeterLocation,
                tag: property.tag,
                buildDate: property.buildDate,
            }).subscribe();
        },
        [property, update],
    );

    return { marker, handleCoordinatesUpdate, loading };
};
