import { useSegment } from "@newfront/react-segment-hooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ICarouselProps } from ".";
import { Button, Icon } from "..";
import config from "../../config";
import styles from "./Carousel.module.scss";

const Carousel = ({ images }: ICarouselProps) => {
    const { t } = useTranslation();
    const [index, setIndex] = useState(0);

    const image = useMemo(() => images[index], [images, index]);

    const handlePreviousClick = useCallback(() => {
        setIndex((i) => (i === 0 ? images.length - 1 : i - 1));
    }, [images.length]);

    const handleNextClick = useCallback(() => {
        setIndex((i) => (i === images.length - 1 ? 0 : i + 1));
    }, [images.length]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            handleNextClick();
        }, 15000);

        return () => {
            clearTimeout(intervalId);
        };
    }, [handleNextClick]);

    const analytics = useSegment();

    const handleBannerClick = useCallback(() => {
        if (config.isPageTrackingEnabled && image.analytics) {
            analytics.track({
                event: "Clicked Banner Advert",
                properties: {
                    advert_title: image.analytics.title,
                    advert_description: image.analytics.description,
                    advert_type: image.analytics.type,
                },
            });
        }
    }, [analytics, image.analytics]);

    return (
        <div>
            <a
                href={image.navigateTo}
                className={styles.link}
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleBannerClick}
            >
                <img
                    src={image.image}
                    alt={image.title}
                    className={styles.image}
                />
            </a>

            <div className={styles.actions}>
                <Button onClick={handlePreviousClick} displayAsLink={true}>
                    <Icon icon="chevron-left" ariaLabel={t("Previous")} />
                </Button>

                <div className={styles.indicators}>
                    {images.map((im, i) => (
                        <Button
                            key={im.image}
                            onClick={() => setIndex(i)}
                            displayAsLink={true}
                        >
                            <span
                                className={`${styles.indicator} ${
                                    i === index ? styles.active : ""
                                }`}
                            />
                        </Button>
                    ))}
                </div>

                <Button onClick={handleNextClick} displayAsLink={true}>
                    <Icon icon="chevron-right" ariaLabel={t("Next")} />
                </Button>
            </div>
        </div>
    );
};

export default Carousel;
