import { RefObject } from "react";
import FlagColour from "./FlagColour";

interface IFlagColourBaseProps {
    colour: string;
}

interface IFlagColourPickerProps extends IFlagColourBaseProps {
    selectColour: (colour: string) => void;
}

interface IFlagColourSelectedProps extends IFlagColourBaseProps {
    togglePopover: () => void;
    setRef: RefObject<HTMLDivElement>;
}

export type IFlagColourProps =
    | IFlagColourPickerProps
    | IFlagColourSelectedProps;

export default FlagColour;
