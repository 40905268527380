import { useContext, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Icon, Loading, PropertyCategoriesContext } from "../../..";
import { useToggle } from "../../../../hooks";
import { IPropertyCategory } from "../../../../utils/api/misc";
import styles from "./ComplianceTypesDropdown.module.scss";
import ComplianceTypesPopover from "./ComplianceTypesPopover";

const ComplianceTypesDropdown = () => {
    const { t } = useTranslation();

    const {
        propertyCategories,
        selectedCategories,
        loading,
        selectCategories,
    } = useContext(PropertyCategoriesContext);

    const selected = useMemo(
        () =>
            propertyCategories.length === 1
                ? propertyCategories
                : selectedCategories,
        [propertyCategories, selectedCategories],
    );

    const all = useMemo<IPropertyCategory>(
        () => ({
            id: 0,
            name: "all",
            displayName: "All",
            colour: "",
        }),
        [],
    );

    const buttonCategory = useMemo(() => {
        if (selected.length === 0) {
            return all;
        } else if (selected.length === 1) {
            return selected[0];
        }

        return {
            id: 0,
            name: "multi",
            displayName: "Multi",
            colour: "",
        };
    }, [all, selected]);

    const complianceTypesButton = useRef<HTMLButtonElement>(null);
    const { visible, hide, toggle } = useToggle();

    return (
        <>
            {loading ? (
                <div className={styles.loading}>
                    <Loading small={true} smallWidth={34} inline={true} />
                </div>
            ) : (
                <>
                    <button
                        type="button"
                        className={styles.button}
                        ref={complianceTypesButton}
                        onClick={toggle}
                    >
                        <span className={styles.icon}>
                            <Icon
                                icon={buttonCategory.name}
                                ariaHidden={true}
                            />
                        </span>

                        <span>{t(buttonCategory.displayName)}</span>

                        <div className={styles.chevronIcon}>
                            <Icon
                                icon={visible ? "chevron-up" : "chevron-down"}
                                ariaLabel={t("View select options")}
                            />
                        </div>
                    </button>

                    {visible && (
                        <ComplianceTypesPopover
                            anchorElement={complianceTypesButton}
                            selectedCategories={selected}
                            allCategory={all}
                            hide={hide}
                            onSave={selectCategories}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default ComplianceTypesDropdown;
