import { useParams } from "react-router-dom";
import { Grid, GridColumn, Loading } from "../../components";
import useObservation from "../../utils/api/observations/useObservation";
import IssueDetails from "../Issue/IssueDetails";
import IssueStatus from "../Issue/IssueStatus";
import IssueTimeline from "../Issue/IssueTimeline";
import IssueTitle from "../Issue/IssueTitle";

const Observation = () => {
    const { id } = useParams();
    const { value, loaded } = useObservation(id ?? "");

    return value && loaded ? (
        <Grid>
            <GridColumn size="half">
                <IssueTitle type="observation" issue={value} />
                <IssueTimeline
                    issueId={value.id.toString()}
                    viewers={value.viewers}
                />
            </GridColumn>
            <GridColumn size="half">
                <IssueStatus issue={value} type="observation" />
                <IssueDetails issue={value} type="observation" />
            </GridColumn>
        </Grid>
    ) : (
        <Loading />
    );
};

export default Observation;
