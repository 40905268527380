import { useEffect, useMemo, useRef } from "react";
import { ISingleLineChartData } from ".";
import { IDrawableObject } from "..";
import { colours } from "../../../styles/colours";
import { animate } from "../animation";
import { bounce } from "../animations";
import { createChart } from "../chart";
import getGrid from "../grid";
import getTooltip from "../tooltip";
import styles from "./SingleLineChart.module.scss";

const SingleLineChart = ({
    data,
    aspectRatio,
    showTooltip = false,
    maxHeight,
}: ISingleLineChartProps) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);

    const labels = useMemo(() => data.map((record) => record.label), [data]);
    const minValue = useMemo(
        () => Math.min(...data.map((record) => record.value)) || 0,
        [data],
    );
    const maxValue = useMemo(
        () => Math.max(...data.map((record) => record.value)) || 0,
        [data],
    );

    const getLines = (): IDrawableObject => {
        const concurrentMovingPoints = 3;
        const delay = 1500 / (data.length + concurrentMovingPoints);
        const animations = data.map((record, index) =>
            animate(
                (concurrentMovingPoints + 1) * delay,
                bounce,
                index * delay,
            ),
        );

        const grid = getGrid({
            minValue,
            maxValue,
            labels,
            gridType: "line",
            bottomPadding: 0,
            rightPadding: 0,
            leftPadding: 0,
            drawXLabels: false,
            drawXLines: false,
            drawYLabels: false,
            drawYLines: false,
        });
        const tooltip = getTooltip({});

        let gridHeight = 0;
        let xAxisPositions: number[] = [];
        let displayTooltip = false;

        const onHover = (x: number, y: number) => {
            const requiresUpdate = grid.onHover(x, y);

            if (requiresUpdate) {
                const gridStatus = grid.getStatus();

                if (gridStatus.hover !== null && showTooltip) {
                    displayTooltip = showTooltip;

                    const index = gridStatus.hover.group;

                    tooltip.setData(
                        [data[index].value.toString()],
                        gridStatus.hover.min,
                        gridStatus.hover.max,
                        data[index].label,
                    );
                }
            }

            return requiresUpdate;
        };

        const onResize = (width: number, height: number) => {
            grid.onResize(width, height);
            tooltip.onResize(width, height);

            const gridStatus = grid.getStatus();

            gridHeight = gridStatus.height;
            xAxisPositions = gridStatus.axis.x.positions;
        };

        const draw = (context: CanvasRenderingContext2D, time: number) => {
            const isAnimating = animations
                .map((animation) => animation.updateFrame(time))
                .some((a) => a);
            const isGridAnimating = grid.draw(context, time);

            context.save();
            context.beginPath();
            data.forEach((record, index) => {
                const x = xAxisPositions[index];
                const y = animations[index].getValue(
                    grid.getY(record.value),
                    gridHeight,
                );

                if (index === 0) {
                    context.moveTo(x, y);
                } else {
                    context.lineTo(x, y);
                }
            });
            context.strokeStyle = colours.chart.colours[0];
            context.stroke();
            context.restore();

            let isTooltipAnimating = false;
            if (displayTooltip) {
                isTooltipAnimating = tooltip.draw(context, time);
            }

            return isGridAnimating || isTooltipAnimating || isAnimating;
        };

        return { draw, onResize, onHover };
    };

    useEffect(() => {
        if (canvasRef.current) {
            const chart = createChart({
                aspectRatio,
                canvas: canvasRef.current,
                objects: [getLines()],
                fontSize: 12,
                maxHeight,
            });

            return chart.destroy;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        data,
        labels,
        minValue,
        maxValue,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        canvasRef.current ? canvasRef.current.clientWidth : 0,
    ]);

    return <canvas className={styles.chart} ref={canvasRef} />;
};

interface ISingleLineChartProps {
    data: ISingleLineChartData[];
    aspectRatio?: number;
    showTooltip?: boolean;
    maxHeight?: number;
}

export default SingleLineChart;
