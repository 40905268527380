import { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { first, switchMap } from "rxjs";
import {
    Card,
    Link,
    Loading,
    UserPreferencesContext,
} from "../../../components";
import useApplianceDueDateDashboard from "../../../utils/api/appliances/useApplianceDueDateDashboard";
import { IPhase } from "../../../utils/api/properties";
import { getNow } from "../../../utils/dates";
import { encodeUrl } from "../../../utils/url";
import AppliancesDueDateGraph from "../ChartComponents/AppliancesDueDateGraph";
import ChartActions from "../ChartComponents/ChartActions";
import ChartOverview from "../ChartComponents/ChartOverview";
import styles from "./ApplianceServices.module.scss";

const ApplianceServices = () => {
    const { t } = useTranslation();
    const { value, loading, refresh } = useApplianceDueDateDashboard();

    const { preferences, updatePreferences } = useContext(
        UserPreferencesContext,
    );

    const [showGraph, setShowGraph] = useState(
        preferences.appliance_status_view === "graph",
    );

    const getToday = () => {
        const today = getNow();
        today.setHours(0, 0, 0, 0);
        return today.toISOString();
    };

    const handleToggleGraphOverview = useCallback(
        (view: "graph" | "overview"): void => {
            updatePreferences({
                ...preferences,
                appliance_status_view: view,
            }).subscribe(() => setShowGraph(view === "graph"));
        },
        [preferences, updatePreferences],
    );

    const handleSavePhaseLimits = useCallback(
        (phases: IPhase[]) => {
            updatePreferences({
                ...preferences,
                appliance_status_phases: phases.map((phase) => ({
                    colour: phase.colour,
                    days_until_deadline: phase.phaseTimeFrame,
                    title: phase.title,
                })),
            })
                .pipe(switchMap(() => refresh().pipe(first())))
                .subscribe();
        },
        [preferences, refresh, updatePreferences],
    );

    return (
        <Card
            title={t("Appliance services")}
            fullHeight={true}
            actions={
                <ChartActions
                    showGraph={showGraph}
                    phaseTimeframe={t("Days until deadline")}
                    phases={value ? value.phases : []}
                    toggleGraphOverview={handleToggleGraphOverview}
                    savePhaseLimits={handleSavePhaseLimits}
                />
            }
        >
            {!value || loading ? (
                <Loading />
            ) : (
                <div className={styles.container}>
                    <div className={styles.leftColumn}>
                        <div className={styles.overdue}>
                            <Link
                                url={encodeUrl("/management/appliances", {
                                    nextServiceDate: [`<${getToday()}`],
                                })}
                                color="red"
                            >
                                {value.overdueAppliances}
                            </Link>
                        </div>
                        <p className={styles.label}>
                            {t("Overdue appliances")}
                        </p>
                    </div>
                    <div className={styles.rightColumn}>
                        {showGraph ? (
                            <AppliancesDueDateGraph
                                phases={value.phases}
                                maxHeight={200}
                            />
                        ) : (
                            <ChartOverview
                                phases={value.phases}
                                dateFilterField="nextServiceDate"
                                targetUrl="/management/appliances"
                                phaseTimeFrameMultiplier={1}
                                areDatesInPast={false}
                            />
                        )}
                    </div>
                </div>
            )}
        </Card>
    );
};

export default ApplianceServices;
