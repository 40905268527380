import { IStepSeparatorProps } from ".";
import { useCssClasses } from "../../../hooks";
import styles from "./StepSeparator.module.scss";

const StepSeparator = ({ isCompleted }: IStepSeparatorProps) => {
    const cssStyles = useCssClasses(
        styles.container,
        isCompleted ? styles.completed : "",
    );

    return <div className={cssStyles} />;
};

export default StepSeparator;
