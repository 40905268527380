import { ReactNode } from "react";
import styles from "./SideContent.module.scss";

const SideContent = ({ side, children }: ISideContentProps) => {
    return (
        <div className={styles.container}>
            <div className={styles.side}>{side}</div>
            <div>{children}</div>
        </div>
    );
};

interface ISideContentProps {
    side: ReactNode;
    children: ReactNode;
}

export default SideContent;
