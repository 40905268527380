import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IDocumentAuditHeaderProps } from ".";
import { Icon } from "../../../components";
import { useCssClasses } from "../../../hooks";
import styles from "./DocumentAuditHeader.module.scss";

const DocumentAuditHeader = ({
    index,
    document,
    status,
    expanded,
}: IDocumentAuditHeaderProps) => {
    const { t } = useTranslation();

    const headerStyle = useMemo(() => {
        switch (status) {
            case "pass":
                return styles.pass;
            case "fail":
                return styles.fail;
            default:
                return "";
        }
    }, [status]);

    const css = useCssClasses(styles.header, headerStyle);

    return (
        <div className={css}>
            {index + 1}. {document.documentName}
            <div
                className={`${styles.chevron} ${
                    expanded ? styles.expanded : ""
                }`}
            >
                <Icon icon="chevron-down" size={30} ariaLabel={t("Expand")} />
            </div>
        </div>
    );
};

export default DocumentAuditHeader;
