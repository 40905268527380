import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, Loading } from "../../components";
import Table, { ITableColumn } from "../../components/Table";
import { useToggle } from "../../hooks";
import { IUser } from "../../utils/api/users";
import useUsers from "../../utils/api/users/useUsers";
import { clearCache } from "../../utils/cache";
import CreateUserModal from "./CreateUserModal";
import TableActions from "./TableActions";

const UserManagement = () => {
    const users = useUsers({
        sortProperty: "name",
        sortDirection: "asc",
    });
    const { refresh, loaded } = users;

    const { t } = useTranslation();

    const { hide, show, visible } = useToggle();

    const refreshTable = useCallback(() => {
        clearCache();
        refresh();
    }, [refresh]);

    const columns = useMemo(() => {
        const renderActions = (value: unknown, row: IUser) => (
            <TableActions user={row} onComplete={refreshTable} />
        );

        const col: { [key: string]: ITableColumn<IUser> } = {
            name: {
                title: t("Name"),
                filterable: false,
                canBeToggledByUser: false,
                render: (value: string, row) => value,
            },
            email: {
                title: t("Email"),
                filterable: false,
            },
            roles: {
                title: t("User Type"),
                sortable: false,
                render: (value, row) =>
                    [
                        ...new Set(
                            row.userParentRoles.flatMap((userParent) =>
                                userParent.roles.map(
                                    (role) => role.displayName,
                                ),
                            ),
                        ),
                    ].join(", "),
            },
            actions: {
                title: t("Actions"),
                filterable: false,
                sortable: false,
                canBeToggledByUser: false,
                render: renderActions,
            },
        };

        return col;
    }, [refreshTable, t]);

    return (
        <>
            <Card
                title={t("Users")}
                actions={<Button onClick={show}>{t("Add User")}</Button>}
            >
                {loaded ? (
                    <Table
                        {...users}
                        preferences="user-management-table"
                        columns={columns}
                    />
                ) : (
                    <Loading visible={true} />
                )}
            </Card>

            {visible && (
                <CreateUserModal onCreateUser={refreshTable} hide={hide} />
            )}
        </>
    );
};

export default UserManagement;
