import { IValidationResult } from ".";

const greaterThan = (
    lowestValue: number,
    error = "Your number must be higher than " + lowestValue,
) => {
    const validate = (value: string): IValidationResult => {
        const isValid = Number(value) > lowestValue;

        return {
            isValid,
            error: isValid ? "" : error,
        };
    };

    return { validate };
};

export default greaterThan;
