import { useMemo } from "react";
import { ILandlordEngineer } from ".";
import config from "../../../config";
import useAllFilterable from "../useAllFilterable";

const useAllEngineers = () => {
    const sortProperties = useMemo(() => ["name"], []);

    const filterable = useAllFilterable<ILandlordEngineer>({
        url: `${config.engineersApiUrl}`,
        sortProperties,
    });

    return filterable;
};

export default useAllEngineers;
