import { useCallback, useContext, useEffect } from "react";
import { filter, map } from "rxjs";
import {
    IManualUploadResetApproval,
    IManualUploadResetApprovalNumbers,
} from ".";
import {
    PropertyCategoriesContext,
    RealtimeContext,
    UserContext,
} from "../../../components";
import config from "../../../config";
import { clearCache } from "../../cache";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiValue from "../useApiValue";

const useManualUploadResetApprovalInboxNumbers = () => {
    const { realtimeObservable } = useContext(RealtimeContext);

    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);
    const { value, loaded, send, updateValue } =
        useApiValue<IManualUploadResetApprovalNumbers>({});
    const sendRequest = useXTagApiRequest();

    const refresh = useCallback(() => {
        const subscription = send(
            sendRequest({
                url: `${config.jobsApiUrl}/manualuploadresetapproval/inboxnumbers`,
                method: "GET",
                urlParams: getGlobalUrlParameters(
                    selectedCategories.map((c) => c.id),
                    activeUserParentsIds,
                ),
            }),
        ).subscribe();

        return subscription;
    }, [activeUserParentsIds, selectedCategories, send, sendRequest]);

    useEffect(() => {
        const subscription = refresh();

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [refresh]);

    useEffect(() => {
        if (value) {
            const subscription = realtimeObservable
                .pipe(
                    filter((e) => e.entity === "manual-upload-reset-approval"),
                    map((e) => e.payload as IManualUploadResetApproval),
                )
                .subscribe((e) => {
                    const test = { ...value };

                    test.waitingApproval += e.approved === null ? 1 : -1;

                    clearCache();
                    updateValue(test);
                });

            return () => {
                if (subscription) {
                    subscription.unsubscribe();
                }
            };
        }
    }, [realtimeObservable, refresh, updateValue, value]);

    return { value, loaded, refresh };
};

export default useManualUploadResetApprovalInboxNumbers;
