import { useState } from "react";
import { ILineChartWithTotal } from ".";
import { DataSelect, Grid, GridColumn, SingleLineChart } from "..";
import styles from "./LineChartWithTotal.module.scss";

const LineChartWithTotal = ({
    data,
    maxHeight,
    testId,
}: ILineChartWithTotalProps) => {
    const [tabIndex, setTabIndex] = useState(0);

    return (
        <>
            {data.length > 0 && (
                <Grid>
                    <GridColumn size="half" fullHeight={true}>
                        <div className={styles.center}>
                            <div className={styles.count} data-test-id={testId}>
                                {data[tabIndex].count}
                            </div>
                            <DataSelect
                                current={tabIndex}
                                onChange={setTabIndex}
                                dataLength={data.length}
                                label={data[tabIndex].label}
                            />
                        </div>
                    </GridColumn>

                    <GridColumn size="half" fullHeight={true}>
                        <SingleLineChart
                            data={data[tabIndex].chart}
                            maxHeight={maxHeight}
                        />
                    </GridColumn>
                </Grid>
            )}
        </>
    );
};

interface ILineChartWithTotalProps {
    data: ILineChartWithTotal[];
    maxHeight?: number;
    testId?: string;
}

export default LineChartWithTotal;
