import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { IUtaListProps } from ".";
import { ImageList, InfiniteScroll, Link } from "../../../components";
import ComplianceCategory from "../../../components/ComplianceCategory";
import {
    IUnableToAccess,
    useUnableToAccessList,
} from "../../../utils/api/jobs";
import { toDateTimeString } from "../../../utils/dates";
import styles from "./UtaList.module.scss";

const UtaList = ({ propertyId }: IUtaListProps) => {
    const { t } = useTranslation();

    const [attempts, setAttempts] = useState<IUnableToAccess[]>([]);
    const onAttemptsLoaded = useCallback(
        (values: IUnableToAccess[], currentPage: number) => {
            setAttempts((current) => {
                const newRecords = currentPage === 1 ? [] : [...current];

                newRecords.push(...values);

                return newRecords;
            });
        },
        [],
    );

    const { loaded, loading, currentPage, totalPages, goToNextPage } =
        useUnableToAccessList(
            {
                sortProperty: "date",
                sortDirection: "desc",
                filters: {
                    "property.id": {
                        operator: "{AND}",
                        filters: [
                            {
                                function: "=",
                                value: propertyId.toString(),
                            },
                        ],
                    },
                    assetId: {
                        operator: "{AND}",
                        filters: [{ function: "=", value: "null" }],
                    },
                },
            },
            onAttemptsLoaded,
        );

    const loadNextPage = useCallback(() => {
        if (currentPage < totalPages) {
            goToNextPage();
        }
    }, [currentPage, totalPages, goToNextPage]);

    return (
        <InfiniteScroll loading={loading} onScrollToBottom={loadNextPage}>
            {attempts.length > 0
                ? attempts.map((a) => (
                      <ImageList
                          key={a.id}
                          image={a.imageUrl}
                          bottomBanner={
                              <div className={styles.banner}>
                                  {t("UTA Reason")}: {a.utaType}
                              </div>
                          }
                      >
                          <div className={styles.utaRecord}>
                              <div className={styles.utaDetails}>
                                  <div className={styles.textWrapper}>
                                      <Link
                                          url={`/issues/unabletoaccess/${a.id}`}
                                      >
                                          {toDateTimeString(new Date(a.date))}
                                      </Link>
                                      &nbsp;{t("by")}
                                      &nbsp;
                                      <Link
                                          url={`/management/engineers/${a.engineer.id}`}
                                      >
                                          {a.engineer.name}
                                      </Link>
                                  </div>
                                  {a.meterReading && (
                                      <div className={styles.textWrapper}>
                                          {t("Meter Reading")}:&nbsp;
                                          <b>{a.meterReading}</b>
                                      </div>
                                  )}
                              </div>
                              <div>
                                  <ComplianceCategory
                                      displayName={a.complianceType.displayName}
                                      colour={a.complianceType.colour}
                                  />
                              </div>
                          </div>
                      </ImageList>
                  ))
                : loaded && t("No unable to accesses found")}
        </InfiniteScroll>
    );
};

export default UtaList;
