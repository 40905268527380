import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IValidateField, IValidator } from ".";

function useValidateField<T>(
    value: T,
    ...validators: IValidator<T>[]
): IValidateField {
    const { t } = useTranslation();
    const [isFirstValidation, setIsFirstValidation] = useState(true);
    const [error, setError] = useState("");
    const [isValid, setValid] = useState(true);

    useEffect(() => {
        validate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const validate = () => {
        let validationResult = { isValid: true, error: "" };

        for (const validator of validators) {
            validationResult = validator.validate(value);

            if (!validationResult.isValid) {
                break;
            }
        }

        setValid(validationResult.isValid);

        if (isFirstValidation) {
            setIsFirstValidation(false);
        } else {
            setError(validationResult.error);
        }

        return validationResult.isValid;
    };

    const reset = () => {
        setTimeout(() => {
            setError("");
            setValid(true);
        }, 0);
    };

    return {
        error: t(error),
        isValid,
        validate,
        reset,
    };
}

export default useValidateField;
