import { useMemo } from "react";
import { ITableFilterProps } from ".";
import { Button } from "../..";
import styles from "./TableFilter.module.scss";

const TableFilter = ({
    property,
    column,
    filterGroup,
    format,
    clearFilters,
}: ITableFilterProps) => {
    const displayText = useMemo(() => {
        if (column !== undefined) {
            return filterGroup.filters
                .map(
                    (filter) =>
                        `${
                            filter.function === "=" ? "" : `${filter.function} `
                        }${format(filter.value, column)}`,
                )
                .join(filterGroup.operator === "{AND}" ? " & " : " | ");
        }

        return "";
    }, [column, filterGroup, format]);

    const isVisible = useMemo(() => {
        let visible =
            column !== undefined &&
            !filterGroup.readOnly &&
            filterGroup.filters.length > 0 &&
            (column.filterable ?? true);

        if (column?.getColumnFilters) {
            const allFilters = column.getColumnFilters();
            const activeFilters = filterGroup.filters.map((f) => f.value);

            visible =
                visible && !allFilters.every((f) => activeFilters.includes(f));
        }

        return visible;
    }, [column, filterGroup.readOnly, filterGroup.filters]);

    if (isVisible) {
        return (
            <div className={styles.filter}>
                <div className={styles.title}>{column?.title}</div>
                {displayText}

                <Button
                    size="small"
                    onClick={clearFilters}
                    clickParams={[property]}
                    cssRules={{
                        marginLeft: "10px",
                        flexShrink: 0,
                    }}
                >
                    x
                </Button>
            </div>
        );
    }

    return null;
};

export default TableFilter;
