import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card, Loading, TabGroup, TabGroupTab } from "../../components";
import useActiveTabFromUrl from "../../hooks/useActiveTabFromUrl";
import { useAwaitingAllocationJobsStatusCount } from "../../utils/api/allocatedJobs";
import AwaitingAllocationTab, {
    AwaitingAllocationStatus,
} from "./AwaitingAllocationTab";

const tabs: string[] = ["Open", "Completed", "Cancelled"];

const AwaitingAllocation = () => {
    const { t } = useTranslation();
    const { value, loaded } = useAwaitingAllocationJobsStatusCount();
    const [activeTab, setActiveTab] = useActiveTabFromUrl(tabs);

    const getStatusCount = useCallback(
        (status: string) => {
            const jobStatus = value.find((job) => job.statusLabel === status);
            return jobStatus ? `(${jobStatus.count})` : "";
        },
        [value],
    );

    const openStatus = useMemo(
        (): AwaitingAllocationStatus[] => [
            "Allocated",
            "Awaiting Allocation",
            "Awaiting Re-allocation",
        ],
        [],
    );

    const completedStatus = useMemo(
        (): AwaitingAllocationStatus[] => ["Completed"],
        [],
    );

    const cancelledStatus = useMemo(
        (): AwaitingAllocationStatus[] => ["Cancelled"],
        [],
    );

    return loaded ? (
        <Card title={t("Jobs Awaiting Allocation")} padding={false}>
            <TabGroup currentTab={activeTab} onActivate={setActiveTab}>
                <TabGroupTab header={`${t("Open")} ${getStatusCount("Open")}`}>
                    <AwaitingAllocationTab status={openStatus} />
                </TabGroupTab>
                <TabGroupTab header={t("Completed")}>
                    <AwaitingAllocationTab status={completedStatus} />
                </TabGroupTab>
                <TabGroupTab header={t("Cancelled")}>
                    <AwaitingAllocationTab status={cancelledStatus} />
                </TabGroupTab>
            </TabGroup>
        </Card>
    ) : (
        <Loading />
    );
};

export default AwaitingAllocation;
