import { ReactNode } from "react";
import ToggleButton from "./ToggleButton";

export interface IToggleButtonProps<T> {
    children?: ReactNode;
    value: T;
    checked?: boolean;
    readonly?: boolean;
    onChange(checked: boolean, value?: T): void;
}

export default ToggleButton;
