import { ISelectOption } from "../../../components/Select";
import {
    IInspectionScheduleAnswer,
    IInspectionSchedulePayload,
} from "../../../utils/api/answers";
import OverrideInspectionScheduleAnswer from "./OverrideInspectionScheduleAnswer";

export interface IOverrideInspectionScheduleAnswerProps {
    options: ISelectOption[];
    answer: IInspectionScheduleAnswer;
    value?: string | IInspectionSchedulePayload;
    showObservationOption: boolean;
    overrideAnswer: (
        answerId: number,
        answer?: string | IInspectionSchedulePayload,
    ) => void;
}

export default OverrideInspectionScheduleAnswer;
