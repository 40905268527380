const roundedRectangle = (
    context: CanvasRenderingContext2D,
    x: number,
    y: number,
    width: number,
    height: number,
    radius: number,
    roundedTopLeft = true,
    roundedTopRight = true,
    roundedBottomRight = true,
    roundedBottomLeft = true,
) => {
    context.beginPath();
    if (roundedTopLeft) {
        context.arc(x + radius, y + radius, radius, Math.PI, 1.5 * Math.PI);
    } else {
        context.moveTo(x, y);
    }

    if (roundedTopRight) {
        context.arc(
            x + width - radius,
            y + radius,
            radius,
            1.5 * Math.PI,
            2 * Math.PI,
        );
    } else {
        context.lineTo(x + width, y);
    }

    if (roundedBottomRight) {
        context.arc(
            x + width - radius,
            y + height - radius,
            radius,
            0,
            0.5 * Math.PI,
        );
    } else {
        context.lineTo(x + width, y + height);
    }

    if (roundedBottomLeft) {
        context.arc(
            x + radius,
            y + height - radius,
            radius,
            0.5 * Math.PI,
            Math.PI,
        );
    } else {
        context.lineTo(x, y + height);
    }
    context.closePath();
};

export { roundedRectangle };
