import { Img } from "../..";
import { useAuthorizedImage } from "../../../hooks/useAuthorizedImage";
import defaultImage from "../../../images/default-profile.svg";

const MessageLogImage = ({ image }: IMessageLogProps) => {
    const img = useAuthorizedImage(image);

    return (
        <Img
            url={img || defaultImage}
            size={30}
            circle={true}
            display="inline-block"
        />
    );
};

interface IMessageLogProps {
    image: string;
}

export default MessageLogImage;
