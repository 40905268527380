import { ChangeEvent, ReactNode, RefObject, useCallback } from "react";
import { useCssClasses } from "../../hooks";
import { useHtmlEntityId } from "../../hooks/useHtmlEntityId";
import ValidationError from "../ValidationError";
import styles from "./RadioButton.module.scss";

function RadioButton<T>(props: IRadioButtonProps<T>) {
    const {
        onChange,
        disabled = false,
        checked,
        value,
        useMargin = true,
        isValid,
        error,
        setRef,
    } = props;
    const ariaLabel = "ariaLabel" in props ? props.ariaLabel : undefined;
    const children = "children" in props ? props.children : undefined;

    const handleChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>): void => {
            if (!disabled) {
                onChange(event.target.checked, value);
            }
        },
        [disabled, onChange, value],
    );

    const id = useHtmlEntityId();

    const cssClasses = useCssClasses(useMargin ? styles.margin : "");

    return (
        <div className={cssClasses} ref={setRef}>
            <input
                id={id}
                type="radio"
                onChange={handleChange}
                checked={checked}
                aria-label={ariaLabel}
            />
            &nbsp;
            <label htmlFor={id} className={styles.label}>
                {children}
            </label>
            <ValidationError isValid={isValid} error={error} />
        </div>
    );
}

interface IRadioButtonBaseProps<T> {
    value: T;
    useMargin?: boolean;
    disabled?: boolean;
    checked: boolean;
    isValid?: boolean;
    error?: string;
    setRef?: RefObject<HTMLDivElement>;
    onChange(checked: boolean, value?: T): void;
}

interface IRadioButtonChildrenProps<T> extends IRadioButtonBaseProps<T> {
    children: ReactNode;
}

interface IRadioButtonAriaLabelProps<T> extends IRadioButtonBaseProps<T> {
    ariaLabel: string;
}

type IRadioButtonProps<T> =
    | IRadioButtonChildrenProps<T>
    | IRadioButtonAriaLabelProps<T>;

export default RadioButton;
