import { ISimpleJob } from ".";
import config from "../../../config";
import useFilterable, { SortDirection } from "../useFilterable";

const useJobs = ({
    sortProperty = "",
    sortDirection = "asc",
    rootJobs = true,
}: ISortParameters) => {
    const filterable = useFilterable<ISimpleJob>(config.jobsApiUrl, {
        params: {
            sortProperty,
            sortDirection,
            filters: {
                recoveryJob: {
                    operator: "{AND}",
                    readOnly: true,
                    filters: [{ function: "=", value: "false" }],
                },
                parentJobId: {
                    operator: "{AND}",
                    readOnly: true,
                    filters: [
                        { function: rootJobs ? "=" : "!=", value: "null" },
                    ],
                },
            },
        },
        filtersUrl: `${config.jobsApiUrl}/filters/unique`,
    });
    return filterable;
};

interface ISortParameters {
    sortProperty?: string;
    sortDirection?: SortDirection;
    rootJobs?: boolean;
}

export default useJobs;
