import { useTranslation } from "react-i18next";
import styles from "./PageNotFound.module.scss";

const PageNotFound = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.holding}>
            <div className={styles.title}>
                {t("Could not load the resource requested")}
            </div>

            <p>
                {t("Try switching the fuel type in the upper right to 'All'")}
            </p>
            <p>
                {t(
                    "If you still see this page, you may not have permission to view this resource.",
                )}
            </p>
        </div>
    );
};

export default PageNotFound;
