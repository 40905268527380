import { useCallback, useEffect, useMemo, useState } from "react";
import { useToggle } from "../../../hooks";
import { IInspectionSchedulePayload } from "../../../utils/api/answers";
import { IAnswerOverride } from "../../../utils/api/audits";
import { IAnswerSectionGroup } from "../QualifiedSupervisorAudit";
import { OverriddenAnswers } from "../QualifiedSupervisorAuditRow";
import { IQualifiedSupervisorAuditAnswer } from "../QualifiedSupervisorAuditsGrouping";

const useOverrideAnswersQS = (
    sectionId: string,
    questions: IAnswerSectionGroup<IQualifiedSupervisorAuditAnswer>[],
    overriddenAnswers: IAnswerOverride[],
    validate: (id: string, isValid: boolean) => void,
) => {
    const {
        visible: isEditing,
        hide: disableEditMode,
        show: enableEditMode,
    } = useToggle();

    const [questionsValidation, setQuestionsValidation] = useState<{
        [key: string]: string | undefined;
    }>({});

    const validateQuestion = useCallback(
        (questionId: string, errorMessage?: string) => {
            setQuestionsValidation((dictionary) => ({
                ...dictionary,
                [questionId]: errorMessage,
            }));
        },
        [],
    );

    const initialOverriddenAnswers = useMemo(() => {
        const answers: OverriddenAnswers = {};

        for (const answer of overriddenAnswers) {
            const isAnswerInSection = questions.some((q) =>
                q.children.some((a) => a.answer.id === answer.answerId),
            );

            if (isAnswerInSection) {
                answers[answer.answerId] = {
                    answer: answer.payload,
                    comment: answer.comments,
                };
            }
        }

        return answers;
    }, [overriddenAnswers, questions]);

    const [newOverriddenAnswers, setNewOverriddenAnswers] =
        useState<OverriddenAnswers>({});

    const updatedOverriddenAnswers = useMemo(
        () => ({ ...initialOverriddenAnswers, ...newOverriddenAnswers }),
        [initialOverriddenAnswers, newOverriddenAnswers],
    );

    const overrideAnswer = useCallback(
        (
            answerId: number,
            answer?: string | IInspectionSchedulePayload,
            comment?: string,
        ) =>
            setNewOverriddenAnswers((state) => ({
                ...state,
                [answerId]:
                    answer !== undefined
                        ? { answer, comment: comment ?? null }
                        : undefined,
            })),
        [],
    );

    const handleCancelEditClick = useCallback(() => {
        setQuestionsValidation({});
        setNewOverriddenAnswers({});
        disableEditMode();
    }, [disableEditMode]);

    const allQuestionsAreValid = useMemo(
        () => Object.values(questionsValidation).every((v) => !v),
        [questionsValidation],
    );

    useEffect(() => {
        validate(sectionId, allQuestionsAreValid && !isEditing);
    }, [allQuestionsAreValid, sectionId, isEditing, validate]);

    return {
        isEditing,
        disableEditMode,
        enableEditMode,
        questionsValidation,
        validateQuestion,
        initialOverriddenAnswers,
        updatedOverriddenAnswers,
        overrideAnswer,
        handleCancelEditClick,
        allQuestionsAreValid,
    };
};

export default useOverrideAnswersQS;
