import {
    IBooleanAnswer,
    IReadingAnswer,
    IStringAnswer,
} from "../../../utils/api/answers";
import OverrideSelectAnswer from "./OverrideSelectAnswer";

export interface IOverrideSelectAnswerProps {
    answer: IReadingAnswer | IBooleanAnswer | IStringAnswer;
    value?: string;
    overrideAnswer: (answerId: number, answer?: string) => void;
}

export default OverrideSelectAnswer;
