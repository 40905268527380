import { useEffect, useMemo, useState } from "react";

function useSelectRecords<T>(records: T[], selectRecordsOnChange: boolean) {
    const [selectedIds, setSelectedIds] = useState<T[]>([]);

    useEffect(() => {
        handleAllRowsSelect(selectRecordsOnChange);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [records]);

    const handleAllRowsSelect = (checked: boolean) => {
        if (checked) {
            setSelectedIds(records.map((record) => record));
        } else {
            setSelectedIds([]);
        }
    };

    const allRowsChecked = useMemo(
        () => selectedIds.length === records.length,
        [records, selectedIds],
    );

    const handleRowSelect = (checked: boolean, value: T) => {
        let temp = [...selectedIds];

        if (checked) {
            temp.push(value);
        } else {
            temp = temp.filter((rowId) => rowId !== value);
        }

        setSelectedIds(temp);

        return temp;
    };

    return {
        selectedIds,
        handleAllRowsSelect,
        allRowsChecked,
        handleRowSelect,
    };
}

export default useSelectRecords;
