import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IPaperworkLogCardProps } from ".";
import { Button, Card, Icon, Loading, PaperworkLog, Portal } from "..";
import { useSelectRecords, useToast, useToggle } from "../../hooks";
import { clearCache } from "../../utils/cache";
import DocumentUploadModal from "../DocumentUploadModal";
import Toast from "../Toast";

const PaperworkLogCard = ({
    isLoaded,
    paperwork,
    tenantEmail,
    disableFuelTypeFiltering,
    landlordId,
    propertyId,
    propertyAddress,
    jobId,
    propertyLetterStateId,
    fullHeight,
    refreshPaperwork,
}: IPaperworkLogCardProps) => {
    const { t } = useTranslation();
    const {
        show: showDocumentUploadModal,
        hide: hideDocumentUploadModal,
        visible: isDocumentUploadModalVisible,
    } = useToggle();
    // const {
    //     show: showSendDocumentsModal,
    //     hide: hideSendDocumentsModal,
    //     visible: isSendDocumentsModalVisible,
    // } = useToggle();
    const { show: showToast, visible: isToastVisible } = useToast();

    const rowsIds = useMemo(() => paperwork.map((row) => row.id), [paperwork]);
    const { handleRowSelect, selectedIds } = useSelectRecords(rowsIds, false);

    const isRowSelected = useCallback(
        (id: number) => selectedIds.some((i) => i === id),
        [selectedIds],
    );

    const handleDocumentUpload = useCallback(() => {
        showToast();
        clearCache();
        refreshPaperwork();
    }, [refreshPaperwork, showToast]);

    // const handleSendDocuments = useCallback(() => {
    //     clearCache();
    //     refreshPaperwork();
    // }, [refreshPaperwork]);

    return (
        <Card
            title={t("Paperwork Log")}
            fullHeight={fullHeight}
            actions={
                <>
                    {/* Temporarily restricted XT-758 */}
                    {/* {selectedIds.length > 0 && (
                        <Button
                            onClick={showSendDocumentsModal}
                            cssRules={{ marginRight: "5px" }}
                        >
                            <Icon icon="send" size={12} ariaHidden={true} />
                            &nbsp;
                            {t("Bulk Send")}
                        </Button>
                    )} */}

                    <Button onClick={showDocumentUploadModal}>
                        <Icon icon="upload" size={12} ariaHidden={true} />
                        &nbsp;
                        {t("Document Upload")}
                    </Button>
                </>
            }
        >
            {isLoaded ? (
                <PaperworkLog
                    paperwork={paperwork}
                    refresh={refreshPaperwork}
                    onSelectRow={handleRowSelect}
                    isRowSelected={isRowSelected}
                    tenantEmail={tenantEmail}
                    disableFuelTypeFiltering={disableFuelTypeFiltering}
                />
            ) : (
                <Loading />
            )}

            {isToastVisible && (
                <Portal>
                    <Toast>{t("Document was uploaded successfully")}</Toast>
                </Portal>
            )}

            {isDocumentUploadModalVisible && (
                <DocumentUploadModal
                    landlordId={landlordId}
                    propertyId={propertyId}
                    jobId={jobId}
                    propertyAddress={propertyAddress}
                    propertyLetterStateId={propertyLetterStateId}
                    onSuccess={handleDocumentUpload}
                    hide={hideDocumentUploadModal}
                />
            )}

            {/* {isSendDocumentsModalVisible && (
                <SendDocumentsModal
                    documentIds={selectedIds}
                    hide={hideSendDocumentsModal}
                    defaultEmailAddress={tenantEmail}
                    onSendDocuments={handleSendDocuments}
                />
            )} */}
        </Card>
    );
};

export default PaperworkLogCard;
