import { useCallback } from "react";
import { ITabGroupHeaderProps } from ".";
import { useCssClasses } from "../../../hooks";
import useClickableNonInteractiveElement from "../../../hooks/useClickableNonInteractiveElement";
import styles from "./TabGroupHeader.module.scss";

const TabGroupHeader = ({
    isActive,
    index,
    children,
    onClick,
    position,
}: ITabGroupHeaderProps) => {
    const cssClasses = useCssClasses(
        styles.tabHeader,
        isActive ? styles.active : "",
        position === "top" ? styles.top : styles.left,
    );

    const handleClick = useCallback(() => onClick(index), [index, onClick]);

    const { onKeyDown } = useClickableNonInteractiveElement(handleClick);

    return (
        <div
            className={cssClasses}
            onClick={handleClick}
            onKeyDown={onKeyDown}
            role="button"
            tabIndex={0}
        >
            {children}
        </div>
    );
};

export default TabGroupHeader;
