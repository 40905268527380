import { IValidationResult } from ".";

const isInteger = (error = "Please type a valid number.") => {
    const validate = (value: string): IValidationResult => {
        const isValid = /^\d+$/.test(value);

        return {
            isValid,
            error: isValid ? "" : error,
        };
    };

    return { validate };
};

export default isInteger;
