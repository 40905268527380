import AwaitingAllocationTab from "./AwaitingAllocationTab";

export type AwaitingAllocationStatus =
    | "Allocated"
    | "Awaiting Allocation"
    | "Awaiting Re-allocation"
    | "Completed"
    | "Cancelled";

export interface IAwaitingAllocationTabProps {
    status: AwaitingAllocationStatus[];
}

export default AwaitingAllocationTab;
