import { useEffect, useState } from "react";
import { noop } from "rxjs";
import styles from "./TabContainer.module.scss";
import TabHeader from "./TabHeader";

const TabContainer = ({
    currentTab = 0,
    children,
    onActivate = noop,
}: ITabGroupProps) => {
    const [activeTab, setActiveTab] = useState(currentTab);
    useEffect(() => {
        setActiveTab(currentTab);
    }, [currentTab]);

    useEffect(() => {
        onActivate(activeTab);
    }, [activeTab, onActivate]);

    return (
        <div className={styles.container}>
            <div className={styles.tabHeaders}>
                {children.map(
                    (tab, index) =>
                        typeof tab !== "boolean" && (
                            <TabHeader
                                key={index}
                                isActive={index === activeTab}
                                onClick={setActiveTab}
                                index={index}
                            >
                                {tab.props.header}
                            </TabHeader>
                        ),
                )}
            </div>

            <div>
                {children.map((tab, index) => index === activeTab && tab)}
            </div>
        </div>
    );
};

interface ITabGroupProps {
    currentTab?: number;
    onActivate?: (index: number) => void;
    children: (JSX.Element | boolean)[];
}

export default TabContainer;
