import { RefObject } from "react";
import { IFilterToggle } from "../../../utils/api/useFilterable";
import DateRangePickerDropdown from "./DateRangePickerDropdown";

export interface IDateRangePickerDropdownProps {
    toggleFilter?: (toggle: IFilterToggle[]) => void;
    property: string;
    hide: () => void;
    buttonElement: RefObject<HTMLElement>;
    startDate?: Date | null;
    endDate?: Date | null;
    onDatesSelected?: (fromDate: Date, toDate: Date) => void;
}

export default DateRangePickerDropdown;
