import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
    Card,
    Icon,
    Link,
    Loading,
    UserPreferencesContext,
} from "../../../components";
import DashboardWidgetFilter, {
    IFilterGroup,
} from "../../../components/DashboardWidgetFilter";
import { IComplianceDashboard } from "../../../utils/api/misc";
import { encodeUrl } from "../../../utils/url";
import ApplianceCondition from "./ApplianceCondition";

const ApplianceConditionCard = ({
    loaded,
    data,
    collapsed,
}: IApplianceConditionProps) => {
    const { t } = useTranslation();
    const { preferences, updatePreferences } = useContext(
        UserPreferencesContext,
    );

    const landlordsAppliance = useMemo(
        () => (preferences.appliance_level ? preferences.appliance_level : ""),
        [preferences.appliance_level],
    );

    const filters = useMemo(() => {
        const changeLandlordsAppliance = (value: string) => {
            updatePreferences({
                ...preferences,
                appliance_level: value,
            }).subscribe();
        };

        const list: IFilterGroup[] = [
            {
                id: "appliance_level",
                title: t("Appliance owner"),
                value: landlordsAppliance,
                changeValue: changeLandlordsAppliance,
                allowEmptyValue: true,
                options: [
                    { label: t("Tenant"), value: "false" },
                    { label: t("Landlord"), value: "true" },
                ],
            },
        ];

        return list;
    }, [landlordsAppliance, preferences, t, updatePreferences]);

    return (
        <Card
            title={t("Appliance condition")}
            fullHeight={true}
            testId="ApplianceCondition_Widget"
            actions={
                <>
                    <Link
                        url={encodeUrl("/management/appliances", {
                            ...(landlordsAppliance !== "" && {
                                landlordsApplianceV2: [
                                    `=${landlordsAppliance}`,
                                ],
                            }),
                        })}
                        ariaLabel={t("View all appliances")}
                    >
                        <Icon
                            icon="eye"
                            size={12}
                            display="inline-block"
                            cssRules={{ marginRight: "5px" }}
                            ariaHidden={true}
                        />
                        {t("View all")}
                    </Link>

                    <DashboardWidgetFilter filters={filters} />
                </>
            }
        >
            {loaded ? (
                <ApplianceCondition
                    conditions={data.applianceConditions}
                    collapsed={collapsed}
                    landlordsAppliance={landlordsAppliance}
                />
            ) : (
                <Loading />
            )}
        </Card>
    );
};

interface IApplianceConditionProps {
    loaded: boolean;
    data: IComplianceDashboard;
    collapsed: boolean;
}

export default ApplianceConditionCard;
