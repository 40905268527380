import { useCallback, useContext, useEffect } from "react";
import { IUnresolvedObservationStatusGraph } from ".";
import { PropertyCategoriesContext, UserContext } from "../../../components";
import config from "../../../config";
import { clearCache } from "../../cache";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiValue from "../useApiValue";

const useUnresolvedObservationStatusGraph = (observationType: string) => {
    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);
    const { value, loading, send } =
        useApiValue<IUnresolvedObservationStatusGraph>({});
    const sendRequest = useXTagApiRequest();

    const refresh = useCallback(
        (shouldClearCache = true) => {
            if (shouldClearCache) {
                clearCache();
            }

            return send(
                sendRequest({
                    url: `${config.issuesApiUrl}/observations/graph/status`,
                    method: "GET",
                    urlParams: {
                        ...(observationType && {
                            "type.displayName": [`=${observationType}`],
                        }),
                        ...getGlobalUrlParameters(
                            selectedCategories.map((c) => c.id),
                            activeUserParentsIds,
                        ),
                    },
                }),
            );
        },
        [
            activeUserParentsIds,
            observationType,
            selectedCategories,
            send,
            sendRequest,
        ],
    );

    useEffect(() => {
        const subscription = refresh(false).subscribe();

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [refresh]);

    return { value, loading, refresh };
};

export default useUnresolvedObservationStatusGraph;
