import { useCallback, useContext } from "react";
import { IPropertyStateTransition } from ".";
import { UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const usePropertyStateTransition = () => {
    const { activeUserParentsIds } = useContext(UserContext);
    const { send } = useApiRequest({});
    const sendRequest = useXTagApiRequest();

    const transitionState = useCallback(
        (propertyId: number, stateTransition: IPropertyStateTransition) =>
            send(
                sendRequest({
                    url: `${config.propertiesApiUrl}/${propertyId}/state`,
                    method: "POST",
                    urlParams: getGlobalUrlParameters([], activeUserParentsIds),
                    body: stateTransition,
                }),
            ),
        [activeUserParentsIds, send, sendRequest],
    );

    return { transitionState };
};

export default usePropertyStateTransition;
