import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { map, noop } from "rxjs";
import { Button, Icon, Loading, Select } from "../../../components";
import { ISelectOption } from "../../../components/Select";
import Table, { ITableColumn } from "../../../components/Table";
import {
    IReconciliationData,
    ReconciliationEntityType,
    useReconciliationData,
    useReconciliationLookups,
    useUpdateReconciliation,
} from "../../../utils/api/reconciliation";
import { clearCache } from "../../../utils/cache";

// TODO: Bury this component to Mordor, never to be seen again.
const ReconciliationTable = (tableProps: IReconciliationTableProps) => {
    const { t } = useTranslation();

    const lookup = useReconciliationLookups(tableProps.entity);

    const { updateReconciliation } = useUpdateReconciliation(tableProps.entity);

    const options = useMemo<ISelectOption[]>(() => {
        return lookup.records.map((record) => ({
            label: record.primaryValue,
            value: record.id.toString(),
        }));
    }, [lookup]);

    const recondiliationData = useReconciliationData(
        tableProps.entity,
        tableProps.status,
    );

    const rowSelectedCallback = useCallback(
        (row: IReconciliationData, set?: (r: IReconciliationData) => void) => {
            if (set) {
                return (value: string) => {
                    row.suggestedId = +value;
                    row.reconciliedTo = value;
                    row.reconciledId = undefined;
                    row.statusMessage = row.status = "";
                    set(row);
                };
            }

            return noop;
        },
        [],
    );

    const update = useCallback(
        (row: IReconciliationData, set?: (r: IReconciliationData) => void) => {
            row.status = "updating";
            if (set) {
                set(row);
            }

            updateReconciliation({ id: row.id, reconciledId: row.reconciledId })
                .pipe(
                    map(() => {
                        clearCache();
                        tableProps.onReconciliationUpdate();
                    }),
                )
                .subscribe(
                    () => {
                        if (set) {
                            row.status = "updated";
                            set(row);
                        }
                    },
                    (err) => {
                        if (set) {
                            row.status = "errored";
                            row.statusMessage = err;
                            row.reconciledId = undefined;
                            set(row);
                        }
                    },
                );
        },
        [updateReconciliation, tableProps],
    );

    const handleReoncile = useCallback(
        (row: IReconciliationData, set?: (r: IReconciliationData) => void) => {
            if (row.suggestedId) {
                row.reconciledId = row.suggestedId;
                update(row, set);
            }
        },
        [update],
    );

    const handleUnreoncile = useCallback(
        (row: IReconciliationData, set?: (r: IReconciliationData) => void) => {
            row.reconciledId = undefined;
            update(row, set);
        },
        [update],
    );

    const getValidationValue = useCallback(
        (row: IReconciliationData) => {
            const numbericValue = row.reconciledId
                ? +row.reconciledId
                : row.suggestedId &&
                  tableProps.status === "unreconciled" &&
                  +row.suggestedId;
            const record = lookup.records.find((r) => r.id === numbericValue);

            return record && record.secondaryValue;
        },
        [lookup.records, tableProps.status],
    );

    const getReconciledValue = useCallback(
        (value: string) => {
            if (value) {
                const record = lookup.records.find(
                    (r) => r.id.toString() === value.toString(),
                );

                return record && record.primaryValue;
            }
        },
        [lookup.records],
    );

    const recordReconciled = useCallback(
        (row: IReconciliationData) => {
            switch (row.status) {
                case "":
                    return "";
                case "updating":
                    return <Loading small={true} inline={true} />;
                case "updated":
                    return (
                        <Icon
                            icon="check"
                            size={26}
                            color="blue"
                            display="inline-block"
                            ariaLabel={t("Updated")}
                        />
                    );
                case "errored":
                    return (
                        <Icon
                            icon="close"
                            size={26}
                            color="red"
                            display="inline-block"
                            ariaLabel={t("An error occured")}
                        />
                    );
            }
        },
        [t],
    );

    const cols = useMemo(() => {
        const columns: {
            [key: string]: ITableColumn<IReconciliationData>;
        } = {};

        columns.reconcilePrimary = {
            title: tableProps.reconcile.primaryTitle,
            filterable: false,
        };

        if (tableProps.reconcile.secondaryTitle) {
            columns.reconcileSecondary = {
                title: tableProps.reconcile.secondaryTitle,
                filterable: false,
            };
        }

        columns.reconcileWith = {
            title: t("Reconciled With"),
            filterable: false,
            sortable: tableProps.status === "reconciled",
            canBeToggledByUser: false,
            field:
                tableProps.status === "unreconciled"
                    ? "suggestedId"
                    : "reconciledId",
            // eslint-disable-next-line react/display-name
            render: (value: string, row: IReconciliationData, set) =>
                tableProps.status === "unreconciled" ? (
                    <Select
                        options={options}
                        value={value ? value.toString() : "-1"}
                        placeholder="Please Select..."
                        onChange={rowSelectedCallback(row, set)}
                        useMargin={false}
                    />
                ) : (
                    getReconciledValue(value)
                ),
            separator: true,
        };

        if (tableProps.reconciledValidation) {
            columns.reconcileValue = {
                filterable: false,
                sortable: tableProps.status === "reconciled",
                title: tableProps.reconciledValidation.title,
                render: (value: string, row: IReconciliationData) =>
                    getValidationValue(row),
            };
        }

        columns.confirm = {
            title: "",
            filterable: false,
            sortable: false,
            canBeToggledByUser: false,
            field:
                tableProps.status === "unreconciled"
                    ? "suggestedId"
                    : "reconciledId",
            render: (value: string, row: IReconciliationData, set) =>
                tableProps.status === "unreconciled" &&
                value &&
                row.status === "errored" ? (
                    row.statusMessage
                ) : tableProps.status === "unreconciled" && value ? (
                    <>
                        <Button
                            disabled={!value}
                            onClick={handleReoncile}
                            clickParams={[row, set]}
                        >
                            {t("Reconcile")}
                        </Button>
                        {recordReconciled(row)}
                    </>
                ) : (
                    tableProps.status === "reconciled" &&
                    value && (
                        <Button
                            disabled={!value}
                            onClick={handleUnreoncile}
                            clickParams={[row]}
                        >
                            {t("Unreconcile")}
                        </Button>
                    )
                ),
        };

        return columns;
    }, [
        t,
        options,
        rowSelectedCallback,
        getValidationValue,
        getReconciledValue,
        handleReoncile,
        handleUnreoncile,
        tableProps,
        recordReconciled,
    ]);

    return (
        <>
            {recondiliationData.loaded && lookup.loaded ? (
                <Table
                    preferences="reconciliation-table"
                    columns={cols}
                    hideFilters={true}
                    {...recondiliationData}
                />
            ) : (
                <Loading />
            )}
        </>
    );
};

export interface IReconciliationTableProps {
    status: "reconciled" | "unreconciled";
    entity: ReconciliationEntityType;
    reconcile: {
        primaryTitle: string;
        secondaryTitle?: string;
    };
    reconciledValue: {
        title: string;
    };
    reconciledValidation?: {
        title: string;
    };
    onReconciliationUpdate: () => void;
}

export default ReconciliationTable;
