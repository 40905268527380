import { useCssClasses } from "../../hooks";
import styles from "./Badge.module.scss";

const Badge = ({
    children,
    color = "",
    position = "",
    topOffset = 0,
    leftOffset = 0,
}: IBadgeProps) => {
    const cssClasses = useCssClasses(
        styles.badge,
        position && styles[position],
        color && styles[color],
    );

    return (
        <div className={styles.wrapper}>
            <div
                className={cssClasses}
                style={{
                    top: `${topOffset}px`,
                    left: `${leftOffset}px`,
                }}
            >
                {children}
            </div>
        </div>
    );
};

export type Position = "absolute" | "";

export type BadgeColor = "green" | "blue" | "";

interface IBadgeProps {
    children?: number | string;
    color?: BadgeColor;
    position?: Position;
    topOffset?: number;
    leftOffset?: number;
}

export default Badge;
