import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { KeyValueWidget } from "../../../components";
import { useAwaitingAllocationJobCount } from "../../../utils/api/allocatedJobs";
import { encodeUrl } from "../../../utils/url";
const CustomerVisits = ({ startDate, endDate }: ICustomerVisitsProps) => {
    const { t } = useTranslation();
    const [jobPriorities] = useState(["Customer Requested"]);

    const { value, loaded } = useAwaitingAllocationJobCount({
        jobPriorities: jobPriorities,
        startDate,
        endDate,
    });

    const summary = useMemo(
        () => [
            {
                key: t("Total"),
                value: value?.count,
            },
        ],
        [t, value],
    );

    const url = useMemo(
        () =>
            encodeUrl("/jobs/awaiting-allocation", {
                slaDate: [
                    `>=${startDate.toISOString()}{AND}<${endDate.toISOString()}`,
                ],
                jobPriority: ["=Customer Requested"],
            }),
        [endDate, startDate],
    );

    return (
        <KeyValueWidget
            title={t("Customer requested visits")}
            summary={summary}
            url={url}
            tooltipText={t(
                "Shows the amount of awaiting allocation jobs with the customer requested SLA within the date filters selected.",
            )}
            loaded={loaded}
        />
    );
};

interface ICustomerVisitsProps {
    startDate: Date;
    endDate: Date;
}

export default CustomerVisits;
