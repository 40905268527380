import { useCallback, useContext, useEffect } from "react";
import { filter } from "rxjs";
import { IIntegrationApprovalInboxNumbers } from ".";
import {
    PropertyCategoriesContext,
    RealtimeContext,
    UserContext,
} from "../../../components";
import config from "../../../config";
import { clearCache } from "../../cache";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiValue from "../useApiValue";

const useIntegrationApprovalInboxNumbers = () => {
    const { realtimeObservable } = useContext(RealtimeContext);

    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);
    const { value, loaded, send } =
        useApiValue<IIntegrationApprovalInboxNumbers>({});
    const sendRequest = useXTagApiRequest();

    const refresh = useCallback(() => {
        const subscription = send(
            sendRequest({
                url: `${config.miscApiUrl}/integration/inboxnumbers`,
                method: "GET",
                urlParams: getGlobalUrlParameters(
                    selectedCategories.map((c) => c.id),
                    activeUserParentsIds,
                ),
            }),
        ).subscribe();

        return subscription;
    }, [activeUserParentsIds, selectedCategories, send, sendRequest]);

    useEffect(() => {
        const subscription = refresh();

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [refresh]);

    useEffect(() => {
        if (value) {
            const subscription = realtimeObservable
                .pipe(
                    filter(
                        (e) =>
                            e.entity === "integration-failure" ||
                            e.entity === "integration-job-approval",
                    ),
                )
                .subscribe(() => {
                    // TODO: This should be implemented as in commit id: 27aa4cd64c24dfaedf83303de767db9a9a9c49f5
                    // currently there is a race condition resulting in the numbers being reported incorrectly
                    clearCache();
                    refresh();
                });

            return () => {
                if (subscription) {
                    subscription.unsubscribe();
                }
            };
        }
    }, [realtimeObservable, refresh, value]);

    return { value, loaded, refresh };
};

export default useIntegrationApprovalInboxNumbers;
