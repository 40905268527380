import { useMemo } from "react";
import { IRouteProps } from ".";
import SideMenuContainer from "../../components/MainLayout/SideMenuContainer";
import { useHasPermission } from "../../hooks";
import PageNotFound from "../../pages/PageNotFound";
import UserDetails from "../../pages/UserDetails";
import UserNotifications from "../../pages/UserNotifications";
import { useContractorStandardRoutes } from "./useContractorStandardRoutes";
import { usePortalStandardRoutes } from "./usePortalStandardRoutes";

export const useAppRoutes = () => {
    const isPortalStandard = useHasPermission("portal_standard");
    const isContractorStandard = useHasPermission("contractor_standard");

    const portalStandardRoutes = usePortalStandardRoutes();
    const contractorStandardRoutes = useContractorStandardRoutes();

    const routes = useMemo(() => {
        const getUser = (): IRouteProps => {
            const children: IRouteProps[] = [
                {
                    title: "Details",
                    index: true,
                    element: <UserDetails />,
                },
                {
                    title: "Notifications",
                    path: "notifications",
                    element: <UserNotifications />,
                },
            ];

            return {
                path: "/user",
                element: <SideMenuContainer appRoutes={children} />,
                children: children,
            };
        };

        const list: IRouteProps[] = [];

        if (isPortalStandard) {
            list.push(...portalStandardRoutes);
        } else if (isContractorStandard) {
            list.push(...contractorStandardRoutes);
        }

        list.push(getUser(), {
            path: "*",
            element: <PageNotFound />,
        });

        return list;
    }, [
        contractorStandardRoutes,
        isContractorStandard,
        isPortalStandard,
        portalStandardRoutes,
    ]);

    return routes;
};
