import { IUnableToAccess } from ".";
import config from "../../../config";
import useFilterable, { IFilterableParams } from "../useFilterable";

const useUnableToAccessList = (
    params?: IFilterableParams,
    onRecordsLoaded?: (
        values: IUnableToAccess[],
        currentPage: number,
        totalPages: number,
    ) => void,
) => {
    const filterable = useFilterable<IUnableToAccess>(
        `${config.jobsApiUrl}/unabletoaccess`,
        { params },
        onRecordsLoaded,
    );

    return filterable;
};

export default useUnableToAccessList;
