import * as FullStory from "@fullstory/browser";
import { useSegment } from "@newfront/react-segment-hooks";
import { useContext, useEffect } from "react";
import { useLocation, useRoutes } from "react-router-dom";
import { PropertyCategoriesContext, UserContext } from "../..";
import config from "../../../config";
import { useAppRoutes } from "../../../utils/routing/useAppRoutes";
import Toolbar from "../Toolbar";
import styles from "./Layout.module.scss";

const Layout = () => {
    const analytics = useSegment();

    const appRoutes = useAppRoutes();
    const routes = useRoutes(appRoutes);
    const { user } = useContext(UserContext);
    const { propertyCategories } = useContext(PropertyCategoriesContext);

    const { pathname, search, hash } = useLocation();

    useEffect(() => {
        if (config.isPageTrackingEnabled) {
            (window as any).Beamer.update({
                user_id: user.id.toString(),
                user_firstname: user.name,
                user_lastname: "-",
                user_email: user.email,
            });
            (window as any).Beamer.init();
        }
    }, [user.email, user.id, user.name]);

    useEffect(() => {
        if (config.isPageTrackingEnabled) {
            analytics.identify({
                userId: user.id.toString(),
                traits: {
                    name: user.name,
                    email: user.email,
                    xtaguser: true,
                    fuelTypes: propertyCategories.map((pc) => pc.displayName),
                },
            });
        }
    }, [analytics, propertyCategories, user.email, user.id, user.name]);

    useEffect(() => {
        if (config.isPageTrackingEnabled) {
            FullStory.identify(user.id.toString(), {
                displayName: user.name,
                email: user.email,
            });
        }
    }, [user.id, user.email, user.name]);

    useEffect(() => {
        if (config.isPageTrackingEnabled) {
            analytics.page({
                name: `${pathname}${search}${hash}`,
            });
        }
    }, [analytics, hash, pathname, search]);

    return (
        <div className={styles.container}>
            <Toolbar appRoutes={appRoutes} />

            <div className={styles.content} role="main">
                {routes}
            </div>
        </div>
    );
};

export default Layout;
