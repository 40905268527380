import { useCallback, useMemo } from "react";
import { ICollapseProps } from ".";
import { useCssClasses } from "../../hooks";
import useClickableNonInteractiveElement from "../../hooks/useClickableNonInteractiveElement";
import Icon from "../Icon";
import styles from "./Collapse.module.scss";

const Collapse = ({
    title,
    sectionId,
    expandedSectionId,
    onExpandToggle,
    children,
}: ICollapseProps) => {
    const isExpanded = useMemo(() => sectionId === expandedSectionId, [
        expandedSectionId,
        sectionId,
    ]);

    const handleClick = useCallback(() => {
        if (isExpanded) {
            onExpandToggle("");
        } else {
            onExpandToggle(sectionId);
        }
    }, [isExpanded, onExpandToggle, sectionId]);

    const { onKeyDown } = useClickableNonInteractiveElement(handleClick);

    const mainContentCssClasses = useCssClasses(
        styles.mainContent,
        isExpanded ? "" : styles.collapsed,
    );

    const iconCssClasses = useCssClasses(
        styles.icon,
        isExpanded ? "" : styles.collapsed,
    );

    return (
        <div className={styles.container}>
            <div
                className={styles.header}
                onClick={handleClick}
                onKeyDown={onKeyDown}
                role="button"
                tabIndex={0}
            >
                {title}
                <span className={iconCssClasses}>
                    <Icon icon="chevron-up" ariaHidden={true} />
                </span>
            </div>
            <div className={mainContentCssClasses}>{children}</div>
        </div>
    );
};

export default Collapse;
