import { IPaperwork } from ".";
import config from "../../../config";
import useFilterable, { SortDirection } from "../useFilterable";

const useDocumentStatus = ({
    status,
    sortProperty = "",
    sortDirection = "asc",
}: ISortParameters) => {
    const filterable = useFilterable<IPaperwork>(
        `${config.lgsrsApiUrl}/documents`,
        {
            params: {
                sortProperty,
                sortDirection,
                filters: {
                    lgsrSent: {
                        operator: "{AND}",
                        readOnly: true,
                        filters: [{ function: "=", value: status }],
                    },
                    jobType: {
                        filters: [{ function: "!=", value: "Multi" }],
                    },
                },
            },
        },
    );

    return filterable;
};

interface ISortParameters {
    status: string;
    sortProperty?: string;
    sortDirection?: SortDirection;
}

export default useDocumentStatus;
