import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { INextServiceDateCardProps } from ".";
import {
    Card,
    Grid,
    GridColumn,
    Icon,
    PropertyCategoriesContext,
} from "../../../components";
import ProgressBar from "../../../components/ProgressBar";
import { getDifferenceInDays, getToday } from "../../../utils/dates";
import styles from "./NextServiceDateCard.module.scss";

const NextServiceDateCard = ({
    nextServiceDueDates,
}: INextServiceDateCardProps) => {
    const { t } = useTranslation();

    const { selectedCategories } = useContext(PropertyCategoriesContext);

    const dueDates = useMemo(
        () =>
            nextServiceDueDates
                .filter(
                    (dueDate) =>
                        selectedCategories.length === 0 ||
                        selectedCategories.some(
                            (c) => c.id === dueDate.fuelTypeId,
                        ),
                )
                .sort((a, b) => (a.fuelTypeId > b.fuelTypeId ? 1 : -1))
                .map((dueDate) => ({
                    id: dueDate.fuelTypeId,
                    name: dueDate.propertyCategoryName,
                    displayName: dueDate.fuelTypeName,
                    color: dueDate.color,
                    days:
                        dueDate.date !== null
                            ? Math.floor(
                                  getDifferenceInDays(
                                      getToday(),
                                      new Date(dueDate.date),
                                  ),
                              )
                            : null,
                    noAccessCount: dueDate.noAccessCount,
                })),
        [nextServiceDueDates, selectedCategories],
    );

    return (
        <Card
            title={
                <div className={styles.headerContainer}>
                    <div className={styles.topRow}>
                        <div>{t("Next maintenance in")}</div>
                        <div>{t("No access count")}</div>
                    </div>
                </div>
            }
            fullHeight={true}
        >
            {dueDates.map(
                (dueDate) =>
                    dueDate.days !== null && (
                        <Grid key={dueDate.id} fullHeight={false}>
                            <GridColumn size="eightyPercent">
                                <div
                                    key={dueDate.id}
                                    className={styles.container}
                                >
                                    <div className={styles.topRow}>
                                        <div
                                            className={styles.propertyCategory}
                                        >
                                            <Icon
                                                icon={dueDate.name}
                                                customColor={dueDate.color}
                                                ariaHidden={true}
                                                display="inline-block"
                                            />
                                            &nbsp;
                                            {dueDate.displayName}
                                        </div>

                                        <div>
                                            {dueDate.days < 0 && (
                                                <span
                                                    className={styles.overdue}
                                                >
                                                    {t("overdue")}&nbsp;
                                                </span>
                                            )}

                                            {`${Math.abs(dueDate.days)} ${t(
                                                "daysCount",
                                                {
                                                    count: dueDate.days,
                                                },
                                            )}`}
                                        </div>
                                    </div>

                                    {dueDate.days < 0 ? (
                                        <ProgressBar
                                            percentage={100}
                                            cssClass={styles.overdueProgressBar}
                                        />
                                    ) : (
                                        <ProgressBar
                                            percentage={Math.ceil(
                                                (dueDate.days / 365) * 100,
                                            )}
                                            color={dueDate.color}
                                        />
                                    )}
                                </div>
                            </GridColumn>
                            <GridColumn size="twentyPercent">
                                {dueDate.noAccessCount}
                            </GridColumn>
                        </Grid>
                    ),
            )}
        </Card>
    );
};

export default NextServiceDateCard;
