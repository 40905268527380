import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { INotesTabProps } from ".";
import { Button, Loading } from "../../../components";
import AddNote from "../../../components/AddNote";
import Notes, { INote } from "../../../components/Notes";
import { useToggle } from "../../../hooks";
import { usePropertyNotes } from "../../../utils/api/properties";
import useAddNote from "../../../utils/api/properties/useAddNote";
import usePortalPropertyNotes from "../../../utils/api/properties/usePortalPropertyNotes";
import { clearCache } from "../../../utils/cache";
import { realtimeCreateList } from "../../../utils/realtime";
import styles from "./NotesTab.module.scss";

const NotesTab = ({ propertyId }: INotesTabProps) => {
    const { t } = useTranslation();

    const { value: engineerNotes, loaded: engineerNotesLoaded } =
        usePropertyNotes(propertyId);

    const {
        value: propertyNotes,
        loaded: propertyNotesLoaded,
        updateValue,
    } = usePortalPropertyNotes(propertyId);

    const { show, hide, visible } = useToggle();

    const { addNote } = useAddNote();
    const handleNewNotelSave = useCallback(
        (id: number, note: string) => {
            addNote({ note, propertyId: id }).subscribe((newNote) => {
                clearCache();
                hide();

                if (newNote) {
                    const list = realtimeCreateList(newNote, propertyNotes);
                    if (list) {
                        updateValue(list);
                    }
                }
            });
        },
        [addNote, hide, propertyNotes, updateValue],
    );

    const notes = useMemo(() => {
        const list: INote[] = engineerNotes
            .map((note) => ({
                url: "/management/engineers/" + note.engineer.id,
                text: note.note,
                subtitle: note.engineer.name,
                date: note.date || "",
            }))
            .concat(
                propertyNotes.map((note) => ({
                    url: "",
                    text: note.note,
                    subtitle: note.user.name,
                    date: note.createdAt || "",
                })),
            );

        return list.sort((a, b) => (a.date < b.date ? 1 : -1));
    }, [engineerNotes, propertyNotes]);

    return (
        <>
            {engineerNotesLoaded && propertyNotesLoaded ? (
                <>
                    <div className={styles.actions}>
                        <Button onClick={show} displayBlock={false}>
                            {t("Add note")}
                        </Button>
                    </div>

                    {notes.length > 0 ? (
                        <Notes notes={notes} />
                    ) : (
                        t("No notes supplied")
                    )}

                    {visible && (
                        <AddNote
                            propertyId={propertyId}
                            onCancel={hide}
                            onSave={handleNewNotelSave}
                        />
                    )}
                </>
            ) : (
                <Loading />
            )}
        </>
    );
};

export default NotesTab;
