import { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Alert,
    Button,
    Card,
    Form,
    InputField,
    UserContext,
} from "../../../components";
import { useToggle } from "../../../hooks";
import { useUpdateUser } from "../../../utils/api/users";
import {
    isRequired,
    useValidateField,
    validateForm,
} from "../../../utils/validation";
import styles from "./UserDetailsSection.module.scss";

const UserDetailsSection = () => {
    const { t } = useTranslation();

    const { user } = useContext(UserContext);
    const { updateUser, loading, error } = useUpdateUser();

    const {
        show: enableEditMode,
        hide: disableEditMode,
        visible: isEditMode,
    } = useToggle();

    const [name, setName] = useState(user.name);

    const handleDisableEditModeClick = useCallback(() => {
        setName(user.name);
        disableEditMode();
    }, [disableEditMode, user]);

    const nameValidator = useValidateField(name, isRequired());

    const formValidators = validateForm(() => [nameValidator]);

    const handleSubmit = useCallback(() => {
        updateUser({
            name,
        }).subscribe(() => {
            disableEditMode();
        });
    }, [disableEditMode, name, updateUser]);

    return (
        <Card title={t("Details")}>
            <Form onSubmit={handleSubmit} {...formValidators}>
                <InputField
                    value={name}
                    onChange={setName}
                    label={t("Name")}
                    disabled={!isEditMode}
                    {...nameValidator}
                />

                {error && (
                    <div className={styles.container}>
                        <Alert type="error">{error}</Alert>
                    </div>
                )}

                {isEditMode && (
                    <div>
                        <Button
                            type="submit"
                            variant="primary"
                            disabled={loading}
                        >
                            {t("Save")}
                        </Button>
                        &nbsp;
                        <Button onClick={handleDisableEditModeClick}>
                            {t("Cancel")}
                        </Button>
                    </div>
                )}
            </Form>

            {!isEditMode && (
                <Button onClick={enableEditMode}>{t("Edit")}</Button>
            )}
        </Card>
    );
};

export default UserDetailsSection;
