import { useTranslation } from "react-i18next";
import { IJobsIssuesUTAsWidgetProps } from ".";
import { Icon, Link, TabCard, TabCardTab } from "../../../components";
import RecentIssues from "../RecentIssues";
import RecentJobs from "../RecentJobs";
import RecentUnableToAccess from "../RecentUnableToAccess";

const JobsIssuesUTAsWidget = ({
    rowScrollHeight,
}: IJobsIssuesUTAsWidgetProps) => {
    const { t } = useTranslation();

    return (
        <TabCard
            maxHeight={rowScrollHeight}
            fullHeight={true}
            testId="JobsIssuesUTAs_Widget"
        >
            <TabCardTab
                title={t("Jobs")}
                actions={
                    <Link url="/jobs/jobs" ariaLabel={t("View all jobs")}>
                        <Icon
                            icon="eye"
                            size={12}
                            display="inline-block"
                            cssRules={{ marginRight: "5px" }}
                            ariaHidden={true}
                        />
                        {t("View all")}
                    </Link>
                }
            >
                <RecentJobs />
            </TabCardTab>
            <TabCardTab
                title={t("Issues")}
                actions={
                    <Link url="/issues" ariaLabel={t("View all issues")}>
                        <Icon
                            icon="eye"
                            size={12}
                            display="inline-block"
                            cssRules={{ marginRight: "5px" }}
                            ariaHidden={true}
                        />
                        {t("View all")}
                    </Link>
                }
            >
                <RecentIssues />
            </TabCardTab>
            <TabCardTab
                title={t("UTAs")}
                actions={
                    <Link
                        url="/issues/unabletoaccess"
                        ariaLabel={t("View all unable to access attempts")}
                    >
                        <Icon
                            icon="eye"
                            size={12}
                            display="inline-block"
                            cssRules={{ marginRight: "5px" }}
                            ariaHidden={true}
                        />
                        {t("View all")}
                    </Link>
                }
            >
                <RecentUnableToAccess />
            </TabCardTab>
        </TabCard>
    );
};

export default JobsIssuesUTAsWidget;
