import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Link } from "../../components";
import Asset from "../../components/Asset";
import { IEditableDetailsData } from "../../components/EditableDetailsTable";

const Circuit = () => {
    const { t } = useTranslation();
    const { id } = useParams();

    const getColumns = useCallback(() => {
        const renderPhaseType = (value: string) => {
            return value ? t("Three phase") : t("Single phase");
        };

        const renderAddress = (value: string, data: any) => (
            <Link url={`/management/properties/${data.property.id}`}>
                {value}
            </Link>
        );

        const detailTableColumns: IEditableDetailsData[] = [
            {
                title: t("Property"),
                type: "component",
                path: "property.address",
                render: renderAddress,
            },
            {
                title: t("Distribution board"),
                type: "string",
                path: "board.name",
            },
            {
                title: t("Circuit number"),
                type: "string",
                path: "number",
            },
            {
                title: t("Circuit description"),
                type: "string",
                path: "description",
            },
            {
                title: t("BS (EN)"),
                type: "string",
                path: "bsEn",
            },
            {
                title: t("Type"),
                type: "string",
                path: "type.displayName",
            },
            {
                title: t("Phase type"),
                type: "component",
                path: "threePhase",
                render: renderPhaseType,
            },
            {
                title: t("Rating (A)"),
                type: "string",
                path: "rating",
            },
            {
                title: t("Breaking capacity (kA)"),
                type: "string",
                path: "breakingCapacity",
            },
            {
                title: t("RCD operating current (mA)"),
                type: "string",
                path: "operatingCurrent",
            },
            {
                title: t(`Maximum permitted Zs (\u2126)`),
                type: "string",
                path: "maximumZs",
            },
            {
                title: t("Reference method"),
                type: "string",
                path: "referenceMethod",
            },
            {
                title: t("Live (mm\u00B2)"),
                type: "string",
                path: "live",
            },
            {
                title: t("cpc (mm\u00B2)"),
                type: "string",
                path: "cpc",
            },
            {
                title: t("Protected by RCD"),
                type: "string",
                path: "protectiveCircuit.description",
            },
        ];

        return detailTableColumns;
    }, [t]);

    return (
        <>
            {id && (
                <Asset
                    id={id}
                    displayName={t("Circuit")}
                    getColumns={getColumns}
                />
            )}
        </>
    );
};

export default Circuit;
