import { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Portal } from "../..";
import { useToggle } from "../../../hooks";
import { IFlag } from "../../../utils/api/misc";
import Button from "../../Button";
import Modal from "../../Modal";
import ModalBody from "../../Modal/ModalBody";
import Popover from "../../Popover/Popover";
import FlagItem from "../FlagItem/FlagItem";
import PropertyFlagEdit from "../PropertyFlagEdit";
import styles from "./PropertyFlagSelector.module.scss";

const PropertyFlagSelector = ({
    flags,
    notSelectedFlags,
    selectFlag,
    onFlagsCreate,
    onFlagUpdate,
    onFlagDelete,
    landlordId,
}: IPropertyFlagSelectorProps) => {
    const { t } = useTranslation();
    const { hide, toggle, visible } = useToggle();
    const {
        visible: modalVisible,
        hide: modalHide,
        show: modalShow,
    } = useToggle();

    const anchorElement = useRef<HTMLButtonElement>(null);

    const handleSelectFlag = useCallback(
        (flag: IFlag) => {
            selectFlag(flag.id);
            hide();
        },
        [hide, selectFlag],
    );

    const handleEditFlag = () => {
        modalShow();
        hide();
    };

    // TODO: Use Dropdown button.
    return (
        <div className={styles.container}>
            <Button onClick={toggle} setRef={anchorElement}>
                {t("Select Flag")}
            </Button>
            {visible && (
                <Popover anchorElement={anchorElement} hide={hide}>
                    {notSelectedFlags.map((flag) => (
                        <FlagItem
                            key={flag.id}
                            flag={flag}
                            onClick={handleSelectFlag}
                        />
                    ))}

                    <div className={styles.editOption}>
                        <Button onClick={handleEditFlag}>
                            {t("Edit Flags")}
                        </Button>
                    </div>
                </Popover>
            )}

            {modalVisible && (
                <Portal>
                    <Modal title={t("Edit Flags")} hide={modalHide}>
                        <ModalBody>
                            <PropertyFlagEdit
                                flags={flags}
                                onClose={modalHide}
                                onFlagsCreate={onFlagsCreate}
                                onFlagUpdate={onFlagUpdate}
                                onFlagDelete={onFlagDelete}
                                landlordId={landlordId}
                            />
                        </ModalBody>
                    </Modal>
                </Portal>
            )}
        </div>
    );
};

interface IPropertyFlagSelectorProps {
    flags: IFlag[];
    notSelectedFlags: IFlag[];
    selectFlag: (id: number) => void;
    onFlagsCreate: (flags: IFlag[]) => void;
    onFlagUpdate: (flag: IFlag) => void;
    onFlagDelete: (flag: IFlag) => void;
    landlordId: number;
}

export default PropertyFlagSelector;
