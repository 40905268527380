import { IRealtimeValue } from ".";

function realtimeCreateList<T extends IRealtimeValue, U extends T>(
    realtimeValue: U,
    currentValue: T[],
) {
    const list = [...currentValue];

    const exists = list.findIndex((val) => val.id === realtimeValue.id);

    if (exists === -1) {
        // TODO: If the currentValue array is empty, the full model will be pushed in the array. Find a better solution.
        if (list.length > 0) {
            Object.keys(list[0]).forEach((key) => {
                if (!Object.prototype.hasOwnProperty.call(realtimeValue, key)) {
                    delete realtimeValue[key];
                }
            });
        }

        list.push(realtimeValue);

        return list;
    }

    return false;
}

export default realtimeCreateList;
