import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Observable } from "rxjs";
import { ITenant, IUpdateTenant } from ".";
import { UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const useUpdateTenant = (
    propertyId: number,
): [
    (tenantId: number, data: IUpdateTenant) => Observable<ITenant>,
    boolean,
    string,
] => {
    const { t } = useTranslation();
    const { activeUserParentsIds } = useContext(UserContext);
    const { send, loading, error } = useApiRequest<ITenant>({
        errorMessage: t("Unexpected error occurred. Please try again later."),
    });
    const sendRequest = useXTagApiRequest();

    const updateTenant = useCallback(
        (tenantId: number, data: IUpdateTenant) =>
            send(
                sendRequest({
                    url: `${config.propertiesApiUrl}/${propertyId}/tenants/${tenantId}`,
                    method: "PUT",
                    urlParams: getGlobalUrlParameters([], activeUserParentsIds),
                    body: data,
                }),
            ),
        [send, sendRequest, propertyId, activeUserParentsIds],
    );

    return [updateTenant, loading, error];
};

export default useUpdateTenant;
