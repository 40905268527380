import { Observable } from "rxjs";
import EditableListItemDeleteModal from "./EditableListItemDeleteModal";

export interface IEditableListItemDeleteModalProps<T> {
    id: string;
    onDelete: (entity: T) => void;
    hide: () => void;
    deleteRequest: (id: string) => Observable<T>;
    title: string;
    message: string;
}

export default EditableListItemDeleteModal;
