import { useTranslation } from "react-i18next";
import { IMessage } from ".";
import { IMention } from "../../utils/api/issues";
import { getDisplayDifference, getNow } from "../../utils/dates";
import Timeline from "../SimpleTable/Timeline/Timeline";
import styles from "./MessageLog.module.scss";
import { MessageLogImage } from "./MessageLogImage";
import { MessageLogText } from "./MessageLogText";
import PostComment from "./PostComment";

const MessageLog = ({
    messages,
    onCreateComment,
    loading,
}: IMessageLogProps) => {
    const { t } = useTranslation();

    const parseCommentDate = (date: Date) => {
        const commentDate = getDisplayDifference(date, getNow());
        return t(commentDate.key, {
            count: commentDate.count,
        });
    };

    return (
        <>
            {messages.length ? (
                <div className={styles.messageScrollView}>
                    {messages.map((message, index) => (
                        <div className={styles.messageLogWrapper} key={index}>
                            <Timeline
                                index={index}
                                length={messages.length}
                                cssRules={{ marginRight: 10 }}
                            />
                            <div className={styles.comment}>
                                <div className={styles.commentHeader}>
                                    <div className={styles.imageContainer}>
                                        <MessageLogImage
                                            image={message.imageUrl}
                                        />
                                    </div>
                                    <div className={styles.commentHeaderRight}>
                                        <div
                                            className={
                                                styles.commentHeaderRightStatus
                                            }
                                        >
                                            <div className={styles.username}>
                                                {message.user}
                                            </div>
                                            {message.messageStatus}
                                        </div>
                                        <div>
                                            <div className={styles.commentDate}>
                                                {parseCommentDate(
                                                    message.messageDateTime,
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {message.text && (
                                    <MessageLogText
                                        comment={message.text}
                                        mentions={message.mentions}
                                    />
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                t("No comments found")
            )}
            <PostComment handleSubmit={onCreateComment} loading={loading} />
        </>
    );
};

interface IMessageLogProps {
    loading: boolean;
    messages: IMessage[];
    onCreateComment: (comment: string, mentions: IMention[]) => void;
}

export default MessageLog;
