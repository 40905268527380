import { ReactNode, useRef } from "react";
import { noop } from "rxjs";
import { IDropdownItem } from ".";
import { Button, Popover } from "..";
import { useToggle } from "../../hooks";
import { ButtonSize, ButtonVariant } from "../Button";
import PopoverListItem from "../Popover/PopoverListItem";

const Dropdown = ({
    label,
    items,
    onClick = noop,
    variant,
    size,
}: IDropdownProps) => {
    const anchorElement = useRef<HTMLButtonElement>(null);
    const { hide, toggle, visible } = useToggle();

    return (
        <>
            <Button
                onClick={toggle}
                setRef={anchorElement}
                variant={variant}
                size={size}
            >
                {label}
            </Button>

            {visible && (
                <Popover anchorElement={anchorElement} hide={hide}>
                    {items.map((item) => {
                        const handleClick = () => {
                            toggle();

                            if (item.onClick) {
                                item.onClick();
                            }
                            onClick(item.value);
                        };

                        return (
                            <PopoverListItem
                                key={item.label}
                                onClick={handleClick}
                            >
                                {item.label}
                            </PopoverListItem>
                        );
                    })}
                </Popover>
            )}
        </>
    );
};

interface IDropdownProps {
    label: ReactNode;
    items: IDropdownItem[];
    onClick?: (value: any) => void;
    variant?: ButtonVariant;
    size?: ButtonSize;
}

export default Dropdown;
