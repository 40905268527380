import { ReactNode, useEffect, useState } from "react";
import { IMention } from "../../../utils/api/issues";
import Link from "../../Link";
import styles from "./MessageLogText.module.scss";

// TODO: Refactor this component as this code doesn't follow the React principles.
const MessageLogText = ({ comment, mentions }: IMessageLogTextProps) => {
    const [text, setText] = useState<ReactNode>([]);

    useEffect(() => {
        const parsedComment: ReactNode[] = [];
        let startOffset = 0;

        if (mentions.length) {
            mentions.forEach((mention, index) => {
                const start = comment.substr(
                    startOffset,
                    mention.mentionPosition.offset - startOffset,
                );
                const mentionToInsert = (
                    <Link key={index} url="">
                        {mention.name}
                    </Link>
                );

                parsedComment.push(start);
                parsedComment.push(mentionToInsert);

                if (index === mentions.length - 1) {
                    parsedComment.push(
                        comment.substr(
                            mention.mentionPosition.offset +
                                mention.mentionPosition.length,
                        ),
                    );
                }

                startOffset =
                    mention.mentionPosition.offset +
                    mention.mentionPosition.length;
            });

            setText(parsedComment);
        } else {
            setText([comment]);
        }
    }, [comment, mentions]);

    return <div className={styles.messageText}>{text}</div>;
};

export default MessageLogText;

interface IMessageLogTextProps {
    comment: string;
    mentions: IMention[];
}
