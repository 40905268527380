import { IAuditComment } from "../../../utils/api/audits";
import CommentsList from "./CommentsList";

export interface ICommentsListProps {
    auditId: number;
    isRemedial: boolean;
    isReadOnly: boolean;
    comments: IAuditComment[];
    createComment: (comment: IAuditComment) => void;
}

export default CommentsList;
