import { SegmentProvider } from "@newfront/react-segment-hooks";
import {
    Loading,
    PropertyCategoriesProvider,
    RealtimeProvider,
    UserPreferencesProvider,
    UserProvider,
} from "..";
import config from "../../config";
import { useGetPreferences } from "../../utils/api/users";
import NotificationsProvider from "../NotificationsProvider";
import { AuthenticationError } from "../UserAuthentication/components";
import Layout from "./Layout";
import ProvidersStatus from "./ProvidersStatus";

const MainLayout = () => {
    const { value, updateValue, error, loading } = useGetPreferences();

    return (
        <>
            {loading && <Loading />}

            {!loading && !error && (
                <SegmentProvider apiKey={config.segmentApiKey}>
                    <UserPreferencesProvider
                        preferencesId={value.id}
                        preferences={value.preferences}
                        savePreferences={updateValue}
                    >
                        <UserProvider>
                            <NotificationsProvider>
                                <PropertyCategoriesProvider>
                                    <RealtimeProvider>
                                        <ProvidersStatus>
                                            <Layout />
                                        </ProvidersStatus>
                                    </RealtimeProvider>
                                </PropertyCategoriesProvider>
                            </NotificationsProvider>
                        </UserProvider>
                    </UserPreferencesProvider>
                </SegmentProvider>
            )}

            {error && (
                <AuthenticationError
                    errorCode={
                        "A problem occurred when trying to retrieve your account details. Please sign out and try again. If the problem persists, contact support."
                    }
                />
            )}
        </>
    );
};

export default MainLayout;
