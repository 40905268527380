import { useEffect, useState } from "react";
import { useFileUrl } from "../utils/api/misc";

export const useAuthorizedImage = (image: string) => {
    const [imageUrl, setImageUrl] = useState("");
    const getFileUrl = useFileUrl();

    useEffect(() => {
        if (image) {
            const subscription = getFileUrl(image).subscribe((url) => {
                setImageUrl(url);
            });

            return () => {
                if (subscription) {
                    subscription.unsubscribe();
                }
            };
        }
    }, [getFileUrl, image]);

    return imageUrl;
};
