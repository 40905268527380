import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
    Card,
    Grid,
    GridColumn,
    Loading,
    TabGroup,
    TabGroupTab,
} from "../../components";
import IssuesLog from "../../components/IssuesLog";
import ObservationsLog from "../../components/ObservationsLog";
import TagsTable from "../../components/TagsTable";
import { useHasUserParentPermission } from "../../hooks";
import { useDocuments } from "../../utils/api/documents";
import { useIssuesForModel } from "../../utils/api/issues";
import { useProperty } from "../../utils/api/properties";
import PageNotFound from "../PageNotFound";
import AllocatedJobsTab from "./AllocatedJobsTab";
import AppliancesTab from "./AppliancesTab";
import ComplianceTypesTab from "./ComplianceTypesTab";
import DistributionBoards from "./DistributionBoards";
import JobsTab from "./JobsTab";
import ManualUploadsTab from "./ManualUploadsTab";
import NextServiceDateCard from "./NextServiceDateCard";
import NotesTab from "./NotesTab";
import styles from "./Property.module.scss";
import PropertyDetails from "./PropertyDetails";
import PropertyPaperworkCard from "./PropertyPaperworkCard";
import PropertyTabHeader from "./PropertyTabHeader";
import TenantsTab from "./TenantsTab";
import UtaList from "./UtaList";

const height = 360;
const Property = () => {
    const { t } = useTranslation();
    const { id } = useParams();

    const { value, error, loading, updateValue } = useProperty(id ?? "");

    const { records: issues, loading: issuesLoading } = useIssuesForModel({
        id: id ?? "",
        modelType: "property",
    });

    const {
        records: jobDocumentsRecords,
        refresh: jobDocumentsRefresh,
        loaded: jobDocumentsLoaded,
    } = useDocuments({
        jobPropertyId: id,
    });

    const canViewElecTag = useHasUserParentPermission(
        "electag",
        value?.landlord.userId,
    );

    return loading || issuesLoading ? (
        <Loading />
    ) : error ? (
        <PageNotFound />
    ) : (
        value && (
            <>
                <div className={styles.container}>
                    <Grid fullHeight={true}>
                        <GridColumn size="twoThirds" fullHeight={true}>
                            <PropertyDetails
                                property={value}
                                updateProperty={updateValue}
                            />
                        </GridColumn>

                        <GridColumn size="oneThird" fullHeight={true}>
                            <NextServiceDateCard
                                nextServiceDueDates={value.nextServiceDueDates}
                            />
                        </GridColumn>
                    </Grid>
                </div>

                <div className={styles.container}>
                    <Grid fullHeight={true}>
                        <GridColumn size="twoThirds" fullHeight={true}>
                            <Card fullHeight={true} padding={false}>
                                <TabGroup
                                    contentMaxHeight={height}
                                    fullHeight={true}
                                >
                                    <TabGroupTab
                                        header={
                                            <PropertyTabHeader
                                                icon="details"
                                                label={t("Assets")}
                                            />
                                        }
                                    >
                                        <AppliancesTab propertyId={value.id} />
                                    </TabGroupTab>

                                    {canViewElecTag && (
                                        <TabGroupTab
                                            header={
                                                <PropertyTabHeader
                                                    icon="circuits"
                                                    label={t(
                                                        "Distribution boards",
                                                    )}
                                                />
                                            }
                                        >
                                            <DistributionBoards
                                                propertyId={value.id}
                                            />
                                        </TabGroupTab>
                                    )}

                                    {id !== undefined && canViewElecTag && (
                                        <TabGroupTab
                                            header={
                                                <PropertyTabHeader
                                                    icon="eye"
                                                    label={t("Observations")}
                                                />
                                            }
                                        >
                                            <ObservationsLog
                                                modelType="property"
                                                id={id}
                                            />
                                        </TabGroupTab>
                                    )}

                                    <TabGroupTab
                                        header={
                                            <PropertyTabHeader
                                                icon="uta-details"
                                                label={t("Unable to access")}
                                            />
                                        }
                                    >
                                        <UtaList propertyId={value.id} />
                                    </TabGroupTab>

                                    <TabGroupTab
                                        header={
                                            <PropertyTabHeader
                                                icon="contacts"
                                                label={t("Tenants")}
                                            />
                                        }
                                    >
                                        <TenantsTab
                                            property={value}
                                            updateProperty={updateValue}
                                        />
                                    </TabGroupTab>

                                    <TabGroupTab
                                        header={
                                            <PropertyTabHeader
                                                icon="buildings"
                                                label={t("Compliance types")}
                                            />
                                        }
                                    >
                                        <ComplianceTypesTab
                                            property={value}
                                            updateProperty={updateValue}
                                        />
                                    </TabGroupTab>
                                </TabGroup>
                            </Card>
                        </GridColumn>

                        <GridColumn size="oneThird" fullHeight={true}>
                            <PropertyPaperworkCard
                                property={value}
                                jobDocumentsLoaded={jobDocumentsLoaded}
                                jobDocumentsRecords={jobDocumentsRecords}
                                jobDocumentsRefresh={jobDocumentsRefresh}
                            />
                        </GridColumn>
                    </Grid>
                </div>

                <div className={styles.container}>
                    <Grid fullHeight={true}>
                        <GridColumn size="twoThirds" fullHeight={true}>
                            <Card fullHeight={true} padding={false}>
                                <TabGroup
                                    contentMaxHeight={height}
                                    fullHeight={true}
                                >
                                    <TabGroupTab
                                        header={
                                            <PropertyTabHeader
                                                icon="details"
                                                label={t("Jobs")}
                                            />
                                        }
                                    >
                                        <JobsTab
                                            documents={jobDocumentsRecords}
                                            propertyId={value.id}
                                        />
                                    </TabGroupTab>

                                    <TabGroupTab
                                        header={
                                            <PropertyTabHeader
                                                icon="upload"
                                                label={t("Manual uploads")}
                                            />
                                        }
                                    >
                                        <ManualUploadsTab
                                            propertyId={value.id}
                                        />
                                    </TabGroupTab>

                                    <TabGroupTab
                                        header={
                                            <PropertyTabHeader
                                                icon="buildings"
                                                label={t("Allocated jobs")}
                                            />
                                        }
                                    >
                                        <AllocatedJobsTab
                                            propertyId={value.id}
                                        />
                                    </TabGroupTab>

                                    <TabGroupTab
                                        header={
                                            <PropertyTabHeader
                                                icon="tag-details"
                                                label={t("Tags")}
                                            />
                                        }
                                    >
                                        {value.tags && value.tags.length > 0 ? (
                                            <TagsTable tags={value.tags} />
                                        ) : (
                                            t("No tags registered")
                                        )}
                                    </TabGroupTab>

                                    <TabGroupTab
                                        header={
                                            <PropertyTabHeader
                                                icon="notes"
                                                label={t("Notes")}
                                            />
                                        }
                                    >
                                        <NotesTab propertyId={value.id} />
                                    </TabGroupTab>
                                </TabGroup>
                            </Card>
                        </GridColumn>

                        <GridColumn size="oneThird" fullHeight={true}>
                            <IssuesLog issues={issues} fullHeight={true} />
                        </GridColumn>
                    </Grid>
                </div>
            </>
        )
    );
};

export default Property;
