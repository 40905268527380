import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, Loading, NotificationsContext } from "../../components";
import { TabContainer } from "../../components/TabContainer";
import {
    IUpdateUserNotificationTypes,
    IUserNotificationType,
} from "../../utils/api/users";
import useUpdateUserNotificationTypes from "../../utils/api/users/useUpdateUserNotificationTypes";
import NotificationTab from "./NotificationTab";

const UserNotifications = () => {
    const { t } = useTranslation();

    const [activeNotificationTypes, setActiveNotificationTypes] = useState<
        IUserNotificationType[]
    >([]);

    const notificationsContext = useContext(NotificationsContext);
    const updateUserNotificationTypes = useUpdateUserNotificationTypes();

    useEffect(() => {
        setActiveNotificationTypes(
            notificationsContext.activeNotificationTypes,
        );
    }, [notificationsContext.activeNotificationTypes]);

    const saveNotificationTypes = () => {
        const notificationTypesUpdateModel: IUpdateUserNotificationTypes[] =
            activeNotificationTypes.map((notificationTypes) => ({
                notificationTypeId: notificationTypes.notification_type_id,
                showPopup: notificationTypes.show_popup,
            }));

        // TODO: Move this to NotificationsContext, and update the activeNotifiationTypes list on subscribe.
        updateUserNotificationTypes
            .updateUserNotificationTypes(notificationTypesUpdateModel)
            .subscribe();
    };

    const updateActiveNotificationTypes = (
        updatedNotificationType: IUserNotificationType,
        active: boolean,
    ) => {
        let updatedNotificationTypes = activeNotificationTypes.slice();

        if (active) {
            const index = updatedNotificationTypes.findIndex(
                (notificationType) =>
                    notificationType.notification_type_id ===
                    updatedNotificationType.id,
            );

            if (index === -1) {
                updatedNotificationTypes.push(updatedNotificationType);
            }
            setActiveNotificationTypes(updatedNotificationTypes);
        } else {
            updatedNotificationTypes = activeNotificationTypes.filter(
                (notificationType) =>
                    notificationType.notification_type_id !==
                    updatedNotificationType.id,
            );
            setActiveNotificationTypes(updatedNotificationTypes);
        }
    };

    const updatePopup = (updatedNotificationType: IUserNotificationType) => {
        const updatedNotificationTypes = activeNotificationTypes.slice();

        const index = updatedNotificationTypes.findIndex(
            (notificationType) =>
                notificationType.notification_type_id ===
                updatedNotificationType.id,
        );

        if (index !== -1) {
            updatedNotificationTypes[index] = updatedNotificationType;
            setActiveNotificationTypes(updatedNotificationTypes);
        }
    };

    return (
        <Card title={t("Notification Preferences")}>
            <p>{t("Toggle which notifications will appear")}</p>
            <TabContainer>
                <NotificationTab
                    header={t("ISSUE")}
                    notificationTypes={notificationsContext.issueTypes}
                    activeNotificationTypes={activeNotificationTypes}
                    updateActiveNotificationTypes={
                        updateActiveNotificationTypes
                    }
                    updatePopup={updatePopup}
                />
                <NotificationTab
                    header={t("STANDARD")}
                    notificationTypes={notificationsContext.standardTypes}
                    activeNotificationTypes={activeNotificationTypes}
                    updateActiveNotificationTypes={
                        updateActiveNotificationTypes
                    }
                    updatePopup={updatePopup}
                />
            </TabContainer>
            {!updateUserNotificationTypes.loading ? (
                <Button variant="primary" onClick={saveNotificationTypes}>
                    {t("Save")}
                </Button>
            ) : (
                <Loading small={true} />
            )}
        </Card>
    );
};

export default UserNotifications;
