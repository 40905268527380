import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { IUpdateUserModalProps } from ".";
import { Button, Form, Modal, Portal } from "../../../components";
import ModalBody from "../../../components/Modal/ModalBody";
import ModalFooter from "../../../components/Modal/ModalFooter";
import { useAdminUpdateUser } from "../../../utils/api/users";
import { UpdateUserContext, useUpdateUserFields } from "../UpdateUserContext";
import UpdateUserFields from "../UpdateUserFields";

const UpdateUserModal = ({
    user,
    onUpdateUser,
    hide,
}: IUpdateUserModalProps) => {
    const { t } = useTranslation();
    const { updateUser, error, loading } = useAdminUpdateUser();

    const updateUserContext = useUpdateUserFields(user, []);
    const { updatedUser, formValidation } = updateUserContext;

    const handleSubmit = useCallback(() => {
        updateUser(user.id, updatedUser).subscribe(() => {
            hide();
            onUpdateUser();
        });
    }, [updateUser, user.id, updatedUser, hide, onUpdateUser]);

    return (
        <Portal>
            <UpdateUserContext.Provider value={updateUserContext}>
                <Form onSubmit={handleSubmit} {...formValidation}>
                    <Modal title={t("Update User")} hide={hide}>
                        <ModalBody>
                            <UpdateUserFields error={error} />
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                variant="primary"
                                type="submit"
                                disabled={loading}
                            >
                                {t("Update User")}
                            </Button>
                        </ModalFooter>
                    </Modal>
                </Form>
            </UpdateUserContext.Provider>
        </Portal>
    );
};

export default UpdateUserModal;
