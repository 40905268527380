import { IAssetListItemProps } from ".";
import { ImageList, Link } from "..";

const AssetListItem = ({ asset }: IAssetListItemProps) => {
    return (
        <ImageList key={asset.id} image={asset.imageUrl || ""}>
            <Link url={`/management/circuits/${asset.id}`}>
                {asset.type.displayName}: {asset.description}
            </Link>
        </ImageList>
    );
};

export default AssetListItem;
