import { useMemo } from "react";
import { IBoard } from ".";
import config from "../../../config";
import useAllFilterable from "../useAllFilterable";

const useBoardsAtProperty = (propertyId: number) => {
    const { records, loaded } = useAllFilterable<IBoard>({
        url: `${config.assetsApiUrl}/boards`,
        sortProperties: ["id"],
        filters: {
            propertyId: {
                operator: "{AND}",
                readOnly: true,
                filters: [{ function: "=", value: propertyId.toString() }],
            },
            active: {
                operator: "{AND}",
                readOnly: true,
                filters: [{ function: "=", value: "true" }],
            },
        },
    });

    const orderedRecords = useMemo(() => {
        records.forEach((n) => {
            n.circuits.sort((a, b) => (a.wayIndex < b.wayIndex ? -1 : 1));
        });

        return records;
    }, [records]);

    return { orderedRecords, loaded };
};

export default useBoardsAtProperty;
