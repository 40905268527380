import { CSSProperties, ReactNode, useMemo } from "react";
import { useCssClasses } from "../../hooks";
import defaultImage from "../../images/default-property.png";
import styles from "./Img.module.scss";

// TODO: Make this a simple img element and move the background image to ImageHero component.
const Img = ({
    url,
    size,
    width,
    height,
    circle = false,
    display = "",
    children,
    cssRules = {},
    testId,
}: IImageProps) => {
    const cssClasses = useCssClasses(
        styles.container,
        height || size ? "" : styles.paddingBottom,
        circle ? styles.circle : "",
        display === "inline-block" ? styles.inlineBlock : "",
    );

    const style = useMemo(() => {
        const temp = { ...cssRules };

        if (url) {
            temp.backgroundImage = `url('${url}')`;
        } else if (!children) {
            temp.backgroundImage = `url('${defaultImage}')`;
        }

        if (width || size) {
            temp.width = `${width || size}px`;
        }

        if (height || size) {
            temp.height = `${height || size}px`;
            temp.lineHeight = `${height || size}px`;
        }

        return temp;
    }, [children, cssRules, height, size, url, width]);

    return (
        <div className={cssClasses} style={style} data-test-id={testId}>
            {children}
        </div>
    );
};

export type Display = "" | "inline-block";

interface IImageProps {
    url: string | null;
    size?: number;
    width?: number;
    height?: number;
    circle?: boolean;
    display?: Display;
    children?: ReactNode;
    cssRules?: CSSProperties;
    testId?: string;
}

export default Img;
