import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IPropertyCategoryCardProps } from ".";
import { Card, Checkbox, InputField, Select } from "../../../../components";
import { ISelectOption } from "../../../../components/Select";
import ToggleButton from "../../../../components/ToggleButton";
import PropertyCategoryAccessLevel from "../PropertyCategoryAccessLevel";
import styles from "./PropertyCategoryCard.module.scss";

const PropertyCategoryCard = ({
    propertyCategory,
    integrations,
    updatePropertyCategory,
}: IPropertyCategoryCardProps) => {
    const { t } = useTranslation();

    const handleResetTenantChange = useCallback(
        (checked: boolean) => {
            updatePropertyCategory({
                ...propertyCategory,
                notResetTenantAppliance: checked,
            });
        },
        [propertyCategory, updatePropertyCategory],
    );

    const handleResetInstallChange = useCallback(
        (checked: boolean) => {
            updatePropertyCategory({
                ...propertyCategory,
                notResetInstallAppliance: checked,
            });
        },
        [propertyCategory, updatePropertyCategory],
    );

    const motStyleChecksPropertyCategories = useMemo(
        () => [
            "gas",
            "electric",
            "oil",
            "solid_fuel",
            "unvented",
            "ashp",
            "solar",
        ],
        [],
    );

    const booleanOptions = useMemo(
        () => [
            {
                label: t("Yes"),
                value: "true",
            },
            {
                label: t("No"),
                value: "false",
            },
        ],
        [t],
    );

    const handleMotStyleChecksChange = useCallback(
        (value: string) => {
            updatePropertyCategory({
                ...propertyCategory,
                motStyleChecks: value === "true",
            });
        },
        [propertyCategory, updatePropertyCategory],
    );

    const handleHidePiiFromDocumentsChange = useCallback(
        (value: string) => {
            updatePropertyCategory({
                ...propertyCategory,
                hidePiiFromDocuments: value === "true",
            });
        },
        [propertyCategory, updatePropertyCategory],
    );

    const handleserviceDateResetMonthsChange = useCallback(
        (value: string) => {
            updatePropertyCategory({
                ...propertyCategory,
                serviceDateResetMonths: Number(value),
            });
        },
        [propertyCategory, updatePropertyCategory],
    );

    const auditPercentageOptions = useMemo(() => {
        const list: ISelectOption[] = [];
        for (let i = 0; i <= 100; i += 10) {
            list.push({ label: t(`${i}%`), value: i.toString() });
        }

        return list;
    }, [t]);

    const handleAuditPercentageChange = useCallback(
        (value: string) => {
            updatePropertyCategory({
                ...propertyCategory,
                auditPercentage: Number(value),
            });
        },
        [propertyCategory, updatePropertyCategory],
    );

    const handleUnsatisfactoryEicrResetServiceDaysToggle = useCallback(
        (value: string) => {
            updatePropertyCategory({
                ...propertyCategory,
                unsatisfactoryEicrResetServiceDays:
                    value === "true" ? 60 : null,
            });
        },
        [propertyCategory, updatePropertyCategory],
    );

    const handleUnsatisfactoryEicrResetServiceDaysChange = useCallback(
        (value: string) => {
            updatePropertyCategory({
                ...propertyCategory,
                unsatisfactoryEicrResetServiceDays: Number(value),
            });
        },
        [propertyCategory, updatePropertyCategory],
    );

    const handlePreventRemedialDateResetChange = useCallback(
        (value: string) => {
            updatePropertyCategory({
                ...propertyCategory,
                preventRemedialDateReset: value === "true",
            });
        },
        [propertyCategory, updatePropertyCategory],
    );

    const handleIntegrationChange = useCallback(
        (checked: boolean, value: number) => {
            updatePropertyCategory({
                ...propertyCategory,
                integrationTypes: checked
                    ? [...propertyCategory.integrationTypes, value]
                    : propertyCategory.integrationTypes.filter(
                          (i) => i !== value,
                      ),
            });
        },
        [propertyCategory, updatePropertyCategory],
    );

    return (
        <Card
            fullHeight={true}
            title={
                <span className={styles.title}>
                    {propertyCategory.propertyCategory.displayName}
                </span>
            }
            titleCssRules={{
                backgroundColor: propertyCategory.propertyCategory.colour,
            }}
        >
            {propertyCategory.propertyCategory.name === "gas" && (
                <>
                    <div className={styles.section}>
                        <span>
                            {t("The current LGSR will remain valid when")}
                        </span>

                        <div>
                            <ToggleButton
                                value="false"
                                checked={
                                    propertyCategory.notResetTenantAppliance
                                }
                                onChange={handleResetTenantChange}
                            >
                                {t("A tenants asset is registered")}
                            </ToggleButton>

                            <ToggleButton
                                value="false"
                                checked={
                                    propertyCategory.notResetInstallAppliance
                                }
                                onChange={handleResetInstallChange}
                            >
                                {t("A landlords asset is installed")}
                            </ToggleButton>
                        </div>
                    </div>

                    <div className={styles.section}>
                        <span>
                            {t(
                                "Hide personal identifiable information from documents",
                            )}
                        </span>

                        <Select
                            options={booleanOptions}
                            onChange={handleHidePiiFromDocumentsChange}
                            value={propertyCategory.hidePiiFromDocuments.toString()}
                        />
                    </div>
                </>
            )}

            {motStyleChecksPropertyCategories.includes(
                propertyCategory.propertyCategory.name,
            ) && (
                <div className={styles.section}>
                    <span>{t("MOT Style Checks")}</span>

                    <Select
                        options={booleanOptions}
                        onChange={handleMotStyleChecksChange}
                        value={propertyCategory.motStyleChecks.toString()}
                    />
                </div>
            )}

            {propertyCategory.propertyCategory.name === "electric" && (
                <>
                    <div className={styles.section}>
                        <span>
                            {t("Prevent Remedial Jobs from Resetting Date")}
                        </span>

                        <Select
                            options={booleanOptions}
                            onChange={handlePreventRemedialDateResetChange}
                            value={propertyCategory.preventRemedialDateReset.toString()}
                        />
                    </div>
                    <div className={styles.section}>
                        <span>{t("Reset Date on Unsatisfactory EICR")}</span>

                        <Select
                            options={booleanOptions}
                            onChange={
                                handleUnsatisfactoryEicrResetServiceDaysToggle
                            }
                            value={
                                propertyCategory.unsatisfactoryEicrResetServiceDays !==
                                null
                                    ? "true"
                                    : "false"
                            }
                        />
                    </div>

                    {propertyCategory.unsatisfactoryEicrResetServiceDays !==
                        null && (
                        <div className={styles.section}>
                            <span>
                                {t("Unsatisfactory EICR Service Reset")}
                            </span>

                            <InputField
                                value={propertyCategory.unsatisfactoryEicrResetServiceDays.toString()}
                                type="number"
                                placeholder={t("Reset service in days")}
                                append="days"
                                onChange={
                                    handleUnsatisfactoryEicrResetServiceDaysChange
                                }
                            />
                        </div>
                    )}
                </>
            )}

            <div className={styles.section}>
                <span>{t("Service Reset")}</span>

                <InputField
                    value={propertyCategory.serviceDateResetMonths.toString()}
                    type="number"
                    placeholder={t("Reset service in months")}
                    append="months"
                    onChange={handleserviceDateResetMonthsChange}
                    disabled={propertyCategory.propertyCategory.name === "gas"}
                />
            </div>

            <div className={styles.section}>
                <span>{t("Audit Percentage")}</span>

                <Select
                    allowEmpty={true}
                    options={auditPercentageOptions}
                    onChange={handleAuditPercentageChange}
                    value={propertyCategory.auditPercentage.toString()}
                />
            </div>

            <div className={styles.section}>
                <span>{t("Access Level")}</span>

                <PropertyCategoryAccessLevel
                    propertyCategory={propertyCategory}
                    updatePropertyCategory={updatePropertyCategory}
                />
            </div>

            {integrations.map((integration) => (
                <div key={integration.id} className={styles.section}>
                    <Checkbox
                        checked={propertyCategory.integrationTypes.includes(
                            integration.id,
                        )}
                        onChange={handleIntegrationChange}
                        value={integration.id}
                    >
                        {t("Sync to {{integration}}", {
                            integration: integration.displayName,
                        })}
                    </Checkbox>
                </div>
            ))}
        </Card>
    );
};

export default PropertyCategoryCard;
