import { useMemo, useState } from "react";

function useFilter<T>(records: T[], getSearchFields: (record: T) => string[]) {
    const [search, setSearch] = useState("");

    const filteredRecords = useMemo(() => {
        if (search) {
            return records.filter((record) =>
                getSearchFields(record)
                    .map((r) => r.toLowerCase())
                    .some((r) => r.includes(search.toLowerCase())),
            );
        }

        return records;
    }, [getSearchFields, records, search]);

    return {
        search,
        filteredRecords,
        handleSearchChange: setSearch,
    };
}

export default useFilter;
