import { useState, useEffect } from "react";
import { getNow } from "../utils/dates";

export const useNow = () => {
    const [now, setNow] = useState(getNow());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setNow(getNow());
        }, 10000);

        return () => clearInterval(intervalId);
    }, []);

    return now;
};
