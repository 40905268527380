import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ISearchableSelectProps } from ".";
import { Button, InputField, Popover } from "..";
import { useToggle } from "../../hooks";
import useAccessibleCustomSelect from "../../hooks/useAccessibleCustomSelect";
import Select from "../Select";
import SelectItem from "../SelectItem";

const SearchableSelect = ({
    options,
    label = "",
    value = "",
    search = "",
    useMargin = true,
    onChange = () => null,
    isValid,
    error,
    cssRules,
    applySearch,
    hasMore = false,
    loadNextPage = () => null,
    applyPadding,
    customRender,
    disabled = false,
    size,
    placeholder,
    autoComplete,
}: ISearchableSelectProps) => {
    const { t } = useTranslation();
    const { show, hide, visible } = useToggle();
    const searchElement = useRef<HTMLInputElement>(null);

    const selectOptions = useMemo(
        () => options.filter((option) => option.value === value),
        [options, value],
    );

    const [query, setQuery] = useState(search);

    useEffect(() => {
        if (autoComplete && options.length === 1 && query !== search) {
            hide();
            onChange(options[0].value);
        }
    }, [autoComplete, hide, onChange, options, query, search]);

    const handleDeselectClick = () => {
        onChange("");
        applySearch("");
        hide();
    };

    const handleOptionClicked = useCallback(
        (selectedValue: string) => {
            hide();
            onChange(selectedValue);
        },
        [hide, onChange],
    );

    const handleChange = useMemo(
        () =>
            debounce((filter: string) => {
                setQuery(filter);
                applySearch(filter);
            }, 300),
        [applySearch],
    );

    const { focusedIndex, onKeyDown } = useAccessibleCustomSelect(
        true,
        value,
        options,
        visible,
        show,
        hide,
        handleOptionClicked,
        true,
    );

    return (
        <>
            {!value ? (
                <>
                    <InputField
                        label={label}
                        placeholder={placeholder}
                        onChange={handleChange}
                        setRef={searchElement}
                        value={search}
                        isValid={isValid}
                        error={error}
                        useMargin={useMargin}
                        cssRules={cssRules}
                        onFocus={show}
                        onKeyDown={onKeyDown}
                        disabled={disabled}
                        size={size}
                    />

                    {visible && (
                        <Popover
                            anchorElement={searchElement}
                            placement="bottom-start"
                            hide={hide}
                            enableFocusTrap={false}
                        >
                            {options.map((option, index) => (
                                <SelectItem
                                    key={option.value}
                                    value={option.value}
                                    isSelected={focusedIndex === index}
                                    onClick={handleOptionClicked}
                                >
                                    {option.label}
                                </SelectItem>
                            ))}

                            {hasMore && (
                                <Button
                                    displayBlock={true}
                                    onClick={loadNextPage}
                                >
                                    {t("Load more")}
                                </Button>
                            )}
                        </Popover>
                    )}
                </>
            ) : (
                <Select
                    label={label}
                    useMargin={useMargin}
                    cssRules={cssRules}
                    options={selectOptions}
                    value={value}
                    canDeselect={true}
                    onChange={handleDeselectClick}
                    applyPadding={applyPadding}
                    customRender={customRender}
                    size={size}
                />
            )}
        </>
    );
};

export default SearchableSelect;
