import { useCallback } from "react";
import { IContractor } from ".";
import config from "../../../config";
import { useXTagApiRequest } from "../../request";
import useApiValue from "../useApiValue";

const useSearchContractor = () => {
    const { value, send, loading, error } = useApiValue<IContractor>({
        discardPreviousValue: true,
    });
    const sendRequest = useXTagApiRequest();

    const getContractor = useCallback(
        (businessRegNo: string) => {
            return send(
                sendRequest({
                    url: `${config.landlordsApiUrl}/contractors/${businessRegNo}`,
                    method: "GET",
                }),
            );
        },
        [send, sendRequest],
    );

    return { value, getContractor, loading, error };
};

export default useSearchContractor;
