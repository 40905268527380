import NavigationModal from "./NavigationModal";

export interface INavigationModalProps {
    title: string;
    message?: string;
    hide: () => void;
    primaryLinks: { title: string; url: string; icon: string }[];
    description?: string;
}

export default NavigationModal;
