import { useCallback, useState } from "react";
import { IManualUploadsTabProps } from ".";
import { InfiniteScroll, JobTimeline } from "../../../components";
import { IJob } from "../../../components/JobTimeline";
import { usePropertyJobs } from "../../../utils/api/jobs";

const ManualUploadsTab = ({ propertyId }: IManualUploadsTabProps) => {
    const [manualUploads, setManualUploads] = useState<IJob[]>([]);

    const onAttemptsLoaded = useCallback(
        (values: IJob[], currentPage: number) => {
            setManualUploads((current) => {
                const newRecords = currentPage === 1 ? [] : [...current];

                newRecords.push(...values);

                return newRecords;
            });
        },
        [],
    );

    const { loading, currentPage, totalPages, goToNextPage } = usePropertyJobs(
        {
            propertyId: propertyId.toString(),
            recoveryJob: true,
        },
        onAttemptsLoaded,
    );

    const loadNextPage = useCallback(() => {
        if (currentPage < totalPages) {
            goToNextPage();
        }
    }, [currentPage, totalPages, goToNextPage]);

    return (
        <>
            <InfiniteScroll loading={loading} onScrollToBottom={loadNextPage}>
                <JobTimeline
                    jobs={manualUploads}
                    showTasks={false}
                    showJobIcons={true}
                    isCompact={true}
                />
            </InfiniteScroll>
        </>
    );
};

export default ManualUploadsTab;
