import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card, Gallery, useGallery } from "../../../components";
import { IImage } from "../../../utils/api/appliances";
import ApplianceImage from "../ApplianceImage/ApplianceImage";

const ApplianceImages = ({ images }: IApplianceImagesProps) => {
    const { t } = useTranslation();
    const { currentImageIndex, setCurrentImageIndex, show, hide, visible } =
        useGallery();

    const pictures = useMemo(
        () => images.map((image) => image.imageUrl).filter((image) => !!image),
        [images],
    );

    const openGallery = (image: number) => {
        setCurrentImageIndex(image);
        show();
    };

    return (
        <Card title={t("Recent Images")}>
            {pictures.length > 0
                ? pictures.map((picture, index) => (
                      <ApplianceImage
                          key={index}
                          imageUrl={picture}
                          onClick={openGallery}
                          clickParams={[index]}
                      />
                  ))
                : t("Looks like there are no images for this item.")}

            {visible && (
                <Gallery
                    images={pictures}
                    currentImageIndex={currentImageIndex}
                    setCurrentImageIndex={setCurrentImageIndex}
                    hide={hide}
                />
            )}
        </Card>
    );
};

interface IApplianceImagesProps {
    images: IImage[];
}

export default ApplianceImages;
