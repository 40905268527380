import { useMemo } from "react";
import { IOverviewCardProps } from ".";
import styles from "./OverviewCard.module.scss";

const OverviewCard = ({ phase }: IOverviewCardProps) => {
    const total = useMemo(
        () =>
            phase.dates.reduce(
                (t, date) =>
                    date.count.reduce(
                        (xTotal, fuelType) => xTotal + fuelType.count,
                        t,
                    ),
                0,
            ),
        [phase.dates],
    );

    return (
        <div className={styles.container}>
            <div
                className={styles.title}
                style={{ backgroundColor: phase.colour }}
            >
                {phase.title}
            </div>
            <div className={styles.value}>{total}</div>
        </div>
    );
};

export default OverviewCard;
