import { useCallback, useContext } from "react";
import { PropertyCategoriesContext, UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiValue from "../useApiValue";

export const useKPIs = () => {
    const { activeUserParentsIds } = useContext(UserContext);
    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { send, value, loaded } = useApiValue<{ [key: string]: string }>({});
    const sendRequest = useXTagApiRequest();

    const getKPIs = useCallback(
        (landlordUserId: string, date: string) =>
            send(
                sendRequest({
                    url: `${config.miscApiUrl}/kpis/${landlordUserId}`,
                    method: "GET",
                    urlParams: {
                        dateFrom: [date],
                        ...getGlobalUrlParameters(
                            selectedCategories.map((c) => c.id),
                            activeUserParentsIds,
                        ),
                    },
                }),
            ),
        [activeUserParentsIds, selectedCategories, send, sendRequest],
    );

    return { getKPIs, value, loaded };
};

export default useKPIs;
