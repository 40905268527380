import { useMemo } from "react";
import { OverriddenAnswers } from "../pages/QualifiedSupervisorAudits/QualifiedSupervisorAuditRow";
import { IAnswer } from "../utils/api/answers";

export const useOverriddenAnswerComment = (
    answers: IAnswer[],
    overriddenAnswers: OverriddenAnswers,
) => {
    const overriddenAnswersComment = useMemo(() => {
        for (const answer of answers) {
            const override = overriddenAnswers[answer.id];

            if (override?.comment) {
                return override?.comment;
            }
        }

        return "";
    }, [answers, overriddenAnswers]);

    return overriddenAnswersComment;
};
