import { IValidateField } from ".";

const validateForm = (validators: () => IValidateField[]) => {
    const isValid = validators()
        .map((validator) => validator.isValid)
        .every((v) => v);

    const validate = () =>
        validators()
            .map((validator) => validator.validate())
            .every((v) => v);

    return { validate, isValid };
};

export default validateForm;
