import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { map } from "rxjs";
import { IAnswer } from ".";
import { PropertyCategoriesContext, UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import { IUrlParameters } from "../../url";
import useApiValue from "../useApiValue";

const useAnswersForJob = (
    id: string,
    {
        includeDeletedAnswers,
        activeAssets,
        includePreviousConfigAnswers,
    }: IAnswersFilterableParameters,
) => {
    const { t } = useTranslation();
    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);
    const { send, value, loading, loaded, error } = useApiValue<IAnswer[]>({
        errorMessage: t("An error occured. Please try again."),
        initialValue: [],
    });
    const sendRequest = useXTagApiRequest();

    useEffect(() => {
        const urlParams: IUrlParameters = {};

        if (activeAssets !== undefined) {
            urlParams["activeAssets"] = [`${activeAssets}`];
        }

        if (includePreviousConfigAnswers !== undefined) {
            urlParams["includePreviousConfigAnswers"] = [
                `${includePreviousConfigAnswers}`,
            ];
        }

        if (includeDeletedAnswers === false) {
            urlParams["override.isDeleted"] = ["!=true"];
        }

        const subscription = send(
            sendRequest<IAnswer[], unknown>({
                url: `${config.answersApiUrl}/${id}`,
                method: "GET",
                urlParams: {
                    ...urlParams,
                    ...getGlobalUrlParameters(
                        selectedCategories.map((c) => c.id),
                        activeUserParentsIds,
                    ),
                },
            }).pipe(
                map((answers) => {
                    const cableTypes = answers.filter(
                        (a) =>
                            a.questionName === "E_CableTypeV2" ||
                            a.questionName === "E_ReferenceMethodV2",
                    );

                    for (const cableType of cableTypes) {
                        if (typeof cableType.payload === "string") {
                            cableType.payload = cableType.payload
                                .split("-")[0]
                                .trim();
                        }
                    }
                    return answers;
                }),
            ),
        ).subscribe();

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [
        includeDeletedAnswers,
        activeAssets,
        activeUserParentsIds,
        id,
        selectedCategories,
        includePreviousConfigAnswers,
        send,
        sendRequest,
    ]);

    return { value, loading, loaded, error };
};

interface IAnswersFilterableParameters {
    includeDeletedAnswers: boolean;
    activeAssets?: boolean;
    includePreviousConfigAnswers?: boolean;
}

export default useAnswersForJob;
