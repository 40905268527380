import { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { map, switchMap } from "rxjs";
import {
    Alert,
    Card,
    Grid,
    GridColumn,
    Img,
    UserContext,
} from "../../../components";
import FileUpload from "../../../components/FileUpload";
import { useAuthorizedImage } from "../../../hooks/useAuthorizedImage";
import { useUploadFile } from "../../../utils/api/misc";
import { useUpdateUser } from "../../../utils/api/users";
import styles from "./UserProfilePicture.module.scss";

const UserProfilePicture = () => {
    const { t } = useTranslation();

    const { user } = useContext(UserContext);
    const { updateUser, loading: updateUserLoading } = useUpdateUser();
    const { uploadFile, loading: uploadFileLoading } = useUploadFile();

    const [hasUploadError, setHasUploadError] = useState(false);

    const profileImageUrl = useAuthorizedImage(user.profileImageUrl);

    const handleFileSelected = useCallback(
        (filename: string, file: File | null) => {
            setHasUploadError(false);

            if (file) {
                uploadFile(`user/${user.id}/${filename}`, file)
                    .pipe(
                        map((r) => r.filename),
                        switchMap((fileUrl) =>
                            updateUser({
                                profileImageUrl: fileUrl,
                            }),
                        ),
                    )
                    .subscribe(
                        () => {},
                        () => {
                            setHasUploadError(true);
                        },
                    );
            }
        },
        [updateUser, uploadFile, user],
    );

    return (
        <Card title={t("Profile picture")}>
            <Grid>
                <GridColumn size="half">
                    <div className={styles.container}>
                        <Img url={profileImageUrl} size={200} />
                    </div>
                </GridColumn>

                <GridColumn size="half">
                    <FileUpload
                        label={t("Upload new profile picture")}
                        onFileSelected={handleFileSelected}
                        isLoading={uploadFileLoading || updateUserLoading}
                        allowedFileTypes={[".jpeg", ".jpg", ".png"]}
                        overrideFileName="profile_photo"
                    />

                    {hasUploadError && (
                        <Alert type="error">
                            {t("An error occured. Please try again.")}
                        </Alert>
                    )}
                </GridColumn>
            </Grid>
        </Card>
    );
};

export default UserProfilePicture;
