import { CSSProperties, ReactNode, useMemo } from "react";
import { useCssClasses } from "../../hooks";
import styles from "./Card.module.scss";

const Card = ({
    title,
    titleCssRules,
    actions,
    padding = true,
    fullHeight = false,
    maxHeight,
    children,
    bodyScroll = true,
    withMargin = true,
    flexBasis,
    testId,
}: ICardProps) => {
    const cardCssClasses = useCssClasses(
        styles.card,
        fullHeight ? styles.fullHeight : "",
        withMargin ? styles.margin : "",
    );
    const bodyCssClasses = useCssClasses(
        styles.body,
        bodyScroll ? "" : styles.noScroll,
        padding ? styles.padding : "",
        flexBasis ? styles.flex300 : "",
    );

    const cardCSSProperties = useMemo<CSSProperties>(() => {
        if (maxHeight) {
            return { maxHeight: `${maxHeight}px` };
        }

        return {};
    }, [maxHeight]);

    return (
        <div
            className={cardCssClasses}
            style={cardCSSProperties}
            data-test-id={testId}
        >
            {(title || actions) && (
                <div
                    className={styles.head}
                    data-test-id={testId ? `${testId}_Header_Div` : undefined}
                    style={titleCssRules}
                >
                    {title}
                    <div
                        className={styles.actions}
                        data-test-id={
                            testId ? `${testId}_HeaderActions_Div` : undefined
                        }
                    >
                        {actions}
                    </div>
                </div>
            )}
            <div
                className={bodyCssClasses}
                data-test-id={testId ? `${testId}_Body_Div` : undefined}
            >
                {children}
            </div>
        </div>
    );
};

interface ICardProps {
    title?: ReactNode;
    titleCssRules?: CSSProperties;
    actions?: ReactNode;
    padding?: boolean;
    children: ReactNode;
    fullHeight?: boolean;
    maxHeight?: number;
    bodyScroll?: boolean;
    withMargin?: boolean;
    flexBasis?: boolean;
    testId?: string;
}

export default Card;
