import {
    IAgreedLimitation,
    IAuditLimitation,
    IStatus,
} from "../../../utils/api/audits";
import QualifiedSupervisorAuditLimitations from "./QualifiedSupervisorAuditLimitations";

export interface IOperationalLimitation {
    id: string;
    title: string;
    description: string;
}

export interface IQualifiedSupervisorAuditLimitationsProps {
    agreedLimitations: IAgreedLimitation[];
    operationalLimitations: IOperationalLimitation[];
    auditLimitations: IAuditLimitation[];
    auditStatus: IStatus;
}

export default QualifiedSupervisorAuditLimitations;
