import {
    IBooleanAnswer,
    IReadingAnswer,
    IStringAnswer,
} from "../../../utils/api/answers";
import OverrideTextAnswer from "./OverrideTextAnswer";

export interface IOverrideTextAnswerProps {
    answer: IStringAnswer | IReadingAnswer | IBooleanAnswer;
    value?: string;
    overrideAnswer: (answerId: number, answer?: string) => void;
}

export default OverrideTextAnswer;
