import { IAmendedLgsr } from ".";
import config from "../../../config";
import useFilterable, { IFilterableParams } from "../useFilterable";

const useAmendedLgsrs = (params?: IFilterableParams) => {
    const filterable = useFilterable<IAmendedLgsr>(
        `${config.lgsrsApiUrl}/amendedlgsrs`,
        {
            params,
        },
    );

    return filterable;
};

export default useAmendedLgsrs;
