import { ReactNode } from "react";
import PopoverItem from "./PopoverItem";

export interface IPopoverItemProps {
    children: ReactNode;
    onClick?: () => void;
    url?: string;
    className?: string;
}

export default PopoverItem;
