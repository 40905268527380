import { useContext, useEffect, useState } from "react";
import { noop } from "rxjs";
import { IQualifiedSupervisorHoldAuditProps } from ".";
import { Alert, Loading, UserContext } from "../../../components";
import ModalBody from "../../../components/Modal/ModalBody";
import { useHoldAudit } from "../../../utils/api/audits";
import { clearCache } from "../../../utils/cache";
import QualifiedSupervisorAuditsGrouping from "../QualifiedSupervisorAuditsGrouping";

const QualifiedSupervisorHoldAudit = ({
    audit,
    isReadOnly,
    hasHold,
    createComment,
    onHoldAcquired = noop,
    canCompleteAudit,
    isSaveDisabled,
    onHoldAudit,
    onSaveAudit,
    canOverrideAnswers,
    addAuditJobComment,
    addAuditObservation,
    removeAuditJobComment,
    removeAuditObservation,
    removeAuditOverrides,
    sidebarVisible,
}: IQualifiedSupervisorHoldAuditProps) => {
    const [hold, setHold] = useState(false);
    const { holdAudit, error } = useHoldAudit(audit.id);

    const { user } = useContext(UserContext);

    useEffect(() => {
        setHold(false);

        if (hasHold || isReadOnly || audit.assignedUser?.id === user.id) {
            setTimeout(() => {
                setHold(true);
                onHoldAcquired();
            }, 0);
        } else {
            const subscription = holdAudit().subscribe(() => {
                setHold(true);
                onHoldAcquired();
                removeAuditOverrides();
                clearCache();
            });

            return () => {
                if (subscription) {
                    subscription.unsubscribe();
                }
            };
        }
    }, [
        audit.id,
        audit.assignedUser?.id,
        hasHold,
        isReadOnly,
        user,
        holdAudit,
        onHoldAcquired,
        removeAuditOverrides,
    ]);

    return (
        <>
            {hold ? (
                <QualifiedSupervisorAuditsGrouping
                    audit={audit}
                    isReadOnly={isReadOnly}
                    onHoldAudit={onHoldAudit}
                    onSaveAudit={onSaveAudit}
                    createComment={createComment}
                    canCompleteAudit={canCompleteAudit}
                    isSaveDisabled={isSaveDisabled}
                    canOverrideAnswers={canOverrideAnswers}
                    addAuditJobComment={addAuditJobComment}
                    addAuditObservation={addAuditObservation}
                    removeAuditJobComment={removeAuditJobComment}
                    removeAuditObservation={removeAuditObservation}
                    sidebarVisible={sidebarVisible}
                />
            ) : (
                <ModalBody>
                    {error ? <Alert type="error">{error}</Alert> : <Loading />}
                </ModalBody>
            )}
        </>
    );
};

export default QualifiedSupervisorHoldAudit;
