import { AuditStatus, ISimpleAudit } from ".";
import config from "../../../config";
import useFilterable from "../useFilterable";

const useAudits = (status: AuditStatus) => {
    const filterable = useFilterable<ISimpleAudit>(config.auditsApiUrl, {
        params: {
            filters: {
                "status.name": {
                    operator: "{AND}",
                    readOnly: true,
                    filters: [{ function: "=", value: status }],
                },
                ...((status === "Waiting" || status === "Hold") && {
                    parentAuditId: {
                        operator: "{AND}",
                        readOnly: true,
                        filters: [{ function: "=", value: "null" }],
                    },
                }),
            },
        },
    });

    return filterable;
};

export default useAudits;
