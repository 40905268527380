import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card, TabGroup, TabGroupTab, UserContext } from "../../components";
import { ISelectOption } from "../../components/Select";
import { useHasPermission } from "../../hooks";
import {
    IIntegration,
    ILandlord,
    useLandlordPreferences,
} from "../../utils/api/landlords";
import AgreedLimitations from "./AgreedLimitations";
import DocumentPreferences from "./DocumentPreferences";
import General from "./General";
import IssuesPreferences from "./IssuesPreferences";
import PropertyCategories from "./PropertyCategories";

const LandlordPreferences = () => {
    const { t } = useTranslation();
    const { user } = useContext(UserContext);

    const landlordPreferences = useLandlordPreferences();

    const landlordSelectOptions = useMemo<ISelectOption[]>(() => {
        const landlords: ILandlord[] = [];

        if (landlordPreferences.value) {
            for (const landlord of landlordPreferences.value) {
                if (
                    user.userParentPermissions[Number(landlord.userId)]
                        .map((p) => p.name)
                        .includes("portal_admin")
                ) {
                    landlords.push(landlord);
                }
            }
            return landlords.map((p) => ({
                label: p.name,
                value: p.id.toString(),
            }));
        }

        return [];
    }, [landlordPreferences.value, user.userParentPermissions]);

    const integrations = useMemo(() => {
        if (landlordPreferences.value) {
            return landlordPreferences.value.reduce<
                Record<string, IIntegration[]>
            >(
                (previous, current) => ({
                    ...previous,
                    [current.id.toString()]: current.integrations,
                }),
                {},
            );
        }

        return {};
    }, [landlordPreferences.value]);

    const canViewElecTag = useHasPermission("electag");

    return (
        <Card title={t("Landlord Preferences")} padding={false}>
            <TabGroup overflowAuto={false}>
                <TabGroupTab header={t("General")}>
                    <General landlordOptions={landlordSelectOptions} />
                </TabGroupTab>
                <TabGroupTab header={t("Issues")}>
                    <IssuesPreferences
                        landlordOptions={landlordSelectOptions}
                    />
                </TabGroupTab>
                <TabGroupTab header={t("Property Categories")}>
                    <PropertyCategories
                        landlordOptions={landlordSelectOptions}
                        integrations={integrations}
                    />
                </TabGroupTab>
                <TabGroupTab header={t("Document Auditing")}>
                    <DocumentPreferences
                        landlordOptions={landlordSelectOptions}
                    />
                </TabGroupTab>
                {canViewElecTag && (
                    <TabGroupTab header={t("Agreed Limitations")}>
                        <AgreedLimitations
                            landlordOptions={landlordSelectOptions}
                        />
                    </TabGroupTab>
                )}
            </TabGroup>
        </Card>
    );
};

export default LandlordPreferences;
