import { IAnswer } from "../../utils/api/answers";
import { IComplianceType, ISimplePaperwork } from "../../utils/api/jobs";
import JobTimeline from "./JobTimeline";
import { ITask } from "./JobTimelineTask";

export interface IJob {
    id: number;
    date?: string;
    engineer: IEngineer | null;
    property?: IProperty;
    tasks?: ITask[];
    totalTasks?: number;
    jobType?: string;
    paperwork?: ISimplePaperwork[];
    complianceType?: IComplianceType;
    recoveryJob?: boolean;
    childJobs?: IChildJob[];
}

interface IProperty {
    id: number;
    addressString: string;
}

interface IEngineer {
    id: number;
    name: string;
}

interface IChildJob {
    id: number;
    engineer: IEngineer;
    jobType: string;
    totalTasks: number;
}

export interface IJobTimelineProps {
    jobs: IJob[];
    selectedIds?: number[];
    showTasks?: boolean;
    showTaskIcons?: boolean;
    showAddress?: boolean;
    showJobType?: boolean;
    loadTasksAsync?: boolean;
    onCheckedChange?: (checked: boolean, value: number) => void;
    isCompact?: boolean;
    answers?: IAnswer[];
    showJobIcons?: boolean;
    openChildJobsInNewWindow?: boolean;
}

export default JobTimeline;
