import { IDrawableObject } from ".";
import { colours } from "../../styles/colours";
import { roundedRectangle } from "./draw";

const getTooltip = ({
    displayDataColorboxes = false,
}: ITooltipProps): IDrawableTooltip => {
    const padding = 10;
    const textOffsetX = displayDataColorboxes ? 20 : 0;

    let canvasWidth = 0;
    let canvasHeight = 0;
    let minHoverX = 0;
    let maxHoverX = 0;
    let offsetY = 0;
    let tooltipTitle = "";
    let tooltipData: string[] = [];

    const setData = (
        data: string[],
        minX: number,
        maxX: number,
        title = "",
    ) => {
        tooltipTitle = title;
        tooltipData = data;
        minHoverX = minX;
        maxHoverX = maxX;
    };

    const onResize = (width: number, height: number) => {
        canvasWidth = width;
        canvasHeight = height;
        offsetY = Math.min(
            (height - 2 * padding) /
                (tooltipData.length + (tooltipTitle ? 1 : 0)),
            20,
        );
    };

    const draw = (context: CanvasRenderingContext2D) => {
        context.save();

        context.textBaseline = "bottom";
        const textWidth =
            Math.max(
                ...[...tooltipData, tooltipTitle].map(
                    (data) => context.measureText(data).width,
                ),
            ) || 0;

        const tooltipWidth = textWidth + padding * 2 + textOffsetX;
        const tooltipHeight =
            (tooltipData.length + (tooltipTitle ? 1 : 0)) * offsetY +
            2 * padding;

        const tooltipX = Math.min(
            Math.max(0, minHoverX + (maxHoverX - minHoverX - tooltipWidth) / 2),
            canvasWidth - tooltipWidth,
        );

        const tooltipY = (canvasHeight - tooltipHeight) / 2;

        roundedRectangle(
            context,
            tooltipX,
            tooltipY,
            tooltipWidth,
            tooltipHeight,
            5,
        );
        context.fillStyle = "rgba(0, 0, 0, 0.8)";
        context.fill();

        if (tooltipTitle) {
            context.fillStyle = colours.chart.white;
            context.textAlign = "center";
            context.fillText(
                tooltipTitle,
                tooltipX + tooltipWidth / 2,
                tooltipY + offsetY + padding / 2,
            );
            context.textAlign = "left";
        }

        tooltipData.forEach((data, index) => {
            const x = tooltipX + padding;
            const y =
                tooltipY + offsetY * (index + (tooltipTitle ? 2 : 1)) + padding;

            if (displayDataColorboxes) {
                roundedRectangle(context, x, y - 13, 12, 12, 2);
                context.fillStyle = colours.chart.colours[index];
                context.fill();
                context.strokeStyle = colours.chart.white;
                context.lineWidth = 0.5;
                context.stroke();
            }

            context.fillStyle = colours.chart.white;
            context.fillText(data, x + textOffsetX, y);
        });

        context.restore();

        return false;
    };

    return { draw, onResize, setData };
};

interface ITooltipProps {
    displayDataColorboxes?: boolean;
}

interface IDrawableTooltip extends IDrawableObject {
    setData: (
        data: string[],
        minX: number,
        maxX: number,
        title?: string,
    ) => void;
}

export default getTooltip;
