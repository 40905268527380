import { useCallback } from "react";
import { ISelectItemProps } from ".";
import PopoverListItem from "../Popover/PopoverListItem";

const SelectItem = ({
    value,
    children,
    isSelected,
    onClick,
    applyPadding,
}: ISelectItemProps) => {
    const handleClick = useCallback(() => onClick(value), [onClick, value]);

    return (
        <PopoverListItem
            onClick={handleClick}
            isSelected={isSelected}
            applyPadding={applyPadding}
        >
            {children}
        </PopoverListItem>
    );
};

export default SelectItem;
