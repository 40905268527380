import { ICompliancePropertyServicesDashboardProps } from ".";
import { Grid, GridColumn } from "../../../components";
import AverageJobTimeCard from "../AverageJobTimeCard";
import CompliancePropertyMap from "../CompliancePropertyMap";
import JobsIssuesUTAsWidget from "../JobsIssuesUTAsWidget";
import ManualUploadCard from "../ManualUploadCard";
import NetPromoterScoreCard from "../NetPromoterScoreCard";
import NoAccessCountCard from "../NoAccessCountCard";
import RecentTasksCard from "../RecentTasksCard";
import SafetyRisks from "../SafetyRisks";
import ServiceDatesResetCard from "../ServiceDatesResetCard";
import TotalJobsCard from "../TotalJobsCard";

const CompliancePropertyServicesDashboard = ({
    rowHeight,
    dashboard,
    loaded,
    mapFeature,
}: ICompliancePropertyServicesDashboardProps) => {
    return (
        <>
            <Grid>
                <GridColumn size="oneQuarter">
                    <NoAccessCountCard />
                </GridColumn>

                <GridColumn size="oneQuarter">
                    <TotalJobsCard loaded={loaded} data={dashboard} />
                </GridColumn>

                <GridColumn size="oneQuarter">
                    <AverageJobTimeCard loaded={loaded} data={dashboard} />
                </GridColumn>

                <GridColumn size="oneQuarter">
                    <SafetyRisks />
                </GridColumn>
            </Grid>

            <Grid>
                <GridColumn size="sevenOverTwelve">
                    <ServiceDatesResetCard loaded={loaded} data={dashboard} />
                </GridColumn>

                <GridColumn size="fiveOverTwelve">
                    <ManualUploadCard
                        data={dashboard}
                        loaded={loaded}
                        maxHeight={250}
                    />
                </GridColumn>
            </Grid>

            <Grid>
                <GridColumn size="oneThird">
                    <RecentTasksCard
                        data={dashboard}
                        loaded={loaded}
                        maxHeight={rowHeight}
                    />
                </GridColumn>

                <GridColumn size="oneThird">
                    <JobsIssuesUTAsWidget rowScrollHeight={rowHeight} />
                </GridColumn>

                {mapFeature && (
                    <GridColumn size="oneThird">
                        <CompliancePropertyMap />
                    </GridColumn>
                )}
            </Grid>

            <Grid>
                <GridColumn size="oneThird">
                    <NetPromoterScoreCard loaded={loaded} data={dashboard} />
                </GridColumn>
            </Grid>
        </>
    );
};

export default CompliancePropertyServicesDashboard;
