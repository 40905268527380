import { useContext, useEffect } from "react";
import { IMultipleRepairs } from ".";
import { PropertyCategoriesContext, UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiValue from "../useApiValue";

const useAwaitingAllocationMultipleRepairsCount = ({
    startDate,
    endDate,
}: IUseAwaitingAllocationMultipleRepairsCountProps) => {
    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);
    const { value, loaded, send } = useApiValue<IMultipleRepairs[]>({});
    const sendRequest = useXTagApiRequest();

    useEffect(() => {
        const subscription = send(
            sendRequest({
                url: `${config.propertiesApiUrl}/repairs/groups`,
                method: "GET",
                urlParams: {
                    startDate: [startDate.toISOString()],
                    endDate: [endDate.toISOString()],
                    ...getGlobalUrlParameters(
                        selectedCategories.map((c) => c.id),
                        activeUserParentsIds,
                    ),
                },
            }),
        ).subscribe();

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [
        activeUserParentsIds,
        selectedCategories,
        startDate,
        endDate,
        send,
        sendRequest,
    ]);

    return { value, loaded };
};

export interface IUseAwaitingAllocationMultipleRepairsCountProps {
    startDate: Date;
    endDate: Date;
}

export default useAwaitingAllocationMultipleRepairsCount;
