import { Fragment } from "react";
import { IStepperProps } from ".";
import Step from "./Step";
import styles from "./Stepper.module.scss";
import StepSeparator from "./StepSeparator";

const Stepper = ({ activeStepId, steps }: IStepperProps) => {
    return (
        <div className={styles.container}>
            {steps.map((step, index) => (
                <Fragment key={step.id}>
                    <Step
                        stepNumber={index + 1}
                        id={step.id}
                        title={step.title}
                        isCompleted={step.isCompleted}
                        isActive={step.id === activeStepId}
                        onClick={step.onClick}
                    />

                    {index < steps.length - 1 && (
                        <StepSeparator isCompleted={step.isCompleted} />
                    )}
                </Fragment>
            ))}
        </div>
    );
};

export default Stepper;
