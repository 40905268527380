import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IAllocateJobExtendedModalProps } from ".";
import {
    Badge,
    ColourPill,
    Grid,
    GridColumn,
    SelectFilter,
    TabGroup,
    TabGroupTab,
} from "..";
import { useAwaitingAllocation } from "../../utils/api/allocatedJobs";
import { useProperties } from "../../utils/api/properties";
import { getDifferenceInDays, getToday, toDateString } from "../../utils/dates";
import { isRequired, useValidateField } from "../../utils/validation";
import AllocateJobModal from "../AllocateJobModal";
import { ISelectOption } from "../Select";
import styles from "./AllocateJobExtendedModal.module.scss";

const AllocateJobExtendedModal = ({
    allocatedJob,
    defaultJobDate,
    engineers,
    loading,
    hide,
    onDeleteAllocatedJob,
    onManuallyCompleteAllocatedJob,
    onReconcileAllocatedJob,
}: IAllocateJobExtendedModalProps) => {
    const { t } = useTranslation();

    const [isPropertyTabActive, setIsPropertyTabActive] = useState(true);

    const awaitingAllocation = useAwaitingAllocation([
        "Allocated",
        "Awaiting Allocation",
        "Awaiting Re-allocation",
    ]);
    const {
        loading: awaitingAllocationLoading,
        records: awaitingAllocationRecords,
        totalRecords: awaitingAllocationTotalRecords,
        refresh: awaitingAllocationRefresh,
    } = awaitingAllocation;

    const [awaitingAllocationId, setAwaitingAllocationId] = useState("");
    const awaitingAllocationIdValidator = useValidateField(
        awaitingAllocationId,
        isRequired(),
    );

    const awaitingAllocationOptions = useMemo<ISelectOption[]>(
        () =>
            awaitingAllocationRecords.map((allocation) => ({
                value: allocation.id,
                label: allocation.property.addressString,
            })),
        [awaitingAllocationRecords],
    );

    const awaitingAllocationJob = useMemo(
        () =>
            awaitingAllocationRecords.find(
                (j) => j.id === awaitingAllocationId,
            ),
        [awaitingAllocationId, awaitingAllocationRecords],
    );

    useEffect(() => {
        if (awaitingAllocationJob) {
            setPropertyId(awaitingAllocationJob.property.id.toString());
        }
    }, [awaitingAllocationJob]);

    const properties = useProperties({
        sortProperty: "nextServiceDueDate",
        sortDirection: "asc",
    });
    const { records: propertiesRecords } = properties;

    const [propertyId, setPropertyId] = useState(
        allocatedJob?.property.id.toString() ?? "",
    );
    const propertyIdValidator = useValidateField(propertyId, isRequired());

    const defaultProperty = useMemo<ISelectOption | null>(
        () =>
            allocatedJob
                ? {
                      value: allocatedJob.property.id.toString(),
                      label: allocatedJob.property.addressString,
                  }
                : null,
        [allocatedJob],
    );

    const propertyOptions = useMemo(() => {
        return propertiesRecords
            .map((prop) => {
                // 1. Create the due days displays, ordered by the due in days (ascending)
                const dates = prop.nextServiceDueDates
                    .map((date) => {
                        const dueInDays = Math.floor(
                            date.date !== null
                                ? getDifferenceInDays(
                                      getToday(),
                                      new Date(date.date),
                                  )
                                : 365,
                        );

                        return {
                            dueInDays,
                            fuelType: {
                                id: date.fuelTypeId,
                                displayName: date.fuelTypeName,
                                color: date.color,
                            },
                            date: date.date,
                        };
                    })
                    .sort((a, b) => a.dueInDays - b.dueInDays);
                // 2. Create the property model
                return {
                    id: prop.id,
                    addressString: prop.addressString,
                    dueInDays: Math.min(...dates.map((d) => d.dueInDays)),
                    dates,
                };
            })
            .map((prop) => {
                return {
                    label: (
                        <div
                            className={`${styles.propertyDropdownItem} ${
                                prop.dueInDays > 30
                                    ? styles.compliantBorder
                                    : prop.dueInDays >= 0
                                    ? styles.dueSoonBorder
                                    : styles.overdueBorder
                            }`}
                        >
                            {prop.addressString.length > 70 ? (
                                <p title={prop.addressString}>
                                    {`${prop.addressString.substr(0, 67)}`}
                                    &hellip;
                                </p>
                            ) : (
                                <p>{prop.addressString}</p>
                            )}

                            <Grid>
                                {prop.dates.map((date) => (
                                    <Fragment key={date.fuelType.id}>
                                        <GridColumn
                                            size="twentyPercent"
                                            cssRules={{
                                                fontSize: "0.75rem",
                                                padding: "5px",
                                            }}
                                        >
                                            <span
                                                className={
                                                    styles.propertyCategory
                                                }
                                            >
                                                <ColourPill
                                                    customColour={
                                                        date.fuelType.color
                                                    }
                                                    value={t(
                                                        date.fuelType
                                                            .displayName,
                                                    )}
                                                    fullWidth={true}
                                                />
                                            </span>
                                        </GridColumn>
                                        <GridColumn
                                            size="thirtyPercent"
                                            cssRules={{
                                                fontSize: "0.75rem",
                                                padding: "5px",
                                            }}
                                        >
                                            {date.dueInDays > 0 ? (
                                                <>
                                                    {t("Due in")}&nbsp;
                                                    <span
                                                        className={
                                                            date.dueInDays > 30
                                                                ? styles.compliant
                                                                : styles.dueSoon
                                                        }
                                                    >
                                                        {date.dueInDays}
                                                    </span>
                                                    &nbsp;
                                                    {t("daysCount", {
                                                        count: date.dueInDays,
                                                    })}
                                                    &nbsp;
                                                    {date.date &&
                                                        `(${toDateString(
                                                            new Date(date.date),
                                                        )})`}
                                                </>
                                            ) : (
                                                <span
                                                    className={styles.overdue}
                                                >
                                                    {t("Overdue")}
                                                </span>
                                            )}
                                        </GridColumn>
                                    </Fragment>
                                ))}
                            </Grid>
                        </div>
                    ),
                    value: prop.id.toString(),
                };
            });
    }, [propertiesRecords, t]);

    const handlePropertyTabActivate = useCallback(
        (index: number) => {
            setIsPropertyTabActive(index === 0);
            setAwaitingAllocationId("");

            if (propertyId !== allocatedJob?.property.id.toString()) {
                setPropertyId("");
            }
        },
        [allocatedJob, propertyId],
    );

    const property = useMemo(
        () => propertiesRecords.find((p) => p.id === Number(propertyId)),
        [propertiesRecords, propertyId],
    );

    const customPropertyRender = useCallback(() => {
        return (
            (property && property.addressString) ||
            (defaultProperty && defaultProperty.label)
        );
    }, [defaultProperty, property]);

    const validators = useMemo(
        () => [
            isPropertyTabActive
                ? propertyIdValidator
                : awaitingAllocationIdValidator,
        ],
        [
            awaitingAllocationIdValidator,
            propertyIdValidator,
            isPropertyTabActive,
        ],
    );

    return (
        <AllocateJobModal
            allocatedJob={allocatedJob}
            loading={loading || awaitingAllocationLoading}
            defaultJobDate={defaultJobDate}
            engineers={engineers}
            property={property}
            propertyId={propertyId ? Number(propertyId) : null}
            validators={validators}
            awaitingAllocationJob={awaitingAllocationJob}
            hide={hide}
            onDeleteAllocatedJob={onDeleteAllocatedJob}
            onManuallyCompleteAllocatedJob={onManuallyCompleteAllocatedJob}
            onReconcileAllocatedJob={onReconcileAllocatedJob}
            refresh={awaitingAllocationRefresh}
            canSelectContractor={isPropertyTabActive}
        >
            <TabGroup
                headersPosition="top"
                onActivate={handlePropertyTabActivate}
            >
                <TabGroupTab header={t("Property")}>
                    <SelectFilter
                        label={t("Property")}
                        value={propertyId}
                        defaultValue={defaultProperty}
                        onChange={setPropertyId}
                        options={propertyOptions}
                        applyPadding={false}
                        customRender={customPropertyRender}
                        {...properties}
                        {...propertyIdValidator}
                    />
                </TabGroupTab>

                <TabGroupTab
                    header={
                        <>
                            {t("Awaiting allocation")}&nbsp;
                            <Badge color="green">
                                {awaitingAllocationTotalRecords}
                            </Badge>
                        </>
                    }
                >
                    <SelectFilter
                        label={t("Job")}
                        value={awaitingAllocationId}
                        onChange={setAwaitingAllocationId}
                        options={awaitingAllocationOptions}
                        {...awaitingAllocation}
                        {...awaitingAllocationIdValidator}
                    />
                </TabGroupTab>
            </TabGroup>
        </AllocateJobModal>
    );
};

export default AllocateJobExtendedModal;
