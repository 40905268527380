import { useState } from "react";
import { useToggle } from "../../hooks";

const useGallery = () => {
    const { show, hide, visible } = useToggle();
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    return { show, hide, visible, currentImageIndex, setCurrentImageIndex };
};

export default useGallery;
