import { IDateRangePickerDropdownProps } from ".";
import { DateRangePicker, Popover } from "../..";
import { addDays } from "../../../utils/dates";

const DateRangePickerDropdown = ({
    property,
    toggleFilter,
    hide,
    buttonElement,
    startDate = null,
    endDate = null,
    onDatesSelected = () => null,
}: IDateRangePickerDropdownProps) => {
    const handleDatesSelected = (fromDate: Date, toDate: Date) => {
        if (toggleFilter) {
            toggleFilter([
                {
                    property,
                    filterGroup: {
                        operator: "{AND}",
                        filters: [
                            { function: ">=", value: fromDate.toISOString() },
                            {
                                function: "<",
                                value: addDays(toDate, 1).toISOString(),
                            },
                        ],
                    },
                    appendFilters: false,
                },
            ]);
        }

        onDatesSelected(fromDate, toDate);
    };

    return (
        <Popover anchorElement={buttonElement} hide={hide}>
            <DateRangePicker
                onDatesSelected={handleDatesSelected}
                selectedFromDate={startDate}
                selectedToDate={endDate}
            />
        </Popover>
    );
};

export default DateRangePickerDropdown;
