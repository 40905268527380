import { useCallback, useContext } from "react";
import { IUpdateProperty } from ".";
import { PropertyCategoriesContext, UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const useUpdateProperty = () => {
    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);
    const { send, loading } = useApiRequest<IUpdateProperty>({});
    const sendRequest = useXTagApiRequest();

    const update = useCallback(
        (id: number, property: IUpdateProperty) =>
            send(
                sendRequest({
                    url: `${config.propertiesApiUrl}/${id}`,
                    method: "PUT",
                    body: property,
                    urlParams: getGlobalUrlParameters(
                        selectedCategories.map((c) => c.id),
                        activeUserParentsIds,
                    ),
                }),
            ),
        [activeUserParentsIds, selectedCategories, send, sendRequest],
    );

    return { update, loading };
};

export default useUpdateProperty;
