import { useMemo } from "react";
import { IMapMarker } from "../../components/Map";
import engineerRed from "../../images/map/engineer-red.svg";
import engineer from "../../images/map/engineer.svg";
import property from "../../images/map/property.svg";
import { IJob } from "../../utils/api/jobs";

export const useJobMapFeature = (
    mapFeatureFlag: boolean,
    job: IUseJobMapFeatureProps,
) => {
    const markers = useMemo(() => {
        const array: IMapMarker[] = [];

        if (
            mapFeatureFlag &&
            job.value &&
            job.value.property.lat &&
            job.value.property.long
        ) {
            array.push({
                type: "Feature",
                geometry: {
                    type: "Point",
                    coordinates: [
                        job.value.property.long,
                        job.value.property.lat,
                    ],
                },
                id: job.value.property.id,
                properties: {
                    icon: property,
                },
            });

            array.push({
                type: "Feature",
                geometry: {
                    type: "Point",
                    coordinates: [job.value.long, job.value.lat],
                },
                id: job.value.id,
                properties: {
                    icon:
                        job.value.distanceBetween &&
                        job.value.distanceBetween > 0.5
                            ? engineerRed
                            : engineer,
                },
            });
        }

        return array;
    }, [mapFeatureFlag, job.value]);

    return { markers };
};

interface IUseJobMapFeatureProps {
    value: IJob;
    loading: boolean;
    loaded: boolean;
    error: string;
}
