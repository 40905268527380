import { IComplianceDefaultDashboardProps } from ".";
import { Grid, GridColumn } from "../../../components";
import ApplianceConditionCard from "../ApplianceConditionCard";
import AverageJobTimeCard from "../AverageJobTimeCard";
import CompliancePropertyMap from "../CompliancePropertyMap";
import JobsIssuesUTAsWidget from "../JobsIssuesUTAsWidget";
import NetPromoterScoreCard from "../NetPromoterScoreCard";
import NoAccessCountCard from "../NoAccessCountCard";
import PropertyStatus from "../PropertyStatus";
import RecentWorksCard from "../RecentWorksCard";
import SafetyRisks from "../SafetyRisks";
import TotalJobsCard from "../TotalJobsCard";

const ComplianceDefaultDashboard = ({
    rowHeight,
    loaded,
    dashboard,
    mapFeature,
}: IComplianceDefaultDashboardProps) => {
    return (
        <>
            <Grid>
                <GridColumn size="oneQuarter">
                    <NoAccessCountCard />
                </GridColumn>

                <GridColumn size="oneQuarter">
                    <TotalJobsCard loaded={loaded} data={dashboard} />
                </GridColumn>

                <GridColumn size="oneQuarter">
                    <AverageJobTimeCard loaded={loaded} data={dashboard} />
                </GridColumn>

                <GridColumn size="oneQuarter">
                    <SafetyRisks />
                </GridColumn>
            </Grid>

            <Grid>
                <GridColumn size="sevenOverTwelve">
                    <PropertyStatus />
                </GridColumn>

                <GridColumn size="fiveOverTwelve">
                    <ApplianceConditionCard
                        loaded={loaded}
                        data={dashboard}
                        collapsed={false}
                    />
                </GridColumn>
            </Grid>

            <Grid>
                <GridColumn size="oneThird">
                    <RecentWorksCard
                        loaded={loaded}
                        data={dashboard}
                        maxHeight={rowHeight}
                    />
                </GridColumn>

                <GridColumn size="oneThird">
                    <JobsIssuesUTAsWidget rowScrollHeight={rowHeight} />
                </GridColumn>

                {mapFeature && (
                    <GridColumn size="oneThird">
                        <CompliancePropertyMap />
                    </GridColumn>
                )}
            </Grid>

            <Grid>
                <GridColumn size="oneThird">
                    <NetPromoterScoreCard loaded={loaded} data={dashboard} />
                </GridColumn>
            </Grid>
        </>
    );
};

export default ComplianceDefaultDashboard;
