import { IValidationResult } from ".";

const isTrue = (error = "The field is required.") => {
    const validate = (value: boolean): IValidationResult => {
        return {
            isValid: value,
            error: value ? "" : error,
        };
    };

    return { validate };
};

export default isTrue;
