import DocumentAuditHeader from "./DocumentAuditHeader";
import { IAuditableDocument } from "../../../utils/api/lgsrs";

export interface IDocumentAuditHeaderProps {
    index: number;
    document: IAuditableDocument;
    status?: "pass" | "fail" | "awaiting";
    expanded: boolean;
}

export default DocumentAuditHeader;
