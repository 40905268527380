import { padStart, split } from "lodash";

// TODO: Make the Job.EndDate nullable in the database to avoid this check.
const defaultDate = "0001-01-01T00:00:00+00:00";

const pad = (value: number, length = 2) => {
    return padStart(value.toString(), length, "0");
};

const getDisplayDifference = (fromDate: Date, toDate: Date) => {
    const seconds = Math.abs(toDate.getTime() - fromDate.getTime()) / 1000;

    if (seconds < 45) {
        return { key: "timeAgo.seconds", count: seconds };
    } else {
        const minutes = Math.round(seconds / 60);

        if (minutes < 45) {
            return { key: "timeAgo.minutes", count: minutes };
        } else {
            const hours = Math.round(minutes / 60);

            if (hours < 22) {
                return { key: "timeAgo.hours", count: hours };
            } else {
                const days = Math.round(hours / 24);

                if (days < 26) {
                    return { key: "timeAgo.days", count: days };
                } else {
                    const months = Math.round(days / 30.5);

                    if (months < 11) {
                        return { key: "timeAgo.months", count: months };
                    } else {
                        const years = Math.round(months / 12);

                        return { key: "timeAgo.years", count: years };
                    }
                }
            }
        }
    }
};

const getDifferenceInSeconds = (fromDate: Date, toDate: Date) => {
    return (toDate.getTime() - fromDate.getTime()) / 1000;
};

const getDifferenceInMinutes = (fromDate: Date, toDate: Date) => {
    return getDifferenceInSeconds(fromDate, toDate) / 60;
};

const getDifferenceInDays = (fromDate: Date, toDate: Date) => {
    return getDifferenceInMinutes(fromDate, toDate) / 60 / 24;
};

const getNow = () => {
    return new Date(Date.now());
};

const getDate = (date: Date) => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

const getFirstDayOfMonth = (date: Date) => {
    return new Date(date.getFullYear(), date.getMonth(), 1);
};

const getToday = () => {
    return getDate(getNow());
};

const getStartOfWeek = (date: Date) => {
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(date.setDate(diff));
};

const addMonths = (date: Date, months: number) => {
    const tempDate = new Date(date);
    tempDate.setMonth(date.getMonth() + months);

    return tempDate;
};

const addDays = (date: Date, days: number) => {
    const tempDate = new Date(date);
    tempDate.setDate(date.getDate() + days);

    return tempDate;
};

const addSeconds = (date: Date, seconds: number) => {
    const tempDate = new Date(date);
    tempDate.setSeconds(date.getSeconds() + seconds);

    return tempDate;
};

const addMinutes = (date: Date, minutes: number) => {
    const tempDate = new Date(date);
    tempDate.setMinutes(date.getMinutes() + minutes);

    return tempDate;
};

const addHours = (date: Date, hours: number) => {
    const tempDate = new Date(date);
    tempDate.setHours(date.getHours() + hours);

    return tempDate;
};

const toDateString = (date: Date) => {
    return `${pad(date.getDate())}/${pad(date.getMonth() + 1)}/${pad(
        date.getFullYear(),
        4,
    )}`;
};

const toTimeString = (date: Date) => {
    return date.toTimeString().substring(0, 5);
};

const secondsToTimeSpan = (totalSeconds: number) => {
    const days = Math.floor(totalSeconds / 86400);
    totalSeconds %= 86400;
    const hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = Math.floor(totalSeconds % 60);

    return toTimeSpan(
        `${days ? `${days}.` : ""}${pad(hours)}:${pad(minutes)}:${pad(
            seconds,
        )}`,
        hours <= 0 && minutes <= 0,
    );
};

// TODO: Add translation.
const toTimeSpan = (timeSpan: string, includeSeconds = false) => {
    let days = "";
    let time = "";

    if (timeSpan.includes(".")) {
        const parts = split(timeSpan, ".");
        days = `${parts[0]}d `;
        time = parts[1];
    } else {
        time = timeSpan;
    }
    const timeParts = time.split(":");
    time = `${timeParts[0]}h ${timeParts[1]}m ${
        includeSeconds ? timeParts[2] + "s" : ""
    }`;

    return days + time;
};

const toDateTimeString = (date: Date) => {
    return toDateString(date) + " " + toTimeString(date);
};

export {
    getNow,
    getToday,
    addSeconds,
    addMinutes,
    addHours,
    addDays,
    addMonths,
    toDateString,
    toTimeString,
    toTimeSpan,
    secondsToTimeSpan,
    toDateTimeString,
    getDifferenceInSeconds,
    getDifferenceInMinutes,
    getDisplayDifference,
    getDate,
    getStartOfWeek,
    getDifferenceInDays,
    getFirstDayOfMonth,
    defaultDate,
};
