import { useAuth0 } from "@auth0/auth0-react";
import { useCallback } from "react";
import {
    catchError,
    from,
    map,
    Observable,
    shareReplay,
    switchMap,
} from "rxjs";
import { ajax } from "rxjs/ajax";
import { ISendApiRequest } from ".";
import { cache } from "../cache";
import { addSeconds, getNow } from "../dates";
import { encodeUrl, encodeUrlParameters } from "../url";

export const useXTagApiRequest = () => {
    const { getAccessTokenSilently, logout } = useAuth0();

    const sendRequest = useCallback(
        <T, U>({
            url,
            method,
            contentType = "json",
            urlParams,
            body,
        }: ISendApiRequest<U>) => {
            return from(getAccessTokenSilently()).pipe(
                map(
                    (token): Record<string, string> => ({
                        Authorization: `Bearer ${token}`,
                    }),
                ),
                map((headers): Record<string, string> => {
                    switch (contentType) {
                        case "multipart/form-data": {
                            return headers;
                        }
                        default:
                        case "json": {
                            return {
                                ...headers,
                                Accept: "application/json",
                                "Content-Type":
                                    "application/json; charset=utf-8",
                            };
                        }
                    }
                }),
                switchMap((headers) => {
                    let observable = ajax<T>({
                        url: encodeUrl(url, urlParams),
                        body,
                        headers,
                        method,
                    }).pipe(
                        catchError((response) => {
                            if (response.status === 401) {
                                logout({ returnTo: window.location.origin });
                            }

                            throw response;
                        }),
                        map((response) => response.response),
                    );

                    if (method === "GET") {
                        const ttl = 240;

                        if (!cache[url]) {
                            cache[url] = {};
                        }

                        const cacheKey = encodeUrlParameters(urlParams);
                        const cached = cache[url][cacheKey];

                        if (cached && cached.expiresAt > getNow()) {
                            return cached.observable as Observable<T>;
                        }

                        observable = observable.pipe(
                            shareReplay(1, (ttl + 2) * 1000),
                        );

                        cache[url][cacheKey] = {
                            expiresAt: addSeconds(getNow(), ttl),
                            observable,
                        };
                    }

                    return observable;
                }),
            );
        },
        [getAccessTokenSilently, logout],
    );

    return sendRequest;
};
