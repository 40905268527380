import { IColumnActionsProps } from ".";
import BooleanFilter from "../BooleanFilter";
import DateFilter from "../DateFilter";
import ListFilter from "../ListFilter";
import NumberFilter from "../NumberFilter";
import Sorting from "../Sorting/Sorting";
import styles from "./ColumnActions.module.scss";

const ColumnActions = ({
    property,
    sortable = true,
    filterable = true,
    activeFilters,
    sort,
    sortDirection,
    sortProperty,
    type = "string",
    visible,
    toggleFilter,
    filterOperator,
    getFilters,
    loading,
    title,
    filterFormatter,
    getBooleanFilters,
    getColumnFilters,
    filterAvailableFilters,
}: IColumnActionsProps) => {
    return (
        <div className={styles.container}>
            {sortable && (
                <Sorting
                    property={property}
                    sort={sort}
                    sortDirection={sortDirection}
                    sortProperty={sortProperty}
                    visible={visible}
                    title={title}
                />
            )}
            {filterable &&
                (() => {
                    switch (type) {
                        case "string":
                            return (
                                <ListFilter
                                    property={property}
                                    toggleFilter={toggleFilter}
                                    hover={visible}
                                    operator={filterOperator}
                                    getFilters={getFilters}
                                    getColumnFilters={getColumnFilters}
                                    filterAvailableFilters={
                                        filterAvailableFilters
                                    }
                                    activeFilters={activeFilters}
                                    loading={loading}
                                    filterFormatter={filterFormatter}
                                    title={title}
                                />
                            );
                        case "boolean": {
                            return (
                                <BooleanFilter
                                    property={property}
                                    toggleFilter={toggleFilter}
                                    hover={visible}
                                    getBooleanFilters={getBooleanFilters}
                                    activeFilters={activeFilters}
                                    loading={loading}
                                    title={title}
                                />
                            );
                        }
                        case "number":
                            return (
                                <NumberFilter
                                    property={property}
                                    toggleFilter={toggleFilter}
                                    hover={visible}
                                    activeFilters={activeFilters}
                                    loading={loading}
                                    title={title}
                                />
                            );
                        case "date":
                        case "datetime":
                            return (
                                <DateFilter
                                    property={property}
                                    toggleFilter={toggleFilter}
                                    hover={visible}
                                    activeFilters={activeFilters}
                                    loading={loading}
                                    title={title}
                                />
                            );
                        default:
                            return "";
                    }
                })()}
        </div>
    );
};

export default ColumnActions;
