import { useMemo } from "react";
import { OverriddenAnswers } from "../pages/QualifiedSupervisorAudits/QualifiedSupervisorAuditRow";
import { IAnswer } from "../utils/api/answers";
import useDisplayAnswerPayload from "./useDisplayAnswerPayload";

const useOverriddenAnswerText = (
    answers: IAnswer[],
    overriddenAnswers: OverriddenAnswers,
) => {
    const displayAnswerPayload = useDisplayAnswerPayload();

    const overriddenAnswersText = useMemo(() => {
        const isOverridden =
            answers.filter(
                (answer) => overriddenAnswers[answer.id] !== undefined,
            ).length > 0;

        if (isOverridden) {
            return answers
                .map((answer) => {
                    if (answer.type === "Reading") {
                        return displayAnswerPayload(
                            overriddenAnswers[answer.id]?.answer ??
                                answer.payload?.toString() ??
                                "",
                            answer,
                        );
                    } else {
                        return displayAnswerPayload(
                            overriddenAnswers[answer.id]?.answer ?? "",
                            answer,
                        );
                    }
                })
                .filter((answer) => answer)
                .join(" ");
        } else {
            return "";
        }
    }, [answers, displayAnswerPayload, overriddenAnswers]);

    return overriddenAnswersText;
};

export default useOverriddenAnswerText;
