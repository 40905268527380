import { ISimpleAppliance } from "../../../utils/api/appliances";
import { Checkbox } from "../../../components";

const UnsafeApplianceRow = ({
    appliance,
    isSafe,
    setSafetyStatus,
}: IUnsafeApplianceRowProps) => {
    const setApplianceSafetyStatus = (status: boolean) => {
        setSafetyStatus(appliance.id, status);
    };

    return (
        <Checkbox checked={isSafe} onChange={setApplianceSafetyStatus}>
            {appliance.make} {appliance.model} {appliance.location}
        </Checkbox>
    );
};

interface IUnsafeApplianceRowProps {
    appliance: ISimpleAppliance;
    isSafe: boolean;
    setSafetyStatus: (id: string, safetyStatus: boolean) => void;
}

export default UnsafeApplianceRow;
