import { ISelectOption } from "../../../components/Select";
import { IIntegration } from "../../../utils/api/landlords";
import PropertyCategories from "./PropertyCategories";

export interface IPropertyCategoriesProps {
    landlordOptions: ISelectOption[];
    integrations: Record<string, IIntegration[]>;
}

export default PropertyCategories;
