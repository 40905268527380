import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import {
    Button,
    Card,
    Form,
    InputField,
    Loading,
    PropertyCategoriesContext,
    SearchableSelect,
    Select,
    UserContext,
    ValidationError,
} from "../../components";
import InputDatePicker from "../../components/InputDatePicker";
import { useLandlordPropertyCategories } from "../../utils/api/landlords";
import { useAddProperty } from "../../utils/api/properties";
import { getToday } from "../../utils/dates";
import {
    isRequired,
    useValidateField,
    validateForm,
} from "../../utils/validation";

const PropertyAdd = () => {
    const { t } = useTranslation();
    const { user } = useContext(UserContext);

    const [landlordUserId, setLandlordUserId] = useState("");
    const [addressOne, setAddressOne] = useState("");
    const [addressTwo, setAddressTwo] = useState("");
    const [city, setCity] = useState("");
    const [county, setCounty] = useState("");
    const [postcode, setPostcode] = useState("");
    const [uprn, setUPRN] = useState("");
    const [propertyType, setPropertyType] = useState("");
    const [nextServiceDueDate, setnextServiceDueDate] = useState(getToday());
    const [propertyCategoryId, setPropertyCategoryId] = useState("");
    const [propertyCategoryFilter, setPropertyCategoryFilter] = useState("");

    useEffect(() => {
        setPropertyCategoryId("");
    }, [landlordUserId]);

    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { value: propertyCategories } = useLandlordPropertyCategories({
        functionalOnly: true,
    });

    const userParent = useMemo(
        () =>
            user.userParents.find(
                (u) => u.userParentId.toString() === landlordUserId,
            ),
        [landlordUserId, user.userParents],
    );

    const parsedPropertyCategories = useMemo(
        () =>
            propertyCategories
                .filter(
                    (c) =>
                        c.landlordId === userParent?.landlord?.id &&
                        (!propertyCategoryFilter ||
                            c.propertyCategory.displayName
                                .toLocaleLowerCase()
                                .includes(
                                    propertyCategoryFilter.toLocaleLowerCase(),
                                )),
                )
                .filter(
                    (category) =>
                        selectedCategories.length === 0 ||
                        selectedCategories
                            .map((c) => c.id)
                            .includes(category.propertyCategoryId),
                )
                .map((c) => ({
                    label: c.propertyCategory.displayName,
                    value: c.propertyCategory.id.toString(),
                })),
        [
            userParent,
            propertyCategories,
            propertyCategoryFilter,
            selectedCategories,
        ],
    );

    const propertyCategoryIdValidator = useValidateField(
        propertyCategoryId,
        isRequired(),
    );

    const addressOneValidator = useValidateField(addressOne, isRequired());
    const postcodeValidator = useValidateField(postcode, isRequired());
    const nextServiceDueDateValidator = useValidateField(
        nextServiceDueDate,
        isRequired(),
    );
    const addPropertyForValidator = validateForm(() => [
        addressOneValidator,
        postcodeValidator,
        nextServiceDueDateValidator,
        propertyCategoryIdValidator,
    ]);

    const navigate = useNavigate();

    const addProperty = useAddProperty();
    const handleAddProperty = () => {
        addProperty
            .addProperty({
                address: {
                    addressOne,
                    addressTwo,
                    city,
                    county,
                    postcode,
                },
                uprn,
                propertyType,
                nextServiceDueDate,
                propertyCategoryId,
                landlordUserId: landlordUserId,
            })
            .subscribe(() => navigate("/management/properties"));
    };

    return (
        <Form onSubmit={handleAddProperty} {...addPropertyForValidator}>
            <Card>
                <Select
                    label={t("Landlord")}
                    options={user.userParents.map((u) => {
                        return {
                            label: u.landlord?.name ?? "",
                            value: u.userParentId.toString(),
                        };
                    })}
                    value={landlordUserId}
                    onChange={setLandlordUserId}
                />
                <InputField
                    label={t("Address Line One")}
                    placeholder={t("Address Line One")}
                    value={addressOne}
                    onChange={setAddressOne}
                    {...addressOneValidator}
                />
                <InputField
                    label={t("Address Line Two")}
                    placeholder={t("Address Line Two")}
                    value={addressTwo}
                    onChange={setAddressTwo}
                />
                <InputField
                    label={t("City")}
                    placeholder={t("City")}
                    value={city}
                    onChange={setCity}
                />
                <InputField
                    label={t("County")}
                    placeholder={t("County")}
                    value={county}
                    onChange={setCounty}
                />
                <InputField
                    label={t("Postcode")}
                    placeholder={t("Postcode")}
                    value={postcode}
                    onChange={setPostcode}
                    {...postcodeValidator}
                />
                <InputField
                    label={t("UPRN")}
                    placeholder={t("UPRN")}
                    value={uprn}
                    onChange={setUPRN}
                />
                <InputField
                    label={t("Property Type")}
                    placeholder={t("Property Type")}
                    value={propertyType}
                    onChange={setPropertyType}
                />
                <SearchableSelect
                    label={t("Fuel Type")}
                    placeholder={t("Search")}
                    options={parsedPropertyCategories}
                    value={propertyCategoryId}
                    onChange={setPropertyCategoryId}
                    applySearch={setPropertyCategoryFilter}
                    autoComplete={true}
                    {...propertyCategoryIdValidator}
                />
                <InputDatePicker
                    label={t("Next Service Date")}
                    date={nextServiceDueDate}
                    onDateSelected={setnextServiceDueDate}
                    {...nextServiceDueDateValidator}
                />

                <Button
                    type="submit"
                    variant="primary"
                    displayBlock={true}
                    disabled={addProperty.loading}
                >
                    {t("Add Property")}
                </Button>

                <ValidationError
                    error={addProperty.error}
                    isValid={!addProperty.error}
                />

                <Loading visible={addProperty.loading} />
            </Card>
        </Form>
    );
};

export default PropertyAdd;
