import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IQualifiedSupervisorAuditProps } from ".";
import { Button, Form, ValidationError } from "../../../components";
import ModalBody from "../../../components/Modal/ModalBody";
import ModalFooter from "../../../components/Modal/ModalFooter";
import { useToggle } from "../../../hooks";
import { IInspectionScheduleAnswer } from "../../../utils/api/answers";
import { IAgreedLimitation, IAnswerOverride } from "../../../utils/api/audits";
import { toDateString } from "../../../utils/dates";
import {
    isTrue,
    useValidateField,
    validateForm,
} from "../../../utils/validation";
import { AgreedLimitationsProvider } from "../AgreedLimitations";
import { ObservationTypesProvider } from "../ObservationTypes";
import QualifiedSupervisorAuditLimitations, {
    IOperationalLimitation,
} from "../QualifiedSupervisorAuditLimitations";
import QualifiedSupervisorAuditObservations from "../QualifiedSupervisorAuditObservations";
import QualifiedSupervisorAuditQuestionsAnswersSection from "../QualifiedSupervisorAuditQuestionsAnswersSection";
import QualifiedSupervisorAuditSection from "../QualifiedSupervisorAuditSection";
import QualifiedSupervisorAuditSidebar from "../QualifiedSupervisorAuditSidebar";
import Table from "../Table";
import styles from "./QualifiedSupervisorAudit.module.scss";

const QualifiedSupervisorAudit = ({
    onSaveAudit,
    onHoldAudit,
    audit,
    overriddenAnswers,
    answersSections,
    isReadOnly,
    isSaveDisabled,
    updateOverriddenAnswers,
    createComment,
    canOverrideAnswers = true,
    addAuditJobComment,
    addAuditObservation,
    removeAuditJobComment,
    removeAuditObservation,
    addAdditionalAnswers,
    sidebarVisible,
}: IQualifiedSupervisorAuditProps) => {
    const { t } = useTranslation();

    const {
        visible: isJobSummaryReviewed,
        toggle: toggleIsJobSummaryReviewed,
    } = useToggle();
    const {
        visible: isAuditReasonReviewed,
        toggle: toggleIsAuditReasonReviewed,
    } = useToggle();
    const {
        visible: isObservationsReviewed,
        toggle: toggleIsObservationsReviewed,
    } = useToggle();
    const {
        visible: isLimitationsReviewed,
        toggle: toggleIsLimitationsReviewed,
    } = useToggle();

    const [reviewedSections, setReviewedSections] = useState<{
        [key: string]: boolean;
    }>({});
    const toggleReviewSection = useCallback((sectionId: string) => {
        setReviewedSections((state) => {
            const newState = { ...state };

            newState[sectionId] = !newState[sectionId];

            return newState;
        });
    }, []);

    const handleFormSubmit = useCallback(() => {
        setIsFormValid(true);
        onSaveAudit();
    }, [onSaveAudit]);

    const [isFormValid, setIsFormValid] = useState(true);
    const handleInvalidFormSubmit = useCallback(
        () => setIsFormValid(false),
        [],
    );

    const [sectionsValid, setSectionsValid] = useState<{
        [key: string]: boolean;
    }>({});

    const allSectionsAreValid = useMemo(
        () => Object.values(sectionsValid).every((v) => v),
        [sectionsValid],
    );

    const validateSection = useCallback((id: string, isValid: boolean) => {
        setSectionsValid((dictionary) => ({
            ...dictionary,
            [id]: isValid,
        }));
    }, []);

    const sectionsValidation = useValidateField(allSectionsAreValid, isTrue());

    const { validate } = validateForm(() => [sectionsValidation]);

    const overriddenAnswersDictionary = useMemo(
        () =>
            overriddenAnswers.reduce<Record<string, IAnswerOverride>>(
                (dictionary, answer) => {
                    return {
                        ...dictionary,
                        [answer.answerId.toString()]: answer,
                    };
                },
                {},
            ),
        [overriddenAnswers],
    );

    const deletedEntities = useMemo(() => {
        const deletedIds = answersSections
            .flatMap((s) =>
                s.children.flatMap((c) =>
                    c.children.flatMap((q) =>
                        q.children.filter((a) => {
                            return (
                                a.relatedAnswerIds.length > 0 &&
                                a.relatedAnswerIds.every((i) => {
                                    const override =
                                        overriddenAnswersDictionary[
                                            i.toString()
                                        ];

                                    return (
                                        override !== undefined &&
                                        override.isDeleted
                                    );
                                })
                            );
                        }),
                    ),
                ),
            )
            .map((a) => a.answer)
            .filter(
                (a): a is IInspectionScheduleAnswer =>
                    a.type === "InspectionSchedule" ||
                    a.type === "ToggleButtonGroup",
            )
            .map((a) => a.payload.Observation?.ObservationId)
            .filter((i): i is string => i !== undefined);

        return deletedIds.map((e) => e);
    }, [answersSections, overriddenAnswersDictionary]);

    const observations = useMemo(
        () =>
            audit.observations.filter(
                (o) => !o.appId || !deletedEntities.includes(o.appId),
            ),
        [audit.observations, deletedEntities],
    );

    const agreedLimitations = useMemo(() => {
        const list: IAgreedLimitation[] = audit.jobLimitations
            .filter(
                (l) =>
                    !deletedEntities.includes(l.id) &&
                    l.agreedLimitationId !== null,
            )
            .map((l) => ({
                id: l.agreedLimitationId!,
                order: l.order,
                text: l.text,
            }));

        return list;
    }, [audit.jobLimitations, deletedEntities]);

    const operationalLimitations = useMemo(() => {
        const list: IOperationalLimitation[] = audit.jobLimitations
            .filter(
                (l) =>
                    !deletedEntities.includes(l.id) &&
                    l.agreedLimitationId === null,
            )
            .map((limitation) => ({
                id: limitation.id,
                title: limitation.title,
                description: limitation.text,
            }));

        return list;
    }, [audit.jobLimitations, deletedEntities]);

    const nonDeletedAnswersSections = useMemo(
        () =>
            answersSections.filter((s) =>
                s.children.some((c) =>
                    c.children.some((q) =>
                        q.children.some((a) => {
                            const override =
                                overriddenAnswersDictionary[a.answer.id];

                            return (
                                override === undefined || !override.isDeleted
                            );
                        }),
                    ),
                ),
            ),
        [answersSections, overriddenAnswersDictionary],
    );

    return (
        <>
            <ModalBody withPadding={false}>
                <AgreedLimitationsProvider
                    canLoadTypes={!isReadOnly}
                    landlordId={audit.job.property.landlord.id}
                    contractorId={audit.job.contractor?.id}
                >
                    <ObservationTypesProvider canLoadTypes={!isReadOnly}>
                        <div className={styles.container}>
                            {sidebarVisible && (
                                <div className={styles.sidebar}>
                                    <QualifiedSupervisorAuditSidebar
                                        audit={audit}
                                        answersSections={
                                            nonDeletedAnswersSections
                                        }
                                        isReadOnly={isReadOnly}
                                        reviewedSections={reviewedSections}
                                        isJobSummaryReviewed={
                                            isJobSummaryReviewed
                                        }
                                        isAuditReasonReviewed={
                                            isAuditReasonReviewed
                                        }
                                        isLimitationsReviewed={
                                            isLimitationsReviewed
                                        }
                                        isObservationsReviewed={
                                            isObservationsReviewed
                                        }
                                        createComment={createComment}
                                    />
                                </div>
                            )}

                            <div className={styles.mainContent}>
                                <QualifiedSupervisorAuditSection
                                    id="qs-jobSummary"
                                    title={t("Job summary")}
                                    isReviewed={isJobSummaryReviewed}
                                    isReadOnly={isReadOnly}
                                    toggleIsReviewed={
                                        toggleIsJobSummaryReviewed
                                    }
                                >
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>{t("Id")}</th>
                                                <th>{t("Property")}</th>
                                                <th>{t("Job date")}</th>
                                                <th>{t("Engineer")}</th>
                                                {audit.documentType !==
                                                    null && (
                                                    <th>
                                                        {t(
                                                            "{{documentType}} status",
                                                            {
                                                                documentType:
                                                                    audit.documentType,
                                                            },
                                                        )}
                                                    </th>
                                                )}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>#{audit.job.id}</td>
                                                <td>
                                                    {
                                                        audit.job.property
                                                            .addressString
                                                    }
                                                </td>
                                                <td>
                                                    {toDateString(
                                                        new Date(
                                                            audit.job.date,
                                                        ),
                                                    )}
                                                </td>
                                                <td>
                                                    {audit.job.engineer.name}
                                                </td>
                                                {audit.documentType !==
                                                    null && (
                                                    <td>
                                                        {audit.paperworkStatus}
                                                    </td>
                                                )}
                                            </tr>
                                        </tbody>
                                    </Table>
                                </QualifiedSupervisorAuditSection>

                                {audit.reason && (
                                    <QualifiedSupervisorAuditSection
                                        id="qs-jobInformation"
                                        title={t("Job information")}
                                        isReviewed={isAuditReasonReviewed}
                                        isReadOnly={isReadOnly}
                                        toggleIsReviewed={
                                            toggleIsAuditReasonReviewed
                                        }
                                    >
                                        <p className={styles.sectionSubtitle}>
                                            {t(
                                                "Reason for producing this report",
                                            )}
                                        </p>
                                        <p>{audit.reason}</p>
                                    </QualifiedSupervisorAuditSection>
                                )}

                                {nonDeletedAnswersSections.map((section) =>
                                    section.displayChildrenOnMenu ? (
                                        section.children.map((child) => (
                                            <QualifiedSupervisorAuditQuestionsAnswersSection
                                                key={child.id}
                                                id={`qs-${section.id}-${child.id}`}
                                                auditId={audit.id}
                                                documentType={
                                                    audit.documentType
                                                }
                                                title={child.title}
                                                isReviewed={
                                                    reviewedSections[
                                                        `qs-${section.id}-${child.id}`
                                                    ]
                                                }
                                                hiddenAnswer={
                                                    section.hiddenAnswer
                                                }
                                                toggleIsReviewed={
                                                    toggleReviewSection
                                                }
                                                isReadOnly={isReadOnly}
                                                questionGroups={[
                                                    {
                                                        id: child.id,
                                                        title: child.title,
                                                        children:
                                                            child.children,
                                                    },
                                                ]}
                                                overriddenAnswers={
                                                    overriddenAnswers
                                                }
                                                updateOverriddenAnswers={
                                                    updateOverriddenAnswers
                                                }
                                                validate={validateSection}
                                                canOverrideAnswers={
                                                    canOverrideAnswers
                                                }
                                                addAuditJobComment={
                                                    addAuditJobComment
                                                }
                                                addAuditObservation={
                                                    addAuditObservation
                                                }
                                                removeAuditJobComment={
                                                    removeAuditJobComment
                                                }
                                                removeAuditObservation={
                                                    removeAuditObservation
                                                }
                                                addAdditionalAnswers={
                                                    addAdditionalAnswers
                                                }
                                            />
                                        ))
                                    ) : (
                                        <QualifiedSupervisorAuditQuestionsAnswersSection
                                            key={section.id}
                                            id={`qs-${section.id}`}
                                            auditId={audit.id}
                                            documentType={audit.documentType}
                                            title={section.title}
                                            isReviewed={
                                                reviewedSections[
                                                    `qs-${section.id}`
                                                ]
                                            }
                                            hiddenAnswer={section.hiddenAnswer}
                                            toggleIsReviewed={
                                                toggleReviewSection
                                            }
                                            displayAsGrid={
                                                section.displayAsGrid
                                            }
                                            isReadOnly={isReadOnly}
                                            questionGroups={section.children}
                                            overriddenAnswers={
                                                overriddenAnswers
                                            }
                                            updateOverriddenAnswers={
                                                updateOverriddenAnswers
                                            }
                                            validate={validateSection}
                                            canOverrideAnswers={
                                                canOverrideAnswers
                                            }
                                            addAuditJobComment={
                                                addAuditJobComment
                                            }
                                            addAuditObservation={
                                                addAuditObservation
                                            }
                                            removeAuditJobComment={
                                                removeAuditJobComment
                                            }
                                            removeAuditObservation={
                                                removeAuditObservation
                                            }
                                            addAdditionalAnswers={
                                                addAdditionalAnswers
                                            }
                                        />
                                    ),
                                )}

                                <QualifiedSupervisorAuditSection
                                    id="qs-observations"
                                    title={t("Observations summary")}
                                    isReviewed={isObservationsReviewed}
                                    isReadOnly={isReadOnly}
                                    toggleIsReviewed={
                                        toggleIsObservationsReviewed
                                    }
                                >
                                    <QualifiedSupervisorAuditObservations
                                        observations={observations}
                                        auditObservations={
                                            audit.auditObservations
                                        }
                                        auditStatus={audit.status}
                                    />
                                </QualifiedSupervisorAuditSection>

                                <QualifiedSupervisorAuditSection
                                    id="qs-limitations"
                                    title={t("Limitations summary")}
                                    isReviewed={isLimitationsReviewed}
                                    isReadOnly={isReadOnly}
                                    toggleIsReviewed={
                                        toggleIsLimitationsReviewed
                                    }
                                >
                                    <QualifiedSupervisorAuditLimitations
                                        agreedLimitations={agreedLimitations}
                                        operationalLimitations={
                                            operationalLimitations
                                        }
                                        auditLimitations={
                                            audit.auditLimitations
                                        }
                                        auditStatus={audit.status}
                                    />
                                </QualifiedSupervisorAuditSection>
                            </div>
                        </div>
                    </ObservationTypesProvider>
                </AgreedLimitationsProvider>
            </ModalBody>

            {!isReadOnly && (
                <ModalFooter>
                    <Form
                        onSubmit={handleFormSubmit}
                        onInvalidSubmit={handleInvalidFormSubmit}
                        validate={validate}
                    >
                        <div className={styles.error}>
                            <ValidationError
                                isValid={isFormValid}
                                error={t(
                                    "Please save the individual sections first.",
                                )}
                            />
                        </div>
                        <Button onClick={onHoldAudit}>{t("Hold")}</Button>

                        <Button
                            variant="primary"
                            cssRules={{ marginLeft: "10px" }}
                            type="submit"
                            disabled={isSaveDisabled}
                        >
                            {t("Save")}
                        </Button>
                    </Form>
                </ModalFooter>
            )}
        </>
    );
};

export default QualifiedSupervisorAudit;
