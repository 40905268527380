import { Observable } from "rxjs";
import { ICreateJob, ICreateUnableToAccess } from "../../utils/api/jobs";
import ManualUploadForm from "./ManualUploadForm";

export interface IManualUploadFormProps {
    cardTitle: string;
    dateFieldLabel: string;
    showPaperworkTypes: boolean;
    showCommentsField: boolean;
    showUnsafeAppliances: boolean;
    isLoading: boolean;
    error: string;
    successMessage: string;
    onSubmit: (job: IManualUploadJob) => Observable<unknown>;
}

export type IManualUploadJob = ICreateUnableToAccess &
    ICreateJob & {
        file: File | null;
        filename: string;
        landlordId: string;
        otherPaperworkName: string;
        paperworkType: string;
        propertyUprn: string;
    };

interface IFuelType {
    id: number;
    displayName: string;
    color: string;
}

export interface IDueDateDisplay {
    fuelType: IFuelType;
    date: string | null;
    dueInDays: number;
    fontColor: string;
}

export interface IPropertySelector {
    id: number;
    addressString: string;
    colour: string;
    dates: IDueDateDisplay[];
}

export default ManualUploadForm;
