import { useCallback } from "react";
import { noop } from "rxjs";
import { IEditableListItemProps } from ".";
import { useToggle } from "../../../hooks";
import EditableListItemDeleteModal from "../EditableListItemDeleteModal";
import EditableListItemLayout from "../EditableListItemLayout";
import styles from "./EditableListItem.module.scss";

function EditableListItem<T>({
    isEditing,
    deleteModalTitle,
    deleteModalMessage,
    entity,
    getEntityId,
    renderForm,
    renderView,
    deleteRequest,
    onSave,
    onCancel,
    onEdit = noop,
    onDelete = noop,
    canEdit = true,
}: IEditableListItemProps<T>) {
    const { show, visible, hide } = useToggle();

    const handleEditClick = useCallback(() => {
        if (entity) {
            onEdit(getEntityId(entity));
        }
    }, [entity, getEntityId, onEdit]);

    const handleCancelClick = useCallback(() => {
        onCancel(entity ? getEntityId(entity) : "");
    }, [onCancel, getEntityId, entity]);

    return (
        <>
            <div className={styles.listItem}>
                {isEditing ? (
                    renderForm({
                        entity,
                        onSave,
                        onCancel: handleCancelClick,
                    })
                ) : (
                    <>
                        <EditableListItemLayout
                            isEditing={false}
                            onEdit={handleEditClick}
                            onDelete={show}
                            canEdit={canEdit}
                        >
                            {entity && renderView(entity)}
                        </EditableListItemLayout>
                    </>
                )}
            </div>

            {entity && visible && (
                <EditableListItemDeleteModal
                    id={getEntityId(entity)}
                    hide={hide}
                    onDelete={onDelete}
                    deleteRequest={deleteRequest}
                    title={deleteModalTitle}
                    message={deleteModalMessage}
                />
            )}
        </>
    );
}

export default EditableListItem;
