import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, Loading } from "../../../components";
import PieChart, { IPieChartData } from "../../../components/Charts/PieChart";
import { colours } from "../../../styles/colours";
import { IComplianceDashboard } from "../../../utils/api/misc";
import styles from "./NetPromoterScoreCard.module.scss";

const NetPromoterScoreCard = ({ loaded, data }: INetPromoterScoreCardProps) => {
    const { t } = useTranslation();

    const [promoters, setPromoters] = useState(0);
    const [passives, setPassives] = useState(0);
    const [detractors, setDetractors] = useState(0);

    const npsScore = useMemo(() => {
        const total = promoters + passives + detractors;

        if (total > 0) {
            return Math.round(((promoters - detractors) / total) * 100);
        }

        return 0;
    }, [promoters, passives, detractors]);

    const pieChartData = useMemo<IPieChartData[]>(
        () => [
            {
                label: "Passives",
                value: passives,
            },
            {
                label: "Promoters",
                value: promoters,
            },
            {
                label: "Detractors",
                value: detractors,
            },
        ],
        [promoters, passives, detractors],
    );

    useEffect(() => {
        let promotersCount = 0;
        let passivesCount = 0;
        let detractorsCount = 0;

        if (data) {
            for (const score of data.netPromoterScores) {
                promotersCount += score.data.promoters;
                passivesCount += score.data.passives;
                detractorsCount += score.data.detractors;
            }

            setPromoters(promotersCount);
            setPassives(passivesCount);
            setDetractors(detractorsCount);
        }
    }, [data]);

    return (
        <Card
            title={t("Net promoter score")}
            fullHeight={true}
            testId="NetPromoterScore_Widget"
        >
            {loaded ? (
                <div className={styles.centerContainer}>
                    <div className={styles.npsContainer}>
                        <div className={styles.chartContainer}>
                            <PieChart
                                data={pieChartData}
                                aspectRatio={1 / 1}
                                thickness={10}
                                showLabels={false}
                            />

                            <div className={styles.nps}>
                                <span
                                    className={`${styles.bold} ${styles.blue}`}
                                    data-test-id="NetPromoterScore_Span"
                                >
                                    {npsScore}
                                </span>
                                <br />
                                <span className={styles.label}>{t("NPS")}</span>
                            </div>
                        </div>
                        <div className={styles.separator} />

                        <div className={styles.labelsContainer}>
                            {pieChartData.map((item, index) => (
                                <div key={index}>
                                    <span
                                        className={styles.bold}
                                        style={{
                                            color: colours.chart.colours[index],
                                        }}
                                        data-test-id={`${item.label}_Total_Span`}
                                    >
                                        {item.value}
                                    </span>
                                    &nbsp;
                                    <span className={styles.label}>
                                        {t(item.label)}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            ) : (
                <Loading small={true} smallWidth={100} centerContent={true} />
            )}
        </Card>
    );
};

interface INetPromoterScoreCardProps {
    loaded: boolean;
    data?: IComplianceDashboard;
}

export default NetPromoterScoreCard;
