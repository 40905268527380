import { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { IAudit, IAuditComment, IAuditJobComment, IAuditObservation } from ".";
import { PropertyCategoriesContext, UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiValue from "../useApiValue";

const useAudit = (id?: number) => {
    const { t } = useTranslation();
    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);
    const { send, value, updateValue, error, loaded, reset } =
        useApiValue<IAudit>({
            errorMessage: t("An error occured. Please try again."),
        });
    const sendRequest = useXTagApiRequest();

    useEffect(() => {
        if (id !== undefined) {
            const subscription = send(
                sendRequest({
                    url: `${config.auditsApiUrl}/${id}`,
                    method: "GET",
                    urlParams: getGlobalUrlParameters(
                        selectedCategories.map((c) => c.id),
                        activeUserParentsIds,
                    ),
                }),
            ).subscribe();

            return () => {
                if (subscription) {
                    subscription.unsubscribe();
                }
            };
        } else {
            reset();
        }
    }, [
        id,
        selectedCategories,
        activeUserParentsIds,
        send,
        reset,
        sendRequest,
    ]);

    const addComment = useCallback(
        (comment: IAuditComment) => {
            updateValue((v) => ({
                ...v,
                comments: [...v.comments, comment],
            }));
        },
        [updateValue],
    );

    const addAuditJobComment = useCallback(
        (jobComment: IAuditJobComment) => {
            updateValue((v) => ({
                ...v,
                auditJobComments: [...v.auditJobComments, jobComment],
            }));
        },
        [updateValue],
    );

    const removeAuditJobComment = useCallback(
        (jobCommentId: string) => {
            updateValue((v) => ({
                ...v,
                auditJobComments: v.auditJobComments.filter(
                    (c) => c.id !== jobCommentId,
                ),
            }));
        },
        [updateValue],
    );

    const addAuditObservation = useCallback(
        (auditObservation: IAuditObservation) => {
            updateValue((v) => ({
                ...v,
                auditObservations: [...v.auditObservations, auditObservation],
            }));
        },
        [updateValue],
    );

    const removeAuditObservation = useCallback(
        (auditObservationId: string) => {
            updateValue((v) => ({
                ...v,
                auditObservations: v.auditObservations.filter(
                    (c) => c.id !== auditObservationId,
                ),
            }));
        },
        [updateValue],
    );

    const removeAuditOverrides = useCallback(() => {
        updateValue((v) => ({
            ...v,
            auditLimitations: [],
            auditObservations: [],
            auditJobComments: [],
        }));
    }, [updateValue]);

    return {
        value,
        error,
        loaded,
        addComment,
        addAuditJobComment,
        removeAuditJobComment,
        addAuditObservation,
        removeAuditObservation,
        removeAuditOverrides,
    };
};

export default useAudit;
