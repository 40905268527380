import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Link, Loading } from "../../../../components";
import { IUnableToAccess } from "../../../../utils/api/jobs";
import { toDateString, toTimeString } from "../../../../utils/dates";
import styles from "./MatchUtaRow.module.scss";

const MatchUtaRow = ({ uta, matchUta }: IMatchUtaRowProps) => {
    const { t } = useTranslation();
    const utaDate = new Date(uta.date);

    const [isMatching, setIsMatching] = useState(false);

    const matchUtaRow = () => {
        matchUta(uta.id);
        setIsMatching(true);
    };

    return (
        <div className={styles.container}>
            <div className={styles.utaColumn}>
                <Link url={`/issues/unabletoaccess/${uta.id}`}>
                    {toDateString(utaDate)} {t("at")} {toTimeString(utaDate)}
                </Link>
            </div>
            <div className={styles.engineerColumn}>
                <Link url={`/management/engineers/${uta.engineer.id}`}>
                    {uta.engineer.name}
                </Link>
            </div>
            <div className={styles.matchColumn}>
                <Button onClick={matchUtaRow}>
                    {isMatching ? <Loading small={true} /> : t("Match UTA")}
                </Button>
            </div>
        </div>
    );
};

interface IMatchUtaRowProps {
    uta: IUnableToAccess;
    matchUta: (utaId: number) => void;
}

export default MatchUtaRow;
