import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { switchMap } from "rxjs";
import {
    Card,
    Grid,
    GridColumn,
    Loading,
    UserPreferencesContext,
} from "../../../components";
import Link from "../../../components/Link/Link";
import { IPhase } from "../../../utils/api/properties";
import useCappedMaintenanceGraph from "../../../utils/api/properties/useCappedMaintenanceGraph";
import { addDays, getToday } from "../../../utils/dates";
import { IUrlParameters, encodeUrl } from "../../../utils/url";
import ChartActions from "../ChartComponents/ChartActions/ChartActions";
import ChartOverview from "../ChartComponents/ChartOverview";
import PropertyActiveFilter from "../ChartComponents/PropertyActiveFilter";
import PropertyGraph from "../ChartComponents/PropertyGraph";
import styles from "./CappedMaintenance.module.scss";

const CappedMaintenance = () => {
    const { t } = useTranslation();

    const { preferences, updatePreferences } = useContext(
        UserPreferencesContext,
    );

    const [cappedActiveStatus, setCappedActiveStatus] = useState("true");
    const cappedMaintenanceGraph =
        useCappedMaintenanceGraph(cappedActiveStatus);
    const [showGraph, setShowGraph] = useState(
        preferences.property_capped_view === "graph",
    );

    const onSave = (phases: IPhase[]) => {
        updatePreferences({
            ...preferences,
            capped_maintenance_phases: phases.map((phase) => ({
                colour: phase.colour,
                weeks_since: phase.phaseTimeFrame,
                title: phase.title,
            })),
        })
            .pipe(switchMap(() => cappedMaintenanceGraph.refresh()))
            .subscribe();
    };

    useEffect(() => {
        if (preferences.property_capped_occupied_status !== undefined) {
            setCappedActiveStatus(preferences.property_capped_occupied_status);
        }
    }, [preferences]);

    const cappedPhases = useMemo(
        () =>
            cappedMaintenanceGraph.value
                ? cappedMaintenanceGraph.value.phases.filter(
                      (p) => p.dates.length > 0,
                  )
                : [],
        [cappedMaintenanceGraph.value],
    );

    const toggleGraphOverview = (view: "graph" | "overview"): void => {
        updatePreferences({
            ...preferences,
            property_capped_view: view,
        }).subscribe(() => setShowGraph(view === "graph"));
    };

    const changeOccupiedStatus = (value: string): void => {
        updatePreferences({
            ...preferences,
            property_capped_occupied_status: value,
        }).subscribe();
    };

    const urlParams = useMemo(() => {
        const params: IUrlParameters = {
            lastMaintenanceDate: [`<${addDays(getToday(), 1).toISOString()}`],
            isCapped: [`=Capped`],
            ...(cappedActiveStatus !== "" && {
                occupied: [
                    `=${cappedActiveStatus === "true" ? "Occupied" : "Void"}`,
                ],
            }),
        };

        return params;
    }, [cappedActiveStatus]);

    return (
        <Card
            title={t("Capped Maintenance status")}
            fullHeight={true}
            testId="CappedMaintenanceStatus_Widget"
            actions={
                <ChartActions
                    phases={cappedPhases}
                    savePhaseLimits={onSave}
                    showGraph={showGraph}
                    toggleGraphOverview={toggleGraphOverview}
                    phaseTimeframe="Weeks Since"
                >
                    <PropertyActiveFilter
                        activeStatus={cappedActiveStatus}
                        onActiveStatusChange={changeOccupiedStatus}
                    />
                </ChartActions>
            }
        >
            {cappedMaintenanceGraph.loaded ? (
                <div className={styles.container}>
                    <div className={styles.leftColumn}>
                        <div className={styles.topRow}>
                            <Grid fullHeight={false}>
                                <GridColumn size="half">
                                    <div className={styles.capped}>
                                        <div className={styles.cappedValueLink}>
                                            <Link
                                                url={encodeUrl(
                                                    "/management/properties",
                                                    urlParams,
                                                )}
                                                color="red"
                                                testId="CappedTotal_A"
                                            >
                                                {
                                                    cappedMaintenanceGraph.value
                                                        .totalProperties
                                                }
                                            </Link>
                                        </div>
                                        <p className={styles.cappedLabel}>
                                            {t("Capped")}
                                        </p>
                                    </div>
                                </GridColumn>
                                <GridColumn size="half">
                                    <div
                                        className={`${styles.cappedValue} ${styles.green}`}
                                        data-test-id="OccupiedTotal_Div"
                                    >
                                        {cappedMaintenanceGraph.value.occupied}
                                        <p className={styles.label}>
                                            {t("Occupied")}
                                        </p>
                                    </div>
                                    <div
                                        className={`${styles.cappedValue} ${styles.green}`}
                                        data-test-id="VoidedTotal_Div"
                                    >
                                        {cappedMaintenanceGraph.value.voided}
                                        <p className={styles.label}>
                                            {t("Voided")}
                                        </p>
                                    </div>
                                </GridColumn>
                            </Grid>
                        </div>
                        <div>
                            <div className={`${styles.value} ${styles.blue}`}>
                                <Link
                                    url={encodeUrl("/management/properties", {
                                        nextServiceDueDate: [
                                            `<${getToday().toISOString()}`,
                                        ],
                                        lastMaintenanceDate: [
                                            `<=${getToday().toISOString()}`,
                                        ],
                                        isCapped: [`=Capped`],
                                        ...(cappedActiveStatus !== "" && {
                                            occupied: [
                                                `=${
                                                    cappedActiveStatus ===
                                                    "true"
                                                        ? "Occupied"
                                                        : "Void"
                                                }`,
                                            ],
                                        }),
                                    })}
                                    testId="RequireServiceTotal_A"
                                >
                                    {cappedMaintenanceGraph.value.toat}
                                </Link>
                            </div>
                            <p className={styles.valueLabel}>
                                {t("Will require service at TOAT")}
                            </p>
                        </div>
                    </div>
                    <div className={styles.rightColumn}>
                        {showGraph ? (
                            <PropertyGraph
                                phases={cappedPhases}
                                maxHeight={200}
                                dateFilterField="lastMaintenanceDate"
                                targetUrl="/management/properties"
                                targetUrlParams={urlParams}
                                phaseTimeFrameMultiplier={7}
                                areDatesInPast={true}
                            />
                        ) : (
                            <ChartOverview
                                phases={cappedPhases}
                                dateFilterField="lastMaintenanceDate"
                                targetUrl="/management/properties"
                                targetUrlParams={urlParams}
                                phaseTimeFrameMultiplier={7}
                                areDatesInPast={true}
                            />
                        )}
                        <div className={styles.chartLabel}>
                            {t("Weeks since Cap or Last Maintenance")}
                        </div>
                    </div>
                </div>
            ) : (
                <Loading />
            )}
        </Card>
    );
};

export default CappedMaintenance;
