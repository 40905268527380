import { IComplianceDashboard } from "../../../utils/api/misc";
import CompliancePropertyServicesDashboard from "./CompliancePropertyServicesDashboard";

export interface ICompliancePropertyServicesDashboardProps {
    rowHeight: number;
    dashboard: IComplianceDashboard;
    loaded: boolean;
    mapFeature: boolean;
}

export default CompliancePropertyServicesDashboard;
