import { IUrlParameters } from "../url";
import { IFilters } from "./useFilterable";

export const getFiltersUrlParams = (filters: IFilters) => {
    const urlParams: IUrlParameters = {};

    for (const key of Object.keys(filters)) {
        const filter = filters[key];

        const currentFilters = filter.filters
            .map((f) => `${f.function}${f.value}`)
            .join(filter.operator);

        if (currentFilters) {
            urlParams[key] = [currentFilters];
        }
    }

    return urlParams;
};
