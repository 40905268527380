import { CSSProperties, ReactNode } from "react";
import { InputSize } from "../InputField";
import { ISelectOption } from "../Select";
import SearchableSelect from "./SearchableSelect";

export interface ISearchableSelectProps {
    options: ISelectOption[];
    placeholder: string;
    label?: string;
    value?: string;
    useMargin?: boolean;
    applyPadding?: boolean;
    search?: string;
    isValid?: boolean;
    error?: string;
    cssRules?: CSSProperties;
    onChange?: (value: string) => void;
    customRender?: (value: string) => ReactNode;
    applySearch: (query: string) => void;
    hasMore?: boolean;
    loadNextPage?: () => void;
    disabled?: boolean;
    size?: InputSize;
    autoComplete?: boolean;
}

export default SearchableSelect;
