import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ITableActionsProps } from ".";
import { Dropdown, Icon, PropertyCategoriesContext } from "../../../components";
import { useToggle } from "../../../hooks";
import PropertyCategories from "../PropertyCategories";

const TableActions = ({ id }: ITableActionsProps) => {
    const { t } = useTranslation();
    const { show, hide, visible } = useToggle();
    const { propertyCategories, selectedCategories } = useContext(
        PropertyCategoriesContext,
    );

    const categories = useMemo(
        () =>
            selectedCategories.length > 0
                ? selectedCategories
                : propertyCategories,
        [propertyCategories, selectedCategories],
    );

    return (
        <>
            <Dropdown
                label={<Icon icon="more" ariaLabel={t("Actions")} />}
                size="small"
                items={[
                    {
                        label: t("Add Property Category"),
                        onClick: show,
                    },
                ]}
            />

            {visible && (
                <PropertyCategories
                    engineerId={id}
                    propertyCategories={categories}
                    hide={hide}
                />
            )}
        </>
    );
};

export default TableActions;
