import { useCallback, useContext } from "react";
import { IAppliance } from ".";
import { PropertyCategoriesContext, UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const useUpdateAppliance = () => {
    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);
    const { send } = useApiRequest<IAppliance>({});
    const sendRequest = useXTagApiRequest();

    const updateAppliance = useCallback(
        (id: string, appliance: IAppliance) =>
            send(
                sendRequest({
                    url: `${config.appliancesApiUrl}/${id}`,
                    method: "PUT",
                    urlParams: getGlobalUrlParameters(
                        selectedCategories.map((c) => c.id),
                        activeUserParentsIds,
                    ),
                    body: appliance,
                }),
            ),
        [activeUserParentsIds, selectedCategories, send, sendRequest],
    );

    return { updateAppliance };
};

export default useUpdateAppliance;
