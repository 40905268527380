import { ITenant } from "../../../utils/api/properties";
import TenantsCard from "./TenantsCard";

export interface ITenantsCardProps {
    propertyId: number;
    userParentId: number | null;
    tenants: ITenant[];
    onCreate: (tenant: ITenant) => void;
    onUpdate: (tenant: ITenant) => void;
    onDelete: (tenant: ITenant) => void;
}

export default TenantsCard;
