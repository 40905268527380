import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IQualifiedSupervisorAuditSidebarProps } from ".";
import HeadersAccordion from "../HeadersAccordion";
import QualifiedSupervisorAuditSectionHeader from "../QualifiedSupervisorAuditSectionHeader";

const QualifiedSupervisorAuditSidebar = ({
    audit,
    answersSections,
    reviewedSections,
    isReadOnly,
    isJobSummaryReviewed,
    isAuditReasonReviewed,
    isLimitationsReviewed,
    isObservationsReviewed,
    createComment,
}: IQualifiedSupervisorAuditSidebarProps) => {
    const { t } = useTranslation();
    const comments = useMemo(
        () =>
            audit.comments
                .filter((c) => !c.isRemedial)
                .sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1)),
        [audit.comments],
    );
    const remedialActions = useMemo(
        () =>
            audit.comments
                .filter((c) => c.isRemedial)
                .sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1)),
        [audit.comments],
    );

    return (
        <>
            <QualifiedSupervisorAuditSectionHeader
                id="qs-jobSummary"
                title={t("Job summary")}
                isReviewed={isJobSummaryReviewed}
            />

            {audit.reason && (
                <QualifiedSupervisorAuditSectionHeader
                    id="qs-jobInformation"
                    title={t("Job information")}
                    isReviewed={isAuditReasonReviewed}
                />
            )}

            {answersSections.map((section) => (
                <QualifiedSupervisorAuditSectionHeader
                    key={section.id}
                    id={`qs-${section.id}`}
                    title={section.title}
                    isReviewed={reviewedSections[`qs-${section.id}`]}
                >
                    {section.displayChildrenOnMenu &&
                        section.children.map((childSection) => (
                            <QualifiedSupervisorAuditSectionHeader
                                key={childSection.id}
                                id={`qs-${section.id}-${childSection.id}`}
                                title={childSection.title}
                                paddingLeft={true}
                                isReviewed={
                                    reviewedSections[
                                        `qs-${section.id}-${childSection.id}`
                                    ]
                                }
                            />
                        ))}
                </QualifiedSupervisorAuditSectionHeader>
            ))}

            <QualifiedSupervisorAuditSectionHeader
                id="qs-observations"
                title={t("Observations summary")}
                isReviewed={isObservationsReviewed}
            />

            <QualifiedSupervisorAuditSectionHeader
                id="qs-limitations"
                title={t("Limitations summary")}
                isReviewed={isLimitationsReviewed}
            />

            <HeadersAccordion
                auditId={audit.id}
                comments={comments}
                remedialActions={remedialActions}
                issues={audit.issues}
                isReadOnly={isReadOnly}
                createComment={createComment}
            />
        </>
    );
};

export default QualifiedSupervisorAuditSidebar;
