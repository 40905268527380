import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IQualifiedSupervisorDependenciesProps } from ".";
import { Alert, Button, UserContext } from "../../../components";
import ModalBody from "../../../components/Modal/ModalBody";
import styles from "./QualifiedSupervisorDependencies.module.scss";

const QualifiedSupervisorDependencies = ({
    auditDependencies,
    auditDependencyUserIds,
}: IQualifiedSupervisorDependenciesProps) => {
    const { t } = useTranslation();

    const { user } = useContext(UserContext);

    const isDependencyHeldByOtherUser = useMemo(() => {
        return auditDependencyUserIds.some((a) => a !== null && a !== user.id);
    }, [auditDependencyUserIds, user.id]);

    return isDependencyHeldByOtherUser ? (
        <ModalBody>
            <Alert type="error">
                {t(
                    "You can't view/edit this audit. The audit is on hold by someone else.",
                )}
            </Alert>
        </ModalBody>
    ) : (
        <ModalBody>
            <div className={styles.margin}>
                <Alert type="info">
                    {t(
                        "This audit depends on another audit. You need to audit any dependencies first.",
                    )}
                </Alert>
            </div>

            <Button variant="primary" onClick={auditDependencies}>
                {t("Audit dependency")}
            </Button>
        </ModalBody>
    );
};

export default QualifiedSupervisorDependencies;
