import { useRef } from "react";
import { IToggleColumnsProps } from ".";
import { Button, Checkbox, Icon, Popover } from "../../../components";
import PopoverListItem from "../../../components/Popover/PopoverListItem";
import { useToggle } from "../../../hooks";

const ToggleColumns = ({
    allColumns,
    columnvisiblity,
    toggleColumnvisibility,
}: IToggleColumnsProps) => {
    const anchorElement = useRef<HTMLButtonElement>(null);
    const { visible, toggle, hide } = useToggle();

    return (
        <>
            <Button onClick={toggle} setRef={anchorElement} size="small">
                <Icon icon="gear" display="inline-block" ariaHidden={true} />
            </Button>

            {visible && (
                <Popover anchorElement={anchorElement} hide={hide}>
                    {allColumns.map((column) => {
                        const handleClick = () => {
                            toggleColumnvisibility(column.id);
                        };

                        return (
                            <PopoverListItem
                                key={column.id}
                                onClick={handleClick}
                            >
                                <Checkbox
                                    value={column.id}
                                    checked={
                                        columnvisiblity[column.id] ?? false
                                    }
                                    useMargin={false}
                                    onChange={handleClick}
                                    ariaLabel={column.title}
                                    displayInline={true}
                                />
                                {column.title}
                            </PopoverListItem>
                        );
                    })}
                </Popover>
            )}
        </>
    );
};

export default ToggleColumns;
