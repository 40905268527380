import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Button,
    Form,
    Loading,
    Modal,
    MultilineInputField,
    Portal,
} from "../../../components";
import ModalBody from "../../../components/Modal/ModalBody";
import ModalFooter from "../../../components/Modal/ModalFooter";
import useCancelAwaitingAllocationJob from "../../../utils/api/allocatedJobs/useCancelAwaitingAllocation";
import {
    isRequired,
    maxLength,
    minLength,
    useValidateField,
    validateForm,
} from "../../../utils/validation";

const CancelAwaitingAllocation = ({
    addressString,
    jobId,
    hide,
}: ICancelAwaitingAllocationProps) => {
    const { t } = useTranslation();

    const { cancelAwaitingAllocationJob, loading } =
        useCancelAwaitingAllocationJob();

    const [cancellationReason, setCancellationReason] = useState("");

    const hideModal = useCallback(() => {
        setCancellationReason("");
        hide();
    }, [hide]);

    const handleCancelAwaitingAllocation = useCallback(() => {
        cancelAwaitingAllocationJob([jobId], cancellationReason).subscribe(() =>
            hideModal(),
        );
    }, [jobId, cancelAwaitingAllocationJob, cancellationReason, hideModal]);

    const cancellationReasonValidator = useValidateField(
        cancellationReason,
        isRequired(),
        maxLength(500),
        minLength(1),
    );

    const cancellationFormValidator = validateForm(() => [
        cancellationReasonValidator,
    ]);

    return (
        <Portal>
            <Form
                onSubmit={handleCancelAwaitingAllocation}
                {...cancellationFormValidator}
            >
                <Modal
                    title={`${t("Cancel Job")} - ${addressString}`}
                    hide={hideModal}
                >
                    <ModalBody>
                        <MultilineInputField
                            onChange={setCancellationReason}
                            value={cancellationReason}
                            label={t("Cancellation Reason")}
                            {...cancellationReasonValidator}
                        />
                    </ModalBody>
                    <ModalFooter>
                        {!loading ? (
                            <Button
                                type="submit"
                                variant="primary"
                                disabled={!cancellationReasonValidator.isValid}
                            >
                                {t("Cancel Job")}
                            </Button>
                        ) : (
                            <Loading small={true} />
                        )}
                    </ModalFooter>
                </Modal>
            </Form>
        </Portal>
    );
};

interface ICancelAwaitingAllocationProps {
    addressString: string;
    jobId: string;
    hide: () => void;
}

export default CancelAwaitingAllocation;
