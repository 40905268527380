import { useTranslation } from "react-i18next";
import { IQualifiedSupervisorAuditQuestionsSectionProps } from ".";
import useOverrideAnswersQS from "../hooks/useOverrideAnswersQS";
import useUnansweredQuestions from "../hooks/useUnansweredQuestions";
import QualifiedSupervisorAuditRow from "../QualifiedSupervisorAuditRow";
import SectionEditButtons from "../SectionEditButtons";
import Table from "../Table";
import styles from "./QualifiedSupervisorAuditQuestionsSection.module.scss";

const QualifiedSupervisorAuditQuestionsSection = ({
    id,
    auditId,
    isReadOnly,
    documentType,
    questions,
    overriddenAnswers,
    hiddenAnswer,
    updateOverriddenAnswers,
    validate,
    addAuditJobComment,
    addAuditObservation,
    removeAuditJobComment,
    removeAuditObservation,
    addAdditionalAnswers,
}: IQualifiedSupervisorAuditQuestionsSectionProps) => {
    const { t } = useTranslation();

    const {
        isEditing,
        disableEditMode,
        enableEditMode,
        questionsValidation,
        validateQuestion,
        initialOverriddenAnswers,
        updatedOverriddenAnswers,
        overrideAnswer,
        handleCancelEditClick,
        allQuestionsAreValid,
    } = useOverrideAnswersQS(id, questions, overriddenAnswers, validate);

    useUnansweredQuestions(auditId, isEditing, questions, addAdditionalAnswers);

    return (
        <Table highlightColumn={true}>
            <thead>
                <tr>
                    <th className={styles.questionColumn} />
                    <th />
                    <th className={styles.editColumn}>{t("QS response")}</th>
                    {!isReadOnly && (
                        <th className={styles.actionsColumn}>
                            <SectionEditButtons
                                auditId={auditId}
                                updateOverriddenAnswers={
                                    updateOverriddenAnswers
                                }
                                questions={questions}
                                disableEditMode={disableEditMode}
                                enableEditMode={enableEditMode}
                                isEditing={isEditing}
                                hiddenAnswer={hiddenAnswer}
                                allQuestionsAreValid={allQuestionsAreValid}
                                initialOverriddenAnswers={
                                    initialOverriddenAnswers
                                }
                                overriddenAnswers={updatedOverriddenAnswers}
                                onCancelClick={handleCancelEditClick}
                                addAuditJobComment={addAuditJobComment}
                                addAuditObservation={addAuditObservation}
                                removeAuditJobComment={removeAuditJobComment}
                                removeAuditObservation={removeAuditObservation}
                            />
                        </th>
                    )}
                </tr>
            </thead>
            <tbody>
                {questions.map((question) => (
                    <QualifiedSupervisorAuditRow
                        key={question.id}
                        questionId={question.id}
                        question={question.title}
                        answers={question.children}
                        documentType={documentType}
                        overriddenAnswers={updatedOverriddenAnswers}
                        isReadOnly={isReadOnly}
                        isEditing={isEditing}
                        errorMessage={questionsValidation[question.id]}
                        overrideAnswer={overrideAnswer}
                        validateQuestion={validateQuestion}
                    />
                ))}
            </tbody>
        </Table>
    );
};

export default QualifiedSupervisorAuditQuestionsSection;
