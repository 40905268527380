import { IComplianceTypesProps } from ".";
import { Grid, GridColumn } from "../../../components";
import ComplianceCategory from "../../../components/ComplianceCategory";
import { toDateString } from "../../../utils/dates";
import styles from "./ComplianceTypes.module.scss";

const ComplianceTypes = ({ complianceTypes }: IComplianceTypesProps) => {
    return (
        <>
            {complianceTypes.map((entity) => (
                <div key={entity.id} className={styles.complianceTypesItem}>
                    <Grid>
                        <GridColumn size="half">
                            <ComplianceCategory
                                colour={entity.colour}
                                displayName={entity.displayName}
                                displayBlock={true}
                            />
                        </GridColumn>
                        <GridColumn size="half">
                            {toDateString(new Date(entity.nextServiceDate))}
                        </GridColumn>
                    </Grid>
                </div>
            ))}
        </>
    );
};

export default ComplianceTypes;
