import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { IPropertyAssignFlags } from ".";
import { PropertyCategoriesContext, UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const useAssignPropertyFlags = () => {
    const { t } = useTranslation();
    const { activeUserParentsIds } = useContext(UserContext);
    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { send } = useApiRequest({
        errorMessage: t("Failed to save the changes."),
    });
    const sendRequest = useXTagApiRequest();

    const assignPropertyFlags = useCallback(
        (propertyFlags: IPropertyAssignFlags) => {
            return send(
                sendRequest({
                    url: `${config.miscApiUrl}/flags/types/property`,
                    method: "POST",
                    urlParams: getGlobalUrlParameters(
                        selectedCategories.map((c) => c.id),
                        activeUserParentsIds,
                    ),
                    body: propertyFlags,
                }),
            );
        },
        [send, sendRequest, selectedCategories, activeUserParentsIds],
    );

    return { assignPropertyFlags };
};

export default useAssignPropertyFlags;
