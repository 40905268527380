import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Button,
    Form,
    Icon,
    Modal,
    Portal,
    ValidationError,
} from "../../../../components";
import ModalBody from "../../../../components/Modal/ModalBody";
import ModalFooter from "../../../../components/Modal/ModalFooter";
import { colours } from "../../../../styles/colours";
import { IPhase } from "../../../../utils/api/properties";
import {
    isRequired,
    useValidateField,
    validateForm,
} from "../../../../utils/validation";
import PhaseLimitsRow from "../PhaseLimitsRow";
import styles from "./PhaseLimits.module.scss";

const PhaseLimits = ({
    phases,
    hide,
    onSave,
    timeFrame,
}: IPhaseLimitsProps) => {
    const { t } = useTranslation();

    const [internalPhases, setInternalPhases] = useState(
        phases.map((p) => ({ ...p, isEditing: false })),
    );
    const handleCreatePhase = () => {
        const temp = [...internalPhases];
        let found = false;

        for (const phase of internalPhases) {
            if (phase.phaseTimeFrame === 0) {
                phase.isEditing = true;
                found = true;
            }
        }

        if (!found) {
            let phaseColor = "";
            const usedColors = internalPhases.map((phase) => phase.colour);
            for (const color of colours.colourPicker) {
                if (!usedColors.includes(color)) {
                    phaseColor = color;
                    break;
                }
            }

            temp.push({
                dates: [],
                phaseTimeFrame: 0,
                title: "",
                isEditing: true,
                colour: phaseColor,
            });
        }

        setInternalPhases(temp);
    };

    const handleToggleEditPhase = (phaseTimeFrame: number) => {
        const temp = [...internalPhases];

        for (const phase of internalPhases) {
            if (phase.phaseTimeFrame === phaseTimeFrame) {
                phase.isEditing = !phase.isEditing;
            }
        }

        setInternalPhases(temp);
    };

    const handleUpdatePhase = (
        phaseTimeFrame: number,
        updatedPhase: IPhase,
    ) => {
        const temp = [...internalPhases];

        for (const phase of internalPhases) {
            if (phase.phaseTimeFrame === phaseTimeFrame) {
                phase.isEditing = false;
                phase.colour = updatedPhase.colour;
                phase.dates = updatedPhase.dates;
                phase.phaseTimeFrame = updatedPhase.phaseTimeFrame;
                phase.title = updatedPhase.title;
            }
        }

        setInternalPhases(temp);
    };

    const handleDeletePhase = (phaseTimeFrame: number) => {
        setInternalPhases(
            internalPhases.filter((p) => p.phaseTimeFrame !== phaseTimeFrame),
        );
    };

    const editingPhases = useMemo(
        () =>
            internalPhases
                .map((p) => !p.isEditing)
                .reduce((a, b) => a && b, true),
        [internalPhases],
    );

    const phasesValidator = useValidateField(
        editingPhases,
        isRequired(t("Please save the individual rows first.")),
    );
    const formValidator = validateForm(() => [phasesValidator]);

    const [showFormError, setShowFormError] = useState(false);
    const handleInvalidSubmit = () => {
        setShowFormError(true);
    };
    useEffect(() => {
        if (!editingPhases) {
            setShowFormError(false);
        }
    }, [editingPhases]);

    const handleSave = () => {
        hide();
        onSave(internalPhases);
    };

    return (
        <Portal>
            <Modal hide={hide}>
                <ModalBody>
                    <table className={styles.table}>
                        <thead>
                            <tr>
                                <th className={styles.th}>{t("Name")}</th>
                                <th className={styles.th}>{t(timeFrame)}</th>
                                <th className={styles.th}>{t("Colour")}</th>
                                <th className={styles.th} />
                            </tr>
                        </thead>

                        <tbody>
                            {internalPhases.map((phase) => (
                                <PhaseLimitsRow
                                    key={phase.phaseTimeFrame}
                                    id={phase.phaseTimeFrame}
                                    phase={phase}
                                    isEditing={phase.isEditing}
                                    onToggleEdit={handleToggleEditPhase}
                                    onUpdateClick={handleUpdatePhase}
                                    onDeleteClick={handleDeletePhase}
                                    preventDelete={internalPhases.length === 1}
                                    excludedValues={internalPhases
                                        .map((p) => p.phaseTimeFrame)
                                        .filter(
                                            (d) => d !== phase.phaseTimeFrame,
                                        )}
                                />
                            ))}
                        </tbody>
                    </table>
                </ModalBody>
                <ModalFooter>
                    <Form
                        onSubmit={handleSave}
                        {...formValidator}
                        onInvalidSubmit={handleInvalidSubmit}
                    >
                        {showFormError && (
                            <ValidationError {...phasesValidator} />
                        )}

                        <div className={styles.actions}>
                            <Button
                                onClick={handleCreatePhase}
                                testId="AddNewPhase_Button"
                            >
                                <Icon icon="plus" ariaHidden={true} />
                                &nbsp;
                                {t("Add new phase")}
                            </Button>

                            <div>
                                <Button
                                    variant="primary"
                                    type="submit"
                                    cssRules={{ marginLeft: "10px" }}
                                    testId="Save_Button"
                                >
                                    {t("Save")}
                                </Button>

                                <Button
                                    onClick={hide}
                                    cssRules={{ marginLeft: "10px" }}
                                    testId="Cancel_Button"
                                >
                                    {t("Cancel")}
                                </Button>
                            </div>
                        </div>
                    </Form>
                </ModalFooter>
            </Modal>
        </Portal>
    );
};

interface IPhaseLimitsProps {
    phases: IPhase[];
    timeFrame: string;
    hide: () => void;
    onSave: (phases: IPhase[]) => void;
}

export default PhaseLimits;
