import { useCallback } from "react";
import { IComplianceTypesTabProps } from ".";
import { IPropertyCategory } from "../../../utils/api/properties";
import { clearCache } from "../../../utils/cache";
import {
    realtimeCreateList,
    realtimeDeleteList,
    realtimeUpdateList,
} from "../../../utils/realtime";
import ComplianceTypesCard from "../ComplianceTypesCard";

const ComplianceTypesTab = ({
    property,
    updateProperty,
}: IComplianceTypesTabProps) => {
    const handleCreate = useCallback(
        (category: IPropertyCategory) => {
            const list = realtimeCreateList(category, property.categories);
            if (list) {
                updateProperty({ ...property, categories: list });
            }
            clearCache();
        },
        [property, updateProperty],
    );

    const handleUpdate = useCallback(
        (category: IPropertyCategory) => {
            const list = realtimeUpdateList(category, property.categories);
            if (list) {
                updateProperty({ ...property, categories: list });
            }
            clearCache();
        },
        [property, updateProperty],
    );

    const handleDelete = useCallback(
        (category: IPropertyCategory) => {
            const list = realtimeDeleteList(category, property.categories);
            if (list) {
                updateProperty({ ...property, categories: list });
            }
            clearCache();
        },
        [property, updateProperty],
    );

    return (
        <ComplianceTypesCard
            propertyId={property.id}
            userParentId={property.landlord.userId}
            landlordId={property.landlord.id}
            categories={property.categories}
            onCreate={handleCreate}
            onUpdate={handleUpdate}
            onDelete={handleDelete}
        />
    );
};

export default ComplianceTypesTab;
