import { useTranslation } from "react-i18next";
import { Dropdown, Icon } from "../../../components";

const TableActions = ({
    assignContractorModalOpen,
    editPropertyFlagsModalOpen,
    allocateJobModalOpen,
    addNoteModalOpen,
}: ITableActionsProps) => {
    const { t } = useTranslation();

    const items = [
        {
            label: t("Assign Contractors"),
            onClick: assignContractorModalOpen,
        },
        {
            label: t("Edit Flags"),
            onClick: editPropertyFlagsModalOpen,
        },
        {
            label: t("Allocate Job"),
            onClick: allocateJobModalOpen,
        },
    ];

    if (addNoteModalOpen) {
        items.push({
            label: t("Add Note"),
            onClick: addNoteModalOpen,
        });
    }

    return (
        <Dropdown
            label={<Icon icon="more" ariaLabel={t("Actions")} />}
            size="small"
            items={items}
        />
    );
};

interface ITableActionsProps {
    assignContractorModalOpen: () => void;
    editPropertyFlagsModalOpen: () => void;
    allocateJobModalOpen: () => void;
    addNoteModalOpen?: () => void;
}

export default TableActions;
