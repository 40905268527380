import { useContext, useEffect } from "react";
import { filter, map } from "rxjs";
import { IProperty, IPropertyCategory, ITenant } from ".";
import {
    PropertyCategoriesContext,
    RealtimeContext,
    UserContext,
} from "../../../components";
import config from "../../../config";
import { clearCache } from "../../cache";
import {
    realtimeCreateList,
    realtimeDeleteList,
    realtimeUpdateList,
} from "../../realtime";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import { IJob, IUnableToAccess } from "../jobs";
import useApiValue from "../useApiValue";

const useProperty = (id: string) => {
    const { realtimeObservable } = useContext(RealtimeContext);

    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);
    const { value, loading, error, send, updateValue } = useApiValue<IProperty>(
        {},
    );
    const sendRequest = useXTagApiRequest();

    useEffect(() => {
        if (value) {
            const subscription = realtimeObservable
                .pipe(
                    filter(
                        (e) => e.entity === "property" && e.event === "updated",
                    ),
                    map((e) => e.payload as IProperty),
                    filter((e) => Number(id) === e.id),
                )
                .subscribe((newValue) => {
                    updateValue({
                        ...value,
                        ...newValue,
                    });
                    clearCache();
                });

            return () => {
                if (subscription) {
                    subscription.unsubscribe();
                }
            };
        }
    }, [id, realtimeObservable, updateValue, value]);

    useEffect(() => {
        if (value) {
            const subscription = realtimeObservable
                .pipe(
                    filter((e) => e.entity === "job" && e.event === "created"),
                    map((e) => e.payload as IJob),
                    filter((e) => Number(id) === e.property.id),
                )
                .subscribe((newValue) => {
                    if (
                        !value.lastServiceDate ||
                        newValue.date > value.lastServiceDate
                    ) {
                        updateValue({
                            ...value,
                            lastServiceDate: newValue.date,
                            currentAttemptsCount: 0,
                        });
                    }

                    clearCache();
                });

            return () => {
                if (subscription) {
                    subscription.unsubscribe();
                }
            };
        }
    }, [id, realtimeObservable, updateValue, value]);

    useEffect(() => {
        if (value) {
            const subscription = realtimeObservable
                .pipe(
                    filter(
                        (e) =>
                            e.entity === "unable-to-access" &&
                            e.event === "created",
                    ),
                    map((e) => e.payload as IUnableToAccess),
                    filter((e) => Number(id) === e.property.id),
                )
                .subscribe((newValue) => {
                    if (
                        !value.lastServiceDate ||
                        newValue.date > value.lastServiceDate
                    ) {
                        const nextServiceDueDates =
                            value.nextServiceDueDates.map((serviceDate) => {
                                if (
                                    serviceDate.fuelTypeId ===
                                    newValue.complianceType.id
                                )
                                    return {
                                        ...serviceDate,
                                        noAccessCount:
                                            (serviceDate.noAccessCount += 1),
                                    };

                                return serviceDate;
                            });

                        updateValue({
                            ...value,
                            nextServiceDueDates,
                            currentAttemptsCount:
                                value.currentAttemptsCount + 1,
                        });
                    }

                    clearCache();
                });

            return () => {
                if (subscription) {
                    subscription.unsubscribe();
                }
            };
        }
    }, [id, realtimeObservable, updateValue, value]);

    useEffect(() => {
        if (value) {
            const subscription = realtimeObservable
                .pipe(
                    filter((e) => e.entity === "tenant"),
                    map((e) => ({
                        payload: e.payload as ITenant,
                        event: e.event,
                    })),
                    filter((e) => Number(id) === e.payload.propertyId),
                )
                .subscribe((newValue) => {
                    let updatedList: ITenant[] | false = false;

                    switch (newValue.event) {
                        case "created": {
                            updatedList = realtimeCreateList(
                                newValue.payload,
                                value.tenants,
                            );
                            break;
                        }
                        case "updated": {
                            updatedList = realtimeUpdateList(
                                newValue.payload,
                                value.tenants,
                            );
                            break;
                        }
                        case "deleted": {
                            updatedList = realtimeDeleteList(
                                newValue.payload,
                                value.tenants,
                            );
                            break;
                        }
                    }

                    if (updatedList) {
                        updateValue({
                            ...value,
                            tenants: updatedList,
                        });
                    }

                    clearCache();
                });

            return () => {
                if (subscription) {
                    subscription.unsubscribe();
                }
            };
        }
    }, [id, realtimeObservable, updateValue, value]);

    useEffect(() => {
        if (value) {
            const subscription = realtimeObservable
                .pipe(
                    filter((e) => e.entity === "property-category"),
                    map((e) => ({
                        payload: e.payload as IPropertyCategory,
                        event: e.event,
                    })),
                    filter((e) => Number(id) === e.payload.propertyId),
                )
                .subscribe((newValue) => {
                    let updatedList: IPropertyCategory[] | false = false;

                    switch (newValue.event) {
                        case "created": {
                            updatedList = realtimeCreateList(
                                newValue.payload,
                                value.categories,
                            );
                            break;
                        }
                        case "updated": {
                            updatedList = realtimeUpdateList(
                                newValue.payload,
                                value.categories,
                            );
                            break;
                        }
                        case "deleted": {
                            updatedList = realtimeDeleteList(
                                newValue.payload,
                                value.categories,
                            );
                            break;
                        }
                    }

                    if (updatedList) {
                        updateValue({
                            ...value,
                            categories: updatedList,
                        });
                    }

                    clearCache();
                });

            return () => {
                if (subscription) {
                    subscription.unsubscribe();
                }
            };
        }
    }, [id, realtimeObservable, updateValue, value]);

    useEffect(() => {
        const subscription = send(
            sendRequest({
                url: `${config.propertiesApiUrl}/${id}`,
                method: "GET",
                urlParams: getGlobalUrlParameters(
                    selectedCategories.map((c) => c.id),
                    activeUserParentsIds,
                ),
            }),
        ).subscribe();

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [activeUserParentsIds, id, selectedCategories, send, sendRequest]);

    return { value, loading, error, updateValue };
};

export default useProperty;
