import { useCallback } from "react";
import config from "../../../config";
import { useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const useUpdateUserParents = () => {
    const { send, loading } = useApiRequest({});
    const sendRequest = useXTagApiRequest();

    const updateUserParents = useCallback(
        (userParentIds: number[]) =>
            send(
                sendRequest({
                    url: `${config.usersApiUrl}/parentusers`,
                    method: "POST",
                    body: {
                        userParentIds,
                    },
                }),
            ),
        [send, sendRequest],
    );

    return { loading, updateUserParents };
};

export default useUpdateUserParents;
