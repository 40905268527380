import { ReactNode } from "react";
import Collapse from "./Collapse";

export interface ICollapseProps {
    title: string;
    expandedSectionId: string;
    sectionId: string;
    onExpandToggle: (sectionId: string) => void;
    children: ReactNode;
}

export default Collapse;
