import { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IUserParentsPopoverProps } from ".";
import { Button, Checkbox, Popover, UserContext } from "../../../..";

const UserParentsPopover = ({
    hide,
    anchorElement,
}: IUserParentsPopoverProps) => {
    const { t } = useTranslation();

    const { user, activateUserParents } = useContext(UserContext);

    const [userParents, setUserParents] = useState(
        user.userParents.map((u) => ({
            ...u,
            landlord: u.landlord ? { ...u.landlord } : null,
        })),
    );

    const activeUserParentsCount = useMemo(
        () => userParents.filter((userParent) => userParent.isActive).length,
        [userParents],
    );

    const disabled = useMemo(() => {
        const original = user.userParents
            .filter((userParent) => userParent.isActive)
            .map((u) => u.userParentId)
            .toString();
        const current = userParents
            .filter((userParent) => userParent.isActive)
            .map((u) => u.userParentId)
            .toString();

        return original === current;
    }, [user.userParents, userParents]);

    const handleUserParentChange = useCallback(
        (checked: boolean, value: number) => {
            setUserParents((users) => {
                const index = users.map((u) => u.userParentId).indexOf(value);
                users[index] = { ...users[index], isActive: checked };

                return [...users];
            });
        },
        [],
    );

    const handleSave = useCallback(() => {
        activateUserParents(
            userParents.filter((u) => u.isActive).map((u) => u.userParentId),
        );
        hide();
    }, [activateUserParents, hide, userParents]);

    return (
        <Popover anchorElement={anchorElement} hide={hide}>
            {userParents.map((userParent) => (
                <Checkbox
                    key={userParent.id}
                    onChange={handleUserParentChange}
                    checked={userParent.isActive}
                    value={userParent.userParentId}
                    disabled={
                        userParent.isActive && activeUserParentsCount === 1
                    }
                >
                    {userParent.landlord?.name ?? ""}
                </Checkbox>
            ))}

            <Button
                variant="primary"
                displayBlock={true}
                disabled={disabled}
                onClick={handleSave}
            >
                {t("Save")}
            </Button>
        </Popover>
    );
};

export default UserParentsPopover;
