import { IValidator } from "../../../../utils/validation";
import LimitationSet from "./LimitationSet";

export interface IEditLimitation {
    id: string;
    text: string;
}

export const limitationsValidation: IValidator<IEditLimitation[]> = {
    validate: (value) => {
        const isValid = value.every((v) => v.text);
        const error = "The field is required.";

        return { isValid, error: isValid ? "" : error };
    },
};

export default LimitationSet;
