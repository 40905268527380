import { useTranslation } from "react-i18next";
import { ITotalJobsAverageTimeCardProps } from ".";
import { Card, Icon, Link, Loading } from "../../../components";
import DashboardWidgetActiveFilters from "../../../components/DashboardWidgetActiveFilters";
import DashboardWidgetFilter from "../../../components/DashboardWidgetFilter";
import useJobLevelWidgetFilter from "../../../hooks/useJobLevelWidgetFilter";
import TotalJobsAverageTime from "../TotalJobsAverageTime";
import styles from "./TotalJobsAverageTimeCard.module.scss";

const TotalJobsAverageTimeCard = ({
    data,
    loaded,
}: ITotalJobsAverageTimeCardProps) => {
    const { t } = useTranslation();

    const { filters, url, totalJobs, jobTimes } = useJobLevelWidgetFilter(
        "total_jobs_average_time_job_level",
        data,
    );

    return (
        <Card
            title={t("Total jobs")}
            fullHeight={true}
            testId="TotalJobs_Widget"
            actions={
                <>
                    <Link url={url} ariaLabel={t("View all jobs")}>
                        <Icon
                            icon="eye"
                            size={12}
                            display="inline-block"
                            cssRules={{ marginRight: "5px" }}
                            ariaHidden={true}
                        />
                        {t("View all")}
                    </Link>

                    <DashboardWidgetFilter filters={filters} />
                </>
            }
        >
            {loaded ? (
                <div className={styles.container}>
                    <TotalJobsAverageTime
                        totalJobs={totalJobs}
                        jobTimes={jobTimes}
                    />

                    <DashboardWidgetActiveFilters filters={filters} />
                </div>
            ) : (
                <Loading small={true} smallWidth={100} centerContent={true} />
            )}
        </Card>
    );
};

export default TotalJobsAverageTimeCard;
