import Pagination from "./Pagination";

export interface IPaginationProps {
    currentPage: number;
    totalPages: number;
    goToPage: (page: number) => void;
    goToNextPage: () => void;
    goToPreviousPage: () => void;
}

export default Pagination;
