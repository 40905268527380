import { useCallback, useMemo, useState } from "react";
import { IComplianceTypeFormProps } from ".";
import {
    EditableListItemLayout,
    Form,
    Grid,
    GridColumn,
    Select,
} from "../../../components";
import ComplianceCategory from "../../../components/ComplianceCategory";
import { ISelectOption } from "../../../components/Select";
import { useAllContractors } from "../../../utils/api/landlords";
import {
    useCreatePropertyCategory,
    useUpdatePropertyCategory,
} from "../../../utils/api/properties";
import {
    isRequired,
    useValidateField,
    validateForm,
} from "../../../utils/validation";

const ComplianceTypeForm = ({
    propertyCategories,
    propertyId,
    entity,
    onSave,
    onCancel,
}: IComplianceTypeFormProps) => {
    const contractors = useAllContractors();
    const contractorOptions = useMemo<ISelectOption[]>(
        () =>
            contractors.records.map((contractor) => ({
                label: contractor.name,
                value: contractor.id.toString(),
            })),
        [contractors.records],
    );

    const [
        createPropertyCategory,
        createPropertyCategoryLoading,
        createPropertyCategoryError,
    ] = useCreatePropertyCategory(propertyId);
    const [
        updatePropertyCategory,
        updatePropertyCategoryLoading,
        updatePropertyCategoryError,
    ] = useUpdatePropertyCategory(propertyId);

    const [categoryId, setCategoryId] = useState(
        entity?.propertyCategoryId.toString() || "",
    );
    const [contractorId, setContractorId] = useState(
        entity?.contractors.map((c) => c.id)[0]?.toString() || "",
    );

    const categoryIdValidation = useValidateField(categoryId, isRequired());
    const formValidation = validateForm(() => [categoryIdValidation]);

    const handleSubmit = useCallback(() => {
        const selectedContractors = contractorId ? [Number(contractorId)] : [];
        if (entity) {
            updatePropertyCategory(entity.id, {
                contractors: selectedContractors,
            }).subscribe(onSave);
        } else if (categoryId) {
            createPropertyCategory({
                propertyCategoryId: Number(categoryId),
                contractors: selectedContractors,
            }).subscribe(onSave);
        }
    }, [
        contractorId,
        entity,
        updatePropertyCategory,
        onSave,
        createPropertyCategory,
        categoryId,
    ]);

    return (
        <Form onSubmit={handleSubmit} {...formValidation}>
            <EditableListItemLayout
                isEditing={true}
                onCancel={onCancel}
                loading={
                    createPropertyCategoryLoading ||
                    updatePropertyCategoryLoading
                }
                error={
                    createPropertyCategoryError || updatePropertyCategoryError
                }
            >
                <Grid>
                    <GridColumn size="thirtyPercent">
                        {entity ? (
                            <ComplianceCategory
                                colour={entity.colour}
                                displayName={entity.displayName}
                                displayBlock={true}
                            />
                        ) : (
                            <Select
                                value={categoryId}
                                allowEmpty={true}
                                useMargin={false}
                                size="small"
                                onChange={setCategoryId}
                                options={propertyCategories}
                                {...categoryIdValidation}
                            />
                        )}
                    </GridColumn>
                    <GridColumn size="seventyPercent">
                        <Select
                            value={contractorId}
                            onChange={setContractorId}
                            options={contractorOptions}
                            canDeselect={true}
                            allowEmpty={true}
                            useMargin={false}
                            size="small"
                        />
                    </GridColumn>
                </Grid>
            </EditableListItemLayout>
        </Form>
    );
};

export default ComplianceTypeForm;
