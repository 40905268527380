import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IOverrideAnswersProps } from ".";
import { InputField, ValidationError } from "../../../components";
import { IInspectionSchedulePayload } from "../../../utils/api/answers";
import OverrideAnswer from "../OverrideAnswer";
import styles from "./OverrideAnswers.module.scss";

const OverrideAnswers = ({
    questionId,
    answers,
    errorMessage,
    overriddenAnswers,
    documentType,
    overrideAnswer,
    validateQuestion,
}: IOverrideAnswersProps) => {
    const { t } = useTranslation();

    useEffect(() => {
        let error: string | undefined;

        for (const answer of answers) {
            switch (answer.type) {
                case "Reading": {
                    if (!answer.questionName.endsWith("Item")) {
                        let payload =
                            overriddenAnswers[answer.id]?.answer ?? "";

                        if (typeof payload === "string") {
                            payload = payload.replace(/^"(.+)"$/, "$1");
                        }

                        if (isNaN(Number(payload))) {
                            error = t("Please type a valid number.");
                        }
                    }

                    break;
                }

                case "InspectionSchedule":
                case "ToggleButtonGroup": {
                    const payload = overriddenAnswers[answer.id]?.answer;

                    if (payload && typeof payload !== "string") {
                        if (payload.Observation) {
                            if (
                                !payload.Observation.Code ||
                                !payload.Observation.Description
                            ) {
                                error = t(
                                    "Please provide a code and description for the observation.",
                                );
                            }
                        } else if (payload.Comment) {
                            if (!payload.Comment.Notes) {
                                error = t(
                                    "Please add some notes on the comment.",
                                );
                            }
                        }
                    }

                    break;
                }
                default: {
                    break;
                }
            }
        }

        validateQuestion(questionId, error);
    }, [answers, overriddenAnswers, questionId, t, validateQuestion]);

    const [comment, setComment] = useState(() => {
        for (const answer of answers) {
            const override = overriddenAnswers[answer.id];

            if (override?.comment) {
                return override?.comment;
            }
        }

        return "";
    });
    const handleCommentChange = useCallback(
        (value: string) => {
            setComment(value);

            for (const answer of answers) {
                const override = overriddenAnswers[answer.id];

                if (override !== undefined) {
                    overrideAnswer(answer.id, override.answer, value ?? null);
                }
            }
        },
        [answers, overriddenAnswers, overrideAnswer],
    );

    const handleOverrideAnswer = useCallback(
        (answerId: number, answer?: string | IInspectionSchedulePayload) => {
            overrideAnswer(
                answerId,
                answer,
                answer !== undefined ? comment : undefined,
            );
        },
        [comment, overrideAnswer],
    );

    const showComment = useMemo(() => {
        for (const answer of answers) {
            const override = overriddenAnswers[answer.id];

            if (override !== undefined) {
                if (
                    answer.type === "InspectionSchedule" &&
                    (answer.payload.Observation || answer.payload.Comment)
                ) {
                    return false;
                }

                return true;
            }
        }

        return false;
    }, [answers, overriddenAnswers]);

    return (
        <>
            <div className={styles.flex}>
                {answers.map((answer) => (
                    <OverrideAnswer
                        key={answer.id}
                        answer={answer}
                        documentType={documentType}
                        value={overriddenAnswers[answer.id]?.answer}
                        overrideAnswer={handleOverrideAnswer}
                    />
                ))}
            </div>

            <ValidationError
                isValid={errorMessage === null}
                useMargin={false}
                error={errorMessage}
            />

            {showComment && (
                <div className={styles.comment}>
                    <InputField
                        placeholder={t("Comment")}
                        value={comment}
                        onChange={handleCommentChange}
                        useMargin={false}
                    />
                </div>
            )}
        </>
    );
};

export default OverrideAnswers;
