import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IAssetProps } from ".";
import {
    Card,
    EditableDetailsTable,
    Grid,
    GridColumn,
    ImageHero,
    Loading,
} from "..";
import { useHasUserParentPermission, useToggle } from "../../hooks";
import ApplianceImages from "../../pages/Appliance/ApplianceImages";
import { useCircuit } from "../../utils/api/assets";
import { useIssuesForModel } from "../../utils/api/issues";
import AssetJobTimeline from "../AssetJobTimeline";
import AssetsAtProperty from "../AssetsAtProperty";
import { IEditableDetailsData } from "../EditableDetailsTable";
import IssuesLog from "../IssuesLog";
import ObservationsLog from "../ObservationsLog";

const Asset = ({ id, displayName, getColumns }: IAssetProps) => {
    const { t } = useTranslation();

    const { value, loaded } = useCircuit(id);

    const issues = useIssuesForModel({
        id,
        modelType: "asset",
    });

    const canEditAppliances = useHasUserParentPermission(
        "edit_appliances",
        value?.property.landlord.userId,
    );

    const { visible, toggle } = useToggle();

    const detailTableColumns = useMemo<IEditableDetailsData[]>(
        () => [...getColumns(visible)],
        [visible, getColumns],
    );

    return (
        <>
            {loaded && issues.loaded ? (
                <Grid>
                    <GridColumn size="twoThirds">
                        <Grid>
                            <GridColumn size="half">
                                <ImageHero
                                    imageUrl={value.imageUrl ?? ""}
                                    title={`${value.type.displayName}: ${value.description}`}
                                />
                            </GridColumn>
                            <GridColumn size="half">
                                <EditableDetailsTable
                                    canEdit={false}
                                    isEditing={canEditAppliances && visible}
                                    toggle={toggle}
                                    entity={value}
                                    title={`${displayName} ${t("details")}`}
                                    data={detailTableColumns}
                                />
                            </GridColumn>
                        </Grid>
                        <AssetJobTimeline
                            assetId={id}
                            displayName={displayName}
                        />
                    </GridColumn>

                    <GridColumn size="oneThird">
                        <ApplianceImages images={value.images} />
                        <AssetsAtProperty
                            assetId={id}
                            propertyId={value.property.id}
                        />
                        <IssuesLog issues={issues.records} />

                        <Card title={t("Observations")}>
                            <ObservationsLog id={value.id} modelType="asset" />
                        </Card>
                    </GridColumn>
                </Grid>
            ) : (
                <Loading />
            )}
        </>
    );
};

export default Asset;
