import { useEffect } from "react";
import { IFailReason } from ".";
import config from "../../../config";
import { useXTagApiRequest } from "../../request";
import useApiValue from "../useApiValue";

const useLgsrsFailReasons = (documentTypeId: number = 1) => {
    const { send, value, loaded } = useApiValue<IFailReason[]>({});
    const sendRequest = useXTagApiRequest();

    useEffect(() => {
        const subscription = send(
            sendRequest({
                url: `${config.lgsrsApiUrl}/failreasons/${documentTypeId}`,
                method: "GET",
            }),
        ).subscribe();

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [documentTypeId, send, sendRequest]);

    return { send, value, loaded };
};

export default useLgsrsFailReasons;
