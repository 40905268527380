import { ReactNode } from "react";
import Modal from "./Modal";

export interface IModalProps {
    title?: ReactNode;
    children: ReactNode;
    fullWidth?: boolean;
    fullHeight?: boolean;
    hide: () => void;
}

export default Modal;
