import { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IQualifiedSupervisorAuditsSaveModalProps, IQuestion } from ".";
import {
    Alert,
    Button,
    Form,
    Loading,
    Modal,
    Select,
    UserContext,
} from "../../../components";
import ModalBody from "../../../components/Modal/ModalBody";
import ModalFooter from "../../../components/Modal/ModalFooter";
import { ISelectOption } from "../../../components/Select";
import useDisplayAnswerPayload from "../../../hooks/useDisplayAnswerPayload";
import { IAnswer } from "../../../utils/api/answers";
import {
    IPaperworkSignatureModel,
    useCompleteAudit,
} from "../../../utils/api/audits";
import { useObservations } from "../../../utils/api/observations";
import { toDateString } from "../../../utils/dates";
import {
    IValidateField,
    isRequired,
    useValidateField,
    validateForm,
} from "../../../utils/validation";
import Table from "../Table";
import styles from "./QualifiedSupervisorAuditsSaveModal.module.scss";

const QualifiedSupervisorAuditsSaveModal = ({
    audit,
    overriddenAnswers,
    answersSections,
    onSaveAudit,
    hide,
    canCompleteAudit = true,
}: IQualifiedSupervisorAuditsSaveModalProps) => {
    const { t } = useTranslation();

    const {
        completeAudit,
        loading: isCompleteAuditLoading,
        error,
    } = useCompleteAudit(audit.id);

    const {
        loading: areObservationsLoading,
        records: openObservationsAtProperty,
    } = useObservations({
        propertyId: audit.job.property.id,
        status: "Unresolved",
        dateRaised: audit.job.date,
    });

    const [designer, setDesigner] = useState("");
    const [constructor, setConstructor] = useState("");

    const displayEicFields = useMemo(
        () =>
            canCompleteAudit &&
            (audit.documentType === "EIC" ||
                audit.childAudits.some((c) => c.documentType === "EIC")),
        [canCompleteAudit, audit],
    );
    const { user } = useContext(UserContext);

    const handleSubmit = useCallback(() => {
        if (canCompleteAudit) {
            const signatures: IPaperworkSignatureModel[] = [];

            if (audit.documentType === "EIC") {
                signatures.push(
                    {
                        auditId: audit.id,
                        key: "DesignerSignature",
                        name:
                            designer === "engineer"
                                ? audit.job.engineer.name
                                : user.name,
                        signatureUrl:
                            designer === "engineer"
                                ? audit.job.engineer.signatureUrl
                                : user.signatureUrl,
                    },
                    {
                        auditId: audit.id,
                        key: "ConstructorSignature",
                        name:
                            constructor === "engineer"
                                ? audit.job.engineer.name
                                : user.name,
                        signatureUrl:
                            constructor === "engineer"
                                ? audit.job.engineer.signatureUrl
                                : user.signatureUrl,
                    },
                );
            }

            for (const child of audit.childAudits) {
                if (child.documentType === "EIC") {
                    signatures.push(
                        {
                            auditId: child.id,
                            key: "DesignerSignature",
                            name:
                                designer === "engineer"
                                    ? child.engineer.name
                                    : user.name,
                            signatureUrl:
                                designer === "engineer"
                                    ? child.engineer.signatureUrl
                                    : user.signatureUrl,
                        },
                        {
                            auditId: child.id,
                            key: "ConstructorSignature",
                            name:
                                constructor === "engineer"
                                    ? child.engineer.name
                                    : user.name,
                            signatureUrl:
                                constructor === "engineer"
                                    ? child.engineer.signatureUrl
                                    : user.signatureUrl,
                        },
                    );
                }
            }

            completeAudit({
                signatures,
            }).subscribe(() => onSaveAudit());
        } else {
            onSaveAudit();
        }
    }, [
        canCompleteAudit,
        audit,
        completeAudit,
        designer,
        user,
        constructor,
        onSaveAudit,
    ]);

    const designerValidation = useValidateField(designer, isRequired());
    const constructorValidation = useValidateField(constructor, isRequired());

    const formValidation = validateForm(() => {
        const validators: IValidateField[] = [];

        if (displayEicFields) {
            validators.push(designerValidation, constructorValidation);
        }

        return validators;
    });

    const displayAnswerPayload = useDisplayAnswerPayload();

    const isSatisfactoryWithUnresolvedObservations = useMemo(() => {
        let overallAssessment = "";
        let generalCondition = "";
        const jobObservations: IAnswer[] = [];

        for (const section of answersSections) {
            for (const subSection of section.children) {
                let observationAnswer: IAnswer | undefined;
                let isResolved = false;

                for (const question of subSection.children) {
                    for (const answer of question.children) {
                        if (
                            answer.answer.questionName ===
                            "E_ResolvedIssueResult"
                        ) {
                            const override = overriddenAnswers.find(
                                (a) =>
                                    a.answerId === answer.answer.id &&
                                    !a.isDeleted,
                            );

                            if (override) {
                                isResolved =
                                    JSON.parse(override.payload) === "Yes";
                            } else if (
                                typeof answer.answer.payload === "string"
                            ) {
                                isResolved = answer.answer.payload === "Yes";
                            }
                        } else if (
                            answer.answer.questionName === "E_ObservationCode"
                        ) {
                            const override = overriddenAnswers.find(
                                (a) => a.answerId === answer.answer.id,
                            );

                            if (override) {
                                if (!override.isDeleted) {
                                    observationAnswer = {
                                        ...answer.answer,
                                        payload: JSON.parse(override.payload),
                                    };
                                }
                            } else {
                                observationAnswer = answer.answer;
                            }
                        } else if (
                            answer.answer.questionName.endsWith(
                                "OverallAssessmentResult",
                            )
                        ) {
                            const override = overriddenAnswers.find(
                                (a) =>
                                    a.answerId === answer.answer.id &&
                                    !a.isDeleted,
                            );

                            if (override) {
                                overallAssessment = JSON.parse(
                                    override.payload,
                                );
                            } else if (
                                typeof answer.answer.payload === "string"
                            ) {
                                overallAssessment = answer.answer.payload;
                            }
                        } else if (
                            answer.answer.questionName.endsWith(
                                "GeneralConditionResult",
                            )
                        ) {
                            const override = overriddenAnswers.find(
                                (a) =>
                                    a.answerId === answer.answer.id &&
                                    !a.isDeleted,
                            );

                            if (override) {
                                generalCondition = JSON.parse(
                                    override.payload,
                                ).toString();
                            } else if (
                                typeof answer.answer.payload === "string"
                            ) {
                                generalCondition =
                                    answer.answer.payload.toString();
                            }
                        }
                    }
                }

                if (observationAnswer !== undefined && !isResolved) {
                    jobObservations.push(observationAnswer);
                }
            }
        }

        if (
            overallAssessment === "Satisfactory" ||
            generalCondition === "The installation does comply with BS7671"
        ) {
            const observationCodes = ["C1", "C2", "FI"];
            return (
                openObservationsAtProperty.some((o) =>
                    observationCodes.includes(o.type.displayName),
                ) ||
                audit.auditObservations.some((o) =>
                    observationCodes.includes(o.type.displayName),
                ) ||
                jobObservations.some((o) =>
                    observationCodes.includes(o.payload as string),
                )
            );
        }

        return false;
    }, [
        answersSections,
        audit.auditObservations,
        openObservationsAtProperty,
        overriddenAnswers,
    ]);

    const answers = useMemo(() => {
        const list: IQuestion[] = [];
        const overrides: { [answerId: number]: string } = {};

        for (const answer of overriddenAnswers) {
            overrides[answer.answerId] = answer.payload;
        }

        for (const section of answersSections) {
            for (const subSection of section.children) {
                for (const question of subSection.children) {
                    const tempList: {
                        answer: IAnswer;
                        originalAnswer: string;
                        overriddenAnswer: string;
                    }[] = [];

                    for (const answer of question.children) {
                        const payload = overrides[answer.answer.id];

                        if (payload) {
                            let originalAnswer: string = answer.answer.payload;

                            switch (answer.answer.type) {
                                case "DatePicker": {
                                    if (answer.answer.payload !== null) {
                                        const date = new Date(
                                            answer.answer.payload,
                                        );
                                        if (
                                            date.toString() !== "Invalid Date"
                                        ) {
                                            originalAnswer = toDateString(date);
                                        }
                                    }

                                    break;
                                }
                                case "InspectionSchedule":
                                case "ToggleButtonGroup": {
                                    if (
                                        typeof answer.answer.payload ===
                                        "object"
                                    ) {
                                        originalAnswer =
                                            answer.answer.payload.Text;
                                    }

                                    break;
                                }
                                default: {
                                    break;
                                }
                            }

                            tempList.push({
                                answer: answer.answer,
                                originalAnswer: originalAnswer,
                                overriddenAnswer: payload,
                            });
                        }
                    }

                    if (tempList.length > 0) {
                        list.push({
                            id: `${section.id}-${subSection.id}-${question.id}`,
                            section: section.title,
                            question: question.title,
                            originalAnswer: tempList
                                .map((l) => l.originalAnswer)
                                .join(" "),
                            overriddenAnswer: tempList
                                .map((l) =>
                                    displayAnswerPayload(
                                        l.overriddenAnswer,
                                        l.answer,
                                    ),
                                )
                                .join(" "),
                        });
                    }
                }
            }
        }

        return list;
    }, [answersSections, displayAnswerPayload, overriddenAnswers]);

    const designerConstructorOptions = useMemo<ISelectOption[]>(
        () => [
            { value: "engineer", label: t("Engineer") },
            { value: "qs", label: t("QS") },
        ],
        [t],
    );

    return (
        <Form onSubmit={handleSubmit} {...formValidation}>
            <Modal title={t("QS modifications summary")} hide={hide}>
                <ModalBody withPadding={false}>
                    {displayEicFields && (
                        <div className={styles.container}>
                            <Select
                                label={t("Who is the designer")}
                                value={designer}
                                onChange={setDesigner}
                                options={designerConstructorOptions}
                                allowEmpty={true}
                                canDeselect={true}
                                {...designerValidation}
                            />

                            <Select
                                label={t("Who is the constructor")}
                                value={constructor}
                                onChange={setConstructor}
                                options={designerConstructorOptions}
                                allowEmpty={true}
                                canDeselect={true}
                                {...constructorValidation}
                            />
                        </div>
                    )}

                    {answers.length > 0 ? (
                        <Table>
                            <thead>
                                <tr>
                                    <th>{t("Section")}</th>
                                    <th>{t("Question")}</th>
                                    <th>{t("Original data")}</th>
                                    <th>{t("QS modification")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {answers.map((answer) => (
                                    <tr key={answer.id}>
                                        <td>{answer.section}</td>
                                        <td>{answer.question}</td>
                                        <td>{answer.originalAnswer}</td>
                                        <td>{answer.overriddenAnswer}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    ) : (
                        <div className={styles.container}>
                            {t("There are no modifications.")}
                        </div>
                    )}

                    <div className={styles.container}>
                        {error && (
                            <div className={styles.error}>
                                <Alert type="error">{error}</Alert>
                            </div>
                        )}

                        {!areObservationsLoading &&
                            isSatisfactoryWithUnresolvedObservations && (
                                <div className={styles.error}>
                                    <Alert type="error">
                                        {t(
                                            "There are unresolved observations at the property. Either change the certificate status to Unsatisfactory or downgrade the observation severities",
                                        )}
                                    </Alert>
                                </div>
                            )}

                        {(isCompleteAuditLoading || areObservationsLoading) && (
                            <Loading />
                        )}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button
                        variant="primary"
                        type="submit"
                        disabled={
                            isSatisfactoryWithUnresolvedObservations ||
                            isCompleteAuditLoading ||
                            areObservationsLoading
                        }
                    >
                        {canCompleteAudit
                            ? audit.documentType !== null
                                ? t("Create {{documentType}}", {
                                      documentType: audit.documentType,
                                  })
                                : t("Create documents")
                            : t("Save")}
                    </Button>
                    &nbsp;
                    <Button onClick={hide}>{t("Return to edit")}</Button>
                </ModalFooter>
            </Modal>
        </Form>
    );
};

export default QualifiedSupervisorAuditsSaveModal;
