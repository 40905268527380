import { useCallback, useContext } from "react";
import { IAuditJobComment } from ".";
import { PropertyCategoriesContext, UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

export const useDeleteJobComment = (auditId: number) => {
    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);
    const { send, loading } = useApiRequest<IAuditJobComment>({});
    const sendRequest = useXTagApiRequest();

    const deleteJobComment = useCallback(
        (commentId: string) =>
            send(
                sendRequest({
                    url: `${config.auditsApiUrl}/${auditId}/job-comment/${commentId}`,
                    method: "DELETE",

                    urlParams: getGlobalUrlParameters(
                        selectedCategories.map((c) => c.id),
                        activeUserParentsIds,
                    ),
                }),
            ),
        [send, sendRequest, auditId, selectedCategories, activeUserParentsIds],
    );

    return { deleteJobComment, loading };
};
