import {
    CSSProperties,
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useRef,
} from "react";
import { useTranslation } from "react-i18next";
import { ISelectOption } from ".";
import { Icon, Popover, ValidationError } from "..";
import { useCssClasses, useToggle } from "../../hooks";
import useAccessibleCustomSelect from "../../hooks/useAccessibleCustomSelect";
import useClickableNonInteractiveElement from "../../hooks/useClickableNonInteractiveElement";
import { useHtmlEntityId } from "../../hooks/useHtmlEntityId";
import { InputSize } from "../InputField";
import SelectItem from "../SelectItem";
import styles from "./Select.module.scss";

const Select = ({
    options,
    label = "",
    value,
    allowEmpty = false,
    applyPadding = true,
    useMargin = true,
    canDeselect = false,
    closeOnSelect = true,
    onChange,
    isValid,
    error,
    cssRules,
    customRender,
    placeholder = "",
    size = "",
    testId,
}: ISelectProps) => {
    const { t } = useTranslation();
    const { hide, toggle, visible } = useToggle();

    const id = useHtmlEntityId();

    const selectElement = useRef<HTMLDivElement>(null);

    const handleOptionClicked = (selectedValue: string) => {
        if (closeOnSelect) {
            hide();
        }
        onChange(selectedValue);
    };

    useEffect(() => {
        if (!allowEmpty && !value) {
            handleOptionClicked(options[0].value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const needsDeselect = useMemo(
        () => canDeselect && !!value,
        [canDeselect, value],
    );

    const handleSelectClick = useCallback(() => {
        if (!needsDeselect) {
            toggle();
        }
    }, [needsDeselect, toggle]);

    const handleDeselectClick = useCallback(() => {
        onChange("");
        hide();
    }, [hide, onChange]);

    const { onKeyDown: onDeselectKeyDown } =
        useClickableNonInteractiveElement(handleDeselectClick);

    const selectedOption = useMemo(
        () => options.filter((option) => value === option.value),
        [value, options],
    );

    const cssClasses = useCssClasses(
        styles.select,
        needsDeselect ? "" : styles.clickable,
        size === "small" ? styles.small : "",
    );

    const { onKeyDown: onSelectKeyDown, focusedIndex } =
        useAccessibleCustomSelect(
            false,
            value,
            options,
            visible,
            handleSelectClick,
            hide,
            handleOptionClicked,
            closeOnSelect,
        );

    // TODO: Popover's min width should be the same as the select element's width.
    return (
        <div className={useMargin ? styles.margin : undefined} style={cssRules}>
            {label && (
                <label htmlFor={id} className={styles.label}>
                    {label}
                </label>
            )}

            <div className={styles.border}>
                <div
                    id={id}
                    className={cssClasses}
                    onClick={handleSelectClick}
                    onKeyDown={onSelectKeyDown}
                    role="listbox"
                    tabIndex={0}
                    ref={selectElement}
                    data-test-id={testId}
                >
                    {selectedOption.length > 0 ? (
                        selectedOption.map((option) => (
                            <div className={styles.option} key={option.value}>
                                {customRender
                                    ? customRender(value)
                                    : option.label}
                            </div>
                        ))
                    ) : (
                        <div>{placeholder}</div>
                    )}
                </div>
                {needsDeselect ? (
                    <div
                        className={`${styles.icon} ${styles.clickable}`}
                        onClick={handleDeselectClick}
                        onKeyDown={onDeselectKeyDown}
                        role="button"
                        tabIndex={0}
                    >
                        <Icon
                            icon="close"
                            color="blue"
                            ariaLabel={t("Clear selection")}
                        />
                    </div>
                ) : (
                    <div className={`${styles.icon} ${styles.noPointerEvents}`}>
                        <Icon
                            icon="chevron-down"
                            color="blue"
                            ariaLabel={t("View select options")}
                        />
                    </div>
                )}
            </div>
            <ValidationError isValid={isValid} error={error} />

            {visible && (
                <Popover
                    anchorElement={selectElement}
                    placement="bottom-start"
                    hide={hide}
                    enableFocusTrap={false}
                >
                    {options.map((option, index) => (
                        <SelectItem
                            key={option.value}
                            value={option.value}
                            isSelected={focusedIndex === index}
                            applyPadding={applyPadding}
                            onClick={handleOptionClicked}
                        >
                            {option.label}
                        </SelectItem>
                    ))}
                </Popover>
            )}
        </div>
    );
};

interface ISelectProps {
    options: ISelectOption[];
    label?: string;
    value: string;
    useMargin?: boolean;
    allowEmpty?: boolean;
    applyPadding?: boolean;
    canDeselect?: boolean;
    isValid?: boolean;
    error?: string;
    cssRules?: CSSProperties;
    onChange: (value: string) => void;
    customRender?: (value: string) => ReactNode;
    placeholder?: string;
    testId?: string;
    size?: InputSize;
    closeOnSelect?: boolean;
}

export default Select;
