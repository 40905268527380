import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const useAssignContractors = (propertyIds: number[]) => {
    const { t } = useTranslation();
    const { activeUserParentsIds } = useContext(UserContext);
    const { send, loading, error } = useApiRequest({
        errorMessage: t("Unexpected error occured. Please try again later."),
    });
    const sendRequest = useXTagApiRequest();

    const assignContractors = useCallback(
        (list: IPropertyCategoryContractor[]) =>
            send(
                sendRequest({
                    url: `${config.propertiesApiUrl}/categories`,
                    method: "PUT",
                    urlParams: getGlobalUrlParameters([], activeUserParentsIds),
                    body: {
                        propertyIds,
                        list,
                    },
                }),
            ),
        [send, sendRequest, activeUserParentsIds, propertyIds],
    );

    return { assignContractors, loading, error };
};

interface IPropertyCategoryContractor {
    contractorId: number;
    propertyCategoryId: number;
}

export default useAssignContractors;
