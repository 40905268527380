import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { ICompleteAudit } from ".";
import { PropertyCategoriesContext, UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const useCompleteAudit = (id: number) => {
    const { t } = useTranslation();
    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);
    const { send, loading, error } = useApiRequest({
        errorMessage: t("Failed to complete audit."),
    });
    const sendRequest = useXTagApiRequest();

    const completeAudit = useCallback(
        (details: ICompleteAudit) =>
            send(
                sendRequest({
                    url: `${config.auditsApiUrl}/${id}/complete`,
                    method: "POST",
                    urlParams: getGlobalUrlParameters(
                        selectedCategories.map((c) => c.id),
                        activeUserParentsIds,
                    ),
                    body: details,
                }),
            ),
        [send, sendRequest, id, selectedCategories, activeUserParentsIds],
    );

    return { completeAudit, loading, error };
};

export default useCompleteAudit;
