import Carousel from "./Carousel";

export interface ICarouselImage {
    image: string;
    navigateTo: string;
    title: string;
    analytics?: {
        title: string;
        description: string;
        type: string;
    };
}

export interface ICarouselProps {
    images: ICarouselImage[];
}

export default Carousel;
