import { ISelectOption } from "../../../../components/Select";
import EditComplianceRow from "./EditComplianceRow";

export interface IEditComplianceRowProps {
    complianceTypes: ISelectOption[];
    contractors: ISelectOption[];
    contractorId: string;
    complianceTypeId: string;
    setContractorId: (complianceTypeId: string, contractorId: string) => void;
    setComplianceTypeId: (
        oldComplianceTypeId: string,
        newComplianceTypeId: string,
    ) => void;
    removeComplianceType: (complianceTypeId: string) => void;
}

export default EditComplianceRow;
