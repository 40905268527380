import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IPropertyDetailsProps } from ".";
import { Button, Card, EditablePill, Icon } from "../../../components";
import AuthorizedImage from "../../../components/AuthorizedImage";
import { useHasUserParentPermission, useToggle } from "../../../hooks";
import { usePropertyMapFeature } from "../../../hooks/usePropertyMapFeature";
import propertyImage from "../../../images/map/property.svg";
import { getNow, toDateString } from "../../../utils/dates";
import EditableMap from "../EditableMap";
import PropertyData from "../PropertyData";
import PropertyDataRow from "../PropertyDataRow";
import PropertyDetailsEditModal from "../PropertyDetailsEditModal";
import styles from "./PropertyDetails.module.scss";

const PropertyDetails = ({
    property,
    updateProperty,
}: IPropertyDetailsProps) => {
    const { t } = useTranslation();
    const canEditProperties = useHasUserParentPermission(
        "edit_properties",
        property.landlord.userId,
    );

    // TODO: Create an env variable and get this feature flag from there XT-1142.
    const mapFeature = true;
    const { marker, handleCoordinatesUpdate, loading } =
        usePropertyMapFeature(property);

    const { visible, show, hide } = useToggle();

    const isGasProperty = useMemo(
        () => property.categories.some((c) => c.id === 1),
        [property.categories],
    );

    const isFireDoorProperty = useMemo(
        () => property.categories.some((c) => c.id === 13),
        [property.categories],
    );

    return (
        <Card fullHeight={true} padding={false}>
            <div className={styles.container}>
                <div className={styles.mapWrapper}>
                    {mapFeature && (
                        <EditableMap
                            marker={marker}
                            icon={propertyImage}
                            onMarkerUpdate={handleCoordinatesUpdate}
                            loading={loading}
                            userParentId={property.landlord.userId}
                        />
                    )}

                    <div className={styles.propertyImage}>
                        <AuthorizedImage image={property.imageUrl} />
                    </div>
                </div>

                <div className={styles.propertyDetails}>
                    <h1 className={styles.title}>
                        {property.addressString}

                        {canEditProperties && (
                            <div className={styles.editButton}>
                                <Button size="small" onClick={show}>
                                    <Icon
                                        icon="pencil"
                                        ariaLabel={t("Edit property details")}
                                    />
                                </Button>
                            </div>
                        )}
                    </h1>

                    <PropertyDataRow>
                        <PropertyData label={t("Landlord")}>
                            {property.landlord.name}
                        </PropertyData>

                        <PropertyData label={t("Due Date")}>
                            {property.nextServiceDueDate &&
                                toDateString(
                                    new Date(property.nextServiceDueDate),
                                )}
                        </PropertyData>
                    </PropertyDataRow>

                    {property.letterState && (
                        <PropertyDataRow>
                            <PropertyData label={t("Letter Status")}>
                                {property.letterState.status}
                            </PropertyData>

                            <PropertyData label={t("Status Overdue")}>
                                {getNow() >
                                new Date(property.letterState.expiryDate)
                                    ? t("Yes")
                                    : t("No")}
                            </PropertyData>
                        </PropertyDataRow>
                    )}

                    <PropertyDataRow>
                        <PropertyData label={t("UPRN")}>
                            {property.uprn}
                        </PropertyData>

                        <PropertyData label={t("Property Type")}>
                            {property.propertyType}
                        </PropertyData>
                    </PropertyDataRow>

                    <PropertyDataRow>
                        <PropertyData label={t("Void")}>
                            {property.voidDate
                                ? toDateString(new Date(property.voidDate))
                                : t("No")}
                        </PropertyData>

                        <PropertyData label={t("Capped")}>
                            {property.isCapped ? t("Yes") : t("No")}
                        </PropertyData>
                    </PropertyDataRow>

                    {isGasProperty && (
                        <PropertyDataRow>
                            <PropertyData label={t("Last Maintenance Date")}>
                                {property.lastMaintenanceDate &&
                                    toDateString(
                                        new Date(property.lastMaintenanceDate),
                                    )}
                            </PropertyData>
                        </PropertyDataRow>
                    )}

                    {isFireDoorProperty && (
                        <PropertyDataRow>
                            <PropertyData label={t("Next Maintenance Date")}>
                                {property.nextMaintenanceDate &&
                                    toDateString(
                                        new Date(property.nextMaintenanceDate),
                                    )}
                            </PropertyData>
                        </PropertyDataRow>
                    )}

                    <PropertyDataRow>
                        <PropertyData label={t("First Tag Installed Date")}>
                            {property.tag
                                ? toDateString(
                                      new Date(property.tag.installedDate),
                                  )
                                : t("No tag installed")}
                        </PropertyData>

                        <PropertyData label={t("Number of Tags")}>
                            {property.tags?.length ?? 0}
                        </PropertyData>
                    </PropertyDataRow>

                    <PropertyDataRow>
                        <PropertyData label={t("Gas Meter Location")}>
                            {property.gasMeterLocation}
                        </PropertyData>

                        <PropertyData label={t("Flags")}>
                            {property.flags.map((flag) => (
                                <EditablePill
                                    key={flag.id}
                                    name={flag.name}
                                    colour={flag.colour}
                                    title={flag.name}
                                />
                            ))}
                        </PropertyData>
                    </PropertyDataRow>
                </div>
            </div>

            {visible && (
                <PropertyDetailsEditModal
                    property={property}
                    updateProperty={updateProperty}
                    hide={hide}
                />
            )}
        </Card>
    );
};

export default PropertyDetails;
