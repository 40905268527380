import { useCallback, useContext } from "react";
import { IAuditJobComment, IAuditJobCommentCreate } from ".";
import { PropertyCategoriesContext, UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

export const useCreateJobComment = (auditId: number) => {
    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);
    const { send, loading } = useApiRequest<IAuditJobComment>({});
    const sendRequest = useXTagApiRequest();

    const createJobComment = useCallback(
        (comment: IAuditJobCommentCreate) =>
            send(
                sendRequest({
                    url: `${config.auditsApiUrl}/${auditId}/job-comment`,
                    method: "POST",
                    urlParams: getGlobalUrlParameters(
                        selectedCategories.map((c) => c.id),
                        activeUserParentsIds,
                    ),
                    body: comment,
                }),
            ),
        [send, sendRequest, auditId, selectedCategories, activeUserParentsIds],
    );

    return { createJobComment, loading };
};
