import { sortBy } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
    AssignUser,
    Button,
    Card,
    Grid,
    GridColumn,
    Img,
    Link,
    Loading,
    Modal,
    Portal,
} from "../../components";
import Icon from "../../components/Icon/Icon";
import MessageLog, { IMessage } from "../../components/MessageLog";
import ModalBody from "../../components/Modal/ModalBody";
import ModalFooter from "../../components/Modal/ModalFooter";
import ObservationsLog from "../../components/ObservationsLog";
import SimpleTable, { IColumn } from "../../components/SimpleTable";
import StatusSelector from "../../components/StatusSelector";
import { useHasUserParentPermission, useToggle } from "../../hooks";
import useDownloadPaperwork from "../../hooks/useDownloadPaperwork";
import {
    IFuelType,
    IIssue,
    IIssueJob,
    IIssueUser,
    IMention,
    useAssignIssue,
    useIssue,
    useIssueComments,
    useIssueSubscription,
    useSubmitIssueComment,
    useSubscribeToIssue,
    useUpdateIssueStatus,
} from "../../utils/api/issues";
import useUnsubscribeFromIssue from "../../utils/api/issues/useUnsubscribeFromIssue";
import { useFileUrl } from "../../utils/api/misc";
import useAllUsers from "../../utils/api/users/useAllUsers";
import {
    getDisplayDifference,
    getNow,
    toDateTimeString,
} from "../../utils/dates";
import PageNotFound from "../PageNotFound";
import styles from "./Issue.module.scss";
import IssueJobApplianceTimeline from "./IssueJobApplianceTimeline";

const Issue = () => {
    const { download } = useDownloadPaperwork();
    const { id } = useParams();
    const issue = useIssue(id ?? "");
    const issueUsers = useAllUsers();
    const issueComments = useIssueComments(id ?? "");
    const submitIssueComment = useSubmitIssueComment();
    const assignIssue = useAssignIssue();
    const updateIssueStatus = useUpdateIssueStatus();
    const subscribeToIssue = useSubscribeToIssue();
    const unsubscribeFromIssue = useUnsubscribeFromIssue();
    const [imageUrl, setImageUrl] = useState("");
    const canViewElecTag = useHasUserParentPermission(
        "electag",
        issue.value?.landlord.userId,
    );

    const [subscribedToIssue, setSubscribedToIssue] = useState(false);
    const issueSubscription = useIssueSubscription(id ?? "");

    useEffect(() => {
        setSubscribedToIssue(issueSubscription.value.isSubscribed);
    }, [issueSubscription.value.isSubscribed]);

    const getFileUrl = useFileUrl();
    const issueDescription = useMemo(() => {
        if (issue.value) {
            try {
                const parsedIssue = JSON.parse(issue.value.description);
                if (parsedIssue.datatype !== "PhotoButton") {
                    return parsedIssue.data;
                } else {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                    getFileUrl(parsedIssue.data.Key).subscribe((url) => {
                        setImageUrl(url);
                    });
                }
            } catch {
                return issue.value.description;
            }
        } else {
            return "";
        }
    }, [getFileUrl, issue.value]);

    const [comments, setComments] = useState<IMessage[]>([]);
    const [assignedUserId, setAssignedUserId] = useState("");

    const { t } = useTranslation();

    const {
        show: assignModalShow,
        hide: assignModalHide,
        visible: assignModalVisible,
    } = useToggle();
    const {
        show: viewedByModalShow,
        hide: viewedByModalHide,
        visible: viewedByModalVisible,
    } = useToggle();

    const handleAssignUser = () => {
        assignIssue
            .assignIssue(Number(assignedUserId), [Number(id ?? "")])
            .subscribe();
        assignModalHide();
    };

    const handleCreateComment = (comment: string, mentions: IMention[]) => {
        submitIssueComment
            .submitIssueComment(id ?? "", {
                comment,
                mentions,
            })
            .subscribe();
    };

    const handleStatusChange = (newStatus: string, comment: string) => {
        updateIssueStatus
            .updateIssueStatus(id ?? "", newStatus, comment)
            .subscribe();
    };

    const handleSubscription = () => {
        if (subscribedToIssue) {
            unsubscribeFromIssue
                .unsubscribeFromIssue(id ?? "")
                .subscribe(() => setSubscribedToIssue(false));
        } else {
            subscribeToIssue
                .subscribeToIssue(id ?? "")
                .subscribe(() => setSubscribedToIssue(true));
        }
    };

    const statuses = useMemo(() => {
        return ["Resolved", "Unresolved", "Blocked"];
    }, []);

    const downloadDocument = useCallback(
        (documentId: number, fileLink: string) => {
            download(documentId, fileLink).subscribe();
        },
        [download],
    );

    const statusTableData = useMemo<IColumn[]>(
        () => [
            {
                title: t("Date Raised"),
                type: "date",
                path: "createdAt",
            },
            {
                title: t("Date Resolved"),
                path: "dateResolved",
                type: "component",
                render: (value: string | null) =>
                    value ? toDateTimeString(new Date(value)) : t("Unresolved"),
            },
            {
                title: t("Resolved By"),
                path: "resolvedUser",
                type: "component",
                render: (value: IIssueUser | null) =>
                    value ? value.name : t("Unresolved"),
            },
            {
                title: t("Resolution Time"),
                path: "dateResolved",
                type: "component",
                render: (value: string | null, row: IIssue) => {
                    const resolvedDate = value
                        ? getDisplayDifference(new Date(value), getNow())
                        : getDisplayDifference(
                              new Date(row.createdAt),
                              getNow(),
                          );
                    return t(resolvedDate.key, {
                        count: resolvedDate.count,
                    });
                },
            },
        ],
        [t],
    );

    const detailsTableData = useMemo<IColumn[]>(() => {
        const insertIf = (
            condition: boolean | string | undefined,
            ...elements: IColumn[]
        ) => {
            return condition ? elements : [];
        };

        const renderLgsrDownload = (job: IIssueJob | null) =>
            job?.lgsrUrl && (
                <Button
                    onClick={downloadDocument}
                    clickParams={[job.paperworkId, job.lgsrUrl]}
                >
                    {t("Download LGSR")}
                </Button>
            );

        const renderEicrDownload = (job: IIssueJob | null) =>
            job?.eicr?.fileLink && (
                <Button
                    onClick={downloadDocument}
                    clickParams={[job.eicr.id, job.eicr.fileLink]}
                >
                    {t("Download EICR")}
                </Button>
            );

        const renderJob = (value: IIssueJob | null) => {
            return (
                value && (
                    <Link url={`/jobs/jobs/${value.id}`}>{`#${value.id}`}</Link>
                )
            );
        };

        const renderAddressString = (value: string, row: IIssue) => (
            <Link url={`/management/properties/${row.property.id}`}>
                {value}
            </Link>
        );

        const renderAppliance = (value: string, row: IIssue) =>
            row.appliance && (
                <Link url={`/management/appliances/${row.appliance.id}`}>
                    {value}
                </Link>
            );

        const renderAssignedUser = (value: IIssueUser | null) => (
            <>
                {value ? value.name : t("Not assigned")}
                <Button
                    onClick={assignModalShow}
                    cssRules={{ marginLeft: "10px" }}
                >
                    {t("Change")}
                </Button>
            </>
        );

        return [
            {
                title: t("Jobs"),
                path: "job",
                type: "component",
                render: renderJob,
            },
            {
                title: t("Job Type"),
                path: "job",
                type: "component",
                render: (value: IIssueJob | null) =>
                    value ? t(value.jobType) : "---",
            },
            {
                title: t("Fuel Type"),
                path: "fuelType",
                type: "component",
                render: (value: IFuelType | null) =>
                    value ? value.displayName : "---",
            },

            // add eicr if it has a eicr and electag is selected
            ...insertIf(issue.value?.job?.eicr?.fileLink && canViewElecTag, {
                title: t("EICR"),
                type: "component",
                path: "job",
                render: renderEicrDownload,
            }),

            // add download lgsr if it has one
            ...insertIf(issue?.value?.job?.lgsrUrl, {
                title: t("LGSR"),
                type: "component",
                path: "job",
                render: renderLgsrDownload,
            }),

            {
                title: t("Property"),
                type: "component",
                render: renderAddressString,
                path: "property.addressString",
            },
            {
                title: t("Appliance"),
                type: "component",
                render: renderAppliance,
                path: "appliance.displayName",
            },
            {
                title: t("Assigned To"),
                type: "component",
                render: renderAssignedUser,
                path: "assignedUser",
            },
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [issue.value, canViewElecTag, t]);

    const issueViewersColumns = useMemo<IColumn[]>(
        () => [
            {
                title: t("Name"),
                type: "string",
                path: "user.name",
            },
            {
                title: t("Date Viewed"),
                type: "datetime",
                path: "updatedAt",
            },
        ],
        [t],
    );

    useEffect(() => {
        setComments(
            sortBy(issueComments.records, ["createdAt"]).map((comment) => {
                return {
                    imageUrl:
                        (comment.engineer && comment.engineer.imageUrl) ||
                        comment.user.imageUrl,
                    user:
                        (comment.engineer && comment.engineer.name) ||
                        comment.user.name,
                    text:
                        comment.status !== "Assigned User"
                            ? comment.comment
                            : "",
                    messageDateTime: new Date(comment.createdAt),
                    mentions: comment.mentions,
                    messageStatus:
                        comment.status &&
                        (comment.status === "Assigned User" ? (
                            <div className={styles.messageStatus}>
                                {t("Assigned User to")} {comment.comment}
                            </div>
                        ) : (
                            <div
                                className={`${
                                    styles.messageStatus
                                } ${comment.status.toLowerCase()}`}
                            >
                                {t("Marked Issue as")} {comment.status}
                            </div>
                        )),
                };
            }),
        );
    }, [issueComments.records, t]);

    // eslint-disable-next-line no-console
    console.log(issue.value, issueComments.loaded, issueUsers.loaded);

    return issue.value && issueComments.loaded && issueUsers.loaded ? (
        <>
            <Grid>
                <GridColumn size="half">
                    <Card title={`${t("Issue")} #${issue.value.id}`}>
                        <p className={styles.issueTitle}>{issue.value.title}</p>
                        {issueDescription}
                        {imageUrl && <Img url={imageUrl} size={300} />}
                    </Card>
                    <Card
                        title={t("Timeline")}
                        actions={
                            <>
                                <Button
                                    onClick={viewedByModalShow}
                                    cssRules={{ marginRight: "10px" }}
                                >
                                    <Icon
                                        icon="eye"
                                        size={20}
                                        ariaLabel={t(
                                            "Open issue viewers modal",
                                        )}
                                    />
                                </Button>

                                <Button onClick={handleSubscription}>
                                    {subscribedToIssue ? (
                                        <Icon
                                            icon="bell-filled"
                                            size={20}
                                            ariaLabel={t(
                                                "Unsubscribe from issue",
                                            )}
                                        />
                                    ) : (
                                        <Icon
                                            icon="bell"
                                            size={20}
                                            ariaLabel={t("Subscribe to issue")}
                                        />
                                    )}
                                </Button>
                            </>
                        }
                    >
                        <MessageLog
                            messages={comments}
                            onCreateComment={handleCreateComment}
                            loading={submitIssueComment.loading}
                        />
                    </Card>
                    {issue.value &&
                        issue.value.job &&
                        issue.value.appliance && (
                            <IssueJobApplianceTimeline
                                jobId={issue.value.job.id}
                                applianceId={issue.value.appliance.id}
                            />
                        )}
                </GridColumn>
                <GridColumn size="half">
                    <Card title={t("Status")}>
                        <StatusSelector
                            statuses={statuses}
                            currentStatus={issue.value.status}
                            onSave={handleStatusChange}
                            loading={updateIssueStatus.loading}
                        />
                        <SimpleTable
                            single={true}
                            columns={statusTableData}
                            data={issue.value}
                        />
                    </Card>
                    <Card title={t("Details")}>
                        <SimpleTable
                            single={true}
                            columns={detailsTableData}
                            data={issue.value}
                        />
                    </Card>
                    {id && canViewElecTag && (
                        <Card title={t("Observations")}>
                            <ObservationsLog id={id} modelType="issue" />
                        </Card>
                    )}
                </GridColumn>

                <Portal>
                    {assignModalVisible && (
                        <Modal title={t("Assign to...")} hide={assignModalHide}>
                            <ModalBody>
                                <AssignUser
                                    users={issueUsers.records}
                                    onChange={setAssignedUserId}
                                    value={assignedUserId}
                                />
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    variant="primary"
                                    onClick={handleAssignUser}
                                >
                                    {t("Assign user")}
                                </Button>
                            </ModalFooter>
                        </Modal>
                    )}

                    {viewedByModalVisible && (
                        <Modal
                            title={t("Issue Seen By")}
                            hide={viewedByModalHide}
                        >
                            <ModalBody>
                                {issue.value.viewers ? (
                                    <SimpleTable
                                        columns={issueViewersColumns}
                                        data={issue.value.viewers}
                                        withTimeline={true}
                                    />
                                ) : (
                                    t("Issue not viewed yet")
                                )}
                            </ModalBody>
                        </Modal>
                    )}
                </Portal>
            </Grid>
        </>
    ) : !issue.error ? (
        <Loading />
    ) : (
        <PageNotFound />
    );
};

export default Issue;
