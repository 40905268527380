const findScrollableAncestor = (current: HTMLElement | null): HTMLElement => {
    if (!current) {
        return document.body;
    }

    const style = getComputedStyle(current);
    const overflow =
        style.getPropertyValue("overflow-y") ||
        style.getPropertyValue("overflow");

    if (overflow === "auto" || overflow === "scroll") {
        return current;
    }

    return findScrollableAncestor(current.parentElement);
};

export { findScrollableAncestor };
