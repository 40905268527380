import { useCallback, useContext, useEffect } from "react";
import { filter, map } from "rxjs";
import { IIssue, ISimpleIssue } from ".";
import RealtimeContext from "../../../components/RealtimeProvider/RealtimeContext";
import config from "../../../config";
import { clearCache } from "../../cache";
import { realtimeCreateList, realtimeUpdateList } from "../../realtime";
import { IProperty } from "../properties";
import useFilterable, { IFilters } from "../useFilterable";

const useIssuesForModel = ({ id, modelType }: IUseIssuesForModel) => {
    const { realtimeObservable } = useContext(RealtimeContext);

    const buildFilter = useCallback((): IFilters => {
        return {
            [modelType + ".id"]: {
                operator: "{AND}",
                filters: [{ function: "=", value: id }],
            },
        };
    }, [id, modelType]);

    const { records, loaded, loading, refresh, updateValue } =
        useFilterable<ISimpleIssue>(config.issuesApiUrl, {
            params: {
                sortProperty: "createdAt",
                sortDirection: "desc",
                filters: buildFilter(),
            },
        });

    useEffect(() => refresh(buildFilter()), [buildFilter, refresh]);

    // TODO: A job created realtime event should map the job in the issue job.
    useEffect(() => {
        const subscription = realtimeObservable
            .pipe(
                filter((e) => e.entity === "property" && e.event === "updated"),
                map((e) => e.payload as IProperty),
            )
            .subscribe((value) => {
                const issues = [...records];

                for (const issue of issues) {
                    if (issue.property.id === value.id) {
                        issue.property = {
                            id: value.id,
                            addressString: value.addressString,
                            uprn: value.uprn,
                        };
                    }
                }

                updateValue(issues);
                clearCache();
            });

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [realtimeObservable, records, updateValue]);

    useEffect(() => {
        const subscription = realtimeObservable
            .pipe(
                filter((e) => e.entity === "issue" && e.event === "created"),
                map((e) => e.payload as IIssue),
                filter((e) => {
                    switch (modelType) {
                        case "asset": {
                            return (
                                (e.appliance && e.appliance.id === id) || false
                            );
                        }
                        case "appliance": {
                            return (
                                (e.appliance && e.appliance.id === id) || false
                            );
                        }
                        case "job": {
                            return (e.job && e.job.id === Number(id)) || false;
                        }
                        case "property": {
                            return e.property.id === Number(id);
                        }
                    }
                }),
            )
            .subscribe((value) => {
                const updatedList = realtimeCreateList(value, records);

                if (updatedList) {
                    updateValue(updatedList);
                }

                clearCache();
            });

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [id, modelType, realtimeObservable, records, updateValue]);

    useEffect(() => {
        const subscription = realtimeObservable
            .pipe(
                filter((e) => e.entity === "issue" && e.event === "updated"),
                map((e) => e.payload as IIssue),
                filter((e) => {
                    switch (modelType) {
                        case "asset": {
                            return (
                                (e.appliance && e.appliance.id === id) || false
                            );
                        }
                        case "appliance": {
                            return (
                                (e.appliance && e.appliance.id === id) || false
                            );
                        }
                        case "job": {
                            return (e.job && e.job.id === Number(id)) || false;
                        }
                        case "property": {
                            return e.property.id === Number(id);
                        }
                    }
                }),
            )
            .subscribe((value) => {
                const updatedList = realtimeUpdateList(value, records);

                if (updatedList) {
                    updateValue(updatedList);
                }

                clearCache();
            });

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [id, modelType, realtimeObservable, records, updateValue]);

    return { loaded, loading, records };
};

interface IUseIssuesForModel {
    id: string;
    modelType: "asset" | "appliance" | "property" | "job";
}

export default useIssuesForModel;
