import { CSSProperties, useMemo } from "react";
import AuthorizedImage from "../AuthorizedImage";
import { Display } from "../Img/Img";

const EngineerImg = ({
    name,
    imageUrl,
    size = 50,
    display = "",
    cssRules,
}: IEngineerImgProps) => {
    const initial = useMemo(() => (imageUrl ? "" : name ? name[0] : ""), [
        imageUrl,
        name,
    ]);

    return (
        <AuthorizedImage
            circle={true}
            size={size}
            image={imageUrl}
            display={display}
            cssRules={cssRules}
        >
            {initial}
        </AuthorizedImage>
    );
};

interface IEngineerImgProps {
    size?: number;
    display?: Display;
    name: string;
    imageUrl: string;
    cssRules?: CSSProperties;
}

export default EngineerImg;
