import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IDocumentPreferencesRowProps } from ".";
import { Select } from "../../../../components";
import styles from "./DocumentPreferencesRow.module.scss";

const DocumentPreferencesRow = ({
    documentPreferences,
    paperworkType,
    onPreferencesChange,
}: IDocumentPreferencesRowProps) => {
    const { t } = useTranslation();

    const preference = useMemo(
        () =>
            documentPreferences.find(
                (d) => d.paperworkTypeId === paperworkType.id,
            ),
        [documentPreferences, paperworkType.id],
    );

    const percentage = useMemo(() => preference?.percentage ?? 0, [preference]);
    const percentageOptions = useMemo(
        () => [
            { label: t("0%"), value: "0" },
            { label: t("10%"), value: "10" },
            { label: t("20%"), value: "20" },
            { label: t("30%"), value: "30" },
            { label: t("40%"), value: "40" },
            { label: t("50%"), value: "50" },
            { label: t("60%"), value: "60" },
            { label: t("70%"), value: "70" },
            { label: t("80%"), value: "80" },
            { label: t("90%"), value: "90" },
            { label: t("100%"), value: "100" },
        ],
        [t],
    );

    const audit = useMemo(() => preference?.audit ?? false, [preference]);
    const auditOptions = useMemo(
        () => [
            { label: t("Yes"), value: "true" },
            { label: t("No"), value: "false" },
        ],
        [t],
    );

    const handlePercentageChange = useCallback(
        (value: string) => {
            onPreferencesChange({
                paperworkTypeId: paperworkType.id,
                percentage: Number(value),
                audit,
            });
        },
        [onPreferencesChange, paperworkType.id, audit],
    );

    const handleAuditChange = useCallback(
        (value: string) => {
            onPreferencesChange({
                paperworkTypeId: paperworkType.id,
                audit: value === "true",
                percentage,
            });
        },
        [onPreferencesChange, paperworkType.id, percentage],
    );

    return (
        <div className={styles.paperworkRow}>
            <div className={styles.documentColumn}>
                {t(paperworkType.paperworkType)}
            </div>

            <div className={styles.percentageColumn}>
                {audit && (
                    <>
                        <div className={styles.selectLabel}>{t("Manual")}</div>
                        <Select
                            options={percentageOptions}
                            onChange={handlePercentageChange}
                            value={percentage.toString()}
                            useMargin={false}
                        />
                    </>
                )}
            </div>

            <div>
                <Select
                    options={auditOptions}
                    onChange={handleAuditChange}
                    value={audit.toString()}
                    useMargin={false}
                />
            </div>
        </div>
    );
};

export default DocumentPreferencesRow;
