import { ReactNode } from "react";
import EditableListItemLayout from "./EditableListItemLayout";

export interface IEditableListItemLayoutProps {
    children: ReactNode;
    isEditing: boolean;
    canEdit?: boolean;
    loading?: boolean;
    error?: string;
    onEdit?: () => void;
    onDelete?: () => void;
    onCancel?: () => void;
}

export default EditableListItemLayout;
