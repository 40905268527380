import { ReactNode } from "react";
import TabGroupHeader from "./TabGroupHeader";

export interface ITabGroupHeaderProps {
    isActive: boolean;
    index: number;
    onClick: (index: number) => void;
    children: ReactNode;
    position: "top" | "left";
}

export default TabGroupHeader;
