import { useEffect } from "react";
import { INotificationType } from ".";
import config from "../../../config";
import { useXTagApiRequest } from "../../request";
import useApiValue from "../useApiValue";

export const useNotificationTypes = (notificationCategoryId: number) => {
    const { send, value, loaded } = useApiValue<INotificationType[]>({
        initialValue: [],
    });

    const sendRequest = useXTagApiRequest();

    useEffect(() => {
        const subscription = send(
            sendRequest({
                url: `${config.usersApiUrl}/notifications/${notificationCategoryId}`,
                method: "GET",
            }),
        ).subscribe();

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [notificationCategoryId, send, sendRequest]);

    return { value, loaded };
};

export default useNotificationTypes;
