import { useState } from "react";
import { useTranslation } from "react-i18next";
import { InputField, ValidationError } from "../..";
import { IMention } from "../../../utils/api/issues";
import {
    isRequired,
    useValidateField,
    validateForm,
} from "../../../utils/validation";
import Button from "../../Button";
import Form from "../../Form";
import Icon from "../../Icon/Icon";
import styles from "./PostComment.module.scss";

const PostComment = ({ handleSubmit, loading }: IPostCommentProps) => {
    const { t } = useTranslation();

    const [value, setValue] = useState("");
    // TODO: Implement add mentions.
    const [mentions] = useState<IMention[]>([]);

    const handleSubmitComment = () => {
        handleSubmit(value, mentions);
        setValue("");

        valueValidation.reset();
    };

    const valueValidation = useValidateField(value, isRequired());

    const formValidation = validateForm(() => [valueValidation]);

    return (
        <Form onSubmit={handleSubmitComment} {...formValidation}>
            <div className={styles.postCommentContainer}>
                <div className={styles.inputContainer}>
                    <InputField
                        value={value}
                        onChange={setValue}
                        useMargin={false}
                        placeholder={t("Comment")}
                    />
                </div>
                <Button type="submit" disabled={loading}>
                    <Icon icon="send" ariaLabel={t("Send comment")} size={16} />
                </Button>
            </div>

            <ValidationError {...valueValidation} />
        </Form>
    );
};

interface IPostCommentProps {
    loading: boolean;
    handleSubmit: (comment: string, mentions: IMention[]) => void;
}

export default PostComment;
