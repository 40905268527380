import { useCallback } from "react";
import { noop } from "rxjs";
import useClickableNonInteractiveElement from "../../../hooks/useClickableNonInteractiveElement";
import { IFlag } from "../../../utils/api/misc";
import styles from "./FlagItem.module.scss";

const FlagItem = ({ flag, onClick = noop }: IFlagItemProps) => {
    const handleClick = useCallback(() => onClick(flag), [flag, onClick]);

    const { onKeyDown } = useClickableNonInteractiveElement(handleClick);

    return (
        <div
            className={styles.container}
            onClick={handleClick}
            onKeyDown={onKeyDown}
            role="button"
            tabIndex={0}
        >
            <div
                className={styles.icon}
                style={{ backgroundColor: flag.colour }}
            />
            <div className={styles.name}>{flag.name}</div>
        </div>
    );
};

interface IFlagItemProps {
    flag: IFlag;
    onClick?: (flag: IFlag) => void;
}

export default FlagItem;
