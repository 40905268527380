import { IReconciliationData, ReconciliationEntityType } from ".";
import config from "../../../config";
import useFilterable from "../useFilterable";

const useReconciliationData = (
    entity: ReconciliationEntityType,
    status: string,
) => {
    const filterable = useFilterable<IReconciliationData>(
        `${config.reconciliationApiUrl}/${entity}`,
        {
            params: {
                sortProperty: "reconcilePrimary",
                sortDirection: "asc",
                filters: {
                    reconciled: {
                        operator: "{AND}",
                        readOnly: true,
                        filters: [
                            {
                                function: "=",
                                value:
                                    status === "reconciled" ? "true" : "false",
                            },
                        ],
                    },
                },
            },
        },
    );

    return filterable;
};

export default useReconciliationData;
