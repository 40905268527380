import { useCallback, useContext } from "react";
import { IIntegrationJobApprovalUpdate } from ".";
import { PropertyCategoriesContext, UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const useIntegrationApprovalUpdate = () => {
    const { selectedCategories } = useContext(PropertyCategoriesContext);
    const { activeUserParentsIds } = useContext(UserContext);
    const { send } = useApiRequest({});
    const sendRequest = useXTagApiRequest();

    const updateApproval = useCallback(
        (approval: IIntegrationJobApprovalUpdate) =>
            send(
                sendRequest({
                    url: `${config.miscApiUrl}/integration/approvals`,
                    method: "POST",
                    urlParams: getGlobalUrlParameters(
                        selectedCategories.map((c) => c.id),
                        activeUserParentsIds,
                    ),
                    body: approval,
                }),
            ),
        [activeUserParentsIds, selectedCategories, send, sendRequest],
    );

    return { updateApproval };
};

export default useIntegrationApprovalUpdate;
