import { useMemo } from "react";
import { IContractor } from ".";
import config from "../../../config";
import useAllFilterable from "../useAllFilterable";

const useAllContractors = () => {
    const sortProperties = useMemo(() => ["name"], []);

    const filterable = useAllFilterable<IContractor>({
        url: `${config.landlordsApiUrl}/contractors`,
        sortProperties,
    });

    return filterable;
};

export default useAllContractors;
