import { useCallback } from "react";
import { IUrlParameters } from "../utils/url";

const useQueryString = (search: string) => {
    const getQueryString = useCallback(() => {
        return { queryString: [search] } as IUrlParameters;
    }, [search]);

    return { getQueryString };
};

export default useQueryString;
