import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { INavLinkProps } from "../components/NavLink";
import { IRouteProps } from "../utils/routing";

const useNavigationLinks = (routes: IRouteProps[]) => {
    const urlParams = useParams();

    const links = useMemo(() => {
        const replaceUrlParams = (path: string) => {
            let text = path;

            for (const key of Object.keys(urlParams)) {
                const param = urlParams[key];
                if (param !== undefined) {
                    text = text.replace(`:${key}`, param);
                }
            }

            return text;
        };

        if (routes) {
            return routes
                .filter((route) => !!route.title)
                .map<INavLinkProps>((route) => {
                    return {
                        url: replaceUrlParams(route.path || ""),
                        title: route.title || "",
                        end: route.index,
                    };
                });
        }

        return [];
    }, [routes, urlParams]);

    return links;
};

export default useNavigationLinks;
