import { createContext } from "react";
import { noop } from "rxjs";
import { IUpdateUserContext } from ".";

export const UpdateUserContext = createContext<IUpdateUserContext>({
    firstName: "",
    setFirstName: noop,
    firstNameValidation: {
        error: "",
        isValid: false,
        reset: noop,
        validate: () => false,
    },
    lastName: "",
    setLastName: noop,
    lastNameValidation: {
        error: "",
        isValid: false,
        reset: noop,
        validate: () => false,
    },
    userParentRoles: [],
    setUserParentRoles: noop,
    userParentRolesValidation: {
        error: "",
        isValid: false,
        reset: noop,
        validate: () => false,
    },
    propertyCategoryIds: [],
    setPropertyCategoryIds: noop,
    updatedUser: {
        name: "",
        propertyCategories: [],
        userParentRoles: [],
        profileImageUrl: "",
    },
    formValidation: {
        isValid: false,
        validate: () => false,
    },
});
