import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { noop } from "rxjs";
import { ValidationError } from "..";
import { PropertyCategoriesContext } from "../PropertyCategories";
import Select, { ISelectOption } from "../Select";

const PropertyCategorySelector = ({
    label = "Please select a property category",
    onChange = noop,
    value = "",
    isValid,
    error,
}: IPropertyCategorySelectorProps) => {
    const { t } = useTranslation();
    const { propertyCategories } = useContext(PropertyCategoriesContext);

    const propertyCategorySelectOptions = useMemo<ISelectOption[]>(() => {
        return propertyCategories.map((u) => ({
            label: u.displayName ?? "",
            value: u.id.toString(),
        }));
    }, [propertyCategories]);

    return (
        <>
            <Select
                label={t(label)}
                options={propertyCategorySelectOptions}
                onChange={onChange}
                value={value}
                allowEmpty={true}
            />
            <ValidationError isValid={isValid} error={error} />
        </>
    );
};

interface IPropertyCategorySelectorProps {
    label?: string;
    value?: string;
    onChange?: (landlordId: string) => void;
    isValid?: boolean;
    error?: string;
}

export default PropertyCategorySelector;
