import { useContext, useEffect, useMemo } from "react";
import { filter, map } from "rxjs";
import { IAllocatedJob } from ".";
import RealtimeContext from "../../../components/RealtimeProvider/RealtimeContext";
import config from "../../../config";
import { clearCache } from "../../cache";
import { realtimeCreateList, realtimeUpdateList } from "../../realtime";
import filterLocal from "../filterLocal";
import { IProperty } from "../properties";
import useAllFilterable from "../useAllFilterable";
import { IFilterGroup } from "../useFilterable";

const useAllAllocatedJobs = ({
    startDate,
    endDate,
    propertyId,
}: IUseAllAllocatedJobs) => {
    const { realtimeObservable } = useContext(RealtimeContext);

    const sortProperties = useMemo(() => ["jobDate"], []);

    let dateFilterGroup: IFilterGroup;

    if (startDate !== undefined && endDate !== undefined) {
        dateFilterGroup = {
            operator: "{AND}",
            filters: [
                { function: ">=", value: startDate.toISOString() },
                {
                    function: "<",
                    value: endDate.toISOString(),
                },
            ],
            readOnly: false,
        };
    } else {
        dateFilterGroup = {
            filters: [{ function: ">=", value: startDate.toISOString() }],
            readOnly: false,
        };
    }

    const filterable = useAllFilterable<IAllocatedJob>({
        url: config.allocatedJobsApiUrl,
        sortProperties,
        filters: {
            jobDate: dateFilterGroup,
            ...(propertyId !== undefined && {
                "property.id": {
                    filters: [{ function: "=", value: propertyId.toString() }],
                },
            }),
        },
    });

    useEffect(() => {
        const subscription = realtimeObservable
            .pipe(
                filter(
                    (e) =>
                        e.event === "created" && e.entity === "allocated-job",
                ),
                map((e) => e.payload as IAllocatedJob),
                filter((e) => filterLocal(filterable.filters, e)),
            )
            .subscribe((value) => {
                const updatedList = realtimeCreateList(
                    value,
                    filterable.records,
                );

                if (updatedList) {
                    filterable.updateValue(
                        updatedList.sort((a, b) =>
                            new Date(a.jobDate) > new Date(b.jobDate) ? 1 : -1,
                        ),
                    );
                }

                clearCache();
            });

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterable.records]);

    useEffect(() => {
        const subscription = realtimeObservable
            .pipe(
                filter(
                    (e) =>
                        e.event === "updated" && e.entity === "allocated-job",
                ),
                map((e) => e.payload as IAllocatedJob),
            )
            .subscribe((value) => {
                const isVisible = filterLocal(filterable.filters, value);

                if (isVisible) {
                    const updatedList = realtimeUpdateList(
                        value,
                        filterable.records,
                    );

                    if (updatedList) {
                        filterable.updateValue(updatedList);
                    }
                } else {
                    const list = filterable.records.filter(
                        (j) => j.id !== value.id,
                    );

                    filterable.updateValue(list);
                }

                clearCache();
            });

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterable.records]);

    useEffect(() => {
        const subscription = realtimeObservable
            .pipe(
                filter((e) => e.event === "updated" && e.entity === "property"),
                map((e) => e.payload as IProperty),
            )
            .subscribe((value) => {
                const jobs = [...filterable.records];

                for (const job of jobs) {
                    if (job.property.id === value.id) {
                        job.property = {
                            id: value.id,
                            addressString: value.addressString,
                            categories: value.categories,
                            lat: value.lat,
                            long: value.long,
                            nextServiceDate: value.nextServiceDueDate,
                            lastMaintenanceDate: value.lastMaintenanceDate,
                            landlord: {
                                id: value.landlord.id,
                                name: value.landlord.name,
                            },
                        };
                    }
                }

                filterable.updateValue(jobs);
                clearCache();
            });

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterable.records]);

    return filterable;
};

export interface IUseAllAllocatedJobs {
    startDate: Date;
    endDate?: Date;
    propertyId?: number;
}

export default useAllAllocatedJobs;
