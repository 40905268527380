import { ReactNode } from "react";
import { Observable } from "rxjs";
import { IContext } from "..";
import { NotificationCategory } from "../../utils/api/misc";
import {
    INotificationType,
    IUserNotificationType,
} from "../../utils/api/users";
import NotificationsProvider from "./NotificationsProvider";

export interface INotificationsProviderProps {
    children: ReactNode;
}

export interface INotificationCount {
    category: NotificationCategory;
    count: number;
}

export interface INotificationsContext extends IContext {
    categories: {
        issue: Observable<number>;
        standard: Observable<number>;
    };
    activeNotificationTypes: IUserNotificationType[];
    standardTypes: INotificationType[];
    issueTypes: INotificationType[];
    updateNotificationsCount: (
        category: NotificationCategory,
        count: number,
    ) => void;
}

export default NotificationsProvider;
