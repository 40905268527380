import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BaseAppliance } from ".";
import { InputField, Select, UserContext } from "../../components";
import { IEditableDetailsData } from "../../components/EditableDetailsTable";
import { IAppliance, IGasAppliance } from "../../utils/api/appliances";

const GasAppliance = ({ appliance }: IGasApplianceProps) => {
    const userContext = useContext(UserContext);

    const { t } = useTranslation();
    const [flueAngle, setFlueAngle] = useState("");
    const [flueType, setFlueType] = useState("");
    const [landlordsAppliance, setLandlordsAppliance] = useState("");
    const [gasCouncilNumber, setGasCouncilNumber] = useState("");

    const flueTypeOptions = [t("RS"), t("OF"), t("FL"), t("N/A"), t("BF"), ""];
    const flueAngleOptions = [t("Vertical"), t("Horizontal"), t("N/A"), ""];

    useEffect(() => {
        if (appliance) {
            // Set initial value of dropdowns
            setFlueAngle(appliance.flueAngle);
            setFlueType(appliance.flueType);
            setLandlordsAppliance(appliance.landlordsAppliance.toString());

            if (appliance.gasCouncilNumber) {
                setGasCouncilNumber(appliance.gasCouncilNumber.toString());
            }
        }
    }, [appliance, userContext.activeUserParentsIds]);

    const getColumns = (editingToggleVisible: boolean) => {
        const detailTableColumns: IEditableDetailsData[] = [
            {
                title: t("Fuel Type"),
                type: "component",
                path: "fuelType",
            },
            {
                title: t("Make"),
                type: "string",
                path: "make",
            },
            {
                title: t("Model"),
                type: "string",
                path: "model",
            },
            {
                title: t("Gas Council Number"),
                type: "component",
                path: "gasCouncilNumber",
                // eslint-disable-next-line react/display-name
                render: (value: string) => {
                    return editingToggleVisible ? (
                        <InputField
                            onChange={setGasCouncilNumber}
                            value={value}
                            placeholder={t("Gas Council Number")}
                        />
                    ) : (
                        value
                    );
                },
            },
            {
                title: t("Flue Type"),
                type: "component",
                path: "flueType",
                // eslint-disable-next-line react/display-name
                render: (value: string) => {
                    return editingToggleVisible ? (
                        <Select
                            options={flueTypeOptions.map((flue) => ({
                                label: flue,
                                value: flue,
                            }))}
                            value={flueType}
                            onChange={setFlueType}
                        />
                    ) : (
                        value
                    );
                },
            },
            {
                title: t("Flue Angle"),
                type: "component",
                path: "flueAngle",
                // eslint-disable-next-line react/display-name
                render: (value: string) => {
                    return editingToggleVisible ? (
                        <Select
                            options={flueAngleOptions.map((flue) => ({
                                label: flue,
                                value: flue,
                            }))}
                            value={flueAngle}
                            onChange={setFlueAngle}
                        />
                    ) : (
                        value
                    );
                },
            },
            {
                title: t("Landlords Appliance"),
                type: "component",
                path: "landlordsAppliance",
                // eslint-disable-next-line react/display-name
                render: (value: string) => {
                    return editingToggleVisible ? (
                        <Select
                            options={[
                                {
                                    label: t("Yes"),
                                    value: "true",
                                },
                                {
                                    label: t("No"),
                                    value: "false",
                                },
                            ]}
                            value={landlordsAppliance}
                            onChange={setLandlordsAppliance}
                        />
                    ) : value ? (
                        t("Yes")
                    ) : (
                        t("No")
                    );
                },
            },
        ];

        return detailTableColumns;
    };

    const patchUpdateModel = (model: IAppliance) => {
        const gasAppliance = model as IGasAppliance;

        gasAppliance.landlordsAppliance = landlordsAppliance === "true";
        gasAppliance.flueAngle = flueAngle;
        gasAppliance.flueType = flueType;
        gasAppliance.gasCouncilNumber = gasCouncilNumber;

        return gasAppliance;
    };

    return (
        <BaseAppliance
            appliance={appliance}
            getColumns={getColumns}
            patchUpdateModel={patchUpdateModel}
        />
    );
};

interface IGasApplianceProps {
    appliance: IGasAppliance;
}

export default GasAppliance;
