import { ISimpleAppliance } from ".";
import config from "../../../config";
import useFilterable, { IFilterableParams } from "../useFilterable";

const useAppliances = (params?: IFilterableParams) => {
    const filterable = useFilterable<ISimpleAppliance>(
        config.appliancesApiUrl,
        {
            params: {
                ...params,
                filters: {
                    ...(params && params.filters),
                    active: {
                        readOnly: true,
                        operator: "{AND}",
                        filters: [{ function: "=", value: "true" }],
                    },
                },
            },
        },
    );

    return filterable;
};

export default useAppliances;
