import { useCallback, useContext } from "react";
import { IFlag, IUpdateFlag } from ".";
import { UserContext } from "../../../components";
import config from "../../../config";
import { getGlobalUrlParameters, useXTagApiRequest } from "../../request";
import useApiRequest from "../useApiRequest";

const useUpdateFlag = () => {
    const { activeUserParentsIds } = useContext(UserContext);
    const { send, loading, error, setError } = useApiRequest<IFlag>({});
    const sendRequest = useXTagApiRequest();

    const updateFlag = useCallback(
        (flag: IUpdateFlag) =>
            send(
                sendRequest({
                    url: `${config.miscApiUrl}/flags/${flag.id}`,
                    method: "PUT",
                    urlParams: getGlobalUrlParameters([], activeUserParentsIds),
                    body: flag,
                }),
            ),
        [activeUserParentsIds, send, sendRequest],
    );

    return { updateFlag, loading, error, setError };
};

export default useUpdateFlag;
