import { IObservation } from ".";
import config from "../../../config";
import useFilterable from "../useFilterable";

interface IUseObservationsProps {
    status?: string;
    propertyId?: number;
    dateRaised?: string;
}

const useObservations = ({
    status,
    propertyId,
    dateRaised,
}: IUseObservationsProps) => {
    const filterable = useFilterable<IObservation>(
        `${config.issuesApiUrl}/observations`,
        {
            params: {
                filters: {
                    ...(status !== undefined
                        ? {
                              "status.displayName": {
                                  operator: "{AND}",
                                  readOnly: true,
                                  filters: [{ function: "=", value: status }],
                              },
                          }
                        : {}),
                    ...(propertyId !== undefined
                        ? {
                              "property.id": {
                                  operator: "{AND}",
                                  filters: [
                                      {
                                          function: "=",
                                          value: propertyId.toString(),
                                      },
                                  ],
                              },
                          }
                        : {}),
                    ...(dateRaised !== undefined
                        ? {
                              dateRaised: {
                                  operator: "{AND}",
                                  filters: [
                                      {
                                          function: "<",
                                          value: dateRaised,
                                      },
                                  ],
                              },
                          }
                        : {}),
                },
            },
        },
    );

    return filterable;
};

export default useObservations;
