import { IValidationResult } from ".";

function isRequired<T>(error = "The field is required.") {
    const validate = (value: T): IValidationResult => {
        const isValid = !!value;

        return {
            isValid,
            error: isValid ? "" : error,
        };
    };

    return { validate };
}

export default isRequired;
