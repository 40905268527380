import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IDocumentPreferencesFormProps } from ".";
import { Alert, Button, Form, Loading } from "../../../../components";
import { IDocumentAuditPreferences } from "../../../../utils/api/landlords";
import DocumentPreferencesRow from "../DocumentPreferencesRow";

const DocumentPreferencesForm = ({
    paperworkTypes,
    preferences,
    isLoading,
    error,
    updatePreferences,
}: IDocumentPreferencesFormProps) => {
    const { t } = useTranslation();

    const [newPreferences, setNewPreferences] = useState(preferences);
    useEffect(() => {
        setNewPreferences(preferences);
    }, [preferences]);

    const handleFormSubmit = useCallback(() => {
        updatePreferences(newPreferences);
    }, [newPreferences, updatePreferences]);

    const handlePreferencesChange = useCallback(
        (preference: IDocumentAuditPreferences) => {
            const list = newPreferences.preferences.filter(
                (p) => p.paperworkTypeId !== preference.paperworkTypeId,
            );

            list.push({
                paperworkTypeId: preference.paperworkTypeId,
                audit: preference.audit,
                percentage: preference.percentage,
            });

            setNewPreferences((p) => ({
                landlordId: p.landlordId,
                preferences: list,
            }));
        },
        [newPreferences.preferences],
    );

    return (
        <Form onSubmit={handleFormSubmit}>
            {paperworkTypes.map((paperworkType) => (
                <DocumentPreferencesRow
                    key={paperworkType.id}
                    documentPreferences={newPreferences.preferences}
                    paperworkType={paperworkType}
                    onPreferencesChange={handlePreferencesChange}
                />
            ))}

            {isLoading ? (
                <Loading small={true} />
            ) : (
                <Button type="submit" variant="primary">
                    {t("Save")}
                </Button>
            )}

            {error && <Alert type="error">{error}</Alert>}
        </Form>
    );
};

export default DocumentPreferencesForm;
