import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { IDeleteUserModalProps } from ".";
import { Button, Loading, Modal, Portal } from "../../../components";
import ModalBody from "../../../components/Modal/ModalBody";
import ModalFooter from "../../../components/Modal/ModalFooter";
import useAdminDisableUser from "../../../utils/api/users/useAdminDisableUser";

const DeleteUserModal = ({
    userId,
    onDeleteUser,
    hide,
}: IDeleteUserModalProps) => {
    const { t } = useTranslation();
    const { loading, disableUser } = useAdminDisableUser();

    const handleDeleteUserClick = useCallback(() => {
        disableUser(userId).subscribe(() => {
            hide();
            onDeleteUser();
        });
    }, [disableUser, userId, hide, onDeleteUser]);

    return (
        <Portal>
            <Modal title={t("Delete User")} hide={hide}>
                <ModalBody>
                    <p>
                        {t(
                            "Are you sure you want to delete this user? They will no longer be able to access the Plentific system.",
                        )}
                    </p>

                    {loading && <Loading small={true} />}
                </ModalBody>
                <ModalFooter>
                    <Button
                        variant="error"
                        onClick={handleDeleteUserClick}
                        disabled={loading}
                        cssRules={{ marginRight: "10px" }}
                    >
                        {t("Delete")}
                    </Button>
                    <Button onClick={hide}>{t("Cancel")}</Button>
                </ModalFooter>
            </Modal>
        </Portal>
    );
};

export default DeleteUserModal;
