import { useCallback, useContext, useEffect } from "react";
import { filter, map } from "rxjs";
import { IObservation, IUseObservationsForModel } from ".";
import RealtimeContext from "../../../components/RealtimeProvider/RealtimeContext";
import config from "../../../config";
import { clearCache } from "../../cache";
import { realtimeCreateList } from "../../realtime";
import { IProperty } from "../properties";
import useFilterable, { IFilters } from "../useFilterable";

const useObservationsForModel = ({
    id,
    modelType,
}: IUseObservationsForModel) => {
    const { realtimeObservable } = useContext(RealtimeContext);

    const buildFilter = useCallback((): IFilters => {
        return {
            [modelType + ".id"]: {
                operator: "{AND}",
                filters: [{ function: "=", value: id }],
            },
        };
    }, [id, modelType]);

    const { records, loaded, error, refresh, updateValue } =
        useFilterable<IObservation>(`${config.issuesApiUrl}/observations`, {
            params: {
                sortProperty: "createdAt",
                sortDirection: "desc",
                itemsPerPage: 1000,
                filters: buildFilter(),
            },
        });

    useEffect(
        () => refresh(buildFilter()),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [buildFilter, id, modelType],
    );

    // property realtime handler
    useEffect(() => {
        const subscription = realtimeObservable
            .pipe(
                filter((e) => e.entity === "property" && e.event === "updated"),
                map((e) => e.payload as IProperty),
            )
            .subscribe((value) => {
                const observations = [...records];

                observations
                    .filter(
                        (observation) => observation.property.id === value.id,
                    )
                    .forEach((observation) => {
                        observation.property = {
                            id: value.id,
                            address: value.addressString,
                        };
                    });

                updateValue(observations);
                clearCache();
            });

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [realtimeObservable, records, updateValue]);

    const filterModelType = useCallback(
        (observation: IObservation) => {
            switch (modelType) {
                case "asset": {
                    return observation.asset?.id === id || false;
                }
                case "job": {
                    return observation.job?.id === Number(id) || false;
                }
                case "property": {
                    return observation.property.id === Number(id);
                }
                case "issue": {
                    return observation.issue?.id === Number(id);
                }
            }
        },
        [id, modelType],
    );

    useEffect(() => {
        const subscription = realtimeObservable
            .pipe(
                filter(
                    (e) => e.entity === "observations" && e.event === "created",
                ),
                map((e) => e.payload as IObservation),
                filter(filterModelType),
            )
            .subscribe((value) => {
                const updatedList = realtimeCreateList(value, records);

                if (updatedList) {
                    updateValue(updatedList);
                }

                clearCache();
            });

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [filterModelType, realtimeObservable, records, updateValue]);

    useEffect(() => {
        const subscription = realtimeObservable
            .pipe(
                filter(
                    (e) => e.entity === "observations" && e.event === "updated",
                ),
                map((e) => e.payload as IObservation),
                filter(filterModelType),
            )
            .subscribe((value) => {
                const updatedList = realtimeCreateList(value, records);

                if (updatedList) {
                    updateValue(updatedList);
                }

                clearCache();
            });

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [filterModelType, realtimeObservable, records, updateValue]);

    return { records, loaded, error };
};

export default useObservationsForModel;
