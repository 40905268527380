import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IJobTimelineJobProps } from ".";
import { Button, JobType, Link } from "../..";
import { useCssClasses, useHasPermission } from "../../../hooks";
import useDownloadPaperwork from "../../../hooks/useDownloadPaperwork";
import { useJobTasks } from "../../../utils/api/jobs";
import useFileUrl from "../../../utils/api/misc/useFileUrl";
import { toTimeString } from "../../../utils/dates";
import Checkbox from "../../Checkbox";
import FuelTypeIcon from "../FuelTypeIcon/FuelTypeIcon";
import JobIcon from "../JobIcon/JobIcon";
import TaskContainer from "../TaskContainer";
import styles from "./JobTimelineJob.module.scss";

const JobTimelineJob = ({
    job,
    isChecked,
    onCheckedChange,
    showTasks = true,
    showTaskIcons,
    showAddress = true,
    showJobType = true,
    loadTasksAsync = false,
    isCompact = false,
    answers,
    showJobIcons = false,
    openChildJobsInNewWindow = false,
}: IJobTimelineJobProps) => {
    const { t } = useTranslation();
    const [tasksOpen, setTasksOpen] = useState(false);
    const downloadPaperwork = useDownloadPaperwork();

    const { value, getTasks, loaded, loading } = useJobTasks();
    const tasks = useMemo(
        () =>
            (value || job.tasks || [])
                .filter(
                    (task) =>
                        task.type.taskType !== "electric_apply_limitation",
                )
                .sort((a, b) => (a.startDate > b.startDate ? 1 : -1)),
        [job.tasks, value],
    );

    const isPortalStandard = useHasPermission("portal_standard");

    const tasksCount = useMemo(
        () => tasks.length || job.totalTasks,
        [job.totalTasks, tasks.length],
    );

    const onTotalTasksClick = () => {
        if (!loaded && !loading) {
            getTasks(job.id).subscribe();
        }

        setTasksOpen(!tasksOpen);
    };

    const onManualUploadClick = () => {
        if (job.recoveryJob && job.paperwork && job.paperwork.length > 0) {
            const paperwork = job.paperwork[0];

            downloadPaperwork
                .download(paperwork.id, paperwork.documentUrl, true)
                .subscribe();
        }
    };

    const [lgsr, setLgsr] = useState("");

    const getFileUrl = useFileUrl();

    useEffect(() => {
        const lgsrJob = job.paperwork?.find((p) => p.documentName === "LGSR");

        if (lgsrJob) {
            getFileUrl(lgsrJob.documentUrl).subscribe((url) => {
                if (!lgsr) {
                    setLgsr(url);
                }
            });
        }
    }, [getFileUrl, job, lgsr]);

    const jobDetailsAlignedLeftCssClasses = useCssClasses(
        styles.jobDetailsAlignedLeft,
        isCompact ? styles.compact : "",
    );

    const jobDetailsPropertyCssClasses = useCssClasses(
        styles.jobDetailsProperty,
        isCompact ? styles.compact : "",
    );

    return (
        <div className={styles.container}>
            <div className={styles.jobHeader}>
                {onCheckedChange && (
                    <div className={styles.jobCheckbox}>
                        <Checkbox
                            value={job.id}
                            onChange={onCheckedChange}
                            checked={isChecked}
                            useMargin={false}
                            ariaLabel={t("Select job")}
                        />
                    </div>
                )}

                <div className={styles.jobTime}>
                    {showJobIcons && (
                        <div className={styles.iconFlex}>
                            <JobIcon jobType={job.jobType} />
                            {job.complianceType && (
                                <FuelTypeIcon
                                    fuelType={job.complianceType.displayName}
                                    fuelTypeColour={job.complianceType.colour}
                                />
                            )}
                        </div>
                    )}
                    {isPortalStandard ? (
                        <Link url={`/jobs/jobs/${job.id}`} testId="JobId_A">
                            #{job.id}
                        </Link>
                    ) : (
                        `#${job.id}`
                    )}
                    {!job.recoveryJob && job.date && (
                        <div data-test-id="JobTime_Div">
                            {toTimeString(new Date(job.date))}
                        </div>
                    )}
                </div>

                <div className={styles.jobSeparator} />

                <div className={styles.jobDetails}>
                    <div className={jobDetailsAlignedLeftCssClasses}>
                        {job.property && showAddress && (
                            <div className={jobDetailsPropertyCssClasses}>
                                {isPortalStandard ? (
                                    <Link
                                        url={`/management/properties/${job.property.id}`}
                                        testId="JobAddress_A"
                                    >
                                        {job.property.addressString}
                                    </Link>
                                ) : (
                                    job.property.addressString
                                )}
                            </div>
                        )}

                        {isCompact || (
                            <div className={styles.timelineSeparator} />
                        )}

                        <div className={styles.jobDetailsAlignedRight}>
                            <div data-test-id="JobTaskCount_Div">
                                {job.recoveryJob ? (
                                    <Button
                                        displayAsLink={true}
                                        variant="primary"
                                        onClick={onManualUploadClick}
                                        cssRules={{ fontSize: "inherit" }}
                                    >
                                        {t("Manual Upload")}
                                    </Button>
                                ) : showTasks && loadTasksAsync ? (
                                    <Button
                                        displayAsLink={true}
                                        variant="primary"
                                        onClick={onTotalTasksClick}
                                        cssRules={{ fontSize: "inherit" }}
                                    >
                                        {t("tasksCount", { count: tasksCount })}
                                    </Button>
                                ) : (
                                    t("tasksCount", { count: tasksCount })
                                )}
                                &nbsp;{t("carried out by")}&nbsp;
                                {job.engineer &&
                                    (isPortalStandard ? (
                                        <Link
                                            url={`/management/engineers/${job.engineer.id}`}
                                            testId="JobEngineer_A"
                                        >
                                            {job.engineer.name}
                                        </Link>
                                    ) : (
                                        job.engineer.name
                                    ))}
                            </div>
                            {!isCompact && (
                                <>
                                    <div>
                                        {job.jobType && showJobType && (
                                            <>
                                                <JobType
                                                    jobType={job.jobType}
                                                />
                                                {isPortalStandard && (
                                                    <Link
                                                        variant="bordered"
                                                        url={`/jobs/jobs/${job.id}`}
                                                    >
                                                        {t("View")}
                                                    </Link>
                                                )}
                                                {lgsr && (
                                                    <Link
                                                        variant="bordered"
                                                        url={lgsr}
                                                    >
                                                        {t("LGSR")}
                                                    </Link>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {job.childJobs?.map((j) => (
                <div key={j.id} className={styles.subJob}>
                    <div className={styles.subJobId}>
                        <Link
                            url={`/jobs/jobs/${j.id}`}
                            openInNewWindow={openChildJobsInNewWindow}
                        >
                            #{j.id}
                        </Link>
                    </div>
                    <div className={styles.subJobType}>{j.jobType}</div>
                    <div>
                        {t("tasksCount", { count: j.totalTasks })}&nbsp;
                        {t("carried out by")}&nbsp;
                        <Link url={`/management/engineers/${j.engineer.id}`}>
                            {j.engineer.name}
                        </Link>
                    </div>
                </div>
            ))}

            {showTasks &&
                (!loadTasksAsync || (loadTasksAsync && tasksOpen && loaded)) &&
                tasks.map((task) => (
                    <TaskContainer
                        key={task.id}
                        answers={answers}
                        task={task}
                        showTaskIcons={showTaskIcons}
                        otherTask={
                            job.complianceType?.displayName ===
                                "Property Services" &&
                            task.type.taskType !== "other_final_checks"
                        }
                        isPortalStandard={isPortalStandard}
                    />
                ))}
        </div>
    );
};

export default JobTimelineJob;
