import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Checkbox, Modal, Portal } from "..";
import { useToast, useToggle } from "../../hooks";
import useDownloadPaperwork from "../../hooks/useDownloadPaperwork";
import {
    IPaperwork,
    IPaperworkDistribution,
    IPaperworkDownloader,
} from "../../utils/api/jobs";
import { clearCache } from "../../utils/cache";
import { toDateString, toDateTimeString } from "../../utils/dates";
import ModalBody from "../Modal/ModalBody";
import PaperworkSender from "../PaperworkSender";
import SimpleTable, { IColumn } from "../SimpleTable";
import Toast from "../Toast";
import styles from "./PaperworkLog.module.scss";

const PaperworkLog = ({
    paperwork,
    tenantEmail,
    refresh,
    onSelectRow,
    isRowSelected,
    disableFuelTypeFiltering = false,
}: IPaperworkLogProps) => {
    const downloadPaperwork = useDownloadPaperwork();

    const {
        visible: seenByModalVisible,
        show: seenByModalShow,
        hide: seenByModalHide,
    } = useToggle();
    const {
        visible: sentToModalVisible,
        show: sentToModalShow,
        hide: sentToModalHide,
    } = useToggle();
    const toast = useToast();
    const [selectedPaperworkId, setSelectedPaperworkId] = useState<
        number | null
    >(null);

    const [modalType, setModalType] = useState<"sent" | "send" | "">("");

    const [paperworkDownloaders, setPaperworkDownloaders] = useState<
        IPaperworkDownloader[]
    >([]);
    const [paperworkDistributions, setPaperworkDistributions] = useState<
        IPaperworkDistribution[]
    >([]);

    const { t } = useTranslation();

    const [toastMessage, setToastMessage] = useState<string>("");

    useEffect(() => {
        const result = paperwork.find((p) => p.id === selectedPaperworkId);
        if (result) {
            setPaperworkDownloaders(result.paperworkDownloaders);

            setPaperworkDistributions(
                result.paperworkDistributions.sort((a, b) =>
                    a.distributionType === "Unknown"
                        ? 1
                        : b.distributionType === "Unknown"
                        ? -1
                        : new Date(b.createdAt).getTime() -
                          new Date(a.createdAt).getTime(),
                ),
            );
        }
    }, [selectedPaperworkId, paperwork]);

    const sentHandler = useCallback(
        (success: boolean, type: "email" | "post", to?: string) => {
            const message =
                type === "email"
                    ? `${
                          success
                              ? t("Document sent to")
                              : t("Failed to send Document to ")
                      } ${to}`
                    : success
                    ? t("Document marked as sent by post")
                    : t("Failed to marke document as sent by post");

            sentToModalHide();
            setToastMessage(message);
            toast.show();
            if (refresh) {
                clearCache();
                refresh();
            }
        },
        [refresh, sentToModalHide, t, toast],
    );

    const columns = useMemo<IColumn[]>(() => {
        const renderDocumentName = (value: unknown, data: IPaperwork) => {
            const handleOpenDocument = () => {
                downloadPaperwork
                    .download(
                        data.id,
                        data.documentUrl,
                        disableFuelTypeFiltering,
                    )
                    .subscribe();
            };

            return (
                <Button
                    displayAsLink={true}
                    variant="primary"
                    onClick={handleOpenDocument}
                    cssRules={{ textAlign: "left" }}
                >
                    {data.documentName === "" ? t("View") : data.documentName}
                </Button>
            );
        };

        const renderPaperworkDownloaders = (
            value: IPaperworkDownloader[],
            data: IPaperwork,
        ) => {
            const handleOnViewClick = () => {
                setSelectedPaperworkId(data.id);
                seenByModalShow();
            };

            return value.length ? (
                <Button
                    displayAsLink={true}
                    variant="primary"
                    onClick={handleOnViewClick}
                >
                    {t("View Users")}
                </Button>
            ) : (
                t("Not downloaded")
            );
        };

        const renderPaperworkDistributions = (
            value: IPaperworkDistribution[],
            data: IPaperwork,
        ) => {
            const handleOnClick = (type: "sent" | "send") => {
                setSelectedPaperworkId(data.id);
                setModalType(type);
                sentToModalShow();
            };

            return (
                <Button
                    displayAsLink={true}
                    variant="primary"
                    onClick={handleOnClick}
                    clickParams={[value.length ? "sent" : "send"]}
                >
                    {value.length ? t("Sent") : t("Send")}
                </Button>
            );
        };

        const renderCheckbox = (value: unknown, data: IPaperwork) => {
            const isChecked = isRowSelected(data.id);

            return (
                <Checkbox
                    value={data.id}
                    onChange={onSelectRow}
                    checked={isChecked}
                    className={styles.checkbox}
                    useMargin={false}
                    ariaLabel={t("Select row")}
                />
            );
        };

        return [
            {
                title: t("Id"),
                path: "id",
                type: "component",
                render: renderCheckbox,
            },
            {
                title: t("Date"),
                path: "createdAt",
                type: "date",
            },
            {
                title: t("Document Name"),
                path: "documentName",
                type: "component",
                render: renderDocumentName,
            },
            {
                title: t("Document Viewers"),
                path: "paperworkDownloaders",
                type: "component",
                render: renderPaperworkDownloaders,
            },
            {
                title: t("Sent Viewer"),
                path: "paperworkDistributions",
                type: "component",
                render: renderPaperworkDistributions,
            },
        ];
    }, [
        disableFuelTypeFiltering,
        downloadPaperwork,
        isRowSelected,
        onSelectRow,
        seenByModalShow,
        sentToModalShow,
        t,
    ]);

    const seenByColumns = useMemo<IColumn[]>(
        () => [
            {
                title: t("Name"),
                path: "user.name",
                type: "component",
                render: (value: string) => `${t("Name")}: ${value}`,
            },
            {
                title: t("First Viewed"),
                path: "createdAt",
                type: "component",
                render: (value: string) => {
                    return `${t("First")}: ${toDateString(new Date(value))}`;
                },
            },
            {
                title: t("Last Viewed"),
                path: "updatedAt",
                type: "component",
                render: (value: string) => {
                    return `${t("Last")}: ${toDateString(new Date(value))}`;
                },
            },
        ],
        [t],
    );

    const sentToColumns = useMemo<IColumn[]>(
        () => [
            {
                title: t("Date"),
                path: "createdAt",
                type: "component",
                render: (value: string, row: IPaperworkDistribution) => {
                    return `${t("Date")}: ${
                        row.distributionType !== "Unknown"
                            ? toDateTimeString(new Date(value))
                            : t("Unknown")
                    }`;
                },
            },
            {
                title: t("Type"),
                path: "distributionType",
                type: "component",
                render: (value: string) => value,
            },
            {
                title: t("Recipient"),
                path: "recipient",
                type: "component",
                render: (value: string) => value,
            },
        ],
        [t],
    );

    return (
        <div>
            {paperwork.length ? (
                <SimpleTable data={paperwork} columns={columns} />
            ) : (
                t("No Paperwork Found")
            )}

            <Portal>
                {seenByModalVisible && (
                    <Modal
                        title={t("Paperwork Downloaded By")}
                        hide={seenByModalHide}
                    >
                        <ModalBody>
                            <SimpleTable
                                columns={seenByColumns}
                                data={paperworkDownloaders}
                                withTimeline={true}
                            />
                        </ModalBody>
                    </Modal>
                )}

                {sentToModalVisible && (
                    <Modal
                        title={
                            modalType === "sent"
                                ? t("Documents sent to")
                                : t("Send documents to")
                        }
                        hide={sentToModalHide}
                    >
                        <ModalBody>
                            {modalType === "sent" && (
                                <div className={styles.historyContainer}>
                                    <SimpleTable
                                        columns={sentToColumns}
                                        data={paperworkDistributions}
                                        withTimeline={true}
                                    />
                                </div>
                            )}
                            <PaperworkSender
                                paperworkId={selectedPaperworkId}
                                hasBeenSent={modalType === "sent"}
                                emailAddress={tenantEmail ? tenantEmail : ""}
                                sentHandler={sentHandler}
                            />
                        </ModalBody>
                    </Modal>
                )}

                {toast.visible && (
                    <Portal>
                        <Toast>{toastMessage}</Toast>
                    </Portal>
                )}
            </Portal>
        </div>
    );
};

interface IPaperworkLogProps {
    paperwork: IPaperwork[];
    tenantEmail?: string;
    refresh?: () => void;
    onSelectRow: (checked: boolean, value: number) => void;
    isRowSelected: (id: number) => boolean;
    disableFuelTypeFiltering?: boolean;
}

export default PaperworkLog;
