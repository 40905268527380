import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LineChartWithTotal, {
    ILineChartWithTotal,
} from "../../../components/LineChartWithTotal";
import { IEngineerJobCounts } from "../../../utils/api/engineers";

const JobsCompleted = ({ jobCounts }: IJobsCompletedProps) => {
    const { t } = useTranslation();
    const [jobsData, setJobsData] = useState<ILineChartWithTotal[]>([]);

    const mapChartData = (count: number[]) => {
        return count.map((i) => ({
            label: i.toString(),
            value: i,
        }));
    };

    useEffect(() => {
        setJobsData([
            {
                label: t("This week"),
                count: jobCounts.week.total,
                chart: mapChartData(jobCounts.week.periodCounts),
            },
            {
                label: t("This month"),
                count: jobCounts.month.total,
                chart: mapChartData(jobCounts.month.periodCounts),
            },
            {
                label: t("This year"),
                count: jobCounts.year.total,
                chart: mapChartData(jobCounts.year.periodCounts),
            },
        ]);
    }, [jobCounts, t]);

    return <LineChartWithTotal data={jobsData} />;
};

interface IJobsCompletedProps {
    jobCounts: IEngineerJobCounts;
}

export default JobsCompleted;
