import { useContext, useEffect, useMemo } from "react";
import { filter, map } from "rxjs";
import { IUnableToAccess } from ".";
import RealtimeContext from "../../../components/RealtimeProvider/RealtimeContext";
import config from "../../../config";
import { clearCache } from "../../cache";
import { realtimeCreateList } from "../../realtime";
import useAllFilterable from "../useAllFilterable";
import { IFilters } from "../useFilterable";

interface IUnableToAccessFilters {
    utaDate?: string;
    applianceId?: string;
}

const useAllUnableToAccessList = (
    propertyId: string,
    { utaDate, applianceId }: IUnableToAccessFilters,
) => {
    const sortProperties = useMemo(() => ["complianceType.id", "date"], []);
    const sortOrders = useMemo<("asc" | "desc")[]>(() => ["asc", "desc"], []);

    const { realtimeObservable } = useContext(RealtimeContext);

    const filters = useMemo(() => {
        const f: IFilters = {
            "property.id": {
                operator: "{AND}",
                filters: [{ function: "=", value: propertyId }],
            },
            assetId: {
                operator: "{AND}",
                filters: [{ function: "=", value: applianceId ?? "null" }],
            },
        };

        if (utaDate) {
            f.date = {
                operator: "{AND}",
                filters: [{ function: ">", value: utaDate }],
            };
        }

        return f;
    }, [propertyId, applianceId, utaDate]);

    const { refresh, records, loaded, updateValue } =
        useAllFilterable<IUnableToAccess>({
            url: `${config.jobsApiUrl}/unabletoaccess`,
            sortProperties,
            sortOrders,
            filters,
        });

    useEffect(() => {
        refresh(filters);
    }, [filters, refresh]);

    useEffect(() => {
        const subscription = realtimeObservable
            .pipe(
                filter(
                    (e) =>
                        e.entity === "unable-to-access" &&
                        e.event === "created",
                ),
                map((e) => e.payload as IUnableToAccess),
                filter(
                    (e) =>
                        Number(propertyId) === e.property.id &&
                        applianceId === e.appliance?.id,
                ),
            )
            .subscribe((value) => {
                const updatedList = realtimeCreateList(value, records);

                if (updatedList) {
                    updateValue(updatedList);
                }

                clearCache();
            });
        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applianceId, propertyId, realtimeObservable, records]);

    return { loaded, records };
};

export default useAllUnableToAccessList;
