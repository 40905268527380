import { CSSProperties, useCallback, useMemo } from "react";
import { IPhaseLimitsItemProps } from ".";
import { useCssClasses } from "../../../../hooks";
import useClickableNonInteractiveElement from "../../../../hooks/useClickableNonInteractiveElement";
import styles from "./PhaseLimitsItem.module.scss";

const PhaseLimitsItem = (props: IPhaseLimitsItemProps) => {
    const { colour } = props;
    const isSelected = "isSelected" in props ? props.isSelected : undefined;
    const hide = "hide" in props ? props.hide : undefined;
    const updateColour =
        "updateColour" in props ? props.updateColour : undefined;
    const setRef = "setRef" in props ? props.setRef : undefined;
    const testId = "testId" in props ? props.testId : undefined;
    const onClick = "onClick" in props ? props.onClick : undefined;
    const isInline = "isInline" in props ? props.isInline : undefined;
    const isEditing = "isEditing" in props ? props.isEditing : undefined;

    const handleClick = useCallback(() => {
        if (onClick) {
            onClick();
        } else if (updateColour && hide) {
            updateColour(colour);
            hide();
        }
    }, [colour, hide, onClick, updateColour]);

    const { onKeyDown } = useClickableNonInteractiveElement(handleClick);

    const cssClasses = useCssClasses(
        styles.phase,
        isEditing ? styles.editing : "",
        isInline ? styles.inline : "",
    );

    const cssStyle = useMemo(() => {
        const css: CSSProperties = {
            backgroundColor: isSelected ? undefined : colour,
            borderColor: isSelected ? colour : undefined,
        };

        return css;
    }, [colour, isSelected]);

    return isEditing ? (
        <div
            className={cssClasses}
            style={cssStyle}
            onClick={handleClick}
            onKeyDown={onKeyDown}
            role="button"
            tabIndex={0}
            ref={setRef}
            data-test-id={testId}
        />
    ) : (
        <div
            className={cssClasses}
            style={cssStyle}
            ref={setRef}
            data-test-id={testId}
        />
    );
};

export default PhaseLimitsItem;
